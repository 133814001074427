import React, { Component } from 'react'

import NavbarInformes from './NavbarInformes'
import TablaInfoPreguntas from './TablasInformes/TablaInfoPreguntas'

export default class InformesPreguntas extends Component {

    state = {
        titulo: "",
    }

    componentWillMount() {
        this.setState({
            titulo: `Informe PREGUNTAS`
        })
    }

    render() {
        return (
            <div className="g-r-prf3 bg-light vh-100">
                <NavbarInformes titulo={this.state.titulo}/>
                <TablaInfoPreguntas/>
                 
            </div>
        )
    }
}
