import React, { Component } from "react";
import MaterialTable from "material-table";
import { Tab, Tabs } from "react-bootstrap";
import HeadTitle from "../../principal/HeadTitle";
import { Modal } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator'
import { TextField } from "@mui/material";
import { MenuItem, } from "@material-ui/core";
import PpHourglass from "./../Asamblea/PopUps/PpHourglass";
import ModalInfoPr from "./modals/ModalInfoPr";
import { Fab, Tooltip } from "@material-ui/core";

import { Redirect } from "react-router-dom";
import Global from "../../../Global";
import moment from "moment";
import "moment/locale/es";
import swal from "sweetalert";
import axios from "axios";
import { CookieSesion } from "../../Helpers";
import Loader from "../../principal/Loader";

const rol = [
  {
    value: "1",
    label: "Propietario",
  },
  {
    value: "2",
    label: "Propietario - Residente",
  },
  {
    value: "3",
    label: "Residente",
  },
];

export default class MenuTabsSusb extends Component {

  state = {
    redirect_home: false,
    redirect_login: 0,
    Roles: [1, 2, 3, 4],
    tab: 0,

    columns: [],

    /* PARA PRE REGISTRO PENDIENTE */
    modal: false,
    rows1: [],
    relacion_in: undefined,
    data1: false,
    TipoSusc: "3",

    modalVal: false,
    textInpVal: "",
    textInfoValE: false,
    Tem_data: {},

    Nombre: "",

    /* PARA PRE REGISTRO HISTORIAL */
    data2: false,
    Historial: [],
    PrData: {},
    modal2: false,

    /* COMUNIDAD */
    form_susc: 0,
    data3: false,
    rol: 0,
    nombres: [],
    newData: [],
    rows: [],

    PermisoPortero: '',
  }

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          this.DataTabs((parseInt(Global.GlobalUser.rol) === 4 || parseInt(Global.GlobalUser.rol) === 3) ? ("0") : ("0"))
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }

    this.validator = new SimpleReactValidator({
      locale: 'es',
      messages: {
        required: 'Requerido',
      }
    });
  }

  DataTabs = (opc) => {
    this.setState({ data1: false, data2: false, data3: false })
    switch (parseInt(opc)) {
      case 0:
        this.DataTablaSuscA();
        break;
      case 1:
        this.DataTablaPRapro();
        break;
      default:
        this.DataTablaPRhisto();
        break;
    }

    this.setState({
      tab: opc,
    })
  }

  /* Inicio pre registro para aprobar*/
  DataTablaPRapro() {

    this.setState({
      data1: false
    })

    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64")
    }
    axios.post(`${Global.Ruta.Url}preregistro/autorizafalse`, body).then((res) => {
      if (res.data.message === "") {
        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].fecha_solicitud = moment(res.data.data[i].fecha_solicitud).format("LLLL");
          res.data.data[i].origen_view = res.data.data[i].origen === "W" ? "WEB" : "MOVIL"
          res.data.data[i].origen_view = res.data.data[i].origen === "W" ? "WEB" : "MOVIL"
        }
        this.setState({
          data1: true,
          rows1: res.data.data,
          columns: [
            {
              field: "nombres",
              title: "Nombres",
            },
            {
              field: "direccion",
              title: "Direccion",
            },
            {
              field: "telefono",
              title: "Telefono",
            },
            {
              field: "email",
              title: "Correo",
            },
            {
              field: "fecha_solicitud",
              title: "Fecha Solicitud",
            },
            {
              field: "id",
              title: "Id",
              hidden: true,
            },
            {
              field: "identificador",
              title: "Identificador",
              hidden: true,
            },
            {
              field: "idinmueble",
              title: "Id Inmueble",
              hidden: true,
            },
            {
              field: "login",
              title: "Login",
              hidden: true,
            },

            {
              field: "origen",
              title: "Origen",
              hidden: true,
            },
            {
              field: "tiposervicio",
              title: "Tipo Servicio",
              hidden: true,
            },

            {
              field: "tipo_doc",
              title: "tipo_doc",
              hidden: true,
            },
            {
              field: "documento",
              title: "documento",
              hidden: true,
            },
            {
              field: "adicionales",
              title: "adicionales",
              hidden: true,
            },
            {
              field: "origen_view",
              title: "Origen",
              hidden: true,
            },
            {
              field: "relacion_in",
              title: "Rol",
              hidden: true,
            },
          ],
        })
      } else {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_home: true });
        });
      }
    }).catch((err) => {
      swal({
        title: "ALERTA!",
        text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
        icon: "error",
        button: "Aceptar",
      }).then((e) => {
        this.setState({ redirect_home: true });
      });
    });

  }

  ApruebaRechaza = () => {

    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      autorizado: Buffer.from("" + this.state.textInfoValE).toString("base64"),
      id_autoriza: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
      id: Buffer.from("" + this.state.Tem_data.id).toString("base64"),
      justificacion: Buffer.from("" + this.state.textInpVal).toString("base64"),
      tipo: Buffer.from("" + this.state.TipoSusc,).toString("base64"),
      nombres: Buffer.from("" + this.state.Tem_data.nombres).toString("base64"),
      login: Buffer.from("" + this.state.Tem_data.login).toString("base64"),
      email: Buffer.from("" + this.state.Tem_data.email).toString("base64"),
      telefono: Buffer.from("" + this.state.Tem_data.telefono).toString("base64"),
      inmuebleid: Buffer.from("" + this.state.Tem_data.idinmueble).toString("base64"),
      tiposervicio: Buffer.from("" + this.state.Tem_data.tiposervicio).toString("base64"),
      identificador: Buffer.from("" + this.state.Tem_data.identificador).toString("base64"),
      tipo_doc: Buffer.from("" + this.state.Tem_data.tipo_doc).toString("base64"),
      documento: Buffer.from("" + this.state.Tem_data.documento).toString("base64"),
      adicionales: Buffer.from(JSON.stringify(this.state.Tem_data.adicionales)).toString("base64"),
    }

    if (this.validator.allValid() || this.state.textInfoValE) {

      this.setState({
        modal: true
      })

      axios.post(`${Global.Ruta.Url}preregistro/suscriotrpreregistro`, body).then((res) => {
        this.setState({
          modal: false
        })
        swal({
          title: "ATENCIÓN!",
          text: res.data.message,
          icon: (parseInt(res.data.caso) > 0) ? "success" : "error",
          button: "Aceptar",
        }).then(() => {
          if (res.data.caso > 0) {
            this.Actualiza(this.state.Tem_data.id);
            this.setState({ modalVal: false, Tem_data: {}, textInpVal: "", Nombre: "", });
          }
        });
      }).catch((err) => {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then((e) => {
          this.setState({ redirect_home: true });
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleChange = (prop) => (event) => {
    this.setState((state) => ({
      ...state,
      [prop]: event.target.value,
    }));
  };

  Actualiza = (id) => {
    var tem = this.state.rows1;
    var temi = this.BuscarIndexCambio(id, tem);
    tem.splice(temi, 1);

    this.setState({ rows1: tem });
  };

  BuscarIndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };
  /* Fin pre registro por aprobar*/

  /* Inicio pre registro Historial */
  DataTablaPRhisto = () => {
    this.setState({
      data2: false
    })
    var body = {
      ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
    }

    axios.post(`${Global.Ruta.Url}preregistro/historialpreregistro/`, body).then(res => {
      if (res.data.message === "") {

        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].fecha_solicitud = moment(res.data.data[i].fecha_solicitud).format("LLLL")
          res.data.data[i].fecha_autoriza = moment(res.data.data[i].fecha_autoriza).format("LLLL")
          res.data.data[i].origen_view = (res.data.data[i].origen === "W" ? ("WEB") : ("MOVIL"))
          res.data.data[i].autorizado_view = (res.data.data[i].autorizado === true ? ("SI") : ("NO"))
        }

        this.setState({
          data2: true,
          Historial: res.data.data,
          columns: [
            {
              field: "id",
              title: "id",
              hidden: true,
            },
            {
              field: "idinmueble",
              title: "idinmueble",
              hidden: true,
            },
            {
              field: "id_autoriza",
              title: "id_autoriza",
              hidden: true,
            },
            {
              field: "id_autoriza",
              title: "id_autoriza",
              hidden: true,
            },
            {
              field: "identificador",
              title: "identificador",
              hidden: true,
            },
            {
              field: "tiposervicio",
              title: "tiposervicio",
              hidden: true,
            },
            {
              field: "nombres",
              title: "Nombres",
            },
            {
              field: "direccion",
              title: "Direccion",
            },
            {
              field: "telefono",
              title: "Telefono",
              hidden: true,
            },
            {
              field: "email",
              title: "Correo",
              hidden: true,
            },
            {
              field: "fecha_solicitud",
              title: "Fecha Solicitud",

            },
            {
              field: "fecha_autoriza",
              title: "Fecha Autoriza",

            },
            {
              field: "login",
              title: "Login",
              hidden: true,
            },
            {
              field: "origen_view",
              title: "origen",
            },
            {
              field: "autorizado_view",
              title: "Autorizado",
            },
            {
              field: "nombre_autoriza",
              title: "Nombre Autoriza",
            },
            {
              field: "justificacion",
              title: "Justificacion",
              hidden: true,
            },
            {
              field: "tipo_doc",
              title: "tipo_doc",
              hidden: true,
            },
            {
              field: "documento",
              title: "documento",
              hidden: true,
            },
          ]
        });
      } else {
        swal({
          title: "Atención !!",
          text: res.data.message,
          icon: 'warning',
          button: 'Aceptar',
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      }
    }).catch(err => {
      console.log(err.message)
      this.setState({
        redirect_home: true,
      });
    })
  }

  ShowModal = (data) => {
    this.setState({ modal2: true, PrData: data })
  }
  /* Fin Historial pre-registro */

  /* COMUNIDAD */

  DataTablaSuscA = async () => {
    this.setState({
      data3: false
    })

    var userRol = Global.GlobalUser.rol;

    if (this.state.Roles.includes(Global.GlobalUser.rol) || parseInt(userRol) === 3 || parseInt(userRol) === 4) {
      axios.get(Global.Ruta.Url + "admin/listsusc/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from(Global.Ruta.Ipin).toString("base64")).then(async (res) => {
        if (res.data.message === "") {
          var body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          }
          await axios.post(Global.Ruta.Url + "porteria/permite/", body).then((res1) => {
            if (res1.data.message === "") {
              this.setState({
                PermisoPortero: res1.data.PermisoTelefonos
              })
            } else {
              console.log(res1.data.message)
            }
          }).catch(err2 => {
            console.log(err2.message)
          })
          var tem = [];
          var user = {};
          for (var i = 0; i < res.data.Suscriptores.length; i++) {
            user = {
              suscriptorid: res.data.Suscriptores[i].suscriptorid,
              login: res.data.Suscriptores[i].login,
              nombres: res.data.Suscriptores[i].nombres,
              rolid: res.data.Suscriptores[i].rolid,
              email: res.data.Suscriptores[i].email,
              telefono: res.data.Suscriptores[i].telefono,
              direccion: res.data.Suscriptores[i].direccion,
              inmuebleid: res.data.Suscriptores[i].inmuebleid,
              coeficiente: res.data.Suscriptores[i].coeficiente,
              estadoint: res.data.Suscriptores[i].estadoint,
            };
            tem.push(user);
          }
          setTimeout(() => {
            this.setState({
              rows: tem, data3: true, rol: userRol,
              columns: [
                {
                  field: "suscriptorid",
                  title: "Id usuario",
                  hidden: true,
                },
                {
                  field: "direccion",
                  title: "Dirección",
                },
                {
                  field: "nombres",
                  title: "Nombres",
                },
                {
                  field: "rolid",
                  title: "Rol",
                  lookup: { 1: "PROPIETARIO", 2: "PROPIETARIO-RESIDENTE", 3: "RESIDENTE" },
                },
                {
                  field: "email",
                  title: "Correo",
                  cellStyle: { maxWidth: 220, overflowWrap: "break-word" },
                  hidden: parseInt(userRol) === 4 ? true : false,
                },
                {
                  field: "telefono",
                  title: "Teléfono",
                  hidden: (parseInt(userRol) === 4 && this.state.PermisoPortero === true) ? false : true,
                },
                {
                  field: "login",
                  title: "Usuario",
                  hidden: parseInt(userRol) === 4 ? true : false,
                },

                {
                  field: "inmuebleid",
                  title: "Nombres",
                  hidden: true,
                },
                {
                  field: "coeficiente",
                  title: "Coeficiente",
                  hidden:
                    parseInt(Global.GlobalUser.rol) === 1 ||
                      parseInt(Global.GlobalUser.rol) === 2
                      ? false
                      : true,
                },
                {
                  field: "estadoint",
                  title: "Estado",
                  lookup: { 0: "INACTIVO", 1: "ACTIVO" },
                },
              ],
            });
          }, 500);
        } else {
          swal({
            title: "ALERTA!",
            text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      }).catch((err) => {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then((e) => {
          this.setState({ redirect_home: true });
        });
      });
    } else {
      this.setState({ redirect_home: true });
    }
  }

  formulario = (e) => {
    Global.Form.tipo = e.tipo;
    if (e.tipo === 2) {
      Global.Form.id = e.id;
      Global.Form.nombre = e.nombre;
      Global.Form.login = e.login;
      Global.Form.estado = e.estado;
      Global.Form.rol = e.rol;
      Global.Form.inmuebleid = e.inmuebleid;
      Global.Form.correo = e.correo;
      Global.Form.telefono = e.telefono;
      //Global.Form.mora = e.mora;
    } else {
      Global.Form.id = "";
      Global.Form.nombre = "";
      Global.Form.login = "";
      Global.Form.estado = "";
      Global.Form.rol = "";
      Global.Form.inmuebleid = "";
      //Global.Form.mora = false;
    }
    this.setState({ form_susc: 1, data3: false });
  };

  /* FIN COMUNIDAD */

  render() {

    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.form_susc === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/formsuscriptor"} />;
    }



    if (this.state.data1 || this.state.data2 || this.state.data3) {
      const { tab } = this.state
      return (
        <div className="container-fluid h-100">
          {/* PRE-REGISTRO */}
          <Modal show={this.state.modalVal} onHide={() => this.setState({ modalVal: false })} backdrop='static' centered>
            <Modal.Header closeButton style={{ fontSize: "13px" }}>
              {`Pre-Registro -`}<strong style={{ marginLeft: "3px" }}>{`${this.state.Nombre}`}</strong>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div className="col-12 text-center">
                  <i className='fa-solid fa-triangle-exclamation my-3 text-warning' style={{ fontSize: '5rem', }} />
                  <h5 className="fw-normal">
                    Esta seguro de <strong>{(this.state.textInfoValE === false) ? ('Rechazar') : ('Aprobar')}</strong> al usuario: <br /> <strong>{this.state.Nombre}</strong> del inmueble <strong>{this.state.direccion}</strong> ? <br />
                  </h5>
                </div>
                {(this.state.textInfoValE === false) ? (
                  <div className="col">
                    <TextField size="small" className="shadow-sm" required fullWidth id="outlined-basic"
                      label="Justificación" name="justificacion" variant="outlined" defaultValue={this.state.textInpVal}
                      onChange={this.handleChange("textInpVal")} />
                    <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('textInpVal', this.state.textInpVal, 'required', { className: 'text-danger' })}</p>
                  </div>
                ) : (
                  <div className="col">
                    <TextField size="small" className="shadow-sm" required select label="Rol" defaultValue={this.state.TipoSusc} value={this.state.TipoSusc}
                      fullWidth onChange={(e) => { this.setState({ TipoSusc: e.target.value }) }} variant="outlined"
                      inputProps={{ min: 0, style: { textAlign: "center" }, }} >
                      {rol.map((option) => (
                        <MenuItem key={option.value} value={option.value} >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('TipoSusc', this.state.TipoSusc, 'required', { className: 'text-danger' })}</p>
                  </div>
                )
                }
              </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
              <button className='btn btn-primary' onClick={this.ApruebaRechaza}>{(this.state.textInfoValE === false) ? ('Rechazar') : ('Aprobar')}</button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })} >
            <PpHourglass />
          </Modal>

          {/* HISTORIAL */}
          <Modal show={this.state.modal2} centered onHide={() => this.setState({ modal2: false })}>
            <ModalInfoPr datos={this.state.PrData} />
          </Modal>

          <div className="row py-3 gy-3 bg-light">
            <div className="col-12">
              <div className="row">
                <HeadTitle titulo="Registro de la Comunidad" classNameOwn="row px-3" indicadores={[]} />
              </div>
            </div>
            <div className="col-12">
              <Tabs className="rounded-top"
                justify
                activeKey={this.state.tab}
                onSelect={this.DataTabs}
              >
                <Tab Tab eventKey="0" title="Comunidad" tabClassName={`${tab === '0' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`}>
                  {(this.state.data3) ? (
                    <div className="col-12">
                      <MaterialTable
                        columns={this.state.columns}
                        data={this.state.rows}
                        title={
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <h5 className="mb-0 me-3">{`Comunidad`}</h5>
                            </div>
                            <div className="col-auto ps-0">
                              {parseInt(Global.GlobalUser.rol) !== 4 ? (
                                <Tooltip title="Añadir nuevo suscriptor" placement="right" arrow >
                                  <Fab disabled={this.state.Roles.includes(Global.GlobalUser.rol) ? false : true}
                                    onClick={() => this.formulario({ tipo: 1 })} className="shadow-none bg-success text-light"
                                    size="small" aria-label="edit" >
                                    <i className="fas fa-plus"></i>
                                  </Fab>
                                </Tooltip>
                              ) : ("")}


                            </div>
                          </div>
                        }
                        actions={[
                          {
                            icon: "save",
                            tooltip: "Save User",
                            onClick: (event, rowData) =>
                              this.formulario({
                                tipo: 2,
                                id: rowData.suscriptorid,
                                nombre: rowData.nombres,
                                login: rowData.login,
                                rol: rowData.rolid,
                                estado: rowData.estadoint,
                                inmuebleid: rowData.inmuebleid,
                                correo: rowData.email,
                                telefono: rowData.telefono,
                              }),
                          },
                        ]}
                        components={{
                          Action: (props) => (
                            <Fab className="mx-3" onClick={(event) => props.action.onClick(event, props.data)} color="primary"
                              aria-label="edit" size="small" disabled={this.state.rol === 4 ? true : false} >
                              <i className="fas fa-edit" />
                            </Fab>
                          ),
                        }}
                        options={{
                          pageSize:
                            this.state.rows.length < 20
                              ? this.state.rows.length
                              : 20,
                          pageSizeOptions: [
                            this.state.rows.length < 20
                              ? this.state.rows.length
                              : 20,
                          ],
                          actionsColumnIndex: -1,
                          padding: "dense",
                          rowStyle: (rowData) => {
                            if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                              return {
                                backgroundColor: "#eeeeee",
                                fontSize: '0.9rem',
                              };
                            }
                            return {
                              backgroundColor: "#ffffff",
                              fontSize: '0.9rem',
                            };
                          },
                        }}
                        localization={{
                          header: {
                            actions: "Editar",
                          },
                          body: {
                            emptyDataSourceMessage: "No hay datos por mostrar.",
                          },
                          pagination: {
                            labelRowsSelect: "registros",
                            labelDisplayedRows: "{from}-{to} de {count}",
                            firstTooltip: "Primera página",
                            previousTooltip: "Página anterior",
                            nextTooltip: "Próxima página",
                            lastTooltip: "Última página",
                          },
                          toolbar: {
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Buscar",
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center text-info donita">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </Tab>
                {(parseInt(Global.GlobalUser.rol) === 2 || parseInt(Global.GlobalUser.rol) === 1) ?
                  (<Tab Tab eventKey="1" title="Aprobación Pre registro" tabClassName={`${tab === '1' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`} >
                    {(this.state.data1) ? (<div className="col-12">
                      <MaterialTable
                        columns={this.state.columns}
                        data={this.state.rows1}
                        title={
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <h5 className="mb-0 me-3">{`Aprobación de Pre-registros`}</h5>
                            </div>
                          </div>
                        }
                        actions={[
                          {
                            onClick: (rowData, caso) => {
                              this.setState({
                                modalVal: true,
                                textInfoValE: caso,
                                Nombre: rowData.nombres,
                                direccion: rowData.direccion,
                                TipoSusc: rowData.relacion_in.toString(),
                                Tem_data: rowData
                              })
                            }
                          },
                        ]}
                        components={{
                          Action: (props) => (
                            <div className="d-flex ms-1" style={{ padding: "10px 3px" }}>
                              <Tooltip title="APROBAR" placement="left-start">
                                <Fab aria-label="edit" size="small" onClick={(event) => props.action.onClick(props.data, true)}
                                  className={`me-1 text-white bg-success`} disabled={this.state.Roles.includes(Global.GlobalUser.rol) ? false : true} >
                                  <i className="fas fa-check" />
                                </Fab>
                              </Tooltip>
                              <Tooltip title={"RECHAZAR"} placement="top" >
                                <Fab color="primary" aria-label="edit" size="small" className={`me-1 text-white bg-danger`}
                                  onClick={() => props.action.onClick(props.data, false)} disabled={this.state.Roles.includes(Global.GlobalUser.rol) ? false : true}>
                                  <i className="fas fa-times"></i>
                                </Fab>
                              </Tooltip>
                            </div>
                          ),
                        }}
                        options={{
                          pageSize:
                            this.state.rows1.length < 20
                              ? this.state.rows1.length
                              : 20,
                          pageSizeOptions: [
                            this.state.rows1.length < 20
                              ? this.state.rows1.length
                              : 20,
                          ],
                          actionsColumnIndex: -1,
                          padding: "dense",
                          rowStyle: (rowData) => {
                            if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                              return {
                                backgroundColor: "#eeeeee",
                                fontSize: '0.9rem',
                              };
                            }
                            return {
                              backgroundColor: "#ffffff",
                              fontSize: '0.9rem',
                            };
                          },
                        }}
                        localization={{
                          header: {
                            actions: "Acciones",
                          },
                          body: {
                            emptyDataSourceMessage: "No hay datos por mostrar.",
                          },
                          pagination: {
                            labelRowsSelect: "registros",
                            labelDisplayedRows: "{from}-{to} de {count}",
                            firstTooltip: "Primera página",
                            previousTooltip: "Página anterior",
                            nextTooltip: "Próxima página",
                            lastTooltip: "Última página",
                          },
                          toolbar: {
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Buscar",
                          },
                        }}
                      />
                    </div>) : (
                      <div className="d-flex justify-content-center text-info donita">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </Tab>
                  ) :
                  null
                }
                {(parseInt(Global.GlobalUser.rol) === 2 || parseInt(Global.GlobalUser.rol) === 1) ?
                  (<Tab Tab eventKey="2" title="Historial Pre registro" tabClassName={`${tab === '2' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`} >
                    {(this.state.data2 === true) ? (<div className="col-12">
                      <MaterialTable
                        columns={this.state.columns}
                        data={this.state.Historial}
                        title={
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <h5 className="mb-0 me-3">{`Historial de Pre-registros`}</h5>
                            </div>
                          </div>
                        }
                        actions={[
                          {
                            onClick: (rowData) => {
                              this.ShowModal(rowData)
                            },
                          },
                        ]}
                        components={{
                          Action: (props) => (
                            <Fab
                              className="mx-3"
                              onClick={(event) => props.action.onClick(props.data)}
                              color="primary"
                              aria-label="edit"
                              size="small"
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Fab>
                          ),
                        }}

                        options={{
                          pageSize:
                            this.state.Historial.length < 20
                              ? this.state.Historial.length
                              : 20,
                          pageSizeOptions: [
                            this.state.Historial.length < 20
                              ? this.state.Historial.length
                              : 20,
                          ],
                          actionsColumnIndex: -1,
                          padding: "dense",
                          rowStyle: (rowData) => {
                            if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                              return {
                                backgroundColor: "#eeeeee",
                                fontSize: '0.9rem',
                              };
                            }
                            return {
                              backgroundColor: "#ffffff",
                              fontSize: '0.9rem',
                            };
                          },
                        }}
                        localization={{

                          body: {
                            emptyDataSourceMessage: "No hay datos por mostrar.",
                          },
                          pagination: {
                            labelRowsSelect: "registros",
                            labelDisplayedRows: "{from}-{to} de {count}",
                            firstTooltip: "Primera página",
                            previousTooltip: "Página anterior",
                            nextTooltip: "Próxima página",
                            lastTooltip: "Última página",
                          },
                          toolbar: {
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Buscar",
                          },
                        }}
                      />
                    </div>) : (
                      <div className="d-flex justify-content-center text-info donita">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </Tab>
                  ) :
                  null
                }

              </Tabs>
            </div>
          </div>
        </div >
      );
    }

    return (
      <Loader princial={true} uno={"Comunidad"} dos={"Datos"} tres={"Suscriptores"} />
    )

  }
}
