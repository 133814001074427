import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import SimpleReactValidator from 'simple-react-validator'

export default class SeccionBanka extends Component {


componentWillMount(){
    this.validator = new SimpleReactValidator({
        locale: 'es',
        messages: {
            url: 'Ingrese una URL Válida',
        }
      });
}

    state = {
        banco: this.props.bancos,
        tipo_c: this.props.tipo_c,
        n_cuenta: this.props.n_cuenta,
        detalle: this.props.detalle,
        link:this.props.link,
    }

    agregar = () => {
        if (this.validator.allValid()) {
        this.props.accion({
            banco: this.state.banco,
            tipo: this.state.tipo_c,
            numero: this.state.n_cuenta,
            detalle: this.state.detalle,
            link: this.state.link,
        });

        this.setState({
            banco: 'BANCAMIA S.A.',
            tipo_c: 'Ahorros',
            n_cuenta: '',
            detalle: '',
            link:''
        })
    } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }

    render() {
        const bancos = [
            "BANCAMIA S.A.",
            "BANCO AGRARIO",
            "BANCO AV VILLAS",
            "BANCO BBVA COLOMBIA S.A.",
            "BANCO CAJA SOCIAL",
            "BANCO COOPERATIVO COOPCENTRAL",
            "BANCO CREDIFINANCIERA",
            "BANCO DAVIVIENDA",
            "BANCO DE BOGOTA",
            "BANCO DE OCCIDENTE",
            "BANCO FALABELLA",
            "BANCO GNB SUDAMERIS",
            "BANCO ITAU",
            "BANCO PICHINCHA S.A.",
            "BANCO POPULAR",
            "BANCO SANTANDER COLOMBIA",
            "BANCO SERFINANZA",
            "BANCOLOMBIA",
            "BANCOOMEVA S.A.",
            "CFA COOPERATIVA FINANCIERA",
            "CITIBANK",
            "COLTEFINANCIERA",
            "CONFIAR COOPERATIVA FINANCIERA",
            "COOFINEP COOPERATIVA FINANCIERA",
            "COTRAFA",
            "DALE",
            "DAVIPLATA",
            "GIROS Y FINANZAS COMPAÑIA DE FINANCIAMIENTO S.A.",
            "IRIS",
            "MOVII S.A.",
            "NEQUI",
            "RAPPIPAY",
            "SCOTIABANK COLPATRIA"
        ]

        const tipoC = [
            'Ahorros',
            'Corriente',
        ]

        let buttonState = this.props.eliminar ? 'btn btn-danger' : 'btn btn-primary';
        let iconState = this.props.eliminar ? 'minus' : 'plus';

        return (
            <div className='row align-items-center gy-2'>
                <div className="col-12">
                    <h6 className={`m-0`}>Datos Bancarios</h6>
                </div>
                <div className='col-11'>
                    <div className='row row-cols-3 gy-3'>
                        <div className="col">
                            <Form.Floating>
                                <Form.Select value={this.state.banco} onChange={(e) => this.setState({ banco: e.target.value })} disabled={this.props.eliminar}>
                                    {bancos.map((element) => (<option value={element}>{element}</option>))}
                                </Form.Select>
                                <label><span className="fa-solid fa-bank" />{' '}Banco</label>
                            </Form.Floating>
                        </div>
                        <div className="col">
                            <Form.Floating>
                                <Form.Select value={this.state.tipo_c} onChange={(e) => this.setState({ tipo_c: e.target.value })} disabled={this.props.eliminar}>
                                    {tipoC.map((element) => (<option value={element}>{element}</option>))}
                                </Form.Select>
                                <label><span className="fa-solid fa-file-invoice-dollar" />{' '}Tipo de cuenta</label>
                            </Form.Floating>
                        </div>
                        <div className='col'>
                            <Form.Floating>
                                <Form.Control className='shadow-sm' id={`cmpBnk${this.props.index}`} type={'number'} placeholder={'ph'} disabled={this.props.eliminar}
                                    value={this.state.n_cuenta} onChange={(event) => this.setState({ n_cuenta: event.target.value })}
                                />
                                <label htmlFor={`cmpBnk${this.props.index}`}>
                                    <i className={`fa-solid fa-money-check-dollar me-2`} />
                                    {'N° de Cuenta'}
                                </label>
                            </Form.Floating>
                        </div>
                        <div className="col-12 col-md">
                            <Form.Floating>
                                <Form.Control className='shadow-sm' id={`cmpBnk${this.props.index + 1}`} as='textarea' placeholder={'ph'} rows={3} disabled={this.props.eliminar}
                                    value={this.state.detalle} onChange={(event) => this.setState({ detalle: event.target.value })}
                                />
                                <label htmlFor={`cmpBnk${this.props.index + 1}`}>
                                    <i className={`fa-solid fa-feather-pointed me-2`} />
                                    {'Detalle'}
                                </label>
                            </Form.Floating>
                        </div>
                        <div className="col-12 col-md">
                            <Form.Floating>
                                <Form.Control className='shadow-sm' id={`cmpBnk${this.props.index + 1}`} as='textarea' placeholder={'ph'} rows={3} disabled={this.props.eliminar}
                                    value={this.state.link} onChange={(event) => this.setState({ link: event.target.value })}
                                />
                                <label htmlFor={`cmpBnk${this.props.index + 1}`}>
                                    <i className={`fa-solid fa-link me-2`} />
                                    {'link de pago'}
                                </label>
                            </Form.Floating>
                            <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('link', this.state.link, 'url', { className: 'text-danger' })}</p>
                        </div>
                    </div>
                </div>
                <div className="col-1 d-flex align-items-center">
                    <button className={`${buttonState} rounded-circle`}
                        onClick={() => (this.props.eliminar) ?
                            (this.props.accion(this.props.index)) :
                            (this.agregar())}
                            disabled={(this.state.n_cuenta !== '' && this.state.detalle !== '') ? (false) : (true)}>
                        <i className={`fas fa-${iconState}`} />
                    </button>
                </div>
            </div>
        )
    }
}
