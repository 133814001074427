import React, { Component } from "react";
import axios from "axios";
import Global from "../../../Global";
import { CookieSesion } from "../../Helpers";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import { TextField } from "@material-ui/core";
//--------IMPORT COMPONENTS--------
import Loader from "../../principal/Loader";
import { removeDiacritics } from "../../Helpers"

//let switcher = "";
export default class CardReservasRol6 extends Component {
  state = {

    ParaBusqueda: [],
    Busqueda: "",
    //redirect
    redirect_home: false,
    redirect_login: 0,

    Roles: [6],

    selectZona: -1,
    Zonas: [],
    Data: false,
    Reservas: [],
    interval: undefined,
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          }
          axios.post(Global.Ruta.Url + "rol6/nomrecursos/", body).then((res) => {
            if (res.data.message === "") {

              this.setState({
                Zonas: res.data.zonas,
                Data: true
              });
            } else if (res.data.message === "ERROR") {
              swal({
                title: "ALERTA!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_home: true });
              });
            } else {
              swal({
                title: "ALERTA!",
                text: res.data.message,
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_home: true });
              });
            }
          }).catch((err) => {
            this.setState({
              redirect_home: true,
            });
          });
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.Reservas) {
      // Solo llamar a onDataUpdate si hay un cambio real en el estado
      if (this.props.onDataUpdate) {
        this.props.onDataUpdate(this.state.Reservas.length);
      }
    }
  }

  convertMinsToHrsMins = (minutes) => {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m;
  };

  EstadoReserva = (data) => {
    switch (parseInt(data)) {
      case 0:
        return { bg: "danger", text: "Cancelado" };
      case 1:
        return { bg: "danger", text: "Negado" };
      case 2:
        return { bg: "success", text: "Aprobado" };
      case 3:
        return { bg: "primary", text: "Estado" };
      case 4:
        return { bg: "warning", text: "Pendiente" };
      default:
        return { bg: "primary", text: "Estado" };
    }
  };

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value
    }));
    this.setState({
      Reservas: this.filterItems(event.target.value, this.state.ParaBusqueda)
    })
  }

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return (
        removeDiacritics(data.nombres).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.nombrerecurso).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1);
    })
  }

  NoAsistio = (data, e) => {
    e.preventDefault();
    let mensaje =
      "Desea marcar inasistencia a: \n" +
      data.nombres +
      ". \n para \n" +
      data.nombrerecurso +
      "? \n en " +
      data.fechareserva;
    swal({
      title: "ATENCIÓN!!",
      text: mensaje,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result === true) {
        var Mmensaje = `La reserva fue cancelada por inasistencia.`;

        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          idreserva: Buffer.from("" + data.idreserva).toString("base64"),
          fechareserva: Buffer.from("" + data.fechareserva).toString("base64"),
          horareserva: Buffer.from("" + data.horareserva).toString("base64"),
          iduser: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
          suscriptorid: Buffer.from("" + data.suscriptorid).toString("base64"),
          nombrerecurso: Buffer.from("" + data.nombrerecurso).toString("base64"),
        };

        axios.post(Global.Ruta.Url + "rol6/noasistio/", body).then((res2) => {

          if (res2.data.message === "") {
            swal({
              title: "Estupendo!!",
              text: Mmensaje,
              icon: "success",
              button: "Aceptar",
              timer: 1000
            }).then(() => {
              this.setState({ modal: false });
              var tem = this.state.ParaBusqueda;
              var index = this.IndexCambio(data.idreserva, tem);

              tem.splice(index, 1);
              this.setState({
                Reservas: tem,
                Busqueda: ""
              });
            });
          } else {
            swal({
              title: "ALERTA!",
              text: res2.data.message,
              icon: "info",
              button: "Aceptar",
              timer: 1000
            });
          }
        }).catch((err) => { });
      }
    });
  }

  Asiste = (data, e) => {
    e.preventDefault();
    let mensaje =
      "Desea marcar asistencia a: \n" +
      data.nombres +
      ". \n para \n" +
      data.nombrerecurso +
      "? \n en " +
      data.fechareserva;
    swal({
      title: "ATENCIÓN!!",
      text: mensaje,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result === true) {
        var Mmensaje = `Se ha marcado la asistencia.`;

        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          idreserva: Buffer.from("" + data.idreserva).toString("base64"),
        };

        axios.post(Global.Ruta.Url + "rol6/siasistio/", body).then((res2) => {

          if (res2.data.message === "") {
            swal({
              title: "Estupendo!!",
              text: Mmensaje,
              icon: "success",
              button: "Aceptar",
              timer: 1000
            }).then(() => {
              this.setState({ modal: false });
              var tem = this.state.ParaBusqueda;
              var index = this.IndexCambio(data.idreserva, tem);

              tem.splice(index, 1);
              this.setState({
                Reservas: tem,
                Busqueda: ""
              });
            });
          } else {
            swal({
              title: "ALERTA!",
              text: res2.data.message,
              icon: "info",
              button: "Aceptar",
              timer: 1000
            });
          }
        }).catch((err) => { });
      }
    });
  }

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.idreserva) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  BuscarReservasHoyZona = (value) => {

    clearInterval(this.state.interval);

    this.setState({ Data: false, selectZona: value }, () => {
      var interval = setInterval(this.prueba, 30000);
      this.setState({
        interval: interval
      })
    })

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      idzona: Buffer.from(`${value}`).toString("base64"),
    };

    axios.post(Global.Ruta.Url + "rol6/brhzona/", body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Data: true,
          Reservas: res.data.Reservas,
          ParaBusqueda: res.data.Reservas,
        })
      } else {
        swal({
          title: "ALERTA!",
          text: res.data.message,
          icon: "info",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            Data: true,
            Reservas: [],
          });
        });
      }
    }).catch((err) => {
      this.setState({
        redirect_home: true,
      });
    });
  }

  prueba = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      idzona: Buffer.from(`${this.state.selectZona}`).toString("base64"),
    };

    axios.post(Global.Ruta.Url + "rol6/brhzona/", body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Reservas: res.data.Reservas,
          ParaBusqueda: res.data.Reservas,
        })
      } else {
        this.setState({
          Reservas: [],
        });
      }
    }).catch((err) => {

    });
  }

  render() {

    const { redirect_home, redirect_login, Data, selectZona, Zonas } = this.state

    if (redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (Data) {

      return (
        <React.Fragment>
          <div class="container mt-1"></div>

          {/* Select Recurso */}
          <div className="row">
            <div className="col mt-1">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="col-12 col-md-3">
                  <select className="minimal" value={selectZona} onChange={(e) => { this.BuscarReservasHoyZona(e.target.value) }}>
                    <option key={-1} value={-1} disabled={selectZona !== -1}>
                      {"--Seleccione una Zona--"}
                    </option>
                    {Zonas.map((i) => (
                      <option key={i.idrecurso} value={i.idrecurso}>
                        {i.nombrerecurso.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Barra Busqueda */}
                <div className="col-12 col-md-2">
                  <TextField
                    autoFocus
                    size="small"
                    fullWidth
                    margin="normal"
                    placeholder="Buscar Reserva"
                    variant="outlined"
                    defaultValue={this.state.Busqueda}
                    value={this.state.Busqueda}
                    onChange={this.handleChange("Busqueda")}
                    disabled={parseInt(selectZona) === -1 ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Card's */}
          <div className="row overflow-auto">
            <div className="col">
              {this.state.Reservas.map((data, index) => (
                <div className="card mb-2">
                  <div className="card-header">{data.nombres.toUpperCase()}</div>
                  <div className="card-body">
                    <p className="card-text texto_x mb-0">{data.direccion}</p>
                    {/* <span className="texto_x">{data.nombrerecurso}</span> - <span className={`texto_x text-${this.EstadoReserva(data.aprobado).bg}`}>{" " + this.EstadoReserva(data.aprobado).text.toUpperCase()}</span> */}
                    <p className="card-text texto_x mb-0">{data.fechareserva + " " + this.convertMinsToHrsMins(data.horareserva)}</p>
                    <div class="d-flex justify-content-start flex-nowrap">
                      <button class="btn btn-primary btn-sm mt-1 mb-0 mx-2" type="button" onClick={(e) => this.Asiste(data, e)}>Asiste</button>
                      <button class="btn btn-danger btn-sm mt-1 mb-0" type="button" onClick={(e) => this.NoAsistio(data, e)}>No Asiste</button>
                    </div>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Loader princial={true} uno={"Reservas"} dos={"Datos"} tres={"Historial"} />
    );
  }
}

CardReservasRol6.defaultProps = {
  onDataUpdate: () => { }, // Función vacía por defecto
  // Otros valores por defecto que puedas necesitar
};