import React, { Component } from "react";
import Logo from "../../../../../src/assets/images/NewLogoPH-bgw.png";

export default class FooterHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indicadores: [
                {
                    icono: "bi-facebook",
                    url: "https://www.facebook.com/gophcolombia",
                    color: "primary"
                },
                {
                    icono: "bi-youtube",
                    url: "https://www.youtube.com/channel/UCK3bVVXVw4ltzil3MCqdG4A",
                    color: "danger"
                },
                {
                    icono: "bi-instagram",
                    url: "instagram",
                    color: "warning"
                },
                {
                    icono: "bi-browser-edge",
                    url: "https://www.inggos.com/",
                    color: "primary"
                },
            ],
        };
    }
    render() {

        return (

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">

                        {/* Footer Left */}
                        <div className="col-12 col-md-4">
                            <div className="icon-text-container">
                                <div className="icon-circle d-none d-sm-block">
                                    <img className="" src={Logo} alt="logoGoph" width="90"></img>
                                </div>
                                <div className="text-content text-left-footer">
                                    <div>Plataforma para la Administración y Asamblea en su Propiedad Horizontal.</div>
                                    <div className="mt-2">V. 2.7.2</div>
                                </div>
                            </div>
                        </div>

                        {/* Footer Center */}
                        <div className="col-12 col-md-4 col-center">
                            <p className="text-content text-center mt-2 mb-0 text-center-footer"><strong>Contáctanos</strong></p>
                            <div className="text-content text-center">
                                <div>comercial@inggos.com</div>
                                <div className="mt-1">www.inggos.com</div>
                            </div>                        
                        </div>

                        {/* Footer Right */}
                        <div className="col-12 col-md-4 text-center">
                            <p className="text-content mt-3"><strong>Síguenos</strong></p>
                            <section className="mt-0">
                                {this.state.indicadores.map((data, item) => {
                                    return (
                                        <a key={item}                                            
                                            className={`btn text-white mx-2 bg-${data.color}`}
                                            href={data.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            role="button"
                                        ><i className={`bi ${data.icono}`}></i></a>
                                    )
                                })
                                }
                            </section>
                        </div>
                        <div className="text-content text-center mt-3">© Copyright 2024 by Inggo Developments</div>
                    </div>
                </div>
            </footer >
        )
    }
}

FooterHome.defaultProps = {

};