import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import swal from 'sweetalert';
import { TextField, Grid, CssBaseline, MenuItem, Box, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';

import Global from "../../../Global";

//------------------------------------------IMAGENES-----------------------------------------------------

import Img1 from "../../../assets/images/icons/01-Futbol.2.png";
import Img2 from "../../../assets/images/icons/02-Golf.2.png";
import Img3 from "../../../assets/images/icons/03-Gym.2.png";
import Img4 from "../../../assets/images/icons/04-Tenis.2.png";
import Img5 from "../../../assets/images/icons/05-bbq.2.png";
import Img6 from "../../../assets/images/icons/06-Piscina.2.png";
import Img7 from "../../../assets/images/icons/07-Multicancha.2.png";
import Img8 from "../../../assets/images/icons/08-Videojuegos.2.png";
import Img9 from "../../../assets/images/icons/09-Zona-niños.2.png";
import Img10 from "../../../assets/images/icons/10-Otros.2.png";
import Img11 from "../../../assets/images/icons/11-Mascotas.2.png";
import Img13 from "../../../assets/images/icons/13-Sauna-Turco.2.png";
import Img12 from "../../../assets/images/icons/12-Salon-social.2.png";

class PopupGrpZona extends Component {

    state = {
        img: null,
        nombre: "",
        id: 0,
        estado: "S",
    }

    componentWillMount() {
        if (this.props.opc === 2) {
            this.setState({
                img: this.props.data.Imagenid,
                nombre: this.props.data.Nombre,
                id: this.props.data.grupo,
                estado: this.props.data.estado,
            })
        }
    }

    Guardar = () => {
        if (this.state.nombre === "") {
            swal({
                title: "ATENCIÓN !!",
                text: "Debe coloar un nombre.",
                icon: "error"
            })
            return
        }
        if (this.state.img === null) {
            swal({
                title: "ATENCIÓN !!",
                text: "Debe cseleccionar un icono para la zona.",
                icon: "error"
            })
            return
        }

        //EDITAR
        if (this.props.opc === 2) {
            axios.get(Global.Ruta.Url + 'admin/updgrpzona/' + Buffer.from(Global.Ph.Codigo).toString('base64') + '/' + Buffer.from(Global.Ruta.Ipin).toString('base64') + '/' + Buffer.from(this.state.nombre).toString('base64') + '/' + Buffer.from("" + this.state.img).toString('base64') + '/' + Buffer.from("" + this.state.id).toString('base64') + '/' + Buffer.from("" + this.state.estado).toString('base64'))
                .then(res => {
                    if (res.data.message === "") {
                        swal({
                            title: "ATENCIÓN!",
                            text: "El grupo se a actualizado satisfactoriamente.",
                            icon: "success",
                            button: "Aceptar"
                        }).then(() => {
                            this.props.guardar(this.state);
                        });
                    } else if (res.data.message === "ERROR") {
                        swal({
                            title: "ERROR!",
                            text: "No fue posible actualizar el grupo, por favor vuelva a intentarlo.",
                            icon: "error",
                            button: "Aceptar"
                        }).then(() => {
                            this.Cancelar();
                        })
                    } else {
                        swal({
                            title: "ERROR!",
                            text: res.data.message,
                            icon: "error",
                            button: "Aceptar"
                        }).then(() => {
                            this.Cancelar();
                        });
                    }
                })
                .catch(err => {
                    swal({
                        title: "ERROR!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar"
                    }).then(() => {
                        this.Cancelar();
                    });
                });
            // CREAR
        } else {
            axios.get(Global.Ruta.Url + 'admin/insergrpzona/' + Buffer.from(Global.Ph.Codigo).toString('base64') + '/' + Buffer.from(Global.Ruta.Ipin).toString('base64') + '/' + Buffer.from(this.state.nombre).toString('base64') + '/' + Buffer.from("" + this.state.img).toString('base64'))
                .then(res => {
                    if (res.data.message === "") {
                        this.setState({
                            id: res.data.id
                        })
                        swal({
                            title: "ATENCIÓN!",
                            text: "El grupo se a creado satisfactoriamente.",
                            icon: "success",
                            button: "Aceptar"
                        }).then(() => {
                            this.props.guardar(this.state);
                            this.setState({
                                img: null,
                                nombre: "",
                                id: 0,
                                estado: "S",
                            })
                        });
                    } else if (res.data.message === "ERROR") {
                        swal({
                            title: "ERROR!",
                            text: "No fue posible crear el grupo, por favor vuelva a intentarlo.",
                            icon: "error",
                            button: "Aceptar"
                        }).then(() => {
                            this.Cancelar();
                        })
                    } else {
                        swal({
                            title: "ERROR!",
                            text: res.data.message,
                            icon: "error",
                            button: "Aceptar"
                        }).then(() => {
                            this.Cancelar();
                        });
                    }
                })
                .catch(err => {
                    swal({
                        title: "ERROR!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar"
                    }).then(() => {
                        this.Cancelar();
                    });
                });
        }
    }

    Cancelar = () => {
        if (this.props.opc === 2) {

            this.props.cancelar();
        } else {
            this.setState({
                img: null,
                nombre: "",
                id: 0,
                estado: "S",
            })
            this.props.cancelar();
        }
    }

    render() {

        return (
            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered>
                <CssBaseline />
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Nuevo Grupo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box sx={{ width: '100%' }}>
                        <div className="row row-cols-2">
                            <div className="col">
                                <TextField required fullWidth id="outlined-basic" label="Nombre" name="nombres" variant="outlined" value={this.state.nombre} onChange={(event) => { this.setState((state) => ({ ...state, nombre: event.target.value.toUpperCase() })) }} />
                            </div>
                            <div className="col">
                                <TextField id="cuenta-rol" required fullWidth select label="Icono" value={this.state.img} onChange={(event) => { this.setState({ img: event.target.value }) }} variant="outlined" inputProps={{ min: 0, style: { textAlign: 'center' } }} >
                                    <MenuItem value={1}><img src={Img12} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Zona zocial" /> Zona Social</MenuItem>
                                    <MenuItem value={2}><img src={Img1} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Futbol" /> Futbol</MenuItem>
                                    <MenuItem value={3}><img src={Img5} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="BBQ" /> BBQ</MenuItem>
                                    <MenuItem value={4}><img src={Img4} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Tennis" /> Tennis</MenuItem>
                                    <MenuItem value={5}><img src={Img7} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Cancha Multiple" /> Cancha Multiples</MenuItem>
                                    <MenuItem value={6}><img src={Img2} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Golf" /> Golf</MenuItem>
                                    <MenuItem value={7}><img src={Img8} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Play room" /> Play room</MenuItem>
                                    <MenuItem value={8}><img src={Img9} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Guarderia" /> Guarderia</MenuItem>
                                    <MenuItem value={9}><img src={Img11} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Baño Perros" /> Peluqueria Mascotas</MenuItem>
                                    <MenuItem value={10}><img src={Img6} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Piscina" /> Piscina</MenuItem>
                                    <MenuItem value={11}><img src={Img3} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Gym" /> Gym</MenuItem>
                                    <MenuItem value={12}><img src={Img13} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Otros" />Sauna/Turco</MenuItem>
                                    <MenuItem value={13}><img src={Img10} className="rounded bg-dark me-2" style={{ width: '2rem'}} alt="Otros" /> Otros</MenuItem>
                                </TextField>
                            </div>
                        </div>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                            <Grid item xs={12}>
                                <FormControl disabled={(this.props.opc === 1) ? (true) : (false)} component="fieldset">
                                    <FormLabel component="legend" className="tituloEstadoPopR">Estado</FormLabel>
                                    <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={this.state.estado}>
                                        <FormControlLabel value={"S"} control={<Radio onChange={(event) => { this.setState({ estado: event.target.value }) }} />} label="Activo" />
                                        <FormControlLabel value={"N"} control={<Radio onChange={(event) => { this.setState({ estado: event.target.value }) }} />} label="Inactivo" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Cancelar} variant="danger" size="lg"><i className="fas fa-times"></i> cancelar</Button>
                    <Button onClick={this.Guardar} variant="success" size="lg"><i className="fas fa-check"></i> Guardar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PopupGrpZona;