import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import MaterialTable, { MTableToolbar } from "material-table";
import ModalDispatchH from "./ModalDispatchH";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import "moment/locale/es";
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";

import { Typography, Grid, TextField, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Es from "date-fns/locale/es";

export default class DespachoDeliv extends Component {
  state = {
    tab: "1",
    data: [],
    loanding: false,
    PpHourglass: false,
    modal: false,
    modalH: false,
    modData: [],
    Cols: [],
    startDate: null,
    endDate: null,
  };

  componentWillMount() {
    this.Historial();
  }

  Historial = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_portero: Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}porteria/domicilioshistorial`, body)
      .then((res) => {
        if (res.data.message === "") {
          var cols = [
            { field: "id_domicilio", title: "id_domicilio", hidden: true },
            { field: "estado", title: "estado", hidden: true },
            { field: "nombre_portero", title: "nombre_portero", hidden: true },
            { field: "nombres", title: "Nombre" },
            { field: "direccion", title: "Dirección" },
            {
              field: "fecha_crea",
              title: "Fecha de Petición",
              render: (data) =>
                moment(data.fecha_crea).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "fecha_llegada",
              title: "Fecha Llegada",
              render: (data) =>
                moment(data.fecha_llegada).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "detalle",
              title: "Detalle",
              align: "center",
              render: (rowData) => (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handlerH(rowData)}
                    >
                      Ver Mas...
                    </button>
                  </div>
                </div>
              ),
            },
          ];

          this.setState({
            Cols: cols,
            data: res.data.data,
            loanding: true,
          });
        } else {
          this.setState({ data: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  HistorialDate = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_portero: Buffer.from(Global.GlobalUser.userid).toString("base64"),
      fechai: Buffer.from(moment(this.state.startDate).format("DD/MM/YYYY")).toString("base64"),
      fechaf: Buffer.from(moment(this.state.endDate).format("DD/MM/YYYY")).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}porteria/domicilioshistorialdate`, body)
      .then((res) => {
        if (res.data.message === "") {
          var cols = [
            { field: "id_domicilio", title: "id_domicilio", hidden: true },
            { field: "estado", title: "estado", hidden: true },
            { field: "nombre_portero", title: "nombre_portero", hidden: true },
            { field: "nombres", title: "Nombre" },
            { field: "direccion", title: "Dirección" },
            {
              field: "fecha_crea",
              title: "Fecha de Petición",
              render: (data) =>
                moment(data.fecha_crea).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "fecha_llegada",
              title: "Fecha Llegada",
              render: (data) =>
                moment(data.fecha_llegada).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "detalle",
              title: "Detalle",
              align: "center",
              render: (rowData) => (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handlerH(rowData)}
                    >
                      Ver Mas...
                    </button>
                  </div>
                </div>
              ),
            },
          ];

          this.setState({
            Cols: cols,
            data: res.data.data,
            loanding: true,
          });
        } else {
          this.setState({ data: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  handlerH = (data) => {
    this.setState({ modalH: true, modData: data });
  };

  Aprobar = (data) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_domicilio: Buffer.from("" + data.id_domicilio).toString("base64"),
      id_portero: Buffer.from(Global.GlobalUser.userid).toString("base64"),
      id_inmueble: Buffer.from("" + data.id_inmueble).toString("base64"),
    };
    axios
      .post(`${Global.Ruta.Url}porteria/entregadomicilio`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            PpHourglass: false,
          });
          swal({
            title: "",
            text: "Domicilio Entregado",
            icon: "success",
            button: "Aceptar",
          }).then(() => this.Cerrar(data.id_domicilio));
        } else {
          this.setState({ PpHourglass: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
            PpHourglass: false,
          });
        });
      });
  };

  Cerrar = (id_paquete) => {
    this.setState({ modal: false, loanding: false });
    var tem = this.state.data;
    var index = this.IndexCambio(id_paquete, tem);

    tem.splice(index, 1);
    this.setState({
      data: tem,
    });

    setTimeout(() => {
      this.setState({
        loanding: true,
      });
    }, 150);
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_domicilio) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  render() {
    const ModalH = () => {
      return (
        <Modal
          show={this.state.modalH}
          centered
          backdrop={"static"}
          onHide={() => this.setState({ modalH: false })}
        >
          <ModalDispatchH infoDispatch={this.state.modData} dependencia={2} />
        </Modal>
      );
    };

    return (
      <div className="container-fluid bg-light h-100">
        <Modal
          show={this.state.PpHourglass}
          centered
          backdrop="static"
          onHide={() => this.setState({ PpHourglass: false })}
        >
          <PpHourglass />
        </Modal>
        <ModalH />
        <div className="row h-100 py-3">
          <div className="col-12">
            <div className="card h-100 shadow g-r-prf2">
              <div className="card-body">
                {this.state.loanding ? (
                  <MaterialTable
                    title={``}
                    columns={this.state.Cols}
                    data={this.state.data}
                    options={{
                      pageSize:
                        this.state.data.length < 20
                          ? this.state.data.length
                          : 20,
                      pageSizeOptions: [
                        this.state.data.length < 20
                          ? this.state.data.length
                          : 20,
                      ],
                      actionsColumnIndex: -1,
                      padding: "dense",
                      search: true,
                      exportButton: true,
                      exportAllData: true,
                      exportDelimiter: ";",
                      exportFileName: (`Informe de Domicilios, ${moment(new Date()).format('LL')}`),
                      rowStyle: (rowData) => {
                        if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                          return {
                            backgroundColor: "#eeeeee",
                            fontSize: "0.9rem",
                            textTransform: "uppercase",
                          };
                        }
                        return {
                          backgroundColor: "#ffffff",
                          fontSize: "0.9rem",
                          textTransform: "uppercase",
                        };
                      },
                    }}
                    localization={{

                      pagination: {
                        labelRowsSelect: 'registros',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                      },
                      toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "CSV",
                        exportPDFName: "PDF"
                      }
                    }}
                    components={{
                      Toolbar: props => (
                        <div>
                          <MTableToolbar {...props} />
                          <div style={{ padding: '0px 10px' }}>

                            <div className="row pb-3">
                              <div className="col-4">
                                <div className="position-relative s-r-option d-flex align-item-center" style={{ zIndex: 23 }}>
                                  <Accordion className="absolute-swt shadow-sm" >
                                    <AccordionSummary
                                      className=""
                                      expandIcon={<i className="fas fa-chevron-down"></i>}
                                      aria-controls="panel3a-content"
                                      id="panel3a-header"
                                    >
                                      <Typography>Fecha</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-light shadow">
                                      <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={Es}
                                          >
                                            <DatePicker
                                              label="fecha inicio"
                                              value={this.state.startDate}
                                              maxDate={new Date()}
                                              onChange={(date) => {
                                                this.setState({ startDate: date });
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  className="barraCalendar"
                                                  size="small"
                                                  {...params}
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={Es}
                                          >
                                            <DatePicker
                                              label="fecha final"
                                              value={this.state.endDate}
                                              minDate={this.state.startDate}
                                              maxDate={new Date()}
                                              onChange={(date) => {
                                                this.setState({ endDate: date });
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  className="barraCalendar"
                                                  size="small"
                                                  {...params}
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </div>
                              <div className="col">
                                <button className="btn btn-primary text-nowrap btn-sm mx-3" disabled={this.state.startDate === null || this.state.endDate === null ? (true) : (false)} onClick={this.HistorialDate}> Buscar </button>
                                <button className="btn btn-danger text-nowrap btn-sm mx-3" onClick={this.Historial}> Limpiar </button>
                              </div>
                            </div>

                          </div>
                        </div>
                      ),
                    }}
                  />
                ) : (
                  <div className="d-flex justify-content-center bg-light text-info flex-column align-items-center h-100">
                    <div
                      className="spinner-border fs-3 m-3"
                      style={{ width: "5rem", height: "5rem" }}
                      role="status"
                    ></div>
                    <p className="fs-3">Cargando...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
