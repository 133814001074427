import React, { Component } from "react";
import axios from "axios";
import Global from "../../../../Global";
import swal from "sweetalert";
import { CookieSesion } from "../../../Helpers";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";
import { Modal } from 'react-bootstrap';
import moment from "moment";
import "moment/locale/es";
import { TextField } from "@material-ui/core";
import ModIngresoPlaca from './ModIngresoPlaca'
import SimpleReactValidator from 'simple-react-validator'
import Loader from "../../../principal/Loader";
import { removeDiacritics } from "../../../Helpers"


const columns = [
  {
    field: "id",
    title: "Id",
    hidden: true,
  },
  {
    field: "direccion",
    title: "Dirección",
  },
  {
    field: "placa",
    title: "Placa",
  },
  {
    field: "fecha_llega",
    title: "Fecha",
    render: data => moment(data.fecha_llega).format("DD MMM YYYY HH:mm:ss"),

  },
  {
    field: "tipo_residente",
    title: "Tipo",
    lookup: { 0: "RESIDENTE", 1: "VISITANTE" },
  },
  {
    field: "tipo_vehiculo",
    title: "Vehículo",
    lookup: { 1: "AUTOMÓVIL", 2: "MOTOCICLETA", 3: "TAXI", 4: "DOMICILIO" },
  },
];

class DataTablePlacas extends Component {

  state = {
    redirect_home: false,
    redirect_login: 0,
    data: false,
    PpHourglass: false,
    modal1: false,
    modalPlaca: false,
    Vehiculos: [],
    Inmuebles: [],
    Placa: "",
    Rol: 0,

    // formulario placa
    inmueble: '',
    tipo_vehiculo: '',
    ParaBusqueda: [],
    Busqueda: "",
  }

  componentWillMount() {

    this.validator = new SimpleReactValidator({
      messages: {
        required: 'El campo es requerido.',
        email: 'Correo Invalido',
        alpha_num: 'No puede Contener Caracteres',
        numeric: 'solo puede ingresar numeros enteros',
        integer: 'solo puede ingresar numeros enteros',
        min: 'Debe Ingresar mínimo 5 caracteres',
        max: 'Puede Ingresar Máximo 10 caracteres',
      }
    });
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    var userRol = Global.GlobalUser.rol;
    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (parseInt(userRol) === 4 || parseInt(userRol) === 1 || parseInt(userRol) === 2) {
          var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
          }
          axios.post(`${Global.Ruta.Url}porteria/historialingresoplacas/`, body).then((res) => {
            if (res.data.message === "") {
              this.setState({
                Vehiculos: res.data.Registros,
                ParaBusqueda: res.data.Registros,
              })
              this.BuscaInmueble()
            } else {
              this.BuscaInmueble()
            }
          }).catch((err) => {
            swal({
              title: "ALERTA!",
              text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
              icon: "error",
              button: "Aceptar"
            }).then(e => {
              this.setState({ redirect_home: true })
            });
          });
        } else {
          this.setState({ redirect_home: true })
        }
      } else {
        this.setState({ redirect_login: 1 })
      }
    } else {
      this.setState({ redirect_login: 1 })
    }
  }

  BuscaInmueble = () => {
    var body = {
      ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
      porteroid: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
    };
    axios.post(`${Global.Ruta.Url}admin/listinm`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Inmuebles: res.data.Inmuebles,
          data: true,
        });
      } else {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      }
    })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  BuscaPlaca = () => {
    if (this.validator.allValid()) {
      this.setState({ PpHourglass: true })
      var body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        placa: Buffer.from("" + this.state.Placa).toString("base64"),
        id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
      };
      axios.post(`${Global.Ruta.Url}porteria/buscaplaca/`, body).then((res) => {
        if (res.data.message === "") {
          this.InsertarVehiculo(res.data.RegistroVehiculo)
        } else {
          this.setState({ PpHourglass: false });
          if (parseInt(res.data.caso) > 0) {
            swal({
              title: "ATENCIÓN!",
              text: `${res.data.message}`,
              icon: "warning",
              button: "Aceptar",
            })
          } else {
            this.setState({ modalPlaca: true })
          }
        }
      })
        .catch((err) => {
          swal({
            title: "ATENCIÓN!",
            text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true, PpHourglass: false });
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  CambiaPlaca = (data) => {
    this.setState({ Placa: data })
  }

  CambiaInmueble = (data) => {
    this.setState({ inmueble: data })
  }

  CambiaInmueble = (data) => {
    this.setState({ inmueble: data })
  }

  CambiaTipoVihiculo = (data) => {
    this.setState({ tipo_vehiculo: data })
  }

  PlacaVisitante = () => {
    this.setState({ PpHourglass: true, modalPlaca: false })
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      placa: Buffer.from("" + this.state.Placa).toString("base64"),
      id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
      inmueble: Buffer.from("" + this.state.inmueble).toString("base64"),
      tipo_vehiculo: Buffer.from("" + this.state.tipo_vehiculo).toString("base64"),
    };
    axios.post(`${Global.Ruta.Url}porteria/ingresaplacavisitante/`, body).then((res) => {
      if (res.data.message === "") {
        this.InsertarVehiculo(res.data.RegistroVehiculo)
      } else {
        this.setState({ PpHourglass: false, modalPlaca: false });
        swal({
          title: "ATENCIÓN!",
          text: `${res.data.message}`,
          icon: "warning",
          button: "Aceptar",
        })
      }
    })
      .catch((err) => {
        swal({
          title: "ATENCIÓN!",
          text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_home: true, PpHourglass: false, modalPlaca: false });
        });
      });
  }

  InsertarVehiculo = (data) => {
    var tem1 = this.state.Placa
    var tem = this.state.Vehiculos;
    tem.push(data);
    this.setState({ Vehiculos: tem, ParaBusqueda: tem, PpHourglass: false, Placa: '' });
    swal({
      title: "ATENCIÓN!",
      text: `Placa ${tem1} Ingresada en el Inmueble ${data.direccion}`,
      icon: "success",
      button: "Aceptar",
      timer: 800
    })
  };

  SalidaPlaca = (data, index1) => {
    var id = data.id

    var index = ""

    if (this.props.caso === undefined) {
      index = data.tableData.id
    } else {
      index = index1
    }

    this.setState({ PpHourglass: true })
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      idplaca: Buffer.from("" + id).toString("base64"),
      id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),

    };
    axios.post(`${Global.Ruta.Url}porteria/salidaplaca/`, body).then((res) => {
      if (res.data.message === "") {
        this.EliminarVehiculo(index)
      } else {
        this.setState({ PpHourglass: false });
        swal({
          title: "ATENCIÓN!",
          text: `${res.data.message}`,
          icon: "warning",
          button: "Aceptar",
        })
      }
    }).catch((err) => {
      swal({
        title: "ATENCIÓN!",
        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({ redirect_home: true, PpHourglass: false });
      });
    });
  }

  EliminarVehiculo = (indice) => {
    var tem = this.state.Vehiculos;
    tem.splice(indice, 1);
    if (this.props.caso === undefined) {
      this.setState({
        Vehiculos: tem,
        PpHourglass: false,
      });
    } else {

      this.setState({
        data: false
      }, () => {
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        }
        axios.post(`${Global.Ruta.Url}porteria/historialingresoplacas/`, body).then((res) => {
          if (res.data.message === "") {
            this.setState({
              Vehiculos: res.data.Registros,
              ParaBusqueda: res.data.Registros,
              PpHourglass: false,
              Busqueda: "",
              data: true
            })
          } else {
            this.setState({
              Vehiculos: [],
              ParaBusqueda: [],
              PpHourglass: false,
              Busqueda: "",
              data: true
            })
          }
        }).catch((err) => {
          swal({
            title: "ALERTA!",
            text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
            icon: "error",
            button: "Aceptar"
          }).then(e => {
            this.setState({ redirect_home: true })
          });
        });

      });
    }



  }

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value
    }));
    this.setState({
      Vehiculos: this.filterItems(event.target.value, this.state.ParaBusqueda)
    })
  }

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return (
        removeDiacritics(data.placa).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1);
    })
  }

  render() {

    if (this.state.redirect_home === true) {
      return (
        <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
      )
    }

    if (this.state.redirect_login === 1) {
      return (
        <Redirect to={Global.Ruta.Raiz + '/'} />
      )
    }

    if (this.state.data === true && this.props.caso === undefined) {
      return (
        <div className="container-fluid bg-light">
          <Modal show={this.state.modalPlaca} centered backdrop="static" onHide={() => this.setState({ modalPlaca: false })}>
            <ModIngresoPlaca Placa={this.state.Placa} PlacaVisitante={this.PlacaVisitante} CambiaInmueble={this.CambiaInmueble}
              CambiaPlaca={this.CambiaPlaca} Inmuebles={this.state.Inmuebles} CambiaTipoVihiculo={this.CambiaTipoVihiculo} />
          </Modal>

          <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>
          <div className="row py-2">
            <div className="col-12">
              <MaterialTable
                columns={columns}
                data={this.state.Vehiculos}
                title={
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <h5 className="m-0">Placas</h5>
                    </div>
                    <div className="col">
                      <TextField label='Entrada' value={this.state.Placa} variant="standard" size="small" onChange={(e) => this.setState({ Placa: e.target.value.toUpperCase() })}
                        // inputProps={{ startAdornment: (<i className="fa-solid fa-search" />), maxLength: 10 }}
                        inputProps={{ maxLength: 10 }}
                        onKeyPress={(e) => { if (e.key === "Enter") { this.BuscaPlaca() } }}
                      />
                      <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Placa ', this.state.Placa, 'required|alpha_num|min:5|max:10', { className: 'text-danger' })}</label>
                    </div>
                  </div>
                }

                actions={[
                  {
                    onClick: (event, rowData) => this.SalidaPlaca(rowData)
                  }
                ]}
                components={{
                  Action: props => (
                    <button className="btn btn-danger rounded-pill mx-3" onClick={(event) => props.action.onClick(event, props.data)} color="primary"
                      aria-label="edit" size="small" >
                      <div className="d-flex gap-2 align-items-center">
                        <i className="fa-solid fa-close" />
                        <span>Salida</span>
                      </div>
                    </button>
                  ),
                }}
                options={{
                  pageSize: (parseInt(this.state.Rol) === 4) ? (this.state.Vehiculos.length < 11 ? this.state.Vehiculos.length : 10) : (this.state.Vehiculos.length < 11 ? this.state.Vehiculos.length : 10),
                  pageSizeOptions: (parseInt(this.state.Rol) === 4) ? ([this.state.Vehiculos.length < 11 ? this.state.Vehiculos.length : 10]) : ([this.state.Vehiculos.length < 11 ? this.state.Vehiculos.length : 10]),
                  paging: (parseInt(this.state.Rol) === 4) ? (this.state.Vehiculos.length >= 11 ? true : false) : (this.state.Vehiculos.length >= 5 ? true : false),
                  actionsColumnIndex: -1,
                  search: true,
                  exportButton: (parseInt(this.state.Rol) === 4) ? (false) : (true),
                  exportAllData: (parseInt(this.state.Rol) === 4) ? (false) : (true),
                  exportDelimiter: (parseInt(this.state.Rol) === 4) ? ("") : (";"),
                  exportFileName: (parseInt(this.state.Rol) === 4) ? ("") : (`Informe de vehículos, ${moment(new Date()).format('LL')}`),
                  rowStyle: (rowData) => {
                    if (
                      parseInt(rowData.tableData.id + 1) % 2 ===
                      0
                    ) {
                      return {
                        backgroundColor: "#eeeeee",
                        fontSize: "0.9rem",
                      };
                    }
                    return {
                      backgroundColor: "#ffffff",
                      fontSize: "0.9rem",
                    };
                  },
                }}
                localization={{
                  header: {
                    actions: 'Salida',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay datos por mostrar.',
                  },
                  pagination: {
                    labelRowsSelect: 'registros',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Próxima página',
                    lastTooltip: 'Última página'
                  },
                  toolbar: {
                    searchTooltip: 'Salida',
                    searchPlaceholder: 'Salida',
                    exportCSVName: "CSV",
                    exportPDFName: "PDF"
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.data === true && this.props.caso !== undefined) {
      return (
        <React.Fragment>

          <Modal show={this.state.modalPlaca} centered backdrop="static" onHide={() => this.setState({ modalPlaca: false })}>
            <ModIngresoPlaca Placa={this.state.Placa} PlacaVisitante={this.PlacaVisitante} CambiaInmueble={this.CambiaInmueble}
              CambiaPlaca={this.CambiaPlaca} Inmuebles={this.state.Inmuebles} CambiaTipoVihiculo={this.CambiaTipoVihiculo} />
          </Modal>


          <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>

          <div className="row align-items-center mb-3">
            <div className="col">
              <TextField autoFocus
                size="small"
                fullWidth
                margin="normal"
                placeholder="Ingrear Placa"
                variant="outlined"
                value={this.state.Placa}
                defaultValue={this.state.Placa}
                onChange={(e) => this.setState({ Placa: e.target.value.toUpperCase() })}
                // inputProps={{ startAdornment: (<i className="fa-solid fa-search" />), maxLength: 10 }}
                inputProps={{ maxLength: 10 }}
                onKeyPress={(e) => { if (e.key === "Enter") { this.BuscaPlaca() } }}
              />
            </div>

            <div className="col">
              <TextField autoFocus
                size="small"
                fullWidth
                margin="normal"
                placeholder="Buscar Placa"
                variant="outlined"
                value={this.state.Busqueda}
                defaultValue={this.state.Busqueda}
                onChange={this.handleChange("Busqueda")}
              />

            </div>
            <label className='text-muted text-capitalize m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message('Placa ', this.state.Placa, 'required|alpha_num|min:5|max:10', { className: 'text-danger' })}</label>
          </div>

          <div className="w-100 h-100 position-relative align-items-start overflow-x-hidden custom-scroll">
            <div className=" rounded bg-light mx-2 mb-3 d-flex flex-column position-relative">
              <div className="h-100 position-relative align-items-start" style={{ maxHeight: "89%" }}>
                <div className="h-100" style={{ padding: "0px 0px 0px 5px" }}>
                  {this.state.Vehiculos.map((data, index) => (

                    <div className="row border  border-secondary rounded bg-white my-2" key={index} style={{ marginInline: "0px", width: "95%" }} >
                      <div className="col" style={{ minWidth: "cal(100%-45px)" }}>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>{data.placa}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{data.direccion}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{moment(data.fecha_llega).format("DD MMM YYYY HH:mm:ss")}</span>
                      </div>
                      <div className=" d-flex align-items-center justify-content-center" style={{ maxWidth: "45px" }}>
                        <button className="btn btn-danger rounded-circle btn-sm" onClick={() => this.SalidaPlaca(data, index)}>
                          <i className="fa-solid fa-close" />
                        </button>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>

        </React.Fragment>
      )

    }

    return (
      <Loader princial={true} uno={"Vehículos"} dos={"Datos"} tres={"Placas"} />
    );
  }
}

export default DataTablePlacas;