import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

export default class ListaRespAut extends Component {

    state = {
        // items
        items: ['Llegó la factura del servicio de energía.', 'Llegó la factura del servicio de agua.', ' la factura del servicio de gas.',
            'Llegó la factura de Claro.', 'Llegó la factura de Tigo.', 'Llegó la factura de Movistar.', 'Llegó la factura de ETB.',
            'Llegó la factura del impuesto predial.', 'Llegó la factura del impuesto de valorización.'],
    }

    render() {
        return (
            <Modal.Body>
                <div className='card'>
                    <div className='card-header d-flex align-items-center'>
                        <p className='m-0 font-weight-normal'>Respuestas Automaticas</p>
                        <button className='btn rounded-circle ml-auto' onClick={this.props.onClose}><i className='fas fa-times fa-1x' /></button>
                    </div>
                    <div className='card-body'>
                        <ul className="list-group list-group-flush">
                            {this.state.items.map((item, indice) => (
                                <li className="list-group-item d-flex">{item}
                                    <button className='btn btn-primary ml-auto' onClick={() => this.props.enviar(parseInt(indice) + 1, item)}>
                                        ENVIAR</button></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        );
    }
}
