import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class CardSolicitudesMovil extends Component {
    render() {
        const data = this.props
        return (
            <NavLink exact to={data.url} className="card-body" key={data.key1} >
                <i className={`bi ${data.icono}`} title={data.titulo}></i>
                <h5>{data.valor}</h5>
            </NavLink>
        )
    }
}

CardSolicitudesMovil.defaultProps = {
    titulo: "na",
    valor: 0,
    icono: "",
    key1: 0,
    url: '/'
};