import React, { Component } from "react";
import "./../../../assets/css/admin.css";

//import de componentes
import HeaderA2 from "./../Home/HeaderA2";

import { ThemeProvider, createTheme } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import Global from "../../../Global";
import { ValidarRolPagina } from "../../Helpers";
import DataTablePlacas from "./Vehiculos/TablePlacas";

const theme = createTheme({
  palette: {
      secondary: {
          main: '#4c944a'
      },
      light:{
        main:'white'
      }
  },
});


export default class PagePlacasV extends Component {
  state = {
    redirect_home: false
  }
  componentWillMount() {
    this.setState({ redirect_home: ValidarRolPagina('Placas') })
  }
  render() {
    if (this.state.redirect_home === true) {
      return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
    }
    return (
      <div className="bg-light vh-100">
        <ThemeProvider theme={theme}>
          <HeaderA2 />
          <DataTablePlacas />

        </ThemeProvider>
      </div>
    );
  }
}
