// FUNCIONES

import { Buffer } from 'buffer';
import Cookie from 'universal-cookie';
import Global from '../Global'

import axios from 'axios';
import swal from 'sweetalert';

const cookies = new Cookie();

export function capitalizeFirstLetter(string) {
    var string1 = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string1.slice(1);
}

export function horaInicioAsamblea(VarTimer) {
    const moment = require("moment");
    let startDate = moment(VarTimer);
    const endDate = moment();

    const duration = moment.duration(startDate.diff(endDate))
    return duration.asSeconds() * 1000;
}

// Set a Cookie PRODUCCION
export function setCookieP(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    var VarToCrypt = cValue.toString('utf8');
    var encrypted = Buffer.from(VarToCrypt).toString('hex');
    document.cookie = cName + "=" + encrypted + ";" + expires + ";path=/ ;samesite=lax; secure";
}

// Set a Cookie DESARROLLO
export function setCookieD(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    var VarToCrypt = cValue.toString('utf8');
    var encrypted = Buffer.from(VarToCrypt).toString('hex');
    document.cookie = cName + "=" + encrypted + ";" + expires + ";path=/";
}


// Leer Cookie Aceptacion
export function AceptaDeclinaCookie() {

    if (cookies.get('onAccept')) {
        return Buffer.from(cookies.get('onAccept'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Sesion
export function CookieSesion() {
    if (cookies.get('Sesion')) {
        return Buffer.from(cookies.get('Sesion'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie PH Nombre
export function CookieNombrePH() {
    if (cookies.get('Conjunto')) {
        return Buffer.from(cookies.get('Conjunto'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie PH Código
export function CookiePhCodigo() {
    if (cookies.get('PhCodigo')) {
        return Buffer.from(cookies.get('PhCodigo'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Suscriptor ID
export function CookieSuscId() {
    if (cookies.get('SuscId')) {
        return Buffer.from(cookies.get('SuscId'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Suscriptor Nombre
export function CookieNombre() {
    if (cookies.get('Nombre')) {
        return Buffer.from(cookies.get('Nombre'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Suscriptor Direccion
export function CookieDireccion() {
    if (cookies.get('Direccion')) {
        return Buffer.from(cookies.get('Direccion'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Suscriptor IdInmueble
export function CookieIdInmueble() {
    if (cookies.get('IdInmueble')) {
        return Buffer.from(cookies.get('IdInmueble'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Suscriptor Tipo
export function CookieTipo() {
    if (cookies.get('Tipo')) {
        return Buffer.from(cookies.get('Tipo'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie Suscriptor Coeficiente
export function CookieCoef() {
    if (cookies.get('Cfptc')) {
        return Buffer.from(cookies.get('Cfptc'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

// Leer Cookie usuario id
export function CookieUserId() {
    if (cookies.get('UserId')) {
        return Buffer.from(cookies.get('UserId'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

//Leer Cookie ROL
export function CookieRol() {
    if (cookies.get('Rol')) {
        return Buffer.from(cookies.get('Rol'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

//Leer Cookie NIT DE LA PH
export function CookieNitph() {
    if (cookies.get('Nitph')) {
        return Buffer.from(cookies.get('Nitph'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

//Leer Cookie ciudad de la ph
export function CookieCiudad() {
    if (cookies.get('Ciudad')) {
        return Buffer.from(cookies.get('Ciudad'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}

//Leer Cookie Rondero
export function CookieRondero() {
    if (cookies.get('Rondero')) {
        return Buffer.from(cookies.get('Rondero'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}


//Leer Cookie Supervisor
export function CookieSupervisor() {
    if (cookies.get('Supervisor')) {
        return Buffer.from(cookies.get('Supervisor'), 'hex').toString('utf8');
    } else {
        return 0;
    }
}


// TIPO DE ASAMBLEA
export function TipoAsamblea(VarTipo) {
    switch (VarTipo) {
        case 1:
            return "Asamblea ordinaria presencial."
        case 2:
            return "Asamblea extraordinaria presencial."
        case 3:
            return "Asamblea ordinaria no presencial."
        case 4:
            return "Asamblea extraordinaria no presencial."
        case 5:
            return "Capacitación no presencial."
        case 6:
            return "Continuación asamblea presencial."
        case 7:
            return "Continuación asamblea no presencial."
        default:
            return "GoPH Asambleas Virtuales y Presenciales."
    }
}

// RETORNA EL TIPO DE CONVOCATORIA
export function TipoConvocatoria(VarConvocatoria) {
    switch (VarConvocatoria) {
        case 1:
            return " Primera Convocatoria."
        case 2:
            return " Segunda Convocatoria."
        default:
            return ""
    }
}

export function ValidarRolPagina(VarPagina) {
    var VarPage = []
    switch (parseInt(Global.GlobalUser.rol)) {
        case 1:
            VarPage = ['Asamblea', 'HomeAsamblea', 'Poderes', 'Preguntas', 'Asistencia', 'Morosos', 'Votacion', 'NavbarInformes', 'CrearPregunta',
                'ZonasSociales', 'Reserva', 'UserPage', 'FormUser', 'SuscPage', 'FormSusc', 'SubirArchivo', 'RevisarArchivo', 'SistemaMensajes', 'Autorizaciones',
                'peticiones', 'HistorialPeticiones', 'LibroPropietarios', 'Reportes', 'Notas', 'PorteriaComunicacion', 'Rondas'
            ]
            if (VarPage.findIndex(Element => Element === VarPagina) === -1) {
                return true
            } else {
                return false
            }
        case 2:
            VarPage = ['Asamblea', 'HomeAsamblea', 'NavbarInformes', 'ZonasSociales', 'Reserva', 'UserPage', 'FormUser', 'SuscPage', 'FormSusc', 'SubirArchivo', 'RevisarArchivo',
                'SistemaMensajes', 'Autorizaciones', 'peticiones', 'HistorialPeticiones', 'LibroPropietarios',
                'Reportes', 'Notas', 'PorteriaComunicacion', 'Rondas'
            ]
            if (VarPage.findIndex(Element => Element === VarPagina) === -1) {
                return true
            } else {
                return false
            }
        case 3:
            VarPage = ['Asamblea', 'HomeAsamblea', 'Poderes', 'Asistencia', 'Morosos', 'Votacion', 'SuscPage', 'FormSusc']
            if (VarPage.findIndex(Element => Element === VarPagina) === -1) {
                return true
            } else {
                return false
            }
        case 4:
            VarPage = ['SuscPage', 'Reserva', 'Autorizaciones', 'SistemaMensajes', 'Vehiculos', 'Porteria', 'Placas', 'Notas', 'PorteriaComunicacion']
            if (VarPage.findIndex(Element => Element === VarPagina) === -1) {
                return true
            } else {
                return false
            }
        case 5:
            VarPage = ['Reserva']
            if (VarPage.findIndex(Element => Element === VarPagina) === -1) {
                return true
            } else {
                return false
            }
        default:
            return true
    }
}

export function CambiarUserPorteria(codigo, login, pwd) {

    var Plataforma = Global.Plataforma.Valor;

    var user = {
        codigoph: "",
        usuario: "",
        password: ""
    }
    // CIFRAR
    user.codigoph = Buffer.from(codigo).toString('base64');
    user.usuario = Buffer.from(login).toString('base64');
    user.password = Buffer.from(pwd).toString('base64');
    var ipin = Buffer.from(Global.Ruta.Ipin).toString('base64');

    axios.get(`${Global.Ruta.Url}login/loginsusc/${user.codigoph}/${ipin}/${user.usuario}/${user.password}`).then(res => {

        if (res.data.message === "") {
            if (parseInt(res.data.rol) > 0) {
                if (parseInt(res.data.Estadoph) === 0) {
                    swal({
                        title: "ATENCION !!",
                        text: "Error de acceso, por favor comuníquese con INGGO DEVELOPMENTS en comercial@inggos.com",
                        icon: "error"
                    });
                    return
                }
                // DATOS DE LA PH
                Global.Ph.Codigo = res.data.CodePh;
                Global.Ph.Nombre = res.data.Nombreph;
                Global.Ph.Nitph = res.data.Nitph;
                Global.Ph.Ciudadph = res.data.Ciudadph;
                Global.Ph.Estadoph = parseInt(res.data.Estadoph);

                // DATOS DEL USUARIO
                Global.GlobalUser.userid = "" + parseInt(res.data.userid);
                Global.GlobalUser.rol = "" + parseInt(res.data.rol);

                if (res.data.Nomb !== "") {
                    Global.GlobalUser.Nombre = res.data.Nomb
                } else {
                    switch (parseInt(res.data.rol)) {
                        case 1:
                            Global.GlobalUser.Nombre = "ADMINISTRADOR"
                            break;
                        case 2:
                            Global.GlobalUser.Nombre = "NA"
                            break;
                        case 3:
                            Global.GlobalUser.Nombre = "ASISTENTE"
                            break;
                        case 4:
                            Global.GlobalUser.Nombre = "PORTERIA"
                            break;
                        default:
                            Global.GlobalUser.Nombre = "ADMINISTRADOR"
                    }
                }

                // 1 DESARROLLO - 2 PRODUCCION 
                if (Plataforma === 1) {
                    setCookieD('Sesion', "1", 1);
                    // DATOS DEL USUARIO
                    setCookieD('UserId', "" + Global.GlobalUser.userid, 1);
                    setCookieD('Nombre', Global.GlobalUser.Nombre, 1);
                    setCookieD('Rol', "" + Global.GlobalUser.rol, 1);
                    // DATOS DE LA PH
                    setCookieD('PhCodigo', "" + Global.Ph.Codigo, 1);
                    setCookieD('Conjunto', Global.Ph.Nombre, 1);
                    setCookieD('Nitph', "" + Global.Ph.Nitph, 1);
                    setCookieD('Ciudad', Global.Ph.Ciudadph, 1);
                    setCookieD('Tipo', "" + Global.Ph.Estadoph, 1);
                } else if (Plataforma === 2) {
                    setCookieD('Sesion', "1", 1);
                    // DATOS DEL USUARIO
                    setCookieP('UserId', "" + Global.GlobalUser.userid, 1);
                    setCookieP('Nombre', Global.GlobalUser.Nombre, 1);
                    setCookieP('Rol', "" + Global.GlobalUser.rol, 1);
                    // DATOS DE LA PH
                    setCookieP('PhCodigo', "" + Global.Ph.Codigo, 1);
                    setCookieP('Conjunto', Global.Ph.Nombre, 1);
                    setCookieP('Nitph', "" + Global.Ph.Nitph, 1);
                    setCookieP('Ciudad', Global.Ph.Ciudadph, 1);
                    setCookieP('Tipo', "" + Global.Ph.Estadoph, 1);
                }
            }
        } else {
            swal({
                title: "ATENCION !!",
                text: res.data.message,
                icon: "error"
            });
        }
    });
}

export function removeDiacritics(text) {
    return text.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function CerrarSesion() {
    cookies.remove("Sesion", { path: "/" });
    cookies.remove("UserId", { path: "/" });
    cookies.remove("Nombre", { path: "/" });
    cookies.remove("Rol", { path: "/" });
    cookies.remove("PhCodigo", { path: "/" });
    cookies.remove("Conjunto", { path: "/" });
    cookies.remove("Nitph", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    cookies.remove("Tipo", { path: "/" });
    cookies.remove("Rondero", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    Global.NotifHome.View = false;
    Global.NotifHome.FirtsEntry = false;
    Global.NotifHome.Pendientes = [];
};