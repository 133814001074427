import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { TextField, Autocomplete, FormControl } from "@mui/material";
import SimpleReactValidator from 'simple-react-validator'

export default class ModIngresoPlaca extends Component {

    state = {
        placaf: '',
        inmueblef: '',
        Tipo: [{ id: 1, tipo: "AUTOMÓVIL" }, { id: 2, tipo: "MOTOCICLETA" }, { id: 3, tipo: "TAXI" }, { id: 4, tipo: "DOMICILIO" }],
        tipo_vehiculof: '',
    }

    componentWillMount() {

        this.setState({ placaf: this.props.Placa })

        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'Debe Ingresar mínimo 5 caracteres',
                max: 'Puede Ingresar Máximo 10 caracteres',
            }
        });
    }


    CambiaInmuebleF = (data) => {
        this.setState({ inmueblef: data })
        this.props.CambiaInmueble(data)
    }

    CambiaPlacaF = (data) => {
        this.setState({ placaf: data })
        this.props.CambiaPlaca(data)
    }


    CambiaTipoVihiculoF = (data) => {
        this.setState({ tipo_vehiculof: data })
        this.props.CambiaTipoVihiculo(data)
    }


    PlacaVisitanteF = () => {
        if (this.validator.allValid()) {
            this.props.PlacaVisitante()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    render() {
        return (
            <div>
                <Modal.Header closeButton>
                    {`Ingresar Nueva Placa ${this.state.placaf }`}
                </Modal.Header>
                <Modal.Body>
                    <form className="row justify-content-center gy-2">
                        <div className="col-12">
                            <TextField
                                disabled
                                inputProps={{ maxLength: 10 }}
                                value={this.props.Placa}
                                label='Placa'
                                fullWidth
                            // onChange={(e) => this.CambiaPlacaF(e.target.value.toUpperCase())}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Placaf ', this.state.placaf, 'required|alpha_num|min:5|max:10', { className: 'text-danger' })}</label>
                        </div>
                        <div className="col-12">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    id="combo-box-demo"
                                    options={this.props.Inmuebles}
                                    getOptionLabel={(option) => option.direccion}
                                    onChange={(event, value) => this.CambiaInmuebleF(value.inmuebleid)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Dirección" />
                                    )}
                                />
                            </FormControl>
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Inmueblef', this.state.inmueblef, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className="col-12">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    id="combo-box-demo"
                                    options={this.state.Tipo}
                                    getOptionLabel={(option) => option.tipo}
                                    onChange={(event, value) => this.CambiaTipoVihiculoF(value.id)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Tipo Vehículo" />
                                    )}
                                />
                            </FormControl>
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('tipo_vehiculof', this.state.tipo_vehiculof, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className="col-12 mt-4">
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 justify-content-center">
                        <div className="col-3 d-grid">
                            <button className="btn btn-primary" onClick={this.PlacaVisitanteF}>Guardar</button>
                        </div>
                    </div>
                </Modal.Footer>

            </div >
        )
    }
}
