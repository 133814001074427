import React, { Component } from 'react'
import { ValidarRolPagina } from '../Helpers';
import HeaderA2 from './Home/HeaderA2';
import ReportesComp from './reportes/ReportesComp';
import Global from './../../Global';
import { Redirect } from 'react-router-dom';


export default class Reportes extends Component {
  state = {
    redirect_home: false,
  };
  componentWillMount() {
    this.setState({redirect_home: ValidarRolPagina("Reportes")});
  }
  render() {


    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }
    return (
      <div className="g-r-prf3 bg-light vh-100">
        <HeaderA2 />
        <ReportesComp/>
      </div>
    );
  }
}