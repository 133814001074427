//VARIABLE GLOBAL DONDE DENTRO DE GLOBAL DEBE IR TODAS LAS VARIABLES GLOBALES

var Global = {

  Ph: {
    Codigo: "",
    Nombre: "",
    Nitph: "",
    Ciudadph: "",
    Estadoph: 0,
    Logo: "",
  },

  Form: {
    tipo: 0,
    nombre: "",
    login: "",
    estado: "",
    rol: "",
    id: "",
    usertipo: 0,
    inmuebleid: 0,
    correo: "",
    telefono: "",
    rondero: ""
  },

  FormPregunta: {
    Tipo: -1,
    PreguntaId: 0,
  },

  GlobalUser: {
    rol: 0,
    userid: 0,
    Nombre: "",
    UserLog: "",
    Rondero: "",
    Supervisor: "",
  },

  GlobalSusc: {
    Nombre: "",
    Apellido: "",
    Direccion: "",
    IdInmueble: "",
    Coeficiente: "",
    SuscId: "",
    Tipo: "",
    VarSession: "",
    FechaApertura: "",
    HoraApertura: "",
    Estado: false,
    rol: 0
  },

  SuscAsamblea: {
    AsistenciaId: "",
    CalidadAsis: 0,
    Mora: false,
    AsambleaId: "",
    Link: "",
    TipoAsamblea: ""
  },

  Asamblea: {
    // PARA TODAS
    AsambleaId: 0,
    TipoAsamblea: "",
    TipoIdAsamblea: 0,
    Convocatoria: "",
    FechaApertura: "",
    TotalCoeficiente: 0,
    TotalAsistentes: 0,
    TotalPoderes: 0,
    LimitePoder: "",
    Link: "",
    Descripcion: "",
    BloqueoAsistencia: false,

    // SOLO PARA LAS CERRADAS
    FechaCierre: "",
    DetalleCierre: "",

    // al cambiar el nombre de una variables desde el home Asamblea
    NameInm: "Inmuebles",
    NamePoder: "Representaciones",
    VarNrepr: "initial",
    Col_coe_prg: false,
    ColCoeRprg: false
  },

  InfoAsamblea: {
    AsambleaId: "",
    Descripcion: "",
    Fecha: "",
    Asamblea: "",
    Convocatoria: "",
  },

  NotifHome: {
    Pendientes: [],
    View: false,
    FirtsEntry: false,
  },

  Plataforma: {
    Valor: window.location.host.includes('goph.com.co') ? 2 : 1,
    //Valor: 1,       // 1 DESARROLLO   2 PRODUCCION
    Lang: "",
    Usuarios: [2, 12, 24, 25, 50, 77], //USUARIOS QUE PUEDEN VER PERFILES PRIBILEGIADOS
    Partner: [7890, 1014, 3333, 1202, 1112, 1012, 1019, 1004, 1003, 1011, 1028, 1015, 1016, 1017, 1029, 1031, 1032]
  },

  Geolocalizacion: {
    Latitud: undefined,
    Longitud: undefined,
  },
  /*
    PushNotify:{
      PUBLIC_VAPID_KEY: 'BASSVCLe6-LkOG0QAjW-iZQWfmF9PpOhadxdnHV_qpF9eWf76llN6OOCUQJZ88G9tj3jDu5EBmnK55o468nOjpM'
    },
  */
  Ruta: {
    Raiz: "",
    Url: window.location.host.includes('goph.com.co') ? "https://goph.com.co/api/api/" : "http://192.168.7.90:4000/api/",
    Url2: window.location.host.includes('goph.com.co') ? "https://goph.com.co/" : "http://192.168.7.73:8059/",
    // Url: "http://192.168.1.3:4000/api/",
    //Url2: "http://localhost:8058/pre",
    // Url: "http://192.168.7.202:4000/api/",
    //Url: "http://192.168.80.10:4000/api/",
    version: "2.8.0",
    Ipin: "OpenSesame159*"
  }
};

export default Global;