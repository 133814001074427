import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import axios from "axios";
import Global from "../../../Global";
import {CookieSesion} from "../../Helpers";
import swal from "sweetalert";

import Carrusel from "./Carrusel";
import PopupGrpZona from "./PopupGrpZona";
import HeadTitle from "../../principal/HeadTitle";

class GrpZonas extends Component {
  state = {
    // MODAL
    modalShow: false,
    modalShow1: false,

    // DATA
    Data: false,
    zonas: [],
    count: 0,

    //redirect
    redirect_home: false,
    redirect_login: 0,

    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          axios
            .get(
              Global.Ruta.Url +
                "admin/listarzonas/" +
                Buffer.from(Global.Ph.Codigo).toString("base64") +
                "/" +
                Buffer.from(Global.Ruta.Ipin).toString("base64")
            )
            .then((res) => {
              if (res.data.message === "") {
                var zonas = [];
                var tem = {};
                for (var i of res.data.Zonas) {
                  var zonasporgupo = [];
                  for (var j of i.zonasporgupo) {
                    zonasporgupo.push(j);
                  }
                  tem = {
                    GrpZona: i.GrpZona,
                    zonasporgupo: zonasporgupo,
                  };
                  zonas.push(tem);
                }
                this.setState({
                  Data: true,
                  count: 1,
                  zonas: zonas,
                });
              } else if (res.data.message === "ERROR0") {
                this.setState({
                  Data: true,
                  count: -1,
                });
              } else {
                swal({
                  title: "ALERTA!",
                  text: res.data.message,
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({redirect_home: true});
                });
              }
            })
            .catch((err) => {
              this.setState({
                redirect_home: true,
              });
            });
        } else {
          this.setState({redirect_home: true});
        }
      } else {
        this.setState({redirect_login: 1});
      }
    } else {
      this.setState({redirect_login: 1});
    }
  }

  guardar = (nuevoDato) => {
    var GrpZona = {
      GrpZona: {
        nombre: nuevoDato.nombre,
        imagenid: nuevoDato.img,
        grupo: nuevoDato.id,
        estado: "S",
      },
      zonasporgupo: [],
    };
    var tem = this.state.zonas;
    tem.push(GrpZona);
    this.setState({zonas: tem});
    this.setState((state) => ({modalShow: !state.modalShow}));
  };

  cancelar = () => {
    this.setState((state) => ({modalShow: !state.modalShow}));
  };

  eliminar = (indiceGrpZona) => {
    var tem = this.state.zonas;
    tem.splice(indiceGrpZona, 1);
    this.setState({
      zonas: tem,
    });
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.Data === true) {
      return (
        <div className="container-fluid bg-white" style={{overflowX: 'auto'}}>
          <div className="row pt-2 gy-3 pt-3">
            <div className="col-12">
              {this.state.count === -1 ? (
                <div className="card shadow py-2">
                  <div className="row justify-content-center px-1">
                    <div className="col-12">
                      <p className="lead text-center fw-normal interline1">
                        La Propiedad Horizontal aún no tiene Zonas Sociales
                        configuradas, es un buen momento para crearlas !!
                      </p>
                    </div>
                    <div className="col-auto py-2">
                      <button
                        className="btn btn-primary text-nowrap"
                        onClick={() => this.setState({modalShow: true})}
                      >
                        <i className="fas fa-plus"></i> Nuevo Grupo
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <HeadTitle
                  classNameOwn="row align-items-center px-3"
                  titulo="Zonas Sociales"
                  subTitulo="Módulo para crear y configurar Zonas Sociales de la propiedad horizontal."
                  indicadores={[]}
                  actionButton={
                    <button
                      className="btn btn-primary text-nowrap"
                      onClick={() => this.setState({modalShow: true})}
                    >
                      <i className="fas fa-plus"></i> Nuevo Grupo
                    </button>
                  }
                />
              )}
            </div>

            <div className="col-12">
              <div className="row">
                {this.state.zonas.map((grzona, indice) => (
                  //<p key={grzona.GrpZona.grupo}>{grzona.GrpZona.grupo}</p>
                  <Carrusel
                    key={grzona.GrpZona.grupo}
                    indice={indice}
                    grzona={grzona.GrpZona}
                    recursos={grzona.zonasporgupo}
                    eliminar={this.eliminar}
                  />
                ))}
              </div>
            </div>
          </div>
          <PopupGrpZona
            show={this.state.modalShow}
            opc={1}
            guardar={this.guardar}
            cancelar={this.cancelar}
          />

          <div className="separador"></div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default GrpZonas;
