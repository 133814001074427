import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";



class IconHome extends Component {

  state = {
    modal: false
  }

  render() {
    const contador = parseInt(this.props.globonotificacion);
    const { icono, masInfo, onClick, id } = this.props
    const { modal } = this.state

    return (
      <div className="card overflow-hidden rounded-4 shadow card_menu_home h-100"
        style={{ maxWidth: '12em' }}
      >
        {parseInt(id) !== 6 ? (<NavLink exact to={this.props.url} onClick={() => { (typeof onClick === 'function') && onClick() }} className='h-100'>
          <div className="">
            <img src={icono} alt="" className="w-100" />
            <div className="card-body p-2">
              <div className='flex flex-column'>
                <div className="cover-marqss pe-0">
                  <h5 className="fw-bold text-capitalize" style={{ fontSize: '1.2rem' }}>
                    {this.props.titulo}
                  </h5>
                </div>
                <div className="col-12 v_cover_marqss" style={{ maxHeight: '3rem', minHeight: '3rem' }}>
                  <h6 className="v_marqss text-secondary">
                    {this.props.descripcion || "descripción"}
                  </h6>
                </div>
              </div>
            </div>
            {contador === 0 ? null : contador === 1 ? (
              parseInt(this.props.valor) !== 0 &&
                this.props.valor !== undefined ? (
                <div className="card-footer">
                  <div className="contador">
                    <h6 className="m-0 text-light">{this.props.valor}</h6>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : contador === 2 ? (
              <div className="col-auto">
                <img
                  src={this.props.icono}
                  className="i-MenuHome"
                  alt={this.props.titulo}
                ></img>
              </div>
            ) : null}
          </div>
        </NavLink>) : (<div exact to={this.props.url} onClick={() => { (typeof onClick === 'function') && onClick() }} className='h-100' style={{ cursor: "pointer" }}>
          <div className="">
            <img src={icono} alt="" className="w-100" />
            <div className="card-body p-2">
              <div className='flex flex-column'>
                <div className="cover-marqss pe-0">
                  <h5 className="fw-bold text-capitalize" style={{ fontSize: '1.2rem', color: "#0d6efd" }}>
                    {this.props.titulo}
                  </h5>
                </div>
                <div className="col-12 v_cover_marqss" style={{ maxHeight: '3rem', minHeight: '3rem' }}>
                  <h6 className="v_marqss text-secondary">
                    {this.props.descripcion || "descripción"}
                  </h6>
                </div>
              </div>
            </div>
            {contador === 0 ? null : contador === 1 ? (
              parseInt(this.props.valor) !== 0 &&
                this.props.valor !== undefined ? (
                <div className="card-footer">
                  <div className="contador">
                    <h6 className="m-0 text-light">{this.props.valor}</h6>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : contador === 2 ? (
              <div className="col-auto">
                <img
                  src={this.props.icono}
                  className="i-MenuHome"
                  alt={this.props.titulo}
                ></img>
              </div>
            ) : null}
          </div>
        </div>)}
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary btn-sm rounded-0" style={{ fontSize: '0.95rem' }} onClick={() => this.setState({ modal: true })}>
            Mas Info
          </button>
        </div>
        <Modal show={modal} centered onHide={() => this.setState({ modal: false })}>
          <Modal.Header closeButton />
          <Modal.Body>{masInfo}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default IconHome;
