import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { TextField } from "@mui/material";
import { styled } from "@material-ui/core";
import * as XLSX from 'xlsx'
import swal from "sweetalert";
import Global from "./../../../../Global";
import axios from "axios";
import SimpleReactValidator from 'simple-react-validator'
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";

export default class CrearBD extends Component {

    state = {
        PpHourglass: false,
        selectedFile: null,
        namefile: "",
        inputKey: Date.now(),
        nombre: '',
        direccion: '',
        contacto: '',
        ciudad: '',
        nit: '',
        email: '',
        fijo: '',
        celular: '',
        postal: undefined,
    }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'solo puede ingresar numeros enteros'
            }
        });
    }

    handleFileChange = (e) => {
        this.setState({ PpHourglass: true })
        const file = e.target.files[0];

        if (file) {

            if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
                this.setState({ PpHourglass: false })
                swal({
                    title: "ATENCIÓN!",
                    text: "Por favor, seleccione un archivo Excel válido.",
                    icon: "info",
                    button: "Aceptar",
                })
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const data = event.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });

                    // Obtener la primera hoja del libro de trabajo
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    // Obtener los datos de la columna A
                    const columnAData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false }) // Cambiar a 'header: 0' si la primera fila son datos
                        .map(row => [row[0], row[1], row[2], row[3]]).slice(1);

                    this.setState({ inputKey: Date.now() }, () => this.ValidarExcel(columnAData, file.name))
                };

                reader.readAsBinaryString(file);
            }

        }
    };

    ValidarExcel = (columnData, name) => {

        var validaArchivo = true
        const rowsWithEmptyCellsA = [];
        const rowsWithEmptyCellsB = [];
        const rowsWithEmptyCellsC = [];
        const rowsWithEmptyCellsD = [];

        var regex = /[^a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s.]/;
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validar que no existan columnas vacías en la columna NOMBRE PROPIETARIO, DIRECCIÓN INMUEBLE y  COEFICIENTE 
        columnData.forEach((row, index) => {
            if (row[0] === undefined || row[0] === null || row[0] === '' || regex.test(row[0]) || row[0].includes("\n")
                || row[0].includes("\r\n") || row[0].includes("\r") || row[0].includes("\t") || row[0].length > 100) {
                rowsWithEmptyCellsA.push(" " + (index + 2));
            }

            if (row[1] === undefined || row[1] === null || row[1] === '' || row[1].length > 100) {
                rowsWithEmptyCellsB.push(" " + (index + 2));
            }

            if (row[2] === undefined || row[2] === null || row[2] === '' || isNaN(row[2]) || row[2].length > 7) {
                rowsWithEmptyCellsC.push(" " + (index + 2));
                // console.log(row[2])
            }


            if (row[3] !== undefined && row[3] !== null && row[3] !== '') {
                var emails = row[3].split(';');
                for (var i = 0; i < emails.length; i++) {
                    var trimmedEmail = emails[i].trim();
                    if (trimmedEmail.length < 100) {
                        if (trimmedEmail && !emailRegex.test(trimmedEmail)) {
                            rowsWithEmptyCellsD.push(" " + (index + 2));
                            break;
                        }
                    } else {
                        rowsWithEmptyCellsD.push(" " + (index + 2));
                    }
                }
            }



        });

        if (rowsWithEmptyCellsA.length > 0) {
            this.setState({ PpHourglass: false })
            swal({
                title: "ATENCIÓN!",
                text: `El archivo contiene celdas vacías o con caracteres especiales en la columna NOMBRE PROPIETARIO. verifique el archivo.
                
                Filas vacias o carácteres: ${rowsWithEmptyCellsA}`,
                icon: "info",
                button: "Aceptar",
            })
            validaArchivo = false
            return
        }

        if (rowsWithEmptyCellsB.length > 0) {
            this.setState({ PpHourglass: false })
            swal({
                title: "ATENCIÓN!",
                text: `El archivo contiene celdas vacías en la columna DIRECCIÓN INMUEBLE. verifique el archivo.
                
                Filas vacias o carácteres: ${rowsWithEmptyCellsB}`,
                icon: "info",
                button: "Aceptar",
            })
            validaArchivo = false
            return
        }

        if (rowsWithEmptyCellsC.length > 0) {
            this.setState({ PpHourglass: false })
            swal({
                title: "ATENCIÓN!",
                text: `El archivo contiene celdas vacías en la columna COEFICIENTE. verifique el archivo.
                
                Filas vacias o carácteres: ${rowsWithEmptyCellsC}`,
                icon: "info",
                button: "Aceptar",
            })
            validaArchivo = false
            return
        }

        if (rowsWithEmptyCellsD.length > 0) {
            this.setState({ PpHourglass: false })
            swal({
                title: "ATENCIÓN!",
                text: `El archivo contiene celdas inválidas en la columna Correo. verifique el archivo.
                
                Filas: ${rowsWithEmptyCellsD}`,
                icon: "info",
                button: "Aceptar",
            })
            validaArchivo = false
            return
        }

        // Validar que la suma no esté fuera del rango 99.5 y 100.5
        const sumColumnC = columnData.reduce((sum, row) => sum + (parseFloat(row[2]) || 0), 0);
        if (parseFloat(sumColumnC) < 99.5 || parseFloat(sumColumnC) > 100.5) {
            this.setState({ PpHourglass: false })
            swal({
                title: "ATENCIÓN!",
                text: `La suma de los Coeficientes (${sumColumnC.toFixed(2)}) está fuera del rango permitido.

                Rango 99.5 - 100.5`,
                icon: "info",
                button: "Aceptar",
            })
            return
        }

        if (validaArchivo) {
            this.setState({ selectedFile: columnData, namefile: name }, () => {
                this.setState({ PpHourglass: false })
            })
        }
    }

    Guardar = () => {
        if (this.validator.allValid()) {
            this.setState({ PpHourglass: true });
            const { nombre, direccion, contacto, ciudad, nit, email, fijo, celular, postal, selectedFile } = this.state
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                nombrepropiedad: Buffer.from(nombre).toString("base64"),
                direccion: Buffer.from(direccion).toString("base64"),
                ciudad: Buffer.from(ciudad).toString("base64"),
                administrador: Buffer.from(contacto).toString("base64"),
                telefono: Buffer.from(`${celular}`).toString("base64"),
                nit: Buffer.from(`${nit}`).toString("base64"),
                fijo: Buffer.from(`${fijo}`).toString("base64"),
                correo: Buffer.from(email).toString("base64"),
                codigopostal: Buffer.from(`${postal}`).toString("base64"),
                selectedFile: Buffer.from(JSON.stringify({ selectedFile: selectedFile })).toString("base64"),
            };

            axios.post(`${Global.Ruta.Url}newbd/crearbd`, body).then((res) => {
                if (res.data.message === "") {
                    this.setState({
                        PpHourglass: false,
                    });
                    swal({
                        title: "Excelente",
                        text: `La base de datos de ${nombre} fue creada satisfactoriamente. 
                        
                        El código a utilizar es: ${res.data.codigobd}`,
                        icon: "success",
                        button: "Aceptar",
                    }).then(() => {
                        this.props.onHide();
                    });

                } else {
                    swal({
                        title: "ERROR!",
                        text: res.data.message,
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({
                            PpHourglass: false,
                        });
                    });
                }
            }).catch((err) => {
                swal({
                    title: "ERROR!",
                    text: "Por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({
                        PpHourglass: false,
                    });
                });
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const Input = styled("input")({
            display: "none",
        });
        return (
            <div className='g-r-prf1 h-100'>
                <Modal.Header closeButton>
                    <span className="fw-bold m-0">Crear Base de Datos</span>
                </Modal.Header>
                <Modal.Body>

                    <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}>
                        <PpHourglass />
                    </Modal>

                    <div className='row row-cols-2'>

                        <div className='col-12'>
                            <TextField className="my-2" variant="outlined" label="Nombre PH" fullWidth inputProps={{ maxLength: 50 }}
                                value={this.state.nombre} onChange={(e) => { this.setState({ nombre: e.target.value.toUpperCase() }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('nombre', this.state.nombre, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Dirección" fullWidth inputProps={{ maxLength: 60 }}
                                value={this.state.direccion} onChange={(e) => { this.setState({ direccion: e.target.value.toUpperCase() }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('direccion', this.state.direccion, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Código Postal" fullWidth inputProps={{ maxLength: 10 }}
                                value={this.state.postal} onChange={(e) => { this.setState({ postal: e.target.value }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('postal', this.state.postal, 'required|integer|numeric|min:0,num', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Ciudad" fullWidth inputProps={{ maxLength: 50 }}
                                value={this.state.ciudad} onChange={(e) => { this.setState({ ciudad: e.target.value.toUpperCase() }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('ciudad', this.state.ciudad, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Contacto" fullWidth inputProps={{ maxLength: 60 }}
                                value={this.state.contacto} onChange={(e) => { this.setState({ contacto: e.target.value.toUpperCase() }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('contacto', this.state.contacto, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Email" fullWidth inputProps={{ maxLength: 50 }}
                                value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="N.I.T" fullWidth inputProps={{ maxLength: 20 }}
                                value={this.state.nit} onChange={(e) => { this.setState({ nit: e.target.value }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('nit', this.state.nit, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Teléfono Fijo" type={"number"} fullWidth inputProps={{ maxLength: 10 }}
                                value={this.state.fijo} onChange={(e) => { this.setState({ fijo: e.target.value }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('fijo', this.state.fijo, 'required|integer|numeric|min:0,num', { className: 'text-danger' })}</label>
                        </div>
                        <div className='col'>
                            <TextField className="my-2" variant="outlined" label="Móvil" type='number' fullWidth inputProps={{ maxLength: 10 }}
                                value={this.state.celular} onChange={(e) => { this.setState({ celular: parseInt(e.target.value) }) }}
                            />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('celular', this.state.celular, 'required|integer|numeric|min:0,num', { className: 'text-danger' })}</label>
                        </div>

                        <div className='col-12'>
                            <div className="col">
                                <div className="card mx-auto text-center  position-relative border-a-1 border-secondary">
                                    <div className="card-body">
                                        {this.state.namefile === "" ? (
                                            <div className="row gy-2 justify-content-center">
                                                <div className="col-12 d-none d-lg-block">
                                                    <span className="fs-4 text-center m-0">
                                                        Arrastre y suelte aquí el archivo Excel
                                                    </span>
                                                </div>
                                                <div className="col-12 text-center">
                                                    <span className="spanO"> o </span>
                                                </div>
                                                <div className="col-auto">
                                                    <input
                                                        id="drop-area"
                                                        key={this.state.inputKey}
                                                        type="file" accept=".xls, .xlsx"
                                                        className="top-50 start-50 translate-middle"
                                                        onChange={(event) => this.handleFileChange(event)}
                                                    />
                                                    <label htmlFor="contained-button-file">
                                                        <Input
                                                            id="contained-button-file"
                                                            key={this.state.inputKey}
                                                            type="file" accept=".xls, .xlsx"
                                                            onChange={(event) => this.handleFileChange(event)}
                                                        />
                                                        <button
                                                            className="btn btn-primary text-nowrap"
                                                            variant="contained"
                                                            component="span"
                                                        >
                                                            Seleccionar archivo...
                                                        </button>
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='row row-cols-1 d-flex justify-content-center'>
                                                <span className='fs-5 fw-bold mb-2'>{this.state.namefile}</span>
                                                <button className='btn btn-success w-25' onClick={() => { this.setState({ namefile: "", selectedFile: null }) }}> <i className="fa-regular fa-file-excel"></i> Cambiar Archivo </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <button className='btn btn-primary' disabled={this.state.namefile === ""} onClick={() => this.Guardar()}>Crear Base de Datos</button>
                </Modal.Footer>
            </div>
        )
    }
}