import React, {Component} from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  styled,
} from "@material-ui/core";
import {LocalizationProvider, DatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Es from "date-fns/locale/es";
import {red, grey} from "@mui/material/colors";
//import { FormControl, InputLabel, ListItemText, MenuItem, Select, } from "@mui/material";

class Sidebar extends Component {
  state = {
    checkedZona: [],
    checkedEstado: [],
    Estado: false,
    Zona: false,
    startDate: null,
    endDate: null,
    EstadoA: -99,
    ZonaA: -99,
    ZonaN: 0,
  };

  componentWillMount() {
    var temzonas = [];
    for (let i = 0; i < this.props.Zonas.length; i++) {
      temzonas.push({
        idRecurso: this.props.Zonas[i].idrecurso,
        nombrerecurso: this.props.Zonas[i].nombrerecurso,
        estado: false,
      });
    }
    var temestado = [];
    for (let i = 0; i < this.props.Estados.length; i++) {
      temestado.push(false);
    }

    this.setState({
      checkedZona: temzonas,
      checkedEstado: temestado,
    });
  }

  indeterminateZonas = () => {
    var cont = 0;
    for (var i of this.state.checkedZona) {
      if (i) {
        cont++;
      }
    }
    if (cont === 0 || cont === this.state.checkedZona.length) {
      return false;
    } else {
      return true;
    }
  };
  indeterminateEstados = () => {
    var cont = 0;
    for (var i of this.state.checkedEstado) {
      if (i) {
        cont++;
      }
    }
    if (cont === 0 || cont === this.state.checkedEstado.length) {
      return false;
    } else {
      return true;
    }
  };

  allZonas = (event) => {
    var tem = this.state.checkedZona;
    for (var i = 0; i < tem.length; i++) {
      tem[i] = event.target.checked;
    }
    var temporal = -1;
    if (event.target.checked === false) {
      temporal = -99;
    }
    this.setState({
      Zona: event.target.checked,
      checkedZona: tem,
      ZonaA: temporal,
    });
  };
  allEstados = (event) => {
    var tem = this.state.checkedEstado;
    for (var i = 0; i < tem.length; i++) {
      tem[i] = event.target.checked;
    }
    var temporal = -1;
    if (event.target.checked === false) {
      temporal = -99;
    }
    this.setState({
      Estado: event.target.checked,
      checkedEstado: tem,
      EstadoA: temporal,
    });
  };

  checkedZona = (indice) => {
    var tem = [];

    tem = this.state.checkedZona;
    var idRecurso = tem[indice].idrecurso;
    var nombreRecurso = tem[indice].nombrerecurso;
    tem[indice].estado = !tem[indice].estado;

    /*for (var i = 0; i < this.state.checkedZona.length; i++) {
            if (i === indice) {
                if (event.target.checked) { idRecurso = this.props.Zonas[i].idrecurso }
                tem.push(event.target.checked);
            } else {
                tem.push(false);
            }
        }*/
    // return temRows.filter(function (data) {
    //     return data.nombres.toLowerCase().indexOf(query.toLowerCase()) > -1;
    // });

    this.setState({
      checkedZona: tem,
      Zona: false,
      ZonaA: idRecurso,
      ZonaN: nombreRecurso,
    });
  };
  checkedEstado = (event, indice) => {
    var tem = [];
    var temporal = indice;
    for (var i = 0; i < this.state.checkedEstado.length; i++) {
      if (i === indice) {
        if (!event.target.checked) {
          temporal = -99;
        }
        tem.push(event.target.checked);
      } else {
        tem.push(false);
      }
    }

    if (indice === 3) {
      temporal = 4;
    }
    this.setState({
      checkedEstado: tem,
      Estado: false,
      EstadoA: temporal,
    });
  };

  zonasSelect = () => {
    return this.state.checkedZona.filter((element) => {
      return element.estado.toString().toLowerCase().indexOf("true") > -1;
    });
  };

  datos = () => {
    if (
      (this.state.startDate === null || this.state.endDate === null) &&
      this.zonasSelect().length <= 0 &&
      this.state.EstadoA === -99
    ) {
      return true;
    } else {
      if (this.zonasSelect().length > 0 || this.state.EstadoA !== -99) {
        if (this.state.startDate === null && this.state.endDate === null) {
          return false;
        } else if (
          this.state.startDate !== null ||
          this.state.endDate !== null
        ) {
          if (this.state.startDate !== null && this.state.endDate !== null) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }
  };

  Buscar = () => {
    var filtro = {};
    var fechaEnd;
    var fechaStart;
    if (this.state.startDate !== null) {
      if (this.state.endDate !== null) {
        if (this.zonasSelect().length > 0) {
          if (this.state.EstadoA !== -99) {
            if (this.zonasSelect().length > 0 && this.state.EstadoA > -1) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "1",
                idrecurso: JSON.stringify({idrecursos: this.zonasSelect()}),
                estado: "" + this.state.EstadoA,
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            } else if (
              this.zonasSelect().length > 0 &&
              this.state.EstadoA === -1
            ) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "2",
                idrecurso: JSON.stringify({idrecursos: this.zonasSelect()}),
                estado: "",
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            } else if (
              this.zonasSelect().length <= 0 &&
              this.state.EstadoA > -1
            ) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "3",
                idrecurso: "",
                estado: "" + this.state.EstadoA,
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            } else if (this.state.EstadoA === -1 && this.state.EstadoA === -1) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "4",
                idrecurso: "",
                estado: "",
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            }
          } else {
            if (this.zonasSelect().length > 0) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "2",
                idrecurso: JSON.stringify({idrecursos: this.zonasSelect()}),
                estado: "",
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };

              this.props.BuscarFiltro(filtro);
            } else if (this.zonasSelect().length <= 0) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "4",
                idrecurso: "",
                estado: "",
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            }
          }
        } else {
          if (this.state.EstadoA !== -99) {
            if (this.state.EstadoA > -1) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "3",
                idrecurso: "",
                estado: "" + this.state.EstadoA,
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            } else if (this.state.EstadoA === -1) {
              fechaEnd =
                new Date(this.state.endDate).getFullYear() +
                "/" +
                (new Date(this.state.endDate).getMonth() + 1) +
                "/" +
                new Date(this.state.endDate).getDate();
              fechaStart =
                new Date(this.state.startDate).getFullYear() +
                "/" +
                (new Date(this.state.startDate).getMonth() + 1) +
                "/" +
                new Date(this.state.startDate).getDate();
              filtro = {
                caso: "4",
                idrecurso: "",
                estado: "",
                fechai: "" + fechaStart,
                fechaf: "" + fechaEnd,
              };
              this.props.BuscarFiltro(filtro);
            }
          } else {
            fechaEnd =
              new Date(this.state.endDate).getFullYear() +
              "/" +
              (new Date(this.state.endDate).getMonth() + 1) +
              "/" +
              new Date(this.state.endDate).getDate();
            fechaStart =
              new Date(this.state.startDate).getFullYear() +
              "/" +
              (new Date(this.state.startDate).getMonth() + 1) +
              "/" +
              new Date(this.state.startDate).getDate();
            filtro = {
              caso: "4",
              idrecurso: "",
              estado: "",
              fechai: "" + fechaStart,
              fechaf: "" + fechaEnd,
            };
            this.props.BuscarFiltro(filtro);
          }
        }
      }
    } else {
      if (this.state.endDate === null) {
        if (this.zonasSelect().length > 0) {
          if (this.state.EstadoA !== -99) {
            if (this.zonasSelect().length > 0 && this.state.EstadoA > -1) {
              filtro = {
                caso: "5",
                idrecurso: JSON.stringify({idrecursos: this.zonasSelect()}),
                estado: "" + this.state.EstadoA,
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            } else if (
              this.zonasSelect().length > 0 &&
              this.state.EstadoA === -1
            ) {
              filtro = {
                caso: "6",
                idrecurso: JSON.stringify({idrecursos: this.zonasSelect()}),
                estado: "",
                fechai: "",
                fechaf: "",
              };

              this.props.BuscarFiltro(filtro);
            } else if (
              this.zonasSelect().length <= 0 &&
              this.state.EstadoA > -1
            ) {
              filtro = {
                caso: "7",
                estado: "" + this.state.EstadoA,
                idrecurso: "",
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            } else if (
              this.zonasSelect().length <= 0 &&
              this.state.EstadoA === -1
            ) {
              filtro = {
                caso: "8",
                idrecurso: "",
                estado: "",
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            }
          } else {
            if (this.zonasSelect().length > 0) {
              filtro = {
                caso: "6",
                idrecurso: JSON.stringify({idrecursos: this.zonasSelect()}),
                estado: "",
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            } else if (this.zonasSelect().length <= 0) {
              filtro = {
                caso: "8",
                idrecurso: "",
                estado: "",
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            }
          }
        } else {
          if (this.state.EstadoA !== -99) {
            if (this.state.EstadoA > -1) {
              filtro = {
                caso: "7",
                estado: "" + this.state.EstadoA,
                idrecurso: "",
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            } else if (this.state.EstadoA === -1) {
              filtro = {
                caso: "8",
                idrecurso: "",
                estado: "",
                fechai: "",
                fechaf: "",
              };
              this.props.BuscarFiltro(filtro);
            }
          }
        }
      }
    }
  };

  render() {
    const ColorButton2 = styled(Button)(({theme}) => ({
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[600],
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: red[600],
        borderColor: red[900],
        color: grey[50],
      },
    }));

    // const etiquetaEstado = (llave) => {
    //   switch (llave) {
    //     case -99:
    //       return "Estado";
    //     case 0:
    //       return "Cancelado";
    //     case 1:
    //       return "Negado";
    //     case 2:
    //       return "Aprobado";
    //     case -1:
    //       return "Todos";
    //     case 4:
    //       return "Pendiente";
    //     default:
    //       return "Estado";
    //   }
    // };

    return (
      <div
        className="row row-cols-1 row-cols-md-4 gy-3 align-items-center sidebar-reserva"
      >
        {/*<div className='col-auto h-100'>
                        <div className='row px-3 align-items-center flex-column'>
                            <div className='col-auto'>
                                <div className='rounded px-3' style={{ backgroundColor: 'silver' }}>
                                  <h6 className='m-0 mt-1 fw-normal'>{etiquetaEstado(this.state.EstadoA)}</h6>
                                </div>
                            </div>
                            {/* <div className='col-auto'>
                                <h6 className='m-0 mt-1 fw-normal'>Filtro</h6>
                            </div> 
                        </div>
                    </div>*/}
        <div className="col">
          <div className="position-relative px-2 s-r-option" style={{zIndex: 24}}>
            <Accordion className="absolute-swt shadow-sm">
              <AccordionSummary
                className=""
                expandIcon={<i className="fas fa-chevron-down"></i>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Zonas</Typography>
              </AccordionSummary>
              <AccordionDetails
                className="bg-light shadow"
                style={{height: "15rem"}}
              >
                <div className="w-100" style={{overflowY: "auto"}}>
                  <FormControlLabel
                    className="d-none"
                    size="small"
                    label="Todos"
                    control={
                      <Checkbox
                        checked={this.state.Zona}
                        indeterminate={this.indeterminateZonas()}
                        onChange={this.allZonas}
                      />
                    }
                    disabled={true}
                  />

                  {this.state.checkedZona.map((zona, indice) => (
                    <Box
                      key={indice}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        ml: 3,
                      }}
                    >
                      <FormControlLabel
                        label={zona.nombrerecurso}
                        control={
                          <Checkbox
                            checked={this.state.checkedZona[indice].estado}
                            onChange={(event) => {
                              this.checkedZona(indice);
                            }}
                          />
                        }
                      />
                    </Box>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Zona</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Zona">
                {this.state.checkedZona.map((zona, indice) => (
                  <MenuItem key={indice} value={indice} sx={{ p: 1 }}>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        className='p-0'
                        checked={this.state.checkedZona[indice].estado}
                        onChange={(event) => {
                          this.checkedZona(indice);
                        }}
                      />
                      <ListItemText className='p-0' primary={zona.nombrerecurso} />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
        </div>
        <div className="col">
          <div className="position-relative s-r-option" style={{zIndex: 23}}>
            <Accordion className="absolute-swt shadow-sm" >
              <AccordionSummary
                className=""
                expandIcon={<i className="fas fa-chevron-down"></i>}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Fecha</Typography>
              </AccordionSummary>
              <AccordionDetails className="bg-light shadow">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={Es}
                    >
                      <DatePicker
                        label="fecha inicio"
                        value={this.state.startDate}
                        maxDate={this.state.endDate}
                        onChange={(date) => {
                          this.setState({startDate: date});
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="barraCalendar"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={Es}
                    >
                      <DatePicker
                        label="fecha final"
                        value={this.state.endDate}
                        minDate={this.state.startDate}
                        onChange={(date) => {
                          this.setState({endDate: date});
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="barraCalendar"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="col">
          <div className="position-relative s-r-option" style={{zIndex: 20}}>
            <Accordion className="absolute-swt shadow-sm">
              <AccordionSummary
                className=""
                expandIcon={<i className="fas fa-chevron-down"></i>}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Estado</Typography>
              </AccordionSummary>
              <AccordionDetails className="bg-light shadow">
                <div>
                  <FormControlLabel
                    className="d-none"
                    size="small"
                    label="Todos"
                    control={
                      <Checkbox
                        checked={this.state.Estado}
                        indeterminate={this.indeterminateEstados()}
                        onChange={this.allEstados}
                      />
                    }
                    disabled={true}
                  />
                  {/* ESTADOS CHECKBOX */}
                  {this.props.Estados.map((estado, indice) => (
                    <Box key={indice} sx={{flexDirection: "column", ml: 3}}>
                      <FormControlLabel
                        label={estado}
                        control={
                          <Checkbox
                            checked={this.state.checkedEstado[indice]}
                            onChange={(event) => {
                              this.checkedEstado(event, indice);
                            }}
                          />
                        }
                      />
                    </Box>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="col-auto">
          <div className="row gx-2 justify-content-center align-items-center px-2 h-100">
            <div className="col h-auto">
              <Button
                disabled={this.datos()}
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.Buscar}
              >
                {" "}
                Buscar{" "}
              </Button>
            </div>
            <div className="col h-auto">
              <ColorButton2
                fullWidth
                variant="contained"
                onClick={() => {
                  this.props.BorrarFiltro(2);
                }}
              >
                {" "}
                Limpiar{" "}
              </ColorButton2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
