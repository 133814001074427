import React, {Component} from "react";

class Copyright extends Component {
  render() {

    let year = new Date()

    return (
      <div className="col-12">
        <div className="row justify-content-center pe-2">
          <div className="col-auto">
            <p id="crp" className="m-0 fw-light text-white" style={{fontSize: '0.9rem'}}>
              © Copyright {`${year.getFullYear()}`} by Inggo Developments.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Copyright;
