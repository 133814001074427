import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Buffer } from "buffer";

// import "font-awesome/css/font-awesome.min.css";
import logoGoph from "../../assets/images/logo.png";

import Global from "../../Global";
import axios from "axios";
import swal from "sweetalert";
import {
  capitalizeFirstLetter,
  setCookieP,
  setCookieD,
  AceptaDeclinaCookie,
  CookieSesion,
  CookieNombrePH,
  CookiePhCodigo,
  CookieNombre,
  CookieTipo,
  CookieUserId,
  CookieRol,
  CookieNitph,
  CookieCiudad,
  CookieRondero,
  CookieSupervisor
} from "../Helpers";
import Traductor /*, {BotonTraduct}*/ from "./Traductor";

var vartelfono = {};
var AceptCokie;

const Edge = navigator.appVersion.indexOf("Edg") > -1;

class Login extends Component {
  componentWillMount() {
    //this.getGeoInfo()
    //  VALIDAR SI LAS COOKIES FUERON ACEPTADAS O DECLIADAS POR EL USUARIO
    AceptCokie = AceptaDeclinaCookie();
    if (!parseInt(AceptCokie) > 0) {
      swal({
        text: "En GoPH utilizamos cookies propias para que el usuario pueda acceder y navegar por la plataforma. Puedes obtener más información en nuestra política de cookies. Pulsando en continuar o ingresando a nuestra plataforma consientes su uso.",
        buttons: ["Más información", "Continuar"],
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          this.setState({ verCookie: true });
        } else {
          this.masInfoCookies();
        }
      });

      setCookieP("onAccept", "1", 365);
      this.setState({ AceptCokie: 1 });
    }

    var login = CookieSesion();
    var Rol = CookieRol();

    if (parseInt(login) > 0) {
      if (parseInt(Rol) > 0) {
        Global.GlobalUser.rol = parseInt(Rol);
        Global.GlobalUser.userid = CookieUserId();
        Global.GlobalUser.Nombre = CookieNombre();
        Global.GlobalUser.Rondero = CookieRondero();
        Global.GlobalUser.Supervisor = CookieSupervisor();

        Global.Ph.Nombre = CookieNombrePH();
        Global.Ph.Codigo = CookiePhCodigo();
        Global.Ph.Nitph = CookieNitph();
        Global.Ph.Ciudadph = CookieCiudad();
        Global.Ph.Estadoph = CookieTipo();

        this.setState({ redirectuser: true });
      }
    }

    axios.get(Global.Ruta.Url + "suscasamblea/soporte").then((res) => {
      this.setState({
        telefonos: res.data.Telefonos,
        redirectlogin: 2,
        estado: true,
      });
    });
  }

  onClickF = () => {
    window.open("https://www.facebook.com/gophcolombia", "_blank");
  }

  onClickY = () => {
    window.open(
      "https://www.youtube.com/channel/UCK3bVVXVw4ltzil3MCqdG4A",
      "_blank"
    );
  }

  onClickT = () => {
    window.open("https://twitter.com/GoPHColombia", "_blank");
  }

  masInfoCookies() {
    swal(
      "Resumen de privacidad",
      "Esta web utiliza cookies para que podamos ofrecerte la mejor experiencia de usuario posible. La información de las cookies se almacena en tu navegador y realiza funciones tales como reconocerte cuando vuelves a nuestra web. Estas cookies no requieren el consentimiento del usuario. No utilizamos cookies de terceros."
    );
  }

  phcodRef = React.createRef();
  userRef = React.createRef();
  pwdRef = React.createRef();

  state = {
    version: Global.Ruta.version,
    redirectsusc: false,
    redirectuser: false,
    telefonos: {},
    estado: false,
    AceptCokie: 0,
    countryLang: "",
  };

  /*getGeoInfo = () => {
        axios.get('https://ipapi.co/190.85.104.22/json/').then((response) => {
            let data = response.data;
            Global.Plataforma.Lang = data.languages.substr(0,2)
        }).catch((error) => {
            console.log(error);
        });
    };*/

  IniciarSesion = (e) => {
    e.preventDefault();
    var Plataforma = Global.Plataforma.Valor;

    var user = {
      codigoph: this.phcodRef.current.value,
      usuario: this.userRef.current.value,
      password: this.pwdRef.current.value,
    };

    user.usuario = capitalizeFirstLetter(user.usuario).trim();

    // CIFRAR
    user.codigoph = Buffer.from(user.codigoph).toString("base64");
    user.usuario = Buffer.from(user.usuario).toString("base64");
    user.password = Buffer.from(user.password).toString("base64");
    var ipin = Buffer.from(Global.Ruta.Ipin).toString("base64");

    axios.get(Global.Ruta.Url + "login/loginsusc/" + user.codigoph + "/" + ipin + "/" + user.usuario + "/" + user.password)
      .then((res) => {
        if (res.data.message === "") {
          if (parseInt(res.data.rol) > 0) {
            if (parseInt(res.data.Estadoph) === 0) {
              swal({
                title: "ATENCION !!",
                text: "Error de acceso, por favor comuníquese con INGGO DEVELOPMENTS en comercial@inggos.com",
                icon: "error",
              });
              return;
            }

            // DATOS DE LA PH
            Global.Ph.Codigo = res.data.CodePh;
            Global.Ph.Nombre = res.data.Nombreph;
            Global.Ph.Nitph = res.data.Nitph;
            Global.Ph.Ciudadph = res.data.Ciudadph;
            Global.Ph.Estadoph = parseInt(res.data.Estadoph);

            // DATOS DEL USUARIO
            Global.GlobalUser.userid = parseInt(res.data.userid);
            Global.GlobalUser.rol = parseInt(res.data.rol);
            Global.GlobalUser.Rondero = parseInt(res.data.Rondero);
            Global.GlobalUser.Supervisor = parseInt(res.data.Supervisor);

            if (res.data.Nomb !== "") {
              Global.GlobalUser.Nombre = res.data.Nomb;
            } else {
              switch (parseInt(res.data.rol)) {
                case 1:
                  Global.GlobalUser.Nombre = "ADMINISTRADOR-GOPH";
                  break;
                case 2:
                  Global.GlobalUser.Nombre = "ADMINISTRADOR";
                  break;
                case 3:
                  Global.GlobalUser.Nombre = "ASISTENTE";
                  break;
                case 4:
                  Global.GlobalUser.Nombre = "PORTERIA";
                  break;
                default:
                  Global.GlobalUser.Nombre = "ADMINISTRADOR";
              }
            }

            // 1 DESARROLLO - 2 PRODUCCION
            if (Plataforma === 1) {
              setCookieD("Sesion", "1", 1);
              // DATOS DEL USUARIO
              setCookieD("UserId", "" + Global.GlobalUser.userid, 1);
              setCookieD("Nombre", Global.GlobalUser.Nombre, 1);
              setCookieD("Rol", "" + Global.GlobalUser.rol, 1);
              setCookieD("Rondero", "" + Global.GlobalUser.Rondero, 1);
              setCookieD("Supervisor", "" + Global.GlobalUser.Supervisor, 1);
              // DATOS DE LA PH
              setCookieD("PhCodigo", "" + Global.Ph.Codigo, 1);
              setCookieD("Conjunto", Global.Ph.Nombre, 1);
              setCookieD("Nitph", "" + Global.Ph.Nitph, 1);
              setCookieD("Ciudad", Global.Ph.Ciudadph, 1);
              setCookieD("Tipo", "" + Global.Ph.Estadoph, 1);

            } else if (Plataforma === 2) {
              setCookieD("Sesion", "1", 1);
              // DATOS DEL USUARIO
              setCookieP("UserId", "" + Global.GlobalUser.userid, 1);
              setCookieP("Nombre", Global.GlobalUser.Nombre, 1);
              setCookieP("Rol", "" + Global.GlobalUser.rol, 1);
              setCookieP("Rondero", "" + Global.GlobalUser.Rondero, 1);
              setCookieP("Supervisor", "" + Global.GlobalUser.Supervisor, 1);
              // DATOS DE LA PH
              setCookieP("PhCodigo", "" + Global.Ph.Codigo, 1);
              setCookieP("Conjunto", Global.Ph.Nombre, 1);
              setCookieP("Nitph", "" + Global.Ph.Nitph, 1);
              setCookieP("Ciudad", Global.Ph.Ciudadph, 1);
              setCookieP("Tipo", "" + Global.Ph.Estadoph, 1);
            }
            this.setState({
              redirectuser: true,
            });
          } else {
            swal({
              title: "ATENCION !!",
              text: "ERROR, Usuario o Clave incorrecta",
              icon: "error",
            }).then((res) => {
              parseInt(Global.Plataforma.Valor) === 2 ? (window.location.href = 'https://goph.com.co/asamblea/') : (window.location.href = 'http://192.168.7.73:8080/asamblea/')
            });
          }
        } else {
           swal({
            title: "ATENCION !!",
            text: res.data.message,
            icon: "error",
          });
        }
      });
  };

  soporte = (e) => {
    e.preventDefault();
    if (this.state.estado) {
      vartelfono = this.state.telefonos;
      vartelfono.sort(function () {
        return Math.random() - 0.5;
      });
      swal({
        title: "SOPORTE TÉCNICO",
        text:
          "Escribe o llama a nuestro equipo de apoyo y soporte de la plataforma GoPH. (+57) " +
          vartelfono[1].Numero,
        icon: "warning",
      });
    }
  };

  render() {
    if (Edge) {
      swal({
        title: "Atención !!",
        text: "Navegador no soportado.  Intenta usar Google Chrome o Firefox.",
        icon: "warning",
      });
    }

    if (this.state.redirectuser) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirectsusc) {
      return <Redirect to={Global.Ruta.Raiz + "/home"} />;
    }

    return (
      <div className="container-fluid bg-goph vh-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-md-3">
            <div className="card bg-dark">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                  <img
                    className="img-fluid"
                    src={logoGoph}
                    alt="GoPH"
                    style={{ width: "3.5rem" }}
                  />
                  <div className="row gx-3 fs-1">
                    <span className="col text-primary " onClick={this.onClickF}>
                      <i
                        className="fab fa-facebook-square"

                      ></i>
                    </span>
                    <span className="col text-danger " onClick={this.onClickY}>
                      <i
                        className="fab fa-youtube-square"

                      ></i>
                    </span>
                    <span className="col text-info " onClick={this.onClickT}>
                      <i
                        className="fab fa-twitter-square"

                      ></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <form
                  className="row row-cols-1 gy-3"
                  onSubmit={this.IniciarSesion}
                >
                  <div className="col">
                    <div className="input-group">
                      <span className="input-group-text bg-primary border-0">
                        <i className="fas fa-building text-light fs-5"></i>
                      </span>
                      <input
                        type="text"
                        name="phcod"
                        id="phcod"
                        ref={this.phcodRef}
                        className="form-control"
                        pattern="[0-9]*"
                        placeholder={"código"}
                        required
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="input-group">
                      <span className="input-group-text bg-primary border-0">
                        <i className="fas fa-user text-light fs-5"></i>
                      </span>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        ref={this.userRef}
                        className="form-control"
                        placeholder="usuario"
                        required
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="input-group">
                      <span className="input-group-text bg-primary border-0">
                        <i className="fas fa-key text-light fs-5"></i>
                      </span>
                      <input
                        type="password"
                        name="userpwd"
                        id="userpwd"
                        ref={this.pwdRef}
                        className="form-control"
                        placeholder="contraseña"
                        required
                      />
                    </div>
                  </div>
                  {/*<div className="row align-items-center remember">
                                          <input type="checkbox" disabled />Recuerdame
            div>*/}

                  <div className="pt-2 w-100 d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      <Traductor dir="btn.ingr" />
                    </button>
                  </div>
                </form>
              </div>

              <div className="card-footer pt-3">
                <div className="row justify-content-between align-items-center">
                  <div className="col">
                    <span className="text-light text-nowrap">
                      <Traductor dir="login.f_psw" />
                    </span>
                  </div>
                  <div className="col">
                    <a className="link-warning" href=" " onClick={this.soporte}>
                      <Traductor dir="login.st" />
                    </a>
                  </div>
                  <div className="col-12 text-light text-center">
                    Versión {this.state.version}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
