import React, { Component } from "react";
import HeaderA2 from "./Home/HeaderA2";
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";
import DespachoPort from './porteria/despacho/DespachoPort';

export default class Porteria extends Component {
  state = {
    redirect_home: false,
  };
  componentWillMount() {
    this.setState({redirect_home: ValidarRolPagina("Porteria")});
  }
  render() {


    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }
    return (
      <div className="g-r-prf3 bg-light vh-100">
        <HeaderA2 />
        <DespachoPort pagina={this.props.match.params.tipo}/>
      </div>
    );
  }
}
