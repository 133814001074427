
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Box, Autocomplete } from '@mui/material';
import { TextField } from "@material-ui/core";
import Global from '../../../../Global';
import axios from "axios";
import swal from "sweetalert";
import HourGlass from "./../../../principal/HourGlass";
import SimpleReactValidator from 'simple-react-validator'

export default class CrearRonda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Estado: [{ "id": 1, "label": "Activo" }, { "id": 2, "label": "Inactivo" }],
            HourGlass: true,
            nombre: "",
            id_ronda: "",
            estado: "",
            caso: "",

        }
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'Debe Ingresar mínimo 5 caracteres',
                max: 'Puede Ingresar Máximo 10 caracteres',
            }
        });
    }

    componentDidMount() {
        this.setState({
            nombre: this.props.CasoRonda.nombre,
            id_ronda: this.props.CasoRonda.id_ronda,
            estado: this.props.CasoRonda.estado,
            caso: this.props.CasoRonda.caso,
        }, () => {
            this.setState({ HourGlass: false })
        })
    }

    GuardarRonda = () => {
        if (this.validator.allValid()) {
            this.setState({ HourGlass: true })

            var body = {
                ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                usuario_id: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
                nombre: Buffer.from("" + this.state.nombre).toString("base64"),
            };
            axios.post(`${Global.Ruta.Url}ronda/newronda`, body).then(res => {
                if (res.data.message === "") {
                    var datos = {
                        estado: 1,
                        id_ronda: res.data.Ronda_Id,
                        nombre: this.state.nombre,
                    }
                    this.props.AñadirRonda(datos, 0)
                } else {
                    this.setState({ HourGlass: false })
                    swal({
                        title: "Atención",
                        text: `${res.data.message}`,
                        icon: "warning",
                        button: "Aceptar"
                    }).then(() => {
                        this.props.CerrarModal()
                    })
                }
            }).catch(err => {
                this.setState({ HourGlass: false })
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.props.CerrarModal()
                })
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    EditarRonda = () => {
        if (this.validator.allValid()) {
            this.setState({ HourGlass: true })

            var body = {
                ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                nombre: Buffer.from("" + this.state.nombre).toString("base64"),
                id_ronda: Buffer.from("" + this.state.id_ronda).toString("base64"),
                estado: Buffer.from("" + this.state.estado).toString("base64"),
            };
            axios.post(`${Global.Ruta.Url}ronda/updateronda`, body).then(res => {

                if (res.data.message === "") {
                    var datos = {
                        estado: this.state.estado,
                        id_ronda: this.state.id_ronda,
                        nombre: this.state.nombre,
                    }
                    this.props.AñadirRonda(datos, 1)
                } else {
                    this.setState({ HourGlass: false })
                    swal({
                        title: "Atención",
                        text: `${res.data.message}`,
                        icon: "warning",
                        button: "Aceptar"
                    }).then(() => {
                        this.props.CerrarModal()
                    })
                }
            }).catch(err => {
                this.setState({ HourGlass: false })
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.props.CerrarModal()
                })
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    render() {
        if (!this.state.HourGlass) {
            return (
                <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered onHide={this.props.CerrarModal}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: '16px' }} id="contained-modal-title-vcenter">
                            {this.state.caso === 0 ? (<span><strong style={{ fontWeight: '900' }}>Crea una Ronda</strong></span>) : (<span><strong style={{ fontWeight: '900' }}>Edita la Ronda {this.props.CasoRonda.nombre}</strong></span>)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Box component="form" sx={{ '& > :not(style)': { width: '100%' }, }} noValidate autoComplete="off">
                            <div className="container">
                                <div className="row" >
                                    <div className="col-12 my-1">
                                        <TextField
                                            value={this.state.nombre}
                                            fullWidth
                                            placeholder="Nombre de la Ronda"
                                            variant="outlined"
                                            margin="dense"
                                            onChange={(e) => this.setState({ nombre: e.target.value.toUpperCase() })}
                                            inputProps={{ maxLength: 49 }}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); if (this.state.caso === 0) { this.GuardarRonda() } } }}
                                            onKeyDown={(e) => { if (e.key === 'Tab') { e.preventDefault(); if (this.state.caso === 0) { this.GuardarRonda() } } }}
                                            onBlur={(e) => { e.preventDefault(); if (this.state.caso === 0) { this.GuardarRonda() } }}
                                        />
                                        <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message('nombre ', this.state.nombre, 'required', { className: 'text-danger' })}</label>
                                    </div>
                                    {this.state.caso === 1 ? (
                                        <div className="col-12 my-1">
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                noOptionsText="No Encontrado"
                                                size="small"
                                                defaultValue={this.state.Estado[this.state.estado - 1]}
                                                options={this.state.Estado}
                                                onChange={(event, newValue) => { this.setState({ estado: newValue.id }) }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Selecciona estado" />}
                                            />
                                            <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message('estado ', this.state.estado, 'required', { className: 'text-danger' })}</label>
                                        </div>
                                    ) : ("")}


                                </div>
                            </div>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.caso === 0 ? (<Button className="btn btn-primary" onClick={() => this.GuardarRonda()} size="sm"><i className="fas fa-save"></i> Guardar</Button>) : (
                            <Button className="btn btn-primary" onClick={() => this.EditarRonda()} size="sm"><i className="fas fa-save"></i> Editar</Button>
                        )}

                    </Modal.Footer>
                </Modal >
            );
        }
        return (
            <HourGlass show={this.state.HourGlass}></HourGlass>
        )
    }
}

