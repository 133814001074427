import React from "react";
import axios from "axios";
import Global from "../../../Global";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CookieSesion, capitalizeFirstLetter } from "../../Helpers";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  createTheme,
  ThemeProvider,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";

//------------------------------------------Opciones del Dropdown(seleccionador)---------------------------
const rol = [
  {
    value: "1",
    label: "Propietario",
  },
  {
    value: "2",
    label: "Propietario - Residente",
  },
  {
    value: "3",
    label: "Residente",
  },
];

const estado = [
  {
    value: "1",
    label: "Activo",
  },
  {
    value: "0",
    label: "Inactivo",
  },
];
//---------------------------------------------------------------------------------------------------------

//------------------------------------------------COLORES---------------------------------------------------
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#6f6f6f",
      light: "#fff",
    },
    secondary: {
      main: "#7a8be8",
    },
    background: {
      default: "#ffffff",
      paper: "#fbfafa",
    },
  },
});

//---------------------------------------------------------------------------------------------------------
class FormSusc extends React.Component {
  state = {
    opcion: 0,
    usertipo: 0,
    inmuebles: [],

    idSusc: 0,
    nombre: "",
    login: "",
    estado: "",
    rol: "",
    inmuebleid: "",
    correo: "",
    telefono: "",
    passwordMain: "",
    titulo: "",
    //mora: false,

    CpasswordMain: "",
    showPassword1: false,
    showPassword2: false,

    //redirects
    redirect_home: false,
    redirect_login: 0,
    redirect_suscriptores: false,
    data: false,

    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    var userRol = Global.GlobalUser.rol;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (
          this.state.Roles.includes(Global.GlobalUser.rol) ||
          parseInt(userRol) === 3
        ) {
          var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          };
          axios
            .post(Global.Ruta.Url + "admin/listinm/", body)
            .then((res) => {
              if (res.data.message === "") {
                var tem = [];
                var inmueble = {};
                for (var i = 0; i < res.data.Inmuebles.length; i++) {
                  inmueble = {
                    value: res.data.Inmuebles[i].inmuebleid,
                    label: res.data.Inmuebles[i].direccion,
                  };
                  tem.push(inmueble);
                }
                this.setState({ inmuebles: tem });
                if (Global.Form.tipo === 2) {
                  //2 es editar 1 es crear
                  this.setState({
                    opcion: Global.Form.tipo,
                    idSusc: parseInt(Global.Form.id),
                    nombre: Global.Form.nombre,
                    login: Global.Form.login,
                    rol: Global.Form.rol,
                    estado: Global.Form.estado,
                    inmuebleid: Global.Form.inmuebleid,
                    correo: Global.Form.correo,
                    telefono: Global.Form.telefono,
                    //mora: Global.Form.mora === "Si" ? true : false,
                    titulo: "Editar Suscriptor",
                    data: true,
                  });
                } else {
                  this.setState({
                    opcion: Global.Form.tipo,
                    titulo: "Nuevo Suscriptor",
                    data: true,
                  });
                }
              } else {
                swal({
                  title: "ERROR!",
                  text: res.data.message,
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({ redirect_suscriptores: true });
                });
              }
            })
            .catch((err) => {
              swal({
                title: "ATENCIÓN!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_suscriptores: true });
              });
            });
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  //----------------------------------------------BEGIN FUNCTION--------------------------------------------------
  // ACTUALIZA EL ESTADO CADA VEZ QUE SE OPRIMA UNA TECLA
  handleChange = (prop) => (event) => {
    this.setState((state) => ({
      ...state,
      [prop]: event.target.value,
    }));
  };
  // EL ESTADO PARA VER LAS CLAVES
  EstadoVerClaveMaestra = () => {
    this.setState((state) => ({
      showPassword1: !state.showPassword1,
    }));
  };
  //PREVENIR EL EVENTO AL OPRIMIR EN EL OJO DE VER CONTRASEÑA
  OprimirVerContrasena = (event) => {
    event.preventDefault();
  };
  //ACTUALIZAR ESTADO DE LOS COMBOBOX
  Combobox = (prop) => (e) => {
    this.setState({
      [prop]: e.target.value,
    });
  };
  // VALIDACIÓN DE CLAVES IGUALES PARA HABILITAR EL BOTON DE REGISTRAR
  ClavesIguales = () => {
    if (this.state.passwordMain === this.state.CpasswordMain) {
      return false;
    } else {
      return true;
    }
  };
  // VALIDACIÓN DE CLAVES IGUALES
  validarClaveM = (e) => {
    switch (e) {
      case 1:
        if (
          this.state.passwordMain === this.state.CpasswordMain ||
          this.state.CpasswordMain === ""
        ) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (
          this.state.passwordMain === this.state.CpasswordMain ||
          this.state.CpasswordMain === ""
        ) {
          return "";
        } else {
          return "Las contraseñas son distintas.";
        }
      default:
        return;
    }
  };
  controlEnable = () => {
    switch (this.state.opcion) {
      case 1:
        return true;
      case 2:
        return false;
      default:
        return false;
    }
  };
  //FUNCIÓN ENCARGADA DE ENVIAR EL FORMULARIO
  Enviarformulario = (e) => {
    e.preventDefault();

    this.setState({ data: false });

    var ban = 0;
    if (this.state.correo.includes(";")) {
      var tem = this.state.correo.split(";");
      for (var i = 0; i < tem.length; i++) {
        if (this.ValidarCorreo(tem[i].trim(), i + 1)) {
          ban = 1;
        }
      }
    } else {
      if (this.ValidarCorreo(this.state.correo.trim(), 1)) {
        ban = 1;
      }
    }

    if (ban === 0) {
      var body = {};
      var user = Global.GlobalUser.UserLog;

      if (this.state.opcion === 1) {
        body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          inmueble: Buffer.from("" + this.state.inmuebleid).toString("base64"),
          usernom: Buffer.from(this.state.nombre.toUpperCase()).toString(
            "base64"
          ),
          tiposusc: Buffer.from(this.state.rol).toString("base64"),
          userlogin: Buffer.from(
            capitalizeFirstLetter(this.state.login).trim()
          ).toString("base64"),
          email: Buffer.from(this.state.correo.trim()).toString("base64"),
          telefono: Buffer.from(this.state.telefono).toString("base64"),
          user: Buffer.from(user).toString("base64"),
          //mora: Buffer.from("" + this.state.mora).toString("base64"),
        };
        axios
          .post(Global.Ruta.Url + "admin/insertsusc", body)
          .then((res) => {
            if (res.data.message === "OK") {
              swal({
                title: "ATENCIÓN!",
                text: "El usuario se creado correctamente.",
                icon: "success",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_suscriptores: true });
              });
            } else if (res.data.message === "ERROR0") {
              swal({
                title: "ERROR!",
                text: "El usuario no se ha creado, por favor cambiar el usuario.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ data: true });
              });
            } else if (res.data.message === "ERROR1") {
              swal({
                title: "ERROR!",
                text: "El usuario no se ha creado, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_suscriptores: true });
              });
            } else if (res.data.message === "ERROR2") {
              swal({
                title: "ERROR!",
                text: "No es posible asignar dos propietarios al mismo inmueble.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ data: true });
              });
            } else {
              swal({
                title: "ATENCIÓN",
                text: res.data.message,
                icon: "warning",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_home: true });
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ERROR!",
              text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ redirect_suscriptores: true });
            });
          });
      } else if (this.state.opcion === 2) {
        var clave1 = this.state.passwordMain;

        if (this.state.passwordMain === "") {
          clave1 = "NA";
        }
        body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          userid: Buffer.from("" + this.state.idSusc).toString("base64"),
          inmuebleold: Buffer.from("" + this.state.inmuebleid).toString(
            "base64"
          ),
          usernom: Buffer.from(this.state.nombre.toUpperCase()).toString(
            "base64"
          ),
          tiposusc: Buffer.from("" + this.state.rol).toString("base64"),
          userlogin: Buffer.from(
            capitalizeFirstLetter(this.state.login).trim()
          ).toString("base64"),
          clave: Buffer.from(clave1).toString("base64"),
          estado: Buffer.from(this.state.estado).toString("base64"),
          email: Buffer.from(this.state.correo).toString("base64"),
          telefono: Buffer.from(this.state.telefono).toString("base64"),
          loginold: Buffer.from(Global.Form.nombre).toString("base64"),
          nombreold: Buffer.from(Global.Form.login).toString("base64"),
          user: Buffer.from(user).toString("base64"),
          //mora: Buffer.from("" + this.state.mora).toString("base64"),
        };
        axios
          .post(Global.Ruta.Url + "admin/editsusc", body)
          .then((res) => {
            if (res.data.message === "OK") {
              swal({
                title: "ATENCIÓN!",
                text: "El usuario se ha modificado correctamente.",
                icon: "success",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_suscriptores: true });
              });
            } else if (res.data.message === "ERROR3") {
              swal({
                title: "ERROR!",
                text: "El usuario no se pudo modificar, por favor cambiar el usuario.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ data: true });
              });
            } else if (res.data.message === "ERROR2") {
              swal({
                title: "ERROR!",
                text: "No es posible asignar dos propietarios al mismo inmueble.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ data: true });
              });
            } else if (res.data.message === "ERROR0") {
              swal({
                title: "ERROR!",
                text: "El usuario no se pudo modificar, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_suscriptores: true });
              });
            } else {
              swal({
                title: "ATENCIÓN",
                text: res.data.message,
                icon: "warning",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_home: true });
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ERROR!",
              text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ redirect_suscriptores: true });
            });
          });
      }
    } else {
      this.setState({ data: true });
    }
  };

  ResetearClave = () => {
    this.setState({ data: false });

    swal({
      title: "ATENCIÓN!",
      text: `Desea restablecer la contraseña de ${this.state.nombre}?`,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.ResetPwd("1");
      } else {
        this.setState({ data: true });
      }
    });
  };

  EmailBienvenida = () => {
    this.setState({ data: false });

    swal({
      title: "ALERTA!",
      text: `¿Esta seguro de enviar el email a ${this.state.nombre.toUpperCase()}?`,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.ResetPwd("3");
      } else {
        this.setState({ data: true });
      }
    });
  };

  ResetPwd = (caso) => {
    var ban = 0;
    if (this.state.correo.includes(";")) {
      var tem = this.state.correo.split(";");
      for (var i = 0; i < tem.length; i++) {
        if (this.ValidarCorreo(tem[i], i + 1)) {
          ban = 1;
        }
      }
    } else {
      if (this.ValidarCorreo(this.state.correo, 1)) {
        ban = 1;
      }
    }

    if (ban === 0) {
      var body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        userid: Buffer.from("" + this.state.idSusc).toString("base64"),
        login: Buffer.from("" + this.state.login).toString("base64"),
        email: Buffer.from("" + this.state.correo).toString("base64"),
        Nombre: Buffer.from("" + this.state.nombre.toUpperCase()).toString(
          "base64"
        ),
        Caso: Buffer.from("" + caso).toString("base64"),
      };

      axios
        .post(Global.Ruta.Url + "admin/resetpwd", body)
        .then((res) => {
          if (parseInt(caso) === 3) {
            var clave1 = this.state.passwordMain;

            if (this.state.passwordMain === "") {
              clave1 = "NA";
            }

            var user = Global.GlobalUser.UserLog;

            body = {
              ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
              codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
              userid: Buffer.from("" + this.state.idSusc).toString("base64"),
              inmuebleold: Buffer.from("" + this.state.inmuebleid).toString(
                "base64"
              ),
              usernom: Buffer.from(this.state.nombre.toUpperCase()).toString(
                "base64"
              ),
              tiposusc: Buffer.from("" + this.state.rol).toString("base64"),
              userlogin: Buffer.from(
                capitalizeFirstLetter(this.state.login).trim()
              ).toString("base64"),
              clave: Buffer.from(clave1).toString("base64"),
              estado: Buffer.from(this.state.estado).toString("base64"),
              email: Buffer.from(this.state.correo).toString("base64"),
              telefono: Buffer.from(this.state.telefono).toString("base64"),
              loginold: Buffer.from(Global.Form.nombre).toString("base64"),
              nombreold: Buffer.from(Global.Form.login).toString("base64"),
              user: Buffer.from(user).toString("base64"),
              //mora: Buffer.from("" + this.state.mora).toString("base64"),
            };
            axios
              .post(Global.Ruta.Url + "admin/editsusc", body)
              .then((res1) => {
                if (res.data.message === "OK") {
                  swal({
                    title: "ESTUPENDO",
                    text:
                      parseInt(caso) === 3
                        ? "El e-mail fué envíado satisfactoriamente."
                        : "La contraseña fue restablecida a 4321.",
                    icon: "success",
                    button: "Aceptar",
                  }).then(() => {
                    this.setState({ redirect_suscriptores: true });
                  });
                } else {
                  swal({
                    title: "ATENCIÓN",
                    text: res.data.message,
                    icon: "warning",
                    button: "Aceptar",
                  }).then(() => {
                    this.setState({ redirect_suscriptores: true });
                  });
                }
              })
              .catch((err) => {
                console.log(err + "5172");
              });
          } else {
            this.setState({ redirect_suscriptores: true });
          }
        })
        .catch((err) => {
          swal({
            title: "ATENCIÓN!",
            text: "No se pudo restablecer la clave, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_suscriptores: true });
          });
        });
    } else {
      this.setState({ data: true });
    }
  };

  ValidarCorreo = (data, indice) => {
    var RegExp =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!RegExp.test(data)) {
      swal({
        title: "ATENCIÓN!",
        text: `El correo No. ${indice} no es valido.`,
        icon: "warning",
        button: "Aceptar",
      });
      return true;
    }
  };
  //----------------------------------------------END FUNCTIONS--------------------------------------------------------
  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.redirect_suscriptores === true) {
      return <Redirect to={Global.Ruta.Raiz + "/suscriptores"} />;
    }

    if (this.state.data === true) {
      return (
        <div className="container-fluid bg-light">
          <div className="col-12 h-100">
            <div className="row pt-4 overflow-hidden h-100">
              <div className="col-12 col-md-10 col-lg-11 col-xl-6 mx-auto pb-3">
                <div className="card p-3 shadow-lg">
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <div className="titulo">
                        {this.state.titulo}{" "}
                        <small className="text-muted">
                          {this.state.nombre}
                        </small>
                      </div>
                      <p className="texto-descriptivo text-muted fw-normal m-0">
                        {" "}
                        Diligencie los datos a editar y pulse el botón en
                        registrar cambios.
                      </p>
                      <hr />
                    </div>
                    <ThemeProvider theme={theme}>
                      <div className="col-12 mt-1">
                        <Box noValidate autoComplete="off">
                          <Box
                            className="row row-cols-2 gy-3 justify-content-center"
                            component="form"
                            onSubmit={this.Enviarformulario}
                          >
                            <div className="col">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Nombre"
                                name="nombres"
                                variant="outlined"
                                defaultValue={this.state.nombre}
                                onChange={this.handleChange("nombre")}
                              />
                            </div>

                            <div className="col">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                required
                                fullWidth
                                id="login"
                                label="Usuario"
                                name="login"
                                variant="outlined"
                                defaultValue={this.state.login}
                                onChange={this.handleChange("login")}
                              />
                            </div>

                            <div className="col">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                required
                                fullWidth
                                id="correo"
                                label="E-mail"
                                name="correo"
                                variant="outlined"
                                defaultValue={this.state.correo}
                                onChange={this.handleChange("correo")}
                              />
                            </div>

                            <div className="col">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                required
                                fullWidth
                                id="telefono"
                                label="Telefono"
                                name="telefono"
                                variant="outlined"
                                defaultValue={this.state.telefono}
                                onChange={this.handleChange("telefono")}
                              />
                            </div>

                            <div className="col-12 h-auto">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                disabled={
                                  this.state.opcion === 2 ? true : false
                                }
                                id="inmueble"
                                required
                                select
                                fullWidth
                                label="Inmueble"
                                defaultValue={this.state.inmuebleid}
                                value={this.state.inmuebleid}
                                onChange={this.Combobox("inmuebleid")}
                                variant="outlined"
                              >
                                {this.state.inmuebles.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <p
                                className="m-0 ms-2 text-muted"
                                style={{ fontSize: 12 }}
                              >
                                Seleccione el inmueble
                              </p>
                            </div>

                            <div className="col">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                required
                                select
                                label="Rol"
                                defaultValue={this.state.rol}
                                value={this.state.rol}
                                fullWidth
                                onChange={this.Combobox("rol")}
                                variant="outlined"
                                inputProps={{
                                  min: 0,
                                  style: { textAlign: "center" },
                                }}
                              >
                                {rol.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <p
                                className="m-0 ms-2 text-muted"
                                style={{ fontSize: 12 }}
                              >
                                Seleccione un rol
                              </p>
                            </div>

                            <div className="col">
                              <TextField
                                size="small"
                                className="shadow-sm"
                                disabled={this.controlEnable()}
                                id="estado"
                                required={!this.controlEnable()}
                                select
                                fullWidth
                                label="Estado"
                                defaultValue={
                                  this.state.opcion === 1
                                    ? 1
                                    : this.state.estado
                                }
                                value={
                                  this.state.opcion === 1
                                    ? 1
                                    : this.state.estado
                                }
                                onChange={this.Combobox("estado")}
                                variant="outlined"
                              >
                                {estado.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <p
                                className="m-0 ms-2 text-muted"
                                style={{ fontSize: 12 }}
                              >
                                Seleccione el estado de la cuenta
                              </p>
                            </div>

                            {/*<div xs={12} lg={12}>
                                  <Form.Check type="checkbox" checked={this.state.mora} onChange={(event) => { this.setState({ mora: event.target.checked }); }} className="fs-5"
                                    label="Marcar como moroso" />
                                  <hr className="border border-1 border-dark mb-1 mt-2" />
                                    </div>*/}

                            <div className="col">
                              <FormControl
                                disabled={this.controlEnable()}
                                fullWidth
                                variant="outlined"
                                size="small"
                              >
                                <InputLabel required={false}>
                                  Contraseña
                                </InputLabel>
                                <OutlinedInput
                                  id="constraseña"
                                  type={
                                    this.state.showPassword1
                                      ? "text"
                                      : "password"
                                  }
                                  value={this.state.passwordMain}
                                  onChange={this.handleChange("passwordMain")}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.EstadoVerClaveMaestra}
                                        onMouseDown={this.OprimirVerContrasena}
                                        edge="end"
                                      >
                                        {this.state.showPassword1 ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="contraseña"
                                  variant="filled"
                                  color="secondary"
                                />
                              </FormControl>
                            </div>

                            <div className="col">
                              <FormControl
                                disabled={this.controlEnable()}
                                error={this.validarClaveM(1)}
                                fullWidth
                                variant="outlined"
                                size="small"
                              >
                                <InputLabel required={false}>
                                  Comprobar contraseña
                                </InputLabel>
                                <OutlinedInput
                                  id="c-contraseña-main"
                                  type={
                                    this.state.showPassword1
                                      ? "text"
                                      : "password"
                                  }
                                  value={this.state.CpasswordMain}
                                  onChange={this.handleChange("CpasswordMain")}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.EstadoVerClaveMaestra}
                                        onMouseDown={this.OprimirVerContrasena}
                                        edge="end"
                                      >
                                        {this.state.showPassword1 ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="contraseña"
                                  variant="filled"
                                  color="secondary"
                                />
                                <FormHelperText id="c-contraseña-main">
                                  {this.validarClaveM(2)}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            <div className="col-12 mt-3">
                              <div className="row justify-content-center">
                                <div className="col-4 d-grid">
                                  <Button
                                    className="btn btn-primary text-capitalize"
                                    variant="contained"
                                    onClick={() => {
                                      this.setState({
                                        redirect_suscriptores: true,
                                      });
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-chevron-left me-1"></i>{" "}
                                    Regresar
                                  </Button>
                                </div>
                                <div className="col-4 d-grid">
                                  <Button
                                    onClick={this.ResetearClave}
                                    disabled={this.controlEnable()}
                                    className="btn btn-warning text-nowrap"
                                  >
                                    {" "}
                                    <i className="fas fa-arrows-rotate me-1"></i>{" "}
                                    Reiniciar Contraseña
                                  </Button>
                                </div>
                                <div className="col-4 d-grid">
                                  <Button
                                    disabled={this.ClavesIguales()}
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {" "}
                                    <i className="fas fa-save me-1"></i>{" "}
                                    {this.state.opcion === 1
                                      ? "Guardar Datos"
                                      : "Registrar Cambios"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                            {this.controlEnable() ? (
                              ""
                            ) : (
                              <div className="col-12 d-grid mt-3">
                                <Button
                                  onClick={this.EmailBienvenida}
                                  className="btn btn-r-violet  "
                                >
                                  {" "}
                                  <i className="fas fa-paper-plane me-1"></i>{" "}
                                  Email de Bienvenida
                                </Button>
                              </div>
                            )}
                          </Box>
                        </Box>
                      </div>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default FormSusc;
