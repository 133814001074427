import React, { Component } from 'react';
import axios from "axios";
import Global from "../../../Global";

import MaterialTable from "material-table";
import { Modal } from 'react-bootstrap';


const columns = [
    {
        field: "idinmdir",
        title: "Id inmueble",
        hidden: true
    },
    {
        field: "nombres",
        title: "Nombres",
    },
    {
        field: "direccion",
        title: "Dirección",
    },
    {
        field: "tipo",
        title: "Tipo",
    },
];


class TablaListSubApp extends Component {

    state = {
        redirect_home: false,
        redirect_login: 0,
        form_user: 0,
        rows: [],
        nombres: [],
        newData: [],
        count: 0,
        data: false,
        contactos: [],
    }

    componentWillMount() {
        axios.get(Global.Ruta.Url + "mensajeria/suscapp/" + Buffer.from(Global.Ph.Codigo).toString("base64"))
            .then((res) => {
                if (res.data.message === "") {
                    var tem = [];
                    var susc = {};
                    for (var i = 0; i < res.data.Susc.length; i++) {
                        susc = {
                            idinmdir: res.data.Susc[i].idinmdir,
                            direccion: res.data.Susc[i].direccion,
                            nombres: res.data.Susc[i].nombres.toUpperCase(),
                            tipo: res.data.Susc[i].tipo
                        };
                        tem.push(susc);
                    }
                    this.setState({ rows: tem, data: true });
                } else {
                    this.props.cerrar();
                }
            }).catch(err => {
                this.props.cerrar();
            })

    }

    render() {


        if (this.state.data === true) {
            return (
                <Modal.Body>
                    <div className="row align-items-center justify-content-center px-3">
                        <div className="col-12 p-0 my-3 ">
                            <MaterialTable
                                columns={columns}
                                data={this.state.rows}
                                title={this.props.tituloT}

                                options={{
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    rowStyle:{fontSize: "0.9rem"},
                                }}

                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos por mostrar.',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'registros',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar',
                                        nRowsSelected: '{0} ' + this.props.tituloT + '(s) Seleccionado(s)'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );

    }

}

export default TablaListSubApp;