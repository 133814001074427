import React, { Component } from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'

import axios from "axios";
import Global from "../../../Global";

class CartaDetalle extends Component {

    state = {
        Enviados: [],
        data: false,
        TituloMensaje: "",
    }

    componentWillMount() {
        axios.get(Global.Ruta.Url + "mensajeria/detallemensaje/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from("" + this.props.mensajeid).toString("base64"))
            .then((res) => {
                var Enviados = [];
                if (res.data.message === "") {
                    for (var cadauno of res.data.SuscEnviados) {
                        var remitente = cadauno.nombres + " - " + cadauno.direccion + ". " + cadauno.tipo
                        Enviados.push(remitente);
                    }
                } else {
                    Enviados.push("Propietarios y/o Residentes.")
                }
                this.setState({ Enviados: Enviados, data: true, TituloMensaje: this.props.titulomensaje })
            }).catch(err => {
                this.props.cerrar();
            })
    }

    render() {
        if (this.state.data === true) {
            return (
                <div className="container-fluid my-3">
                    <div className="row justify-content-center">
                        <Card className=" col-12 cardDetalle">
                            <CardContent className="row">
                                <div className="row w-100 justify-content-center overflow-hidden">
                                    <Typography className="col-12 text-center fw-bold text-dark" variant="h5" gutterBottom>
                                        {this.state.TituloMensaje}
                                    </Typography>

                                    <Typography className="col-12 pl-3 text-secondary" variant="h5" component="div">
                                        Enviado a:
                                    </Typography>
                                </div>
                                <div className="row row-cols-2 g-2 overflow-auto">
                                        {this.state.Enviados.map((Nombre, indice) => (
                                            <div className="col">
                                                <div className="card w-100 px-2">
                                                    <div className="cover-marqss" key={indice}>
                                                        <p className='marqss text-nowrap'>{Nombre.toUpperCase()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}

export default CartaDetalle;