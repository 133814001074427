import React, { Component } from "react";

//import de componentes
import HeaderA2 from "./Home/HeaderA2";
import ArchivoReceptor from "./subirArchivos/ArchivoRecepcion"
import "../../assets/css/uploader.css"
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";

class SubirArchivo extends Component {
    state = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('SubirArchivo') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }
        return (
            <div className="g-r-prf3 vh-100 bg-light">
                <HeaderA2 />
                <ArchivoReceptor />
                 
            </div>
        );
    }
}

export default SubirArchivo;
