import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { CerrarSesion } from "./../../Helpers";
import Global from '../../../Global';
import LectorQR from './../Home/LectorQR'
import Loader from "../../principal/Loader";
import { Card } from "@mui/material";
import swal from 'sweetalert';
import axios from "axios";
import HourGlass from "./../../principal/HourGlass";

export default class RondaPortero extends Component {

    state = {
        redirect_home: false,
        redirect_login: false,
        Data: false,
        activQr: false,
        openlugares: false,
        HourGlass: false,
        watchId: undefined,
        indice: undefined,
        Orden: [],
        Lugares: []
    }




    componentDidMount() {
        if (Global.Geolocalizacion.Latitud === undefined && Global.Geolocalizacion.Longitud === undefined) {
            swal({
                title: "Atención",
                text: `Por favor active la geolocalización para utilizar la función de rondero`,
                icon: "warning",
                button: "Aceptar"
            }).then(() => {
                CerrarSesion()
                this.setState({ redirect_login: true });
            })
        } else {
            this.RutaOrden()
        }
    }

    RutaOrden = () => {
        this.setState({ Data: false })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}ronda/listarrutaorden`, body).then(res => {
            if (res.data.message === "") {
                this.setState({ Orden: res.data.Orden }, () => {
                    this.setState({ Data: true })
                })
            } else {
                swal({
                    title: "Atención",
                    text: `${res.data.message}`,
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_login: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_login: true })
            })
        });
    }

    EstablecerLugares = (index) => {
        if (parseInt(index) !== parseInt(this.state.indice)) {
            var temp = this.state.Orden
            this.setState({ Lugares: temp[index].lugar, indice: index }, () => {
                this.setState({ openlugares: true })
            })
        } else {
            this.setState({ openlugares: false, indice: undefined })
        }


    }

    activQr = (data) => {
        this.setState({ activQr: !data })
    }

    DataLectorQR = (var1) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    Global.Geolocalizacion.Latitud = position.coords.latitude
                    Global.Geolocalizacion.Longitud = position.coords.longitude
                    this.setState({ HourGlass: true })
                    var body = {
                        ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                        codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                        id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
                        id_ronda_lugar: Buffer.from("" + var1).toString("base64"),
                        latitude: Buffer.from("" + position.coords.latitude).toString("base64"),
                        longitude: Buffer.from("" + position.coords.longitude).toString("base64"),

                    };
                    axios.post(`${Global.Ruta.Url}ronda/registrarronda`, body).then(res => {
                          this.setState({ HourGlass: false })
                        if (res.data.message === "") {
                            if (res.data.ant === "") {
                                swal({
                                    title: "",
                                    text: `Ronda registrada en el horario ${res.data.Horario}`,
                                    icon: "success",
                                    button: "Aceptar"
                                })
                            } else {
                                if (res.data.Antes === null && res.data.Despues === null) {
                                    swal({
                                        title: "",
                                        text: `No hay ronda para registrar`,
                                        icon: "warning",
                                        button: "Aceptar"
                                    })
                                } else {
                                    swal("Seleccione la hora para registrar la ronda", {
                                        buttons: {
                                            cancel: "Canelar",
                                            catch: {
                                                text: `${res.data.Antes.elemento.hora}`,
                                                value: "catch",
                                            },
                                            defeat: `${res.data.Despues.elemento.hora}`,
                                        },
                                    }).then((value) => {
                                        switch (value) {
                                            case "defeat":
                                                this.RegistrarRondaManual(res.data.Despues.elemento)
                                                break;
                                            case "catch":
                                                this.RegistrarRondaManual(res.data.Antes.elemento)
                                                break;
                                            default:
                                                swal({
                                                    title: "Atención",
                                                    text: "Ronda no registrada",
                                                    icon: "warning",
                                                    button: "Aceptar",
                                                });
                                        }
                                    });
                                }
                                // if (parseInt(res.data.Caso) === 2) {

                                // } else if (parseInt(res.data.Caso) === 1) {
                                //     swal({
                                //         title: "Atención",
                                //         text: "En este momento no puede registrar la ronda, ya se encuentra registrada",
                                //         icon: "warning",
                                //         button: "Aceptar"
                                //     })
                                // } else {
                                //     swal({
                                //         title: "",
                                //         text: `¿Desea ingresar la ronda en esta hora ${res.data.Antes.hora}?`,
                                //         icon: "warning",
                                //         buttons: ["Canelar", "Aceptar"],

                                //     }).then((willDelete) => {
                                //         if (willDelete) {
                                //             this.RegistrarRondaManual(res.data.Antes)
                                //         } else {
                                //             swal({
                                //                 title: "Atención",
                                //                 text: "Ronda no registrada",
                                //                 icon: "warning",
                                //                 button: "Aceptar",
                                //             });
                                //         }
                                //     });
                                // }
                            }
                        } else {
                            swal({
                                title: "Atención",
                                text: `${res.data.message}`,
                                icon: "warning",
                                button: "Aceptar"
                            })
                        }
                    }).catch(err => {
                        swal({
                            title: "ERROR!",
                            text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                            icon: "error",
                            button: "Aceptar"
                        }).then(() => {
                            this.setState({ redirect_login: true })
                        })
                    });
                }, (err) => {
                    swal({
                        title: "Atención",
                        text: `Por favor active la geolocalización para utilizar la función de rondero`,
                        icon: "warning",
                        button: "Aceptar"
                    }).then(() => {
                        CerrarSesion()
                        this.setState({ redirect_login: true });
                    })
                }
            )
        } else {
            swal({
                title: "Atención",
                text: `Por favor active la geolocalización para utilizar la función de rondero`,
                icon: "warning",
                button: "Aceptar"
            }).then(() => {
                CerrarSesion()
                this.setState({ redirect_login: true });
            })
        }
    };


    RegistrarRondaManual = (data) => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    Global.Geolocalizacion.Latitud = position.coords.latitude
                    Global.Geolocalizacion.Longitud = position.coords.longitude
                    this.setState({ HourGlass: true })
                    var body = {
                        ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                        codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                        id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
                        id_ronda: Buffer.from("" + data.id_ronda).toString("base64"),
                        id_ronda_horario: Buffer.from("" + data.id_ronda_horario).toString("base64"),
                        latitude: Buffer.from("" + position.coords.latitude).toString("base64"),
                        longitude: Buffer.from("" + position.coords.longitude).toString("base64"),
                    };
                    axios.post(`${Global.Ruta.Url}ronda/registrarrondamanual`, body).then(res => {
                        this.setState({ HourGlass: false })
                        if (res.data.message === "") {
                            swal({
                                title: "",
                                text: "Ronda registrada",
                                icon: "success",
                                button: "Aceptar"
                            })
                        } else {
                            swal({
                                title: "Atención",
                                text: `${res.data.message}`,
                                icon: "warning",
                                button: "Aceptar"
                            })
                        }
                    }).catch(err => {
                        swal({
                            title: "ERROR!",
                            text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                            icon: "error",
                            button: "Aceptar"
                        }).then(() => {
                            this.setState({ redirect_login: true })
                        })
                    });
                }, (err) => {
                    swal({
                        title: "Atención",
                        text: `Por favor active la geolocalización para utilizar la función de rondero`,
                        icon: "warning",
                        button: "Aceptar"
                    }).then(() => {
                        CerrarSesion()
                        this.setState({ redirect_login: true });
                    })
                }
            )
        } else {
            swal({
                title: "Atención",
                text: `Por favor active la geolocalización para utilizar la función de rondero`,
                icon: "warning",
                button: "Aceptar"
            }).then(() => {
                CerrarSesion()
                this.setState({ redirect_login: true });
            })
        }


    }

    render() {

        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
        }
        if (this.state.redirect_login) {
            return <Redirect to={Global.Ruta.Raiz + "/"} />;
        }
        if (this.state.Data) {
            return (
                <div className='container-fluid' style={{ minWidth: "100vw", maxWidth: "100vw", minHeight: "100vh", maxHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <HourGlass show={this.state.HourGlass}></HourGlass>
                    <div className='d-flex align-items-center justify-content-center flex-grow-1'> {/* Utilizamos flex-grow-1 para que ocupe todo el espacio disponible */}
                        <Card className="border border-light my-2 mx-2 py-2 d-flex flex-column position-relative bg-secondary opacity-75 vh-50 w-100">
                            <div className='py-3 px-3 mt-2 d-flex align-items-center justify-content-center'>
                                {this.state.activQr ? (
                                    <LectorQR CloseLector={() => this.activQr(this.state.activQr)} DataLectorQR={this.DataLectorQR} />
                                ) : ("")}
                            </div>
                            <div className='my-2 pt-4' style={{ position: "absolute", left: "50%", bottom: "0", transform: "translateX(-50%)" }}>
                                <button className='btn btn-primary' onClick={() => { this.setState({ activQr: !this.state.activQr }) }}>
                                    <i className="fa-solid fa-qrcode"></i>
                                </button>
                            </div>
                        </Card>
                    </div>
                    <div className='pb-4'>
                        {this.state.Orden.map((orden, index) => (
                            <div className='d-inline ' key={index}>
                                <button className='btn btn-primary mx-2 my-2' onClick={() => { this.EstablecerLugares(index) }}>{orden.ronda}</button>
                            </div>
                        ))}
                        {this.state.openlugares ? (
                            <Card className='bg-light opacity-100'>
                                {this.state.Lugares.map((lugares, index) => (
                                    <div key={index} className='my-2 mx-2 d-inline '>
                                        <span className='text-primary font-normal p-2  bg-white ms-1 rounded'>{lugares.lugar}</span>
                                    </div>
                                ))}
                            </Card>
                        ) : ("")}
                    </div>
                </div>
            )
        }

        return (
            <div style={{ minHeight: this.props.caso === undefined ? "100vh" : "10%" }}>
                <Loader princial={true} uno={"Rondas"} dos={"Lugares"} tres={"QR"} />
            </div>
        );
    }
}
