import React, {Component} from "react";
import MaterialTable from "material-table";
import {CookieSesion} from "../../Helpers";
import {Redirect} from "react-router-dom";

import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import Es from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {TextField} from "@material-ui/core";
import {Button} from "@material-ui/core";
import {Navbar} from "react-bootstrap";
import moment from "moment";
import HeadTitle from "../../principal/HeadTitle";

const columns = [
  {
    field: "suscriptorid",
    title: "Radicado",
  },
  {
    field: "asunto",
    title: "Asunto",
  },
  {
    field: "mensaje",
    title: "Mensaje",
  },
  {
    field: "fecha_solicitud",
    title: "Fecha de la solicitud",
  },
  {
    field: "nombre_susc",
    title: "Nombre",
  },
  {
    field: "direccion",
    title: "Dirección",
  },
  {
    field: "nombre_portero",
    title: "Portero",
  },
  {
    field: "fecha_aprobacion",
    title: "Fecha de Radicación",
  },
  {
    field: "estado",
    title: "Estado",
    cellStyle: function (rowData) {
      if (rowData === "Aceptada") {
        return {color: "Green", fontWeight: "bold"};
      } else if (rowData === "Rechazada") {
        return {color: "OrangeRed", fontWeight: "bold"};
      } else if (rowData === "Cancelada") {
        return {color: "Gold", fontWeight: "bold"};
      }
    },
  },
];

export default class TablaAutrz extends Component {
  state = {
    estado: "autorizado",
    rows: [],
    data: false,

    //DATA
    Rol: 0,
    Historial: false,
    Solicitudes: [],
    Busqueda: "",
    TemParaBusqueda: [],

    fechai: null,
    fechaf: null,
    fechaAct: new Date(),

    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          this.HistorialSolicitudes();
        } else {
          this.setState({redirect_home: true});
        }
      } else {
        this.setState({redirect_login: 1});
      }
    } else {
      this.setState({redirect_login: 1});
    }
  }

  HistorialSolicitudes = () => {
    this.setState({
      data: false,
    });

    var Caso = "";
    var fechai = null;
    var fechaf = null;

    if (this.state.fechai === null && this.state.fechaf === null) {
      Caso = "0";
    } else if (this.state.fechai !== null && this.state.fechaf === null) {
      Caso = "1";
      fechai = this.state.fechai;
    } else if (this.state.fechai === null && this.state.fechaf !== null) {
      Caso = "2";
      fechaf = this.state.fechaf;
    } else if (this.state.fechai !== null && this.state.fechaf !== null) {
      Caso = "3";
      fechai = this.state.fechai;
      fechaf = this.state.fechaf;
    }

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      caso: Buffer.from("" + Caso).toString("base64"),
      fechai: Buffer.from("" + moment(fechai).format("DD/MM/YYYY")).toString(
        "base64"
      ),
      fechaf: Buffer.from("" + moment(fechaf).format("DD/MM/YYYY")).toString(
        "base64"
      ),
    };

    axios
      .post(`${Global.Ruta.Url}admin/historialsolicitudporteria`, body)
      .then((res) => {
        console.log(res.data.message);
        if (res.data.message === "") {
          this.setState({
            rows: res.data.Solicitudes,
            data: true,

            //limpiar variables de filtro
            fechai: null,
            fechaf: null,
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.data) {
      return (
        <div className="container-fluid bg-light pb-5">
          <div className="row justify-content-center gy-3 pt-3">
            <div className="col-12">
              <HeadTitle
                titulo="Solicitudes a Portería"
                subTitulo="Historial de las solicitudes realizadas a portería por los residentes"
                classNameOwn="row px-3"
                indicadores={[]}
              />
            </div>
            <div className='col-12'>
              <div className="card bg-light text-center shadow-sm">
                <div className="card-body py-2">
                  <Navbar
                    className="navbar bg-light"
                    expand="lg"
                    style={{backgroundColor: "gainsboro"}}
                  >
                    <div className="navbar-brand ms-2">Filtro</div>
                    <Navbar.Toggle aria-controls="id_navbar_1" />
                    <Navbar.Collapse className="py-3 py-md-0" id="id_navbar_1">
                      <ul className="navbar-nav row justify-content-end align-items-center py-2 w-100 mx-0">
                        <div className="col-auto col-lg-10">
                          <div className="row justify-content-center">
                            <li className="col-auto">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={Es}
                              >
                                <DatePicker
                                  label="Fecha inicio"
                                  value={this.state.fechai}
                                  maxDate={this.state.fechaAct}
                                  onChange={(date) =>
                                    this.setState({fechai: date})
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </li>
                            <li className="col-auto">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={Es}
                              >
                                <DatePicker
                                  label="Fecha final"
                                  value={this.state.fechaf}
                                  minDate={this.state.fechai}
                                  maxDate={this.state.fechaAct}
                                  onChange={(date) =>
                                    this.setState({fechaf: date})
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </li>
                          </div>
                        </div>
                      </ul>
                      <div className="col-auto d-flex justify-content-center px-3">
                        <Button
                          className="mx-1"
                          color="error"
                          variant="contained"
                          onClick={this.HistorialSolicitudes}
                        >
                          {" "}
                          Quitar Filtro{" "}
                        </Button>
                        <Button
                          className="mx-1"
                          color="primary"
                          variant="contained"
                          onClick={this.HistorialSolicitudes}
                        >
                          {" "}
                          Filtrar{" "}
                        </Button>
                      </div>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
            </div>
            <div className="col-12">
              <MaterialTable
                title="Autorizaciones"
                columns={columns}
                data={this.state.rows}
                options={{
                  rowStyle: {fontSize: "0.9rem"},
                  pageSize: 10,
                  actionsColumnIndex: -1,
                }}
                localization={{
                  header: {
                    actions: "Estado",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
