
import React from "react";
import axios from "axios";
import swal from 'sweetalert';
import Global from "../../../Global";

import { Button, Modal } from "react-bootstrap";
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, styled, FormLabel, FormHelperText, Autocomplete } from '@mui/material';
import Horario from "./modal/Horario"
import SimpleReactValidator from 'simple-react-validator'
import 'simple-react-validator/dist/locale/es';


class PopupRecurso extends React.Component {

    state = {
        configadicional: false,
        ModalHorario: false,
        Mantenimiento: false,
        dias: [{ id: "Mon", label: "Lunes" }, { id: "Tue", label: "Martes" }, { id: "Wed", label: "Miércoles" }, { id: "Thu", label: "Jueves" }, { id: "Fri", label: "Viernes" }, { id: "Sat", label: "Sábado" }, { id: "Sun", label: "Domingo" }],
        id: "",
        nombre: "",
        aprobacion: "",
        cantresem: "",
        descripcion: "",
        dia_mantenimiento: "",
        disponible: "",
        estado: "",
        festivo: "",
        horario: [],
        limiteper: "",
        limiteporcasa: "",
        limiter: "",
        limresxdia: "",
        morosos: "",
        ragotadores: "",
        disponibilidad: "",
        grupo: ""
    }

    componentWillMount() {
        this.setState({
            id: this.props.id_recurso,
            nombre: this.props.nombre,
            aprobacion: parseInt(this.props.Zona.aprobacion) === 0 ? false : true,
            cantresem: this.props.Zona.cantresem,
            descripcion: this.props.Zona.descripcion,
            dia_mantenimiento: this.props.Zona.dia_mantenimiento === "" ? ("") : (this.switchDia(this.props.Zona.dia_mantenimiento)),
            disponible: parseInt(this.props.Zona.disponible) === 0 ? false : true,
            estado: parseInt(this.props.Zona.estado) === 0 ? false : true,
            festivo: this.props.Zona.festivo,
            horario: this.props.Zona.horario,
            limiteper: this.props.Zona.limiteper,
            limiteporcasa: this.props.Zona.limiteporcasa,
            limiter: parseInt(this.props.Zona.limiter) === 0 ? false : true,
            limresxdia: this.props.Zona.limresxdia,
            morosos: this.props.Zona.morosos,
            ragotadores: parseInt(this.props.Zona.ragotadores) === 0 ? false : true,
            disponibilidad: this.props.Zona.disponibilidad,
            Mantenimiento: this.props.Zona.dia_mantenimiento !== "" ? true : false,
            grupo: this.props.grupo
        })

        this.validator = new SimpleReactValidator({
            locale: 'es',
            messages: {
                required: 'El campo es requerido.',
                //  min: 'El tiempo debe ser mínimo de 1 minuto',
                // max: 'El tiempo debe ser máximo de 1440 minutos',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                alpha_num_space: 'Solo debe contener letras, números y espacios.'
            }
        });

    };

    DiasRender = (dia) => {
        switch (dia) {
            case 'Mon':
                return "Lunes"
            case 'Tue':
                return "Martes"
            case 'Wed':
                return "Miércoles"
            case 'Thu':
                return "Jueves"
            case 'Fri':
                return "Viernes"
            case 'Sat':
                return "Sábado"
            case 'Sun':
                return "Domingo"
            default:
                return ""
        }
    }

    switchId_Dia = (dia) => {
        switch (dia) {
            case 'Mon':
                return 1
            case 'Tue':
                return 2
            case 'Wed':
                return 3
            case 'Thu':
                return 4
            case 'Fri':
                return 5
            case 'Sat':
                return 6
            case 'Sun':
                return 0
            default:
                return 7
        }
    }

    switchDia = (dia) => {
        switch (parseInt(dia)) {
            case 1:
                return 'Mon'
            case 2:
                return 'Tue'
            case 3:
                return 'Wed'
            case 4:
                return 'Thu'
            case 5:
                return 'Fri'
            case 6:
                return 'Sat'
            case 0:
                return 'Sun'
            default:
                return 7
        }
    }

    GuardarHorario = (datos) => {
        this.setState({ horario: datos, ModalHorario: false })
    }

    Guardar = () => {

        if (this.state.horario.length === 0) {
            swal({
                title: "",
                text: "Por favor ingrese un horario",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ configadicional: false, ModalHorario: true })
            });
        } else {
            if (this.validator.allValid()) {
                var body = {
                    ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
                    codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
                    nombrerecurso: Buffer.from("" + this.state.nombre).toString('base64'),
                    descripcion: Buffer.from("" + this.state.descripcion).toString('base64'),
                    disponibilidad: Buffer.from("" + this.state.disponibilidad).toString('base64'),
                    grupo: Buffer.from("" + (this.state.id === 0 ? (this.state.grupo) : (this.state.id))).toString('base64'),
                    diadisponible: Buffer.from("" + (this.state.disponible ? 1 : 0)).toString('base64'),
                    limpersonas: Buffer.from("" + this.state.limiteper).toString('base64'),
                    activo: Buffer.from("" + (this.state.estado ? 1 : 0)).toString('base64'),
                    limiter: Buffer.from("" + (this.state.limiter ? 1 : 0)).toString('base64'),
                    cantresem: Buffer.from("" + this.state.cantresem).toString('base64'),
                    limiteporcasa: Buffer.from("" + this.state.limiteporcasa).toString('base64'),
                    ragotadores: Buffer.from("" + (this.state.ragotadores ? 1 : 0)).toString('base64'),
                    limresxdia: Buffer.from("" + this.state.limresxdia).toString('base64'),
                    aprobacion: Buffer.from("" + (this.state.aprobacion ? 1 : 0)).toString('base64'),
                    mora: Buffer.from("" + (this.state.morosos ? 1 : 0)).toString('base64'),
                    festivo: Buffer.from("" + this.state.festivo).toString('base64'),
                    dia_mantenimiento: Buffer.from("" + (this.state.Mantenimiento ? (this.switchId_Dia(this.state.dia_mantenimiento)) : (""))).toString('base64'),
                    horario: Buffer.from("" + JSON.stringify(this.state.horario)).toString('base64'),
                };
                if (this.state.id === 0) {
                    axios.post(Global.Ruta.Url + 'admin/insertrecurso', body).then(res => {
                        if (res.data.message === "") {
                            swal({
                                title: "ATENCIÓN!",
                                text: "El recurso se a guardado satisfactoriamente.",
                                icon: "success",
                                button: "Aceptar"
                            }).then(() => {
                                var recurso = {
                                    id: res.data.id,
                                    nombre: this.state.nombre,
                                };
                                this.props.guardarCambios(recurso)
                            })
                        } else if (res.data.message === "ERROR") {
                            swal({
                                title: "ERROR!",
                                text: "No fue posible duardar el recurso, por favor vuelva a intentarlo.",
                                icon: "error",
                                button: "Aceptar"
                            })
                        } else {
                            swal({
                                title: "ERROR!",
                                text: res.data.message,
                                icon: "error",
                                button: "Aceptar"
                            })
                        }
                    }).catch(err => {
                        swal({
                            title: "ERROR!",
                            text: "Vaya algo a salido mal, por favor intentelo mas tarde.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    });
                } else {
                    axios.post(Global.Ruta.Url + 'admin/updrecurso', body).then(res => {
                        if (res.data.message === "") {
                            swal({
                                title: "ATENCIÓN!",
                                text: "El recurso se edito satisfactoriamente.",
                                icon: "success",
                                button: "Aceptar"
                            }).then(() => {
                                var recurso = {
                                    id: this.state.id,
                                    nombre: this.state.nombre,
                                };
                                this.props.guardarCambios(recurso)
                            })
                        } else if (res.data.message === "ERROR") {
                            swal({
                                title: "ERROR!",
                                text: "No fue posible editar el recurso, por favor vuelva a intentarlo.",
                                icon: "error",
                                button: "Aceptar"
                            })
                        } else {
                            swal({
                                title: "ERROR!",
                                text: res.data.message,
                                icon: "error",
                                button: "Aceptar"
                            })
                        }
                    }).catch(err => {
                        swal({
                            title: "ERROR!",
                            text: "Vaya algo a salido mal, por favor intentelo mas tarde.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    });
                }
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }
    }

    Eliminar = () => {
        swal({
            title: "ATENCIÓN!",
            text: "Seguro que quiere borrar el recurso?",
            icon: 'warning',
            buttons: ["CANCELAR", "ACEPTAR"]
        }).then((result) => {
            if (result) {
                axios.get(Global.Ruta.Url + 'admin/deletrecurso/' + Buffer.from(Global.Ph.Codigo).toString('base64') + '/' + Buffer.from(Global.Ruta.Ipin).toString('base64') + '/' + Buffer.from("" + this.state.id).toString('base64'))
                    .then(res => {
                        if (res.data.message === "") {
                            swal({
                                title: "ATENCIÓN!",
                                text: "El recurso se ha eliminado correctamente.",
                                icon: "success",
                                button: "Aceptar"
                            }).then(() => {
                                this.props.Eliminar();
                            });
                        } else if (res.data.message === "ERROR") {
                            swal({
                                title: "ERROR!",
                                text: "No fue posible eliminar el recurso, por favor vuelva a intentarlo.",
                                icon: "error",
                                button: "Aceptar"
                            })
                        } else {
                            swal({
                                title: "ERROR!",
                                text: res.data.message,
                                icon: "error",
                                button: "Aceptar"
                            })
                        }
                    })
                    .catch(err => {
                        swal({
                            title: "ERROR!",
                            text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    });
            }
        });
    }

    Disponibilidad = (e) => {
        this.setState({ disponibilidad: e.target.value })
    }

    render() {

        const IOSSwitch = styled((props) => (<Switch checked={props.value} focusVisibleClassName=".Mui-focusVisible" size="small" onChange={(e) => this.setState({ [props.label]: e.target.checked })} {...props} />))(({ theme }) => ({
            width: 32, height: 16, padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[600],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 12,
                height: 12,
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
            },
        }));


        return (
            <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered onHide={this.props.Cancelar}>
                {this.state.ModalHorario && (
                    <Horario show={this.state.ModalHorario} Title={`Editar Recurso ${this.state.nombre}`} Cerrar={() => this.setState({ ModalHorario: false })}
                        horario={this.state.horario} disponibilidad={this.state.disponibilidad} GuardarHorario={(datos) => this.GuardarHorario(datos)}
                    />)}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{this.props.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box component="form" sx={{ '& > :not(style)': { width: '100%' }, }} noValidate autoComplete="off">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <TextField size="small" label="Nombre Recurso" variant="outlined" value={this.state.nombre} onChange={(e) => this.setState({ nombre: e.target.value.toUpperCase() })} className="form-control " />
                                    <FormHelperText className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('nombre', this.state.nombre, 'required', { className: 'text-danger' })}</FormHelperText>
                                </div>
                                <div className="col-3">
                                    <FormControl className="form-control" size="small">
                                        <InputLabel>Disponibilidad</InputLabel>
                                        <Select value={this.state.disponibilidad} label="Disponibilidad" onChange={(e) => this.setState({ disponibilidad: e.target.value, limiteper: 0 })
                                        }>
                                            <MenuItem value={"d"}>Día</MenuItem>
                                            <MenuItem value={"h"}>Horas</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {this.state.disponibilidad === "h" ? (
                                    <div className="col-3">
                                        <TextField size="small" label="Aforo máximo permitido" variant="outlined" value={this.state.limiteper} onChange={(e) => this.setState({ limiteper: e.target.value })} className="form-control" />
                                        <FormHelperText className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Aforo máximo', this.state.limiteper, 'required|numeric|integer|min:0,num', { className: 'text-danger' })}</FormHelperText>
                                    </div>
                                ) : ("")}


                            </div>
                        </div>
                        <hr />
                        <div className="container">
                            <div className="col">
                                <Button onClick={() => this.setState({ configadicional: !this.state.configadicional })} variant="outline-primary" size="md" className="mx-2">
                                    <i className="fa-solid fa-gears"></i> Configuraciones adicionales
                                </Button>
                                <Button onClick={() => this.setState({ ModalHorario: true })} variant="outline-primary" size="md" className="mx-2">
                                    <i className="fa-regular fa-calendar-days"></i> Horarios de la Reserva
                                </Button>
                            </div>
                        </div>

                        {this.state.configadicional ? (
                            <div>
                                <hr />
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <FormLabel component="legend">¿Posibilidad de reservar el mismo día?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} value={this.state.disponible} defaultChecked label={"disponible"} />}
                                                label={this.state.disponible ? "Si" : "No"}
                                            />

                                        </div>
                                        <div className="col">
                                            <FormLabel component="legend">¿Agotar reserva para permitir una nueva?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked value={this.state.ragotadores} label={"ragotadores"} />}
                                                label={this.state.ragotadores ? "Si" : "No"}
                                            />
                                        </div>
                                        <div className="col">
                                            <FormLabel component="legend">¿Aprobación por parte de la administración?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked value={this.state.aprobacion} label={"aprobacion"} />}
                                                label={this.state.aprobacion ? "Si" : "No"}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <FormLabel component="legend">¿Permitir morosos?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} value={this.state.morosos} defaultChecked label={"morosos"} />}
                                                label={this.state.morosos ? "Morosos permitidos" : "Morosos no permitidos"}
                                            />

                                        </div>

                                        {parseInt(this.state.id) !== 0 ? (<div className="col">
                                            <FormLabel component="legend">Estado de la reserva</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked value={this.state.estado} label={"estado"} />}
                                                label={this.state.estado ? "Activo" : "Inactivo"}
                                            />
                                        </div>
                                        ) : ("")}

                                        <div className="col">
                                            <FormLabel component="legend">¿Limitar reserva por semana?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked value={this.state.limiter} label={"limiter"} />}
                                                label={this.state.limiter ? "Reserva limitada" : "Sin limite de reservas"}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <TextField size="small" label="Límite reservas por día" variant="outlined" value={this.state.limresxdia} onChange={(e) => this.setState({ limresxdia: e.target.value })} className="form-control mb-3" />
                                            <FormHelperText className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Límite reservas por día', this.state.limresxdia, 'required|numeric|integer|min:0,num', { className: 'text-danger' })}</FormHelperText>
                                        </div>
                                        <div className="col">
                                            <TextField size="small" label="Aforo por inmueble" variant="outlined" value={this.state.limiteporcasa} onChange={(e) => this.setState({ limiteporcasa: e.target.value })} className="form-control mb-3" />
                                            <FormHelperText className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Aforo por inmueble', this.state.limiteporcasa, 'required|numeric|integer|min:0,num', { className: 'text-danger' })}</FormHelperText>
                                        </div>

                                        {this.state.limiter ? (<div className="col">
                                            <TextField size="small" label="Límite reservas por semana" variant="outlined" value={this.state.cantresem} onChange={(e) => this.setState({ cantresem: e.target.value })} className="form-control mb-3" />
                                            <FormHelperText className='text-muted m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>
                                                {this.state.limiter ?
                                                    this.validator.message('Límite reservas por semana', this.state.cantresem, 'required|numeric|integer|min:0,num', { className: 'text-danger' })
                                                    : null
                                                }
                                            </FormHelperText>
                                        </div>) : ("")}
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row">
                                        <div className={`${this.state.Mantenimiento ? ("col-3") : ("col-12")}`}>
                                            <FormLabel component="legend">¿El Recurso tiene día de mantenimiento?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} value={this.state.Mantenimiento} defaultChecked label={"Mantenimiento"} />}
                                                label={this.state.Mantenimiento ? "Si" : "No"}
                                            />
                                        </div>
                                        {this.state.Mantenimiento ? (<div className="col-5">
                                            <FormLabel component="legend">¿Posponer mantenimiento para el día siguiente si coincide un día festivo?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} value={this.state.festivo} defaultChecked label={"festivo"} />}
                                                label={this.state.festivo ? "Si" : "No"}
                                            />
                                        </div>
                                        ) : ("")}
                                        {this.state.Mantenimiento ? (
                                            <div className="col">
                                                <div className=" align-items-center d-flex">
                                                    <Autocomplete
                                                        fullWidth
                                                        disableClearable
                                                        disablePortal
                                                        noOptionsText="No Encontrado"
                                                        size="small"
                                                        value={this.DiasRender(this.state.dia_mantenimiento)}
                                                        options={this.state.dias}
                                                        onChange={(event, newValue) => { this.setState({ dia_mantenimiento: newValue.id }) }}
                                                        renderInput={(params) => <TextField {...params} label="Día de Mantenimiento" />}
                                                    />

                                                </div>
                                                <FormHelperText className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Día de Mantenimiento', this.state.dia_mantenimiento, 'required', { className: 'text-danger' })}</FormHelperText>
                                            </div>) : ("")}
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                        <hr />
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <TextField value={this.state.descripcion} size="small" inputProps={{ maxLength: 150 }} fullWidth label="Descripción" multiline maxRows={4} onChange={(e) => this.setState({ descripcion: e.target.value.toUpperCase() })} />
                                    <FormHelperText>{this.state.descripcion.length} caracteres restantes de 150 </FormHelperText>
                                    <FormHelperText className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('descripcion', this.state.descripcion, 'required', { className: 'text-danger' })}</FormHelperText>
                                </div>

                            </div>
                        </div>
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.id > 0 ? (<Button onClick={() => this.Eliminar()} variant="danger" size="sm"><i className="fas fa-times"></i> Eliminar</Button>) : ("")}
                    <Button onClick={() => this.Guardar()} variant="success" size="sm"><i className="fas fa-save"></i> {this.state.id === 0 ? ("Guardar") : ("Editar")}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PopupRecurso;