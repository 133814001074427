import React from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import swal from 'sweetalert';
import Global from "../../../Global";
import HourGlass from "./../../principal/HourGlass";

//---------------------------------------------IMAGENES------------------------------------------------
import Img from "../../../assets/images/imageDefault.png";
import Img1 from "../../../assets/images/icons/12-Salon-social.2.png";
import Img2 from "../../../assets/images/icons/01-Futbol.2.png";
import Img3 from "../../../assets/images/icons/05-bbq.2.png";
import Img4 from "../../../assets/images/icons/04-Tenis.2.png";
import Img5 from "../../../assets/images/icons/07-Multicancha.2.png";
import Img6 from "../../../assets/images/icons/02-Golf.2.png";
import Img7 from "../../../assets/images/icons/08-Videojuegos.2.png";
import Img8 from "../../../assets/images/icons/09-Zona-niños.2.png";
import Img9 from "../../../assets/images/icons/11-Mascotas.2.png";
import Img10 from "../../../assets/images/icons/06-Piscina.2.png";
import Img11 from "../../../assets/images/icons/03-Gym.2.png";
import Img12 from "../../../assets/images/icons/13-Sauna-Turco.2.png";
import Img13 from "../../../assets/images/icons/10-Otros.2.png";

import PopupRecurso from './PopupRecurso';

class TarjetaRecurso extends React.Component {
  state = {
    HourGlass: false,
    modalShow1: false,
    id: 0,
    nombre: "",
    Zona: {},

  }

  componentWillMount() {
    this.setState({
      id: this.props.recurso.id,
      nombre: this.props.recurso.nombre,
    })
  }

  consultadatos = () => {
    this.setState({ HourGlass: true })
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_recurso: Buffer.from("" + this.state.id).toString("base64"),
    }
    axios.post(`${Global.Ruta.Url}admin/getdatarecurso/`, body).then(res => {
      if (res.data.message === "") {
        var tem = res.data.Zona

        for (var i = 0; i < tem.horario.length; i++) {
          let tem3 = []
          if (tem.horario[i].desde_hasta.Horario.length) {
            for (var j = 0; j < tem.horario[i].desde_hasta.Horario.length; j++) {
              let tem1 = tem.horario[i].desde_hasta.Horario[j]
              tem3.push({ desde: new Date(0, 0, 0, tem1.desde), hasta: new Date(0, 0, 0, tem1.hasta) })
            }
            tem.horario[i].desde_hasta = tem3
          }
        }


        this.setState(
          { Zona: tem },
          () => {
            this.setState({ HourGlass: false, modalShow1: true })
          }
        );
      } else {
        this.setState({ HourGlass: false })
        swal({
          title: "ALERTA!",
          text: `${res.data.message}`,
          icon: "error",
          button: "Aceptar",
        })
      }
    }).catch((err) => {
      this.setState({ HourGlass: false })
      swal({
        title: "ALERTA!",
        text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      })
    });

  }

  grpImagen = () => {
    switch (parseInt(this.props.img)) {
      case 1:
        return <Card.Img variant="top" src={Img1} className="iconoTarjetaCarr" />
      case 2:
        return <Card.Img variant="top" src={Img2} className="iconoTarjetaCarr" />
      case 3:
        return <Card.Img variant="top" src={Img3} className="iconoTarjetaCarr" />
      case 4:
        return <Card.Img variant="top" src={Img4} className="iconoTarjetaCarr" />
      case 5:
        return <Card.Img variant="top" src={Img5} className="iconoTarjetaCarr" />
      case 6:
        return <Card.Img variant="top" src={Img6} className="iconoTarjetaCarr" />
      case 7:
        return <Card.Img variant="top" src={Img7} className="iconoTarjetaCarr" />
      case 8:
        return <Card.Img variant="top" src={Img8} className="iconoTarjetaCarr" />
      case 9:
        return <Card.Img variant="top" src={Img9} className="iconoTarjetaCarr" />
      case 10:
        return <Card.Img variant="top" src={Img10} className="iconoTarjetaCarr" />
      case 11:
        return <Card.Img variant="top" src={Img11} className="iconoTarjetaCarr" />
      case 12:
        return <Card.Img variant="top" src={Img12} className="iconoTarjetaCarr" />
      case 13:
        return <Card.Img variant="top" src={Img13} className="iconoTarjetaCarr" />
      default:
        return <Card.Img variant="top" src={Img} className="iconoTarjetaCarr" />
    }
  }

  guardarCambios = (nuevoDatos) => {
    this.setState({
      modalShow1: false,
      nombre: nuevoDatos.nombre,
    })
  }

  eliminar = () => {
    this.setState({
      modalShow1: false
    })
    this.props.EliminarRecurso(this.props.indice)
  }

  render() {

    return (
      <div className='carrItem'>
        <div className='col mx-1' style={{ width: '12rem' }}>
          <div className='card shadow rounded-4 overflow-hidden' style={{ height: '15rem' }}>
            <HourGlass show={this.state.HourGlass}></HourGlass>
            <div className="position-relative contImgTarjetaCarr2">
              <svg className="w-full" viewBox="0 0 335 233" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stop-color="#1093c6" />
                    <stop offset="25%" stop-color="#23688d" />
                    <stop offset="50%" stop-color="#203f54" />
                    <stop offset="75%" stop-color="#131a21" />
                    <stop offset="100%" stop-color="#000000" />
                  </linearGradient>
                </defs>
                <path id="gradientOver" d="M0 0H335V164.783C335 164.783 325.271 205.536 166.231 205.536C7.19066 205.536 0 233 0 233V0Z"
                  fill="url(#gradient)" />
                {/* <image xlinkHref={this.grpImagen()} x="0" y="0" style={{ width: '30%', position: 'absolute', inset: 0 }} /> */}
              </svg>
              <div className="position-absolute start-50 translate-middle w-auto" style={{ top: '40%' }}>
                {this.grpImagen()}
              </div>
            </div>
            <Card.Body className="">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="cover-marqss mb-2">
                  <h6 className="marqss">{this.state.nombre}</h6>
                </div>
                <Button variant="primary" size="sm" onClick={() => this.consultadatos()} >
                  Editar Zona Social
                </Button>
              </div>
            </Card.Body>
          </div>
        </div>
        {this.state.modalShow1 && (
          <PopupRecurso show={this.state.modalShow1} Title={`Editar Recurso ${this.state.nombre}`} Cancelar={() => this.setState({ modalShow1: false })}
            Zona={this.state.Zona} id_recurso={this.state.id} nombre={this.state.nombre} grupo={this.props.grupo} guardarCambios={(e) => this.guardarCambios(e)}
            Eliminar={() => this.eliminar()}
          />)}

      </div>
    );
  }
}

export default TarjetaRecurso;