import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { MenuItem, TextField } from '@material-ui/core'
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Global from "../../../../Global";
import swal from 'sweetalert';
import { isSameDay } from 'date-fns';

//------------------------------------------IMAGENES-----------------------------------------------------

import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";

export default class ModalReserva extends Component {

  state = {
    Recursos: [],

    inmuebles: [],
    SelecInmueble: "",
    labelInmueble: "",
    suscriptores: [],
    SelectSuscriptor: "",
    labelSuscriptor: "",
    grupos: [],
    SelectGrupos: "",
    labelGrupos: "",
    zonas: [],
    DataZonas: [],
    SelectZonas: "",
    labelZonas: "",
    DataZona: {},
    IdRecurso: "",

    date: null,//new Date(),//new Date("2022-12-17 12:00:00 GMT-0500"),
    fechasInhabilitadas: [],
    cantPers: 1,

    Horas: [],
    SelectHora: "0",
    labelHoras: "",
    MaxPersonas: 0,

    // hourglass
    modal: false,
    disabledDatePicker: true
  };

  componentWillMount() {

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64")
    }

    axios.post(Global.Ruta.Url + "admin/listinm/", body).then(res => {

      if (res.data.message === "") {
        var tem = [];
        var inmueble = {};
        for (var i = 0; i < res.data.Inmuebles.length; i++) {
          inmueble = {
            value: res.data.Inmuebles[i].inmuebleid,
            label: res.data.Inmuebles[i].direccion,
          };
          tem.push(inmueble);
        }
        this.setState({ inmuebles: tem });
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64")
        }
        axios.post(Global.Ruta.Url + "admin/getrecursos/", body).then(res => {
          if (res.data.message === "") {

            var tem = [];
            var Grupo = {};
            for (var i = 0; i < res.data.Recursos.length; i++) {
              Grupo = {
                value: res.data.Recursos[i].imagenId,
                label: res.data.Recursos[i].Nombre_Grupo,
              };
              tem.push(Grupo);
            }

            this.setState({ grupos: tem, Recursos: res.data.Recursos });

          } else {
            //CERRAR
          }
        }).catch(err => {
          //CERRAR
        });

      } else {
        //CERRAR
      }
    }).catch(err => {
      //CERRAR
    });
  }

  Combobox = (prop) => (e) => {
    this.setState({
      [prop]: e.target.value
    })

    if (prop === "SelecInmueble") {
      this.setState({ modal: true, labelInmueble: this.state.inmuebles[this.state.inmuebles.findIndex((element) => parseInt(element.value) === parseInt(e.target.value))].label })
      this.BuscarSuscriptores(e.target.value)
    }
    if (prop === "SelectSuscriptor") {
      this.setState({ labelSuscriptor: this.state.suscriptores[this.state.suscriptores.findIndex((element) => parseInt(element.value) === parseInt(e.target.value))].label })
    }
    if (prop === "SelectGrupos") {
      this.setState({ labelGrupos: this.state.grupos[this.state.grupos.findIndex((element) => parseInt(element.value) === parseInt(e.target.value))].label, disabledDatePicker: true })
      this.BuscarZonas(e.target.value)
    }
    if (prop === "SelectZonas") {
      var DataZona = this.state.DataZonas[this.state.DataZonas.findIndex((element) => parseInt(element.RecursoId) === parseInt(e.target.value))]
      this.setState({
        modal: true,
        labelZonas: this.state.DataZonas[this.state.DataZonas.findIndex((element) => parseInt(element.RecursoId) === parseInt(e.target.value))].Nombre_Recurso,
        IdRecurso: e.target.value,
        DataZona: DataZona,
        date: null,
      })

      this.diasInhabilitados(e.target.value)
    }
    if (prop === "SelectHora") {
      this.setState({ labelHoras: this.MostarHora(e.target.value) })
    }
  };

  BuscarSuscriptores = (InmuebleId) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      inmuebleid: Buffer.from("" + InmuebleId).toString("base64"),
    }
    axios.post(Global.Ruta.Url + "admin/listresidenteinm/", body).then(res => {
      if (res.data.message === "") {
        var tem = [];
        var suscriptor = {};
        for (var i = 0; i < res.data.Suscriptores.length; i++) {
          suscriptor = {
            value: res.data.Suscriptores[i].suscriptorid,
            label: res.data.Suscriptores[i].nombres.toUpperCase(),
          };
          tem.push(suscriptor);
        }
        this.setState({ suscriptores: tem, modal: false });
      } else {
        //CERRAR
      }
    }).catch(err => {
      //CERRAR
    });
  }

  BuscarZonas = (GrpId) => {
    var index = this.state.Recursos.findIndex((element) => parseInt(element.imagenId) === parseInt(GrpId))

    var temp = this.state.Recursos

    var tem = [];
    var Zona = {};
    for (var i = 0; i < temp[index].Recursos.length; i++) {
      Zona = {
        value: temp[index].Recursos[i].RecursoId,
        label: temp[index].Recursos[i].Nombre_Recurso,
      };
      tem.push(Zona);
    }

    this.setState({
      zonas: tem,
      DataZonas: temp[index].Recursos
    })
  }

  diasInhabilitados = (IdRecurso) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      idrecurso: Buffer.from("" + IdRecurso).toString("base64"),
    }
    axios.post(Global.Ruta.Url + "susc/gethorariorecurso/", body).then(res => {
      if (res.data.message === "") {
        this.setState({
          modal: false,
          disabledDatePicker: false,
          fechasInhabilitadas: res.data.Fechas,
          Horarios: res.data.Horarios,
        })
      } else {
        this.setState({
          modal: false,
        })
      }
    }).catch(err => {
      //CERRAR
    });
  }

  Horarios = (varFecha) => {
    this.setState({
      MaxPersonas: 0,
      Horas: [],
      modal: true,
    })
    var Fecha = new Date(varFecha)
    var varDiaSemana = Fecha.getDay()
    var filteredData = undefined

    if (this.state.Horarios[0].id_dia !== 7) {
      filteredData = this.state.Horarios.filter((item) => {
        return item.id_dia === varDiaSemana
      })
    } else {
      filteredData = this.state.Horarios
    }


    var IdRecurso = this.state.IdRecurso
    var DataZona = this.state.DataZona
    var TipoRecurso = DataZona.PRecurso
    var body = undefined

    if (TipoRecurso !== "d") {
      body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        tiporeserva: Buffer.from((TipoRecurso === "d") ? ("1") : ("0")).toString("base64"),
        idrecurso: Buffer.from("" + IdRecurso).toString("base64"),
        fechareserva: Buffer.from((TipoRecurso === "d") ? (new Date().toISOString().split("T")[0]) : (new Date(Fecha).toISOString().split("T")[0])).toString("base64")
      }
      axios.post(Global.Ruta.Url + "admin/getreservasrecursos/", body).then(res => {
        if (res.data.message === "") {
          if (TipoRecurso !== "d") {
            filteredData[0].desde_hasta.Horario.forEach(element => {
              var CurrentTime = parseInt(new Date().getHours()) * 60
              var desde = parseInt(element.desde) * 60
              var hasta = parseInt(element.hasta) * 60
              var diferencia = hasta - desde
              var TiempoReserva = parseInt(filteredData[0].min_reserva)
              var mensaje = ""

              var Entero = Math.trunc(diferencia / TiempoReserva);

              var Horas = this.state.Horas

              for (var j = 0; j < Entero; j++) {
                var index = this.BuscarIndex(desde, res.data.Reservas)

                var cupos = parseInt(DataZona.CantPer)

                if (parseInt(index) > -1) {
                  cupos = cupos - parseInt(res.data.Reservas[index].Cupos)
                }

                if (parseInt(DataZona.CantPer) === 0) {
                  if (parseInt(index) > -1) {
                    mensaje = "0"
                  } else {
                    mensaje = "Recurso disponible."
                  }
                } else {
                  mensaje = `Cupos disponibles ${cupos}`
                }

                if (new Date(this.state.date).toISOString().split("T")[0] === new Date().toISOString().split("T")[0]) {
                  
                  var banHorario = false
                  if (desde >= CurrentTime) {
                    banHorario = true
                  } else {
                    var desdetmp = desde + TiempoReserva
                    if (desdetmp >= CurrentTime) {
                      banHorario = true
                    } else {
                      banHorario = false
                    }
                  }

                  if (banHorario) {
                    if (parseInt(DataZona.CantPer) === 0) {
                      //PUEDE RESERVAR EN ESTE HORARIO
                      if (mensaje !== "0") {
                        Horas.push({ value: desde, label: this.MostarHora(desde) + " - " + mensaje })
                      }
                    } else {
                      //MOSTRAR CANTIDAD DE CUPOS
                      if (cupos !== 0) {
                        Horas.push({ value: desde, label: this.MostarHora(desde) + " - " + mensaje })
                      }
                    }
                  }
                } else {
                  if (parseInt(DataZona.CantPer) === 0) {
                    //PUEDE RESERVAR EN ESTE HORARIO
                    if (mensaje !== "0") {
                      Horas.push({ value: desde, label: this.MostarHora(desde) + " - " + mensaje })
                    }
                  } else {
                    //MOSTRAR CANTIDAD DE CUPOS
                    if (cupos !== 0) {
                      Horas.push({ value: desde, label: this.MostarHora(desde) + " - " + mensaje })
                    }
                  }
                }

                desde = desde + TiempoReserva
                if (j + 1 === Entero) {
                  desde = desde + (hasta - desde)
                }
              }

              var MaxPersonas = ((parseInt(DataZona.LimPorCasa) < parseInt(DataZona.CantPer)) && parseInt(DataZona.LimPorCasa) !== 0) ? (parseInt(DataZona.LimPorCasa)) : (parseInt(DataZona.CantPer))

              this.setState({ Horas: Horas, modal: false, MaxPersonas: MaxPersonas });
            });
          }
        } else {
          //CERRAR
        }
      }).catch(err => {
        //CERRAR
      });
    } else {
      this.setState({ Horas: [], modal: false, MaxPersonas: 0 });
    }
  }

  MostarHora = (Inicio) => {
    var InicioH = Math.trunc(Inicio / 60)
    var InicioM = (Inicio % 60)
    var MInicioH = InicioH
    var MInicioM = InicioM
    if (InicioH < 10) {
      MInicioH = "0" + InicioH
    }
    if (InicioM < 10) {
      MInicioM = "0" + InicioM
    }

    return MInicioH + ":" + MInicioM
  }

  BuscarIndex = (PresId, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.Hora) === parseInt(PresId);

    return temRows.findIndex(findIndexArray);
  };

  isDisabledDate = (date) => {
    return this.state.fechasInhabilitadas.some((fecha) => isSameDay(date, new Date(fecha)))
  };

  DisabledButton = () => {
    if (this.state.SelecInmueble === "" ||
      this.state.SelectSuscriptor === "" ||
      this.state.SelectGrupos === "" ||
      this.state.SelectZonas === "" ||
      this.state.SelectHora === ""
    ) {
      if (this.state.DataZona.PRecurso === "d") {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  Reservar = () => {
    this.setState({ modal: true, })

    var fecha = new Date(this.state.date)
    fecha.setUTCHours(fecha.getUTCHours() - 5)

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      zonaidsel: Buffer.from("" + this.state.SelectZonas).toString("base64"),
      suscriptorid: Buffer.from("" + this.state.SelectSuscriptor).toString("base64"),
      fecha: Buffer.from("" + fecha.toISOString().split("T")[0]).toString("base64"),
      hora: Buffer.from("" + this.state.SelectHora).toString("base64"),
      inmuebleid: Buffer.from("" + this.state.SelecInmueble).toString("base64"),
      pers: Buffer.from("" + this.state.DataZona.CantPer).toString("base64"),
      cantpersonas: Buffer.from("" + this.state.cantPers).toString("base64"),
      limr: Buffer.from("" + this.state.DataZona.LimRe).toString("base64"),
      cantr: Buffer.from("" + this.state.DataZona.CantReasta).toString("base64"),
      numsem: Buffer.from("" + this.CalcularNumSemana(this.state.date)).toString("base64"),
    }
    axios.post(Global.Ruta.Url + "admin/reservar/", body).then(res => {
      this.setState({ modal: false, })
      if (res.data.message.includes("OK1")) {
        swal({
          title: "ATENCIÓN!",
          text: "La reserva reserva ha sido registrada.",
          icon: "success",
          button: "Aceptar"
        }).then(() => {
          this.props.cerrar();
        });
      }
      if (res.data.message.includes("OK2")) {
        swal({
          title: "ATENCIÓN!",
          text: "La reserva reserva ha sido registrada, por favor aprobarla en el modulo de reservas.",
          icon: "success",
          button: "Aceptar"
        }).then(() => {
          this.props.cerrar();
        });
      }
      if (res.data.message.includes("ERROR")) {

        swal({
          title: "ATENCIÓN!",
          text: res.data.message.substr(6),
          icon: "warning",
          button: "Aceptar"
        })
      }
    }).catch(err => {
      //CERRAR
    });
  }

  CalcularNumSemana = (date) => {
    var startDate = new Date(date.getFullYear(), 0, 1);
    var days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));

    return Math.ceil(days / 7);
  }

  render() {

    const Selector = ({ label, items, state, value, disabled }) => {
      return (
        <TextField variant="outlined" select fullWidth id="demo-simple-select"
          value={value} label={label}
          onChange={this.Combobox(state)} disabled={(items.length > 0 && disabled) ? (false) : (true)}>
          {items.map((inmueble) => (<MenuItem style={{ width: 'auto !important' }} key={inmueble.value} value={inmueble.value}>{inmueble.label}</MenuItem>))}
        </TextField>
      );
    };

    return (
      <div>
        <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
          <PpHourglass />
        </Modal>
        <Modal.Header className="shadow-sm" closeButton>
          Nueva Reserva
        </Modal.Header>
        <Modal.Body>
          <div className="row h-100">
            <div className="col">
              <div className="row gy-2">
                <div className="col-12">
                  <Selector label="Inmueble" items={this.state.inmuebles} state={"SelecInmueble"} value={this.state.SelecInmueble} disabled={true} />
                </div>
                <div className="col-12">
                  <Selector label="Residentes" items={this.state.suscriptores} state={"SelectSuscriptor"} value={this.state.SelectSuscriptor} disabled={true} />
                </div>
                <div className="col-12">
                  <Selector label="Zonas" items={this.state.grupos} state={"SelectGrupos"} value={this.state.SelectGrupos} disabled={true} />
                </div>
                <div className="col-12">
                  <Selector label="Zonas Individuales" items={this.state.zonas} state={"SelectZonas"} value={this.state.SelectZonas} disabled={true} />
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="col-12">
                    <DatePicker
                      label="Fecha"
                      disabled={this.state.disabledDatePicker}
                      value={this.state.date}
                      onChange={(newValue) => {
                        this.setState({ date: newValue });
                        this.Horarios(newValue)
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                      minDate={new Date()}
                      shouldDisableDate={this.isDisabledDate}
                    />
                  </div>
                </LocalizationProvider>
                <div className="col-12">
                  <Selector label="Horario" items={this.state.Horas} state={"SelectHora"} value={this.state.SelectHora} disabled={(this.state.DataZona.PRecurso === "d") ? (false) : (true)} />
                </div>
                <div className="col-12">
                  <TextField label={`Cantidad usuarios (Limite de cupos ${(this.state.MaxPersonas === 0) ? (1) : (this.state.MaxPersonas)})`} fullWidth type="number" InputLabelProps={{ shrink: true, }} value={this.state.cantPers}
                    onChange={(e) => { if ((parseInt(e.target.value) > 0 && parseInt(e.target.value) <= this.state.MaxPersonas) || e.target.value === "") { this.setState({ cantPers: e.target.value }) } }}
                    disabled={(this.state.DataZona.PRecurso === "h" && this.state.MaxPersonas > 0) ? (false) : (true)}
                  />
                </div>
                <div className="col-12 d-grid">
                  <button className="btn btn-primary" onClick={this.Reservar} disabled={this.DisabledButton()}>
                    Reservar
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow h-100">
                <div className="card-header">Resumen</div>
                <div className="card-body">
                  <h6 className="text-muted">
                    Inmueble{" "}
                    <span className="text-dark">{this.state.labelInmueble}</span>
                  </h6>
                  <h6 className="text-muted">
                    Propietario{" "}
                    <span className="text-dark">{this.state.labelSuscriptor}</span>
                  </h6>
                  <h6 className="text-muted">
                    Fecha{" "}
                    <span className="text-dark">{(this.state.date !== null) ? this.state.date.toISOString().split("T")[0] : ""}</span>
                  </h6>
                  <h6 className="text-muted">
                    Hora <span className="text-dark">{this.state.labelHoras}</span>
                  </h6>
                  <hr className="" />
                  <h6 className="text-muted">
                    Grupo <span className="text-dark">{this.state.labelGrupos}</span>
                  </h6>
                  <h6 className="text-muted">
                    Zona{" "}
                    <span className="text-dark">{this.state.labelZonas}</span>
                  </h6>
                  <h6 className="text-muted">
                    Cantidad usuarios{" "}
                    <span className="text-dark">{this.state.cantPers}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    );
  }
}