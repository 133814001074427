import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

export default class PpHourglass extends Component {
  render() {
    return (
        <div>
            <Modal.Header>
                Procesando Datos
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center flex-column align-items-center text-center">
                    <div className="spinner-grow text-info" role="status" style={{width: '5rem', height: '5rem'}}/>
                    <span className="my-2 ">Espere un momento...</span>
                </div>
            </Modal.Body>
        </div>
    )
  }
}
