//import { Autocomplete, TextField } from "@mui/material";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import HeadTitle from "../../../principal/HeadTitle";
import DespachoDeliv from "./DespachoDeliv";
import DespachoPack from "./DespachoPack";
import DespachoVisit from "./DespachoVisit";
//import Global from "../../../../Global";
//import axios from "axios";
//import swal from "sweetalert";

export default class DespachoPort extends Component {

  state = {
    data: false,
    inmueble: "",
    direccion: "",
  }
  // constructor(props) {
  //   super(props);
  //   this.PageChanger = this.PageChanger.bind(this);
  // }

  PageChanger = () => {
    switch (this.props.pagina) {
      case "paquetes":
        return <DespachoPack />;
      case "domicilios":
        return <DespachoDeliv />;
      case "visitas":
        return <DespachoVisit render={this.state.render} Nuevos={this.state.Nuevos} Frecuentes={this.state.Frecuentes} Anunciados={this.state.Anunciados} Historial={this.state.Historial} />;
      default:
        break;
    }
  };

  Title = () => {
    switch (this.props.pagina) {
      case "paquetes":
        return "Paqueteria.";
      case "domicilios":
        return "Domicilios.";
      case "visitas":
        return "Visitantes.";
      default:
        return "";
    }
  }

  componentWillMount() {
    this.PageChanger()
  }

  render() {

    const options = {
      headerStyle: {
        textAlign: "center",
      },
      showTitle: false,
    };

    return (
      <div className="w-full h-full bg-light">
        <div className="g-r-prf2 h-100 py-3">
          <div className="col-12 p-3 pt-1">
            <HeadTitle
              classNameOwn="row align-items-center"
              titulo={`Modulo de ${this.Title()}`}
              actionButton={
                <div className="row">
                  <div className="col-auto">
                    <NavLink className="btn btn-outline-primary" exact to={"./paquetes"} activeClassName="active">
                      Paqueteria
                    </NavLink>
                  </div>
                  <div className="col-auto">
                    <NavLink className="btn btn-outline-primary" exact to={"./domicilios"} activeClassName="active" >
                      Domicilios
                    </NavLink>
                  </div>
                  <div className="col-auto">
                    <NavLink className="btn btn-outline-primary" exact to={"./visitas"} activeClassName="active">
                      Visitantes
                    </NavLink>
                  </div>
                </div>
              } />
          </div>
          {this.PageChanger(options)}
        </div>
      </div>
    );
  }
}
