import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';


export default class InfoSuscSapp extends Component {
  render() {
    return (
      <div>
          <Modal.Header closeButton>
              Información del Suscriptor
          </Modal.Header>
          <Modal.Body>
              <div className="row row-cols-2 justify-content-center gy-2">
                  <div className="col-12 d-flex align-items-center">
                      <h6 className="bg-secondary text-light p-2 rounded-pill m-0">Nombre{' '}
                      </h6>
                      <div className="cover-marqss h-auto mx-2 ">
                          <h5 className="marqss">{this.props.nombre}</h5>
                      </div>
                  </div>
                  <div className="col-12 d-flex align-items-center">
                    <h6 className="bg-secondary text-light p-2 rounded-pill m-0">Dirección{' '}
                    </h6>
                    <div className="cover-marqss h-auto mx-2 ">
                        <h5 className="marqss">{this.props.dir}</h5>
                    </div>
                  </div>
                  <div className="col-12 d-flex align-items-center">
                    <h6 className="bg-secondary text-light p-2 rounded-pill text-nowrap m-0">E-Mail{' '}
                    </h6>
                    <div className="cover-marqss h-auto mx-2 ">
                        <h5 className="marqss">{this.props.email}</h5>
                    </div>
                  </div>
                  <div className="col-12 d-flex align-items-center">
                    <h6 className="bg-secondary text-light p-2 rounded-pill m-0">Teléfono{' '}
                    </h6>
                    <div className="cover-marqss h-auto mx-2 ">
                        <h5 className="marqss">{this.props.tel}</h5>
                    </div>
                  </div>
              </div>
          </Modal.Body>
      </div>
    )
  }
}
