import React, { Component } from "react";

import HeaderA2 from "./Home/HeaderA2";
import SistemaMsn from "./Mensajeria/Msn";
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";

class BuzonMsn extends Component {

    state = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('SistemaMensajes') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }
        return (
            <div className='g-r-prf3 bg-light vh-100'>
                <HeaderA2 />
                <SistemaMsn />
                 
            </div>
        );
    }

}

export default BuzonMsn;