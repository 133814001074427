import React, { Component } from "react";

import HeaderA2 from "./Home/HeaderA2";
import ListSolicitudesComu from "./SolicitudesSusc/ListSolicitudes";
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";

export default class SolicitudesComu extends Component {

    state = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('peticiones') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }
        return (
            <div className="g-r-prf3 bg-light vh-100">
                <HeaderA2 />
                <ListSolicitudesComu />
                 
            </div>
        );
    }

}