import React, { Component } from 'react';
import Copyright from './CopyRight';
import Footer from './Footer';
import foo from '../../assets/images/images_menu/footer.png'

class FooterHome extends Component {

    render() {

        return (
            <div className="container-fluid" style={{backgroundImage:`url(${foo})`, backgroundSize: 'cover', marginTop:'5rem'}}>
                <div className='row pt-5 pb-3 gap-3'>
                  <Footer />
                  <Copyright />
                </div>
            </div>
        );
    }
}

export default FooterHome;