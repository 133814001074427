import React, { Component } from "react";
import "../../assets/css/admin.css";
import HeaderA2 from "./Home/HeaderA2";
import { ThemeProvider, createTheme } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";
import RondaMain from './Ronda/RondaMain'

const theme = createTheme({
    palette: {
        secondary: {
            main: '#4c944a'
        }
    },
});

class Ronda extends Component {
    state = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('Rondas') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }
        return (
            <div className="g-r-prf3 vh-100">
                <ThemeProvider theme={theme}>
                    <HeaderA2 />
                    <RondaMain />
                </ThemeProvider>
            </div>
        );
    }
}

export default Ronda;