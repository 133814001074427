import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from "simple-react-validator";

export default class ModalDispatchPD extends Component {
  state = {
    nombre: this.props.infoDispatch.nombre,
    direccion: this.props.infoDispatch.direccion,
    detalle: this.props.infoDispatch.detalle,
    fecha: this.props.infoDispatch.fecha_llegada,
    nombre_portero: this.props.infoDispatch.nombre_portero,
    id_paquete: this.props.infoDispatch.id_paquete,
    id_inmueble: this.props.infoDispatch.id_inmueble,
    suscriptores: [],
    loanding: false,
    residente: "",
    nombre_entrega: "",
    PpHourglass: false,
  };

  componentWillMount() {
    this.BuscarSuscriptor();
    this.validator = new SimpleReactValidator({
      locale: "es",
      messages: {
        required: "Requerido",
      },
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Realiza la acción que desees cuando el usuario presione Enter
      this.Entregar()
    }
  }

  BuscarSuscriptor = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      inmuebleid: Buffer.from("" + this.state.id_inmueble).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}admin/listsuscinm`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            suscriptores: res.data.Suscriptores,
            loanding: true,
          });
        } else {
          this.setState({ loanding: true });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  Entregar = () => {
    if (this.validator.allValid()) {
      var NombreEntrega = "";
      if (parseInt(this.state.residente) === 0) {
        NombreEntrega = this.state.nombre_entrega.toUpperCase();
      } else {
        var tem = this.state.suscriptores;
        var index = this.IndexCambio(this.state.residente, tem);
        NombreEntrega = tem[index].nombres;
      }

      if (NombreEntrega === "") {
        swal({
          title: "Atención",
          text: "Por Favor Ingresar el Nombre de la persona que Recibe el paquete",
          icon: "warning",
          button: "Aceptar",
        });
        return
      }

      this.setState({ PpHourglass: true });

      var body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        id_paquete: Buffer.from("" + this.state.id_paquete).toString("base64"),
        id_susc: Buffer.from("" + this.state.residente).toString("base64"),
        nombre_recibe: Buffer.from("" + NombreEntrega).toString("base64"),
        id_portero: Buffer.from(Global.GlobalUser.userid).toString("base64"),
        id_inmueble: Buffer.from("" + this.state.id_inmueble).toString("base64"),
      };
      axios
        .post(`${Global.Ruta.Url}porteria/entregapaquete`, body)
        .then((res) => {
          if (res.data.message === "") {
            this.setState({
              PpHourglass: false,
            });
            swal({
              title: "",
              text: "Paquete Entregado",
              icon: "success",
              button: "Aceptar",
            }).then(() => this.props.cerrar(this.state.id_paquete));
          } else {
            this.setState({ PpHourglass: false });
          }
        })
        .catch((err) => {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({
              redirect_home: true,
              PpHourglass: false,
            });
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.suscriptorid) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  render() {
    if (this.state.loanding) {
      return (
        <div>
          <Modal
            show={this.state.PpHourglass}
            centered
            backdrop="static"
            onHide={() => this.setState({ PpHourglass: false })}
          >
            <PpHourglass />
          </Modal>
          <Modal.Header closeButton>
            {`${this.state.detalle.toUpperCase()} A NOMBRE DE ${this.state.nombre
              }`}
          </Modal.Header>
          <Modal.Body>
            <div className="row gy-3">
              <div className="col-12">
                <FormControl>
                  <FormLabel
                    className="py-3"
                    id="demo-radio-buttons-group-label"
                  >
                    RESIDENTES {this.state.direccion}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    className="px-3"
                    name="radio-buttons-group"
                    value={this.state.residente}
                    onChange={(e) =>
                      this.setState({ residente: e.target.value })
                    }
                  >
                    <div className="row gy-1">
                      {this.state.suscriptores.map((items) => (
                        <div className="col-12">
                          <FormControlLabel
                            key={items.suscriptorid}
                            value={String(items.suscriptorid)}
                            control={<Radio />}
                            label={items.nombres}
                          />
                        </div>
                      ))}
                      <div className="col-12">
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Otro..."
                        />
                      </div>
                    </div>
                    <p className="fst-italic text-muted text-capitalize m-0 txt-helper">
                      {this.validator.message(
                        "nombre",
                        this.state.residente,
                        "required",
                        { className: "text-danger" }
                      )}
                    </p>
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12">
                {this.state.residente === "0" ? (
                  <TextField
                    label="Nombre"
                    value={this.state.nombre_entrega.toUpperCase()}
                    onChange={(e) =>
                      this.setState({ nombre_entrega: e.target.value })
                    }
                  />
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <div className="col-auto">
              <button className="btn btn-primary" onClick={this.Entregar}>
                {`Entregar `}
              </button>
            </div>
          </Modal.Footer>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center bg-light text-info flex-column align-items-center h-100">
        <div
          className="spinner-border fs-3 m-3"
          style={{ width: "5rem", height: "5rem" }}
          role="status"
        ></div>
        <p className="fs-3">Cargando...</p>
      </div>
    );
  }
}
