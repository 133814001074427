import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Box, TextField, FormControlLabel, Switch, styled, FormLabel, Autocomplete } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator'


class Horario extends React.Component {

    state = {
        data: true,
        reservadia: false,
        horario: [],
        disponibilidad: "",
        estadoindex: undefined,
        addhorario: false,
        dias: [{ id: "Mon", label: "Lunes" }, { id: "Tue", label: "Martes" }, { id: "Wed", label: "Miércoles" }, { id: "Thu", label: "Jueves" }, { id: "Fri", label: "Viernes" }, { id: "Sat", label: "Sábado" }, { id: "Sun", label: "Domingo" }],
        Horas: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
        Minutos: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
        hora: "",
        selectdia: "",
        desde_hasta: [],
        nuevodia: [],
        min_reserva: "",
        hora_reserva: "",
        t_reserva: true,
        desde: new Date(0, 0, 0, 0),
        hasta: new Date(0, 0, 0, 23),
    }

    componentWillMount() {
        let tem = this.props.horario
        var reservadia = false
        if (tem.length > 0) {
            reservadia = tem[0].dia !== "Tod" ? true : false
        } else {
            reservadia = false
        }

        this.setState({
            reservadia: reservadia,
            horario: tem,
            disponibilidad: this.props.disponibilidad,
            selectdia: "",
            t_reserva: parseInt(this.props.t_reserva) === 0 ? false : true,

        })
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                min: 'El tiempo debe ser mínimo de 1 minuto',
                max: 'El tiempo debe ser máximo de 1440 minutos',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
            }
        });

    };

    Checked = (label, value) => {
        if (label === "reservadia") {
            this.setState({ [label]: value, horario: [] })
        } else {
            this.setState({ [label]: value })
        }

    }

    DiasRender = (dia) => {
        switch (dia) {
            case 'Mon':
                return "Lunes"
            case 'Tue':
                return "Martes"
            case 'Wed':
                return "Miércoles"
            case 'Thu':
                return "Jueves"
            case 'Fri':
                return "Viernes"
            case 'Sat':
                return "Sábado"
            case 'Sun':
                return "Domingo"
            default:
                return "Todos los días"
        }
    }


    EliminarHorario = (indice, array, state) => {
        var temp = array
        temp.splice(indice, 1);
        this.setState({ [state]: temp })
    }

    CambiarHora = (data1, index, index1, valor) => {
        var temp = this.state.horario
        temp[index].desde_hasta[index1][data1] = valor
        this.setState({ horario: temp })
    }

    Tiempo = (value) => {
        this.setState({ min_reserva: value })
    }

    HoraMaxima = (newValue) => {
        var HoraDesde = new Date(newValue);
        var hora = HoraDesde.getHours() - 1
        var HoraMaxima = new Date();
        HoraMaxima.setHours(hora)
        HoraMaxima.setMinutes(0)
        HoraMaxima.setSeconds(0)
        HoraMaxima.setMilliseconds(0)
        return HoraMaxima
    }

    HoraMinima = (newValue) => {
        var HoraDesde = new Date(newValue);
        var hora = HoraDesde.getHours() + 1
        var HoraMinima = new Date();
        HoraMinima.setHours(hora)
        HoraMinima.setMinutes(0)
        HoraMinima.setSeconds(0)
        HoraMinima.setMilliseconds(0)
        return HoraMinima
    }

    Verificardias = (caso) => {
        var temp = this.state.horario
        if (temp.length > 0) {
            let encontrado = "nop";
            temp.forEach((element) => {
                if (element.dia === caso) {
                    encontrado = element.dia
                }
            });
            return encontrado;
        }
    }

    Validardesde_hasta = () => {
        if (this.state.disponibilidad === "h") {
            var hora = parseInt(this.state.min_reserva) + (parseInt(this.state.hora) * 60)
            if (hora > 0) {
                if (this.state.desde_hasta.length === 0) {
                    if ((parseInt((this.state.hasta.getTime() - this.state.desde.getTime()) / (60000)) < hora)) {
                        swal({
                            title: "",
                            text: "Alerta el tiempo de reserva no puede ser mayor a la diferencia entre el horario.",
                            icon: "error",
                            button: "Aceptar",
                        });
                    } else if (parseInt((this.state.hasta.getTime() - this.state.desde.getTime()) / (60000)) % (hora) > 0) {
                        swal({
                            title: `Sobran ${parseInt((this.state.hasta.getTime() - this.state.desde.getTime()) / (60000)) % hora} minutos en la última reserva.`,
                            text: "¿Desea agregar el horario?",
                            icon: "info",
                            buttons: ["Cancelar", "Aceptar"],
                        }).then((aceptar) => {
                            if (aceptar) {
                                this.Añadirdesde_hasta()
                            }
                        });
                    } else {
                        this.Añadirdesde_hasta()
                    }
                } else {
                    this.Añadirdesde_hasta()
                }
            } else {
                swal({
                    title: "",
                    text: "El tiempo de reserva no puede ser mayor al horario establecido.",
                    icon: "error",
                    button: "Aceptar",
                });
            }
        } else {
            this.Añadirdesde_hasta()
        }

    }

    Añadirdesde_hasta = () => {
        this.setState(
            { data: false },
            () => {
                var temp = this.state.desde_hasta
                var horario = {
                    desde: this.state.desde,
                    hasta: this.state.hasta
                }
                temp.push(horario)
                this.setState({ desde_hasta: temp, desde: new Date(0, 0, 0, this.state.desde_hasta[this.state.desde_hasta.length - 1].hasta.getHours() + 1), hasta: new Date(0, 0, 0, 23), data: true })
            }
        );
    }

    AñadirNuevodia = () => {
        var temp = this.state.horario
        var horario = {
            desde_hasta: this.state.desde_hasta,
            dia: this.state.selectdia,
            id_horario: 0,
            min_reserva: this.props.disponibilidad === "h" ? (parseInt(this.state.min_reserva) + (parseInt(this.state.hora) * 60)) : (0),
            t_reserva: 1,
        }

        temp.push(horario)
        this.setState({ horario: temp, addhorario: false, desde_hasta: [], selectdia: "", min_reserva: "", t_reserva: false, desde: new Date(0, 0, 0, 0), hasta: new Date(0, 0, 0, 23), })

    }

    CerrarModal = () => {

        if (this.state.addhorario) {
            this.setState({ addhorario: false, desde_hasta: [], selectdia: "", min_reserva: "", t_reserva: 1, hora: "", desde: new Date(0, 0, 0, 0), hasta: new Date(0, 0, 0, 23) })

        } else {
            this.props.Cerrar()
        }


    }

    DisabledPicker = () => {
        const { disponibilidad, min_reserva, selectdia, desde_hasta } = this.state;
        if (disponibilidad === "h") {
            return min_reserva === "";
        } else {
            return selectdia === "" || desde_hasta.length === 1;
        }
    }

    render() {


        const IOSSwitch = styled((props) => (<Switch checked={props.value} focusVisibleClassName=".Mui-focusVisible" size="small" onChange={(e) => this.Checked(props.label, e.target.checked)} {...props} />))(({ theme }) => ({
            width: 32, height: 16, padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[600],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 12,
                height: 12,
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
            },
        }));


        return (
            <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => this.CerrarModal()} backdrop="static" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Horario de la Reserva</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box component="form" sx={{ '& > :not(style)': { width: '100%' }, }} noValidate autoComplete="off">
                        <div className="container">
                            {!this.state.addhorario ? (
                                <div>
                                    <div className="row">
                                        <div className="col-8">
                                            <FormLabel component="legend">¿La reserva tiene horario diferente cada día?</FormLabel>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} value={this.state.reservadia} defaultChecked label={"reservadia"} />}
                                                label={this.state.reservadia ? "Cada día tiene un horario" : "Un solo horario para todos los días"}
                                            />
                                        </div>
                                        <div className="col d-flex justify-content-center align-items-center">
                                            <Button variant="outline-primary" size="md" className="mx-2" onClick={() => this.setState({ addhorario: true })} disabled={this.state.reservadia ? (this.state.horario.length === 7) : (this.state.horario.length > 0)}>
                                                <i className="fa-solid fa-circle-plus"></i> Agregar Horario
                                            </Button>
                                        </div>
                                    </div>
                                    <hr />
                                    {this.state.horario.map((data, index) => (
                                        <div className="row">
                                            <label>{this.DiasRender(data.dia)}</label>
                                            <div className="col-9">
                                                {data.desde_hasta.map((data1, index1) => (
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="col my-3">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                    <TimePicker label="Hora Apertura" value={data1.desde} minTime={index1 > 0 ? this.HoraMinima(data.desde_hasta[index1 - 1].hasta) : null}
                                                                        maxTime={this.HoraMaxima(data1.hasta)}
                                                                        ampm={false} views={['hours']} disabled={this.state.estadoindex !== index} onChange={(valor) => this.CambiarHora("desde", index, index1, valor)}
                                                                        renderInput={(params) => <TextField size="small" {...params} variant="outlined" />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="col my-3">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                    <TimePicker label="Hora Cierre" value={data1.hasta} minTime={this.HoraMinima(data1.desde)} ampm={false} views={['hours']} disabled={this.state.estadoindex !== index} onChange={(valor) => this.CambiarHora("hasta", index, index1, valor)}
                                                                        renderInput={(params) => <TextField size="small" {...params} variant="outlined" />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col d-flex align-items-center justify-content-center">
                                                <Button variant="outline-danger" size="sm" className="mx-2" onClick={() => this.EliminarHorario(index, this.state.horario, "horario")}>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </Button>
                                                <Button variant="outline-success" size="sm" className="mx-2" onClick={() => this.setState({ estadoindex: this.state.estadoindex === index ? undefined : index })}>
                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                </Button>
                                            </div>
                                            {this.state.horario.length - 1 !== index && <hr />}
                                        </div>
                                    ))
                                    }
                                </div>

                            ) : (
                                <div className="row" >
                                    {/* <div className="row " > */}
                                    <div className={`${this.state.reservadia ? ("col-4") : ("col-5")}`}>
                                        {this.state.reservadia ? (
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                noOptionsText="No Encontrado"
                                                size="small"
                                                options={this.state.dias}
                                                getOptionDisabled={(option) => option.id === this.Verificardias(option.id)}
                                                onChange={(event, newValue) => { this.setState({ selectdia: newValue.id }) }}
                                                renderInput={(params) => <TextField {...params} label="Selecciona un día" />}
                                            />
                                        ) : (
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                noOptionsText="No Encontrado"
                                                size="small"
                                                options={[{ id: "Tod", label: "Lunes - Domingo" }]}
                                                onChange={(event, newValue) => { this.setState({ selectdia: newValue.id }) }}
                                                renderInput={(params) => <TextField {...params} label="Selecciona un día" />}
                                            />
                                        )}
                                    </div>

                                    {this.state.disponibilidad === "h" ? (
                                        <div className="col">
                                            {/* <FormLabel component="legend">¿La reserva tiene horario diferente cada día?</FormLabel> */}
                                            <div className="row ">
                                                <div className="col-6 ">
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        disabled={this.state.selectdia === "" || this.state.desde_hasta.length > 0}
                                                        noOptionsText="No Encontrado"
                                                        size="small"
                                                        options={this.state.Horas}
                                                        onChange={(event, newValue) => { this.setState({ hora: newValue }) }}
                                                        renderInput={(params) => <TextField {...params} label="Tiempo de reserva en horas" />}
                                                    />
                                                </div>
                                                <div className="col-6 ">
                                                    {/* <TextField size="small" label="Tiempo de Reserva en minutos" variant="outlined" className="form-control mb-3" disabled={this.state.hora === "" || this.state.desde_hasta.length > 0} onChange={(e) => this.Tiempo(e.target.value)} /> */}
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        disabled={this.state.hora === "" || this.state.desde_hasta.length > 0}
                                                        noOptionsText="No Encontrado"
                                                        size="small"
                                                        options={this.state.Minutos}
                                                        getOptionDisabled={(option) => parseInt(option) === (parseInt(this.state.hora) === 0 ? 0 : 61)}
                                                        onChange={(event, newValue) => { this.setState({ min_reserva: newValue }) }}
                                                        renderInput={(params) => <TextField {...params} label="Tiempo de reserva en minutos" />}
                                                    />
                                                    <label className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('min_reserva', this.state.min_reserva, 'required|numeric|min:1,num|max:1440,num', { className: 'text-danger' })}</label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ("")}
                                    {/* </div> */}
                                    <div className="col-12 ">
                                        {this.state.data ? (
                                            <div className="row">
                                                <div className="col-5  my-3">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                        <TimePicker label="Hora Apertura" value={this.state.desde}
                                                            disabled={this.DisabledPicker()}
                                                            maxTime={this.HoraMaxima(this.state.hasta)}
                                                            minTime={this.state.desde_hasta.length > 0 ? this.HoraMinima(this.state.desde_hasta[this.state.desde_hasta.length - 1].hasta) : null}
                                                            onChange={(value) => this.setState({ desde: value })}
                                                            ampm={false} views={['hours']}
                                                            renderInput={(params) => <TextField size="small" {...params} variant="outlined" fullWidth />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="col-5  my-3">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                                        <TimePicker label="Hora Cierre" value={this.state.hasta}
                                                            disabled={this.DisabledPicker()}
                                                            minTime={this.HoraMinima(this.state.desde)}
                                                            onChange={(value) => this.setState({ hasta: value }, () => { this.Validardesde_hasta() })}
                                                            ampm={false} views={['hours']}
                                                            renderInput={(params) => <TextField size="small" {...params} variant="outlined" fullWidth />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                {/* <div className="col-2 d-flex align-items-center justify-content-center my-3">
                                                    <Button variant="outline-success" size="sm" className="mx-2 ml-auto" disabled={this.state.disponibilidad === "h" ? (this.state.min_reserva === "") : (this.state.selectdia === "")} onClick={() => this.Validardesde_hasta()}>
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Button>
                                                </div> */}
                                            </div>
                                        ) : ("")}

                                    </div>
                                    <div className="col-12 ">
                                        {this.state.desde_hasta.length > 0 ? (

                                            this.state.desde_hasta.map((data2, index2) => (
                                                <div className="row">
                                                    <div className="col-5  my-3">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                            <TimePicker label="Hora Apertura" value={data2.desde}
                                                                disabled={true}
                                                                ampm={false} views={['hours']}
                                                                renderInput={(params) => <TextField size="small" {...params} variant="outlined" fullWidth />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className="col-5  my-3">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                                            <TimePicker label="Hora Cierre" value={data2.hasta}
                                                                disabled={true}

                                                                ampm={false} views={['hours']}
                                                                renderInput={(params) => <TextField size="small" {...params} variant="outlined" fullWidth />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center justify-content-center my-3">
                                                        <Button variant="outline-danger" size="sm" className="mx-2 ml-auto" onClick={() => this.EliminarHorario(index2, this.state.desde_hasta, "desde_hasta")}>
                                                            <i className="fa-solid fa-trash-can"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))

                                        ) : ("")}

                                    </div>

                                </div>
                            )}
                        </div>
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    {!this.state.addhorario ? (
                        <Button onClick={() => this.props.GuardarHorario(this.state.horario)} variant="success" size="sm"><i className="fas fa-save"></i> Guardar</Button>
                    ) : (
                        <Button onClick={() => this.AñadirNuevodia()} disabled={this.state.desde_hasta.length === 0} variant="success" size="sm"><i className="fa-solid fa-house-medical-circle-check"></i> Añadir </Button>
                    )}
                </Modal.Footer>
            </Modal >
        );
    }
}

export default Horario;