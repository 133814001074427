
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Autocomplete, TextField, Card } from '@mui/material';
import Global from '../../../../Global';
import axios from "axios";
import swal from "sweetalert";
import HourGlass from "./../../../principal/HourGlass";
import { LocalizationProvider, MobileTimePicker, } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator'



export default class CrearHorario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            HourGlass: true,
            onAccept: false,
            includesm: [25, 10, 5, 15, 20],
            Lugares: [],
            selectedValue: undefined,
            nombre_ronda: "",
            id_ronda: "",
            id_ronda_lugar: "",
            hora: "",
            tolerancia: "",
        }
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'Debe Ingresar mínimo 1 minuto',
                max: 'Puede Ingresar Máximo 10 minutos',
            }
        });

    }


    componentDidMount() {
        this.setState({
            nombre_ronda: this.props.CasoHorario.nombre,
            id_ronda: this.props.CasoHorario.id_ronda,
            Lugares: this.props.CasoHorario.Lugares
        }, () => {
            this.setState({ HourGlass: false })
        })
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth })
    }

    VerificarLugares = (option) => {
        const lugarSeleccionado = this.props.Lugar.find((lugar) => lugar.nombre === option);
        if (lugarSeleccionado) {
            const idSeleccionado = lugarSeleccionado.id_ronda_lugar;
            const estadoSeleccionado = this.props.Lugar.find((lugar) => lugar.nombre === option).estado;
            const lugaresIds = this.state.Lugares.map((lugar) => lugar.id_ronda_lugar);
            return lugaresIds.includes(idSeleccionado) || estadoSeleccionado === 2;
        }
        return false;
    };

    GuardarHorario = () => {
        if (this.validator.fieldValid('toleranciaM')) {
            this.setState({ HourGlass: true })
            var temp = this.props.Lugar
            var temp1 = this.state.Lugares
            var index = temp.findIndex(elemento => elemento.id_ronda_lugar === this.state.id_ronda_lugar)

            var body = {
                ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                id_ronda: Buffer.from("" + this.state.id_ronda).toString("base64"),
                id_ronda_lugar: Buffer.from("" + this.state.id_ronda_lugar).toString("base64"),
                hora: Buffer.from("" + moment(this.state.hora).format("HH:mm:ss")).toString("base64"),
                tolerancia: Buffer.from("" + this.state.tolerancia).toString("base64"),
            };
            axios.post(`${Global.Ruta.Url}ronda/newhorario`, body).then(res => {
                if (res.data.message === "") {
                    var datos = {
                        estado: 1,
                        hora: moment(this.state.hora).format("HH:mm:ss"),
                        id_ronda_horario: res.data.VarHorarioID,
                        id_ronda_lugar: this.state.id_ronda_lugar,
                        nombre: temp[index].nombre,
                        tolerancia: this.state.tolerancia,
                    }
                    temp1.push(datos)
                    this.setState({
                        id_ronda_lugar: "",
                        hora: "",
                        tolerancia: "",
                        selectedValue: undefined,
                        HourGlass: false
                    })
                } else {
                    this.setState({ HourGlass: false })
                    swal({
                        title: "Atención",
                        text: `${res.data.message}`,
                        icon: "warning",
                        button: "Aceptar"
                    }).then(() => {
                        this.props.CerrarModal()
                    })
                }
            }).catch(err => {
                this.setState({ HourGlass: false })
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.props.CerrarModal()
                })
            });
        } else {
            this.validator.showMessageFor('toleranciaM');
            this.forceUpdate();
        }
    }

    DisabledHoraCrear = (value, view) => {
        if (view !== 'minutes') {
            return false;
        }
        const { Lugares, hora } = this.state;
        const setHoraState = parseInt(moment(hora).format("HH"));
        return Lugares.some(lugar => {
            const [hora, minutos] = lugar.hora.split(":");
            return parseInt(hora) === setHoraState && parseInt(minutos) === value;
        });

    };

    DisabledHoraEditar = (value, view, index) => {
        if (view === 'hours') {
            return false;
        }
        if (view === 'minutes') {
            const lugares = [...this.state.Lugares]
            const actual = lugares[index].id_ronda_horario
            const horaactual = lugares[index].hora
            const serHorasola = horaactual.split(":")[0]

            return lugares.some((lugar, i) => {
                if (index !== i && actual !== lugar.id_ronda_horario) {
                    const [setHorasplit, setMinplit] = lugar.hora.split(":")
                    return parseInt(serHorasola) === parseInt(setHorasplit) && parseInt(setMinplit) === parseInt(value)
                }
                return false;
            });
        }
        return false;
    };

    onChangeLugar = (value, index, caso) => {
        var temp = this.state.Lugares
        if (parseInt(caso) === 0) {
            if (value !== null) {
                temp[index].hora = moment(value).format("HH:mm:ss")
            } else {
                temp[index].hora = ""
            }

        } else {
            temp[index].tolerancia = value
        }
        this.setState({ Lugares: temp })
    }

    PrevEditarHorario = (index, caso) => {
        var temp = this.state.Lugares
        if (parseInt(caso) === 0) {
            swal({
                title: "",
                text: `¿Desea eliminar ${temp[index].nombre}?`,
                icon: "warning",
                buttons: ["Cancelar", "Eliminar"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.EditarHorario(index, caso)
                }
            });
        } else {
            if (this.validator.fieldValid(`toleranciaE${index}`) && this.validator.fieldValid(`horarioE${index}`)) {
                this.EditarHorario(index, caso)
            } else {
                this.validator.showMessageFor(`toleranciaE${index}`);
                this.validator.showMessageFor(`horarioE${index}`);
                this.forceUpdate();
            }

        }

    }

    EditarHorario = (index, caso) => {
        this.setState({ HourGlass: true })
        var temp = this.state.Lugares
        var estado = 0
        if (parseInt(caso) === 0) { estado = caso } else { estado = temp[index].estado }

        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            estado: Buffer.from("" + estado).toString("base64"),
            hora: Buffer.from("" + temp[index].hora).toString("base64"),
            tolerancia: Buffer.from("" + temp[index].tolerancia).toString("base64"),
            id_ronda_horario: Buffer.from("" + temp[index].id_ronda_horario).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}ronda/updaterondahorario`, body).then(res => {
            if (res.data.message === "") {
                if (parseInt(caso) === 0) {
                    temp.splice(index, 1);
                    this.setState({ Lugares: temp, HourGlass: false })
                } else {
                    this.setState({ HourGlass: false })
                }
            } else {
                this.setState({ HourGlass: false })
                swal({
                    title: "Atención",
                    text: `${res.data.message}`,
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.props.RutaLugar()
                    this.props.CerrarModal()
                })
            }
        }).catch(err => {
            this.setState({ HourGlass: false })
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.props.CerrarModal()
            })
        });
    }


    render() {
    

        if (!this.state.HourGlass) {
            return (
                <Modal {...this.props} size="lg" backdrop="static" centered onHide={this.props.CerrarModal}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: '16px' }} id="contained-modal-title-vcenter">
                            <span><strong style={{ fontWeight: '900' }}>Edita el horario de la ronda {this.state.nombre_ronda}</strong></span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row flex-wrap">
                                <div className={this.state.windowWidth > 1000 ? "col-md-4 col-12 d-flex" : "col-12 my-1"}>
                                    <div className=" ms-2 w-100">
                                        <Autocomplete
                                            disabled={this.props.CasoHorario.estado === 2}
                                            disableClearable
                                            disablePortal
                                            noOptionsText="No Encontrado"
                                            size="small"
                                            value={this.state.selectedValue}
                                            options={this.props.Lugar.map((lugar) => lugar.nombre)}
                                            getOptionDisabled={(option) => this.VerificarLugares(option)}
                                            renderInput={(params) => (<TextField fullWidth style={{ width: "100%" }} {...params} variant="outlined" placeholder="Selecciona" label="Lugar" />)}
                                            onChange={(event, newValue) => { const select = this.props.Lugar.find((lugar) => lugar.nombre === newValue); if (select) { this.setState({ id_ronda_lugar: select.id_ronda_lugar, selectedValue: newValue }) } }} />
                                    </div>
                                </div>
                                <div className={this.state.windowWidth > 1000 ? "col-md-4 col-12 d-flex" : "col-12 my-1"}>
                                    <div className="ms-2">
                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                            <MobileTimePicker
                                                // showToolbar={false}
                                                views={['hours', 'minutes']}
                                                label="Hora de la ronda"
                                                okText={"Aceptar"}
                                                clearText={"Cancelar"}
                                                clearable={true}
                                                cancelText={""}
                                                minutesStep={5}
                                                ampm={false}
                                                reduceAnimations={true}
                                                value={this.state.hora}
                                                disabled={this.state.id_ronda_lugar === ""}
                                                shouldDisableTime={(value, view) => this.DisabledHoraCrear(value, view)}
                                                onAccept={(newValue) => { if (newValue === null) { this.setState({ hora: "" }) } }}
                                                onChange={(newValue) => this.setState({ hora: newValue })}
                                                onOpen={() => { this.setState({ hora: "" }) }}
                                                renderInput={(params) => <TextField fullWidth  {...params} size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className={this.state.windowWidth > 1000 ? "col-md-4 col-12 d-flex" : "col-12 my-1"}>
                                    <div className="ms-2">
                                        <TextField size="small" id="outlined-basic" variant="outlined" label="Rango en minutos" fullWidth
                                            disabled={this.state.hora === ""}
                                            type={"number"}
                                            value={this.state.tolerancia}
                                            onChange={(e) => this.setState({ tolerancia: e.target.value })}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.GuardarHorario() } }}
                                            onKeyDown={(e) => { if (e.key === 'Tab') { e.preventDefault(); this.GuardarHorario() } }}
                                            onBlur={(e) => { e.preventDefault(); this.GuardarHorario() }}
                                        />
                                        <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message('toleranciaM', this.state.tolerancia, 'required|numeric|min:1,num|integer', { className: 'text-danger' })}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row flex-wrap mt-4">
                                <div className="col-12">
                                    <div className="overflow-auto custom-scroll" style={{ maxHeight: "45vh", overflowY: "auto" }}>
                                        {this.state.Lugares.map((lugar, index) => (
                                            <Card key={index} className="my-3 py-1 d-flex align-items-center " >
                                                <div className="row">
                                                    <div className="col-12 my-2">

                                                        <span key={index} className={` text-${lugar.estado === 1 ? ('primary') : ('danger')}  mx-2 my-2 `} > {lugar.nombre}</span>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <div className="row flex-wrap">
                                                            <div className="col-auto" >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                    <MobileTimePicker
                                                                        label="Hora de la ronda"
                                                                        views={['hours', 'minutes']}
                                                                        okText={"Aceptar"}
                                                                        clearText={"Cancelar"}
                                                                        clearable={true}
                                                                        cancelText={""}
                                                                        minutesStep={5}
                                                                        ampm={false}
                                                                        reduceAnimations={true}
                                                                        disabled={lugar.estado === 2}
                                                                        value={lugar.hora ? new Date(`1970-01-01T${lugar.hora}`) : null}
                                                                        shouldDisableTime={(value, view) => this.DisabledHoraEditar(value, view, index)}
                                                                        onChange={(newValue) => this.onChangeLugar(newValue, index, 0)}
                                                                        renderInput={(params) => <TextField className="ms-2 my-2" {...params} variant="outlined" size="small" />}
                                                                    />
                                                                </LocalizationProvider>
                                                                <div>
                                                                    <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message(`horarioE${index}`, this.state.Lugares[index].hora, 'required', { className: 'text-danger' })}</label>
                                                                </div>

                                                            </div>

                                                            <div className="col-auto">
                                                                <TextField size="small" id="outlined-basic" variant="outlined" label="Rango en minutos" className="ms-2 my-2"
                                                                    value={lugar.tolerancia}
                                                                    disabled={lugar.estado === 2}
                                                                    onChange={(e) => this.onChangeLugar(e.target.value, index, 1)}
                                                                />
                                                                <div>
                                                                    <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message(`toleranciaE${index}`, this.state.Lugares[index].tolerancia, 'required|numeric|min:1,num|max:10,num|integer', { className: 'text-danger' })}</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-outline-light rounded-circle ms-2" disabled={lugar.estado === 2} onClick={() => this.PrevEditarHorario(index, 0)}><i className="fa-solid fa-trash-can" style={{ color: "#d16f6f" }}></i></button>
                                                        <button className="btn btn-outline-light rounded-circle ms-2 " disabled={lugar.estado === 2} onClick={() => this.PrevEditarHorario(index, 1)}><i className="fa-solid fa-floppy-disk" style={{ color: "#229954" }}></i></button>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal >
            );
        }
        return (
            <HourGlass show={this.state.HourGlass}></HourGlass>
        )
    }
}

