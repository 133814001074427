import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import TextInfo from "./subComps/TextInfo";
import { TextField } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import SimpleReactValidator from 'simple-react-validator'
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import axios from "axios";
import Global from "../../../../Global";
import swal from "sweetalert";

const rol = [
  {
    value: "1",
    label: "Propietario",
  },
  {
    value: "2",
    label: "Propietario - Residente",
  },
  {
    value: "3",
    label: "Residente",
  },
];

export default class ModalInfoPr extends Component {

  state = {
    modalVal: false,
    TipoSusc: "3",
    textInpVal: "",
    textInfoValE: false,
    modal: false,
    datos: this.props.datos
  }

  static ModalInfoPr = { datos: PropTypes.object, };

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      locale: 'es',
      messages: {
        required: 'Requerido',
      }
    });
  }

  Cambiar = (data) => {
    this.setState({
      modalVal: true
    })
  }

  handleChange = (prop) => (event) => {
    this.setState((state) => ({
      ...state,
      [prop]: event.target.value,
    }));
  };

  ApruebaRechaza = (validado, datos) => {

    var body = {}

    if (validado === false) {
      this.setState({ modal: true })
      body = {
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        autorizado: Buffer.from("" + !validado).toString("base64"),
        id_autoriza: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        id: Buffer.from("" + datos.id).toString("base64"),
        justificacion: Buffer.from("" + this.state.textInpVal).toString("base64"),
        tipo: Buffer.from("" + this.state.TipoSusc,).toString("base64"),
        nombres: Buffer.from("" + datos.nombres).toString("base64"),
        login: Buffer.from("" + datos.login).toString("base64"),
        email: Buffer.from("" + datos.email).toString("base64"),
        telefono: Buffer.from("" + datos.telefono).toString("base64"),
        inmuebleid: Buffer.from("" + datos.idinmueble).toString("base64"),
        tiposervicio: Buffer.from("" + datos.tiposervicio).toString("base64"),
        identificador: Buffer.from("" + datos.identificador).toString("base64"),
        tipo_doc: Buffer.from("" + datos.tipo_doc).toString("base64"),
        documento: Buffer.from("" + datos.documento).toString("base64"),
        adicionales: Buffer.from(JSON.stringify(datos.adicionales)).toString("base64"),
      }

      axios.post(`${Global.Ruta.Url}preregistro/suscriotrpreregistro`, body).then((res) => {
        if (res.data.caso > 0) { this.Actualiza(!validado) }
        this.setState({
          modal: false,
          modalVal: false
        })
        swal({
          title: "ATENCIÓN!",
          text: res.data.message,
          icon: (parseInt(res.data.caso) > 0) ? "success" : "error",
          button: "Aceptar",
        })
      }).catch((err) => {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then((e) => {
          this.setState({ redirect_home: true });
        });
      });
    } else {
      body = {
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        autorizado: Buffer.from("" + !validado).toString("base64"),
        id_autoriza: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        id: Buffer.from("" + datos.id).toString("base64"),
        justificacion: Buffer.from("" + this.state.textInpVal).toString("base64"),
        nombres: Buffer.from("" + datos.nombres).toString("base64"),
        inmuebleid: Buffer.from("" + datos.idinmueble).toString("base64"),
        login: Buffer.from("" + datos.login).toString("base64"),
        telefono: Buffer.from("" + datos.telefono).toString("base64"),
        email: Buffer.from("" + datos.email).toString("base64"),
      }
      if (this.validator.allValid()) {
        this.setState({ modal: true })
        axios.post(`${Global.Ruta.Url}preregistro/deleteautorizado`, body).then((res) => {
          this.setState({ modalVal: false, modal: false, textInpVal: "", })
          this.Actualiza(!validado)
          swal({
            title: "ATENCIÓN!",
            text: res.data.message,
            icon: (parseInt(res.data.caso) > 0) ? "success" : "error",
            button: "Aceptar",
          }).then(() => {

            this.setState({ modalVal: false, modal: false, textInpVal: "", });
          });
        }).catch((err) => {
          swal({
            title: "ALERTA!",
            text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
            icon: "error",
            button: "Aceptar",
          }).then((e) => {
            this.setState({ redirect_home: true });
          });
        });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  }

  Actualiza = (validado) => {
    var tem = this.state.datos;
    tem.autorizado = validado
    tem.autorizado_view = tem.autorizado_view === "NO" ? ("SI") : ("NO")
    this.setState({ datos: tem });
  };

  render() {
    return (
      <div>
        <Modal show={this.state.modalVal} onHide={() => this.setState({ modalVal: false })} backdrop='static' centered>
          <Modal.Header closeButton>
            {`Pre-Registro -`}<strong>{` ${this.state.datos.nombres}`}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className="col-12 text-center">
                <i className='fa-solid fa-triangle-exclamation my-3 text-warning' style={{ fontSize: '5rem', }} />
                <h5 className="fw-normal">
                  Esta seguro de <strong>{(this.state.datos.autorizado === !false) ? ('Rechazar') : ('Aprobar')}</strong> al usuario: <br /> <strong>{this.state.datos.nombres}</strong> del inmueble <strong>{this.state.datos.direccion}</strong> ? <br />
                </h5>
              </div>
              {(this.state.datos.autorizado === !false) ? (
                <div className="col">
                  <TextField size="small" className="shadow-sm" required fullWidth id="outlined-basic"
                    label="Justificación" name="justificacion" variant="outlined" defaultValue={this.state.textInpVal}
                    onChange={this.handleChange("textInpVal")} />
                  <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('textInpVal', this.state.textInpVal, 'required', { className: 'text-danger' })}</p>
                </div>
              ) : (
                <div className="col">
                  <TextField size="small" className="shadow-sm" required select label="Rol" defaultValue={this.state.TipoSusc} value={this.state.TipoSusc}
                    fullWidth onChange={(e) => { this.setState({ TipoSusc: e.target.value }) }} variant="outlined"
                    inputProps={{ min: 0, style: { textAlign: "center" }, }} >
                    {rol.map((option) => (
                      <MenuItem key={option.value} value={option.value} >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('TipoSusc', this.state.TipoSusc, 'required', { className: 'text-danger' })}</p>
                </div>)}
            </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center'>
            <button className={`btn btn-${this.state.datos.autorizado ? 'danger' : 'success'}`} onClick={() => { this.ApruebaRechaza(this.state.datos.autorizado, this.state.datos) }}>{(this.state.datos.autorizado === !false) ? ('Rechazar') : ('Aprobar')}</button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })} >
          <PpHourglass />
        </Modal>
        <Modal.Header closeButton>Detalles del Pre Registro</Modal.Header>
        <Modal.Body>
          <div className="row g-2">
            <div className="col-12">
              <TextInfo titulo="nombres" texto={this.state.datos.nombres} />
            </div>
            <div className="col-12">
              <TextInfo titulo="dirección" texto={this.state.datos.direccion} />
            </div>
            <div className="col-6">
              <TextInfo titulo="email" texto={this.state.datos.email} />
            </div>
            <div className="col-6">
              <TextInfo titulo="telefono" texto={this.state.datos.telefono} />
            </div>
            <div className="col">
              <TextInfo titulo="login" texto={this.state.datos.login} />
            </div>
            <div className="col">
              <TextInfo titulo="origen" texto={this.state.datos.origen_view} />
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-auto">
              <TextInfo titulo="autorizado" texto={this.state.datos.autorizado_view} color={this.state.datos.autorizado ? 'success' : 'danger'} dark={false} darkText={false} opacity='75' />
            </div>
            <div className="col">
              <TextInfo titulo="Nombre Autoriza" color='secondary' darkText={false} opacity='75' dark={false} texto={this.state.datos.nombre_autoriza} />
            </div>
            <div className="col">
              <TextInfo titulo="fecha de solicitud" color='secondary' darkText={false} opacity='75' dark={false} texto={this.state.datos.fecha_solicitud} />
            </div>
            <div className="col">
              <TextInfo titulo="fecha de autorización" color='secondary' darkText={false} opacity='75' dark={false} texto={this.state.datos.fecha_autoriza} />
            </div>
            <div className="col-12">
              <TextInfo titulo="Justificación" color='secondary' darkText={false} opacity='75' dark={false} texto={this.state.datos.justificacion} large={true} />
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              <button className={`btn btn-${this.state.datos.autorizado ? 'danger' : 'success'}`} onClick={() => this.Cambiar()}>{this.state.datos.autorizado ? 'Rechazar' : 'Aprobar'} </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    );
  }
}
