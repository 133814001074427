import React, { Component } from "react";
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@material-ui/icons';
import swal from "sweetalert";
import axios from "axios";
import Global from "../../../../Global";

class PpBlkAsis extends Component {

    state = {
        showPassword: false,
        password: "",
    }

    handleChange = (prop) => (event) => {
        this.setState((state) => ({
            ...state,
            [prop]: event.target.value
        }));
    };

    EstadoVerClave = () => {
        this.setState((state) => ({
            showPassword: !state.showPassword
        }));
    };

    ValidadPwd = () => {

        axios.get(`${Global.Ruta.Url}adminasamblea/validarpwd/${Buffer.from(Global.Ruta.Ipin).toString("base64")}/
            ${Buffer.from(this.state.password).toString("base64")}/${Buffer.from('' + Global.GlobalUser.userid).toString("base64")}`).then(resp => {
            if (resp.data.message === "") {
                this.props.BloquearDesbloquear()
            } else {
                swal({
                    title: "ALERTA!",
                    text: resp.data.message,
                    icon: "warning",
                    button: "Aceptar"
                })
            }
        }).catch(err => {
            console.log(`Error en ValidadPwd: ${err.message}`)
            this.props.onClose()
        })
    }

    render() {

        return (
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-sm-12 col-md-6 col-lg-6 ">
                    <div className="card">
                        <div className="card-header">
                            Bloqueo de Asistencia
                        </div>
                        <div className="card-body px-4 text-center">
                            <h5 className="card-title">{(this.props.BloqueoAsis === true) ? ("¿Esta seguro de desbloquear la Asistencia?") : ("¿Esta seguro de bloquear la Asistencia?")}</h5>
                            <TextField className="" label='Contraseña' variant="outlined" margin="dense" value={this.state.password}
                                type={this.state.showPassword ? "text" : "password"} onChange={this.handleChange("password")}
                                onKeyDown={(e) => { if (e.key === 'Enter') { this.ValidadPwd() } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={this.EstadoVerClave}>
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className="card-footer">
                            <div className="row justify-content-center w-100">
                                <button className="btn btn-success col-4 col-md-4 col-lg-3 col-xl-2 me-3" onClick={this.ValidadPwd}>Aceptar</button>
                                <button className="btn btn-danger col-4 col-md-4 col-lg-3 col-xl-2" onClick={this.props.onClose}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PpBlkAsis