
import React from "react";
import { Modal } from "react-bootstrap";
import { Box, TextField, Typography, Divider, Tooltip } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown'
// import SimpleReactValidator from 'simple-react-validator'
import moment from "moment";
import "moment/locale/es";
import Global from "../../../Global";


class CrearAgenda extends React.Component {

    state = {
        nota: "",
        DataModal: {},
        nombre: "",
        f_last_edit: "",
        fijar: false,
        DropdownOpen: false,
        hoverIndex: null,
        id_nota: undefined,
        Tareas: [],
        ViewButton: parseInt(Global.GlobalUser.rol) !== 4 ? false : true,
    }

    componentWillMount() {
        this.setState({
            DataModal: this.props.DataModal,
            nombre: this.props.Nota.nombre,
            f_last_edit: this.props.Nota.f_last_edit,
            fijar: this.props.Nota.fijar,
            id_nota: this.props.Nota.id_nota,
            Tareas: this.props.Nota.tareas
        })
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEscapeKey, false);
        if (this.props.DataModal.index1 >= 0) {
            setTimeout(() => {
                var inputElement = document.getElementById(`tarea${this.props.DataModal.index1}`);
                inputElement.focus();
                var textLength = inputElement.value.length;
                inputElement.setSelectionRange(textLength, textLength);
            }, 500);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscapeKey, false);
    }

    handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.idNotaTemp !== prevProps.idNotaTemp) {
            this.setState({ id_nota: this.props.idNotaTemp, DataModal: this.props.DataModal })
        }
        if (this.props.Nota !== prevProps.Nota) {
            this.setState({
                nombre: this.props.Nota.nombre,
                f_last_edit: this.props.Nota.f_last_edit,
                fijar: this.props.Nota.fijar,
                id_nota: this.props.Nota.id_nota,
                Tareas: this.props.Nota.tareas
            })
        }
    }

    handleMouseEnter = (index) => {
        this.setState({ hoverIndex: index });
    }

    handleMouseLeave = () => {
        this.setState({ hoverIndex: null });
    }

    GuardarNueva = (nombre) => {
        var data = {}
        if (nombre !== "") {
            const formattedNombre = nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()

            if (this.state.id_nota === undefined) {
                data = {
                    nombre: formattedNombre,
                    f_last_edit: new Date(),
                    fijar: false,
                    id_nota: undefined,
                    tareas: this.state.Tareas,
                }
                this.props.AñadirAgenda(this.state.DataModal.caso, data)
            } else {
                data = {
                    nombre: formattedNombre,
                    id_nota: this.state.id_nota,
                    index: this.state.DataModal.index
                }
                this.props.updatenombrenota(data)
            }
        }

    }

    AgregarNota = (data) => {
        if (data !== "") {
            const formattedNombre = data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
            var tarea = {
                estado: 0,
                id_tarea: undefined,
                nombre: formattedNombre,
            }
            this.props.AñadirTarea(this.state.id_nota, tarea, this.state.DataModal.index)
            this.setState({ nota: "" })
        }
    }

    CambiarNombreTarea = (nombre, index1) => {
        const formattedNombre = nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()
        var temp = this.state.Tareas
        temp[index1].nombre = formattedNombre
        this.setState({ Tareas: temp })
    }

    CambiarNombreTareaBackend = (nombre, index1) => {
        const formattedNombre = nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()
        var data = {
            index: this.state.DataModal.index,
            index1: index1,
            nombre: formattedNombre,
            id_nota: this.state.id_nota,
            idtarea: this.state.Tareas[index1].id_tarea
        }
        this.props.updatenombrenota(data)
    }

    CrearCopiaAgenda = () => {
        var data = {
            nombre: this.state.nombre,
            tareas: this.state.Tareas
        }
        this.props.AñadirAgenda(2, data)
    }

    render() {

        return (
            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered onHide={this.props.CerrarModal}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '16px' }} id="contained-modal-title-vcenter">{
                        this.state.DataModal.titulo !== "" ?
                            <span>
                                Editar Nota <strong style={{ fontWeight: '900' }}>{this.state.DataModal.titulo.toUpperCase()}</strong>
                            </span>
                            :
                            "Nueva Nota"
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '2px' }}>
                    <Box component="form" sx={{ '& > :not(style)': { width: '100%' }, }} noValidate autoComplete="off">
                        <div className="container">
                            <div className="row" >
                                <div className="col-12 my-2">
                                    <TextField size="small" label="Título" variant="standard" value={this.state.nombre} className="form-control custom-textfield" margin="dense" id="standard-basic"
                                        onChange={(e) => this.setState({ nombre: e.target.value.toLowerCase() })}
                                        inputProps={{ maxLength: 28, style: { fontFamily: 'Comfortaa', fontSize: '14px' } }}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.GuardarNueva(e.target.value) } }}
                                        onKeyDown={(e) => { if (e.key === 'Tab') { e.preventDefault(); this.GuardarNueva(e.target.value) } }}
                                        onBlur={(e) => this.GuardarNueva(e.target.value)}
                                        disabled={this.state.ViewButton}
                                    />
                                </div>
                                <div className="col-12 my-2">
                                    <TextField size="small" label="Añade una nota..." variant="standard" value={this.state.nota} className="form-control " margin="dense" id="standard-basic"
                                        onChange={(e) => this.setState({ nota: e.target.value.toLowerCase() })}
                                        inputProps={{ maxLength: 240, style: { fontFamily: 'Comfortaa', fontSize: '14px' } }}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.AgregarNota(e.target.value) } }}
                                        onKeyDown={(e) => { if (e.key === 'Tab') { e.preventDefault(); this.AgregarNota(e.target.value) } }}
                                        onBlur={(e) => this.AgregarNota(e.target.value)}
                                    />
                                </div>
                                {this.state.Tareas.length > 0 ? (
                                    <div className="col-12 my-2 overflow-x-hidden custom-scroll" style={{ maxHeight: '200px' }} >
                                        {
                                            this.state.Tareas.map((tarea, index1) => (
                                                tarea.estado === 0 && (
                                                    <Typography key={index1} sx={{ mb: 1 }} color="text.secondary" variant="caption" className="text-start"
                                                        onMouseEnter={() => this.handleMouseEnter(index1)}
                                                        onMouseLeave={this.handleMouseLeave}>
                                                        <div className="row align-items-center">
                                                            <div className="col-1">
                                                                <button className="btn btn-light rounded-circle" onClick={() => this.props.EstadoTarea(this.state.DataModal.index, index1, 1)}>
                                                                    <i className="fa-regular fa-circle" style={{ color: "#608fe2" }}></i>
                                                                </button>
                                                            </div>
                                                            <div className="col grid justify-content-start" >
                                                                <TextField size="small" value={tarea.nombre} fullWidth sx={{ "& fieldset": { border: 'none' }, }} multiline
                                                                    id={`tarea${index1}`}
                                                                    onChange={(e) => this.CambiarNombreTarea(e.target.value.toLowerCase(), index1)}
                                                                    onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.CambiarNombreTareaBackend(e.target.value, index1) } }}
                                                                    onKeyDown={(e) => { if (e.key === 'Tab') { e.preventDefault(); this.CambiarNombreTareaBackend(e.target.value, index1) } }}
                                                                    onBlur={(e) => this.CambiarNombreTareaBackend(e.target.value, index1)}
                                                                    inputProps={{ style: { fontFamily: 'Comfortaa', fontSize: '14px' } }}
                                                                />
                                                            </div>
                                                            {(!this.state.ViewButton) ? (
                                                                <div className="col-1 pe-5">
                                                                    {this.state.hoverIndex === index1 && (
                                                                        <button className="btn btn-light rounded-circle" onClick={() => this.props.EstadoTarea(this.state.DataModal.index, index1, 2)}>
                                                                            <i className="fa-solid fa-trash-can" style={{ color: "#d16f6f" }}></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )
                                                                : ("")
                                                            }
                                                        </div>

                                                    </Typography>
                                                )
                                            ))
                                        }
                                        {
                                            (this.state.Tareas.find((notas) => notas.estado === 1)) && (
                                                <Divider style={{ backgroundColor: 'grey', height: '2px' }} className="my-2" />
                                            )
                                        }
                                        {
                                            this.state.Tareas.map((tarea, index1) => (
                                                tarea.estado === 1 && (
                                                    <Typography key={index1} sx={{ mb: 1 }} color="text.secondary" className="text-start"
                                                        onMouseEnter={() => this.handleMouseEnter(index1)}
                                                        onMouseLeave={this.handleMouseLeave}>
                                                        <div className="row align-items-center">
                                                            <div className="col-1">
                                                                <button className="btn btn-light rounded-circle" onClick={() => this.props.EstadoTarea(this.state.DataModal.index, index1, 1)}>
                                                                    <i className="fa-regular fa-circle-check fa-shake" style={{ color: "#608fe2" }}></i>
                                                                </button>
                                                            </div>
                                                            <div className="col grid justify-content-start">
                                                                <TextField size="small" value={tarea.nombre} fullWidth sx={{ "& fieldset": { border: 'none' } }} multiline
                                                                    id={`tarea${index1}`}
                                                                    onChange={(e) => this.CambiarNombreTarea(e.target.value.toLowerCase(), index1)}
                                                                    inputProps={{ style: { fontFamily: "Comfortaa", textDecoration: "line-through", color: "#ccc", fontSize: '14px' } }}
                                                                />
                                                            </div>
                                                            {(!this.state.ViewButton) ? (
                                                                <div className="col-1 pe-5">
                                                                    {this.state.hoverIndex === index1 && (
                                                                        <button className="btn btn-light rounded-circle" onClick={() => this.props.EstadoTarea(this.state.DataModal.index, index1, 2)}>
                                                                            <i className="fa-solid fa-trash-can" style={{ color: "#d16f6f" }}></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )
                                                                : ("")
                                                            }
                                                        </div>
                                                    </Typography>
                                                )
                                            ))
                                        }
                                    </div>
                                ) : ("")}
                            </div>
                        </div>
                    </Box>
                </Modal.Body>

                {
                    (parseInt(this.state.DataModal.caso) === 2) ? (
                        <div className="mt-auto d-flex justify-content-between my-2">

                            <div className="d-flex justify-content-start ps-4">
                                {(!this.state.ViewButton) ? (
                                    <React.Fragment>
                                        <Tooltip title="Eliminar">
                                            <button className="btn btn-light rounded-circle" onClick={(event) => { this.props.UpdateNota(this.state.DataModal.index, 2) }}>
                                                <i className="fa-solid fa-trash-can" style={{ color: "#d16f6f" }}></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Archivar">
                                            <button className="btn btn-light rounded-circle" onClick={(event) => { this.props.UpdateNota(this.state.DataModal.index, 1) }}>
                                                <i className="fa-solid fa-box-archive" style={{ color: "#abc9e8" }}></i>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                )
                                    : ("")
                                }
                            </div>


                            {/* <div className="d-flex align-items-center pe-2 justify-content-end">

                        </div> */}
                            <div className="d-flex align-items-center pe-2">
                                <span style={{ fontSize: '12px' }} className="pe-2">Modificada: {moment(this.state.f_last_edit).format("DD MMM YY HH:mm")}</span>
                                {(!this.state.ViewButton) ? (
                                    <Tooltip title="Más">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" className="rounded-circle custom-dropdown-toggle">
                                                <i className="fa-solid fa-ellipsis-vertical" style={{ color: "#8dbdee" }}></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(event) => { this.setState({ DropdownOpen: false }); this.CrearCopiaAgenda() }}>Crear una copia</Dropdown.Item>
                                                <Dropdown.Item onClick={(event) => { this.setState({ DropdownOpen: false }); this.props.MarcarAllElementos(this.state.DataModal.index, 1, 1) }}>Marcar todos los elementos</Dropdown.Item>
                                                <Dropdown.Item onClick={(event) => { this.setState({ DropdownOpen: false }); this.props.MarcarAllElementos(this.state.DataModal.index, 2, 1) }}>Desmarcar todos los elementos</Dropdown.Item>
                                                <Dropdown.Item onClick={(event) => { this.setState({ DropdownOpen: false }); this.props.MarcarAllElementos(this.state.DataModal.index, 2, 2) }}>Eliminar elementos marcados</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Tooltip>
                                )
                                    : ("")
                                }
                            </div>
                        </div>
                    ) : ("")
                }

            </Modal >
        );
    }
}

export default CrearAgenda;