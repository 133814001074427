import React, { Component } from 'react';

import HeaderA2 from "./Home/HeaderA2";
import HomeAsamblea from './Asamblea/HomeAsamblea';
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";

class Asamblea extends Component {
    state = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('Asamblea') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }
        return (
            <div className='bg-light g-r-prf3 vh-100'>
                <HeaderA2 />
                <HomeAsamblea />
                 
            </div>
        );
    }
}

export default Asamblea;