import React, { Component } from 'react';

import axios from "axios";
import Global from "../../../Global";

import MaterialTable from "material-table";
import { Fab, Tooltip, } from "@material-ui/core";
import CartaDetalle from './CartaDetalle';
import moment from 'moment'
import { Modal } from 'react-bootstrap';

const columns = [
    {
        field: "mensajeid",
        title: "Id inmueble",
        hidden: true
    },
    {
        field: "titulo",
        title: "Titulo",
    },
    {
        field: "fecha",
        title: "Fecha",
    },
    {
        field: "destino",
        title: "Destino",
    },
    {
        field: "remitente",
        title: "Remitente",
    },
    {
        field: "contenido",
        title: "Contenido",
    },
];


class TablaListHmsn extends Component {

    state = {
        redirect_home: false,
        redirect_login: 0,
        form_user: 0,
        rows: [],
        nombres: [],
        newData: [],
        count: 0,
        data: false,
        contactos: [],

        modalShow: false,
        MensajeId: -1,
        TituloMensaje: "",
    }

    componentWillMount() {
        axios.get(Global.Ruta.Url + "mensajeria/mensajesenvi/" + Buffer.from(Global.Ph.Codigo).toString("base64"))
            .then((res) => {
                if (res.data.message === "") {
                    var tem = [];
                    var msn = {};
                    for (var i = 0; i < res.data.Mensajes_enviados.length; i++) {
                        msn = {
                            mensajeid: res.data.Mensajes_enviados[i].mensajeid,
                            titulo: res.data.Mensajes_enviados[i].titulo,
                            fecha:  moment(res.data.Mensajes_enviados[i].fecha).format('LLLL'),
                            destino: res.data.Mensajes_enviados[i].destino,
                            remitente: res.data.Mensajes_enviados[i].remitente,
                            contenido: res.data.Mensajes_enviados[i].contenido
                        };
                        tem.push(msn);
                    }
                    this.setState({ rows: tem, data: true });
                } else {
                    this.props.cerrar();
                }
            }).catch(err => {
                this.props.cerrar();
            })

    }

    Enviados = (MensajeId, TituloMensaje) => {
        this.setState({ MensajeId: MensajeId, modalShow: true, TituloMensaje: TituloMensaje })
    }

    render() {



        if (this.state.data === true) {
            return (
                <Modal.Body >

                    <Modal show={this.state.modalShow} onHide={() => { this.setState({ modalShow: false }) }}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <CartaDetalle id="parent-modal-title" mensajeid={this.state.MensajeId} cerrar={() => { this.setState({ modalShow: false }) }} titulomensaje={this.state.TituloMensaje} />
                            </Modal.Body>
                    </Modal>

                    <div className="row justify-content-center mt-1">
                        <div className="tablaContact1 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <MaterialTable
                                columns={columns}
                                data={this.state.rows}
                                title={this.props.tituloT}

                                actions={[
                                    {
                                        onClick: (event, rowData) => { this.Enviados(rowData.mensajeid, rowData.titulo) }
                                    }
                                ]}

                                options={{
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    rowStyle:{fontSize: "0.9rem"},
                                }}

                                components={{
                                    Action: props => (
                                        <div className="py-3 mx-3">
                                            <Tooltip title="Remitente Mensaje" placement="left-start" >
                                                <Fab
                                                    onClick={(event) => props.action.onClick(event, props.data)}
                                                    color="primary"
                                                    aria-label="edit"
                                                    size="small"
                                                >
                                                    <i className="fas fa-search" />
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                    ),
                                }}

                                localization={{
                                    header: {
                                        actions: 'ver detalles ',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos por mostrar.',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'registros',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar',
                                        nRowsSelected: '{0} ' + this.props.tituloT + '(s) Seleccionado(s)'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );

    }

}

export default TablaListHmsn;