import React, { Component } from "react";
import MaterialTable, {MTableToolbar } from "material-table";
import moment from "moment";
import "moment/locale/es";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import { Autocomplete, TextField } from "@mui/material";

import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Es from "date-fns/locale/es";

export default class DespachoVisit extends Component {

  state = {
    cols: [],
    data: false,
    data2: true,
    view: [],
    direcciones: [],
    Nuevos: [],
    Frecuentes: [],
    Anunciados: [],
    Historial: [],
    pag: 1,
    inmuebleid:"",
    startDate: null,
    endDate: null,
  };

  componentWillMount() {
    this.BuscaInmueble();
  }

  Cambio = (opc) => {

    this.setState({
      data: false, data2: false
    })

    var data = [];
    var Cols = [];

    switch (parseInt(opc)) {
      case 1:
        data = this.state.Historial;
        Cols = [
          {
            field: `id_registro`,
            title: "id_registro",
            hidden: true,
          },
          {
            field: `nombre_visitante`,
            title: "Visitante",
          },
          {
            field: `direccion`,
            title: "Dirección",
          },
          {
            field: `nombre_susc`,
            title: "Residente",
          },
          {
            field: `fecha`,
            title: "Fecha de Visita",
            render: data => moment(data.fecha).format("DD MMM YYYY"),
          },
          {
            field: `nombre_portero`,
            title: "Portero",
          },
          {
            field: `tipo`,
            title: "Tipo",
          },
          {
            field: `detalle`,
            title: "Detalle",
          },
        ]
        break;
      case 2:
        data = this.state.Frecuentes;
        Cols = [
          {
            field: `id_registro`,
            title: "id_registro",
            hidden: true,
          },
          {
            field: `nombre_visitante`,
            title: "Visitante",
          },
          {
            field: `direccion`,
            title: "Dirección",
          },
          {
            field: `nombre_susc`,
            title: "Residente",
          },
          {
            field: `fecha`,
            title: "Fecha de Visita",
            render: data => moment(data.fecha).format("DD MMM YYYY"),
          },
          {
            field: `nombre_portero`,
            title: "Portero",
          },
        ]
        break;
      case 3:
        data = this.state.Anunciados;
        Cols = [
          {
            field: `id_registro`,
            title: "id_registro",
            hidden: true,
          },
          {
            field: `nombre_visitante`,
            title: "Visitante",
          },
          {
            field: `direccion`,
            title: "Dirección",
          },
          {
            field: `nombre_susc`,
            title: "Residente",
          },
          {
            field: `fecha`,
            title: "Fecha de Visita",
            render: data => moment(data.fecha).format("DD MMM YYYY"),
          },
          {
            field: `nombre_portero`,
            title: "Portero",
          },
          {
            field: `detalle`,
            title: "Detalle",
          },
        ]
        break;
      case 4:
        data = this.state.Nuevos;
        Cols = [
          {
            field: `id_registro`,
            title: "id_registro",
            hidden: true,
          },
          {
            field: `nombre_visitante`,
            title: "Visitante",
          },
          {
            field: `direccion`,
            title: "Dirección",
          },
          {
            field: `fecha`,
            title: "Fecha de Visita",
            render: data => moment(data.fecha).format("DD MMM YYYY"),
          },
          {
            field: `nombre_portero`,
            title: "Portero",
          },

        ]
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.setState({
        pag: opc,
        view: data,
        cols: Cols,
        data: true,
        data2: false
      });
    }, 100);
  }

  BuscaInmueble = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      porteroid: Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}admin/listinm`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          direcciones: res.data.Inmuebles,
        });
      } else {
        this.setState({ data: false });
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          redirect_home: true,
        });
      });
    });
  };

  Historial = (e) => {

    this.setState({ data2: false, data: false, inmuebleid:e.inmuebleid });

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_inmueble: Buffer.from("" + e.inmuebleid).toString("base64"),
      id_portero:Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/historialvisitantes`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Nuevos: res.data.Nuevos,
          view: res.data.Historial,
          cols: [
            {
              field: `id_registro`,
              title: "id_registro",
              hidden: true,
            },
            {
              field: `nombre_visitante`,
              title: "Visitante",
            },
            {
              field: `direccion`,
              title: "Dirección",
            },
            {
              field: `nombre_susc`,
              title: "Residente",
            },
            {
              field: `fecha`,
              title: "Fecha de Visita",
              render: data => moment(data.fecha).format("DD MMM YYYY"),
            },
            {
              field: `nombre_portero`,
              title: "Portero",
            },
            {
              field: `tipo`,
              title: "Tipo",
            },
            {
              field: `detalle`,
              title: "Detalle",
            },
          ],
          Frecuentes: res.data.Frecuentes,
          Anunciados: res.data.Anunciados,
          Historial: res.data.Historial,
          data: true
        });
      } else {
        this.setState({ data: false, data2: true });
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          redirect_home: true,
        });
      });
    });
  }

  HistorialDate = () => {

    this.setState({ data2: false, data: false});

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_inmueble: Buffer.from("" + this.state.inmuebleid).toString("base64"),
      id_portero:Buffer.from(Global.GlobalUser.userid).toString("base64"),
      fechai:Buffer.from(moment(this.state.startDate).format("DD/MM/YYYY")).toString("base64"),
      fechaf:Buffer.from(moment(this.state.endDate).format("DD/MM/YYYY")).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/historialvisitantesdate`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Nuevos: res.data.Nuevos,
          view: res.data.Historial,
          cols: [
            {
              field: `id_registro`,
              title: "id_registro",
              hidden: true,
            },
            {
              field: `nombre_visitante`,
              title: "Visitante",
            },
            {
              field: `direccion`,
              title: "Dirección",
            },
            {
              field: `nombre_susc`,
              title: "Residente",
            },
            {
              field: `fecha`,
              title: "Fecha de Visita",
              render: data => moment(data.fecha).format("DD MMM YYYY"),
            },
            {
              field: `nombre_portero`,
              title: "Portero",
            },
            {
              field: `tipo`,
              title: "Tipo",
            },
            {
              field: `detalle`,
              title: "Detalle",
            },
          ],
          Frecuentes: res.data.Frecuentes,
          Anunciados: res.data.Anunciados,
          Historial: res.data.Historial,
          data: true
        });this.Cambio(1)
      } else {
        this.setState({ data: false, data2: true });
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          redirect_home: true,
        });
      });
    });
  }

  Limpiar = () => {
    this.setState({ data2: false, data: false });

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_inmueble: Buffer.from("" + this.state.inmuebleid).toString("base64"),
      id_portero:Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/historialvisitantes`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Nuevos: res.data.Nuevos,
          view: res.data.Historial,
          cols: [
            {
              field: `id_registro`,
              title: "id_registro",
              hidden: true,
            },
            {
              field: `nombre_visitante`,
              title: "Visitante",
            },
            {
              field: `direccion`,
              title: "Dirección",
            },
            {
              field: `nombre_susc`,
              title: "Residente",
            },
            {
              field: `fecha`,
              title: "Fecha de Visita",
              render: data => moment(data.fecha).format("DD MMM YYYY"),
            },
            {
              field: `nombre_portero`,
              title: "Portero",
            },
            {
              field: `tipo`,
              title: "Tipo",
            },
            {
              field: `detalle`,
              title: "Detalle",
            },
          ],
          Frecuentes: res.data.Frecuentes,
          Anunciados: res.data.Anunciados,
          Historial: res.data.Historial,
          data: true
        }); this.Cambio(1)
      } else {
        this.setState({ data: false, data2: true });
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          redirect_home: true,
        });
      });
    });
  }

  render() {

    return (
      <div className="container-fluid bg-light h-100">
        <div className="g-r-prf2 h-100 py-3">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col mui-fixer">
                <Autocomplete disableClearable disablePortal id="combo-box-demo" sx={{ minWidth: '20rem', maxHeight: '1.5rem' }}
                  options={this.state.direcciones}
                  getOptionLabel={(option) => option.direccion}
                  onChange={(event, value) => this.Historial((value))}
                  renderInput={(params) => (<TextField {...params} label="Dirección" />)}
                />
              </div>
              <div className="col-2 d-grid">
                <button className="btn btn-primary text-capitalize btn-sm" onClick={() => this.Cambio(1)} disabled={(this.state.data2 || parseInt(this.state.pag) === 1) ? true : false}>
                  Todos
                </button>
              </div>
              <div className="col-2 d-grid">
                <button className="btn btn-primary text-capitalize btn-sm" onClick={() => this.Cambio(2)} disabled={(this.state.data2 || parseInt(this.state.pag) === 2) ? true : false}>
                  Frecuentes
                </button>
              </div>
              <div className="col-2 d-grid">
                <button className="btn btn-primary text-capitalize btn-sm" onClick={() => this.Cambio(3)} disabled={(this.state.data2 || parseInt(this.state.pag) === 3) ? true : false}>
                  Anunciados
                </button>
              </div>
              <div className="col-2 d-grid">
                <button className="btn btn-primary text-capitalize btn-sm" onClick={() => this.Cambio(4)} disabled={(this.state.data2 || parseInt(this.state.pag) === 4) ? true : false}>
                  Desde Porteria
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 py-5">
            {this.state.data ? (
              <div className="card h-100 shadow ">
                <MaterialTable
                  columns={this.state.cols}
                  data={this.state.view}
                  title={``}
                  options={{
                    pageSize:
                      this.state.view.length < 20
                        ? this.state.view.length
                        : 20,
                    pageSizeOptions: [
                      this.state.view.length < 20
                        ? this.state.view.length
                        : 20,
                    ],

                    search: true,
                    exportButton: true,
                    exportAllData: true,
                    exportDelimiter: ";",
                    exportFileName: (`Informe de Visitas, ${moment(new Date()).format('LL')}`),

                    actionsColumnIndex: -1,
                    padding: "dense",
                    rowStyle: (rowData) => {
                      if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                        return {
                          backgroundColor: "#eeeeee",
                          fontSize: "0.9rem",
                          textTransform: "uppercase"
                        };
                      }
                      return {
                        backgroundColor: "#ffffff",
                        fontSize: "0.9rem",
                        textTransform: "uppercase"
                      };
                    },
                  }}

                  localization={{

                    pagination: {
                      labelRowsSelect: 'registros',
                      labelDisplayedRows: '{from}-{to} de {count}',
                      firstTooltip: 'Primera página',
                      previousTooltip: 'Página anterior',
                      nextTooltip: 'Próxima página',
                      lastTooltip: 'Última página'
                    },
                    toolbar: {
                      searchTooltip: 'Buscar',
                      searchPlaceholder: 'Buscar',
                      exportCSVName: "CSV",
                      exportPDFName: "PDF"
                    }
                  }}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <div style={{padding: '0px 10px'}}>

                        <div className="row pb-3">
                      <div className="col-4">
                        <div className="position-relative s-r-option d-flex align-item-center" style={{ zIndex: 23 }}>
                          <Accordion className="absolute-swt shadow-sm" >
                            <AccordionSummary
                              className=""
                              expandIcon={<i className="fas fa-chevron-down"></i>}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                            >
                              <Typography>Fecha</Typography>
                            </AccordionSummary>
                            <AccordionDetails className="bg-light shadow">
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={Es}
                                  >
                                    <DatePicker
                                      label="fecha inicio"
                                      value={this.state.startDate}
                                      maxDate={new Date()}
                                      onChange={(date) => {
                                        this.setState({ startDate: date });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          className="barraCalendar"
                                          size="small"
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={Es}
                                  >
                                    <DatePicker
                                      label="fecha final"
                                      value={this.state.endDate}
                                      minDate={this.state.startDate}
                                      maxDate={new Date()}
                                      onChange={(date) => {
                                        this.setState({ endDate: date });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          className="barraCalendar"
                                          size="small"
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                      <div className="col">
                        <button className="btn btn-primary text-nowrap btn-sm mx-3" disabled={this.state.startDate === null || this.state.endDate === null ? (true) : (false)} onClick={this.HistorialDate}> Buscar </button>
                        <button className="btn btn-danger text-nowrap btn-sm mx-3" onClick={this.Limpiar}> Limpiar </button>
                      </div>
                    </div>
                          
                        </div>
                      </div>
                    ),
                  }}

                />
              </div>
            ) : ((this.state.data2) ? ("") : (
              <div className="d-flex justify-content-center bg-with text-info flex-column align-items-center min-vh-100">
                <div
                  className="spinner-border fs-3 m-3"
                  style={{ width: "5rem", height: "5rem" }}
                  role="status"></div>
                <p className="fs-3">Cargando...</p>
              </div>
            ))
            }

          </div>
        </div>
      </div>
    );
  }
}
