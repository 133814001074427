import React, { Component } from "react";
import MaterialTable from "material-table";
import { CookieSesion } from "../../Helpers";
import { NavLink, Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import FormContestar from "./FormContestar";

import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import HeadTitle from "../../principal/HeadTitle";

const columns = [
  {
    field: "id_mensaje",
    title: "Id Mensaje",
    hidden: true,
  },
  {
    field: "suscriptorid",
    title: "Radicado",
    hidden: true,
  },
  {
    field: "nombre_susc",
    title: "Nombre",
  },
  {
    field: "direccion",
    title: "Dirección",
  },
  {
    field: "asunto",
    title: "Asunto",
    cellStyle: {
      maxWidth: "30rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  {
    field: "mensaje",
    title: "Mensaje",
    cellStyle: {
      maxWidth: "30rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  {
    field: "fecha",
    title: "Fecha de la solicitud",
  },
];

export default class ListSolicitudesComu extends Component {
  state = {
    data: false,
    rows: [],
    redirect_home: false,
    redirect_login: 0,

    modalShow1: false,

    idmensaje: 0,
    nombre_susc: "",
    direccion: "",
    asunto: "",
    mensaje: "",

    fechai: null,
    fechaf: null,
    fechaAct: new Date(),

    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          this.listarSolicitudes();
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  listarSolicitudes = () => {
    this.setState({ data: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}admin/listsolicitudadmin`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            rows: res.data.Solicitudes,
            data: true,
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  Guardar = (VarCaso, VarTipoRes, idmensaje, contenido, pdf) => {
    this.setState({
      modalShow1: false,
    });
    console.log(pdf)
    switch (parseInt(VarCaso)) {
      case 1:
        this.setState({
          data: false,
        });

        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          caso: Buffer.from("" + VarCaso).toString("base64"),
          tipores: Buffer.from("" + VarTipoRes).toString("base64"),
          idmensaje: Buffer.from("" + idmensaje).toString("base64"),
          respuesta: Buffer.from(contenido.toUpperCase()).toString("base64"),
          pdf: Buffer.from(`${pdf !== null ? pdf : ""}`).toString("base64"),
          carchivo: Buffer.from(`${pdf !== null ? '1' : '0'}`).toString("base64"),
        };

        axios
          .post(`${Global.Ruta.Url}admin/responderpeti`, body)
          .then((res) => {
            if (res.data.message === "") {
              swal({
                title: "ATENCIÓN!",
                text: "La repuesta fue enviada.",
                icon: "success",
                button: "Aceptar",
              }).then(() => {
                this.listarSolicitudes();
              });
            } else {
              swal({
                title: "ERROR!",
                text: res.data.message,
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({
                  data: true,
                });
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ERROR!",
              text: "Por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({
                data: true,
              });
            });
          });
        break;
      case 2:
        swal({
          title: "ATENCIÓN!!",
          text: `Por favor, escriba una descripción por la cual no aplica`,
          content: {
            element: "input",
            attributes: {
              placeholder: "Ingrese una descripción",
            },
          },
          icon: "warning",
          buttons: ["CANCELAR", "ACEPTAR"],
        }).then((result) => {
          if (result) {
            this.setState({
              data: false,
            });

            var body = {
              ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
              codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
              caso: Buffer.from("" + VarCaso).toString("base64"),
              tipores: Buffer.from("" + VarTipoRes).toString("base64"),
              idmensaje: Buffer.from("" + idmensaje).toString("base64"),
              respuesta: Buffer.from(result.toUpperCase()).toString("base64"),
              pdf: Buffer.from(``).toString("base64"),
              carchivo: Buffer.from(`0`).toString("base64"),
            };

            axios
              .post(`${Global.Ruta.Url}admin/responderpeti`, body)
              .then((res) => {
                if (res.data.message === "") {
                  swal({
                    title: "ATENCIÓN!",
                    text: "La repuesta fue enviada.",
                    icon: "success",
                    button: "Aceptar",
                  }).then(() => {
                    this.listarSolicitudes();
                  });
                } else {
                  swal({
                    title: "ERROR!",
                    text: res.data.message,
                    icon: "error",
                    button: "Aceptar",
                  }).then(() => {
                    this.setState({
                      data: true,
                    });
                  });
                }
              })
              .catch((err) => {
                swal({
                  title: "ERROR!",
                  text: "Por favor vuelva a intentarlo mas tarde.",
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({
                    data: true,
                  });
                });
              });
          }
        });
        break;
      default:
        break;
    }
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.data) {
      return (
        <div className="container-fluid bg-light overflow-auto">
          {/* modal */}
          <Modal show={this.state.modalShow1} size="lg">
            <FormContestar
              onClose={() => this.setState({ modalShow1: false })}
              idmensaje={this.state.idmensaje}
              Guardar={this.Guardar}
              Nombre={this.state.nombre_susc}
              Direccion={this.state.direccion}
              Asunto={this.state.asunto}
              Mensaje={`${this.state.mensaje}.`}
            />
          </Modal>
          {/* fin del modal */}
          <div className="row justify-content-center gy-3 pt-3 pb-4">
            <div className="col-12">
              <HeadTitle
                titulo="Tramites de la Comunidad"
                subTitulo="Leer y responder las peticiones de los residentes"
                classNameOwn="row px-3"
                indicadores={[]}
                actionButton={
                  <div className="col my-2">
                    <div className="row gx-1 justify-content-end">
                      <div className="col-auto d-grid">
                        <NavLink
                          className="btn btn-outline-primary btn-block text-nowrap"
                          exact
                          to={Global.Ruta.Raiz + "/histori-solicitud"}
                        >
                          Ver Historial
                        </NavLink>
                      </div>
                      <div className="col-auto d-grid">
                        <button
                          className="btn btn-outline-warning"
                          onClick={this.listarSolicitudes}
                        >
                          Recargar Peticiones
                        </button>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="col-12 shadow-sm px-3">
              <MaterialTable
                title="Autorizaciones"
                columns={columns}
                data={this.state.rows}
                options={{
                  pageSize: 10,
                  actionsColumnIndex: -1,
                  rowStyle: { fontSize: "0.9rem" },
                  showTitle: false
                }}
                actions={[
                  {
                    tooltip: "Responder",
                    onClick: (event, rowData) =>
                      this.setState({
                        modalShow1: true,
                        idmensaje: rowData.id_mensaje,
                        nombre_susc: rowData.nombre_susc,
                        direccion: rowData.direccion,
                        asunto: rowData.asunto,
                        mensaje: rowData.mensaje,
                      }),
                  },
                ]}
                components={{
                  Action: (props) => (
                    <button
                      className="btn btn-primary mx-5"
                      onClick={(event) =>
                        props.action.onClick(event, props.data)
                      }
                    >
                      Responder
                    </button>
                  ),
                }}
                localization={{
                  header: {
                    actions: "Responder",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
