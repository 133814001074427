import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import Global from "../../../Global";
import axios from "axios";
import Loader from "../../principal/Loader";
import { CookieSesion, CookieNombrePH, CookiePhCodigo, CookieNombre, CookieTipo, CookieUserId, CookieRol, CookieNitph, CookieCiudad, } from "../../Helpers";
import Psidebar from "./HomePorteria/Psidebar"
import CardCopyLink, { Modales } from "./compos/CardCopyLink";
import { Tooltip, Card } from "@mui/material";
import { Modal } from "react-bootstrap";
import AgendaMain from "../Agenda/AganedaMain"
import { DspachPendDeliv, DspachPendPaqu } from "../porteria/despacho/DspachPendient"
import TableVisitAnunci from "./compos/subCompPort/TableVisitAnunci"
import DataTablePlacas from "./../../admin/porteria/Vehiculos/TablePlacas"
import ContainerReservas from "./../reservas/ContainerReservas"
import PorteriaAdmin from "./../porteria/PorteriaAdmin"
import moment from "moment";
import { NavLink } from "react-router-dom";

const cookies = new Cookie();


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect_login: 0,
            CantInmuebles: 0,
            Reservas_Pend: 0,
            CantPreRegistro: 0,
            solicitudes: 0,
            solicitudesAdmin: 0,
            TareasPendientes: 0,
            Rol: -1,
            Roles: [1, 2],

            nombreEvento: 'Bienvenido a GoPH',
            descrEvento: '',
            slide: false,
            h: 0,
            pantalla: window.screen.width,
            windowWidth: window.innerWidth,
            modalQr: false,
            modalMsN: false,
            Hora: ``,
            interval: undefined,
            EnvioMensaje: 0,
            CambioNota: 0,

            portPackBtns: [
                {
                    id: 1,
                    nombre: "Crear Paquete",
                    bgColor: "secondary",
                    icon: "boxes-packing",
                    mod: false,
                    size: false,
                    button: 'Despachar'
                },
                {
                    id: 3,
                    nombre: "Nuevo Visitante",
                    bgColor: "warning",
                    icon: "id-card-clip",
                    mod: false,
                    size: false,
                    button: 'Validar'
                },
            ],

            paquetesPendientes: 0
        };

        // Asegúrate de enlazar la función si no usas sintaxis de clase
        this.handleDataUpdate = this.handleDataUpdate.bind(this);
    }

    handleDataUpdate(data) {
            this.setState({paquetesPendientes: data})
        // console.log("Data actualizada:", data);
        // Aquí puedes hacer cualquier cosa con el nuevo dato, como actualizar el estado del componente padre
    }

    componentWillMount() {
        this.hora()
        var login = CookieSesion();
        var Rol = CookieRol();
        var userid = CookieUserId();

        if (parseInt(login) > 0) {
            if (parseInt(userid) > 0) {
                this.setState({
                    Rol: parseInt(Rol),
                });
                Global.GlobalUser.rol = parseInt(Rol);
                Global.GlobalUser.userid = CookieUserId();
                Global.GlobalUser.Nombre = CookieNombre();
                Global.Ph.Nombre = CookieNombrePH();
                Global.Ph.Codigo = CookiePhCodigo();
                Global.Ph.Nitph = CookieNitph();
                Global.Ph.Ciudadph = CookieCiudad();
                Global.Ph.Estadoph = CookieTipo();
                axios.get(Global.Ruta.Url + "admin/inithome/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from(Global.Ruta.Ipin).toString("base64")).then((res) => {
                    if (res.data.message === "") {
                        // if (parseInt(Rol) === 4) {
                        //   this.cantidadPeticiones(4);
                        // }
                        // if (this.state.Roles.includes(this.state.Rol)) {
                        //   this.cantidadPeticiones(1);

                        // }
                        if (parseInt(Rol) === 3 || parseInt(Rol) === 5) {
                            this.setState({
                                redirect_login: 1,
                            });
                        }

                        var interval = 1//setInterval(this.hora, 1000);

                        this.setState({
                            CantInmuebles: parseInt(res.data.CantInmuebles),
                            Reservas_Pend: parseInt(res.data.Reservas_Pend),
                            CantPreRegistro: parseInt(res.data.cantPreRegistro),
                            TareasPendientes: parseInt(res.data.TareasPendientes),
                            redirect_login: 1,
                            interval: interval
                        });

                    }

                    var user = parseInt(Rol) + ";" + CookieUserId() + ";" + CookieNombre() + ";" + CookiePhCodigo() + ";" + CookieNombrePH();

                    Global.GlobalUser.UserLog = user;

                }).catch((err) => {
                    cookies.remove("Sesion", { path: "/" });
                    cookies.remove("UserId", { path: "/" });
                    cookies.remove("Nombre", { path: "/" });
                    this.setState({
                        redirect_login: 2,
                    });
                });
            } else {
                cookies.remove("Sesion", { path: "/" });
                cookies.remove("UserId", { path: "/" });
                cookies.remove("Nombre", { path: "/" });
                this.setState({
                    redirect_login: 2,
                });
            }
        } else {
            this.setState({
                redirect_login: 2,
            });
        }
        if (parseInt(Rol) === 0) {
            cookies.remove("Sesion", { path: "/" });
            cookies.remove("UserId", { path: "/" });
            cookies.remove("Nombre", { path: "/" });
            this.setState({
                redirect_login: 2,
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    hora = () => {
        const fechahora = new Date();
        const hora = fechahora.getHours();
        const minuto = fechahora.getMinutes();
        const segundo = fechahora.getSeconds();
        this.setState({ Hora: `${this.corregirTiempo(hora)}:${this.corregirTiempo(minuto)}:${this.corregirTiempo(segundo)}` })
    }

    corregirTiempo = (value) => {
        if (value < 10) {
            return `0${value}`
        }
        else {
            return value
        }
    }

    Sidebar = (caso) => {
        switch (parseInt(caso)) {

            case 9:
                this.setState({ modalQr: !this.state.modalQr })
                break;
            case 10:
                this.setState({ modalMsN: !this.state.modalQr })
                break;
            default:
                break;
        }
    }

    AgregarPaquete = (e) => {
        this.openModal(e, 1)
    }

    AgregarVisitante = (e) => {
        this.openModal(e, 3)
    }

    // CERRAR MODALES DE CREAR PAQUETE Y VISITANTE
    openModal = (e, i) => {
        e.preventDefault();
        let temp = [...this.state.portPackBtns];
        let index = this.IndexCambio(i, temp);
        temp[index].mod = true;
        this.setState({ portPackBtns: temp });
    };

    closeModal = (i) => {
        let temp = [...this.state.portPackBtns];
        let index = this.IndexCambio(i, temp);
        temp[index].mod = false;
        this.setState({ portPackBtns: temp });
    };

    IndexCambio = (Id, temRows) => {
        // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
        const findIndexArray = (element) => parseInt(element.id) === parseInt(Id);

        return temRows.findIndex(findIndexArray);
    };

    MensajeEnviado = (cambio, caso) => {
        this.setState({ EnvioMensaje: parseInt(this.state.EnvioMensaje) + 1 })

        if (parseInt(cambio) === 0) {
            this.setState({ modalMsN: false })
        }

        if (parseInt(caso) === 2) {
            this.setState({ CambioNota: parseInt(this.state.CambioNota) + 1 })
        }
    }

    render() {
        if (this.state.redirect_login === 1) {
            return (
                <div className="container-fluid" style={{ minWidth: "100vw", maxWidth: "100vw", minHeight: "100vh", maxHeight: "100vh" }}>
                    <div className="h-100 row bg-light"
                        style={{ maxHeight: 'calc(100% - 86px)', marginTop: "74px", marginBottom: "12px", marginLeft: "0px", marginRight: "0px", }}>
                        <div className="bg-light col-auto h-100 ">
                            <div className="h-100  align-items-top justify-content-center " >
                                <Psidebar className="w-100" Sidebar={(id) => this.Sidebar(id)} MensajePorteria={this.state.EnvioMensaje} />
                            </div>
                        </div>

                        <div className="col h-100 row row-cols-md-4 flex-md-row justify-content-start overflow-x-hidden custom-scroll">
                            <div className="w-100 d-flex flex-column position-relative">
                                <div className="col h-100 row  flex-md-row justify-content-start">
                                    <Card sx={this.state.windowWidth >= 885 ? (
                                        this.state.windowWidth >= 1400 ? (
                                            { minWidth: "calc(25% - 16px)", maxWidth: "calc(25% - 16px)", minHeight: "45vh", maxHeight: "45vh" }
                                        ) : ({ minWidth: "288px", maxWidth: "288px", minHeight: "40vh", maxHeight: "40vh" })
                                    ) : ({ minWidth: "95%", maxWidth: "95%", minHeight: "50vh", maxHeight: "50vh" })}
                                        className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                        <NavLink key={"0"} to={`/notas`}>
                                            <div className="text-center my-2" style={{ color: "black" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                    Minutas
                                                </span>
                                            </div>
                                        </NavLink>
                                        <div style={{ height: "89%" }}>
                                            <AgendaMain caso={1} CambioNota={this.state.CambioNota} />
                                        </div>
                                    </Card>
                                    <Card sx={this.state.windowWidth >= 885 ? (
                                        this.state.windowWidth >= 1400 ? (
                                            { minWidth: "calc(25% - 16px)", maxWidth: "calc(25% - 16px)", minHeight: "45vh", maxHeight: "45vh" }
                                        ) : ({ minWidth: "288px", maxWidth: "288px", minHeight: "40vh", maxHeight: "40vh" })
                                    ) : ({ minWidth: "95%", maxWidth: "95%", minHeight: "50vh", maxHeight: "50vh" })}
                                        className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                        <NavLink key={"0"} to={`/autorizaciones`}>
                                            <div className="text-center my-2" style={{ color: "black" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                    Domicilios
                                                </span>
                                            </div>
                                        </NavLink>
                                        <DspachPendDeliv caso={1} />
                                    </Card>
                                    <Card sx={this.state.windowWidth >= 885 ? (
                                        this.state.windowWidth >= 1400 ? (
                                            { minWidth: "calc(25% - 16px)", maxWidth: "calc(25% - 16px)", minHeight: "45vh", maxHeight: "45vh" }
                                        ) : ({ minWidth: "288px", maxWidth: "288px", minHeight: "40vh", maxHeight: "40vh" })
                                    ) : ({ minWidth: "95%", maxWidth: "95%", minHeight: "50vh", maxHeight: "50vh" })}
                                        className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                        <NavLink key={"0"} to={`/autorizaciones`}>
                                            <div className="text-center my-2" style={{ color: "black" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                    Visitantes
                                                </span>
                                            </div>
                                        </NavLink>
                                        <TableVisitAnunci caso={1} agregarVisitante={this.AgregarVisitante} />
                                        {/* <CardCopyLink openOverMod={() => this.setState({ modalQr: true })} closeOverMod={() => this.setState({ modalQr: false })} /> */}
                                    </Card>
                                    <Card sx={this.state.windowWidth >= 885 ? (
                                        this.state.windowWidth >= 1400 ? (
                                            { minWidth: "calc(25% - 16px)", maxWidth: "calc(25% - 16px)", minHeight: "45vh", maxHeight: "45vh" }
                                        ) : ({ minWidth: "288px", maxWidth: "288px", minHeight: "40vh", maxHeight: "40vh" })
                                    ) : ({ minWidth: "95%", maxWidth: "95%", minHeight: "50vh", maxHeight: "50vh" })}
                                        className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                        <NavLink key={"0"} to={`/autorizaciones`}>
                                            <div className="text-center my-2" style={{ color: "black" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                    {`Paquetes (${this.state.paquetesPendientes})`}
                                                </span>
                                            </div>
                                        </NavLink>
                                        <DspachPendPaqu caso={1} agregarPaquete={this.AgregarPaquete} onDataUpdate={this.handleDataUpdate} />
                                    </Card>
                                </div>
                            </div>

                            <div className="w-100 d-flex flex-column position-relative" >
                                <div className="col h-100 row row-cols-md-4 flex-md-row justify-content-start" >
                                    <Card sx={this.state.windowWidth >= 885 ? (
                                        this.state.windowWidth >= 1400 ? (
                                            { minWidth: "calc(50% - 16px)", maxWidth: "calc(50% - 16px)", minHeight: "42vh", maxHeight: "42vh" }
                                        ) : ({ minWidth: "594px", maxWidth: "594px", minHeight: "40vh", maxHeight: "40vh" })
                                    ) : ({ minWidth: "95%", maxWidth: "95%", minHeight: "50vh", maxHeight: "50vh" })}
                                        className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                        <NavLink key={"0"} to={`/placas`}>
                                            <div className="text-center my-2" style={{ color: "black" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                Registro de Placas
                                                </span>
                                            </div>
                                        </NavLink>
                                        <DataTablePlacas caso={1} />
                                    </Card>
                                    <Card sx={this.state.windowWidth >= 885 ? (
                                        this.state.windowWidth >= 1400 ? (
                                            { minWidth: "calc(50% - 16px)", maxWidth: "calc(50% - 16px)", minHeight: "42vh", maxHeight: "42vh" }
                                        ) : ({ minWidth: "594px", maxWidth: "594px", minHeight: "40vh", maxHeight: "40vh" })
                                    ) : ({ minWidth: "95%", maxWidth: "95%", minHeight: "50vh", maxHeight: "50vh" })}
                                        className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                        <NavLink key={"0"} to={`/ReservaZonas`}>
                                            <div className="text-center my-2" style={{ color: "black" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                    {` Reservas ${moment(new Date()).format("DD MMM YYYY")}`}
                                                </span>
                                            </div>
                                        </NavLink>
                                        <ContainerReservas caso={1} />
                                    </Card>

                                </div>
                            </div>
                        </div>
                    </div >


                    <Modal show={this.state.modalQr} centered backdrop='static' size="xl" contentClassName="bg-transparent border-0">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <CardCopyLink openOverMod={() => this.setState({ modalQr: true })} closeOverMod={() => this.setState({ modalQr: false })} />
                            <Tooltip title='Cerrar' placement="right" className="fs-4">
                                <button className="btn btn-danger rounded-circle d-flex justify-content-center align-items-center" onClick={() => this.setState({ modalQr: false })}>
                                    <i className="fa-solid fa-times fs-1" />
                                </button>
                            </Tooltip>
                        </div>
                    </Modal>


                    <Modal show={this.state.modalMsN} centered backdrop='static' size="sm" contentClassName="bg-transparent border-0">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <PorteriaAdmin caso={1} CloseModal={() => this.setState({ modalMsN: false })} MensajeEnviado={(cambio, caso) => this.MensajeEnviado(cambio, caso)}></PorteriaAdmin>
                        </div>
                    </Modal>



                    {/* MODALES PARA AGREGAR PAQUETES Y VISITANTES */}
                    {this.state.portPackBtns.map((modal, index) => (
                        <Modales key={index} modal={modal} close={() => this.closeModal(modal.id)} />

                    ))}
                </div >
            )
        }

        if (this.state.redirect_login === 2) {
            return <Redirect to={Global.Ruta.Raiz + "/"} />;
        }

        return (
            <div style={{ minHeight: "calc(100% - 86px)" }}>
                <Loader princial={true} uno={"Inicio"} dos={"Datos"} tres={"Imagenes"} />
            </div>
        );
    }
}

export default Home;