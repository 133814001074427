import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import SwitchBodyModal from "./SwitchBodyModal";
import Global from "../../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import PpHourglass from "../../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from 'simple-react-validator'

export default class ModalCreatePDV extends Component {

  state = {
    infoMod: [
      {
        id: 1,
        dir: '',
        susc: '',
        descr: "",
        otherN: ""
      },
      {
        id: 2,
        dir: '',
        susc: '',
        descr: "",
        otherN: "",
      },
      {
        id: 3,
        dir: "direccion anonima",
        susc: "",
        descr: "Visitante",
        otherN: "",
      },
    ],
    mod: '',
    modal: false,
  };

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      locale: 'es',
      messages: {
        required: 'Requerido',
      }
    });
  }

  setInfoModal = (i, prop, data) => {
    let temp = [...this.state.infoMod];
    let index = this.IndexCambio(i, temp);
    temp[index][prop] = data;
    this.setState({ infoMod: temp, mod: i });

  };

  sendInfo = () => {
    let temp = [...this.state.infoMod];
    let index = this.IndexCambio(this.state.mod, temp);
    var temp1 = temp[index]


    if (parseInt(this.state.mod) === 1) {
      if (this.validator.allValid()) {
        if (parseInt(temp1.susc) === 0 && temp1.otherN === "") {
          swal({
            title: "Atencion",
            text: "El nombre es requerido.",
            icon: "error",
            button: "Aceptar",
          })
          return
        }
        this.setState({ modal: true });
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          id_susc: Buffer.from("" + temp1.susc).toString("base64"),
          id_inmueble: Buffer.from("" + temp1.dir).toString("base64"),
          id_portero: Buffer.from(Global.GlobalUser.userid).toString("base64"),
          detalle: Buffer.from("" + temp1.descr.toUpperCase()).toString("base64"),
          nombre_paq: Buffer.from("" + temp1.otherN.toUpperCase()).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}porteria/creapaquete`, body).then((res) => {
          if (res.data.message === "") {
            this.setState({ modal: false });
            swal({
              title: "Atencion",
              text: "EL Paquete Fue Creado",
              icon: "success",
              button: "Aceptar",
              timer: 800
            }).then(() => this.props.cerrar())
          } else {
            this.setState({ modal: false });
            swal({
              title: "Atencion",
              text: `${res.data.message}`,
              icon: "success",
              button: "Aceptar",
              timer: 800
            })
          }
        }).catch((err) => {
          this.setState({ modal: false });
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({
              redirect_home: true,
            });
          });
        });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }

  }

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  CerrarModal = () => {
    this.props.cerrar()
  }

  render() {
    let { info } = this.props;

    return (
      <div className="g-r-prf4 h-100">
        <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
          <PpHourglass />
        </Modal>
        <Modal.Header closeButton><span><p className="font-weight-bold" style={{ marginBottom: 0 }}>{info.nombre}</p></span></Modal.Header>
        <Modal.Body className='h-100 mui-fixer'>
          <SwitchBodyModal
            key={info.id}
            id={info.id}
            labels={info.nombre}
            info={this.state.infoMod}
            selectDir={(v) => this.setInfoModal(info.id, "dir", v)}
            selectSusc={(v) => this.setInfoModal(info.id, "susc", v)}
            details={(v) => this.setInfoModal(info.id, "descr", v)}
            otro={(v) => this.setInfoModal(info.id, "otherN", v)}
            textdir={<p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('dir', this.state.infoMod[this.IndexCambio(info.id, this.state.infoMod)].dir, 'required', { className: 'text-danger' })}</p>}
            textsus={<p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('susc', this.state.infoMod[this.IndexCambio(info.id, this.state.infoMod)].susc, 'required', { className: 'text-danger' })}</p>}
            textdescr={<p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('descr', this.state.infoMod[this.IndexCambio(info.id, this.state.infoMod)].descr, 'required', { className: 'text-danger' })}</p>}
            CerrarModal={this.CerrarModal}
          />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {info.id === 1 ? (<button className="btn btn-primary" id="snap" onClick={() => this.sendInfo()} >  Enviar </button>) : ("")}
            </div>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
