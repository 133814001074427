import React from 'react'
import { useTranslation} from 'react-i18next'

export default function Traductor({dir}) {

    const [t] = useTranslation("global")

  return (
    <div>{t(dir)}</div>
  )
}

export function BotonTraduct({ nombreLang, lang = 'es'}){

    // eslint-disable-next-line
    const [t, i18n] = useTranslation("global")

    return(
        <div onClick={()=> i18n.changeLanguage(lang)}>{nombreLang}</div>
    )

}