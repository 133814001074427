import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import Global from "../../../Global";
import axios from "axios";
import { Dropdown, DropdownButton, Modal, Navbar } from "react-bootstrap";
import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import swal from "sweetalert";
import { CambiarUserPorteria } from "../../Helpers";
import MyPhModal from "./MyPhModal";
import CrearBD from "./compos/CrearBD"
import CrearPDFBD from "./compos/CrearPDFBD"
import BaseDatos from "./compos/BaseDatos"


// import logo from "../../../assets/images/logo.png";

const cookies = new Cookie();

class HeaderA extends Component {
   
  state = {
    NombrePH: "",
    NombreUser: "",
    CodPH: "",
    redirect_login: 0,
    confi: 0,
    data: false,
    slc_perf: "",

    porteros: [],
    Nombre: "",
    Direccion: "",
    Login: "",

    Ing: [2, 25, 24],
    rol: 2,
    // modal
    modal: false,
    modal1: false,
    modalbd: false,
    modalpdf: false,
    modaldatabase: false
  };

  componentWillMount() {
    this.setState({
      NombrePH: Global.Ph.Nombre,
      NombreUser: Global.GlobalUser.Nombre,
      CodPH: Global.Ph.Codigo,
      NombreUserLimit: Global.GlobalUser.Nombre.substr(0, 18),
      rol: Global.GlobalUser.rol
    });

    var body = {};

    if (parseInt(Global.GlobalUser.rol) === 4) {
      body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        porteroid: Buffer.from(Global.GlobalUser.userid).toString("base64"),
      };

      axios.post(`${Global.Ruta.Url}porteria/listportero`, body).then((res) => {
          if (res.data.message === "") {
            // LLAMO A MI PROCEDIMIENTO AUTOMATICO CADA MINUTO
            var idinterval = setInterval(this.Contador, 1000);

            this.setState({
              intervalid: idinterval,
              porteros: res.data.Usuarios,
            });
          }
          this.setState({
            data: true,
          });
        }).catch((err) => {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({
              redirect_home: true,
            });
          });
        });
    } else {
      this.setState({
        data: true,
      });
    }
  }

  CambiarUsuario = (id, nombre, login) => {
    swal({
      title: "ATENCIÓN!!",
      text: `QUIERE CAMBIAR ALUSUARIO ${nombre}`,
      content: {
        element: "input",
        attributes: {
          placeholder: "Ingrese su Contraseña",
          type: "password",
        },
      },
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.CerrarSesionTem();
        CambiarUserPorteria(Global.Ph.Codigo, login, result);
        setTimeout(() => {
          this.setState({ slc_perf: id, redirect_login: 1 });
        }, 600);
      }
    });
  };

  CerrarSesion = (e) => {
    cookies.remove("Sesion", { path: "/" });
    cookies.remove("UserId", { path: "/" });
    cookies.remove("Nombre", { path: "/" });
    cookies.remove("Rol", { path: "/" });
    cookies.remove("PhCodigo", { path: "/" });
    cookies.remove("Conjunto", { path: "/" });
    cookies.remove("Nitph", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    cookies.remove("Tipo", { path: "/" });
    cookies.remove("Rondero", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    this.setState({ redirect_login: 1 });
    Global.NotifHome.View = false;
    Global.NotifHome.FirtsEntry = false;
    Global.NotifHome.Pendientes = [];
  };

  CerrarSesionTem = (e) => {
    cookies.remove("Sesion", { path: "/" });
    cookies.remove("UserId", { path: "/" });
    cookies.remove("Nombre", { path: "/" });
    cookies.remove("Rol", { path: "/" });
    cookies.remove("PhCodigo", { path: "/" });
    cookies.remove("Conjunto", { path: "/" });
    cookies.remove("Nitph", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    cookies.remove("Tipo", { path: "/" });
    cookies.remove("Rondero", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    Global.NotifHome.View = false;
    Global.NotifHome.FirtsEntry = false;
    Global.NotifHome.Pendientes = [];
  };

  ConfigurarPerfil = () => {
    axios
      .get(
        Global.Ruta.Url +
        "admin/datauser/" +
        Buffer.from(Global.Ruta.Ipin).toString("base64") +
        "/" +
        Buffer.from("" + Global.GlobalUser.userid).toString("base64")
      )
      .then((res) => {
        if (res.data.message === "") {
          for (let user of res.data.Usuarios) {
            Global.Form.tipo = 3;
            Global.Form.id = Global.GlobalUser.userid;
            Global.Form.nombre = user.nombres;
            Global.Form.login = user.login;
            Global.Form.rol = user.rol;
            Global.Form.estado = user.estado;
          }
          this.setState({ confi: 1 });
        } else {
          this.setState({ redirect_login: 1 });
        }
      })
      .catch((err) => {
        this.setState({ redirect_login: 1 });
      });
  };

  verificarBD() {
    swal({
      title: "ATENCIÓN!",
      text: `Espere un momento por favor`,
      icon: "warning",
      timer: 3000,
      button: false,
      closeOnClickOutside: false,
    });
    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}adminasamblea/validarbd`, body)
      .then((res) => {
        var ConEspacios = "";
        var Mayusculas = "";
        var ClaveNoCoincide = "";
        var MaloNombre = "";
        if (res.data.message === "") {
          res.data.ConEspacios.forEach((login) => {
            ConEspacios = ConEspacios + login + " - ";
          });
          res.data.ConMayusculas.forEach((login) => {
            Mayusculas = Mayusculas + login + " - ";
          });
          res.data.ClaveNoCoincide.forEach((login) => {
            ClaveNoCoincide = ClaveNoCoincide + login + " - ";
          });
          res.data.MaloNombre.forEach((login) => {
            MaloNombre = MaloNombre + login + " - ";
          });
          swal({
            title: "ATENCIÓN!",
            text: `
                Total Suscriptores: ${res.data.Suscriptores}\n
                Total Inmuebles: ${res.data.Inmuebles}\n
                Total Coeficiente: ${res.data.TotalCoeficiente}\n
                Usuarios con espacios: ${ConEspacios}\n
                Usuarios con Mayusculas: ${Mayusculas}\n
                Mal Nombre: ${MaloNombre}\n
                Usuarios con Claves Mala: ${ClaveNoCoincide}`,
            icon: "success",
            button: "Aceptar",
          });
        } else {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo.",
            icon: "error",
            button: "Aceptar",
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
          icon: "error",
          button: "Aceptar",
        });
      });
  }

  ActualizarLosCoe() {
    swal({
      title: "ATENCIÓN!",
      text: `Espere un momento por favor`,
      icon: "warning",
      timer: 3000,
      button: false,
      closeOnClickOutside: false,
    });
    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}adminasamblea/organizarcoe`, body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text: `Coeficiente acualizado.`,
            icon: "success",
            button: "Aceptar",
          });
        } else {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo.",
            icon: "error",
            button: "Aceptar",
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
          icon: "error",
          button: "Aceptar",
        });
      });
  }

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value,
    }));
  };

  NewSuscAso = () => {
    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      nombre: Buffer.from(this.state.Nombre).toString("base64"),
      direccion: Buffer.from(this.state.Direccion).toString("base64"),
      login: Buffer.from(this.state.Login).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}adminasamblea/newsusc`, body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text: `El usuario es: ${this.state.Login} \n
                  La clave es: 103020`,
            icon: "success",
            button: "Aceptar",
            closeOnClickOutside: false,
          }).then((res) => {
            this.setState({ modal: false });
          });
        } else {
          swal({
            title: "ERROR!",
            text: res.data.message,
            icon: "error",
            button: "Aceptar",
          }).then((res) => {
            this.setState({ modal: false });
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then((res) => {
          this.setState({ modal: false });
        });
      });
  };

  render() {
    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.confi === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/formusuario"} />;
    }

    // let rol = this.state.rol

    return (
      <React.Fragment>
        {/* modals */}
        <Modal
          show={this.state.modal}
          onHide={() => this.setState({ modal: false })}
          centered
        >
          <Modal.Header closeButton>Agregando Usuario</Modal.Header>
          <Modal.Body>
            <div className="w-100">
              <TextField
                className="my-2"
                variant="outlined"
                label="Nombre"
                fullWidth
                onChange={this.handleChange("Nombre")}
              />
              <TextField
                className="my-2"
                variant="outlined"
                label="Dirección"
                fullWidth
                onChange={this.handleChange("Direccion")}
              />
              <TextField
                className="my-2"
                variant="outlined"
                label="Login"
                fullWidth
                onChange={this.handleChange("Login")}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center d-flex">
            <button className="btn btn-primary" onClick={this.NewSuscAso}>
              Agregar
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modal1}
          onHide={() => this.setState({ modal1: false })}
          centered
          backdrop="static"
          fullscreen
        >
          <MyPhModal onHide={() => this.setState({ modal1: false })} />
        </Modal>


        <Modal
          show={this.state.modalbd}
          onHide={() => this.setState({ modalbd: false })}
          centered
          backdrop="static"
          size="lg"
        >
          <CrearBD onHide={() => this.setState({ modalbd: false })} />
        </Modal>


        <Modal
          show={this.state.modalpdf}
          onHide={() => this.setState({ modalpdf: false })}
          centered
          backdrop="static"
          size="md"
        >
          <CrearPDFBD onHide={() => this.setState({ modalpdf: false })} />
        </Modal>

        <Modal
          show={this.state.modaldatabase}
          onHide={() => this.setState({ modaldatabase: false })}
          centered
          backdrop="static"
          fullscreen={true}
        >
          <BaseDatos onHide={() => this.setState({ modaldatabase: false })} />
        </Modal>

        {/* Componentes */}
        <Navbar
          className="bg-white bg-opacity-25 fixed-top nav-header px-3 align-items-center justify-content-between shadow"
          expand="lg"
        >
          <div className="navbar-brand">
            <NavLink
              className="navbar-brand"
              activeClassName="none"
              exact
              to={Global.Ruta.Raiz + "/home-admin"}
            >
              <span className="goph_logo light fw-bold fs-4">
                GoPH
              </span>
            </NavLink>
          </div>

          <div className="d-flex align-items-center gap-3">
            <Navbar.Collapse className="fw-normal justify-content-center">
              <span className="text-dark fw-bolder">
                {this.state.NombreUser}
                {/* {this.state.NombrePH + " - " + this.state.CodPH} */}
              </span>
            </Navbar.Collapse>

            {[1, 2].includes(parseInt(Global.GlobalUser.rol)) ?
              (<Dropdown autoClose={false}>
                <DropdownButton
                  align="end"
                  id="dropdown-basic-button"
                  className="btn-admin"
                  title={
                    <i className="fa-solid fa-bars " />
                  }
                >
                  <Dropdown.Header>
                    <i
                      className="far fa-id-badge fa-sm"
                      style={{ margin: "0 3px 0 5px" }}
                    ></i>{" "}
                    {this.state.NombreUser}
                  </Dropdown.Header>
                  <Dropdown.Header>
                    <i
                      className="fas fa-home fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    {this.state.NombrePH}
                    <span className="d-block d-lg-none">{this.state.CodPH}</span>
                  </Dropdown.Header>
                  <Dropdown.Item onClick={this.ConfigurarPerfil}>
                    <i
                      className="fas fa-cog fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    CONFIGURACIÓN
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setState({ modal1: true })}>
                    <i
                      className="fas fa-book fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    Mi PH
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  {Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid)) ?
                    (
                      <div>
                        <Dropdown.Item onClick={this.verificarBD}>
                          <i className="fas fa-database me-1" /> Valida BD
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.ActualizarLosCoe}>
                          <i className="fas fa-percent me-1" />
                          Actualizar Coef Asistencia
                        </Dropdown.Item>
                        {this.state.Ing.includes(
                          parseInt(Global.GlobalUser.userid)
                        ) ? (
                          <Dropdown.Item
                            onClick={() => this.setState({ modal: true })}
                          >
                            <i className="fas fa-user me-1" />
                            Agregar Suscriptor
                          </Dropdown.Item>
                        ) : (
                          <div></div>
                        )}
                        <div className="dropdown-divider"></div>
                      </div>
                    ) :
                    null
                  }

                  {Global.Plataforma.Partner.includes(parseInt(Global.Ph.Codigo)) ? (
                    <div>
                      <Dropdown.Item onClick={() => this.setState({ modalbd: true })}>
                        <i className="fa-solid fa-server me-2"></i>Crear Base de Datos </Dropdown.Item>
                      <Dropdown.Item onClick={() => this.setState({ modaldatabase: true })}>
                        <i className="fa-solid fa-database me-2"></i>Mis Bases de Datos </Dropdown.Item>          
                      <div className="dropdown-divider"></div>
                    </div>
                  ) : (null)}
                  <Dropdown.Item onClick={this.CerrarSesion}>
                    <i className="fas fa-power-off fa-sm icon-btn-user"></i>
                    <span className="rojo"> Cerrar Sesión</span>
                  </Dropdown.Item>
                </DropdownButton>
              </Dropdown>
              ) :
              (
                <Dropdown autoClose={false}>
                  <DropdownButton
                    align="end"
                    id="dropdown-basic-button"
                    className="btn-admin"
                    title={
                      <i className="fa-solid fa-bars " />
                    }
                  >
                    <Dropdown.Header>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          variant="standard"
                          htmlFor="uncontrolled-native"
                          id="select_perfil"
                        >
                          <i
                            className="far fa-id-badge fa-sm"
                            style={{ margin: "0 3px 0 5px" }}
                          ></i>{" "}
                          {this.state.NombreUser}
                        </InputLabel>
                        <Select
                          labelId="select_perfil"
                          label={
                            <div>
                              <i
                                className="far fa-id-badge fa-sm"
                                style={{ margin: "0 3px 0 5px" }}
                              ></i>{" "}
                              {this.state.NombreUser}
                            </div>
                          }
                          value={this.state.slc_perf}
                          inputProps={{
                            name: { ...this.state.NombreUser },
                            id: "uncontrolled-native",
                          }}
                        >
                          {this.state.porteros.map((portero, indice) => (
                            <MenuItem
                              value={portero.usuarioid}
                              onClick={() =>
                                this.CambiarUsuario(
                                  portero.usuarioid,
                                  portero.nombres.toUpperCase(),
                                  portero.login
                                )
                              }
                              key={indice}
                            >
                              {portero.nombres.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Dropdown.Header>
                    <Dropdown.Header>
                      <i
                        className="fas fa-home fa-sm"
                        style={{ margin: "0 2px 0 2px" }}
                      ></i>{" "}
                      {this.state.NombrePH}
                    </Dropdown.Header>
                    <Dropdown.Header>
                      <i
                        className="fas fa-cog fa-sm"
                        style={{ margin: "0 2px 0 2px" }}
                      ></i>{" "}
                      CONFIGURACIÓN
                    </Dropdown.Header>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item onClick={this.CerrarSesion}>
                      <i className="fas fa-power-off fa-sm icon-btn-user"></i>
                      <span className="rojo"> Cerrar Sesión</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </Dropdown>
              )
            }

          </div>
        </Navbar>
      </React.Fragment>
    )
  }
}

export default HeaderA;
