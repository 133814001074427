import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

export default class CampoDeTexto extends Component {
  render() {

    let tipoI = this.props.icono === 'whatsapp' ? 'brands':'solid' 

    return (
        <Form.Floating>
            <Form.Control className='shadow-sm' id={this.props.id} type={this.props.tipo} placeholder={'ph'}
            value={this.props.valor} onChange={(event)=>this.props.writhing(this.props.id,event.target.value)}
            />
            <label htmlFor={this.props.id}>
              <i className={`fa-${tipoI} fa-${this.props.icono} me-2`}/>
              {this.props.label}
            </label>
        </Form.Floating>
    )
  }
}
