import { TextField } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import swal from "sweetalert";

export default class HorasAtencion extends Component {

  state = {
    startDate: this.props.desdeDate,
    endDate: this.props.hastaDate,
    dia: this.props.Dias,

  }

  agregar = () => {



    if (Object.prototype.toString.call(this.state.startDate) === "[object Date]" &&
      Object.prototype.toString.call(this.state.endDate) === "[object Date]") {
      // es una fecha
      if (isNaN(this.state.startDate.getTime()) && isNaN(this.state.endDate.getTime())) { // d.getTime() or d.valueOf() will also work
        swal({
          title: "ALERTA!",
          text: "Debe ingresar las fechas validas.",
          icon: "warning",
          button: "Aceptar",
        })
        return
      }
    }


    this.props.accion({
      dias: this.state.dia,
      desde: this.state.startDate.toLocaleTimeString(),
      desdeDate: this.state.startDate,
      hasta: this.state.endDate.toLocaleTimeString(),
      hastaDate: this.state.endDate
    });

    this.setState({
      startDate: null,
      endDate: null,
      dia: '',
    })

  }

  render() {


    let buttonState = this.props.eliminar ? 'btn btn-danger' : 'btn btn-primary';
    let iconState = this.props.eliminar ? 'minus' : 'plus';

    return (
      <div className='row row-cols-4 justify-content-between'>
        <div className="col-12">
          <h6 className={this.props.titulo}>Horario de Atención</h6>
        </div>
        <div className="col">
          <Form.Floating>
            <Form.Select className='' value={this.state.dia} onChange={(e) => this.setState({ dia: e.target.value })} disabled={this.props.eliminar}>
              <option value={'Lunes a Viernes'}>Lunes a Viernes</option>
              <option value={'Lunes a Sábados'}>Lunes a Sábados</option>
              <option value={'Lunes'}>Lunes</option>
              <option value={'Martes'}>Martes</option>
              <option value={'Miércoles'}>Miércoles</option>
              <option value={'Jueves'}>Jueves</option>
              <option value={'Viernes'}>Viernes</option>
              <option value={'Sábado'}>Sábado</option>
              <option value={'Domingos'}>Domingos</option>
            </Form.Select>
            <label>Dias</label>
          </Form.Floating>
        </div>
        <div className='col'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              className=''
              disabled={this.props.eliminar}
              label="Desde"
              value={this.state.startDate}
              onChange={(date) => { this.setState({ startDate: date }) }}
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
              views={['hours', 'minutes']} minutesStep={5}
            />
          </LocalizationProvider>
        </div>
        <div className='col'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              className=''
              disabled={this.props.eliminar}
              label="Hasta"
              value={this.state.endDate}
              onChange={(date) => { this.setState({ endDate: date }) }}
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
              views={['hours', 'minutes']} minutesStep={5}
            />
          </LocalizationProvider>
        </div>
        <div className="col-auto d-flex align-items-center">
          <button className={`${buttonState} rounded-circle`}
            onClick={() => (this.props.eliminar) ?
              (this.props.accion(this.props.Indice)) :
              (this.agregar())}
            disabled={(this.state.startDate !== null && this.state.endDate !== null) ? (false) : (true)}>
            <i className={`fas fa-${iconState}`} />
          </button>
        </div>
      </div>
    )
  }
}
