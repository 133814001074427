import React, { Component } from "react";
import '../../../../assets/css/dashboard.css';
import { NavLink } from "react-router-dom";
import Global from "../../../../Global";

export default class SidebarHome extends Component {
    render() {
        const data = this.props
        return (

            <div className="col-lg-3 col-md-3 col-2 d-none d-md-block pr-5">
                <div className="sidebar-nh">
                    <p className="pt-5">{Global.Ph.Nombre}</p>
                    {data.MenuHome.map((datos, item) => {
                        return (
                            <NavLink exact to={datos.url} key={item}><i className={`${datos.icono} text-${datos.color} mr-2`} />{datos.titulo}</NavLink>
                        )
                    })
                    }
                </div>
            </div>
        )
    }
}

SidebarHome.defaultProps = {
    MenuHome: [],
};