import React, { Component } from 'react'

import NavbarInformes from './NavbarInformes'
import TablaInfoAusentes from './TablasInformes/TablaInfoAusentes'

export default class InformesAusentes extends Component {

    state = {
        titulo: "",
    }

    componentWillMount() {
        this.setState({
            titulo: `Informe AUSENTES`
        })
    }

    stateTotalAusentes = (total) => {
        this.setState({
            titulo: `Informe AUSENTES - Total de ausentes: ${total}`
        })
    }

    render() {
        return (
            <div className="g-r-prf3 bg-light vh-100">
                <NavbarInformes titulo={this.state.titulo} />
                <TablaInfoAusentes TotalAusentes={this.stateTotalAusentes}/>
                 
            </div>
        )
    }
}
