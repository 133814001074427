import React, {Component} from "react";

class PpQmora extends Component{

    state = {
        Hora: undefined,
        ExplanText: `El quorum en mora presentado corresponde a aquellos marcados en mora que se encuentran presentes, mas no a todos los marcados en mora, y por otro lado quitando estos queda el quorum habilitado para votar.`
    }

    componentWillMount(){
        var tem = new Date();
        this.setState({
            Hora:tem.toTimeString().substr(0,8)
        })
    }

    render(){

        return(
                <div className="row h-100 justify-content-center align-items-center px-0">
                    <div className="col-sm-12 col-lg-4 px-0">
                        <div className="card w-auto h-auto">
                            <div className="card-header d-flex justify-content-between">
                                <span>Quorum</span><p>{this.state.Hora}</p>
                            </div>
                            <div className="card-body px-4 pb-0">
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                    <th scope="col"> </th>
                                    <th scope="col">Asistentes</th>
                                    <th scope="col">Quorum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">Total</th>
                                    <td>{this.props.QMora.Presentes}</td>
                                    <td>{parseFloat(this.props.QMora.Coeficiente).toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">Mora</th>
                                    <td>{this.props.QMora.PresentesMora}</td>
                                    <td>{parseFloat(this.props.QMora.CoeficienteMora).toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">Habilitados</th>
                                    <td>{this.props.QMora.PresentesHabilitados}</td>
                                    <td>{parseFloat(this.props.QMora.CoeficienteHabilitado).toFixed(2)}%</td>
                                    </tr>
                                </tbody>
                                </table>
                                <div>
                                    <p className="font-weight-light font-italic mb-0" style={{ color: 'gray', fontSize: 12.5, textAlign: 'center', }}>{this.state.ExplanText}</p>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row justify-content-center">
                                    <button className="btn btn-primary col-5" onClick={this.props.onClose}>Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default PpQmora