import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import Global from '../../../Global';
import Loader from "../../principal/Loader";
import HourGlass from "./../../principal/HourGlass";
import HeadTitle from "../../principal/HeadTitle";
import CrearRonda from './modal/CrearRonda'
import CrearLugar from './modal/CrearLugar'
import CrearHorario from './modal/CrearHorario'
import { Card, Typography, Tooltip } from "@mui/material";
import swal from 'sweetalert';
import axios from "axios";


export default class RondaMain extends Component {
    state = {
        redirect_home: false,
        Data: false,
        ModalRonda: false,
        ModalLugar: false,
        ModalHorario: false,
        HourGlass: false,
        CasoRonda: {},
        CasoLugar: {},
        CasoHorario: {},
        Ronda: [],
        Lugar: [],
        Horarios: [],
    };

    componentDidMount() {
        this.RutaLugar()
    }

    RutaLugar = () => {
        this.setState({ Data: false })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}ronda/listrondas`, body).then(res => {
            if (res.data.message === "") {
                this.setState({
                    Ronda: res.data.Rondas,
                    Lugar: res.data.Lugares,
                    Horarios: res.data.Horarios,
                    Data: true
                })
            } else {
                swal({
                    title: "Atención",
                    text: `${res.data.message}`,
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    OpenRonda = (caso, index) => {

        if (parseInt(caso) === 0) {
            this.setState({ CasoRonda: { "id_ronda": "", "nombre": "", "estado": 2, "caso": 0 } }, () => { this.setState({ ModalRonda: true }) })
        } else {
            var temp = this.state.Ronda[index]
            this.setState({ CasoRonda: { "id_ronda": temp.id_ronda, "nombre": temp.nombre, "estado": temp.estado, "caso": 1 } }, () => { this.setState({ ModalRonda: true }) })
        }
    }

    AñadirRonda = (datos, caso) => {
        this.setState({ Data: false })
        var temp = this.state.Ronda
        var temp1 = this.state.Horarios

        if (parseInt(caso) === 0) {
            var data = { id_ronda: datos.id_ronda, nombre: datos.nombre, estado: datos.estado, Lugares: [] }
            temp.push(datos)
            temp1.push(data)
            this.setState({ Ronda: temp, Horarios: temp1 }, () => {
                this.setState({ ModalRonda: false, Data: true })
            })
        } else {
            var index = temp.findIndex(elemento => elemento.id_ronda === datos.id_ronda)
            temp[index].estado = datos.estado
            temp[index].nombre = datos.nombre
            temp1[index].estado = datos.estado
            temp1[index].nombre = datos.nombre

            temp1.forEach((horario) => {
                horario.Lugares = horario.Lugares.map((ronda) => ({
                    ...ronda,
                    estado: ronda.id_ronda === datos.id_ronda ? datos.estado : ronda.estado,
                }));
            });

            this.setState({ Ronda: temp, Horarios: temp1 }, () => {
                this.setState({ ModalRonda: false, Data: true })
            })
        }
    }

    OpenLugar = (caso, id_ronda_lugar) => {
        if (this.state.Ronda.length === 0) {
            swal({
                title: "",
                text: "Añade un momento para la Ronda",
                icon: "warning",
                button: "Aceptar"
            }).then(() => {
                this.OpenRonda(0, 0)
            })
        } else {
            if (parseInt(caso) === 0) {
                this.setState({ CasoLugar: { "id_ronda_lugar": "", "nombre": "", "estado": 2, "caso": 0 } }, () => { this.setState({ ModalLugar: true }) })
            } else {
                var temp = this.state.Lugar
                var index = temp.findIndex(elemento => elemento.id_ronda_lugar === id_ronda_lugar)
                this.setState({ CasoLugar: { "id_ronda_lugar": temp[index].id_ronda_lugar, "nombre": temp[index].nombre, "estado": temp[index].estado, "caso": 1 } }, () => { this.setState({ ModalLugar: true }) })
            }
        }
    }

    AñadirLugar = (datos, caso) => {
        this.setState({ Data: false })
        var temp = this.state.Lugar
        var temp1 = this.state.Horarios

        if (parseInt(caso) === 0) {
            temp.push(datos)
            this.setState({ Lugar: temp }, () => {
                this.setState({ ModalLugar: false, Data: true })
            })
        } else {
            var index = temp.findIndex(elemento => elemento.id_ronda_lugar === datos.id_ronda_lugar)
            temp[index].estado = datos.estado
            temp[index].nombre = datos.nombre
            temp1.forEach((horario) => {
                if (horario.estado === 1) {
                    horario.Lugares = horario.Lugares.map((lugar) => ({
                        ...lugar,
                        estado: lugar.id_ronda_lugar === datos.id_ronda_lugar ? datos.estado : lugar.estado,
                        nombre: lugar.id_ronda_lugar === datos.id_ronda_lugar ? datos.nombre : lugar.nombre
                    }));
                }
            });
            this.setState({ Lugar: temp, Horarios: temp1 }, () => {
                this.setState({ ModalLugar: false, Data: true })
            })
        }
    }

    OpenHorario = (index) => {
        var temp = this.state.Horarios[index]
        this.setState({ CasoHorario: temp }, () => {
            this.setState({ ModalHorario: true })
        })
    }

    DescargarQR = (data) => {
        this.setState({ HourGlass: true })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            id_ronda_lugar: Buffer.from("" + data.id_ronda_lugar).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}ronda/qrlugar`, body).then(res => {
            this.setState({ HourGlass: false })
            if (res.data.message === "") {
                const link = document.createElement('a');
                link.href = res.data.QR;
                link.download = `Código_QR_${data.nombre}.png`;
                link.click();
            } else {
                swal({
                    title: "Atención",
                    text: `${res.data.message}`,
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    render() {


        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
        }
        if (this.state.Data) {
            return (
                <div className="container-fluid">
                    <HourGlass show={this.state.HourGlass}></HourGlass>
                    {this.state.ModalRonda ? (
                        <CrearRonda show={this.state.ModalRonda} CerrarModal={() => this.setState({ ModalRonda: false })}
                            CasoRonda={this.state.CasoRonda} AñadirRonda={(datos, caso) => this.AñadirRonda(datos, caso)}
                        />) : ("")}

                    {this.state.ModalLugar ? (
                        <CrearLugar show={this.state.ModalLugar} CerrarModal={() => this.setState({ ModalLugar: false })}
                            CasoLugar={this.state.CasoLugar} AñadirLugar={(datos, caso) => this.AñadirLugar(datos, caso)}
                        />) : ("")}

                    {this.state.ModalHorario ? (
                        <CrearHorario show={this.state.ModalHorario} CerrarModal={() => this.setState({ ModalHorario: false })}
                            CasoHorario={this.state.CasoHorario} Lugar={this.state.Lugar} RutaLugar={() => this.RutaLugar()}
                        />) : ("")}

                    <div className="col-12 mt-2">
                        <HeadTitle classNameOwn="row align-items-center " titulo="Gestión de Rondero" subTitulo="" indicadores={[]}
                            actionButton={
                                <div className="buttons-container d-flex justify-content-end pe-0 overflow-y-hidden">
                                    <div className="col " >
                                        <Tooltip title="Añadir Momento de Ronda">
                                            <button className="btn btn-outline-light rounded-circle "
                                                onClick={() => this.OpenRonda(0, 0)}>
                                                <i className="far fa-calendar-check" style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                            </button>
                                        </Tooltip>

                                        {this.state.Ronda.length > 0 ? (
                                            <Tooltip title="Añadir lugar de la ronda">
                                                <button className="btn btn-outline-light mx-2 rounded-circle "
                                                    onClick={() => this.OpenLugar(0, 0)}>
                                                    <i className="fas fa-map-marked-alt" style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                                </button>
                                            </Tooltip>
                                        ) : ("")}
                                    </div>
                                </div>
                            } />

                        <div className="col mt-3 mx-3 overflow-y-visible">
                            <Card className="my-1">
                                <span className="mx-2"> Descarga el código QR </span>
                                {this.state.Lugar.map((lugar, index) => (
                                    <button key={index} className={`btn btn-${lugar.estado === 1 ? ('primary') : ('danger')} btn-sm mx-2 my-2`} disabled={lugar.estado === 2} onClick={() => this.DescargarQR(lugar)}> {lugar.nombre}</button>
                                ))}
                            </Card>
                        </div>

                        <div className="row row-cols-1 row-cols-md-4 mt-4 mx-2 mb-4 justify-content-start">
                            {this.state.Horarios.map((horario, index) => (
                                <Card key={index} sx={{ maxWidth: 300, minWidth: 300 }} className="border border-light-subtle my-2 mx-2 d-flex flex-column position-relative" style={{ cursor: 'pointer' }} onClick={() => this.OpenHorario(index)}>
                                    <div className='my-2 d-flex align-items-center'>
                                        <Typography sx={{ mb: 0.2 }} className={`text-justify mb-2 fw-bold fs-5 ${horario.estado === 1 ? ("text-secondary") : ("text-danger")}`} style={{ fontSize: '12px', cursor: 'pointer' }} onClick={(event) => { event.stopPropagation(); this.OpenRonda(1, index) }}>{horario.nombre}</Typography>
                                    </div>
                                    <div >
                                        {horario.Lugares.map((lugares, index1) => (
                                            <div key={index1} className="d-flex align-items-center">
                                                <Typography sx={{ mb: 0.2, mr: 1 }} className={`text-justify mb-2 ${lugares.estado === 1 ? ("text-secondary") : ("text-danger")}`} style={{ fontSize: '11px' }} onClick={(event) => { event.stopPropagation(); this.OpenLugar(1, lugares.id_ronda_lugar) }}>
                                                    {lugares.nombre}
                                                </Typography>
                                                <Typography sx={{ mb: 0.2, mr: 1 }} color="text.secondary" className="text-justify mb-2" style={{ fontSize: '11px' }}>-</Typography>
                                                <Typography sx={{ mb: 0.2, mr: 1 }} className={`text-justify mb-2 ${lugares.estado === 1 ? ("text-secondary") : ("text-danger")}`} style={{ fontSize: '11px' }}>
                                                    {lugares.hora}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ minHeight: this.props.caso === undefined ? "100vh" : "10%" }}>
                <Loader princial={true} uno={"Ronda"} dos={"Lugares"} tres={"QR"} />
            </div>
        );
    }
}