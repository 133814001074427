import React, { Component } from 'react';
import axios from "axios";
import Global from "../../../Global";

import MaterialTable from "material-table";
import { Modal } from 'react-bootstrap';

const columns = [
    {
        field: "id",
        title: "Id",
        hidden: true
    },
    {
        field: "idinmueble",
        title: "Id Inmueble",
        hidden: true
    },
    {
        field: "direccion",
        title: "Dirección",
    },
];



class TablaGruposMsn extends Component {

    state = {
        rows: [],
        count: 0,
        data: false,
        tituloTable: "",
        Grupos: [],
    }

    componentWillMount() {

        axios.get(Global.Ruta.Url + "mensajeria/notificagrupo/" + Buffer.from(Global.Ph.Codigo).toString("base64"))
            .then((res) => {
                if (res.data.message === "") {
                    var tem = [];
                    var RowGrupo = {};
                    for (var i = 0; i < res.data.Grupo.length; i++) {

                        RowGrupo = {
                            id: res.data.Grupo[i].id_grupo,
                            suscriptorid: 0,
                            direccion: res.data.Grupo[i].nombre_grupo,
                        };
                        tem.push(RowGrupo);
                    }
                    this.setState({ rows: tem, data: true, Grupos: res.data.Grupo });
                } else {
                    this.props.cerrar();
                }
            })
            .catch((err) => {
                this.props.cerrar();
            });
    }

    Guardar = (data) => {
        this.setState({ data: false })

        //var TemInmuebleId = [];
        var TemSuscId = [];
        var Nombres = [];
        for (var SelectGrupo of data) {
            for (var Grupo of this.state.Grupos) {
                if (Grupo.id_grupo === SelectGrupo.id) {
                    for (var asignado of Grupo.Asignados) {
                        TemSuscId.push(asignado.suscriptorid)
                        //TemInmuebleId.push(asignado.inmuebleid);
                    }
                    Nombres.push(Grupo.nombre_grupo);
                }
            }
        }

        this.props.guardar(TemSuscId, Nombres)
    }


    render() {


        if (this.state.data === true) {
            return (
                <Modal.Body className={'container-fluid'}>

                    <div className="row w-100">
                        <div className="tablaContact col-auto  ">
                            <MaterialTable
                                columns={columns}
                                data={this.state.rows}
                                title={this.props.tituloT}

                                actions={[
                                    {
                                        tooltip: 'Guardar seleccionados',
                                        icon: 'save',
                                        iconProps: ({
                                            fontSize: 'large'
                                        }),
                                        onClick: ((evt, Data) => { this.Guardar(Data) })
                                    }
                                ]}

                                options={{
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    rowStyle:{fontSize: "0.9rem"},
                                }}

                                localization={{
                                    header: {
                                        actions: 'Editar Grupo ',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos por mostrar.',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'registros',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

}

export default TablaGruposMsn;