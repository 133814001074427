import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Cookie from "universal-cookie";
import axios from "axios";
import { NavLink } from "react-router-dom";

import CardCopyLink from "./compos/CardCopyLink";
import { Modal } from "react-bootstrap";

import '../../../assets/css/dashboard.css';
import Global from "../../../Global";
import img_header from "../../../assets/images/images_menu/img_header.png";

import CardSolicitudes from './AdminHome2024/cardSolicitudes'
import CardMenuHome from './AdminHome2024/cardMenuHome'
import SidebarHome from './AdminHome2024/sidebarHome'
import FooterHome from "./AdminHome2024/footerHome";
import NavHome from "./AdminHome2024/navHome";
import CardSolicitudesMovil from './AdminHome2024/cardSolicitudesMovil';

import {
    CookieSesion,
    CookieNombrePH,
    CookiePhCodigo,
    CookieNombre,
    CookieTipo,
    CookieUserId,
    CookieRol,
    CookieNitph,
    CookieCiudad,
    CookieRondero,
    CookieSupervisor
} from "../../Helpers";

const cookies = new Cookie();

export default class NuevoHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect_login: 0,
            Rol: -1,
            Roles: [1, 2],

            modalQr: false,

            indicadores: [
                {
                    titulo: "Residentes",
                    texto: "Total Residentes registrados",
                    valor: 34,
                    icono: "bi-person-plus",
                    url: `/suscriptores`,
                },
                {
                    titulo: "Solicitudes",
                    texto: "Pendientes para Revisar",
                    valor: 67,
                    icono: "bi-chat-right-dots",
                    url: `/peticiones`,
                },
                {
                    titulo: "Reservas",
                    texto: "Pendientes para Aprobar",
                    valor: 23,
                    icono: "bi-calendar2-day",
                    url: `/ReservaZonas`,
                },
                {
                    titulo: "Tareas",
                    texto: "Pendientes para Ejecutar",
                    valor: 9,
                    icono: "bi-journal-check",
                    url: `/notas`,
                }
            ],
            MenuHome: [
                {
                    titulo: "Comunidad",
                    detalle: "Información y Edición de los residentes, aprobación de Pre-Registro",
                    icono: "bi bi-people",
                    url: `/suscriptores`,
                    color: "primary",
                },
                {
                    titulo: "Propietarios",
                    detalle: "Los inmuebles y los residentes que lo habitan",
                    icono: "bi bi-person-check",
                    url: `/libro-propietarios`,
                    color: "primary",
                },
                {
                    titulo: "Usuarios",
                    detalle: "Datos de los usuarios de Administración y colaboradores",
                    icono: "bi bi-person-circle",
                    url: `/usuarios`,
                    color: "primary",
                },
                {
                    titulo: "Notas",
                    detalle: "Crear y editar notas con tareas a realizar",
                    icono: "bi bi-card-checklist",
                    url: `/notas`,
                    color: "primary",
                },
                {
                    titulo: "Peticiones",
                    detalle: "Realiza solicitudes y seguimiento",
                    icono: "bi bi-box-arrow-in-down-left",
                    url: `/peticiones`,
                    color: "success",
                },
                {
                    titulo: "Mensajes",
                    detalle: "Mensajería instantánea con tu comunidad",
                    icono: "bi bi-envelope-arrow-down",
                    url: `/sistema-msn`,
                    color: "success",
                },
                {
                    titulo: "Documentos",
                    detalle: "Consulta documentos de tu comunidad",
                    icono: "bi bi-filetype-doc",
                    url: `/subir-archivo`,
                    color: "success",
                },
                {
                    titulo: "Porteria",
                    detalle: "Accede al control de visitantes",
                    icono: "bi bi-shield-check",
                    url: `/porteria-msn`,
                    color: "success",
                },
                {
                    titulo: "Zonas Sociales",
                    detalle: "Realiza reservas de zonas",
                    icono: "bi bi-water",
                    url: `/ZonaSocial`,
                    color: "danger",
                },
                {
                    titulo: "Reservas",
                    detalle: "Aprobar o Denegar Reservas",
                    icono: "bi bi-ticket",
                    url: `/ReservaZonas`,
                    color: "danger",
                },
                {
                    titulo: "Informes",
                    detalle: "Reportes e Informes",
                    icono: "bi bi-filetype-docx",
                    url: `/reportes`,
                    color: "danger",
                },
                {
                    titulo: "Asambleas",
                    detalle: "Ver los informes de asambleas",
                    icono: "bi bi-person-video3",
                    url: `/home-asamblea`,
                    color: "danger",
                },
                {
                    titulo: "Ronda",
                    detalle: "Consulta los informes de los ronderos",
                    icono: "bi bi-person-walking",
                    url: `/ronda-porteria`,
                    color: "danger",
                }
            ]
        };
    }

    componentWillMount() {
        var login = CookieSesion();
        var Rol = CookieRol();
        var userid = CookieUserId();

        if (parseInt(login) > 0) {
            if (parseInt(userid) > 0) {
                this.setState({
                    Rol: parseInt(Rol),
                });
                Global.GlobalUser.rol = parseInt(Rol);
                Global.GlobalUser.userid = CookieUserId();
                Global.GlobalUser.Nombre = CookieNombre();
                Global.GlobalUser.Rondero = CookieRondero();
                Global.GlobalUser.Supervisor = CookieSupervisor();
                Global.Ph.Nombre = CookieNombrePH();
                Global.Ph.Codigo = CookiePhCodigo();
                Global.Ph.Nitph = CookieNitph();
                Global.Ph.Ciudadph = CookieCiudad();
                Global.Ph.Estadoph = CookieTipo();
                axios.get(`${Global.Ruta.Url}admin/inithome/${Buffer.from(Global.Ph.Codigo).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString("base64")}`).then((res) => {
                    if (res.data.message === "") {
                        if (this.state.Roles.includes(this.state.Rol)) {
                            this.cantidadPeticiones(1);

                        }
                        if (parseInt(Rol) === 3 || parseInt(Rol) === 5) {
                            this.setState({
                                redirect_login: 1,
                            });
                        }

                        this.setState((prevState) => ({
                            indicadores: prevState.indicadores.map((indicador, index) =>
                                index === 0
                                    ? { ...indicador, valor: parseInt(res.data.CantInmuebles) }
                                    :
                                    index === 2
                                        ? { ...indicador, valor: parseInt(res.data.Reservas_Pend) }
                                        :
                                        index === 3
                                            ? { ...indicador, valor: parseInt(res.data.TareasPendientes) }
                                            : indicador
                            )
                        }));
                    }

                    var user = parseInt(Rol) + ";" + CookieUserId() + ";" + CookieNombre() + ";" + CookiePhCodigo() + ";" + CookieNombrePH();

                    Global.GlobalUser.UserLog = user;

                }).catch((err) => {
                    cookies.remove("Sesion", { path: "/" });
                    cookies.remove("UserId", { path: "/" });
                    cookies.remove("Nombre", { path: "/" });
                    this.setState({
                        redirect_login: 2,
                    });
                });
            } else {
                cookies.remove("Sesion", { path: "/" });
                cookies.remove("UserId", { path: "/" });
                cookies.remove("Nombre", { path: "/" });
                this.setState({
                    redirect_login: 2,
                });
            }
        } else {
            this.setState({
                redirect_login: 2,
            });
        }
        if (parseInt(Rol) === 0) {
            cookies.remove("Sesion", { path: "/" });
            cookies.remove("UserId", { path: "/" });
            cookies.remove("Nombre", { path: "/" });
            this.setState({
                redirect_login: 2,
            });
        }
    }

    cantidadPeticiones = (rol) => {
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        };
        if (rol === 1) {
            axios.post(`${Global.Ruta.Url}admin/cantpeticiones`, body).then((res) => {
                if (res.data.message === "") {
                    this.setState((prevState) => ({
                        indicadores: prevState.indicadores.map((indicador, index) =>
                            index === 1
                                ? { ...indicador, valor: parseInt(res.data.cantidad) }
                                : indicador
                        )
                    }), () => {
                        this.setState({
                            redirect_login: 1
                        });
                    });
                }
            }).catch((err) => { });
        }
    };

    render() {

        if (this.state.redirect_login === 2) {
            return <Redirect to={Global.Ruta.Raiz + "/"} />;
        }

        if (this.state.redirect_login === 1) {
            return (
                <div className="container-fluid">

                    <Modal show={this.state.modalQr} centered backdrop='static' size="xl" contentClassName="bg-transparent border-0">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <CardCopyLink openOverMod={() => this.setState({ modalQr: true })} closeOverMod={() => this.setState({ modalQr: false })} />
                            <button className="btn btn-danger rounded-circle d-flex justify-content-center align-items-center"
                                onClick={() => this.setState({ modalQr: false })}
                            >
                                <i className="fa-solid fa-times fs-1" />
                            </button>
                        </div>
                    </Modal>

                    <NavHome />

                    <div className="row">

                        <SidebarHome MenuHome={this.state.MenuHome} />

                        {/* Home */}
                        <div className="col-lg-9 col-md-9 col-10 main-content">
                            <h1 className="title pt-4"><strong>{`Bienvenido a ${Global.Ph.Nombre}`}</strong></h1>
                            <p>Aquí puedes gestionar todo lo relacionado con tu comunidad</p>
                            <p className="mt-2"><strong>Solicitudes</strong></p>

                            {/* Tarjetas para mostrar solo en Movil */}
                            <div className="d-flex min-size-sol bg-c-blue text-center d-block d-md-none">
                                {this.state.indicadores.map((data, item) => {
                                    return (
                                        <CardSolicitudesMovil titulo={data.titulo} valor={data.valor} icono={data.icono} key={item} url={data.url} />
                                    )
                                })
                                }
                            </div>

                            {/* Tarjetas para mostrar solo en PC */}
                            <div className="row">
                                {this.state.indicadores.map((data, item) => {
                                    return (
                                        <CardSolicitudes titulo={data.titulo} texto={data.texto} valor={data.valor} key={item} url={data.url} />
                                    )
                                })
                                }
                            </div>

                            {/* Ocultar en modo movil, imagen y mensaje de bienvenida */}
                            <div className="row mt-2 mb-3">
                                <div className="col-6 d-none d-md-block">
                                    <div className="card card-custom img">
                                        <img src={img_header} className="welcome object-fit-fill" alt="Img P.H." height="200" />
                                    </div>
                                </div>
                                <div className="col-6 d-none d-md-block">
                                    <div className="card card-custom bg-white welcome border-0">
                                        <div className="card-body">
                                            <h3 className="card-title pt-2"><strong>{`¡Hola, ${Global.GlobalUser.Nombre}!`}</strong></h3>
                                            <p className="pb-4 texto-resp">Aquí te mostraremos toda la información relevante para ti.</p>
                                            {/* <a  className="btn btn-primary">Ver más</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Menu Home */}
                            <div className="row">
                                {this.state.MenuHome.map((data, item) => {
                                    return (
                                        <CardMenuHome titulo={data.titulo} detalle={data.detalle} icono={data.icono} url={data.url} color={data.color} key={item} />
                                    )
                                })
                                }
                            </div>
                            <div className="col-md-3 mb-5"></div>
                        </div>
                    </div>
                    <FooterHome />
                </div>
            )
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}