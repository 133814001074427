import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap';
import Global from '../../../../Global';
import axios from "axios";
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from 'simple-react-validator'

export default class FormMascotas extends Component {

    state = {
        Tipo: [{ id: 1, tipo: "PERRO" }, { id: 2, tipo: "GATO" }, { id: 3, tipo: "MINI PIG" }],
        Estado: [{ id: 1, tipo: "Activo" }, { id: 2, tipo: "Inactivo" }],
        modal: false,
        caso: this.props.DataMascota.caso,
        estado: this.props.DataMascota.estado,
        fecha_creacion: this.props.DataMascota.fecha_creacion,
        id_mascota: this.props.DataMascota.id_mascota,
        nombre: this.props.DataMascota.nombre,
        peligro: this.props.DataMascota.peligro,
        susc_id: this.props.DataMascota.susc_id,
        tipo_mascota: this.props.DataMascota.tipo_mascota,
        Suscriptores: this.props.Suscriptores,
        Direccion: this.props.Direccion,
        InmuebleId: this.props.InmuebleId,

    }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'solo puede ingresar numeros enteros',
            }
        });
    }

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value
        }));
    }

    GuardarInsert = () => {
        if (this.validator.allValid()) {
            this.setState({ modal: true });
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
                suscriptor_id: Buffer.from("" + this.state.susc_id).toString("base64"),
                nombre: Buffer.from("" + this.state.nombre).toString("base64"),
                tipo_mascota: Buffer.from("" + this.state.tipo_mascota).toString("base64"),
                peligro: Buffer.from("" + this.state.peligro).toString("base64"),

            };
            axios
                .post(`${Global.Ruta.Url}admin/creamascota/`, body)
                .then((res) => {
                    if (res.data.message === "") {
                        swal({
                            title: "ATENCIÓN!",
                            text: `Mascota ${(this.state.nombre).toUpperCase()} Ingresado`,
                            icon: "success",
                            button: "Aceptar",
                        })
                        var newmascota = {
                            estado: 1,
                            fecha_creacion: "",
                            id_mascota: res.data.id_mascota,
                            nombre: this.state.nombre.toUpperCase(),
                            peligro: this.state.peligro,
                            susc_id: this.state.susc_id,
                            tipo_mascota: this.state.tipo_mascota,
                        }
                        this.setState({ modal: false });
                        this.props.InsertMascota(newmascota);
                    } else {
                        swal({
                            title: "ATENCIÓN!",
                            text: `${res.data.message}`,
                            icon: "error",
                            button: "Aceptar",
                        })
                        this.setState({ modal: false });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ATENCIÓN!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ redirect_suscriptores: true });
                    });
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    GuardarUpdate = () => {
        if (this.validator.allValid()) {
            

            this.setState({ modal: true });
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
                suscriptor_id: Buffer.from("" + this.state.susc_id).toString("base64"),
                nombre: Buffer.from("" + this.state.nombre).toString("base64"),
                tipo_mascota: Buffer.from("" + this.state.tipo_mascota).toString("base64"),
                peligro: Buffer.from("" + this.state.peligro).toString("base64"),
                id_mascota: Buffer.from("" + this.state.id_mascota).toString("base64"),
                estado: Buffer.from("" + this.state.estado).toString("base64"),
            };
            axios
                .post(`${Global.Ruta.Url}admin/editamascota/`, body)
                .then((res) => {
                    if (res.data.message === "") {
                        swal({
                            title: "ATENCIÓN!",
                            text: `Mascota ${(this.state.nombre).toUpperCase()} Actualizado`,
                            icon: "success",
                            button: "Aceptar",
                        })
                        var updatemascota = {
                            estado: this.state.estado,
                            fecha_creacion: "",
                            id_mascota: this.state.id_mascota,
                            nombre: this.state.nombre.toUpperCase(),
                            peligro: this.state.peligro,
                            susc_id: this.state.susc_id,
                            tipo_mascota: this.state.tipo_mascota,
                        }
                        this.setState({ modal: false });
                        this.props.ActualizarMascota(updatemascota);
                    } else {
                        swal({
                            title: "ATENCIÓN!",
                            text: `${res.data.messaje}`,
                            icon: "error",
                            button: "Aceptar",
                        })
                        this.setState({ modal: false });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ATENCIÓN!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ redirect_suscriptores: true });
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    render() {

        if (this.state.redirect_home === true) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
            )
        }

        return (
            <div>
                <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                    <PpHourglass />
                </Modal>
                <Modal.Header closeButton>
                    {parseInt(this.state.caso) === 1 ? (`Nueva Mascota ${this.state.Direccion.toUpperCase()}`) : (`Editar Mascota ${this.props.DataMascota.nombre.toUpperCase()}`)}
                </Modal.Header>
                <Modal.Body>

                    {this.state.Suscriptores.length > 0 ? (
                        <div className="row row-cols-2 ">

                            <div className="col">
                                <label htmlFor="nombre">Nombre</label>
                                <Form.Control onChange={this.handleChange("nombre")} maxLength={40} value={this.state.nombre.toUpperCase()} />
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('nombre', this.state.nombre, 'required', { className: 'text-danger' })}</label>
                            </div>

                            <div className="col">
                                <Form.Group>
                                    <label htmlFor="docummento">Tipo Mascota</label>
                                    <Form.Select value={this.state.tipo_mascota} onChange={this.handleChange("tipo_mascota")}>
                                        {parseInt(this.state.caso) === 1 ? (<option value="-1">OPCIÓN...</option>) : ("")}
                                        {this.state.Tipo.map((data, indice) => (
                                            <option key={indice} value={data.id}>{data.tipo.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Tipo Mascota', this.state.tipo_mascota, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>


                            <div className="col">
                                <Form.Group>
                                    <label >Suscriptor</label>
                                    <Form.Select value={this.state.susc_id} onChange={this.handleChange("susc_id")}>
                                        {parseInt(this.state.caso) === 1 ? (<option value="-1">OPCIÓN...</option>) : ("")}
                                        {this.state.Suscriptores.map((data, indice) => (
                                            <option key={indice} value={data.suscriptorid}>{data.nombres.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Suscriptor', this.state.susc_id, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>


                            {parseInt(this.state.caso) === 2 ? (<div className="col">
                                <Form.Group>
                                    <label >Estado</label>
                                    <Form.Select value={this.state.estado} onChange={this.handleChange("estado")}>
                                        {this.state.Estado.map((data, indice) => (
                                            <option key={indice} value={data.id}>{data.tipo.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Estado', this.state.estado, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>


                            ) : ("")}

                            <div className="col">
                                <Form className='py-2 mx-3'>
                                    <label htmlFor="nombre" className='row'>Raza Potencialmente Peligrosa</label>
                                    <Form.Check
                                        type="switch"
                                        onChange={(e) => this.setState({ peligro: e.target.checked })}
                                        label={this.state.peligro === false ? ("No") : ("Si")}
                                        checked={this.state.peligro}
                                    />
                                </Form>
                            </div>

                        </div>
                    ) : (<div className="d-flex justify-content-center flex-column align-items-center text-center">
                        <div className="spinner-grow text-info" role="status" style={{ width: '5rem', height: '5rem' }} />
                        <span className="my-2 ">Espere un momento...</span>
                    </div>)}
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 justify-content-center">
                        <div className="col-3 d-grid">
                            <button className="btn btn-primary" onClick={(parseInt(this.state.caso) === 1) ? (this.GuardarInsert) : (this.GuardarUpdate)}>Guardar</button>
                        </div>
                    </div>
                </Modal.Footer>
            </div >
        )
    }
}
