import React, { Component } from "react";

class PpSorteo extends Component {

    render() {

        return (
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-sm-12 col-lg-4">
                    <div className="card w-auto h-auto">
                        <div className="card-header">
                            Sorteo
                        </div>
                        <div className="card-body px-4 text-center">
                            <h5 className="card-title center">EL GANADOR ES:
                                <br />
                                <h6 className="font-weight-normal mt-2">
                                    {this.props.NombreGanador.toUpperCase()}
                                    <br />
                                    {this.props.DirGanador.toUpperCase()}
                                </h6>
                            </h5>
                        </div>
                        <div className="card-footer">
                            <div className="row justify-content-center">
                                <button className="btn btn-primary col-5" onClick={this.props.onClose}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PpSorteo