import React, { Component } from "react";
import Global from "../../Global";
import logo from "../../assets/images/images_menu/goph_logo.png";

class Footer extends Component {
  render() {
    return (
      <footer className="col-12" style={{paddingTop:'1rem'}}>
        <div className="row row-cols-1 row-cols-md-3 justify-content-center gap-3">
          <div className="col">
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-5 col-lg-3">
                <img src={logo} alt="" className="w-100" />
              </div>
              <div className="col-12 col-md">
                <p className="fw-normal ind-txt-sm m-0 text-white text-center text-md-start" style={{whiteSpace:'pre-wrap'}}>
                  Plataforma para la Administración y Asambleas en su propiedad horizontal.
                </p>
                <p className="m-0 mt-3 ind-txt-xs fw-light text-white text-center text-md-start">
                  v.{' '}{Global.Ruta.version}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-auto">
            <div className="row justify-content-center align-content-center h-100">
              <div className="col-12 h-auto">
                <h5 className="fw-normal text-center text-white">Contáctanos</h5>
              </div>
              <div className="col-auto text-center">
                <p className="fw-light ind-txt-sm m-0 text-white">comercial@inggos.com</p>
                <a
                  href="https://inggos.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="link-light ind-txt-sm fw-light"
                >
                  www.inggos.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <div className="row flex-column text-light text-center justify-content-center h-100">
              <div className="col-auto">
                <h5 className="fw-normal">Síguenos</h5>
              </div>
              <div className="col-auto">
                <div className="row justify-content-center align-items-center">
                  <div className="col-auto">
                    <a
                      href="https://www.facebook.com/gophcolombia"
                      className="link-light fs-2"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a
                      href="https://twitter.com/GoPHColombia"
                      className="link-light fs-2"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a
                      href="https://www.youtube.com/channel/UCK3bVVXVw4ltzil3MCqdG4A"
                      className="link-light fs-2"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col">
            <div className="row">
              <div className="col-12 text-center">
                <h5 className="fw-normal">Ayudas</h5>
              </div>
              <div className="col-12">
                <div className="row">
                  <ul className="col text-light text-center">
                    <li>
                      <a
                        className="text-nowrap link-light fw-light ind-txt-sm"
                        href="https://www.inggos.com/politica-de-privacidad/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Política de privacidad
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-nowrap link-light fw-light ind-txt-sm"
                        href="https://www.inggos.com/terminos-y-condiciones-goph/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Términos y condiciones
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </footer>
    );
  }
}

export default Footer;
