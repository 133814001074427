import React, {Component} from "react";
import axios from "axios";
import Global from "./../../../Global";

import MaterialTable from "material-table";
import {Modal} from "react-bootstrap";
import InfoSuscSapp from "./SubModals/InfoSuscSapp";
import PpHourglass from "./../Asamblea/PopUps/PpHourglass";

const columns = [
  {
    field: "idinmdir",
    title: "Id inmueble",
    hidden: true,
  },
  {
    field: "suscriptorid",
    title: "SuscId",
    hidden: true,
  },
  {
    field: "login",
    title: "Login",
    hidden: true,
  },
  {
    field: "nombres",
    title: "Nombres",
  },
  {
    field: "direccion",
    title: "Dirección",
  },
  {
    field: "tipo",
    title: "Tipo",
  },
  {
    field: "email",
    title: "E-mail",
  },
  {
    field: "telefono",
    title: "Teléfono",
    hidden: true,
  },
];

export default class TablaListSubSapp extends Component {
  state = {
    // MODALS
    modal0: false,
    modal: false,
    data: false,
    rows: [],

    // DATOS MODALS
    datosModal: [],
    Enviados: [],
  };

  componentWillMount() {
    axios
      .get(
        Global.Ruta.Url +
          "mensajeria/suscasinapp/" +
          Buffer.from(Global.Ph.Codigo).toString("base64")
      )
      .then((res) => {
        if (res.data.message === "") {
          this.setState({rows: res.data.Susc, data: true});
        } else {
          this.props.cerrar();
        }
      })
      .catch((err) => {
        this.props.cerrar();
      });
  }

  EnviarCorreo = (Data) => {
    this.setState({modal: true});
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      userid: Buffer.from("" + Data.suscriptorid).toString("base64"),
      login: Buffer.from("" + Data.login).toString("base64"),
      email: Buffer.from("" + Data.email).toString("base64"),
      Nombre: Buffer.from("" + Data.nombres.toUpperCase()).toString("base64"),
      Caso: Buffer.from("" + 3).toString("base64"),
    };
    var tem = this.state.Enviados;
    tem.push(parseInt(Data.suscriptorid));
    axios
      .post(Global.Ruta.Url + "admin/resetpwd", body)
      .then((res) => {
        this.setState({modal: false, Enviados: tem});
      })
      .catch((err) => {
        this.setState({modal: false});
      });
  };

  Valida = (Data) => {
    if (Data.includes(";")) {
      var tem = Data.split(";");
      for (var i = 0; i < tem.length; i++) {
        if (this.ValidarCorreo(tem[i], i + 1)) {
          return true;
        }
      }
    } else {
      if (this.ValidarCorreo(Data, 1)) {
        return true;
      }
    }
    return false;
  };

  ValidarCorreo = (data, indice) => {
    // eslint-disable-next-line
    var RegExp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!RegExp.test(data)) {
      return true;
    }
  };

  render() {
    if (this.state.data === true) {
      return (
        <Modal.Body>
          {/* modal de info adicional */}
          <Modal
            show={this.state.modal0}
            backdrop="static"
            onHide={() => this.setState({modal0: false})}
            centered
            className="shadow-lg"
            backdropClassName="bg-dark bg-opacity-25"
          >
            <InfoSuscSapp
              id={this.state.datosModal.idinmdir}
              nombre={this.state.datosModal.nombres}
              dir={this.state.datosModal.direccion}
              email={this.state.datosModal.email}
              tel={this.state.datosModal.telefono}
            />
          </Modal>

          <Modal
            show={this.state.modal}
            centered
            backdrop="static"
            onHide={() => this.setState({modal: false})}
          >
            <PpHourglass />
          </Modal>

          {/* tabla */}
          <div className="shadow rounded">
            <MaterialTable
              columns={columns}
              data={this.state.rows}
              title={`Suscriptores Sin App`}
              actions={[
                {
                  onClick: (event, rowData) => {
                    this.setState({modal0: true, datosModal: rowData});
                  },
                  onClickDos: (event, rowData) => {
                    this.EnviarCorreo(rowData);
                  },
                },
              ]}
              components={{
                Action: (props) => (
                  <div>
                    <button
                      className="btn btn-primary text-nowrap m-2"
                      onClick={(event) => {
                        props.action.onClick(event, props.data);
                      }}
                    >
                      Detalle
                    </button>
                    <button
                      className={`btn btn-${
                        this.state.Enviados.includes(
                          parseInt(props.data.suscriptorid)
                        )
                          ? "danger"
                          : "success"
                      } text-nowrap m-2 px-3`}
                      onClick={(event) => {
                        props.action.onClickDos(event, props.data);
                      }}
                      disabled={this.Valida(props.data.email) ? true : false}
                    >
                      Invitar
                    </button>
                  </div>
                ),
              }}
              options={{
                pageSize: 10,
                actionsColumnIndex: -1,
                rowStyle:{fontSize: "0.9rem"},
              }}
              localization={{
                header: {
                  actions: "Detalle ",
                },
                body: {
                  emptyDataSourceMessage: "No hay datos por mostrar.",
                },
                pagination: {
                  labelRowsSelect: "registros",
                  labelDisplayedRows: "{from}-{to} de {count}",
                  firstTooltip: "Primera página",
                  previousTooltip: "Página anterior",
                  nextTooltip: "Próxima página",
                  lastTooltip: "Última página",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                  nRowsSelected:
                    "{0} " + this.props.tituloT + "(s) Seleccionado(s)",
                },
              }}
            />
          </div>
        </Modal.Body>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
