import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import DataTable from "../../porteria/Vehiculos/Tablavehiculos";
import PlacaVehiculo from './PlacaVehiculo';

export default class ModalTableVh extends Component {

  state = {
    modal: false
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.modal}
          centered
          size="lg"
          onHide={() => this.setState({ modal: false })}
          keyboard={false}
          backdrop={"static"}
        >
          <PlacaVehiculo />
        </Modal>
        <Modal.Header closeButton>Placas de Vehículos</Modal.Header>
        <Modal.Body>
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-primary mx-2"
                onClick={() => this.setState({ modal: true })}
              >
                <i className="fa-solid fa-magnifying-glass me-2" />{" "}
                Buscar Placas
              </button>
            </div>
          </div>
          <DataTable />
        </Modal.Body>
      </div>
    )
  }
}
