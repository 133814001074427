import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import { CookieSesion } from "../../Helpers";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import { Fab } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import PpHourglass from "../Asamblea/PopUps/PpHourglass";
import FormEditVehiculo from "./modals/FormEditVehiculo";
import ModalMoraLib from "./modals/ModalMoraLib";
import CambiaInmueble from "./modals/CambiaInmueble"
import FormHabitantes from "./modals/FormHabitantes"
import FormMascotas from "./modals/FormMascotas"
import FormArrendado from "./modals/FormArrendado"
import SubirCartera from './modals/SubirCartera'
import HeadTitle from "../../principal/HeadTitle";
import { Avatar } from "@mui/material";
import ModalTableVh from "./modals/ModalTableVh";
import { removeDiacritics } from "../../Helpers"

const columnsSusc = [
  {
    field: "suscriptorid",
    title: "Id usuario",
    hidden: true,
  },
  {
    field: "rolid",
    title: "Rol",
    lookup: { 1: "PROPIETARIO", 2: "PROPIETARIO-RESIDENTE", 3: "RESIDENTE" },
  },
  {
    field: "nombres",
    title: "Nombres",
  },
  {
    field: "login",
    title: "Login",
  },
  {
    field: "email",
    title: "E-mail",
  },
  {
    field: "telefono",
    title: "Teléfono",
  },
  {
    field: "direccion",
    title: "Dirección",
    hidden: true,
  },
  {
    field: "inmuebleid",
    title: "Nombres",
    hidden: true,
  },
  {
    field: "coeficiente",
    title: "Coeficiente",
    hidden: true,
  },
  {
    field: "tipo_doc",
    title: "Tipo Documento",
    lookup: { 0: "NA", 1: "C.C.", 2: "T.I.", 3: "C.E." },
  },
  {
    field: "documento",
    title: "# Documento",
  },
  {
    field: "estadoint",
    title: "Estado",
    lookup: { 0: "INACTIVO", 1: "ACTIVO" },
  },
];

const columnsVehiculos = [
  {
    field: "id",
    title: "Id Vehiculo",
    hidden: true,
  },
  {
    field: "tipo",
    title: "Tipo",
  },
  {
    field: "marca",
    title: "Marca",
  },
  {
    field: "color",
    title: "Color",
  },
  {
    field: "placa",
    title: "Placa",
  },
];

const columnsHabitantes = [
  {
    field: "id_habitante",
    title: "Id Habitante",
    hidden: true,
  },
  {
    field: "susc_id",
    title: "Id Suscriptor",
    hidden: true,
  },
  {
    field: "nombre",
    title: "Nombre",
  },
  {
    field: "tipo_documento",
    title: "Tipo Documento",
    lookup: { 0: "NA", 1: "C.C.", 2: "T.I.", 3: "C.E." },
  },
  {
    field: "documento",
    title: "Documento",
  },
  {
    field: "telefono",
    title: "Telefono",
  },
  {
    field: "correo",
    title: "Correo",
  },
  {
    field: "fecha_creacion",
    title: "Fecha Creación",
    hidden: true,
  },
  {
    field: "estado",
    title: "Estado",
    lookup: { 0: "ELIMINADO", 1: "ACTIVO", 2: "INACTIVO" },
  },
];

const columnsMascotas = [
  {
    field: "id_mascota",
    title: "Id Mascota",
    hidden: true,
  },
  {
    field: "tipo_mascota",
    title: "Tipo Mascota",
    lookup: { 1: "PERRO", 2: "GATO", 3: "MINI PIG" },
  },
  {
    field: "nombre",
    title: "Nombre",
  },
  {
    field: "peligro",
    title: "Raza Potencialmente Peligrosa",
    lookup: { "false": "NO", "true": "SI" },
  },
  {
    field: "estado",
    title: "Estado",
    lookup: { 0: "ELIMINADO", 1: "ACTIVO", 2: "INACTIVO" },
  },
  {
    field: "fecha_creacion",
    title: "Fecha Creación",
    hidden: true,
  },
];

const Localization = {
  header: {
    actions: "Acción",
  },
  body: {
    emptyDataSourceMessage: "No hay datos por mostrar.",
  },
  pagination: {
    labelRowsSelect: "registros",
    labelDisplayedRows: "{from}-{to} de {count}",
    firstTooltip: "Primera página",
    previousTooltip: "Página anterior",
    nextTooltip: "Próxima página",
    lastTooltip: "Última página",
  },
  toolbar: {
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
  },
};

export default class LibroProp extends Component {
  state = {
    data: false,
    redirect_home: false,
    modal: false,
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
    modal7: false,
    modal8: false,
    offC1: false,
    asambleaopen: false,
    DataInmueble: false,
    form_susc: 0,
    redirect_login: 0,
    Roles: [1, 2],
    Inmuebles: [],
    Busqueda: "",
    TemParaBusqueda: [],
    Suscriptores: [],
    Vehiculos: [],
    Habitantes: [],
    Mascotas: [],
    DataVehiculo: {},
    InmuebleId: "",
    Direccion: "",
    Inmueble: {},
    DataPh: {},
    TempSuscriptor: {},
    DataHabitante: {},
    DataMascota: {},
    Arrendado: [{ id: 0, arrendado: "Desocupado" }, { id: 1, arrendado: "Arrendado" }, { id: 2, arrendado: "Ocupado por Propietario" }, { id: 3, arrendado: "Vivienda Turística" }],
    NombreArriendo: "",
    rol: 0,
    // heightSwitch: false,
  };

  componentWillMount() {
    if (parseInt(CookieSesion()) > 0) {
      if (parseInt(Global.GlobalUser.userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          };
          axios
            .post(Global.Ruta.Url + "admin/listinm/", body)
            .then((res) => {
              axios
                .post(Global.Ruta.Url + "admin/ncminmuebles/", body)
                .then((res1) => {
                  if (res.data.message === "") {
                    this.setState({
                      data: true,
                      Inmuebles: res.data.Inmuebles,
                      TemParaBusqueda: res.data.Inmuebles,
                      DataPh: res1.data.data,
                    });
                  }
                })
                .catch((err1) => {
                  swal({
                    title: "ATENCIÓN!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                    icon: "error",
                    button: "Aceptar",
                  }).then(() => {
                    this.setState({ redirect_suscriptores: true });
                  });
                });
            })
            .catch((err) => {
              swal({
                title: "ATENCIÓN!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_suscriptores: true });
              });
            });
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }

    this.AsambleaOpen()
  }

  AsambleaOpen = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}admin/asambleaopen`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            asambleaopen: true,
          });

        } else {
          this.setState({
            asambleaopen: false,
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  }

  formulario = (e) => {
    Global.Form.tipo = e.tipo;
    if (e.tipo === 2) {
      Global.Form.id = e.id;
      Global.Form.nombre = e.nombre;
      Global.Form.login = e.login;
      Global.Form.estado = e.estado;
      Global.Form.rol = e.rol;
      Global.Form.inmuebleid = e.inmuebleid;
      Global.Form.correo = e.correo;
      Global.Form.telefono = e.telefono;
      Global.Form.mora = e.mora;
    } else {
      Global.Form.id = "";
      Global.Form.nombre = "";
      Global.Form.login = "";
      Global.Form.estado = "";
      Global.Form.rol = "";
      Global.Form.inmuebleid = "";
      Global.Form.mora = false;
    }
    this.setState({ form_susc: 1, data: false });
  };

  formularioVehiculo = (data) => {
    this.setState({
      modal1: true,
      DataVehiculo: data,
    });
  };

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value,
    }));

    if (event.target.value === "") {
      var Inmuebles = this.state.TemParaBusqueda;
      this.setState({
        Inmuebles: Inmuebles,
      });
    } else {
      this.setState({
        Inmuebles: this.filterItems(
          event.target.value,
          this.state.TemParaBusqueda
        ),
      });
    }
  };

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1;
    });
  };

  BuscarDataInmueble = (inmuebleid, Direccion) => {
    this.setState({
      modal: true,
      InmuebleId: inmuebleid,
      Direccion: Direccion,
    });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      inmuebleid: Buffer.from("" + inmuebleid).toString("base64"),
    };
    axios
      .post(`${Global.Ruta.Url}admin/listdatainmueble/`, body)
      .then((res) => {
        if (res.data.message === "") {
          var tmpInmuebles = this.state.TemParaBusqueda;

          this.setState({
            Suscriptores: res.data.Suscriptores,
            Vehiculos: res.data.Vehiculos,
            Inmueble: res.data.Inmueble,
            Habitantes: res.data.Habitantes,
            Mascotas: res.data.Mascotas,
            modal: false,
            DataInmueble: true,
            // heightSwitch: true,
            Inmuebles: tmpInmuebles,
            Busqueda: ""
          });
          // this.AsambleaOpen()
          // this.TipoArriendo(res.data.Inmueble.arrendado)
        }
      })
      .catch((err) => {
        swal({
          title: "ATENCIÓN!",
          text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_suscriptores: true });
        });
      });
  };

  TipoArriendo = (New) => {
    var tem = this.state.Arrendado
    var temp = this.state.Inmueble
    temp.arrendado = New
    this.setState({ NombreArriendo: tem[New].arrendado, modal7: false, Inmueble: temp })
  }

  InsertarVehiculo = (New) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Vehiculos;
    tem.push(New);
    this.setState({
      Vehiculos: tem,
      modal1: false,
      DataInmueble: true,
    });
  };

  ActualizarVehiculo = (New) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Vehiculos;
    var indice = this.BuscarIndexCambio(New.id, tem);
    tem[indice].color = New.color;
    tem[indice].marca = New.marca;
    tem[indice].placa = New.placa;
    tem[indice].tipo = New.tipo;
    tem[indice].susc_id = New.susc_id;
    this.setState({
      Vehiculos: tem,
      modal1: false,
      DataInmueble: true,
    });
  };

  EliminarVehiculo = (Eliminar, placa) => {
    swal({
      title: "ATENCIÓN!",
      text: `Desea eliminar el vehiculo ${placa}?`,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.setState({
          modal: true,
        });
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          id: Buffer.from("" + Eliminar).toString("base64"),
        };
        axios
          .post(`${Global.Ruta.Url}admin/deletvehiculo/`, body)
          .then((res) => {
            if (res.data.message === "") {
              this.setState({
                DataInmueble: false,
              });
              var tem = this.state.Vehiculos;
              var indice = this.BuscarIndexCambio(Eliminar, tem);
              tem.splice(indice, 1);
              this.setState({
                Vehiculos: tem,
                modal: false,
                DataInmueble: true,
              });
            } else {
              swal({
                title: "Atención !!",
                text: res.data.message,
                icon: "warning",
                button: "Aceptar",
              }).then(() => {
                this.setState({ modal: false });
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ALERTA!",
              text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then((resp) => {
              this.setState({ modal: false });
            });
          });
      }
    });
  };

  //BUSCA LA POSICION DEL ARRAY
  BuscarIndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  /*Cambiar Inmueble*/
  CambiaInmueble = (suscriptor) => {

    this.setState({
      modal4: true,
      TempSuscriptor: suscriptor,
    });

  }

  CerrarCambioInmueble = () => {
    this.setState({ modal4: false })
    this.BuscarDataInmueble(this.state.InmuebleId, this.state.Direccion)
  }

  /*Habitantes*/
  formularioHabitantes = (data) => {
    this.setState({
      modal5: true,
      DataHabitante: data,
    });
  }

  InsertHabitante = (New) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Habitantes;
    tem.push(New);
    this.setState({
      Habitantes: tem,
      modal5: false,
      DataInmueble: true,
    });
  }

  ActualizarHabitante = (New) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Habitantes;
    var indice = this.BuscarIndexCambioHabitante(New.id_habitante, tem);
    tem[indice].correo = New.correo;
    tem[indice].documento = New.documento;
    tem[indice].estado = New.estado;
    tem[indice].fecha_creacion = New.fecha_creacion;

    tem[indice].id_habitante = New.id_habitante;
    tem[indice].nombre = New.nombre;
    tem[indice].telefono = New.telefono;
    tem[indice].tipo_documento = New.tipo_documento;
    tem[indice].susc_id = New.susc_id;

    this.setState({
      Habitantes: tem,
      modal5: false,
      DataInmueble: true,
    });
  }

  BuscarIndexCambioHabitante = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id_habitante) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  EliminarHabitante = (data) => {
    swal({
      title: "ATENCIÓN!",
      text: `Desea eliminar el Habitante ${(data.nombre).toUpperCase()}?`,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.setState({
          modal: true,
        });
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
          suscriptor_id: Buffer.from("" + data.susc_id).toString("base64"),
          habitante_id: Buffer.from("" + data.id_habitante).toString("base64"),
          nombre: Buffer.from("" + data.nombre).toString("base64"),
          tipo_doc: Buffer.from("" + data.tipo_documento).toString("base64"),
          documento: Buffer.from("" + data.documento).toString("base64"),
          telefono: Buffer.from("" + data.telefono).toString("base64"),
          correo: Buffer.from("" + data.correo).toString("base64"),
          estado: Buffer.from("" + 0).toString("base64"),
        };
        axios
          .post(`${Global.Ruta.Url}admin/editahabitante/`, body)
          .then((res) => {
            if (res.data.message === "") {
              this.setState({
                DataInmueble: false,
              });
              var tem = this.state.Habitantes;
              var indice = this.BuscarIndexCambioHabitante(data.id_habitante, tem);
              tem.splice(indice, 1);
              this.setState({
                Habitantes: tem,
                modal: false,
                DataInmueble: true,
              });
            } else {
              swal({
                title: "Atención !!",
                text: res.data.message,
                icon: "warning",
                button: "Aceptar",
              }).then(() => {
                this.setState({ modal: false });
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ALERTA!",
              text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then((resp) => {
              this.setState({ modal: false });
            });
          });
      }
    });

  }

  /*Mascotas*/
  formularioMascotas = (data) => {
    this.setState({
      modal6: true,
      DataMascota: data,
    });
  }

  InsertMascota = (New) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Mascotas;
    tem.push(New);
    this.setState({
      Mascotas: tem,
      modal6: false,
      DataInmueble: true,
    });
  }

  ActualizarMascota = (New) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Mascotas;
    var indice = this.BuscarIndexCambioMascota(New.id_mascota, tem);
    tem[indice].estado = New.estado;
    tem[indice].nombre = New.nombre;
    tem[indice].peligro = New.peligro;
    tem[indice].susc_id = New.susc_id;
    tem[indice].tipo_mascota = New.tipo_mascota;

    this.setState({
      Mascotas: tem,
      modal6: false,
      DataInmueble: true,
    });
  }

  EliminarMascota = (data) => {
    swal({
      title: "ATENCIÓN!",
      text: `Desea eliminar a la Mascota ${(data.nombre).toUpperCase()}?`,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.setState({
          modal: true,
        });
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
          suscriptor_id: Buffer.from("" + data.susc_id).toString("base64"),
          nombre: Buffer.from("" + data.nombre).toString("base64"),
          tipo_mascota: Buffer.from("" + data.tipo_mascota).toString("base64"),
          peligro: Buffer.from("" + data.peligro).toString("base64"),
          id_mascota: Buffer.from("" + data.id_mascota).toString("base64"),
          estado: Buffer.from("" + 0).toString("base64"),
        };
        axios
          .post(`${Global.Ruta.Url}admin/editamascota/`, body)
          .then((res) => {
            if (res.data.message === "") {
              this.setState({
                DataInmueble: false,
              });
              var tem = this.state.Mascotas;
              var indice = this.BuscarIndexCambioMascota(data.id_mascota, tem);
              tem.splice(indice, 1);
              this.setState({
                Mascotas: tem,
                modal: false,
                DataInmueble: true,
              });
            } else {
              swal({
                title: "Atención !!",
                text: res.data.message,
                icon: "warning",
                button: "Aceptar",
              }).then(() => {
                this.setState({ modal: false });
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ALERTA!",
              text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then((resp) => {
              this.setState({ modal: false });
            });
          });
      }
    });

  }

  BuscarIndexCambioMascota = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id_mascota) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  ActualizarEstadoSuscriptor = (suscriptor) => {
    this.setState({
      DataInmueble: false,
    });
    var tem = this.state.Suscriptores;
    var indice = this.BuscarIndexCambioEstado(suscriptor.id_susc, tem);
    tem[indice].estadoint = suscriptor.estado;
    tem[indice].documento = suscriptor.documento;
    tem[indice].tipo_doc = suscriptor.tipo_doc;


    this.setState({
      Suscriptores: tem,
      modal4: false,
      DataInmueble: true,
    });
  }

  BuscarIndexCambioEstado = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.suscriptorid) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.form_susc === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/formsuscriptor"} />;
    }

    if (this.state.data === true) {
      return (
        <div className="container-fluid bg-light h-100">
          <Modal
            show={this.state.modal}
            centered
            backdrop="static"
            onHide={() => this.setState({ modal: false })}
          >
            <PpHourglass />
          </Modal>
          <Modal
            show={this.state.modal2}
            centered
            backdrop="static"
            dialogClassName="m-size-1"
            onHide={() => this.setState({ modal2: false })}
          >
            <ModalMoraLib />
          </Modal>
          <Modal
            show={this.state.modal1}
            centered
            onHide={() => this.setState({ modal1: false })}
            keyboard={false}
            backdrop={"static"}
          >
            <FormEditVehiculo
              Data={this.state.DataVehiculo}
              InmuebleId={this.state.InmuebleId}
              Insertar={this.InsertarVehiculo}
              Actualizar={this.ActualizarVehiculo}
              Suscriptores={this.state.Suscriptores}
            />
          </Modal>
          <Modal
            show={this.state.modal3}
            centered
            size="xl"
            onHide={() => this.setState({ modal3: false })}
            keyboard={false}
            backdrop={"static"}
          >
            <ModalTableVh />
          </Modal>
          <Modal
            show={this.state.modal4} centered size="sx" backdrop="static" onHide={() => this.setState({ modal4: false })}>
            <CambiaInmueble Suscriptor={this.state.TempSuscriptor} Inmuebles={this.state.Inmuebles} Cerrar={this.CerrarCambioInmueble} ActualizarEstadoSuscriptor={this.ActualizarEstadoSuscriptor} />
          </Modal>
          <Modal
            show={this.state.modal5} centered size="lg" backdrop="static" onHide={() => this.setState({ modal5: false })}>
            <FormHabitantes Suscriptores={this.state.Suscriptores} Direccion={this.state.Direccion}
              InmuebleId={this.state.InmuebleId} DataHabitante={this.state.DataHabitante} InsertHabitante={this.InsertHabitante}
              ActualizarHabitante={this.ActualizarHabitante}
            />
          </Modal>
          <Modal
            show={this.state.modal6} centered size="lg" backdrop="static" onHide={() => this.setState({ modal6: false })}>
            <FormMascotas Suscriptores={this.state.Suscriptores} InmuebleId={this.state.InmuebleId} Direccion={this.state.Direccion}
              DataMascota={this.state.DataMascota} InsertMascota={this.InsertMascota} ActualizarMascota={this.ActualizarMascota}
            />
          </Modal>
          <Modal
            show={this.state.modal7} centered size="lg" backdrop="static" onHide={() => this.setState({ modal7: false })}>
            <FormArrendado InmuebleId={this.state.InmuebleId} Direccion={this.state.Direccion}
              Arrendado={this.state.Inmueble.arrendado} TipoArriendo={this.TipoArriendo}
            />
          </Modal>
          <Modal
            show={this.state.modal8} centered size="lg" backdrop="static" onHide={() => this.setState({ modal8: false })}>
            <SubirCartera onHide={() => this.setState({ modal8: false })} />
          </Modal>


          <div className="row h-100 py-2">

            <div className="col-12 ">
              <div className="g-r-prf3 h-100">
                <div className="col-12 pb-2">
                  <HeadTitle
                    classNameOwn="row align-items-center"
                    titulo="Libro de Propietarios"
                    indicadores={[
                      {
                        titulo: "Inmuebles",
                        valor: this.state.DataPh.inmuebles,
                      },
                      {
                        titulo: "Coeficiente",
                        valor: `${this.state.DataPh.coeficiente}%`,
                        important: true,
                      },
                      {
                        titulo: "Mora",
                        valor: this.state.DataPh.mora,
                        important: true,
                      },
                    ]}
                    actionButton={
                      <div className="col-auto ">
                        <button
                          className="btn btn-primary mx-2"
                          onClick={() => this.setState({ modal8: true })}>
                          <i className="fa-solid fa-money-check-dollar"></i> Cartera
                        </button>
                        <button
                          className="btn btn-primary mx-2"
                          onClick={() => this.setState({ modal2: true })}>
                          <i className="fa-solid fa-gear me-2" /> Morosos
                        </button>
                        <button
                          className="btn btn-primary mx-2"
                          onClick={() => this.setState({ modal3: true })}>
                          <i className="fa-solid fa-car me-2" />{" "}
                          Vehículos
                        </button>
                      </div>
                    }
                  />
                </div>
                {/* <hr className="my-2" /> */}
                <div className="row" style={{ maxHeight: '82vh', height: '82vh' }}>
                  <div className="col-2 h-100 d-inline-block " >
                    <div className="h-100 border border-secondary rounded" >

                      <div className="m-2" style={{ height: "65px" }}>
                        <TextField
                          autoFocus
                          size="small"
                          fullWidth
                          margin="normal"
                          placeholder="Buscar inmueble"
                          variant="outlined"
                          defaultValue={this.state.Busqueda}
                          onChange={this.handleChange("Busqueda")}
                        />
                      </div>
                      <hr className="my-1" />
                      <div className="h-100 position-relative align-items-start overflow-x-hidden custom-scroll" style={{ maxHeight: 'calc(100% - 90px)' }}>
                        <div className="w-100 h-100" style={{ padding: "1em 0 0 0.8em" }}>
                          {this.state.Inmuebles.map((Inmueble, indice) => {
                            return (
                              <div className="w-100 d-flex text-left align-items-center" style={{ cursor: "pointer" }} key={indice}
                                onClick={() => {
                                  this.BuscarDataInmueble(
                                    Inmueble.inmuebleid,
                                    Inmueble.direccion.toUpperCase()
                                  );
                                  // this.setState({ offC1: false })
                                }}>
                                <span className="d-block" style={{ fontSize: "0.875rem", cursor: "pointer" }}>
                                  {`${indice + 1}. ${Inmueble.direccion.toUpperCase()}`}
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-10 h-100 d-inline-block" >

                    {this.state.DataInmueble ? (
                      <div className="h-100 position-relative border border-secondary rounded overflow-x-hidden custom-scroll">
                        <div className="col-12" style={{ padding: "12px" }}>
                          <div className="row align-items-center mb-3">
                            <div className="col-auto pe-0">
                              <Avatar className="bg-primary text-light" sx={{ width: 25, height: 25 }} >
                                <i className="fa-solid fa-house" style={{ fontSize: 12 }} />
                              </Avatar>
                            </div>
                            <div className="col-auto">
                              <h6 className="mb-0">{`${this.state.Direccion} - COEFICIENTE ${this.state.Inmueble.coeficiente}%`}</h6>
                            </div>

                            <div className="col-auto">
                              <button value={this.state.Inmueble.arrendado} onClick={() => { this.setState({ modal7: true }) }} className="bg-primary text-light px-2 rounded">
                                {this.state.NombreArriendo}
                              </button>
                            </div>



                            {this.state.Inmueble.mora ? (
                              <div className="col-auto">
                                <div className="bg-danger text-light px-2 rounded">
                                  en Mora
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div style={{ maxWidth: "98%" }}>
                          <div className="m-3 col-12" >
                            <MaterialTable
                              columns={columnsSusc}
                              data={this.state.Suscriptores}
                              title="Residentes"
                              actions={[{ onClick: (event, rowData) => { this.CambiaInmueble(rowData) } }]}
                              components={{
                                Action: (props) => (
                                  ((this.state.asambleaopen === false && parseInt(props.data.estadoint) === 1) || (this.state.asambleaopen === false && parseInt(props.data.estadoint) === 0 && props.data.email !== "NA" && props.data.telefono !== "NA" && props.data.tipo_doc !== "NA")) ? (
                                    <div
                                      className="row me-1 pb-3 pt-2"
                                      style={{ width: "6rem" }}
                                    >
                                      <div className="col-6">
                                        <Fab
                                          onClick={(event) =>
                                            props.action.onClick(event, props.data)
                                          }
                                          color="primary"
                                          aria-label="edit"
                                          size="small"
                                        >
                                          <i className="fas fa-edit" />
                                        </Fab>
                                      </div>
                                    </div>
                                  ) : ("")
                                ),
                              }}

                              options={{
                                pageSize:
                                  this.state.Suscriptores.length < 5
                                    ? this.state.Suscriptores.length
                                    : 4,
                                pageSizeOptions: [
                                  this.state.Vehiculos.length < 5
                                    ? this.state.Vehiculos.length
                                    : 4,
                                ],
                                search: true,
                                paging:
                                  this.state.Suscriptores.length >= 5
                                    ? true
                                    : false,
                                actionsColumnIndex: -1,
                                rowStyle: (rowData) => {
                                  if (
                                    parseInt(rowData.tableData.id + 1) % 2 ===
                                    0
                                  ) {
                                    return {
                                      backgroundColor: "#eeeeee",
                                      fontSize: "0.9rem",
                                    };
                                  }
                                  return {
                                    backgroundColor: "#ffffff",
                                    fontSize: "0.9rem",
                                  };
                                },
                              }}
                              localization={Localization}
                            />
                          </div>
                          <div className="m-3 col-12">
                            <MaterialTable
                              columns={columnsVehiculos}
                              className="shadow-none"
                              data={this.state.Vehiculos}
                              title={
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <h5 className="mb-0 me-3">{`Vehículos`}</h5>
                                  </div>
                                  <div className="col-auto ps-0">
                                    <Fab
                                      disabled={
                                        this.state.Roles.includes(
                                          Global.GlobalUser.rol
                                        )
                                          ? false
                                          : true
                                      }
                                      onClick={() =>
                                        this.formularioVehiculo({
                                          caso: 1,
                                          placa: "",
                                          tipo: "",
                                          marca: "",
                                          color: "",
                                          susc_id: "",
                                        })
                                      }
                                      color="secondary"
                                      className="shadow-none"
                                      size="small"
                                      aria-label="edit"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Fab>
                                  </div>
                                </div>
                              }
                              actions={[
                                {
                                  onClick: (event, rowData) => {
                                    rowData.caso = 2;
                                    this.formularioVehiculo(rowData);
                                  },
                                  onClickDelet: (event, rowData) => {
                                    this.EliminarVehiculo(
                                      rowData.id,
                                      rowData.placa
                                    );
                                  },
                                },
                              ]}
                              components={{
                                Action: (props) => (
                                  <div
                                    className="row me-1 pb-3 pt-2"
                                    style={{ width: "6rem" }}
                                  >
                                    <div className="col-6">
                                      <Fab
                                        onClick={(event) =>
                                          props.action.onClick(event, props.data)
                                        }
                                        color="primary"
                                        aria-label="edit"
                                        size="small"
                                      >
                                        <i className="fas fa-edit" />
                                      </Fab>
                                    </div>
                                    <div className="col-6">
                                      <Fab
                                        className=" bg-danger"
                                        onClick={(event) =>
                                          props.action.onClickDelet(
                                            event,
                                            props.data
                                          )
                                        }
                                        aria-label="edit"
                                        size="small"
                                      >
                                        <i
                                          className="fas fa-trash-can"
                                          style={{ color: "white" }}
                                        />
                                      </Fab>
                                    </div>
                                  </div>
                                ),
                              }}
                              options={{
                                pageSize:
                                  this.state.Vehiculos.length < 5
                                    ? this.state.Vehiculos.length
                                    : 4,
                                pageSizeOptions: [
                                  this.state.Vehiculos.length < 5
                                    ? this.state.Vehiculos.length
                                    : 4,
                                ],
                                search: false,
                                paging:
                                  this.state.Vehiculos.length >= 5 ? true : false,
                                actionsColumnIndex: -1,
                                rowStyle: (rowData) => {
                                  if (
                                    parseInt(rowData.tableData.id + 1) % 2 ===
                                    0
                                  ) {
                                    return {
                                      backgroundColor: "#eeeeee",
                                      fontSize: "0.9rem",
                                    };
                                  }
                                  return {
                                    backgroundColor: "#ffffff",
                                    fontSize: "0.9rem",
                                  };
                                },
                              }}
                              localization={Localization}
                            />
                          </div>
                          <div className="m-3 col-12">
                            <MaterialTable
                              className="shadow-none"
                              columns={columnsHabitantes}
                              data={this.state.Habitantes}
                              title={
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <h5 className="mb-0 me-3">{`Habitantes`}</h5>
                                  </div>
                                  <div className="col-auto ps-0">
                                    <Fab disabled={this.state.Roles.includes(Global.GlobalUser.rol) ? false : true}
                                      onClick={() =>
                                        this.formularioHabitantes({
                                          caso: 1,
                                          correo: "",
                                          documento: "",
                                          estado: "",
                                          fecha_creacion: "",
                                          id_habitante: "",
                                          nombre: "",
                                          telefono: "",
                                          tipo_documento: "",
                                          susc_id: "",
                                        })
                                      }
                                      color="secondary"
                                      className="shadow-none"
                                      size="small"
                                      aria-label="edit"
                                    > <i className="fas fa-plus"></i>
                                    </Fab>
                                  </div>
                                </div>
                              }
                              actions={[
                                {
                                  onClick: (event, rowData) => {
                                    rowData.caso = 2;
                                    this.formularioHabitantes(rowData);
                                  },
                                  onClickDelet: (event, rowData) => {
                                    this.EliminarHabitante(rowData);
                                  },
                                },
                              ]}
                              components={{
                                Action: (props) => (
                                  <div
                                    className="row me-1 pb-3 pt-2"
                                    style={{ width: "6rem" }}
                                  >
                                    <div className="col-6">
                                      <Fab
                                        onClick={(event) =>
                                          props.action.onClick(event, props.data)
                                        }
                                        color="primary"
                                        aria-label="edit"
                                        size="small"
                                      >
                                        <i className="fas fa-edit" />
                                      </Fab>
                                    </div>
                                    <div className="col-6">
                                      <Fab
                                        className=" bg-danger"
                                        onClick={(event) =>
                                          props.action.onClickDelet(
                                            event,
                                            props.data
                                          )
                                        }
                                        aria-label="edit"
                                        size="small"
                                      >
                                        <i
                                          className="fas fa-trash-can"
                                          style={{ color: "white" }}
                                        />
                                      </Fab>
                                    </div>
                                  </div>
                                ),
                              }}
                              options={{
                                pageSize:
                                  this.state.Habitantes.length < 5
                                    ? this.state.Habitantes.length
                                    : 4,
                                pageSizeOptions: [
                                  this.state.Habitantes.length < 5
                                    ? this.state.Habitantes.length
                                    : 4,
                                ],
                                search: false,
                                paging:
                                  this.state.Habitantes.length >= 5 ? true : false,
                                actionsColumnIndex: -1,
                                rowStyle: (rowData) => {
                                  if (
                                    parseInt(rowData.tableData.id + 1) % 2 ===
                                    0
                                  ) {
                                    return {
                                      backgroundColor: "#eeeeee",
                                      fontSize: "0.9rem",
                                    };
                                  }
                                  return {
                                    backgroundColor: "#ffffff",
                                    fontSize: "0.9rem",
                                  };
                                },
                              }}
                              localization={Localization}
                            />
                          </div>
                          <div className="m-3 col-12">
                            <MaterialTable
                              className="shadow-none"
                              columns={columnsMascotas}
                              data={this.state.Mascotas}
                              title={
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <h5 className="mb-0 me-3">{`Mascotas`}</h5>
                                  </div>
                                  <div className="col-auto ps-0">
                                    <Fab
                                      disabled={
                                        this.state.Roles.includes(
                                          Global.GlobalUser.rol
                                        )
                                          ? false
                                          : true
                                      }
                                      onClick={() =>
                                        this.formularioMascotas({
                                          caso: 1,
                                          estado: "",
                                          fecha_creacion: "",
                                          id_mascota: "",
                                          nombre: "",
                                          peligro: false,
                                          susc_id: "",
                                          tipo_mascota: "",
                                        })
                                      }
                                      color="secondary"
                                      className="shadow-none"
                                      size="small"
                                      aria-label="edit"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Fab>
                                  </div>
                                </div>
                              }
                              actions={[
                                {
                                  onClick: (event, rowData) => {
                                    rowData.caso = 2;
                                    this.formularioMascotas(rowData);
                                  },
                                  onClickDelet: (event, rowData) => {
                                    this.EliminarMascota(rowData);
                                  },
                                },
                              ]}
                              components={{
                                Action: (props) => (
                                  <div
                                    className="row me-1 pb-3 pt-2"
                                    style={{ width: "6rem" }}
                                  >
                                    <div className="col-6">
                                      <Fab
                                        onClick={(event) =>
                                          props.action.onClick(event, props.data)
                                        }
                                        color="primary"
                                        aria-label="edit"
                                        size="small"
                                      >
                                        <i className="fas fa-edit" />
                                      </Fab>
                                    </div>
                                    <div className="col-6">
                                      <Fab
                                        className=" bg-danger"
                                        onClick={(event) =>
                                          props.action.onClickDelet(
                                            event,
                                            props.data
                                          )
                                        }
                                        aria-label="edit"
                                        size="small"
                                      >
                                        <i
                                          className="fas fa-trash-can"
                                          style={{ color: "white" }}
                                        />
                                      </Fab>
                                    </div>
                                  </div>
                                ),
                              }}
                              options={{
                                pageSize:
                                  this.state.Mascotas.length < 5
                                    ? this.state.Mascotas.length
                                    : 4,
                                pageSizeOptions: [
                                  this.state.Mascotas.length < 5
                                    ? this.state.Mascotas.length
                                    : 4,
                                ],
                                search: false,
                                paging:
                                  this.state.Mascotas.length >= 5 ? true : false,
                                actionsColumnIndex: -1,
                                rowStyle: (rowData) => {
                                  if (
                                    parseInt(rowData.tableData.id + 1) % 2 ===
                                    0
                                  ) {
                                    return {
                                      backgroundColor: "#eeeeee",
                                      fontSize: "0.9rem",
                                    };
                                  }
                                  return {
                                    backgroundColor: "#ffffff",
                                    fontSize: "0.9rem",
                                  };
                                },
                              }}
                              localization={Localization}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="h-100 border border-secondary rounded">

                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
