import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Global from '../../../../Global'
import { CookieSesion } from "../../../Helpers";
import { Redirect } from "react-router-dom";
import { ValidarRolPagina } from "../../../Helpers";
import { CsvGlobal, PdfGlobal } from '../pdfGlobal';
import FdataPdf from './../pdfGlobal';
import { Modal } from 'react-bootstrap';
import PpHourglass from '../PopUps/PpHourglass';

export default class NavbarInformes extends Component {

    state = {
        redirect_home: false,
        redirect_login: 0,
        redirect_homeAsamblea: false,
        ppHG: false,
    }

    componentWillMount() {
        var login = CookieSesion();
        var userid = Global.GlobalUser.userid;
        this.iniciarDatosPdf()

        this.setState({ redirect_homeAsamblea: ValidarRolPagina('NavbarInformes') })

        if (parseInt(login) > 0) {
            if (parseInt(userid) > 0) {
            } else {
                this.setState({ redirect_login: 1 });
            }
        } else {
            this.setState({ redirect_login: 1 });
        }
    }

    DescargarPdfs(){
        return new Promise ( res => {
            this.setState({
                ppHG: true
            })

           setTimeout(()=>{
            PdfGlobal.pdf1() 
            PdfGlobal.pdf2()
            PdfGlobal.pdf3()
            PdfGlobal.pdf4()
            PdfGlobal.pdf5()

            CsvGlobal.csv1()
            CsvGlobal.csv2()
            CsvGlobal.csv3()
            CsvGlobal.csv4()

            this.setState({
                ppHG: false
            })

           }, 1000)
        })
    }

    async Hourglass () {
        await this.DescargarPdfs()
    }

    iniciarDatosPdf(){
        return (
            FdataPdf.ResetVar(),
            FdataPdf.Asistencias(),
            FdataPdf.poderes(),
            FdataPdf.preguntas(),
            FdataPdf.Ausentes()
        )
    }

    render() {
        if (this.state.redirect_homeAsamblea === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-asamblea"} />;
        }

        if (this.state.redirect_login === 1) {
            return <Redirect to={Global.Ruta.Raiz + "/"} />;
        }

        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-asamblea"} />;
        }

        return (
            <div>
                <Modal show={this.state.ppHG} backdrop='static' onHide={() => this.setState({ppHG: false})}>
                    <PpHourglass/>
                </Modal>

                <nav className="navbar bg-secondary justify-content-center"><h5 className="text-light m-0 pb-0">{this.props.titulo}</h5></nav>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-2">
                    <NavLink className="navbar-brand" exact to={{ pathname: Global.Ruta.Raiz + "/home-asamblea" }}> <i className="fa fa-chevron-left" /> Regresar</NavLink>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" exact to={Global.Ruta.Raiz + "/info-asmablea-asistentes"} >Asistentes</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact to={Global.Ruta.Raiz + "/info-asmablea-poderes"} >Representaciones</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact to={Global.Ruta.Raiz + "/info-asmablea-preguntas"} >Preguntas</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact to={Global.Ruta.Raiz + "/info-asmablea-ausentes"} >Ausentes</NavLink>
                            </li>
                        </ul>
                        <p className="navbar-brand m-0 p-0 px-2">{Global.GlobalUser.Nombre}</p>
                    </div>
                    <button className="btn btn-secondary mx-2" onClick={()=> { this.Hourglass()}}>Informe <i className="fas fa-download"/></button>
                </nav>
            </div>
        )

    }
}
