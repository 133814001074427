import React, {Component} from "react";
import {Modal, Form} from "react-bootstrap";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import {Redirect} from "react-router-dom";
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from "simple-react-validator";

export default class PlacaVehiculo extends Component {
  state = {
    modal: false,

    placa: "",
    Direccion: "",

    redirect_home: false,
  };

  //VALIDA EL ESTADO
  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: "El campo es requerido.",
        alpha_dash: "El campo es requerido.",
        alpha_num: "Solo se permiten letras y números.",
        min: "La Placa debe tener mínimo 5 caracteres.",
      },
    });
  }

  //GUARDA LOS NUEVOS DATOS
  Consultar = () => {
    if (this.validator.allValid()) {
      this.setState({modal: true});

      var body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        placa: Buffer.from("" + this.state.placa).toString("base64"),
      };
      axios
        .post(`${Global.Ruta.Url}admin/searchvehiculo/`, body)
        .then((res) => {
          if (res.data.message === "") {
            this.setState({
              modal: false,
              Direccion:
                res.data.Direccion.length > 0
                  ? res.data.Direccion[0]["direccion"]
                  : "El vahículo no se encuentra registrado a ningún inmueble.",
            });
          } else {
            swal({
              title: "Atención !!",
              text: res.data.message,
              icon: "warning",
              button: "Aceptar",
            }).then(() => {
              this.setState({modal: false});
            });
          }
        })
        .catch((err) => {
          swal({
            title: "ALERTA!",
            text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then((resp) => {
            this.setState({modal: false});
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value.toUpperCase(),
    }));
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    return (
      <div>
        <Modal
          show={this.state.modal}
          centered
          backdrop="static"
          onHide={() => this.setState({modal: false})}
        >
          <PpHourglass />
        </Modal>
        <Modal.Header closeButton>Buscar Vehículo</Modal.Header>
        <Modal.Body>
          <div className="row gy-3 align-items-center justify-content-center">
            <div className="col-10">
              <label htmlFor="nombre">Placa</label>
              <Form.Control
                onChange={this.handleChange("placa")}
                maxLength={10}
                value={this.state.placa}
                placeholder={this.state.placa}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.Consultar();
                  }
                }}
              />
              <label
                className="text-muted text-capitalize m-0 px-1 txt-helper"
                style={{fontSize: "11px"}}
              >
                {this.validator.message(
                  "placa",
                  this.state.placa,
                  "required|alpha_num|min:5",
                  {className: "text-danger"}
                )}
              </label>
            </div>
            <div className="col-auto">
              {this.state.Direccion !== "" ? (
                <div className='card p-2 bg-secondary bg-opacity-75 shadow'>
                  <p className="m-0 fw-normal text-light">{this.state.Direccion}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100 justify-content-center">
            <div className="col-3 d-grid">
              <button className="btn btn-primary" onClick={this.Consultar}>
                Buscar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
