import React, { Component } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { Autocomplete, TextField } from "@mui/material";
import Global from '../../../../Global';
import axios from "axios";
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from 'simple-react-validator'
import { MenuItem, } from '@material-ui/core'

const estado = [
    {
        value: "1",
        label: "Activo",
    },
    {
        value: "0",
        label: "Inactivo",
    },
];
const Tipo_Documento = [{ value: 1, label: "Cédula" }, { value: 2, label: "Tarjeta de Identidad" }, { value: 3, label: "Cédula Extranjera" }];

export default class CambiaInmueble extends Component {

    state = {
        modal: false,
        tipo: "",
        inmuebleid: "",
        direccion: "",
        Tipo: [{ id: 1, tipo: "Propietario" }, { id: 2, tipo: "Propietario Residente" }, { id: 3, tipo: "Residente" }],
        tipo_doc: "",
        documento: this.props.Suscriptor.documento,
        Inmuebles: [],
        redirect_home: false,
        Suscriptor: this.props.Suscriptor,
        asambleaopen: false,
        tab: "0",
        estado: this.props.Suscriptor.estadoint,
    }

    componentWillMount() {
        this.BuscaInmueble()
        this.validator = new SimpleReactValidator({
            messages: { required: 'El campo es requerido.' }
        });

        if (parseInt(this.props.Suscriptor.tipo_doc) !== 0) {
            this.setState({
                tipo_doc: this.props.Suscriptor.tipo_doc
            })
        }


    }

    SelectTap = (opc) => {
        this.setState({
            tab: opc,
        });
    }

    BuscaInmueble = () => {
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        };

        axios
            .post(`${Global.Ruta.Url}admin/listinm`, body)
            .then((res) => {
                if (res.data.message === "") {
                    this.setState({
                        Inmuebles: res.data.Inmuebles,
                    }); this.ActualizaInmuebles()
                } else {

                }
            })
            .catch((err) => {
                swal({
                    title: "ERROR!",
                    text: "Por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({
                        redirect_home: true,
                    });
                });
            });
    };

    ActualizaInmuebles = () => {
        var tem = this.state.Inmuebles;
        var index = this.BuscarIndexInmueble(this.state.Suscriptor.inmuebleid, tem);
        tem.splice(index, 1);
        this.setState({
            Inmuebles: tem,
        });
    }

    //BUSCA EL ITEM DEL INMUEBLE
    BuscarIndexInmueble = (PresId, temRows) => {
        // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
        const findIndexArray = (element) =>
            parseInt(element.inmuebleid) === parseInt(PresId);
        return temRows.findIndex(findIndexArray);
    };

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value.toUpperCase()
        }));
    }

    BuscaDireccion = (value) => {
        this.setState({ inmuebleid: value.inmuebleid, direccion: value.direccion })
    }

    ActualizarInmueble = () => {
        if (this.validator.fieldValid('Inmueble') && this.validator.fieldValid('Tipo_Residente') ) {
            this.setState({ modal: true })
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                suscriptorid: Buffer.from("" + this.state.Suscriptor.suscriptorid).toString("base64"),
                tipo: Buffer.from("" + this.state.tipo).toString("base64"),
                inmueble_id: Buffer.from("" + this.state.inmuebleid).toString("base64"),
            };

            axios.post(`${Global.Ruta.Url}admin/SuscriptorCambioInmueble`, body)
                .then((res) => {
                    if (res.data.message === "") {
                        swal({
                            title: "Atención!",
                            text: `Usuario ${this.state.Suscriptor.nombres.toUpperCase()} ha sido cambiado al inmueble ${this.state.direccion}`,
                            icon: "success",
                            button: "Aceptar",
                        })
                        this.setState({ modal: false });
                        this.props.Cerrar()
                    } else {
                        swal({
                            title: "ERROR!",
                            text: `${res.data.message}`,
                            icon: "error",
                            button: "Aceptar",
                        }).then(() => {
                            this.setState({
                                modal: false,
                            });
                        });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ERROR!",
                        text: "Por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({
                            redirect_home: true,
                            modal: false,
                        });
                    });
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    ActualizarEstado = () => {
        if (this.validator.fieldValid('documento') && this.validator.fieldValid('tipo_doc') ) {
            this.setState({ modal: true })
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                id_susc: Buffer.from("" + this.state.Suscriptor.suscriptorid).toString("base64"),
                id_inmueble: Buffer.from("" + this.state.Suscriptor.inmuebleid).toString("base64"),
                estado: Buffer.from("" + this.state.estado).toString("base64"),
                documento: Buffer.from("" + this.state.documento).toString("base64"),
                tipo_documento: Buffer.from("" + this.state.tipo_doc).toString("base64"),
            };

            axios.post(`${Global.Ruta.Url}admin/estadosuscriptor`, body)
                .then((res) => {
                    if (res.data.message === "") {
                        swal({
                            title: "Atención!",
                            text: `Usuario ${this.state.Suscriptor.nombres.toUpperCase()} Modificado`,
                            icon: "success",
                            button: "Aceptar",
                        })
                        this.setState({ modal: false });
                        var suscriptor = {
                            id_susc: this.state.Suscriptor.suscriptorid,
                            estado: this.state.estado,
                            documento:this.state.documento,
                            tipo_doc:this.state.tipo_doc
                        }
                        this.props.ActualizarEstadoSuscriptor(suscriptor)
                    } else {
                        swal({
                            title: "ERROR!",
                            text: `${res.data.message}`,
                            icon: "error",
                            button: "Aceptar",
                        }).then(() => {
                            this.setState({
                                modal: false,
                            });
                        });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ERROR!",
                        text: "Por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({
                            redirect_home: true,
                            modal: false,
                        });
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    Combobox = (prop) => (e) => {
        this.setState({
            [prop]: e.target.value,
        });
    };

    render() {

        if (this.state.redirect_home === true) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
            )
        }

        return (
            <div>
                <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                    <PpHourglass />
                </Modal>
                <Modal.Header closeButton>
                    {parseInt(this.state.tab) === 0 ? (`Edita al Usuario ${this.state.Suscriptor.nombres.toUpperCase()}`) : (`Cambio de Inmueble al Usuario ${this.state.Suscriptor.nombres.toUpperCase()}`)}

                </Modal.Header>
                <Modal.Body>
                    <Tabs activeKey={this.state.tab} onSelect={this.SelectTap}
                        id="tabs-reservas" className="bg-primary bg-opacity-75 rounded-top" justify >
                        <Tab eventKey="0" title="Editar" tabClassName="link-light border-light h-100">

                            <div className="col py-4">

                                <div className="col" >
                                    <TextField size="small" className="shadow-sm" id="tipo_doc" select fullWidth label="Tipo Documento" defaultValue={this.state.tipo_doc}
                                        value={this.state.tipo_doc} onChange={this.Combobox("tipo_doc")} variant="outlined" >
                                        {Tipo_Documento.map((option) => (
                                            <MenuItem key={option.value} value={option.value} >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('tipo_doc', this.state.tipo_doc, 'required', { className: 'text-danger' })}</label>
                                </div>

                                <div className="col" >
                                    <TextField size="small" className="shadow-sm" id="documento" fullWidth label="Documento" defaultValue={this.state.documento}
                                        value={this.state.documento} onChange={this.Combobox("documento")} variant="outlined" >
                                        <MenuItem value={this.state.documento} >
                                            {this.state.documento}
                                        </MenuItem>
                                    </TextField>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('documento', this.state.documento, 'required', { className: 'text-danger' })}</label>
                                </div>

                                <div className="col ">
                                    <TextField size="small" className="shadow-sm" id="estado"
                                        select fullWidth label="Estado" defaultValue={this.state.estado}
                                        value={this.state.estado} onChange={this.Combobox("estado")} variant="outlined" >
                                        {estado.map((option) => (
                                            <MenuItem key={option.value} value={option.value} >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <p className="m-0 ms-2 text-muted" style={{ fontSize: 12 }} >
                                        Seleccione el estado de la cuenta
                                    </p>
                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="1" title="Cambiar Inmueble" tabClassName="link-light border-light h-100">
                            {this.state.Inmuebles.length > 0 ? (
                                <div className="row row-cols-2  py-4">
                                    <div className="col-12">
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            id="combo-box-demo"
                                            options={this.state.Inmuebles}
                                            getOptionLabel={(option) => option.direccion}
                                            onChange={(event, value) => this.BuscaDireccion(value)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Nueva Dirección" />
                                            )}
                                        />
                                        <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Inmueble', this.state.inmuebleid, 'required', { className: 'text-danger' })}</label>
                                    </div>
                                    <div className="col-12">
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            id="combo-box-demo"
                                            options={this.state.Tipo}
                                            getOptionLabel={(option) => option.tipo}
                                            onChange={(event, value) => this.setState({ tipo: value.id })}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Tipo Residente" />
                                            )}
                                        />
                                        <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Tipo_Residente', this.state.tipo, 'required', { className: 'text-danger' })}</label>
                                    </div>
                                </div>) : (<div className="d-flex justify-content-center flex-column align-items-center text-center">
                                    <div className="spinner-grow text-info" role="status" style={{ width: '5rem', height: '5rem' }} />
                                    <span className="my-2 ">Espere un momento...</span>
                                </div>)}
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 justify-content-center">
                        <div className="col-3 d-grid">
                            <button className="btn btn-primary" onClick={(parseInt(this.state.tab) === 1) ? (this.ActualizarInmueble) : (this.ActualizarEstado)}>Guardar</button>
                        </div>
                    </div>
                </Modal.Footer>
            </div >
        )
    }
}
