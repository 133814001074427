import React, { Component } from 'react'

import NavbarInformes from './NavbarInformes'
import TablaInfoAsistentes from './TablasInformes/TablaInfoAsistentes'

export default class InformesAsistencia extends Component {

    state = {
        titulo: "",
    }

    componentWillMount() {
        
        this.setState({
            titulo: `Informe ASISTENCIA`
        })
        //COMO MANDAR PROPS SIN SER COMPONENTE HIJO
        //console.log(this.props.location.state.id)
    }


    stateTotalAsistentes = (total) => {
        this.setState({
            titulo: `Informe ASISTENCIA - Total de asistentes: ${total}`
        })
    }

    render() {
        return (
            <div className="g-r-prf3 bg-light vh-100">
                <NavbarInformes titulo={this.state.titulo} />
                <TablaInfoAsistentes TotalAsistentes={this.stateTotalAsistentes} />
                 
            </div>
        )
    }
}
