import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap';
import Global from '../../../../Global';
import axios from "axios";
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from 'simple-react-validator'

export default class FormHabitantes extends Component {

    state = {
        Tipo: [{ id: 1, tipo: "Cédula" }, { id: 2, tipo: "Tarjeta de Identidad" }, { id: 3, tipo: "Cédula Extranjera" }],
        Estado: [{ id: 1, tipo: "Activo" }, { id: 2, tipo: "Inactivo" }],
        modal: false,
        caso: this.props.DataHabitante.caso,
        correo: this.props.DataHabitante.correo,
        documento: this.props.DataHabitante.documento,
        estado: this.props.DataHabitante.estado,
        fecha_creacion: this.props.DataHabitante.fecha_creacion,
        id_habitante: this.props.DataHabitante.id_habitante,
        nombre: this.props.DataHabitante.nombre.toUpperCase(),
        telefono: this.props.DataHabitante.telefono,
        tipo_documento: this.props.DataHabitante.tipo_documento,
        Direccion: this.props.Direccion,
        InmuebleId: this.props.InmuebleId,
        Suscriptores: this.props.Suscriptores,
        susc_id: this.props.DataHabitante.susc_id,

    }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'solo puede ingresar numeros enteros',
            }
        });

    }

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value
        }));
    }

    GuardarInsert = () => {
        if (this.validator.allValid()) {
            this.setState({ modal: true });
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
                suscriptor_id: Buffer.from("" + this.state.susc_id).toString("base64"),
                nombre: Buffer.from("" + this.state.nombre).toString("base64"),
                tipo_doc: Buffer.from("" + this.state.tipo_documento).toString("base64"),
                documento: Buffer.from("" + this.state.documento).toString("base64"),
                telefono: Buffer.from("" + this.state.telefono).toString("base64"),
                correo: Buffer.from("" + this.state.correo).toString("base64"),
            };
            axios
                .post(`${Global.Ruta.Url}admin/creahabitante/`, body)
                .then((res) => {
                    if (res.data.message === "") {
                        swal({
                            title: "ATENCIÓN!",
                            text: `Habitante ${(this.state.nombre).toUpperCase()} Ingresado`,
                            icon: "success",
                            button: "Aceptar",
                        })
                        var newhabitante = {
                            correo: this.state.correo,
                            documento: this.state.documento.toUpperCase(),
                            estado: 1,
                            fecha_creacion: this.state.fecha_creacion,
                            id_habitante: res.data.id_habitante,
                            nombre: (this.state.nombre).toUpperCase(),
                            telefono: this.state.telefono,
                            tipo_documento: this.state.tipo_documento,
                            susc_id: this.state.susc_id
                        }
                        this.setState({ modal: false });
                        this.props.InsertHabitante(newhabitante);
                    } else {
                        swal({
                            title: "ATENCIÓN!",
                            text: `${res.data.messaje}`,
                            icon: "error",
                            button: "Aceptar",
                        })
                        this.setState({ modal: false });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ATENCIÓN!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ redirect_suscriptores: true });
                    });
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    GuardarUpdate = () => {
        if (this.validator.allValid()) {

            this.setState({ modal: true });
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
                suscriptor_id: Buffer.from("" + this.state.susc_id).toString("base64"),
                habitante_id:Buffer.from("" + this.state.id_habitante).toString("base64"),
                nombre: Buffer.from("" + this.state.nombre).toString("base64"),
                tipo_doc: Buffer.from("" + this.state.tipo_documento).toString("base64"),
                documento: Buffer.from("" + this.state.documento).toString("base64"),
                telefono: Buffer.from("" + this.state.telefono).toString("base64"),
                correo: Buffer.from("" + this.state.correo).toString("base64"),
                estado:Buffer.from("" + this.state.estado).toString("base64"),
            };
            axios
                .post(`${Global.Ruta.Url}admin/editahabitante/`, body)
                .then((res) => {
                    if (res.data.message === "") {
                        swal({
                            title: "ATENCIÓN!",
                            text: `Habitante ${(this.state.nombre).toUpperCase()} Actualizado`,
                            icon: "success",
                            button: "Aceptar",
                        })
                        var updatehabitante = {
                            correo: this.state.correo,
                            documento: this.state.documento.toUpperCase(),
                            estado: this.state.estado,
                            fecha_creacion: this.state.fecha_creacion,
                            id_habitante:this.state.id_habitante,
                            nombre: (this.state.nombre).toUpperCase(),
                            telefono: this.state.telefono,
                            tipo_documento: this.state.tipo_documento,
                            susc_id: this.state.susc_id
                        }
                        this.setState({ modal: false });
                        this.props.ActualizarHabitante(updatehabitante);
                    } else {
                        swal({
                            title: "ATENCIÓN!",
                            text: `${res.data.messaje}`,
                            icon: "error",
                            button: "Aceptar",
                        })
                        this.setState({ modal: false });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ATENCIÓN!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ redirect_suscriptores: true });
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    render() {

        if (this.state.redirect_home === true) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
            )
        }

        return (
            <div>
                <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                    <PpHourglass />
                </Modal>
                <Modal.Header closeButton>
                    {parseInt(this.state.caso) === 1 ? (`Nuevo Habitante ${this.state.Direccion.toUpperCase()}`) : (`Editar Habitante ${this.props.DataHabitante.nombre.toUpperCase()}`)}
                </Modal.Header>
                <Modal.Body>

                    {this.state.Suscriptores.length > 0 ? (
                        <div className="row row-cols-2 ">

                            <div className="col">
                                <label htmlFor="nombre">Nombre</label>
                                <Form.Control onChange={this.handleChange("nombre")} maxLength={40} value={this.state.nombre.toUpperCase()} />
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('nombre', this.state.nombre, 'required', { className: 'text-danger' })}</label>
                            </div>
                            <div className="col">
                                <label >Telefono</label>
                                <Form.Control type='number' onChange={(e) => this.setState({ telefono: e.target.value })} maxLength={40} value={this.state.telefono} placeholder={this.state.telefono} />
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('telefono', this.state.telefono, 'integer|numeric|min:0,num', { className: 'text-danger' })}</label>
                            </div>
                            <div className="col">
                                <Form.Group>
                                    <label htmlFor="docummento">Tipo Documento</label>
                                    <Form.Select value={this.state.tipo_documento} onChange={this.handleChange("tipo_documento")}>
                                        {parseInt(this.state.caso) === 1 ? (<option value="-1">OPCIÓN...</option>) : ("")}
                                        {this.state.Tipo.map((data, indice) => (
                                            <option key={indice} value={data.id}>{data.tipo.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Tipo Documento', this.state.tipo_documento, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <label >Documento</label>
                                <Form.Control onChange={this.handleChange("documento")} maxLength={40} value={(this.state.documento.toUpperCase())} placeholder={this.state.documento} />
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Documento', this.state.documento, 'required|alpha_num', { className: 'text-danger' })}</label>
                            </div>
                            <div className="col">
                                <label>Correo</label>
                                <Form.Control onChange={this.handleChange("correo")} maxLength={40} value={this.state.correo.toLowerCase()} placeholder={this.state.correo.toLowerCase()} />
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Correo', this.state.correo, 'email', { className: 'text-danger' })}</label>
                            </div>
                            {parseInt(this.state.caso) === 2 ? (
                                <div className="col">
                                </div>
                            ) : ("")}
                            <div className="col">
                                <Form.Group>
                                    <label >Suscriptor</label>
                                    <Form.Select value={this.state.susc_id} onChange={this.handleChange("susc_id")}>
                                        {parseInt(this.state.caso) === 1 ? (<option value="-1">OPCIÓN...</option>) : ("")}
                                        {this.state.Suscriptores.map((data, indice) => (
                                            <option key={indice} value={data.suscriptorid}>{data.nombres.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Suscriptor', this.state.susc_id, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>
                            {parseInt(this.state.caso) === 2 ? (<div className="col">
                                <Form.Group>
                                    <label >Estado</label>
                                    <Form.Select value={this.state.estado} onChange={this.handleChange("estado")}>
                                        {this.state.Estado.map((data, indice) => (
                                            <option key={indice} value={data.id}>{data.tipo.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Estado', this.state.estado, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>) : ("")}
                        </div>
                    ) : (<div className="d-flex justify-content-center flex-column align-items-center text-center">
                        <div className="spinner-grow text-info" role="status" style={{ width: '5rem', height: '5rem' }} />
                        <span className="my-2 ">Espere un momento...</span>
                    </div>)}
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 justify-content-center">
                        <div className="col-3 d-grid">
                            <button className="btn btn-primary" onClick={(parseInt(this.state.caso) === 1) ? (this.GuardarInsert) : (this.GuardarUpdate)}>Guardar</button>
                        </div>
                    </div>
                </Modal.Footer>
            </div >
        )
    }
}
