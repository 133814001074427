import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap';
import Global from '../../../../Global';
import axios from "axios";
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";


export default class FormArrendado extends Component {

    state = {
        modal: false,
        Tipo: [{ id: 0, arrendado: "Desocupado" }, { id: 1, arrendado: "Arrendado" }, { id: 2, arrendado: "Ocupado por Propietario" }, { id: 3, arrendado: "Vivienda Turística" }],
        tipo: parseInt(this.props.Arrendado),
        Direccion: this.props.Direccion,
        InmuebleId: this.props.InmuebleId,

    }

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value
        }));
    }

    GuardarInsert = () => {
        var tem = this.state.Tipo
        var index = this.state.tipo

        this.setState({ modal: true });
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            inmueble_id: Buffer.from("" + this.state.InmuebleId).toString("base64"),
            arrendado: Buffer.from("" + this.state.tipo).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}admin/arrendadoup/`, body).then((res) => {
            if (res.data.message === "") {
                swal({
                    title: "ATENCIÓN!",
                    text: `Calidad de vivienda ${(tem[index].arrendado).toUpperCase()} Actualizada`,
                    icon: "success",
                    button: "Aceptar",
                })
                this.setState({ modal: false });
                this.props.TipoArriendo(index);
            } else {
                swal({
                    title: "ATENCIÓN!",
                    text: `${res.data.messaje}`,
                    icon: "error",
                    button: "Aceptar",
                })
                this.setState({ modal: false });
            }
        })
            .catch((err) => {
                swal({
                    title: "ATENCIÓN!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ redirect_suscriptores: true });
                });
            });
    }

    render() {

        if (this.state.redirect_home === true) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
            )
        }
        return (
            <div>
                <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                    <PpHourglass />
                </Modal>
                <Modal.Header closeButton>
                    {` ${this.state.Direccion.toUpperCase()}`}
                </Modal.Header>
                <Modal.Body>
                    <div className="row row-cols-2 ">
                        <div className="col-12">
                            <Form.Group>
                                <label htmlFor="vivienda">Calidad de Vivienda</label>
                                <Form.Select value={this.state.tipo} onChange={this.handleChange("tipo")}>
                                    {this.state.Tipo.map((data, indice) => (
                                        <option key={indice} value={data.id}>{data.arrendado.toUpperCase()}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 justify-content-center">
                        <div className="col-3 d-grid">
                            <button className="btn btn-primary" onClick={this.GuardarInsert}>Guardar</button>
                        </div>
                    </div>
                </Modal.Footer>
            </div >
        )
    }
}