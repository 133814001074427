
import MaterialTable from 'material-table';
import moment from 'moment';
import "moment/locale/es";
import React, { Component } from 'react'
import Global from './../../../../Global';
import axios from 'axios';
import swal from 'sweetalert';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Es from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@material-ui/core";
import { Modal } from 'react-bootstrap';
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";

export default class Rplacas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fechaI: '',
      fechaF: '',
      fechaii: '',
      tableHeads: [
        {
          field: "direccion",
          title: "Dirección",
        },
        {
          field: "placa",
          title: "Placa",
        },
        {
          field: "fecha_llega",
          title: "Fecha Entrada",
          //   render: data => moment(data.fecha_llega).format("DD MMM YYYY HH:mm:ss"),
        },
        {
          field: "porteroentrada",
          title: "Portero Entrada",
        },
        {
          field: "fecha_salio",
          title: "Fecha Salida",
          //  render: data => (data.fecha_salio !== null) ? (moment(data.fecha_salio).format("DD MMM YYYY HH:mm:ss")) : (""),
        },
        {
          field: "porterosalida",
          title: "Portero Salida",
        },
        {
          field: "tipo_residente",
          title: "Tipo",
          lookup: { 0: "RESIDENTE", 1: "VISITANTE" },
        },
        {
          field: "tipo_vehiculo",
          title: "Vehículo",
          lookup: { 1: "AUTOMÓVIL", 2: "MOTOCICLETA", 3: "TAXI", 4: "DOMICILIO" },
        },
        {
          field: "estado",
          title: "Estado",
          lookup: { 0: "SALIDA", 1: "INGRESO" },
        },
      ],
      data: [],
      datatable: false,
      btnfechaF: true,
      btnfiltro: true,
      placa: "",
      filtro: true,
      auronlimpia: true,
    }
  }

  componentWillMount() {
    this.BuscaPlaca()
  }

  BuscaPlaca = () => {
    this.setState({
      PpHourglass: true,
    });
    var caso = 0
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      fechai: Buffer.from("").toString("base64"),
      fechaf: Buffer.from("").toString("base64"),
      placa: Buffer.from(this.state.placa).toString("base64"),
      caso: Buffer.from("" + caso).toString("base64"),
    };
    axios.post(`${Global.Ruta.Url}porteria/historialplacasadmin`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          data: res.data.Registros,
          PpHourglass: false,
          datatable: true
        });
      } else {
        this.setState({
          PpHourglass: false,
          datatable: true
        });
      }
    })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  BuscaPlacaF = () => {
    this.setState({
      PpHourglass: true,
      datatable: false
    });
    var Fechai = ""
    var Fechaf = ""

    if (this.state.fechaI !== "" && this.state.fechaF !== "") {
      Fechai = moment(this.state.fechaI).format("YYYY/MM/DD")
      Fechaf = moment(this.state.fechaF).format("YYYY/MM/DD")
    }

    var caso = 1
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      fechai: Buffer.from("" + Fechai).toString("base64"),
      fechaf: Buffer.from("" + Fechaf).toString("base64"),
      placa: Buffer.from(this.state.placa).toString("base64"),
      caso: Buffer.from("" + caso).toString("base64"),
    };
    axios.post(`${Global.Ruta.Url}porteria/historialplacasadmin`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          data: res.data.Registros,
          PpHourglass: false,
          datatable: true
        });
      } else {
        this.setState({
          data: [],
          PpHourglass: false,
          datatable: true
        });
        swal({
          title: "Atención",
          text: res.data.message,
          icon: "error",
          button: "Aceptar",
        })
      }
    })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  }

  QuitarFiltros = () => {
    this.setState({ filtro: false })
    this.BuscaPlaca()
    setTimeout(() => {
      this.setState({
        fechaI: '',
        fechaF: '',
        placa: "",
        filtro: true,
        btnfiltro: true,
        btnfechaF: true,
        auronlimpia: true
      })
    }, 500)
  }


  ActualizaFecha = (caso, date) => {
    if (parseInt(caso) === 1) {
      this.setState({ fechaI: date, btnfechaF: false, auronlimpia: false })
      if (date !== "" && this.state.placa !== "" && this.state.fechaF === "") {
        this.setState({ btnfiltro: true, auronlimpia: false })
      }
    } else if (parseInt(caso) === 2) {
      this.setState({ fechaF: date, btnfiltro: false, auronlimpia: false })
    } else if (parseInt(caso) === 3) {
      this.setState({ placa: date })
      if (date !== "" && this.state.fechaI === "" && this.state.fechaF === "") {
        this.setState({ btnfiltro: false, auronlimpia: false })
      } else if (date === "" && this.state.fechaI === "" && this.state.fechaF === "") {
        this.setState({ btnfiltro: true, auronlimpia: true })
      }
    }
  }


  render() {
    const Tabla = (props) => {
      return (
        <MaterialTable
          title='Placas'
          columns={this.state.tableHeads}
          data={this.state.data}
          options={{
            rowStyle: { fontSize: "0.9rem" },
            pageSize: 10,
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
          }}
          localization={{
            header: {
              actions: "Estado",
            },
            body: {
              emptyDataSourceMessage: "No hay datos por mostrar.",
            },
            pagination: {
              labelRowsSelect: "registros",
              labelDisplayedRows: "{from}-{to} de {count}",
              firstTooltip: "Primera página",
              previousTooltip: "Página anterior",
              nextTooltip: "Próxima página",
              lastTooltip: "Última página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
              exportCSVName: "CSV",
              exportPDFName: "PDF"
            },
          }}
        />
      )
    }

    return (
      <div className='row bg-white shadow rounded-3'>
        <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>
        {/* menu de opciones, depende del tipo de reporte */}

        {this.state.filtro ? (
          <div className='col-12'>
            <div className='row py-3 px-2'>
              <div className="col-12 col-md-4 col-xl-2">
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                  <DatePicker
                    label="Fecha Inicio"
                    value={this.state.fechaI}
                    maxDate={new Date()}
                    onChange={(date) => this.ActualizaFecha(1, date)}
                    renderInput={(params) => (
                      <TextField {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es} >
                  <DatePicker
                    disabled={this.state.btnfechaF}
                    label="Fecha Final"
                    value={this.state.fechaF}
                    minDate={this.state.fechaI}
                    maxDate={new Date()}
                    onChange={(date) => this.ActualizaFecha(2, date)}
                    renderInput={(params) => (
                      <TextField {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <TextField
                  value={this.state.placa}
                  label="Busca por Placa"
                  variant="standard"
                  fullWidth
                  onChange={(e) => this.ActualizaFecha(3, e.target.value.toLocaleUpperCase())}
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" disabled={this.state.btnfiltro} onClick={() => this.BuscaPlacaF()}>
                  Filtrar
                </button>
              </div>
              <div className="col-auto">
                <button className="btn btn-danger" disabled={this.state.auronlimpia} onClick={() => this.QuitarFiltros()}>
                  Limpiar
                </button>
              </div>
            </div>
          </div>
        ) : ("")}


        {/* contenido del reporte */}
        <main className="col-12 px-0">
          {this.state.datatable ? (
            <Tabla />) : (
            <div className="d-flex justify-content-center bg-with text-info flex-column align-items-center min-vh-100">
              <div
                className="spinner-border fs-3 m-3"
                style={{ width: "5rem", height: "5rem" }}
                role="status"></div>
              <p className="fs-3">Cargando...</p>
            </div>
          )}
        </main>
      </div>
    )
  }
}
