import React from "react";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import Global from "../../../Global";
//import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/css/admin.css";

import TarjetaRecurso from "./TarjetaCarrusel";
import PopupRecurso from "./PopupRecurso";
import PopupGrpZona from "./PopupGrpZona";

//-------------------------------------------CODIGO-----------------------------------------

var settings = {
  dots: true,
  infinite: false,
  speed: 600,
  slidesToShow: 3,
  slidesToScroll: 3,
  className: "carruselAlpha col-12 pb-2 h-100",
  adaptiveHeight: true,
  variableWidth: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
  ],
};

class SimpleSlider extends React.Component {
  state = {
    modalShow1: false,
    modalShow: false,

    Nombre: "",
    Imagenid: "",
    grupo: "",
    estado: "",
    recursos: [],
    DataGrp: {},
    Zona: {
      aprobacion: 0,
      cantresem: 0,
      descripcion: "",
      dia_mantenimiento: "",
      disponible: 1,
      estado: 1,
      festivo: false,
      horario: [],
      limiteper: 0,
      limiteporcasa: 0,
      limiter: 0,
      limresxdia: 0,
      morosos: 1,
      ragotadores: 1,
      disponibilidad: "d",
    }

  };

  componentWillMount() {
    this.setState({
      Nombre: this.props.grzona.nombre,
      Imagenid: this.props.grzona.imagenid,
      grupo: this.props.grzona.grupo,
      estado: this.props.grzona.estado,
      recursos: this.props.recursos,
      DataGrp: {
        Nombre: this.props.grzona.nombre,
        Imagenid: this.props.grzona.imagenid,
        grupo: this.props.grzona.grupo,
        estado: this.props.grzona.estado,
      },
    });
  }

  guardarCambios = (nuevoDatos) => {
    this.setState({ modalShow1: false });

    var recurso = {
      id: nuevoDatos.id,
      nombre: nuevoDatos.nombre,
    };
    var tem = this.state.recursos;
    tem.push(recurso);
    this.setState({ recursos: tem });
  };

  guardar = (EditarGrupo) => {
    this.setState({
      modalShow: false,
      Nombre: EditarGrupo.nombre,
      Imagenid: EditarGrupo.img,
      estado: EditarGrupo.estado,
      DataGrp: {
        Nombre: EditarGrupo.nombre,
        Imagenid: EditarGrupo.img,
        grupo: EditarGrupo.id,
        estado: EditarGrupo.estado,
      },
    });
  };

  cancelar = (caso) => {
    if (parseInt(caso) === 1) {
      this.setState({ modalShow: false });
    } else {
      this.setState({
        modalShow1: false,
        Zona: {
          aprobacion: 0,
          cantresem: "",
          descripcion: "",
          dia_mantenimiento: "",
          disponible: 1,
          estado: 1,
          festivo: false,
          horario: [],
          limiteper: "",
          limiteporcasa: "",
          limiter: 0,
          limresxdia: "",
          morosos: 1,
          ragotadores: 1,
          disponibilidad: "d",
        }
      });
    }

  };



  eliminar = () => {
    swal({
      title: "ATENCIÓN!",
      text: "Seguro que quiere borrar el grupo?",
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        axios.get(
          Global.Ruta.Url +
          "admin/deletgrpzona/" +
          Buffer.from(Global.Ph.Codigo).toString("base64") +
          "/" +
          Buffer.from(Global.Ruta.Ipin).toString("base64") +
          "/" +
          Buffer.from("" + this.state.grupo).toString("base64")
        )
          .then((res) => {
            if (res.data.message === "") {
              swal({
                title: "ATENCIÓN!",
                text: "El grupo se ha eliminado correctamente.",
                icon: "success",
                button: "Aceptar",
              }).then(() => {
                this.props.eliminar(this.props.indice);
              });
            } else if (res.data.message === "ERROR") {
              swal({
                title: "ERROR!",
                text: "No fue posible eliminar el grupo, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar",
              });
            } else {
              swal({
                title: "ERROR!",
                text: res.data.message,
                icon: "error",
                button: "Aceptar",
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ERROR!",
              text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            });
          });
      }
    });
  };

  eliminarRecurso = (indiceRecurso) => {
    var tem = this.state.recursos;
    tem.splice(indiceRecurso, 1);
    this.setState({
      recursos: tem,
    });
  };

  render() {
    return (
      <div className="col-12 my-2">
        <div className="card shadow rounded-4" style={{ paddingBottom: '2.5rem' }}>
          <div className="row gx-2 p-3 align-items-center">
            <div className="col-auto">
              <Button
                className="rounded-circle"
                onClick={this.eliminar}
                variant="outline-danger"
                size="sm"
                style={{ height: "1.8rem", width: "1.8rem" }}
              >
                <i className="fa-solid fa-times fa-sm" />
              </Button>
            </div>
            <div className="col-auto">
              <Button
                className="rounded-circle"
                onClick={() => this.setState({ modalShow: true })}
                variant="outline-primary"
                size="sm"
                style={{ height: "1.8rem", width: "1.8rem" }}
              >
                <i className="fa-solid fa-edit fa-sm" />
              </Button>
            </div>
            <div className="col">
              <h6 className="m-0 ps-2">{this.state.Nombre}</h6>
            </div>
          </div>
          <div className="row px-3">
            <Slider {...settings}>
              {this.state.recursos.map((recurso, indice) => (
                //<p key={grzona.GrpZona.grupo}>{grzona.GrpZona.grupo}</p>
                <TarjetaRecurso
                  key={recurso.id}
                  grupo={this.props.grzona.grupo}
                  recurso={recurso}
                  img={this.state.Imagenid}
                  indice={indice}
                  EliminarRecurso={this.eliminarRecurso}
                  guardarCambios={(e) => this.guardarCambios(e)}
                />
              ))}

              {/*items*/}
              <div className="col mx-1 btn-new-zone" style={{ width: "12rem" }}>
                <div
                  className="card bg-primary text-light rounded-4"
                  style={{ height: "15rem", cursor: 'pointer' }}
                  onClick={() => this.setState({ modalShow1: true })}
                >
                  <div className="card-body">
                    <div className="row h-100 justify-content-center">
                      <div className="col-auto d-flex align-items-center">
                        <p className="m-0 fw-normal text-light">
                          <i className="fas fa-plus"></i> Zona Social
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          {this.state.modalShow1 && (<PopupRecurso
            show={this.state.modalShow1}
            opc={1}
            grupo={this.props.grzona.grupo}
            Title={"Crear nuevo recurso"}
            Cancelar={() => this.cancelar(2)}
            Zona={this.state.Zona}
            id_recurso={0}
            nombre={""}
            guardarCambios={(e) => this.guardarCambios(e)}
          />)}


          <PopupGrpZona
            show={this.state.modalShow}
            opc={2}
            data={this.state.DataGrp}
            guardar={this.guardar}
            cancelar={() => this.cancelar(1)}
          />
        </div>
      </div>
    );
  }
}

export default SimpleSlider;
