import React, { Component } from "react";
import HeaderA2 from "../Home/HeaderA2";
import FormSusc from "./Form";
import style from '../../../assets/css/buttomControl.module.css'
import { Redirect } from "react-router-dom";
import Global from "../../../Global";
import { ValidarRolPagina } from "../../Helpers";

class FormUser extends Component {
  state = {
    redirect_home: false
  }
  componentWillMount() {
    this.setState({ redirect_home: ValidarRolPagina('FormUser') })
  }
  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }
    return (
      <div className="g-r-prf3 vh-100">
        <HeaderA2 swOpt={style.optUser} />
        <FormSusc />
         
      </div>
    );
  }
}

export default FormUser;