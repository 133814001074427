import React, { Component } from 'react';
import { ListItem, Checkbox, Button, Divider, TextField } from '@material-ui/core';
import { removeDiacritics } from "../../Helpers"

import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";

class ListaTransfer extends Component {

    state = {
        /* LISTA DE ASIGNADOS O SIN ASGINAR */
        Asignados: [],
        SinAsignar: [],
        AsignadosId: [],
        SinAsignarId: [],

        //PARA BUSQUEDA
        TemBusquedaAsignados: [],
        TemBusquedaSinAsignar: [],
        BusquedaAsig: "",
        BusquedaSinAsig: "",

        /* SELECCIONADOS DE ASIGNADOS O SIN ASIGNAR */
        AsignadosChecked: [],
        SinAsignarChecked: [],

        data: false,
        Nombre: "",
        tipo: 0,

    }

    componentWillMount() {
        //var variable = Global.Grupo.GrupoId;
        if (parseInt(this.props.grupoid) > 0) {
            axios.get(Global.Ruta.Url + "mensajeria/asignados/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from("" + this.props.grupoid).toString("base64"))
                .then((res) => {
                    if (res.data.message === "") {
                        var AsignadosId = [];
                        var SinAsignarId = [];
                        for (let i of res.data.Grupo.Asignados) {
                            AsignadosId.push(i.suscriptorid)
                        }
                        for (let i of res.data.Grupo.SinAsignar) {
                            SinAsignarId.push(i.suscriptorid)
                        }
                        this.setState({
                            Asignados: res.data.Grupo.Asignados,
                            TemBusquedaAsignados: res.data.Grupo.Asignados,
                            SinAsignar: res.data.Grupo.SinAsignar,
                            TemBusquedaSinAsignar: res.data.Grupo.SinAsignar,
                            AsignadosId: AsignadosId,
                            SinAsignarId: SinAsignarId,
                            data: true,
                            Nombre: this.props.nombre,
                            tipo: 1
                        })
                    }
                })
        } else {
            axios.get(Global.Ruta.Url + "mensajeria/notificanewgrupo/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/")
                .then((res) => {
                    if (res.data.message === "") {
                        var SinAsignarId = [];
                        for (let i of res.data.SinAsignar) {
                            SinAsignarId.push(i.suscriptorid)
                        }
                        this.setState({
                            Asignados: [],
                            SinAsignar: res.data.SinAsignar,
                            TemBusquedaSinAsignar: res.data.SinAsignar,
                            AsignadosId: [],
                            SinAsignarId: SinAsignarId,
                            data: true,
                            Nombre: "",
                            tipo: 2,
                        })
                    }
                })
        }
    }

    handleToggle = (paramid) => () => {
        if (this.state.SinAsignarChecked.indexOf(paramid) === -1) {
            if (this.state.AsignadosChecked.indexOf(paramid) === -1) {
                if (this.state.AsignadosId.indexOf(paramid) > -1) {
                    var tem = this.state.AsignadosChecked;
                    tem.push(paramid)
                    this.setState({
                        AsignadosChecked: tem
                    })
                } else {
                    let tem = this.state.SinAsignarChecked;
                    tem.push(paramid)
                    this.setState({
                        SinAsignarChecked: tem
                    })
                }
            } else {
                let tem = this.state.AsignadosChecked;
                tem.splice(this.state.AsignadosChecked.indexOf(paramid), 1)
                this.setState({
                    AsignadosChecked: tem
                })

            }
        } else {
            let tem = this.state.SinAsignarChecked;
            tem.splice(this.state.SinAsignarChecked.indexOf(paramid), 1)
            this.setState({
                SinAsignarChecked: tem
            })
        }
    };
    validarEntre = (paramid) => {
        if (this.state.SinAsignarChecked.indexOf(paramid) > -1 || this.state.AsignadosChecked.indexOf(paramid) > -1) {
            return true
        } else {
            return false
        }
    }

    /* PASAR DE LADO A LADO */
    handleCheckedParaAsignar = () => {
        var ArrayTemAsignados = this.state.Asignados;
        var ArrayTemSinAsignarBusqueda = this.state.TemBusquedaSinAsignar;

        var TemSinAsignarId = this.state.SinAsignarId;
        var TemAsignadosId = this.state.AsignadosId;

        for (let SuscId of this.state.SinAsignarChecked) {
            TemAsignadosId.push(SuscId);
            TemSinAsignarId.splice(TemSinAsignarId.indexOf(SuscId), 1);

            var IndexB = this.BuscarIndexCambio(SuscId, ArrayTemSinAsignarBusqueda)

            var UserTem = ArrayTemSinAsignarBusqueda[IndexB];
            ArrayTemAsignados.push(UserTem);
            ArrayTemSinAsignarBusqueda.splice(IndexB, 1);
        }

        this.setState({
            Asignados: ArrayTemAsignados,
            TemBusquedaAsignados: ArrayTemAsignados,
            SinAsignar: ArrayTemSinAsignarBusqueda,
            TemBusquedaSinAsignar: ArrayTemSinAsignarBusqueda,
            SinAsignarChecked: [],
            SinAsignarId: TemSinAsignarId,
            AsignadosId: TemAsignadosId,
            BusquedaSinAsig: "",
            BusquedaAsig: ""
        })
    }
    BuscarIndexCambio = (SuscId, temRows) => {
        // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON 

        const findIndexArray = (element) => parseInt(element.suscriptorid) === parseInt(SuscId);

        return temRows.findIndex(findIndexArray)
    }
    handleCheckedParaDesAsignar = () => {
        var ArrayTemSinAsignar = this.state.SinAsignar;
        var ArrayTemAsignadosBusqueda = this.state.TemBusquedaAsignados;

        var TemSinAsignarId = this.state.SinAsignarId;
        var TemAsignadosId = this.state.AsignadosId;

        for (let SuscId of this.state.AsignadosChecked) {
            TemSinAsignarId.push(SuscId);
            TemAsignadosId.splice(TemAsignadosId.indexOf(SuscId), 1);

            var IndexB = this.BuscarIndexCambio(SuscId, ArrayTemAsignadosBusqueda)

            var UserTem = ArrayTemAsignadosBusqueda[IndexB];
            ArrayTemSinAsignar.push(UserTem);
            ArrayTemAsignadosBusqueda.splice(IndexB, 1);
        }

        this.setState({
            Asignados: ArrayTemAsignadosBusqueda,
            TemBusquedaAsignados: ArrayTemAsignadosBusqueda,
            SinAsignar: ArrayTemSinAsignar,
            TemBusquedaSinAsignar: ArrayTemSinAsignar,
            AsignadosChecked: [],
            SinAsignarId: TemSinAsignarId,
            AsignadosId: TemAsignadosId,
            BusquedaSinAsig: "",
            BusquedaAsig: ""
        })
    }

    /* PARA GUARDAR LOS CAMBIOS */
    GuardarCambios = () => {
        this.props.guardar(this.props.grupoid, this.state.Asignados, this.state.Nombre, this.state.tipo);
    }

    EliminarGrupo = () => {
        swal({
            title: 'ATENCIÓN!!',
            text: "Seguro que quiere borrar el grupo?",
            icon: 'warning',
            buttons: ["NO", "SI"]
        }).then((result) => {
            if (result) {
                this.props.eliminar(this.props.grupoid);
            }
        });

    }

    handleChangeName = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value
        }));
    }

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value
        }));

        if (paramprop === "BusquedaAsig") {
            if (event.target.value === '') {
                var Asignados = this.state.TemBusquedaAsignados
                this.setState({
                    Asignados: Asignados,
                })
            } else {
                this.setState({
                    Asignados: this.filterItems(event.target.value, this.state.TemBusquedaAsignados)
                })
            }
        } else {
            if (event.target.value === '') {
                var SinAsignar = this.state.TemBusquedaSinAsignar
                this.setState({
                    SinAsignar: SinAsignar,
                })
            } else {
                this.setState({
                    SinAsignar: this.filterItems(event.target.value, this.state.TemBusquedaSinAsignar)
                })
            }
        }
    }

    filterItems = (query, temRows) => {
        return temRows.filter(function (data) {
            return (
                removeDiacritics(data.nombres).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
                removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1);
        })
    }

    /* LAS LISTAS DE LOS GRUPOS */
    customList = (title, items) => (
        <div className='card d-flex flex-column px-2 shadow' style={{ backgroundColor: 'gainsboro' }}>
            <div className="card-header" style={{ backgroundColor: 'gainsboro' }}>
                <div className="row align-items-center my-2">
                    <h4 className="col-auto m-0 fw-normal">{`${title}`}</h4>
                    <div className="col">
                        <TextField fullWidth size='small' id="outlined-basic" label="Buscar..."
                            variant="outlined" value={(title === 'Asignados') ? (this.state.BusquedaAsig) : (this.state.BusquedaSinAsig)}
                            onChange={this.handleChange((title === 'Asignados') ? ("BusquedaAsig") : ("BusquedaSinAsig"))} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h6 className="m-0 fw-normal text-muted">{`${(title === 'Asignados') ? (this.state.AsignadosChecked.length) : (this.state.SinAsignarChecked.length)}/${(title === 'Asignados') ? (items.length) : (items.length)} Seleccionados`}</h6>
                    </div>
                </div>
            </div>

            <Divider />
            <div className='overflow-auto mb-5' style={{ height: '27rem', overflowX: 'unset' }}>
                <ul className='p-0'>
                    {items.map((value) => {
                        const labelId = `${value.suscriptorid}`;

                        return (
                            <li key={value.suscriptorid} onClick={this.handleToggle(value.suscriptorid)}
                                className='row w-100 m-0 bg-light'>
                                <div className='col-auto p-0'>
                                    <Checkbox checked={this.validarEntre(value.suscriptorid)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId, }} />
                                </div>
                                <div id={labelId} className='col cover-marqss'>
                                    <p className='text-uppercase fw-normal marqss' style={{ fontSize: '1.2rem' }}>{`${value.nombres}`}</p>
                                    <p className='text-uppercase fw-normal text-muted text-wrap' style={{ fontSize: '1rem' }}>{`${value.direccion}`}</p>
                                </div>
                            </li>
                        );
                    })}
                    <ListItem />
                </ul>
            </div>
        </div>
    );

    render() {

        if (this.state.data === true) {
            return (
                <div className="container-fluid">
                    <div className="row row-cols-1 row-cols-lg-3" >
                        <div className="col my-2 order-1 order-lg-0">
                            {this.customList('Sin Asignar', this.state.SinAsignar)}
                        </div>

                        <div className="col my-5 order-0 order-lg-1">
                            <div className="py-5 mt-0 mt-lg-5 px-3 rounded" style={{ backgroundColor: 'gainsboro' }}>
                                <div className="my-2">
                                    <TextField required fullWidth margin="normal" id="outlined-basic" label="Nombre Grupo" name="nombres" variant="outlined"
                                        defaultValue={this.state.Nombre} onChange={this.handleChangeName("Nombre")} />
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <Button className="mt-1" fullWidth sx={{ my: 0.5 }} variant="contained" size="medium" onClick={this.handleCheckedParaAsignar} disabled={this.state.SinAsignarChecked.length === 0} aria-label="move selected right">
                                            añadir <i className="fas fa-chevron-right ml-1"></i>
                                        </Button>

                                        <Button className="mt-2" fullWidth sx={{ my: 0.5 }} variant="contained" size="medium" onClick={this.handleCheckedParaDesAsignar} disabled={this.state.AsignadosChecked.length === 0} aria-label="move selected left">
                                            <i className="fas fa-chevron-left mr-1"></i> quitar
                                        </Button>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <Button className="align-self-start mt-5" fullWidth sx={{ my: 0.5 }} onClick={this.GuardarCambios} variant="contained" size="large" color="primary">
                                            Guardar
                                        </Button>
                                        <Button className="align-self-start mt-2 bg-danger text-white" fullWidth disabled={(parseInt(this.props.grupoid) > 0) ? (false) : (true)} sx={{ my: 0.5 }} onClick={this.EliminarGrupo} variant="contained" size="large">
                                            Eliminar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col my-2 order-2 order-lg-2" >
                            {this.customList('Asignados', this.state.Asignados)}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}

export default ListaTransfer;