import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import Global from "../../../Global";
import axios from "axios";
import { FormControl, InputLabel, Select } from "@mui/material";
import { Dropdown, DropdownButton, Navbar, Nav, NavDropdown, Modal, } from "react-bootstrap";
import { MenuItem } from "@material-ui/core";
import swal from "sweetalert";

import { CambiarUserPorteria } from "../../Helpers";
import MyPhModal from "./MyPhModal";
import logo from "../../../assets/images/logo.png";

const cookies = new Cookie();

class HeaderA2 extends Component {
  state = {
    NombrePH: "",
    NombreUser: "",
    CodPH: "",
    redirect_login: 0,
    confi: 0,
    slc_perf: "",
    data: false,

    /* USUARIOS PORTEROS */
    porteros: [],
    // modals
    modal1: false,
  };

  componentWillMount() {
    this.setState({
      NombrePH: Global.Ph.Nombre,
      NombreUser: Global.GlobalUser.Nombre,
      CodPH: Global.Ph.Codigo,
    });

    var body = {};

    if (parseInt(Global.GlobalUser.rol) === 4) {
      body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        porteroid: Buffer.from(`${Global.GlobalUser.userid}`).toString("base64"),
      };

      axios
        .post(`${Global.Ruta.Url}porteria/listportero`, body)
        .then((res) => {
          if (res.data.message === "") {
            this.setState({
              porteros: res.data.Usuarios,
            });
          }
          this.setState({
            data: true,
          });
        })
        .catch((err) => {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({
              redirect_home: true,
            });
          });
        });
    } else {
      this.setState({
        data: true,
      });
    }
  }

  CerrarSesion = (e) => {
    cookies.remove("Sesion", { path: "/" });
    cookies.remove("UserId", { path: "/" });
    cookies.remove("Nombre", { path: "/" });
    cookies.remove("Rol", { path: "/" });
    cookies.remove("PhCodigo", { path: "/" });
    cookies.remove("Conjunto", { path: "/" });
    cookies.remove("Nitph", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    cookies.remove("Tipo", { path: "/" });
    this.setState({ redirect_login: 1 });

    Global.NotifHome.View = false;
    Global.NotifHome.FirtsEntry = false;
    Global.NotifHome.Pendientes = [];
  };

  CerrarSesionTem = (e) => {
    cookies.remove("Sesion", { path: "/" });
    cookies.remove("UserId", { path: "/" });
    cookies.remove("Nombre", { path: "/" });
    cookies.remove("Rol", { path: "/" });
    cookies.remove("PhCodigo", { path: "/" });
    cookies.remove("Conjunto", { path: "/" });
    cookies.remove("Nitph", { path: "/" });
    cookies.remove("Ciudad", { path: "/" });
    cookies.remove("Tipo", { path: "/" });

    Global.NotifHome.View = false;
    Global.NotifHome.FirtsEntry = false;
    Global.NotifHome.Pendientes = [];
  };

  ConfigurarPerfil = () => {
    axios
      .get(
        Global.Ruta.Url +
        "admin/datauser/" +
        Buffer.from(Global.Ruta.Ipin).toString("base64") +
        "/" +
        Buffer.from("" + Global.GlobalUser.userid).toString("base64")
      )
      .then((res) => {
        if (res.data.message === "") {
          for (let user of res.data.Usuarios) {
            Global.Form.tipo = 3;
            Global.Form.id = Global.GlobalUser.userid;
            Global.Form.nombre = user.nombres;
            Global.Form.login = user.login;
            Global.Form.rol = user.rol;
            Global.Form.estado = user.estado;
          }
          this.setState({ confi: 1 });
        } else {
          this.setState({ redirect_login: 1 });
        }
      })
      .catch((err) => {
        this.setState({ redirect_login: 1 });
      });
  };

  CambiarUsuario = (id, nombre, login) => {
    swal({
      title: "ATENCIÓN!!",
      text: `QUIERE CAMBIAR AL USUARIO ${nombre}`,
      content: {
        element: "input",
        attributes: {
          placeholder: "Ingrese su Contraseña",
          type: "password",
        },
      },
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result) {
        this.CerrarSesionTem();
        CambiarUserPorteria(Global.Ph.Codigo, login, result);
        setTimeout(() => {
          this.setState({ slc_perf: id, redirect_login: 1 });
        }, 600);
      }
    });
  };

  render() {
    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.confi === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/formusuario"} />;
    }

    if (this.state.data) {
      if (Global.GlobalUser.rol === 1 || Global.GlobalUser.rol === 2) {
        return (
          <React.Fragment>
            {/* modals */}
            <Modal
              show={this.state.modal1}
              onHide={() => this.setState({ modal1: false })}
              centered
              backdrop="static"
              fullscreen
            >
              <MyPhModal onHide={() => this.setState({ modal1: false })} />
            </Modal>

            {/* Componente */}
            <Navbar className="navbar bg-goph navbar-dark px-2" expand="xl">
              <Navbar.Brand>
                <NavLink
                  className="navbar-brand brand-goph"
                  activeClassName="none"
                  exact
                  to={Global.Ruta.Raiz + "/home-admin"}
                >
                  <span className="goph_logo fw-bold fs-4">
                    GoPH
                  </span>
                </NavLink>
              </Navbar.Brand>
              {/* BOTON DROPDOWN */}
              <Navbar.Toggle
                className="navbar-toggler togglerBtn"
                aria-controls="HeaderA2"
              >
                <span></span> menú <i className="fas fa-caret-down fa-sm"></i>
              </Navbar.Toggle>
              {/* DROPDOWN */}
              <Navbar.Collapse id="HeaderA2">
                <Nav className="navbar-nav mx-auto pt-3 pt-xl-0">
                  <li className="nav-item d-xl-none">
                    <p style={{ color: "#007bff" }}>
                      <i
                        className="far fa-id-badge fa-sm"
                        style={{ margin: "0 3px 0 5px" }}
                      ></i>{" "}
                      {this.state.NombreUser}
                    </p>
                  </li>
                  <li className="nav-item d-xl-none">
                    <p style={{ color: "#007bff" }}>
                      <i
                        className="fas fa-home fa-sm"
                        style={{ margin: "0 2px 0 2px" }}
                      ></i>{" "}
                      {this.state.NombrePH}
                    </p>
                  </li>

                  <div className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/"}
                    >
                      Inicio
                    </NavLink>
                  </div>

                  <div className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/usuarios"}
                    >
                      Usuario
                    </NavLink>
                  </div>

                  <NavDropdown
                    className="nav-item menu-item"
                    title="Comunidad "
                    id="comunidad"
                  >
                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/suscriptores"}
                      >
                        Comunidad
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/libro-propietarios"}
                      >
                        Libro Propietarios
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/*<div className="nav-item">
                                        <NavLink className="nav-link menu-item" activeClassName="Active" exact to={Global.Ruta.Raiz + "/suscriptores"}>
                                            Comunidad
                                        </NavLink>
                                    </div>*/}

                  {[1, 3].includes(Global.GlobalUser.rol) &&
                    (
                      <div className="nav-item">
                        <NavLink
                          className="nav-link menu-item"
                          activeClassName="Active"
                          exact
                          to={Global.Ruta.Raiz + "/home-asamblea"}
                        >
                          Asamblea
                        </NavLink>
                      </div>
                    )
                  }

                  <NavDropdown
                    className="nav-item menu-item"
                    title="Zonas Sociales "
                    id="zonas-sociales-dropdown"
                  >
                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/ZonaSocial"}
                      >
                        Administar Zonas
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/ReservaZonas"}
                      >
                        Reservas
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    className="nav-item menu-item"
                    title="Mensajes y archivos"
                    id="Mensajes-archivos-dropdown"
                  >
                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/sistema-msn"}
                      >
                        Enviar Mensaje
                      </NavLink>
                    </NavDropdown.Item>

                    {/* <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/autorizaciones"}
                      >
                        Solicitudes Portería
                      </NavLink>
                    </NavDropdown.Item> */}

                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/peticiones"}
                      >
                        Peticiones
                      </NavLink>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/subir-archivo"}
                      >
                        Publicar Documento
                      </NavLink>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <NavLink
                        className="dropdown-item menu-item"
                        exact
                        to={Global.Ruta.Raiz + "/revisar-archivo"}
                      >
                        Documentos Publicados
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <div className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/reportes"}
                    >
                      Reportes
                    </NavLink>
                  </div>

                  <li
                    className={this.props.swOpt + " nav-item d-xl-none"}
                    onClick={this.ConfigurarPerfil}
                  >
                    <p className="fs-4 text-primary">
                      <i className="fas fa-cog fa-sm"></i> Configuracion
                    </p>
                  </li>

                  <li
                    className={this.props.swOpt + " nav-item d-xl-none"}
                    onClick={() => this.setState({ modal1: true })}
                  >
                    <p className="fs-4 text-primary">
                      <i className="fas fa-book fa-sm"></i> Mi PH
                    </p>
                  </li>

                  <li
                    className="nav-item d-xl-none mb-2 fs-4"
                    onClick={this.CerrarSesion}
                    style={{ color: "red" }}
                  >
                    <i
                      className="fas fa-power-off fa-sm me-1"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>
                    SALIR
                  </li>
                </Nav>
              </Navbar.Collapse>
              <div className="navbarBtnAdm2">
                <DropdownButton
                  align="end"
                  id="dropdown-basic-button"
                  className="btn-admin dark"
                  title={
                    <div className="row align-items-center">
                      <div className="col-auto pe-0">
                        <i className="fa-solid fa-user-tie " />
                      </div>
                      <div className="col-1">
                        <div className="cover-marqss nav-drop-title-btn">
                          <p className="m-0 marqss ind-txt-sm">
                            {this.state.NombreUser}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <Dropdown.Item disabled={true}>
                    <i
                      className="far fa-id-badge fa-sm"
                      style={{ margin: "0 3px 0 5px" }}
                    ></i>{" "}
                    {this.state.NombreUser}
                  </Dropdown.Item>
                  <Dropdown.Item disabled={true}>
                    <i
                      className="fas fa-home fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    {this.state.NombrePH}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={this.props.swOpt}
                    onClick={this.ConfigurarPerfil}
                  >
                    <i
                      className="fas fa-cog fa-sm "
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    CONFIGURACIÓN
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={this.props.swOpt}
                    onClick={() => this.setState({ modal1: true })}
                  >
                    <i
                      className="fas fa-book fa-sm "
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    Mi PH
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item onClick={this.CerrarSesion}>
                    <i
                      className="fas fa-power-off fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>
                    <span className="rojo"> Cerrar Sesión</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </Navbar>

            <div className="clearfix"></div>
          </React.Fragment>
        );
      } else if (Global.GlobalUser.rol === 3) {
        return (
          <React.Fragment>
            <Navbar className="navbar bg-goph navbar-dark px-2" expand="xl">
              <Navbar.Brand>
                <NavLink
                  className="navbar-brand brand-goph"
                  activeClassName="none"
                  exact
                  to={Global.Ruta.Raiz + "/home-admin"}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "3.1rem" }}
                    src={logo}
                    alt="logo"
                  />
                </NavLink>
              </Navbar.Brand>
              {/* BOTON DROPDOWN */}
              <Navbar.Toggle
                className="navbar-toggler togglerBtn"
                aria-controls="HeaderA2"
              >
                <span></span> menú <i className="fas fa-caret-down fa-sm"></i>
              </Navbar.Toggle>
              {/* DROPDOWN */}
              <Navbar.Collapse id="HeaderA2">
                <Nav className="navbar-nav mx-auto pt-3 pt-xl-0">
                  <li className="nav-item d-xl-none">
                    <p style={{ color: "#007bff" }}>
                      <i
                        className="far fa-id-badge fa-sm"
                        style={{ margin: "0 3px 0 5px" }}
                      ></i>{" "}
                      {this.state.NombreUser}
                    </p>
                  </li>
                  <li className="nav-item d-xl-none">
                    <p style={{ color: "#007bff" }}>
                      <i
                        className="fas fa-home fa-sm"
                        style={{ margin: "0 2px 0 2px" }}
                      ></i>{" "}
                      {this.state.NombrePH}
                    </p>
                  </li>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/"}
                    >
                      Inicio
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/suscriptores"}
                    >
                      Comunidad
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/home-asamblea"}
                    >
                      Asamblea
                    </NavLink>
                  </Nav.Link>

                  <li
                    className="nav-item d-xl-none mb-2 fs-4"
                    onClick={this.CerrarSesion}
                    style={{ color: "red" }}
                  >
                    <i
                      className="fas fa-power-off fa-sm me-1"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>
                    SALIR
                  </li>
                </Nav>
              </Navbar.Collapse>
              <div className="navbarBtnAdm2">
                <DropdownButton
                  align="end"
                  id="dropdown-basic-button"
                  className="btn-admin"
                  title={
                    <div className="row align-items-center">
                      <div className="col-auto pe-0">
                        <i className="fa-solid fa-building-user " />
                      </div>
                      <div className="col-1">
                        <div className="cover-marqss nav-drop-title-btn">
                          <p className="m-0 marqss ind-txt-sm">
                            {this.state.NombreUser}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <Dropdown.Item disabled={true}>
                    <i
                      className="far fa-id-badge fa-sm"
                      style={{ margin: "0 3px 0 5px" }}
                    ></i>{" "}
                    {this.state.NombreUser}
                  </Dropdown.Item>
                  <Dropdown.Item disabled={true}>
                    <i
                      className="fas fa-home fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    {this.state.NombrePH}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={this.props.swOpt}
                    onClick={this.ConfigurarPerfil}
                  >
                    <i
                      className="fas fa-cog fa-sm "
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    CONFIGURACIÓN
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item onClick={this.CerrarSesion}>
                    <i
                      className="fas fa-power-off fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>
                    <span className="rojo"> Cerrar Sesión</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </Navbar>

            <div className="clearfix"></div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Navbar className="navbar bg-goph navbar-dark px-2" expand="xl">
              <Navbar.Brand>
                <NavLink
                  className="navbar-brand brand-goph"
                  activeClassName="none"
                  exact
                  to={Global.Ruta.Raiz + "/home-admin"}
                >
                  GoPH
                </NavLink>
              </Navbar.Brand>
              {/* BOTON DROPDOWN */}
              <Navbar.Toggle
                className="navbar-toggler togglerBtn"
                aria-controls="HeaderA2"
              >
                <span></span> menú <i className="fas fa-caret-down fa-sm"></i>
              </Navbar.Toggle>
              {/* DROPDOWN */}
              <Navbar.Collapse id="HeaderA2">
                <Nav className="navbar-nav mx-auto pt-3 pt-xl-0">
                  <li className="nav-item d-xl-none">
                    <p style={{ color: "#007bff" }}>
                      <i
                        className="far fa-id-badge fa-sm"
                        style={{ margin: "0 3px 0 5px" }}
                      ></i>{" "}
                      {this.state.NombreUser}
                    </p>
                  </li>
                  <li className="nav-item d-xl-none">
                    <p style={{ color: "#007bff" }}>
                      <i
                        className="fas fa-home fa-sm"
                        style={{ margin: "0 2px 0 2px" }}
                      ></i>{" "}
                      {this.state.NombrePH}
                    </p>
                  </li>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/"}
                    >
                      Inicio
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/suscriptores"}
                    >
                      Comunidad
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      activeClassName="Active"
                      exact
                      to={Global.Ruta.Raiz + "/vehiculos"}
                    >
                      Vehículos
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item dropdown">
                    <NavLink
                      className="nav-link menu-item"
                      exact
                      to={Global.Ruta.Raiz + "/ReservaZonas"}
                    >
                      Reservas
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      exact
                      to={Global.Ruta.Raiz + "/autorizaciones"}
                    >
                      Solicitudes
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link className="nav-item">
                    <NavLink
                      className="nav-link menu-item"
                      exact
                      to={Global.Ruta.Raiz + "/sistema-msn"}
                    >
                      Mensajeria
                    </NavLink>
                  </Nav.Link>

                  <li
                    className="nav-item d-xl-none mb-2 fs-4"
                    onClick={this.CerrarSesion}
                    style={{ color: "red" }}
                  >
                    <i
                      className="fas fa-power-off fa-sm me-1"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>
                    SALIR
                  </li>
                </Nav>
              </Navbar.Collapse>
              <div className="navbarBtnAdm2">
                <DropdownButton
                  id="dropdown-basic-button"
                  align="end"
                  title={
                    <p className="btnNBcc">
                      {" "}
                      <i
                        className="fas fa-user fa-sm"
                        style={{ margin: "2px" }}
                      ></i>
                      {this.state.NombreUser}
                    </p>
                  }
                  autoClose={false}
                >
                  <Dropdown.Item disabled={false}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        id="select_perfil"
                      >
                        <i
                          className="far fa-id-badge fa-sm"
                          style={{ margin: "0 3px 0 5px" }}
                        ></i>{" "}
                        {this.state.NombreUser}
                      </InputLabel>
                      <Select
                        labelId="select_perfil"
                        label={
                          <div>
                            <i
                              className="far fa-id-badge fa-sm"
                              style={{ margin: "0 3px 0 5px" }}
                            ></i>{" "}
                            {this.state.NombreUser}
                          </div>
                        }
                        value={this.state.slc_perf}
                        inputProps={{
                          name: { ...this.state.NombreUser },
                          id: "uncontrolled-native",
                        }}
                      >
                        {this.state.porteros.map((portero, indice) => (
                          <MenuItem
                            value={portero.usuarioid}
                            onClick={() =>
                              this.CambiarUsuario(
                                portero.usuarioid,
                                portero.nombres.toUpperCase(),
                                portero.login
                              )
                            }
                            key={indice}
                          >
                            {portero.nombres.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Dropdown.Item>
                  <Dropdown.Item disabled={true}>
                    <i
                      className="fas fa-home fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    {this.state.NombrePH}
                  </Dropdown.Item>
                  <Dropdown.Header className={this.props.swOpt}>
                    <i
                      className="fas fa-cog fa-sm "
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>{" "}
                    CONFIGURACIÓN
                  </Dropdown.Header>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item onClick={this.CerrarSesion}>
                    <i
                      className="fas fa-power-off fa-sm"
                      style={{ margin: "0 2px 0 2px" }}
                    ></i>
                    <span className="rojo"> Cerrar Sesión</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </Navbar>

            <div className="clearfix"></div>
          </React.Fragment>
        );
      }
    }
    return <React.Fragment></React.Fragment>;
  }
}

export default HeaderA2;
