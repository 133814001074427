import React from "react";
import { Tooltip, Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import Global from "../../../../Global";
import axios from "axios";


class Psidebar extends React.Component {

    state = {
        Mensajes: 0,
        Elementos: [
            { id: 10, nombre: "Mensajes Administración", icon: "fa-solid fa-bell", url: "" },
            { id: 1, nombre: "Comunidad", icon: "fa-solid fa-address-book", url: "suscriptores" },
            { id: 2, nombre: "Mensajes Comunidad", icon: "fa-solid fa-comments", url: "sistema-msn" },
            { id: 3, nombre: "Vehículos", icon: "fa-solid fa-car-side", url: "vehiculos" },
            { id: 4, nombre: "Registro de Placas", icon: "fa-solid fa-car-on", url: "placas" },
            { id: 5, nombre: "Minutas", icon: "fa-solid fa-note-sticky", url: "notas" },
            { id: 6, nombre: "Solicitudes Porteria", icon: "fa-regular fa-comment-dots", url: "autorizaciones" },
            { id: 7, nombre: "Reservas", icon: "fa-solid fa-users", url: "ReservaZonas" },
            { id: 8, nombre: "Historial", icon: "fa-solid fa-clock-rotate-left", url: "despacho-porteria/paquetes" },
            { id: 9, nombre: "Código QR", icon: "fa-solid fa-qrcode", url: "" },
        ]
    }

    componentDidMount() {
        this.MensajesPorteria(1)
    }

    componentDidUpdate(prevProps) {
        if (this.props.MensajePorteria !== prevProps.MensajePorteria) {
            this.MensajesPorteria(1)
        }
    }

    MensajesPorteria = (caso) => {

        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            caso: Buffer.from("" + caso).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}smsp/listmensajeporteriaportero`, body).then(res => {
            if (res.data.message === "") {
                this.setState({ Mensajes: res.data.smsp.length })
            } else {
                this.setState({ Mensajes: 0 })
            }
        }).catch(err => {
            this.setState({ Mensajes: 0 })
        });
    }

    ClicButton = (id) => {
        switch (parseInt(id)) {
            case 9:
                this.props.Sidebar(id)
                break;
            case 10:
                this.props.Sidebar(id)
                break;
            default:
                break;
        }

    }

    render() {
        return (
            <div className=" border-right" >

                {
                    this.state.Elementos.map((elemt, index) => (
                        elemt.url !== "" ? (
                            <NavLink key={index} to={`/${elemt.url}`}>
                                <div className="list-group list-group-flush " >
                                    {parseInt(elemt.id) !== 10 ? (
                                        <Tooltip title={elemt.nombre}>
                                            <button className="btn btn-light my-1 ">
                                                <i className={elemt.icon} style={{ color: "#608fe2" }} />
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={elemt.nombre} >
                                            <button className="btn btn-light position-relative" >
                                                <i className={elemt.icon} style={{ color: "#608fe2" }} />
                                                {parseInt(this.state.Mensajes) > 0 ? (
                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                        {this.state.Mensajes}
                                                    </span>
                                                ) : ("")}
                                            </button>
                                        </Tooltip>
                                    )}
                                    <Divider style={{ backgroundColor: 'grey', height: '2px' }} />
                                </div>
                            </NavLink>
                        ) : (
                            <div className="list-group list-group-flush " key={index}>

                                {parseInt(elemt.id) !== 10 ? (

                                    <Tooltip title={elemt.nombre}>
                                        <button className="btn btn-light my-2 " onClick={() => this.ClicButton(elemt.id)}>
                                            <i className={elemt.icon} style={{ color: "#608fe2" }} />
                                        </button>
                                    </Tooltip>

                                ) : (
                                    <Tooltip title={elemt.nombre} >
                                        <button className="btn btn-light position-relative" onClick={() => this.ClicButton(elemt.id)} >
                                            <i className={elemt.icon} style={{ color: "#608fe2" }} />
                                            {parseInt(this.state.Mensajes) > 0 ? (
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                    {this.state.Mensajes}
                                                </span>
                                            ) : ("")}
                                        </button>
                                    </Tooltip>
                                )}
                                <Divider style={{ backgroundColor: 'grey', height: '2px' }} />

                            </div>
                        )
                    ))

                }

            </div>
        );
    }
}

export default Psidebar;