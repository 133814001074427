import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Card, Tooltip, Divider, Typography } from "@mui/material";
import { TextField } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Es from "date-fns/locale/es";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import HeadTitle from "../../principal/HeadTitle";
import Loader from "../../principal/Loader";
import Global from "../../../Global";
import { removeDiacritics } from "../../Helpers"
import axios from "axios";
import swal from "sweetalert";
import CrearMsnpa from './modal/CrearMsnpa'
import moment from 'moment';
import "moment/locale/es";

export default class PorteriaAdmin extends Component {
    state = {
        redirect_home: false,
        Data: false,
        ModalCard: false,
        Modal: false,
        btnfechaF: true,
        btnfiltro: true,
        Porteros: [],
        Mensajes: [],
        fechai: '',
        fechaf: '',
        ParaBusqueda: [],
        Busqueda: "",
        contenido: "",
        editingIndex: null,
        param: undefined
    }

    componentDidMount() {

        if (this.props.caso !== undefined) {
            this.setState({ ModalCard: true })
        }

        this.Porteros()
    }

    componentWillUnmount() {
        if (parseInt(Global.GlobalUser.rol) === 4) {
            var body = {
                ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            };
            axios.post(`${Global.Ruta.Url}smsp/vistomensajeporteria`, body).then(res => {

            }).catch(err => {

            });
        }
    }

    Porteros = () => {
        this.setState({ Data: false })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            porteroid: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}porteria/listportero`, body).then(res => {
            if (res.data.message === "") {
                var porteros = {
                    label: "TODOS",
                    id: 0
                }
                var temp = res.data.Usuarios.map(portero => ({
                    id: portero.usuarioid,
                    label: portero.nombres,
                }))
                temp.unshift(porteros);
                this.setState({ Porteros: temp }, () => {
                    if (parseInt(Global.GlobalUser.rol) !== 4) {
                        this.Mensajes(1)
                    } else {
                        if (this.props.caso !== undefined) {
                            this.MensajesPorteria(1)
                        } else {
                            this.MensajesPorteria(2)
                        }


                    }
                });
            } else {
                swal({
                    title: "ERROR!",
                    text: `${res.data.message}`,
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    Mensajes = (caso) => {
        this.setState({ Data: false })

        var fechai = ""
        var fechaf = ""

        if (parseInt(caso) === 2) {
            fechai = moment(this.state.fechai).format("YYYY-MM-DD")
            fechaf = moment(this.state.fechaf).format("YYYY-MM-DD")
        } else {
            this.setState({ fechai: "", fechaf: "", btnfechaF: true, btnfiltro: true, })
        }

        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            fechai: Buffer.from("" + fechai).toString("base64"),
            fechaf: Buffer.from("" + fechaf).toString("base64"),
            caso: Buffer.from("" + caso).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}smsp/listmensajeporteria`, body).then(res => {

            if (res.data.message === "") {
                this.setState({ Mensajes: res.data.smsp, ParaBusqueda: res.data.smsp, Data: true })
            } else {
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    MensajesPorteria = (caso) => {
        this.setState({ Data: false })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            caso: Buffer.from("" + caso).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}smsp/listmensajeporteriaportero`, body).then(res => {
            if (res.data.message === "") {
                this.setState({ Mensajes: res.data.smsp }, () => {
                    this.setState({ ParaBusqueda: res.data.smsp, Data: true })
                })
            } else {
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    obtenerLabelPorId = (id) => {
        const elemento = this.state.Porteros.find(item => item.id === id);
        return elemento ? elemento.label : 'ID no encontrado';
    }

    AñadirMensaje = (data) => {
        this.setState({ Data: false }, () => {
            var temp = this.state.Mensajes
            var mensaje = {
                estado: "Enviado",
                fecha_envio: new Date(),
                fecha_respuesta: "",
                id_com: data.id_com,
                id_estado: 1,
                id_portero: 0,
                id_portero_send: data.id_portero_send,
                mensaje: data.mensaje,
                respuesta: "",
                tipo_mensaje: 0,
                tipo_respuesta: 0,
            }
            temp.push(mensaje)
            this.setState({ Mensajes: temp, Data: true, Modal: false })
        })

    }

    Eliminar = (data, index) => {
        swal({
            title: ``,
            text: `¿Desea eliminar este Mensaje?`,
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ Data: false })
                var body = {
                    ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                    codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                    id_usuario: Buffer.from(Global.GlobalUser.userid).toString("base64"),
                    id_com: Buffer.from("" + data).toString("base64"),
                };
                axios.post(`${Global.Ruta.Url}smsp/eliminarmensajeporteria`, body).then(res => {
                    if (res.data.message === "") {
                        var temp = this.state.Mensajes
                        temp.splice(index, 1);
                        this.setState({ Mensajes: temp, Data: true })
                    } else {
                        swal({
                            title: "Atención",
                            text: `${res.data.message}`,
                            icon: "warning",
                            button: "Aceptar"
                        }).then(() => {
                            this.Mensajes(1)
                        })
                    }
                }).catch(err => {
                    swal({
                        title: "ERROR!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar"
                    }).then(() => {
                        this.setState({ redirect_home: true })
                    })
                });
            }
        })

    }

    ActualizaFecha = (caso, date) => {
        if (parseInt(caso) === 1) {
            this.setState({ fechai: date, btnfechaF: false })
            if (date > this.state.fechaf) {
                this.setState({ fechaf: "", btnfiltro: true, })
            }
        } else if (parseInt(caso) === 2) {
            this.setState({ fechaf: date, btnfiltro: false, })
        }
    }

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value
        }));
        this.setState({
            Mensajes: this.filterItems(event.target.value, this.state.ParaBusqueda)
        })
    }

    filterItems = (query, temRows) => {
        return temRows.filter(function (data) {
            return (
                removeDiacritics(data.estado).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
                removeDiacritics(data.mensaje).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1
            );

        })
    }

    RespuestaManual = (respuesta, index) => {
        var temp = this.state.Mensajes
        temp[index].respuesta = respuesta.toUpperCase()
        this.setState({ Mensajes: temp })
    }

    GuardarRespuesta = (caso, index, tipo) => {

        var temp = this.state.Mensajes[index]
        var Respuesta = ""
        if (parseInt(caso) === 1) {
            Respuesta = temp.respuesta
        } else {
            Respuesta = "LA SOLICITUD SERÁ TRAMITADA"
        }

        if (Respuesta !== "") {
            this.setState({ Data: false })
            var body = {
                ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
                id_com: Buffer.from("" + temp.id_com).toString("base64"),
                respuesta: Buffer.from("" + Respuesta).toString("base64"),
                tipo_respuesta: Buffer.from("" + caso).toString("base64"),
            };
            axios.post(`${Global.Ruta.Url}smsp/respondermensajeporteria`, body).then(res => {
                if (res.data.message === "") {
                    var temp1 = this.state.Mensajes
                    if (parseInt(tipo) === 0) {
                        temp1.splice(index, 1)
                        if (temp1.length >= 1) { this.setState({ Mensajes: temp1, Data: true }) }
                        this.props.MensajeEnviado(temp1.length, 1)
                    } else {
                        temp1[index].id_portero = Global.GlobalUser.userid
                        temp1[index].tipo_respuesta = caso
                        temp1[index].fecha_respuesta = new Date()
                        temp1[index].id_estado = 3
                        temp1[index].estado = "Contestado "
                        temp1[index].respuesta = Respuesta
                        this.setState({ Mensajes: temp1, Data: true })
                    }
                } else {
                    swal({
                        title: "ERROR!",
                        text: `${res.data.message}`,
                        icon: "error",
                        button: "Aceptar"
                    }).then(() => {
                        this.setState({ redirect_home: true })
                    })
                }
            }).catch(err => {
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            });
        } else {
            swal({
                title: "",
                text: `Por favor ingrese una respuesta`,
                icon: "warning",
                button: "Aceptar"
            }).then(() => {
                return
            })
        }



    }

    PasarNotas = (index, caso) => {
        this.setState({ Data: false })
        var temp = this.state.Mensajes
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            id_com: Buffer.from("" + temp[index].id_com).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}smsp/pasartareamensajeporteria`, body).then(res => {
            if (res.data.message === "") {
                temp[index].nota = 1
                this.setState({ Mensajes: temp }, () => {
                    if (parseInt(caso) === 0) {
                        this.props.MensajeEnviado(1, 2)
                    }
                    this.setState({ Data: true })
                    // this.GuardarRespuesta(0, index)
                })
            } else {
                swal({
                    title: "ERROR!",
                    text: `${res.data.message}`,
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    render() {

        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }


        if (this.state.Data && parseInt(Global.GlobalUser.rol) !== 4 && this.props.caso === undefined) {
            const isWin = /Windows/.test(navigator.userAgent);
            const isMac = /Macintosh/.test(navigator.userAgent);
            return (
                <div className="mx-5">
                    {this.state.Modal && (
                        <CrearMsnpa show={this.state.Modal} backdrop="static" centered size="md" CerrarModal={() => this.setState({ Modal: false })}
                            Porteros={this.state.Porteros} AñadirMensaje={(data) => this.AñadirMensaje(data)} />
                    )}
                    <div className="col-12 mt-3">
                        <HeadTitle classNameOwn="row align-items-center g-0 " titulo="Mensaje a portería" subTitulo="" indicadores={[]}
                            actionButton={
                                <div className="buttons-container d-flex justify-content-end mx-0">
                                    <div className="col " >
                                        <Tooltip title="Añadir Mensaje">
                                            <button className="btn btn-outline-light rounded-circle btn-sm "
                                                onClick={() => this.setState({ Modal: true })}>
                                                <i className="fa-solid fa-circle-plus" style={{ color: "#14A44D", fontSize: "24px" }}></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Actualizar">
                                            <button className="btn btn-outline-light rounded-circle btn-sm "
                                                onClick={() => this.Mensajes(1)} >
                                                <i className="fa-solid fa-arrow-rotate-right" style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                            </button>
                                        </Tooltip>
                                        {isWin || isMac ? (
                                            <TextField
                                                autoFocus
                                                size="small"
                                                className="px-2"
                                                placeholder="Buscar Mensaje"
                                                variant="standard"
                                                value={this.state.Busqueda}
                                                defaultValue={this.state.Busqueda}
                                                onChange={this.handleChange("Busqueda")}
                                            />
                                        ) : ("")}
                                    </div>
                                    {isWin || isMac ? (
                                        <div className="col  overflow-x-hidden" >
                                            <div className="row">



                                                <div className="col">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                                                        <DatePicker
                                                            value={this.state.fechai}
                                                            maxDate={new Date()}
                                                            onChange={(date) => this.ActualizaFecha(1, date)}
                                                            renderInput={(params) => (
                                                                <TextField {...params} size="small" />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="col">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                                                        <DatePicker
                                                            disabled={this.state.btnfechaF}
                                                            placeholder="Fecha Final"
                                                            value={this.state.fechaf}
                                                            minDate={this.state.fechai}
                                                            maxDate={new Date()}
                                                            onChange={(date) => this.ActualizaFecha(2, date)}
                                                            renderInput={(params) => (
                                                                <TextField {...params} size="small" />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="col-auto">
                                                    <button className="btn btn-primary btn-sm" disabled={this.state.btnfiltro} onClick={() => this.Mensajes(2)}>
                                                        <i className="fa-brands fa-searchengin"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ("")}


                                </div>
                            }
                        />

                        {!isWin || isMac ? (
                            <TextField
                                autoFocus
                                fullWidth
                                size="small"
                                className="pt-2"
                                placeholder="Buscar Mensaje"
                                variant="standard"
                                value={this.state.Busqueda}
                                defaultValue={this.state.Busqueda}
                                onChange={this.handleChange("Busqueda")}
                            />
                        ) : ("")}

                        {!isWin || isMac ? (
                            <div className="col mt-2 overflow-x-hidden overflow-y-hidden" >
                                <div className="row">
                                    <div className="col">

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                                            <DatePicker
                                                label="Fecha Inicio"
                                                value={this.state.fechai}
                                                maxDate={new Date()}
                                                onChange={(date) => this.ActualizaFecha(1, date)}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col">
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                                            <DatePicker
                                                label="Fecha Final"
                                                disabled={this.state.btnfechaF}
                                                placeholder="Fecha Final"
                                                value={this.state.fechaf}
                                                minDate={this.state.fechai}
                                                maxDate={new Date()}
                                                onChange={(date) => this.ActualizaFecha(2, date)}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-auto d-flex flex-column">
                                        <button className="btn btn-primary btn-sm mt-auto" disabled={this.state.btnfiltro} onClick={() => this.Mensajes(2)}>
                                            <i className="fa-brands fa-searchengin"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}

                        <div className="row row-cols-1 row-cols-md-4 mt-4 mb-4 justify-content-star">
                            {this.state.Mensajes.map((mensaje, index) => (
                                <Card key={index} sx={{ maxWidth: 300, minWidth: 300, minHeight: 250 }} className="border border-light-subtle my-2 mx-2 d-flex flex-column position-relative">
                                    <div className="row my-2">
                                        <Typography>{mensaje.estado + " " + moment(mensaje.fecha_envio).format("DD MMM YYYY HH:mm")}</Typography>
                                        <Typography>{this.obtenerLabelPorId(mensaje.id_portero_send)}</Typography>
                                    </div>
                                    <div>
                                        <Typography sx={{ mb: 0.2 }} color="text.secondary" className="text-justify mb-2" style={{ fontSize: '11px' }}>{mensaje.mensaje}</Typography>
                                        {mensaje.id_estado === 3 ? (
                                            <div>
                                                <Divider style={{ backgroundColor: 'grey', height: '2px' }} className="my-2" />
                                                <Typography sx={{ mb: 0.2 }} className="text-start text-dark mb-2" style={{ fontSize: '11px' }}>{mensaje.respuesta}</Typography>
                                                <Typography sx={{ mb: 0.2 }} className="text-start text-dark" style={{ fontSize: '11px' }}>{this.obtenerLabelPorId(mensaje.id_portero) + " " + moment(mensaje.fecha_respuesta).format("DD MMM YYYY HH:mm")}</Typography>
                                            </div>
                                        ) : ("")}
                                    </div>
                                    <div className="mt-auto d-flex justify-content-between mb-0">
                                        <Tooltip title="Eliminar">
                                            <button className="btn btn-outline-light rounded-circle " onClick={() => this.Eliminar(mensaje.id_com, index)}><i className="fa-solid fa-trash-can " style={{ color: "#d16f6f" }}></i></button>
                                        </Tooltip>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div >
            );
        }

        if (this.state.Data && parseInt(Global.GlobalUser.rol) === 4 && this.props.caso === undefined) {
            return (
                <div className="mx-5">
                    <div className="col-12 mt-3">
                        <div className="row align-items-center g-0 ">
                            <div className="col-auto">
                                <div className="row g-0 align-items-center">
                                    <div className="col-12">
                                        <h6 className="m-0 titulo1">
                                            {"Mensaje a portería"}
                                        </h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="row ps-1">
                                            <p className="m-0 fw-light text-muted">
                                                {" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col align-items-rigth mx-0">
                                <div className="buttons-container d-flex justify-content-end mx-0">
                                    <div className="col " >
                                        <Tooltip title="Actualizar">
                                            <button className="btn btn-outline-light rounded-circle btn-sm "
                                                onClick={() => this.MensajesPorteria(2)} >
                                                <i className="fa-solid fa-arrow-rotate-right" style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                            </button>
                                        </Tooltip>
                                        <TextField
                                            id="search1"
                                            size="small"
                                            className="px-2"
                                            placeholder="Buscar Mensaje"
                                            variant="standard"
                                            value={this.state.param}
                                            defaultValue={this.state.Busqueda}
                                            onChange={this.handleChange("Busqueda")}
                                        // autoFocus
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-4 mt-4 mb-4 justify-content-star">
                            {this.state.Mensajes.map((mensaje, index) => (
                                <Card key={index} sx={{ maxWidth: 300, minWidth: 300, minHeight: 250 }} className="border border-light-subtle my-2 mx-2 d-flex flex-column position-relative">
                                    <div className="row my-2">
                                        <Typography>{mensaje.estado + " " + moment(mensaje.fecha_envio).format("DD MMM YYYY HH:mm")}</Typography>
                                        <Typography>{parseInt(mensaje.id_portero_send) === 0 ? ("Todos ") : (`${Global.GlobalUser.Nombre} `)}</Typography>
                                    </div>
                                    <div>
                                        <Typography sx={{ mb: 0.2 }} color="text.secondary" className="text-justify mb-2" style={{ fontSize: '11px' }}>{mensaje.mensaje}</Typography>
                                        {mensaje.id_estado === 3 ? (
                                            <div>
                                                <Divider style={{ backgroundColor: 'grey', height: '2px' }} className="my-2" />
                                                <Typography sx={{ mb: 0.2 }} className="text-start text-dark mb-2" style={{ fontSize: '11px' }}>{mensaje.respuesta}</Typography>
                                                <Typography sx={{ mb: 0.2 }} className="text-start text-dark" style={{ fontSize: '11px' }}>{parseInt(mensaje.id_portero) === 0 ? ("Todos ") : (`${Global.GlobalUser.Nombre} `) + moment(mensaje.fecha_respuesta).format("DD MMM YYYY HH:mm")}</Typography>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    value={this.state.Mensajes[index].respuesta}
                                                    key={index}
                                                    multiline
                                                    fullWidth
                                                    placeholder="Cuerpo del Mensaje"
                                                    helperText={`${this.state.Mensajes[index].respuesta.length} de 250 caracteres`}
                                                    variant="outlined"
                                                    margin="dense"
                                                    onChange={(e) => this.RespuestaManual(e.target.value, index)}
                                                    inputProps={{ maxLength: 250 }}
                                                />
                                                <div className="mt-auto d-flex  align-items-center mb-2">
                                                    <Tooltip title="Respuesta Manual">
                                                        <button className="btn btn-success mx-1 " style={{ fontSize: '9px' }} onClick={() => this.GuardarRespuesta(1, index, 1)}><i className="fa-solid fa-paper-plane" ></i> Respuesta Manual</button>
                                                    </Tooltip>
                                                    <Tooltip title="Respuesta Automática">
                                                        <button className="btn btn-primary  " style={{ fontSize: '9px' }} onClick={() => this.GuardarRespuesta(0, index, 1)}><i className="fa-solid fa-reply fa-flip-horizontal"></i> Respuesta Automática</button>
                                                    </Tooltip>
                                                </div>
                                                {parseInt(mensaje.nota) === 0 ? (
                                                    <div className="my-2 text-center">
                                                        <Tooltip title="Enviar mensaje a notas">
                                                            <button className="btn btn-warning mx-1 " style={{ fontSize: '12px' }} onClick={() => this.PasarNotas(index, 1)}><i className="fa-regular fa-note-sticky" ></i> Enviar mensaje a notas</button>
                                                        </Tooltip>
                                                    </div>
                                                ) : ("")}
                                            </div>
                                        )}
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div >
            );
        }

        if (this.state.Data && parseInt(Global.GlobalUser.rol) === 4 && this.props.caso !== undefined) {
            return (
                this.state.Mensajes.length > 0 ? (
                    <Modal size="md" show={this.state.ModalCard} aria-labelledby="contained-modal-title-vcenter" onHide={this.props.CloseModal} backdrop="static" centered
                        className="overflow-y-hidden h-80" style={{ maxHeight: "97vh" }}>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Mensajes de Administración</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="h-80">

                            <div>
                                <NavLink to={`/porteria-msn`}>
                                    <button className="btn btn-primary  mx-1 " style={{ fontSize: '9px' }} ><i className="fa-solid fa-message" ></i> Ver Mensajes</button>
                                </NavLink>
                            </div>
                            <div className="custom-scroll" style={{ maxHeight: "80vh", overflowY: "auto" }}>
                                {this.state.Mensajes.map((mensaje, index) => (
                                    mensaje.id_estado !== 3 ? (
                                        <Card key={index} className="border border-light-subtle my-3 mx-2 d-flex flex-column position-relative">
                                            <div className="container">

                                                <div className="row my-2">
                                                    <Typography>{mensaje.estado + " " + moment(mensaje.fecha_envio).format("DD MMM YYYY HH:mm")}</Typography>
                                                    <Typography>{parseInt(mensaje.id_portero_send) === 0 ? ("Todos ") : (`${Global.GlobalUser.Nombre} `)}</Typography>
                                                </div>
                                                <div>
                                                    <Typography sx={{ mb: 0.2 }} color="text.secondary" className="text-justify mb-2" style={{ fontSize: '11px' }}>{mensaje.mensaje}</Typography>
                                                    <div>
                                                        <TextField
                                                            value={this.state.Mensajes[index].respuesta}
                                                            multiline
                                                            fullWidth
                                                            placeholder="Cuerpo del Mensaje"
                                                            helperText={`${this.state.Mensajes[index].respuesta.length} de 250 caracteres`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            onChange={(e) => this.RespuestaManual(e.target.value, index)}
                                                            inputProps={{ maxLength: 250 }}
                                                        />
                                                        <div className="d-flex justify-content-center text-center mb-2">
                                                            <Tooltip title="Respuesta Manual">
                                                                <button className="btn btn-success mx-2 " style={{ fontSize: '9px' }} onClick={() => this.GuardarRespuesta(1, index, 0)}><i className="fa-solid fa-paper-plane" ></i> Respuesta Manual</button>
                                                            </Tooltip>
                                                            <Tooltip title="Respuesta Automática">
                                                                <button className="btn btn-primary  " style={{ fontSize: '9px' }} onClick={() => this.GuardarRespuesta(0, index, 0)}><i className="fa-solid fa-reply fa-flip-horizontal"></i> Respuesta Automática</button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                {parseInt(mensaje.nota) === 0 ? (
                                                    <div className="my-2 text-center">
                                                        <Tooltip title="Enviar mensaje a notas">
                                                            <button className="btn btn-warning mx-1 " style={{ fontSize: '12px' }} onClick={() => this.PasarNotas(index, 0)}><i className="fa-regular fa-note-sticky" ></i> Enviar mensaje a notas</button>
                                                        </Tooltip>
                                                    </div>
                                                ) : ("")}
                                            </div>
                                        </Card>
                                    ) : ("")
                                ))}
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : (
                    <Redirect to={Global.Ruta.Raiz + "/porteria-msn"} />
                )
            );
        }

        return (
            <Loader princial={true} uno={"Mensajes"} dos={"Respuestas"} tres={"Comunicación"} />
        );
    }
}
