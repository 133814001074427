import React, { Component } from "react";
import HeadTitle from "../../principal/HeadTitle";
import TableVisitAnunci from "../Home/compos/subCompPort/TableVisitAnunci";
import { DspachPendDeliv, DspachPendPaqu } from "../porteria/despacho/DspachPendient";
import Global from "../../../Global";
import axios from "axios";
import swal from "sweetalert";
import { Modales } from "../Home/compos/CardCopyLink";


export default class DispatchPorteria extends Component {
  state = {
    tab: 3,
    btnPack: false,
    btnDeliv: false,
    btnVisit: false,
    Anunciados: [],
    portPackBtns: [
      {
        id: 1,
        nombre: "PAQUETE",
        bgColor: "secondary",
        icon: "boxes-packing",
        mod: false,
        size: false,
        button: 'Despachar'
      },
      {
        id: 2,
        nombre: "VISITANTES",
        bgColor: "warning",
        icon: "id-card-clip",
        mod: false,
        size: true,
        button: 'Validar'
      },
    ],
    data: false,
  };

  componentWillMount() {
    this.BuscaAnunciados()
  }

  componentDidMount() {
    this.timerID = setInterval(() =>
      this.BuscaAnunciados(),
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  SwityTbl = (llave) => {
    this.setState({
      btnPack: false,
      btnDeliv: false,
      btnVisit: false,
      tab: llave,
    });

    switch (llave) {
      case "1":
        this.setState({
          btnPack: true,
        });
        break;
      case "2":
        this.setState({
          btnDeliv: true,
        });
        break;
      case "3":

        this.BuscaAnunciados()
        break;
      default:
        this.setState({
          btnPack: true,
        });
        break;
    }
   

  };

  BuscaAnunciados = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/visitantesanunciados`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          Anunciados: res.data.Anunciados,
          btnVisit: true,
        });
      } else {
        swal({
          title: "Atención",
          text: `${res.data.message}`,
          icon: "warning",
          button: true,
        })
      }
    })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  }

  openModal = (e, i) => {
    e.preventDefault();
    let temp = [...this.state.portPackBtns];
    let index = this.IndexCambio(i, temp);
    temp[index].mod = true;
    this.setState({ portPackBtns: temp });
  };

  closeModal = (i) => {
    let temp = [...this.state.portPackBtns];
    let index = this.IndexCambio(i, temp);
    temp[index].mod = false;
    this.setState({ portPackBtns: temp });
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };



  render() {

    const { btnPack, btnDeliv, btnVisit } = this.state

    return (
      <div className="container-fluid">
        <div className="g-r-prf2 pt-3 row-gap-3 h-100">
          <div className="col-12">
            <HeadTitle
              classNameOwn="row"
              titulo={parseInt(this.state.tab)===3 ? ("Modulo de Visitantes"):(parseInt(this.state.tab)===2 ? ("Modulo de Domicilios"):("Modulo de Paquetes"))}
              actionButton={
                <div className="row justify-content-end">


                  {this.state.portPackBtns.map((modal, index) => (
                    <Modales key={index} modal={modal} close={() => this.closeModal(modal.id)} />

                  ))}


                 <div className="col-12 col-lg-auto me-0 me-lg-5">
                  <div className="row gap-0 justify-content-end justify-content-lg-start pe-0 pe-lg-5">
                  {this.state.portPackBtns.map((btns) => (
                    <div className="col-auto" key={btns.id}>
                      <button
                        className={`btn btn-outline-${btns.bgColor} `}
                        onClick={(e) => this.openModal(e, btns.id)}
                      >
                        <i className={`fa-solid fa-plus me-2`} />
                        <span>{btns.nombre}</span>
                      </button>
                    </div>
                  ))}
                  </div>
                 </div>

                  <div className="col-auto d-grid">
                    <button className={`btn btn-outline-primary ${btnVisit ? 'active' : ''}`} onClick={() => this.SwityTbl("3")}>
                      Visitantes
                    </button>
                  </div>
                  <div className="col-auto d-grid">
                    <button
                      className={`btn btn-outline-primary ${btnDeliv ? 'active' : ''}`} onClick={() => this.SwityTbl("2")}>
                      Domicilios
                    </button>
                  </div>
                  <div className="col-auto d-grid">
                    <button
                      className={`btn btn-outline-primary ${btnPack ? 'active' : ''}`} onClick={() => this.SwityTbl("1")} >
                      Paquetes
                    </button>
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-12">
            {
              (this.state.btnVisit) ? (
                <TableVisitAnunci data={this.state.Anunciados} />
              ) : (
                (this.state.btnDeliv) ? (
                  <DspachPendDeliv />
                ) : (
                  (this.state.btnPack) ? (
                    <DspachPendPaqu />
                  ) : (
                    <div className="d-flex justify-content-center bg-light text-info flex-column align-items-center h-100">
                      <div
                        className="spinner-border fs-3 m-3"
                        style={{ width: "5rem", height: "5rem" }}
                        role="status"
                      ></div>
                      <p className="fs-3">Cargando...</p>
                    </div>
                  )
                )
              )
            }
          </div>
        </div>
      </div>
    );
  }
}
