import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Tooltip } from "@mui/material";
import swal from "sweetalert";
import Global from "./../../../../Global";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import MaterialTable from 'material-table';
import Loader from "../../../principal/Loader";

export default class TablaCorreos extends Component {

    state = {
        data: false,
        Envios: [],
        Row: [],

    }

    componentWillMount() {

        this.Envios()
    }

    Envios = () => {

        this.setState({ data: false })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}newbd/infocorreossend`, body).then((res) => {
            if (res.data.message === "") {
                var row = [
                    {
                        field: "nombre_ph",
                        title: "Propiedad Horizontal",
                        width: "30%",
                        cellStyle: { paddingRight: 0, paddingLeft: 0, },
                    },
                    {
                        field: "codigo_ph",
                        title: "Código",
                        width: "5%",
                    },
                    {
                        field: "partner",
                        title: "Partner",
                        align: "left",
                    },
                    {
                        field: "fecha_envio",
                        title: "Fecha",
                        render: data => moment(data.fecha_envio).format("DD MMM YYYY HH:mm:ss"),
                        align: "left",
                        cellStyle: { paddingRight: 0, paddingLeft: 0, },

                    },
                    {
                        field: "total_inmuebles",
                        title: "Inmuebles",
                        width: "5%",
                    },
                    {
                        field: "total_envios",
                        title: "Enviados",
                        width: "5%",
                    },
                    {
                        field: "envia",
                        title: "Enviado por",
                    },
                ];

                this.setState({
                    Envios: res.data.Envios,
                    Row: row,
                    data: true,
                })

            } else {
                swal({
                    title: "Atención",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                })
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.props.onHide()
            });
        });

    }


    render() {

        if (this.state.data) {
            return (
                <div className='g-r-prf1 h-100'>
                    <Modal.Header closeButton>
                        <span className='fw-bold fs-5'> Correos Enviados</span>
                    </Modal.Header>
                    <Modal.Body>
                        <MaterialTable
                            title={
                                <Tooltip title={"Actualizar"}>
                                    <button className='btn btn-light btn-sm '
                                        onClick={() => { this.Envios() }}>
                                        <i className="fa-solid fa-rotate" style={{ color: "#c1c4c7" }}></i>
                                    </button>
                                </Tooltip>
                            }
                            columns={this.state.Row}
                            data={this.state.Envios}
                            options={{
                                pageSize: 5,
                                actionsColumnIndex: -1,
                                exportButton: false,
                                exportAllData: false,
                                rowStyle: (rowData) => {
                                    if (parseInt(rowData.tableData.id + 1) % 2 === 0
                                    ) {
                                        return {
                                            backgroundColor: "#eeeeee",
                                            fontSize: "0.9rem",
                                        };
                                    }

                                    return {
                                        backgroundColor: "#ffffff",
                                        fontSize: "0.9rem",
                                    };

                                },
                                headerStyle: {
                                    fontWeight: 'bold'
                                },
                            }
                            }
                            localization={{
                                header: {
                                    actions: "Estado",
                                },
                                body: {
                                    emptyDataSourceMessage: "No hay datos por mostrar.",
                                },
                                pagination: {
                                    labelRowsSelect: "registros",
                                    labelDisplayedRows: "{from}-{to} de {count}",
                                    firstTooltip: "Primera página",
                                    previousTooltip: "Página anterior",
                                    nextTooltip: "Próxima página",
                                    lastTooltip: "Última página",
                                },
                                toolbar: {
                                    searchTooltip: "Buscar",
                                    searchPlaceholder: "Buscar",
                                    exportCSVName: "CSV",
                                    exportPDFName: "PDF"
                                },
                            }}
                        />

                    </Modal.Body>

                </div>
            )
        }
        return (
            <div style={{ minHeight: "70vh" }}>
                <Loader princial={true} uno={"Data Base"} dos={"Códigos"} tres={"Partner"} />
            </div>
        );


    }
}