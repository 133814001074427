import React, { Component } from 'react'

export default class SidebarReportes extends Component {
  render() {
    const { indexBtn, setPanel, mainWind } = this.props
    return (
      <div className='col nav_report_main py-3 shadow rounded-end' style={{ maxWidth: '9em', cursor: "pointer" }}>
        <ul className="pt-3">
          {indexBtn.map(({ id, icon, label }) => (
            <li className={parseInt(id) === parseInt(mainWind) ? "active" : ""} key={id} onClick={() => setPanel(id)}>
              <i className={icon} />
              <span className="text-center">{label}</span>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
