
import MaterialTable from 'material-table';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import "moment/locale/es";
import React, { Component } from 'react'
import Global from '../../../../Global';
import axios from 'axios';
import swal from 'sweetalert';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Autocomplete, TextField } from '@mui/material';
import Es from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { TextField } from "@material-ui/core";
import { Modal } from 'react-bootstrap';
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";

export default class RAsistenciaReserva extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect_home: false,
            Zonas: [],
            fechaI: '',
            fechaF: '',
            tableHeads: [
                {
                    field: "nombres",
                    title: "Nombre",
                    width: "25%",
                },
                {
                    field: "direccion",
                    title: "Dirección",
                    width: "20%",
                },
                {
                    field: "nombrerecurso",
                    title: "Recurso",
                    width: "20%",
                },
                {
                    field: "fechareserva",
                    title: "Fecha Reserva",
                    width: "15%",
                },
                {
                    field: "horareserva",
                    title: "Hora",
                    width: "10%",
                    render: rowData => (parseInt(rowData.horareserva) > 0) ? `${this.convertMinsToHrsMins(rowData.horareserva)}` : `00:00`
                },
                {
                    field: "asistencia",
                    title: "Asistencia",
                    width: "10%",
                    render: rowData => (rowData.asistencia !== null && rowData.asistencia !== false) ? "Si" : "No"
                },
            ],
            id_recurso: "",

            data: [],
            datatable: false,
            btnfechaF: true,
            btnfiltro: true,

            selectedValue: "",
            filtro: true,
        }
    }

    componentDidMount() {
        axios.get(Global.Ruta.Url + "admin/nomrecursos/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from(Global.Ruta.Ipin).toString("base64")).then((res) => {
            if (res.data.message === "") {
                this.setState({
                    Zonas: res.data.zonas,
                    datatable: true
                });
            } else if (res.data.message === "ERROR") {
                swal({
                    title: "ALERTA!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ redirect_home: true });
                });
            } else {
                swal({
                    title: "ALERTA!",
                    text: res.data.message,
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ redirect_home: true });
                });
            }
        }).catch((err) => {
            this.setState({
                redirect_home: true,
            });
        });
    }

    convertMinsToHrsMins(minutes) {
        var h = Math.floor(minutes / 60);
        var m = minutes % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        return h + ':' + m;
    }

    Ronda = (caso) => {
        this.setState({ PpHourglass: true, datatable: false });
        var Fechai = ""
        var Fechaf = ""

        if (this.state.fechaI !== "" && this.state.fechaF !== "") {
            Fechai = moment(this.state.fechaI).format("YYYY/MM/DD")
            Fechaf = moment(this.state.fechaF).format("YYYY/MM/DD")
        }
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            fechai: Buffer.from("" + Fechai).toString("base64"),
            fechaf: Buffer.from("" + Fechaf).toString("base64"),
            idrecurso: Buffer.from("" + this.state.id_recurso).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}admin/reservasasistencia`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({
                    data: res.data.Reservas,
                    PpHourglass: false,
                    datatable: true
                });
            } else {
                this.setState({
                    data: [],
                    PpHourglass: false,
                    datatable: true
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({
                    redirect_home: true,
                });
            });
        });
    };

    QuitarFiltros = () => {
        this.setState({ filtro: false })
        this.setState({
            fechaI: '',
            fechaF: '',
            id_recurso: "",
            selectedValue: "",
            btnfechaF: true,
        }, () => { this.setState({ filtro: true }) })

    }

    Filtro = () => {
        if (this.state.fechaI !== "" && this.state.fechaF !== "" && this.state.id_ronda !== "") {
            this.Ronda(1)
        } else if (this.state.fechaI !== "" && this.state.fechaF !== "" && this.state.id_ronda === "") {
            this.Ronda(2)
        } else if (this.state.fechaI === "" && this.state.fechaF === "" && this.state.id_ronda !== "") {
            this.Ronda(3)
        }
    }

    render() {

        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
        }

        const Tabla = (props) => {
            return (
                <MaterialTable
                    title='Asistencia Reserva'
                    columns={this.state.tableHeads}
                    data={this.state.data}
                    options={{
                        rowStyle: { fontSize: "0.9rem" },
                        pageSize: 10,
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                    }}
                    localization={{
                        header: {
                            actions: "Estado",
                        },
                        body: {
                            emptyDataSourceMessage: "No hay datos por mostrar.",
                        },
                        pagination: {
                            labelRowsSelect: "registros",
                            labelDisplayedRows: "{from}-{to} de {count}",
                            firstTooltip: "Primera página",
                            previousTooltip: "Página anterior",
                            nextTooltip: "Próxima página",
                            lastTooltip: "Última página",
                        },
                        toolbar: {
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Buscar",
                            exportCSVName: "CSV",
                            exportPDFName: "PDF"
                        },
                    }}
                />
            )
        }

        return (
            <div className='row bg-white shadow rounded-3'>
                <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>
                {/* menu de opciones, depende del tipo de reporte */}

                {this.state.filtro ? (
                    <div className='col-12'>
                        <div className='row py-3 px-2'>
                            <div className="col-12 col-md-4 col-xl-2">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                                    <DatePicker
                                        label="Fecha Inicio"
                                        value={this.state.fechaI}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.setState({ fechaI: date, btnfechaF: false })
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es} >
                                    <DatePicker
                                        disabled={this.state.btnfechaF}
                                        label="Fecha Final"
                                        value={this.state.fechaF}
                                        minDate={this.state.fechaI}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.setState({ fechaF: date })
                                        }}
                                        renderInput={(params) => (<TextField {...params} size="small" />)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2">
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    autoHighlight
                                    noOptionsText="No Encontrado"
                                    value={this.state.selectedValue}
                                    options={this.state.Zonas.map((data) => data.nombrerecurso)}
                                    renderInput={(params) => (<TextField fullWidth  {...params} placeholder="Selecciona" label="Lugar" size="small" />)}
                                    onChange={(event, newValue) => {
                                        const select = this.state.Zonas.find((data) => data.nombrerecurso === newValue);
                                        if (select) {
                                            this.setState({ selectedValue: newValue },
                                                () => {
                                                    this.setState({ id_recurso: select.idrecurso })
                                                })
                                        }
                                    }} />
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-primary"
                                    disabled={
                                        (this.state.id_recurso !== "" && this.state.fechaI !== "" && this.state.fechaF !== "") ?
                                            false : true
                                    } onClick={() => this.Filtro()}>
                                    Filtrar
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-danger" disabled={
                                    (this.state.id_recurso !== "" && this.state.fechaI !== "" && this.state.fechaF !== "") ?
                                        false : true
                                } onClick={() => this.QuitarFiltros()}>
                                    Limpiar
                                </button>
                            </div>
                        </div>
                    </div>
                ) : ("")}
                {/* contenido del reporte */}
                <main className="col-12 px-0">
                    {this.state.datatable ? (
                        <Tabla />) : (
                        <div className="d-flex justify-content-center bg-with text-info flex-column align-items-center min-vh-100">
                            <div
                                className="spinner-border fs-3 m-3"
                                style={{ width: "5rem", height: "5rem" }}
                                role="status"></div>
                            <p className="fs-3">Cargando...</p>
                        </div>
                    )}
                </main>
            </div>
        )
    }
}
