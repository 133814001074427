
import Router from '../Router';
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {

  
  return (
      <Router/>
  );
}

export default App;
