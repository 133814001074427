import React, { Component } from "react";
import { TextField } from "@material-ui/core";

import swal from "sweetalert";

class PpLink extends Component {

    state = {
        Link: "",
        PId: "",
        Pwd: "",
    }

    componentWillMount() {
        this.setState({
            Link: this.props.Link,
            PId: this.props.PId,
            Pwd: this.props.Pwd,
        })
    }

    handleChange = (prop) => (event) => {
        this.setState((state) => ({
            ...state,
            [prop]: event.target.value
        }));
    };

    Guardar = () => {
        if (this.state.Link === "" || this.isValidURL(this.state.Link) === false) {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar el link de la reunión valido.",
                icon: "error",
                button: "Aceptar"
            })
            return
        }
        if (this.state.PId === "") {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar el ID de la reunión.",
                icon: "error",
                button: "Aceptar"
            })
            return
        }
        if (this.state.Pwd === "") {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar la clave de la reunión.",
                icon: "error",
                button: "Aceptar"
            })
            return
        }
        this.props.Guardar(this.state.Link, this.state.PId, this.state.Pwd);
    }

    isValidURL = (url) => {
        //eslint-disable-next-line
        var RegExp = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

        if (RegExp.test(url)) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        return (
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center">
                    <div className="card">
                        <div className="card-header">
                            Link
                        </div>
                        <div className="card-body px-4">
                            <div className="row mb-3">
                                <div className="col">
                                    <TextField value={this.state.Link} onChange={this.handleChange("Link")} variant="outlined" fullWidth label="URL"></TextField>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <TextField value={this.state.PId} onChange={this.handleChange("PId")} variant="outlined" label="ID Asamblea"></TextField>
                                </div>
                                <div className="col">
                                    <TextField value={this.state.Pwd} onChange={this.handleChange("Pwd")} variant="outlined" label="Contraseña Asamblea"></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row justify-content-center">
                                <button className="col-4 btn btn-success mx-1" onClick={this.Guardar}>Guardar</button>
                                <button className="col-4 btn btn-danger mx-1" onClick={this.props.onClose} >Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PpLink