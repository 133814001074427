import React, { Component } from 'react'


export default class TextInfo extends Component {



  render() {

  let { titulo = 'titulo ejemplo', texto = 'texto ejemplo', color = 'primary', opacity = '25', dark = true, darkText = true, large=false} = this.props

    return (
      <div className={`card bg-${color} bg-opacity-${opacity}`}>
        <div className="row px-3 py-1">
          <div className={`col-auto bg-${dark?'secondary':'light'} rounded-3`}>
            <p className={`m-0 text-${dark?'light':'dark'} ind-txt-sm text-capitalize fw-normal`}>{titulo}</p>
          </div>
          <div className="col overflow-auto">
            <div className={`${large?'':'cover-marqss'}`}>
              <p className={`m-0 text-${darkText?'dark':'light'} ind-txt-sm ${large?'':'marqss'} fw-normal`}>{texto}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}