import React, { Component } from "react";
import { InputAdornment, TextField, IconButton, Snackbar } from "@mui/material";
import { Modal } from "react-bootstrap";
import Global from "./../../../../Global";
import axios from "axios";
import QrCode from "qrcode.react";

import jsPDF from "jspdf";
import ModalCreatePDV from "./subCompPort/ModalCreatePDV";

export function Modales({ modal, close }) {
  return (
    <Modal
      show={modal.mod}
      key={modal.id}
      centered
      backdrop="static"
      fullscreen={modal.size}
      onHide={() => close(modal.id)}
    >
      <ModalCreatePDV info={modal} cerrar={() => close(modal.id)} />
    </Modal>
  )
}

export default class CardCopyLink extends Component {
  state = {
    link: ``,
    CodigoPH: "",
    snackBar: false,
    modalQR: false,
    portPackBtns: [
      {
        id: 1,
        nombre: "CREAR PAQUETE",
        bgColor: "secondary",
        icon: "boxes-packing",
        mod: false,
        size: false,
        button: 'Despachar'
      },
      {
        id: 2,
        nombre: "VISITANTES",
        bgColor: "warning",
        icon: "id-card-clip",
        mod: false,
        size: true,
        button: 'Validar'
      },
    ],
  };

  componentWillMount() {
    var body = {
      ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}preregistro/linkqr/`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            link: `${Global.Ruta.Url2}registro/${res.data.CodigoPH}`,
            CodigoPH: res.data.CodigoPH,
          });
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  getPDF = () => {
    const CodQR = document.getElementById("capture");

    var doc = new jsPDF();

    doc.html(CodQR, {
      callback: function (doc) {
        doc.save("GoPH QR-COD.pdf");
      },
      y: 50,
      html2canvas: { scale: 0.43 },
    });
  };

  CopyLink = () => {
    let aux = document.createElement("input");
    aux.setAttribute("value", this.state.link);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");

    this.setState({ snackBar: true });

    document.body.removeChild(aux);
  };

  openModal = (i) => {
    let temp = [...this.state.portPackBtns];
    let index = this.IndexCambio(i, temp);
    temp[index].mod = true;
    this.setState({ portPackBtns: temp });
  };

  closeModal = (i) => {
    let temp = [...this.state.portPackBtns];
    let index = this.IndexCambio(i, temp);
    temp[index].mod = false;
    this.setState({ portPackBtns: temp });
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  render() {

    return (
      <div className="card card-copy-link">
        <Snackbar
          open={this.state.snackBar}
          autoHideDuration={3000}
          onClose={() => this.setState({ snackBar: false })}
          message="Link Copiado"
          action={
            <button
              className="btn"
              onClick={() => this.setState({ snackBar: false })}
              style={{ borderRadius: "50%" }}
            >
              <i className="fa-solid fa-times m-0" />
            </button>
          }
        />

        <Modal
          show={this.state.modalQR}
          centered
          backdrop="static"
          onHide={() => this.setState({ modalQR: false })}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div
              className="row align-items-center justify-content-center h-100"
              id="capture"
            >
              <h4 className="text-center mb-5">Código QR pre-registro GoPH</h4>
              <div className="col-auto">
                <QrCode value={this.state.CodigoPH} size="200" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row justify-content-center">
              <div className="col-6 d-grid">
                <button
                  className="btn btn-primary"
                  id="snap"
                  onClick={() => this.getPDF()}
                >
                  PDF
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        {this.state.portPackBtns.map((modal) => (
          <Modales modal={modal} close={this.closeModal} />
        ))}

        <div className="row px-4 py-2 align-items-center">
          {(Global.GlobalUser.rol === 1 || Global.GlobalUser.rol === 2) ? (
            <div className="col">
              <TextField
                fullWidth
                size="small"
                value={this.state.link}
                label="link de pre registro"
                id="outlined-start-adornment"
                placeholder="copiar link"
                disabled={true}
                ref={(textarea) => (this.textArea = textarea)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disabled={true}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.CopyLink}
                        edge="end"
                      >
                        <i className="fa-solid fa-link" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : (
            <div className="col">
              <TextField
                fullWidth
                size="small"
                value={this.state.link}
                label="link de pre registro"
                id="outlined-start-adornment"
                placeholder="copiar link"
                disabled={true}
                ref={(textarea) => (this.textArea = textarea)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disabled={true}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.CopyLink}
                        edge="end"
                      >
                        <i className="fa-solid fa-link" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <div className="row row-cols-2 column-gap-2 flex-nowrap">
                {this.state.portPackBtns.map((btns) => (
                  <div className="col d-grid" key={btns.id}>
                    <button
                      className={`btn btn-${btns.bgColor} rounded-pill text-nowrap`}
                      onClick={() => this.openModal(btns.id)}
                    >
                      <i className={`fa-solid fa-${btns.icon} me-2`} />
                      <span>{btns.nombre}</span>
                    </button>
                  </div>
                ))}
              </div> */}
            </div>
          )}
          <div className="col-auto">
            <button
              className="btn btn-primary rounded-pill px-4"
              onClick={() => this.setState({ modalQR: true })}
            >
              <i className="fa-solid fa-qrcode me-1" />
              Codigo QR
            </button>
          </div>
        </div>
      </div>
    );
  }
}

