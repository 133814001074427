import React, { Component } from 'react'
import { Button, Form} from 'react-bootstrap'

export default class CampoDeTextoPsw extends Component {

  render() {
      let looking = this.props.look ? 'password':''
    return (
        <div className='input-group d-flex'>
            <Form.Floating className='col'>
                <Form.Control className='shadow-sm' id={this.props.id} type={looking} placeholder={'ph'} fullWidth
                value={this.props.valor} onChange={(event)=>this.props.writhing(this.props.id,event.target.value)} aria-describedby="button-addon"
                />
                <label htmlFor={this.props.id}>
                <i className={`fa-solid fa-${this.props.icono} me-2`}/>
                {this.props.label}
                </label>
            </Form.Floating>
            <Button variant="outline-secondary" onClick={this.props.handleLook} aria-describedby="button-addon">
                {(this.props.look === true)?
                    (<i className={`fa-solid fa-eye`}/>):
                    (<i className={`fa-solid fa-eye-slash`}/>)}
            </Button>
        </div>
    )
  }
}
