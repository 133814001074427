import React, { Component } from "react";
import HeaderA2 from "./Home/HeaderA2";
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";
import PorteriaAdmin from './porteria/PorteriaAdmin'

export default class PorteriaComunicacion extends Component {
    state = {
        redirect_home: false,
    };
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina("PorteriaComunicacion") });
    }
    render() {


        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
        }
        return (
            <div className="h-100">
                <HeaderA2 />
                <PorteriaAdmin />
            </div>
        );
    }
}
