import React, { Component } from "react";
import { TextField } from "@material-ui/core";

import swal from "sweetalert";

class PpClsAsam extends Component {

    state = {
        DetalleCierre: "",
    }

    handleChange = (prop) => (event) => {
        this.setState((state) => ({
            ...state,
            [prop]: event.target.value
        }));
    };

    Guardar = () => {
        if (this.state.DetalleCierre === ""){
            swal({
                title: "ALERTA!",
                text: "Debe ingresar un detalle del cierre de la asamblea.",
                icon: "error",
                button: "Aceptar"
            })
            return
        }

        this.props.CerrarAsamblea(this.state.DetalleCierre);

    }

    render() {

        return (
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12 col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            Finalizar Asamblea
                        </div>
                        <div className="card-body px-4">
                            <h5 className="card-title text-center">¿Esta seguro de Cerrar la Asamblea?</h5>
                            <div className="row mb-3">
                                <div className="col">
                                    <TextField value={this.state.DetalleCierre} onChange={this.handleChange("DetalleCierre")} variant="outlined" fullWidth label="Detalle Cierre"></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row justify-content-center">
                                <button className="btn btn-success col-4 col-lg-3 mx-1 " onClick={this.Guardar}>Cerrar Asamblea</button>
                                <button className="btn btn-danger col-4 col-lg-3 mx-1 text-nowrap" onClick={this.props.onClose} >Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PpClsAsam