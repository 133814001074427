import React, { Component } from 'react'

import NavbarInformes from './NavbarInformes'
import TablaInfoProderes from './TablasInformes/TablaInfoPoderes'

export default class InformesPoderes extends Component {

    state = {
        titulo: "",
    }

    componentWillMount() {
        this.setState({
            titulo: `Informe REPRESENTACIONES`
        })
    }

    stateTotalPoderes = (total) => {
        this.setState({
            Total: total,
            titulo: `Informe REPRESENTACIONES - Total de representaciones: ${total}`
        })
    }

    render() {
        return (
            <div className="g-r-prf3 bg-light vh-100">
                <NavbarInformes titulo={this.state.titulo}/>
                <TablaInfoProderes TotalPoderes={this.stateTotalPoderes}/>
                 
            </div>
        )
    }
}
