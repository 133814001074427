import React, { Component } from "react";
import '../../../assets/css/reservas.css';

//--------IMPORT COMPONENTS--------

import HeaderA2 from '../Home/HeaderA2';
import ContainerReservas from './ContainerReservas';
import { Redirect } from "react-router-dom";
import Global from "../../../Global";
import { ValidarRolPagina } from "../../Helpers";

class Reservas extends Component {
    rstate = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('Reserva') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
        }
        return (
            <div className="g-r-prf3 vh-100">
                <HeaderA2 />
                <ContainerReservas />
            </div>
        );
    }
}

export default Reservas;