import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap';
import Global from '../../../../Global';
import axios from "axios";
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from 'simple-react-validator'

export default class FormEditVehiculo extends Component {

    state = {
        modal: false,

        id: "",
        color: "",
        marca: "",
        placa: "",
        tipo: "-1",
        inmuebleid: "",
        Suscriptores:"",
        suscriptor_id:"",
        TipoCarro: ["MOTOCICLETA", "AUTOMÓVIL", "CAMPERO", "CAMIONETA", "MOTOCARRO", "CUATRIMOTO", "MICROBÚS", "CAMIÓN"],
        Colores: ["AMARILLO", "AZUL", "BLANCO", "CAFE", "GRIS", "MORADO", "NARANJA", "NEGRO", "ROJO", "ROSADO", "VERDE"],

        redirect_home: false,
    }

    //VALIDA EL ESTADO
    componentWillMount() {
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                alpha_dash: 'El campo es requerido.',
                alpha_num: 'Solo se permiten letras y números.',
                min: 'La Placa debe tener mínimo 5 caracteres.'
            }
        });
        this.setState({
            id: this.props.Data.id,
            color: this.props.Data.color,
            marca: this.props.Data.marca,
            placa: this.props.Data.placa,
            tipo: this.props.Data.tipo,
            inmuebleid: this.props.InmuebleId,
            Suscriptores:this.props.Suscriptores,
            suscriptor_id:this.props.Data.susc_id,
        })

        console.log(this.props.Data)
    }

    //GUARDA LOS NUEVOS DATOS
    GuardarInsert = () => {

        if (this.validator.allValid()) {
            this.setState({ modal: true })

            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                inmuebleid: Buffer.from("" + this.state.inmuebleid).toString("base64"),
                tipo: Buffer.from("" + this.state.tipo).toString("base64"),
                marca: Buffer.from("" + this.state.marca).toString("base64"),
                color: Buffer.from("" + this.state.color).toString("base64"),
                placa: Buffer.from("" + this.state.placa).toString("base64"),
                suscriptor_id: Buffer.from("" + this.state.suscriptor_id).toString("base64"),
            }
            axios.post(`${Global.Ruta.Url}admin/insertvehiculo/`, body).then(res => {
                if (res.data.message === "") {
                    var VehiculoNew = {
                        id: res.data.id,
                        color: this.state.color,
                        marca: this.state.marca,
                        placa: this.state.placa,
                        tipo: this.state.tipo,
                        susc_id: this.state.suscriptor_id,
                    }
                    this.setState({
                        modal: false
                    })
                    this.props.Insertar(VehiculoNew)
                } else {
                    swal({
                        title: "Atención !!",
                        text: res.data.message,
                        icon: 'warning',
                        button: 'Aceptar',
                    }).then(() => {
                        this.setState({ modal: false })
                    });
                }
            }).catch((err) => {
                swal({
                    title: "ALERTA!",
                    text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then((resp) => {
                    this.setState({ modal: false })
                });
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    GuardarUpdate = () => {

        if (this.validator.allValid()) {
            this.setState({ modal: true })

            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                id: Buffer.from("" + this.state.id).toString("base64"),
                tipo: Buffer.from("" + this.state.tipo).toString("base64"),
                marca: Buffer.from("" + this.state.marca).toString("base64"),
                color: Buffer.from("" + this.state.color).toString("base64"),
                placa: Buffer.from("" + this.state.placa).toString("base64"),
                suscriptor_id: Buffer.from("" + this.state.suscriptor_id).toString("base64"),
            }
            axios.post(`${Global.Ruta.Url}admin/updatevehiculo/`, body).then(res => {
                if (res.data.message === "") {
                    var VehiculoNew = {
                        id: this.state.id,
                        color: this.state.color,
                        marca: this.state.marca,
                        placa: this.state.placa,
                        tipo: this.state.tipo,
                        susc_id: this.state.suscriptor_id,
                    }
                    this.setState({
                        modal: false
                    })
                    this.props.Actualizar(VehiculoNew)
                } else {
                    swal({
                        title: "Atención !!",
                        text: res.data.message,
                        icon: 'warning',
                        button: 'Aceptar',
                    }).then(() => {
                        this.setState({ modal: false })
                    });
                }
            }).catch((err) => {
                swal({
                    title: "ALERTA!",
                    text: "Vaya algo a salido mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then((resp) => {
                    this.setState({ modal: false })
                });
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value.toUpperCase()
        }));
    }

    render() {

        if (this.state.redirect_home === true) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
            )
        }

        return (
            <div>
                <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                    <PpHourglass />
                </Modal>
                <Modal.Header closeButton>
                    {(this.props.Data.caso === 1) ? ("Agregar Vehículo") : ("Editar Vehículo")}
                </Modal.Header>
                <Modal.Body>
                    <div className="row row-cols-2 gy-3">
                        <div className="col">

                            <Form.Group>
                                <label htmlFor="nombre">Tipo Vehículo</label>
                                <Form.Select value={this.state.tipo} onChange={this.handleChange("tipo")}>
                                    <option value="-1">OPCIÓN...</option>
                                    {this.state.TipoCarro.map((data, indice) => (
                                        <option key={indice} value={data}>{data}</option>
                                    ))}
                                </Form.Select>
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('tipo', this.state.tipo, 'required|alpha_dash', { className: 'text-danger' })}</label>

                            </Form.Group>
                        </div>
                        <div className="col">
                            <label htmlFor="nombre">Marca</label>
                            <Form.Control onChange={this.handleChange("marca")} maxLength={20} value={this.state.marca} placeholder={this.state.marca} />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('marca', this.state.marca, 'required', { className: 'text-danger' })}</label>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <label htmlFor="nombre">Color</label>
                                <Form.Select value={this.state.color} onChange={this.handleChange("color")}>
                                    <option value="-1">OPCIÓN...</option>
                                    {this.state.Colores.map((data, indice) => (
                                        <option key={indice} value={data}>{data}</option>
                                    ))}
                                </Form.Select>
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('color', this.state.color, 'required|alpha_dash', { className: 'text-danger' })}</label>
                            </Form.Group>
                        </div>
                        <div className="col">
                            <label htmlFor="nombre">Placa</label>
                            <Form.Control onChange={this.handleChange("placa")} maxLength={10} value={this.state.placa} placeholder={this.state.placa} />
                            <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('placa', this.state.placa, 'required|alpha_num|min:5', { className: 'text-danger' })}</label>
                        </div>
                        <div className="col">
                                <Form.Group>
                                    <label >Suscriptor</label>
                                    <Form.Select value={this.state.suscriptor_id} onChange={this.handleChange("suscriptor_id")}>
                                        {parseInt(this.props.Data.caso) === 1 ? (<option value="-1">OPCIÓN...</option>) : ("")}
                                        {this.state.Suscriptores.map((data, indice) => (
                                            <option key={indice} value={data.suscriptorid}>{data.nombres.toUpperCase()}</option>
                                        ))}
                                    </Form.Select>
                                    <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Suscriptor', this.state.suscriptor_id, 'required', { className: 'text-danger' })}</label>
                                </Form.Group>
                            </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 justify-content-center">
                        <div className="col-3 d-grid">
                            <button className="btn btn-primary" onClick={(parseInt(this.props.Data.caso) === 1) ? (this.GuardarInsert) : (this.GuardarUpdate)}>Guardar</button>
                        </div>
                    </div>
                </Modal.Footer>
            </div >
        )
    }
}
