import React, {Component} from "react";
import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import {CookieSesion} from "../../Helpers";
import {NavLink, Redirect} from "react-router-dom";
import {Tooltip} from "@material-ui/core";
import MaterialTable from "material-table";
import HeadTitle from "../../principal/HeadTitle";

const columns = [
  {
    field: "id",
    title: "Id",
    hidden: true,
  },
  {
    field: "nombre",
    title: "Nombre",
    validate: (rowData) =>
      rowData.nombres === undefined || rowData.nombres === ""
        ? {isValid: false, helperText: "Debe colocar un nombre."}
        : true,
    ///validate: rowData => rowData.nombres === '' ? { isValid: false, helperText: 'Name cannot be empty' } : true,
  },
  {
    field: "size",
    title: "Tamaño",
  },
  {
    field: "fecha",
    title: "Fecha",
    validate: (rowData) =>
      rowData.login === undefined || rowData.login === ""
        ? {isValid: false, helperText: "Debe colocar un Login correcto.."}
        : true,
  },
];

class TablaArchivo extends Component {
  state = {
    lista: [],
    data: false,
    //redirect
    redirect_home: false,
    redirect_login: 0,
    redirect_archivos: false,
    Codigo: "",

    Roles: [1, 2],
  };

  //LISTAR PDS
  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          axios
            .get(
              Global.Ruta.Url +
                "listapdf/" +
                Buffer.from(Global.Ph.Codigo).toString("base64")
            )
            .then((res) => {
              if (res.data.length > 0) {
                var tem = [];
                if (res.data.length > 0) {
                  var file = {};
                  for (let archivo of res.data) {
                    file = {
                      id: archivo.id,
                      nombre: archivo.nombre,
                      size: archivo.size,
                      fecha: archivo.fecha,
                    };
                    tem.push(file);
                  }
                }
                this.setState({lista: tem, data: true});
              } else {
                swal({
                  title: "ALERTA!",
                  text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({redirect_home: true});
                });
              }
            })
            .catch((error) => {
              // Handling Errors using promises
              if (error.response) {
                swal({
                  title: "Upsssssss !!!",
                  text: "Aún no hay documentos publicados.",
                  icon: "error",
                  button: "Cerrar",
                }).then((resp) => {
                  this.setState({
                    redirect_home: true,
                  });
                });
              }
            });
        } else {
          this.setState({redirect_home: true});
        }
      } else {
        this.setState({redirect_login: 1});
      }
    } else {
      this.setState({redirect_login: 1});
    }
  }

  //VER PDF
  AbrirPdf = async (ide, nombre) => {
    axios(
      Global.Ruta.Url +
        "leerpdf/" +
        Buffer.from(Global.Ph.Codigo).toString("base64") +
        "/" +
        Buffer.from(nombre + ".pdf").toString("base64"),
      {
        method: "GET",
        responseType: "blob",
        //Force to receive data in a Blob Format
      }
    )
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf",
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        // Handling Errors using promises
        if (error.response) {
          /* El servidor respondió con un código fuera del rango de 2xx */

          swal({
            title: "Upsssssss !!!",
            text: "El documento solicitado no se encuentra disponible.",
            icon: "error",
            button: "Cerrar",
          });
        } else if (error.request) {
          /* Se realizó la solicitud pero no se recibió respuesta */

          swal({
            title: "Upsssssss !!!",
            text: "El servidor no se encuentra disponible en este momento.",
            icon: "error",
            button: "Cerrar",
          });
        } else {
          /* Algo sucedió al configurar la solicitud y desencadenó un error */

          swal({
            title: "Upsssssss !!!",
            text: "Error en la carga del archivo PDF.",
            icon: "error",
            button: "Cerrar",
          });
        }
      });
  };

  //ELIMINAR PDF
  eliminar = (nombre, id) => {
    swal({
      title: "ATENCIÓN!!",
      text: "Seguro que quiere eliminar el archivo: " + nombre + ".pdf",
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result === true) {
        this.setState({
          data: false,
        });
        axios
          .get(
            Global.Ruta.Url +
              "admin/eliminararchivo/" +
              Buffer.from(Global.Ph.Codigo).toString("base64") +
              "/" +
              Buffer.from(nombre + ".pdf").toString("base64")
          )
          .then((response) => {
            if (response.data.res === "OK") {
              swal({
                title: "ATENCIÓN !!",
                text:
                  "el archivo " +
                  nombre +
                  ".pdf, se ha eliminado correctamente.",
                icon: "success",
                button: "Aceptar",
              }).then(() => {
                let tem = this.state.lista;
                for (var i = 0; i < tem.length; i++) {
                  if (tem[i].id === id) {
                    tem.splice(i, 1);
                    i = tem.length + 1;
                  }
                }
                this.setState({
                  lista: tem,
                });
                this.setState({
                  data: true,
                });
              });
            } else {
              this.setState({
                data: true,
              });
            }
          })
          .catch((error) => {
            swal({
              title: "Upsssssss !!!",
              text:
                "Error al eliminar el archivo " +
                nombre +
                ".pdf, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Cerrar",
            });
            this.setState({
              data: true,
            });
          });
      }
    });
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.data === true) {
      return (
        <div className="container-fluid bg-light">
          <div className="row py-3">
            <div className="col-12">
              <HeadTitle
                titulo="Historial de Publicaciones"
                subTitulo="Visualizar y eliminar archivos publicados"
                classNameOwn="row px-3"
                indicadores={[]}
                actionButton={
                  <div className="col-auto my-2 d-flex justify-content-end">
                    <div className="row row-cols-2 gx-1">
                      <div className="col-auto">
                        <NavLink
                          className="btn btn-outline-primary"
                          exact
                          to={Global.Ruta.Raiz + "/subir-archivo"}
                        >
                          Publicar Archivo
                        </NavLink>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="col-12 my-3 shadow">
              <MaterialTable
                columns={columns}
                data={this.state.lista}
                title=""
                actions={[
                  {
                    onClick1: (event, rowData) =>
                      this.AbrirPdf(rowData.id, rowData.id + rowData.nombre),
                    onClick2: (event, rowData) =>
                      this.eliminar(rowData.id + rowData.nombre, rowData.id),
                  },
                ]}
                components={{
                  Action: (props) => (
                    <div
                      className="row gy-1 my-1 justify-content-center"
                      style={{width: "10rem"}}
                    >
                      <div className="col d-grid">
                        <Tooltip title="Abrir PDF" placement="left-start">
                          <button
                            className="btn btn-primary "
                            onClick={(event) =>
                              props.action.onClick1(event, props.data)
                            }
                            style={{fontSize: "0.8rem"}}
                          >
                            Ver documento
                          </button>
                        </Tooltip>
                      </div>
                      <div className="col d-grid">
                        <Tooltip
                          title="Eliminar Archivo "
                          placement="left-start"
                        >
                          <button
                            onClick={(event) =>
                              props.action.onClick2(event, props.data)
                            }
                            className="btn btn-danger"
                            style={{fontSize: "0.8rem"}}
                          >
                            Eliminar
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  ),
                }}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [5, 10, 50],
                  actionsColumnIndex: -1,
                  rowStyle: {fontSize: "0.9rem"},
                  actionsCellStyle: {width: "16rem"},
                }}
                localization={{
                  header: {
                    actions: "Editar",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default TablaArchivo;
