import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Global from '../Global';

import UsersAdm from './admin/UserPage';
import SuscAdm from './admin/SuscPage';

import Login from './principal/Login';

//porteria
import LibroPropietarios from './admin/LibroPropietarios';
import PageVehiculo from './admin/porteria/ListVehiculos';
import Porteria from './admin/Porteria'

// ADMINISTRATIVA
import homeAdm from './admin/HomeAdm';

// ASAMBLEA
import Asamblea from './admin/Asamblea';
//INFORMES
import InformesAsistencia from './admin/Asamblea/MenuInformes/InformesAsistencia'
import InformesPoderes from './admin/Asamblea/MenuInformes/InformesPoderes';
import InformesPreguntas from './admin/Asamblea/MenuInformes/InformesPreguntas';
import InformesAusentes from './admin/Asamblea/MenuInformes/InformesAusentes';
// PUSH Y DOCUMENTOS
import SubirArchivo from './admin/SubirArchivo';
import RevisarArchivo from './admin/RevisarArchivo';
import SistemaMsn from './admin/SistemaMensajes';

// RESERVAS
import Reserva from './admin/reservas/Reserva';

// USUARIO Y SUSCRIPTOR
import FormUser from './admin/Usuario/FormUser';
import FormSusc from './admin/Suscriptor/FormSusc';

// ZONA SOCIAL
import ZonaSocial from './admin/ZonaSocial';

//COMUNICACION
import Autorizaciones from './admin/Autorizaciones'

// SOLICITUDES DE LA COMUNIDAD A LA ADMIN
import SolicitudesComu from './admin/SolicitudesComu'
import HistorialPeticiones from './admin/HistorialPeticiones';
import PorteriaComunicacion from './admin/PorteriaComunicacion'

// REPORTES 
import Reportes from './admin/Reportes';

//ERROR 
import Error from './principal/Error'
import PagePlacasV from './admin/porteria/PagePlacasV';


//NUEVAS
import Agenda from './admin/Agenda'
import Ronda from './admin/Ronda'


class Router extends Component {

    render() {

        return (
            <React.Fragment>
                <BrowserRouter>

                    {/* CONFIGURAR RUTAS Y PAGINAS */}
                    <Switch>
                        {/*LOGIN*/}
                        <Route exact path={Global.Ruta.Raiz + "/"} component={Login} />
                        {/*HOME ADM*/}
                        <Route exact path={Global.Ruta.Raiz + "/home-admin"} component={homeAdm} />
                        {/*LISTADO USUARIO ADMIN*/}
                        <Route exact path={Global.Ruta.Raiz + "/usuarios"} component={UsersAdm} />
                        <Route exact path={Global.Ruta.Raiz + "/formusuario"} component={FormUser} />
                        {/*LISTADO USUARIOI SUSCRIPTOR*/}
                        <Route exact path={Global.Ruta.Raiz + "/suscriptores"} component={SuscAdm} />

                        <Route exact path={Global.Ruta.Raiz + "/formsuscriptor"} component={FormSusc} />
                        <Route exact path={Global.Ruta.Raiz + "/notas"} component={Agenda} />
                        <Route exact path={Global.Ruta.Raiz + "/ronda-porteria"} component={Ronda} />

                        {/* LIBRO PROPIETARIOS Y VEHICULOS PORTERIA */}
                        <Route exact path={Global.Ruta.Raiz + "/libro-propietarios"} component={LibroPropietarios} />
                        <Route exact path={Global.Ruta.Raiz + "/vehiculos"} component={PageVehiculo} />
                        {/* CHECKIN Y CHECKOUT DE PLACAS DE VEHÍCULO */}
                        <Route exact path={Global.Ruta.Raiz + '/placas'} component={PagePlacasV} />
                        {/*ZONA SOCIALES Y RESERVAS*/}
                        <Route exact path={Global.Ruta.Raiz + "/zonasocial"} component={ZonaSocial} />
                        <Route exact path={Global.Ruta.Raiz + "/ReservaZonas"} component={Reserva} />
                        {/*ASAMBLEA*/}
                        <Route exact path={Global.Ruta.Raiz + "/home-asamblea"} component={Asamblea} />
                        {/*INFORMES*/}
                        <Route exact path={Global.Ruta.Raiz + "/info-asmablea-asistentes"} component={InformesAsistencia} />
                        <Route exact path={Global.Ruta.Raiz + "/info-asmablea-poderes"} component={InformesPoderes} />
                        <Route exact path={Global.Ruta.Raiz + "/info-asmablea-preguntas"} component={InformesPreguntas} />
                        <Route exact path={Global.Ruta.Raiz + "/info-asmablea-ausentes"} component={InformesAusentes} />
                        {/*SUBIR ARCHIVOS*/}
                        <Route exact path={Global.Ruta.Raiz + "/subir-archivo"} component={SubirArchivo} />
                        <Route exact path={Global.Ruta.Raiz + "/revisar-archivo"} component={RevisarArchivo} />
                        {/*MENSAJERIA*/}
                        <Route exact path={Global.Ruta.Raiz + "/sistema-msn"} component={SistemaMsn} />
                        <Route exact path={Global.Ruta.Raiz + "/porteria-msn"} component={PorteriaComunicacion} />
                        {/*PQRS*/}
                        {/*<Route exact path={Global.Ruta.Raiz + "/pqrs"} component={Pqrs}/>*/}

                        {/* COMUNICACION */}
                        {/* peticiones y autorizaciones */}
                        <Route exact path={Global.Ruta.Raiz + "/autorizaciones"} component={Autorizaciones} />

                        {/* SOLICITUDES DE LA COMUNIDAD A ADMIN*/}
                        <Route exact path={Global.Ruta.Raiz + "/peticiones"} component={SolicitudesComu} />
                        <Route exact path={Global.Ruta.Raiz + "/histori-solicitud"} component={HistorialPeticiones} />

                        {/* DESPACHO PORTERIA */}
                        <Route exact path={Global.Ruta.Raiz + "/despacho-porteria/:tipo?"} component={Porteria} />

                        {/* REPORTES */}
                        <Route exact path={Global.Ruta.Raiz + "/reportes"} component={Reportes} />


                        {/* ERROR */}
                        <Route component={Error} />
                    </Switch>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default Router;