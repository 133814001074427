import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Global from "../../../../../Global";
import axios from "axios";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";
import { Fab, Tooltip } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import ResultPrg from "../../PopUps/ResultPrg";
import jsPDF from 'jspdf'
import "jspdf-autotable";
import logo from "../../../../../assets/images/logo.png"

const columns = [
    {
        field: 'Item',
        title: 'Item',
        width: '10px',
        cellStyle: { paddingX: '5px'}
    },
    {
        field: 'preguntaid',
        title: 'PreguntaId',
        hidden: true,
    },
    {
        field: 'contenido',
        title: 'Contenido Pregunta',
    },
    {
        field: 'tipo',
        title: 'Tipo',
    },
    {
        field: 'motivocierre',
        title: 'Motivo Cierre Id',
        hidden: true,
    },
    {
        field: 'votoconsejo',
        title: 'Voto Consejo',
        hidden: true,
    },
    {
        field: 'detallecierre',
        title: 'Motivo Cierre',
    },
]

export default class TablaInfoPreguntas extends Component {

    state = {
        rows: [],
        data: false,
        redirect_home: false,

        PopUpResultados: false,
        PreguntaIdResultado: 0,
        PreguntaText: "",
        VotoConsejoR: 0
    }

    componentWillMount() {

        let body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            asambleaid: Buffer.from(Global.InfoAsamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/infopreguntas/`, body).then((res) => {
            if (res.data.message === "") {

                this.setState({
                    rows: res.data.Preguntas,
                    data: true
                })
            } else {
                swal({
                    title: "ALERTA!",
                    text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ redirect_home: true });
                });
            }
        }).catch((err) => {
            swal({
                title: "ALERTA!",
                text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar"
            }).then(e => {
                this.setState({ redirect_home: true })
            });
        })

    }

    render() {

        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-asamblea"} />;
        }

        if (this.state.data === true) {
            return (
                <div className="container-fluid h-100 bg-light pt-2 pb-2">
                    <Modal show={this.state.PopUpResultados} backdrop="static" size="xl" centered>
                        <ResultPrg onClose={() => { this.setState({ PopUpResultados: false }) }} pregId={this.state.PreguntaIdResultado} pregText={this.state.PreguntaText}
                            votoConsejo={this.state.VotoConsejoR} />
                    </Modal>

                    <MaterialTable
                        columns={columns}
                        data={this.state.rows}
                        title={`Preguntas de la asamblea del ${Global.InfoAsamblea.Fecha}`}

                        options={{
                            pageSize: 12,
                            pageSizeOptions: [12, 15, 20, 30],
                            actionsColumnIndex: -1,
                            rowStyle:{fontSize: "0.9rem"},
                            exportButton: true,
                            exportAllData: true,
                            exportDelimiter: ";",
                            exportFileName: `Preguntas de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`,
                            exportPdf: (columns, data) => {

                                const doc = new jsPDF("portrait");
                                doc.addImage(logo, "JPEG", 95, 15, 25, 25);
                                doc.setFontSize(15);
                                doc.text(Global.Ph.Nombre, 105, 48, null, null, "center")
                                doc.setTextColor("DodgerBlue")
                                doc.text(`del ${Global.InfoAsamblea.Fecha}.`, 105, 60, null, null, "center");
                                doc.text(`Preguntas de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}`, 105, 54, null, null, "center");

                                const columnTitles = []
                                for (var i = 0; i < columns.length; i++) {
                                    if (i !== 1 && i !== 4 && i !== 5) {
                                        columnTitles.push(columns[i].title)
                                    }
                                }

                                const pdfData = []
                                data.forEach(IndividualSusc => {
                                    var Data = []
                                    Data[0] = IndividualSusc.Item
                                    Data[1] = IndividualSusc.contenido
                                    Data[2] = IndividualSusc.tipo
                                    Data[3] = IndividualSusc.detallecierre

                                    pdfData.push(Data)
                                });

                                doc.autoTable({
                                    startY: 68,
                                    theme: 'grid',
                                    rowPageBreak: 'avoid',
                                    styles: { cellWidth: 'auto', halign: 'center', valign: 'middle', },
                                    columnStyles: { 2: { cellWidth: 50 } },
                                    bodyStyles: { halign: 'left', cellPadding: 3 },
                                    head: [columnTitles],
                                    body: pdfData,
                                });

                                const cuentaPaginas = doc.internal.getNumberOfPages();
                                for (let i = 1; i <= cuentaPaginas; i++) {
                                    doc.setPage(i);
                                    doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 26, 'S');
                                    doc.setFontSize(12)
                                    doc.setTextColor("DarkGray")
                                    doc.text(`Pagina ${String(i)} de ${String(cuentaPaginas)}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, "right")
                                    doc.setFontSize(10)
                                    doc.text(`PDF hecho desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 10, null, null, "left")
                                }

                                doc.save(`Preguntas de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}.pdf`);
                            }
                        }}
                        actions={[
                            {
                                onClickVerResultados: (rowData) => {
                                    this.setState({
                                        PopUpResultados: true,
                                        PreguntaIdResultado: parseInt(rowData.preguntaid),
                                        PreguntaText: rowData.contenido,
                                        VotoConsejoR: parseInt(rowData.votoconsejo)
                                    });
                                },
                            }
                        ]}

                        components={{
                            Action: props => (
                                <Tooltip title="Ver Resultado" placement="left-start">
                                    <Fab
                                        onClick={() => {
                                            props.action.onClickVerResultados(props.data)
                                        }}
                                        aria-label="edit"
                                        size="small"
                                        className={`mx-1 text-white ${parseInt(props.data.motivocierre) === 3
                                            ? "bg-primary"
                                            : "bg-dark"
                                            }`}
                                        disabled={parseInt(props.data.motivocierre) !== 3 ? true : false}
                                    >
                                        <i className="fas fa-search" />
                                    </Fab>

                                </Tooltip>
                            )
                        }}

                        localization={{
                            header: {
                                actions: "Acción",
                            },
                            body: {
                                emptyDataSourceMessage: "No hay datos por mostrar.",
                            },
                            pagination: {
                                labelRowsSelect: "registros",
                                labelDisplayedRows: "{from}-{to} de {count}",
                                firstTooltip: "Primera página",
                                previousTooltip: "Página anterior",
                                nextTooltip: "Próxima página",
                                lastTooltip: "Última página",
                            },
                            toolbar: {
                                searchTooltip: "Buscar",
                                searchPlaceholder: "Buscar",
                                exportCSVName: "CSV",
                                exportPDFName: "PDF"
                            },
                        }}
                    />
                </div>
            )
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}
