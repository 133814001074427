import React, {Component} from "react";

export default class HeadTitle extends Component {
  render() {
    let IndcArr = [...this.props.indicadores || ' '];

    const Button = () => {
      if (this.props.actionButton !== undefined) {
        return <div className="col align-items-rigth mx-0">{this.props.actionButton}</div>;
      } else {
        return null
      }
    };

    const ContainerHeader = () => {
      return (
        <div className="col-auto">
          <div className="row g-0 align-items-center">
            <div className="col-12">
              <h6 className="m-0 titulo1">
                {this.props.titulo !== undefined
                  ? this.props.titulo
                  : ""}
              </h6>
            </div>
            <div className="col-12">
              <div className="row ps-1">
                {(IndcArr.length > 0 && IndcArr !== null && IndcArr !== undefined) ? (
                  IndcArr.map((ind, index) => (
                    <div key={index} className="col-auto">
                      <div className="row align-items-center">
                        <div className="col-auto pe-0">
                          <p
                            className={`m-0 text-capitalize ${
                              ind.important !== undefined
                                ? ind.important
                                  ? "text-primary"
                                  : ""
                                : ""
                            }`}
                            style={{fontSize: 15}}
                          >
                            {ind.titulo}
                          </p>
                        </div>
                        <div className="col ps-1">
                          <p className="m-0 fw-light" style={{fontSize: 15}}>
                            {ind.valor}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="m-0 fw-light text-muted">
                    {this.props.subTitulo !== undefined
                      ? this.props.subTitulo
                      : " "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        {this.props.card ? (
          <div className="card p-3 shadow">
            <div className='row align-items-center'>
              <ContainerHeader />
              <Button />
            </div>
          </div>
        ) : (
          <div className={this.props.classNameOwn}>
            <ContainerHeader />
            <Button />
          </div>
        )}
      </React.Fragment>
    );
  }
}
