import React, { Component } from "react";

//import de componentes
import HeaderA2 from "./Home/HeaderA2";
import TablaArchivo from "./subirArchivos/TablaArchivos"
import "../../assets/css/uploader.css"
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";

class RevisarArchivo extends Component {
    state = {
        redirect_home: false
    }
    componentWillMount() {
        this.setState({ redirect_home: ValidarRolPagina('RevisarArchivo') })
    }
    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
        }
        return (
            <div className="g-r-prf3 bg-light vh-100 d-flex flex-column">
                <HeaderA2 />
                <TablaArchivo />
                 
            </div>
        );
    }
}

export default RevisarArchivo;
