import React, { Component } from "react";
import MaterialTable from "material-table";
import Global from "../../../../Global";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import moment from "moment";
import EstiloTabla from "../../../../assets/css/tabla.module.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../assets/images/logo.png";
import { Accordion } from "react-bootstrap";

//COMPONENTES

export default class TResultPrg extends Component {

  state = {
    rows: [],
    rowsN: [],
    data: false,
    DataImg: false,
  };

  componentWillMount() {
    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      preguntaid: Buffer.from("" + this.props.preguntaId).toString("base64"),
      votoconsejo: Buffer.from("" + this.props.votoConsejo).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}adminasamblea/listavotantes`, body).then((res) => {
      if (res.data.message === "") {
        var Data = [];

        res.data.ListaVotantes.forEach((Votantes) => {
          var Fecha = moment(Votantes.Fecha).format("LLLL");
          var Votante = {
            nombre: Votantes.Nombres.toUpperCase(),
            coe: Votantes.Coeficiente + "%",
            direccion: Votantes.Direccion,
            fecha: Fecha,
            opcion: Votantes.Opcion,
            origen: Votantes.Medio,
          };
          Data.push(Votante);
        });

        this.setState({
          rows: Data,
          rowsN: res.data.ListaNoVotantes,
          data: true,
        });
      } else {
        swal({
          title: "ERROR!",
          text: res.data.message,
          icon: "warning",
          button: "Aceptar",
        }).then(() => {
          this.props.onClose();
        });
      }
    }).catch((err) => {
        console.log(`Error en componentWillMount: ${err.message}`)
        this.props.onClose();
      });
  }

  render() {

    let hdd = this.props.hidden


    const columns = [
      {
        field: "nombre",
        title: "Nombres",
        minWidth: "15%",
        maxWidth: "20%",
        width: "15%",
      },
      {
        field: "coe",
        title: "Coeficiente",
        minWidth: "10%",
        maxWidth: "15%",
        width: "10%",
        hidden: hdd
      },
      {
        field: "direccion",
        title: (Global.Asamblea.NameInm),
      },
      {
        field: "fecha",
        title: "Fecha/Hora",
      },
      {
        field: "opcion",
        title: "Opción",
        export: true,
      },
      {
        field: "origen",
        title: "Origen",
        minWidth: "10%",
        maxWidth: "15%",
        width: "10%",
      },
    ];

    const columns1 = [
      {
        field: "nombres",
        title: "Nombres",
        minWidth: "15%",
        maxWidth: "20%",
        width: "15%",
      },
      {
        field: "direccion",
        title: (Global.Asamblea.NameInm),
      },
      {
        field: "coeficiente",
        title: "Coeficiente",
        minWidth: "10%",
        maxWidth: "15%",
        width: "10%",
        hidden: hdd
      },
    ];

    if (this.state.data === true) {
      return (
        <Modal.Body className="container-fluid p-0">
          <div className="card pb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
              <p className="m-0 p-0 font-weight-normal">Resultado pregunta</p>
              <button className="btn" onClick={this.props.onClose}>
                <i className="fa fa-times" />
              </button>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Votantes</Accordion.Header>
                <Accordion.Body>
                  <MaterialTable
                    style={{ marginBottom: 0 }}
                    title={
                      <h6 className={`${EstiloTabla.tituloSize1} m-0 py-2`}>
                        {this.props.textPregunta}
                      </h6>
                    }
                    columns={columns}
                    data={this.state.rows}
                    options={{
                      rowStyle:{fontSize: "0.9rem"},
                      selected: false,
                      sorting: true,
                      draggable: false,
                      exportButton: true,
                      exportAllData: true,
                      exportDelimiter: ";",
                      exportFileName:
                        "Resultados pregunta " + Global.Asamblea.FechaApertura,
                      exportPdf: (columns, data) => {
                        const doc = new jsPDF("portrait");
                        doc.addImage(logo, "JPEG", 70, 15, 25, 25);
                        if (Global.Ph.Logo !== undefined) {
                          doc.addImage(Global.Ph.Logo, "JPEG", 110, 15, 25, 25);
                        }
                        doc.setFontSize(15);
                        doc.text(
                          Global.Ph.Nombre,
                          105,
                          48,
                          null,
                          null,
                          "center"
                        );
                        doc.text(`Asamblea del ${Global.InfoAsamblea.Fecha}.`, 105, 54, null, null, "center");
                        doc.setFontSize(13);
                        doc.text(
                          `Estado de la pregunta: Válida y Votada`,
                          105,
                          64,
                          null,
                          null,
                          "center"
                        );
                        doc.text(
                          `Coef. o Indiv. Habilitado: ${this.props.Qactual}%`,
                          105,
                          70,
                          null,
                          null,
                          "center"
                        );
                        doc.text(
                          `Contenido de la Pregunta: `,
                          105,
                          75,
                          null,
                          null,
                          "center"
                        );
                        doc.setDrawColor(0);
                        doc.setFillColor(220, 220, 220);
                        doc.roundedRect(14, 80, 181, 35, 2, 2, "F");
                        let texto = `¿${this.props.contPrgnt}?`;
                        let lines = doc.splitTextToSize(texto, 210 - 16 - 16);
                        doc.text(15, 86, lines,);

                        const columnTitles = [];
                        for (var i = 0; i < columns.length; i++) {

                          columnTitles.push(columns[i].title);

                        }

                        let columns2 = this.props.columnsGlobal;

                        const columnTitles2 = [];
                        for (let i = 0; i < columns2.length; i++) {
                          if (i !== 3) {
                            columnTitles2.push(columns2[i].title);
                          }
                        }

                        const pdfData = [];
                        data.forEach((IndividualSusc) => {
                          var Data = [];
                          Data[0] = IndividualSusc.nombre;
                          Data[1] = IndividualSusc.coe;
                          Data[2] = IndividualSusc.direccion;
                          Data[3] = IndividualSusc.fecha;
                          Data[4] = IndividualSusc.opcion;
                          Data[5] = IndividualSusc.origen;


                          pdfData.push(Data);
                        });

                        let data2 = this.props.dataGlobal;

                        const pdfDAta2 = [];
                        if (parseInt(this.props.votoConsejo) === 1) {
                          data2.forEach((DatosIndv, i) => {
                            let Data = [];
                            Data[0] = i + 1;
                            Data[1] = DatosIndv.opcion;
                            Data[2] = DatosIndv.votos;

                            pdfDAta2.push(Data);
                          });
                        } else {
                          data2.forEach((DatosIndv) => {
                            let Data = [];
                            Data[0] = DatosIndv.opcion;
                            Data[1] = parseFloat(DatosIndv.cOi).toFixed(2);
                            Data[2] = DatosIndv.votos;
                            Data[3] = DatosIndv.total;

                            pdfDAta2.push(Data);
                          });
                        }

                        doc.autoTable({
                          startY: 120,
                          theme: "grid",
                          rowPageBreak: "avoid",
                          styles: {
                            cellWidth: "auto",
                            halign: "center",
                            valign: "middle",
                          },
                          columnStyles: { 2: { cellWidth: 50 } },
                          bodyStyles: { halign: "left", cellPadding: 3 },
                          head: [columnTitles2],
                          body: pdfDAta2,
                        });

                        doc.addPage();
                        doc.setFontSize(17);
                        doc.text(
                          "Votaciones Individuales",
                          105,
                          25,
                          null,
                          null,
                          "center"
                        );

                        doc.autoTable({
                          startY: 37,
                          theme: "grid",
                          rowPageBreak: "avoid",
                          styles: {
                            cellWidth: "auto",
                            halign: "center",
                            valign: "middle",
                          },
                          columnStyles: { 2: { cellWidth: 50 } },
                          bodyStyles: { halign: "left", cellPadding: 3 },
                          head: [columnTitles],
                          body: pdfData,
                        });

                        const cuentaPaginas = doc.internal.getNumberOfPages();
                        for (let i = 1; i <= cuentaPaginas; i++) {
                          doc.setPage(i);
                          doc.rect(
                            10,
                            10,
                            doc.internal.pageSize.width - 20,
                            doc.internal.pageSize.height - 26,
                            "S"
                          );
                          doc.setFontSize(12);
                          doc.setTextColor("DarkGray");
                          doc.text(
                            `Pagina ${String(i)} de ${String(cuentaPaginas)}`,
                            doc.internal.pageSize.width - 20,
                            doc.internal.pageSize.height - 10,
                            null,
                            null,
                            "right"
                          );
                          doc.setFontSize(10);
                          doc.text(
                            `Informe realizado desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`,
                            doc.internal.pageSize.width - 200,
                            doc.internal.pageSize.height - 10,
                            null,
                            null,
                            "left"
                          );
                        }

                        doc.save(
                          `Votantes de la Asamblea del ${Global.InfoAsamblea.Fecha}.pdf`
                        );
                      },
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 50, 100],
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      body: {
                        emptyDataSourceMessage: "No hay datos por mostrar.",
                      },
                      pagination: {
                        labelRowsSelect: "registros",
                        labelDisplayedRows: "{from}-{to} de {count}",
                        firstTooltip: "Primera página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página",
                      },
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                        nRowsSelected:
                          "{0} " + this.props.tituloT + "(s) Seleccionado(s)",
                        exportCSVName: "CSV",
                        exportPDFName: "PDF"
                      },
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>No Votantes</Accordion.Header>
                <Accordion.Body>
                  <MaterialTable
                    style={{ marginBottom: 0 }}
                    title={
                      <h6 className={`${EstiloTabla.tituloSize1} m-0 py-2`}>
                        {this.props.textPregunta}
                      </h6>
                    }
                    columns={columns1}
                    data={this.state.rowsN}
                    options={{
                      rowStyle:{fontSize: "0.9rem"},
                      selected: false,
                      sorting: true,
                      draggable: false,
                      exportButton: true,
                      exportAllData: true,
                      exportDelimiter: ";",
                      exportFileName:
                        "No votantes de la pregunta " + Global.Asamblea.FechaApertura,
                      exportPdf: (columns, data) => {
                        const doc = new jsPDF("portrait");
                        doc.addImage(logo, "JPEG", 70, 15, 25, 25);
                        if (Global.Ph.Logo !== undefined) {
                          doc.addImage(Global.Ph.Logo, "JPEG", 110, 15, 25, 25);
                        }
                        doc.setFontSize(15);
                        doc.text(Global.Ph.Nombre, 105, 48, null, null, "center");
                        doc.text(`Asamblea del ${Global.InfoAsamblea.Fecha}.`, 105, 54, null, null, "center");
                        doc.setFontSize(13);
                        doc.text(
                          `Estado de la pregunta: Válida y Votada`,
                          105,
                          64,
                          null,
                          null,
                          "center"
                        );
                        doc.text(
                          `Coef. o Indiv. Habilitado: ${this.props.Qactual}%`,
                          105,
                          70,
                          null,
                          null,
                          "center"
                        );
                        doc.text(
                          `Contenido de la Pregunta: `,
                          105,
                          75,
                          null,
                          null,
                          "center"
                        );
                        doc.setDrawColor(0);
                        doc.setFillColor(220, 220, 220);
                        doc.roundedRect(14, 80, 181, 35, 2, 2, "F");
                        let texto = `¿${this.props.contPrgnt}?`;
                        let lines = doc.splitTextToSize(texto, 210 - 16 - 16);
                        doc.text(15, 86, lines,);

                        const columnTitles = [];
                        for (var i = 0; i < columns.length; i++) {
                          columnTitles.push(columns[i].title);
                        }


                        const pdfData = [];
                        data.forEach((IndividualSusc) => {
                          var Data = [];
                          Data[0] = IndividualSusc.nombres;
                          Data[1] = IndividualSusc.direccion;
                          Data[2] = IndividualSusc.coeficiente;

                          pdfData.push(Data);
                        });

                        doc.setFontSize(17);
                        doc.text(
                          "No Votantes",
                          105,
                          125,
                          null,
                          null,
                          "center"
                        );

                        doc.autoTable({
                          startY: 130,
                          theme: "grid",
                          rowPageBreak: "avoid",
                          styles: {
                            cellWidth: "auto",
                            halign: "center",
                            valign: "middle",
                          },
                          columnStyles: { 2: { cellWidth: 50 } },
                          bodyStyles: { halign: "left", cellPadding: 3 },
                          head: [columnTitles],
                          body: pdfData,
                        });

                        const cuentaPaginas = doc.internal.getNumberOfPages();
                        for (let i = 1; i <= cuentaPaginas; i++) {
                          doc.setPage(i);
                          doc.rect(
                            10,
                            10,
                            doc.internal.pageSize.width - 20,
                            doc.internal.pageSize.height - 26,
                            "S"
                          );
                          doc.setFontSize(12);
                          doc.setTextColor("DarkGray");
                          doc.text(
                            `Pagina ${String(i)} de ${String(cuentaPaginas)}`,
                            doc.internal.pageSize.width - 20,
                            doc.internal.pageSize.height - 10,
                            null,
                            null,
                            "right"
                          );
                          doc.setFontSize(10);
                          doc.text(
                            `Informe realizado desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`,
                            doc.internal.pageSize.width - 200,
                            doc.internal.pageSize.height - 10,
                            null,
                            null,
                            "left"
                          );
                        }

                        doc.save(
                          `No votantes de la Asamblea del ${Global.InfoAsamblea.Fecha}.pdf`
                        );
                      },
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 50, 100],
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      body: {
                        emptyDataSourceMessage: "No hay datos por mostrar.",
                      },
                      pagination: {
                        labelRowsSelect: "registros",
                        labelDisplayedRows: "{from}-{to} de {count}",
                        firstTooltip: "Primera página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página",
                      },
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                        nRowsSelected:
                          "{0} " + this.props.tituloT + "(s) Seleccionado(s)",
                        exportCSVName: "CSV",
                        exportPDFName: "PDF"
                      },
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Modal.Body>
      );
    }
    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
