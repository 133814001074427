import React, { Component } from "react";
import "../../assets/css/admin.css";

//import de componentes
import HeaderA2 from "./Home/HeaderA2";
import { ThemeProvider, createTheme } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";
import MenuTabsSusb from "./Suscriptor/MenuTabsSusb";

const theme = createTheme({
  palette: {
    secondary: {
      main: '#4c944a'
    }
  },
});

class SuscAdm extends Component {
  state = {
    redirect_home: false
  }
  componentWillMount() {
    this.setState({ redirect_home: ValidarRolPagina('SuscPage') })
  }
  render() {
    if (this.state.redirect_home === true) {
      return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
    }
    return (
      <ThemeProvider theme={theme}>
        <div className="bg-light g-r-prf3 vh-100">
          <HeaderA2 />
          <MenuTabsSusb />
        </div>
      </ThemeProvider>
    );
  }
}

export default SuscAdm;
