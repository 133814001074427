import React, { Component } from "react";
import Global from "../../../Global";
import { Redirect } from "react-router-dom";

import axios from "axios";
import swal from "sweetalert";

import "../../../assets/css/admin.css";
import ruleta from "../../../assets/images/cd1.gif";
import txt from "../../../assets/css/textFloow.module.css";
import logo from "../../../assets/images/logo.png";
import { Modal } from "@material-ui/core";
import { Modal as Mod } from "react-bootstrap";
import PpSorteo from "./PopUps/Sorteo";
import PpQactual from "./PopUps/QuorumActP";
import PpQmora from "./PopUps/QuorumMora";
import Confetti from "react-confetti";

// LLAMAR COMPONENTES
import TablaAsambleaCrear from "./TablaAsambleaCrear";
import PpLink from "./PopUps/LinkP";
import PpQr from "./PopUps/CodigoQrP";
import PpBloqueoAsis from "./PopUps/BlockAsisP";
import PpClsAsam from "./PopUps/CerrarAsamblP";
import { CookieSesion, ValidarRolPagina } from "../../Helpers";
import { Badge, Tooltip } from "@mui/material";
import PupTimer from "./PopUps/PupTimer";

class HomeAsamblea extends Component {
  state = {
    // PARA REDIRECCIONES
    redirect_login: 0,
    redirect_home: false,
    redirect_asist: false,
    redirect_poder: false,
    redirect_pregnt: false,
    redirect_votacion: false,
    redirect_moroso: false,

    /* ROLES*/
    Rol: -1,

    /*SWITCHEAR ENTRE ASAMBLEA ABIERTA Y ASAMBLEA CERRADA*/
    Asamblea: -1,
    data: false,
    HAsamblea: false,

    //INFO DE LA ASAMBLEA ABIERTA
    AsambleaId: 0,
    TipoAsamblea: "",
    TipoIdAsamblea: "",
    Convocatoria: "",
    FechaApertura: "",
    FechaCierre: "",
    LimitePoder: 0,
    CantInmuebles: 0,
    CantAsistentes: 0,
    CantPoderes: 0,
    Coeficiente: 0,
    BloqueoAsistencia: false,
    DataImg: false,

    /* PARA EL AUTOREFRESH */
    intervalid: 0,

    /* VARIABLES DEL POPUP LINK */
    Link: "",
    PId: "",
    Pwd: "",
    //SORTEO
    DirGanador: "",
    //QACTUAL
    QActualAsis: "",
    QActualCoef: "",
    //QMORA
    QMora: {
      Presente: "",
      Coeficiente: "",
      PresentesMora: "",
      CoeficienteMora: "",
      PresentesHabilitados: "",
      CoeficienteHabilitado: "",
    },

    //Estado Modal
    PopUpRuler: false,
    PopUpSorteo: false,
    PopUpQActual: false,
    PopUpQMora: false,

    /* POPUPS*/
    PopUpLink: false,
    PopUpCodeQr: false,
    PopUpBloqueoAsis: false,
    PopUpcerrarAsamblea: false,
    PopUp_timer: false,

    // cambio de nombres
    count: 1,
    nm: 3,
    bdgNm: true,
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    this.setState({
      redirect_home: ValidarRolPagina("HomeAsamblea"),
      Rol: Global.GlobalUser.rol,
    });

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        this.CargarDataAsamblea();

        axios.get(`${Global.Ruta.Url}adminasamblea/imgph/${Buffer.from(Global.Ph.Codigo).toString("base64")}`, { responseType: "blob" }).then((res) => {
          if (res.data.size !== 11) {
            const Img = URL.createObjectURL(
              new Blob([res.data], { type: "image/png" })
            );

            this.setState({ LogoPh: Img, DataImg: true });
          } else {
            this.setState({ DataImg: false });
          }

          Global.Ph.Logo = this.state.LogoPh;
        }).catch((err) => { });
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }

  CargarDataAsamblea = () => {
    axios.get(`${Global.Ruta.Url}adminasamblea/cargarasamblea/${Buffer.from(Global.Ph.Codigo).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString("base64")}/
                    ${Buffer.from(Global.GlobalUser.UserLog).toString("base64")}`).then((res) => {
      if (res.data.message === "") {
        if (parseInt(res.data.asambleaid) > 0) {
          /* PARA LA FECHA Y LA HORA DE APERTURA */
          var fecha = new Date(
            res.data.InfoAsamblea.FechaApertura + "T12:00:00"
          );
          let fecha2 = new Date(
            res.data.InfoAsamblea.Fechacierre + "T12:00:00"
          );
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          /* SE IGUALAN A LAS VARIABLES GLOBALES */
          Global.Asamblea.AsambleaId = res.data.asambleaid;
          Global.Asamblea.TipoAsamblea = res.data.InfoAsamblea.Tipo;
          Global.Asamblea.TipoIdAsamblea = res.data.InfoAsamblea.TipoId;
          Global.Asamblea.Convocatoria = res.data.InfoAsamblea.Convocatoria;
          Global.InfoAsamblea.Fecha = fecha.toLocaleDateString(
            undefined,
            options
          );
          Global.Asamblea.FechaApertura = fecha.toLocaleDateString(
            undefined,
            options
          );
          Global.Asamblea.FechaCierre = fecha2.toLocaleDateString(
            undefined,
            options
          );
          Global.Asamblea.TotalCoeficiente = parseFloat(
            res.data.InfoAsamblea.AQuorum
          );
          Global.Asamblea.TotalAsistentes =
            res.data.InfoAsamblea.TotalAsistentes;
          Global.Asamblea.TotalPoderes = res.data.InfoAsamblea.TotalPoderes;
          Global.Asamblea.LimitePoder = res.data.InfoAsamblea.LimPoderes;
          Global.Asamblea.BloqueoAsistencia =
            res.data.InfoAsamblea.BloqueoAsistencia;
          /* SE ACTUALIZA EL ESTADO */
          this.setState({
            AsambleaId: res.data.asambleaid,
            Asamblea: 1,
            FechaApertura: fecha.toLocaleDateString(undefined, options),
            FechaCierre: fecha2.toLocaleDateString(undefined, options),
            TipoAsamblea: res.data.InfoAsamblea.Tipo,
            TipoIdAsamblea: res.data.InfoAsamblea.TipoId,
            Convocatoria: res.data.InfoAsamblea.Convocatoria,
            LimitePoder: res.data.InfoAsamblea.LimPoderes,
            CantInmuebles: res.data.InfoAsamblea.TotalInmuebles,
            CantAsistentes: res.data.InfoAsamblea.TotalAsistentes,
            CantPoderes: res.data.InfoAsamblea.TotalPoderes,
            Coeficiente: parseFloat(res.data.InfoAsamblea.AQuorum),
            BloqueoAsistencia: res.data.InfoAsamblea.BloqueoAsistencia,
            data: true,
          });
          // LLAMO A MI PROCEDIMIENTO AUTOMATICO CADA MINUTO
          var idinterval = setInterval(this.AutoRefresh, 5000);

          // ACTUALIZO EL IDINTERVAL PARA MATARLO EN componentWillUnmount
          this.setState({
            intervalid: idinterval,
          });
        } else {
          this.setState({
            AsambleaId: 0,
            Asamblea: 0,
            data: true,
            HAsamblea: true,
          });
        }
      } else {
        swal({
          title: "ALERTA!",
          text: res.data.message,
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_home: true });
        });
      }
    }).catch((err) => {
      console.log(`Error en Autorefresh: ${err.message}`);
      this.setState({ redirect_home: true });
    });
  };

  //CERRER LOS MODALS

  PopUpLink = () => {
    this.setState({ PopUpLink: false });
  };

  PopUpBloqueoAsis = () => {
    this.setState({ PopUpBloqueoAsis: false });
  };

  PopUpcerrarAsamblea = () => {
    this.setState({ PopUpcerrarAsamblea: false });
  };

  //------------------------------------------

  AutoRefresh = () => {
    // LLAMADO LA FUNCIÓN DE REFERSH
    axios.get(`${Global.Ruta.Url}adminasamblea/refreshasamblea/${Buffer.from(Global.Ph.Codigo).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString("base64")}`).then((res) => {
      if (res.data.message === "") {
        if (parseInt(res.data.VarAsambleaId) > 0) {
          /* SE IGUALAN A LAS BARIABLES GLOBALES */
          Global.Asamblea.AsambleaId = res.data.VarAsambleaId;
          Global.Asamblea.TotalCoeficiente = parseFloat(res.data.AQuorum);
          Global.Asamblea.TotalAsistentes = res.data.TotalAsistentes;
          Global.Asamblea.TotalPoderes = res.data.TotalPoderes;
          Global.Asamblea.BloqueoAsistencia = res.data.BloqueoAsistencia;
          /* SE ACTUALIZA EL ESTADO */
          this.setState({
            AsambleaId: res.data.VarAsambleaId,
            Asamblea: 1,
            CantInmuebles: res.data.TotalInmuebles,
            CantAsistentes: res.data.TotalAsistentes,
            CantPoderes: res.data.TotalPoderes,
            Coeficiente: parseFloat(res.data.AQuorum),
            BloqueoAsistencia: res.data.BloqueoAsistencia,
            data: true,
          });
        } else {
          this.LimpiarAsamblea();
        }
      } else {
        console.log(`Error en Autorefresh: ${res.data.message}`);
        this.setState({ redirect_home: true });
      }
    }).catch((err) => {
      console.log(`Error en Autorefresh: ${err.message}`);
    });
  };

  LimpiarAsamblea = () => {
    Global.Asamblea.AsambleaId = 0;
    Global.Asamblea.TipoAsamblea = "";
    Global.Asamblea.TipoIdAsamblea = 0;
    Global.Asamblea.Convocatoria = "";
    Global.Asamblea.FechaApertura = "";
    Global.Asamblea.TotalCoeficiente = 0;
    Global.Asamblea.TotalAsistentes = 0;
    Global.Asamblea.TotalPoderes = 0;
    Global.Asamblea.LimitePoder = "";
    Global.Asamblea.BloqueoAsistencia = false;
    this.setState({
      Asamblea: 0,
      AsambleaId: 0,
      TipoAsamblea: "",
      Convocatoria: "",
      FechaApertura: "",
      LimitePoder: 0,
      CantInmuebles: 0,
      CantAsistentes: 0,
      CantPoderes: 0,
      Coeficiente: 0,
      BloqueoAsistencia: false,
      HAsamblea: true,
    });
    clearInterval(this.state.intervalid);
  };

  Regresar = () => {
    this.setState({
      Asamblea: 1,
      HAsamblea: false,
    });
  };
  /* POPUP LINK */
  GetLink = () => {
    axios.get(`${Global.Ruta.Url}adminasamblea/getlink/${Buffer.from(Global.Ph.Codigo).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString("base64")}`).then((res) => {
      if (res.data.message === "") {
        if (res.data.link !== "") {
          this.setState({
            Link: res.data.link,
            PId: res.data.mid,
            Pwd: res.data.mpwd,
          });
        }
        this.setState({ PopUpLink: true });
      } else {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_home: true });
        });
      }
    }).catch((err) => {
      console.log(`Error en GetLink: ${err.message}`);
    });
  };
  GuardarLink = (Link, idzoom, pwdzoom) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      asambleaid: Buffer.from("" + Global.Asamblea.AsambleaId).toString("base64"),
      linkzoom: Buffer.from(Link).toString("base64"),
      idzoom: Buffer.from("" + idzoom).toString("base64"),
      pwdzoom: Buffer.from("" + pwdzoom).toString("base64"),
      user: Buffer.from(Global.GlobalUser.UserLog).toString("base64"),
    };
    axios
      .post(`${Global.Ruta.Url}adminasamblea/updlink/`, body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text: "Se ingreso el link correctamente.",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            this.setState({ PopUpLink: false });
          });
        } else {
          swal({
            title: "ALERTA!",
            text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ PopUpLink: false });
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_home: true });
        });
      });
  };
  /* POPUP DE CIERRE SAMBLEA */
  CerrarAsamblea = (DetalleCierre) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      asambleaid: Buffer.from("" + Global.Asamblea.AsambleaId).toString("base64"),
      detallecierre: Buffer.from(DetalleCierre).toString("base64"),
      user: Buffer.from(Global.GlobalUser.UserLog).toString("base64"),
    };
    axios
      .post(`${Global.Ruta.Url}adminasamblea/cerrarasamblea/`, body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text: "Se ha cerrado la asamblea satisfactoriamente.",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            this.setState({ PopUpcerrarAsamblea: false });
            this.LimpiarAsamblea();
          });
        } else {
          swal({
            title: "ALERTA!",
            text: res.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ PopUpcerrarAsamblea: false });
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ALERTA!",
          text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_home: true });
        });
      });
  };

  /* BLOQUEAR/DESBLOQUEAR ASISTENCIA */
  BloquearDesbloquear = () => {
    var body = {};
    var tem = this.state.BloqueoAsistencia;
    if (this.state.BloqueoAsistencia === true) {
      body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        asambleaid: Buffer.from("" + Global.Asamblea.AsambleaId).toString(
          "base64"
        ),
        calidadasis: Buffer.from("0").toString("base64"),
        bloqueoasis: Buffer.from("" + this.state.BloqueoAsistencia).toString(
          "base64"
        ),
        user: Buffer.from(Global.GlobalUser.UserLog).toString("base64"),
      };
    } else {
      body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        asambleaid: Buffer.from("" + Global.Asamblea.AsambleaId).toString(
          "base64"
        ),
        calidadasis: Buffer.from("5").toString("base64"),
        bloqueoasis: Buffer.from("" + this.state.BloqueoAsistencia).toString(
          "base64"
        ),
        user: Buffer.from(Global.GlobalUser.UserLog).toString("base64"),
      };
    }
    axios
      .post(`${Global.Ruta.Url}adminasamblea/bloqueoasis/`, body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text:
              this.state.BloqueoAsistencia === true
                ? "Se ha desbloqueado la asistencia"
                : "Se ha bloqueado la asistencia",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            this.setState({ PopUpBloqueoAsis: false, BloqueoAsistencia: !tem });
          });
        } else {
          swal({
            title: "ALERTA!",
            text: res.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ PopUpBloqueoAsis: false });
          });
        }
      })
      .catch((err) => {
        console.log(`Error en BloquearDesbloquear: ${err.message}`);
        this.setState({ redirect_home: true });
      });
  };

  // FUNCIÓN VALIDACION PARA ASAMBLEAS VIRTUALES
  ValidarVirtual = () => {
    switch (parseInt(this.state.TipoIdAsamblea)) {
      case 1:
        return false;
      case 2:
        return false;
      case 3:
        return true;
      case 4:
        return true;
      case 5:
        return true;
      case 6:
        return false;
      case 7:
        return true;
      default:
        return false;
    }
  };
  // POPUP DEL MODAL SORTEO
  Sorteo = () => {
    axios
      .get(
        `${Global.Ruta.Url}adminasamblea/sorteo/${Buffer.from(
          Global.Ph.Codigo
        ).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString(
          "base64"
        )}/
        ${Buffer.from("" + Global.Asamblea.AsambleaId).toString(
          "base64"
        )}/${Buffer.from("" + Global.Asamblea.TotalAsistentes).toString(
          "base64"
        )}`
      )
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            NombreGanador: res.data.NombreGanador,
            DirGanador: res.data.DirGanador,
            PopUpRuler: true,
          });
        } else if (res.data.message === "ERROR0") {
          swal({
            title: "ALERTA!",
            text: "Aún no hay asistentes",
            icon: "warning",
            button: "Aceptar",
          });
        } else {
          swal({
            title: "ALERTA!",
            text: res.data.message,
            icon: "warning",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      })
      .catch((err) => {
        console.log(`Error en Sorteo: ${err.message}`);
        this.setState({ redirect_home: true });
      });
  };
  // FUNCIÓN PARA REINICIAR EL QUORUM ACTUAL
  /*ResetQActual = () => {
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            asambleaid: Buffer.from("" + Global.Asamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/resetqactual`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({
                    QActualAsis: 0,
                    QActualCoef: 0,
                    PopUpQActual: true,

                })
            } else {
                console.log(`Error en ResetQActual: ${res.data.message}`)
                this.setState({ redirect_home: true })
            }
        })
            .catch((err) => {
                console.log(`Error en ResetQActual: ${err.message}`)
                this.setState({ redirect_home: true })
            });
    }*/
  // FUNCIÓN PARA EL QUORUM MORA
  QMora = () => {
    axios
      .get(
        `${Global.Ruta.Url}adminasamblea/quorummora/${Buffer.from(
          Global.Ph.Codigo
        ).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString(
          "base64"
        )}/
        ${Buffer.from("" + Global.Asamblea.AsambleaId).toString("base64")}`
      )
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            QMora: {
              Presentes: res.data.Presentes,
              Coeficiente: res.data.Coeficiente,
              PresentesMora: res.data.PresentesMora,
              CoeficienteMora: res.data.CoeficienteMora,
              PresentesHabilitados: res.data.PresentesHabilitados,
              CoeficienteHabilitado: res.data.CoeficienteHabilitado,
            },
            PopUpQMora: true,
          });
        } else {
          swal({
            title: "ALERTA!",
            text: res.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      })
      .catch((err) => {
        console.log(`Error en QMora: ${err.message}`);
        this.setState({ redirect_home: true });
      });
  };
  // FUNCIÓN PARA TRANSICIÓN DEL SORTEO
  // cSpell: disable
  transicion() {
    setTimeout(
      () => this.setState({ PopUpRuler: false, PopUpSorteo: true }),
      3500
    );
  }
  // cSpell: enable
  // FUNCIÓN PARA CAMBIAR ALGUNOS NOMBRES DE BOTONES Y SECCIONES DE PODERES A REPRESENTANTES
  nameChanger = () => {
    let c = this.state.count;
    this.setState({ count: c + 1 });
    if (this.state.count === 6) {
      this.setState({
        count: 2,
        nm: 3,
      });
    } else if (
      this.state.count === 5 &&
      Global.Asamblea.NameInm === "Inmuebles"
    ) {
      Global.Asamblea.NameInm = "Asociados";
      Global.Asamblea.NamePoder = "Representación";
      Global.Asamblea.VarNrepr = "13px";
      Global.Asamblea.Col_coe_prg = true;
      Global.Asamblea.ColCoeRprg = true;
    } else if (
      this.state.count === 5 &&
      Global.Asamblea.NameInm === "Asociados"
    ) {
      Global.Asamblea.NameInm = "Inmuebles";
      Global.Asamblea.NamePoder = "Representaciones";
      Global.Asamblea.VarNrepr = "initial";
      Global.Asamblea.Col_coe_prg = false;
      Global.Asamblea.ColCoeRprg = false;
    }

    if (this.state.count === 2) {
      this.setState({
        bdgNm: false,
      });
    } else if (this.state.count === 3) {
      this.setState({
        nm: this.state.nm - 1,
      });
    } else if (this.state.count === 4) {
      this.setState({
        nm: this.state.nm - 1,
      });
    } else if (this.state.count === 5) {
      this.setState({
        bdgNm: true,
      });
    }
  };

  render() {
    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_asist === true) {
      return <Redirect to={Global.Ruta.Raiz + "/asist-asamblea"} />;
    }

    if (this.state.redirect_poder === true) {
      return <Redirect to={Global.Ruta.Raiz + "/poderes-asamblea"} />;
    }

    //modals

    if (this.props.regreso === true) {
      this.CloseModal();
    }

    if (this.state.PopUpRuler === true) {
      this.transicion();
    }

    const clasesCol = " col-sm-12 col-md-12 col-lg-4 col-xl-3  ";
    /* * (tamañoPantalla.matches === true) ? ("w-25 p-4") : ("w-25")*/

    if (
      this.state.data === true &&
      this.state.Asamblea === 1 &&
      this.state.HAsamblea === false
    ) {
      return (
        <div className="container-fluid bg-light h-100 overflow-auto">
          {/* MODAL LINK */}
          <Modal
            open={this.state.PopUpLink}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className=""
          >
            <div className="container-fluid h-100">
              <PpLink
                onClose={this.PopUpLink}
                Guardar={this.GuardarLink}
                Link={this.state.Link}
                PId={this.state.PId}
                Pwd={this.state.Pwd}
              />
            </div>
          </Modal>
          {/* MODAL QR */}
          <Modal
            open={this.state.PopUpCodeQr}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className=""
          >
            <div className="container-fluid h-100 overflow-auto">
              <PpQr
                cerrar={() => {
                  this.setState({ PopUpCodeQr: false });
                }}
              />
            </div>
          </Modal>
          {/* MODAL BLOC ASISTENCIA */}
          <Modal
            open={this.state.PopUpBloqueoAsis}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className=""
          >
            <div className="container-fluid h-100">
              <PpBloqueoAsis
                BloqueoAsis={this.state.BloqueoAsistencia}
                onClose={this.PopUpBloqueoAsis}
                BloquearDesbloquear={this.BloquearDesbloquear}
              />
            </div>
          </Modal>
          {/* MODAL CERRAR ASAMBLEA */}
          <Modal
            open={this.state.PopUpcerrarAsamblea}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className=""
          >
            <div className="container-fluid h-100">
              <PpClsAsam
                onClose={this.PopUpcerrarAsamblea}
                CerrarAsamblea={this.CerrarAsamblea}
              />
            </div>
          </Modal>
          {/* MODAL RULETA SORTEO */}
          <Modal
            open={this.state.PopUpRuler}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <div className="container-fluid min-vh-100 d-flex justify-content-center align-items-center">
              <img alt="ruleta" src={ruleta} />
            </div>
          </Modal>
          {/* MODAL RESULT SORTEO */}
          <Modal
            open={this.state.PopUpSorteo}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className=""
          >
            <div className="container-fluid h-100 overflow-auto">
              <Confetti />
              <PpSorteo
                onClose={() => {
                  this.setState({ PopUpSorteo: false });
                }}
                NombreGanador={this.state.NombreGanador}
                DirGanador={this.state.DirGanador}
              />
            </div>
          </Modal>
          {/* MODAL QUORUM ACTUAL */}
          <Modal
            open={this.state.PopUpQActual}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            size="sm"
            className=""
          >
            <div className="container-fluid h-100">
              <PpQactual
                onClose={() => {
                  this.setState({ PopUpQActual: false });
                }}
              />
            </div>
          </Modal>
          {/* MODAL QUORUM MORA */}
          <Modal
            open={this.state.PopUpQMora}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className=""
          >
            <div className="container-fluid h-100 px-0">
              <PpQmora
                onClose={() => {
                  this.setState({ PopUpQMora: false });
                }}
                QMora={this.state.QMora}
              />
            </div>
          </Modal>
          {/* MODAL TEMPORIZADOR */}
          <Mod
            show={this.state.PopUp_timer}
            centered
            backdrop="static"
            onHide={() => {
              this.setState({ PopUp_timer: false });
            }}
          >
            <PupTimer />
          </Mod>

          <div className="col-12">
            <div className=" row justify-content-center m-0 h-100 pt-3 gy-3">
              {/* TITULO Y LOGOS */}
              <div className="col-12">
                <div className="row justify-content-center align-items-center gx-5 gy-3 txt-asamblea">
                  <div
                    className={'col-12 col-md-auto'}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <img style={{ width: '5rem' }} src={logo} aria-label="Logo GoPH" />
                    </div>
                  </div>
                  <div
                    className={'col-12 col-md-auto'}
                  >
                    <div className="cover-marqss">
                      <h4 className="text-center text-nowrap text-muted marqss mt-0 mt-xl-3">
                        {Global.Ph.Nombre}
                      </h4>
                    </div>
                    <div className="cover-marqss">
                      <p
                        className="lead text-center fw-light marqss interline1 text-capitalize text-nowrap"
                        style={{ fontSize: 15 }}
                      >
                        {"Asamblea " +
                          this.state.TipoAsamblea +
                          ", " +
                          this.state.Convocatoria}
                      </p>
                    </div>
                  </div>
                  <div
                    className={'col-12 col-md-auto'}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      {this.state.DataImg ? (
                        <img
                          style={{ width: '5rem' }}
                          src={this.state.LogoPh}
                          aria-label="Logo PH"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* MENU ASAMBLEA */}
              <div className="col-12">
                <div className="row justify-content-center align-content-center align-content-xl-stretch gx-5 gy-3 pb-5">
                  {/* COL ACCIONES */}
                  <div className={clasesCol}>
                    <div className={"card shadow my-2 h-100"}>
                      <div className="card-body d-flex flex-column align-items-stretch px-4">
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="fas fa-users fs-5 text-primary me-2"></span>
                          <h4 className="card-title pricing-card-title text-dark m-0">
                            Acciones
                          </h4>
                        </div>
                        <div className="mt-3 d-grid gap-2 h-100">
                          <div className="card mb-1 text-wrap overflow-hidden w-100 shadow">
                            <p className={txt.titulo + " m-0 py-2 px-2"}>
                              {this.state.FechaApertura}
                            </p>
                          </div>
                          <button
                            type="button"
                            className="btn btn-lg btn-block btn-primary text-wrap"
                            onClick={() => {
                              this.setState({ PopUpQActual: true });
                            }}
                          >
                            Quorum Actual
                          </button>
                          <button
                            type="button"
                            className="btn btn-lg btn-block btn-primary text-wrap"
                            onClick={this.QMora}
                          >
                            Quorum Mora
                          </button>
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-primary text-wrap"
                          >
                            Bloquear Asistencia
                          </button>
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-primary text-wrap"
                          >
                            {this.ValidarVirtual() ? "Link" : "Codigo QR"}
                          </button>
                          <button
                            type="button"
                            className="btn btn-lg btn-block btn-primary text-wrap"
                            onClick={this.Sorteo}
                          >
                            Sorteo
                          </button>
                          {/*<button disabled={(parseInt(this.state.Rol) === 1) ? (false) : (true)} type="button" className="btn btn-lg btn-block btn-primary text-wrap mt-2" onClick={() => { this.setState({ PopUpcerrarAsamblea: true }) }} >Finalizar</button>*/}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* COL Asistencia */}
                  <div className={clasesCol}>
                    <div className={"card shadow my-2 card shadow my-2 h-100"}>
                      <div className="card-body d-flex flex-column align-items-stretch px-4">
                        <div className="d-flex w-100 justify-content-center align-items-center">
                          <span
                            className={`fas ${Global.Asamblea.BloqueoAsistencia
                              ? "fa-lock text-danger"
                              : "fa-lock-open text-success"
                              } fs-5 me-2`}
                          ></span>
                          <h4 className="card-title pricing-card-title text-dark m-0">
                            Asistencia
                          </h4>
                          {
                            <Tooltip
                              title="Temporizador de Turnos"
                              placement="right"
                              arrow
                            >
                              <button
                                className="btn btn-sm d-flex p-0"
                                onClick={() => {
                                  this.setState({ PopUp_timer: true });
                                }}
                              >
                                <i className="fas fa-stopwatch-20 mx-2 text-primary fs-3" />
                              </button>
                            </Tooltip>
                          }
                        </div>
                        <div className="card p-1 mt-3 mb-4 h-100 shadow ">
                          <div className="d-flex flex-row h-50">
                            <Tooltip
                              title={Global.Asamblea.NameInm}
                              placement="left"
                            >
                              <Badge
                                className="w-50"
                                sx={{ fontSize: 16 }}
                                color="error"
                                badgeContent={this.state.nm}
                                invisible={this.state.bdgNm}
                              >
                                <button
                                  className="card bg-primary bg-opacity-75 w-100 p-2 m-1 d-flex flex-column align-items-center justify-content-center"
                                  value={1}
                                  onClick={this.nameChanger}
                                >
                                  <h3 className="text-white mb-0 d-flex flex-row justify-content-center align-items-center ind-txt">
                                    <span className="fas fa-building pe-2 fs-5"></span>
                                    {this.state.CantInmuebles}
                                  </h3>
                                  <h6 className="text-white mb-0 ind-txt-sm">
                                    {Global.Asamblea.NameInm}
                                  </h6>
                                </button>
                              </Badge>
                            </Tooltip>
                            <Tooltip title="Asistentes" placement="right">
                              <div className="card bg-light w-50 p-2 m-1 d-flex justify-content-center text-center">
                                <h3 className="text-dark mb-0 d-flex flex-row justify-content-center align-items-center ind-txt">
                                  <span className="fas fa-user pe-2 fs-5"></span>
                                  {this.state.CantAsistentes}
                                </h3>
                                <h6 className="text-dark mb-0 ind-txt-sm ">
                                  Asistentes
                                </h6>
                              </div>
                            </Tooltip>
                          </div>
                          <div className="d-flex flex-row h-50">
                            <Tooltip
                              title={Global.Asamblea.NamePoder}
                              placement="left"
                            >
                              <div className="card bg-warning w-50 p-2 m-1 d-flex justify-content-center text-center">
                                <h3 className="text-dark mb-0 d-flex flex-row justify-content-center align-items-center ind-txt">
                                  <span className="fas fa-balance-scale pe-2 fs-4"></span>
                                  {this.state.CantPoderes}
                                </h3>
                                <div className="cover-marqss">
                                  <h6
                                    className="text-dark mb-0 ind-txt-sm marqss"
                                    style={{ fontSize: Global.Asamblea.VarNrepr }}
                                  >
                                    {Global.Asamblea.NamePoder}
                                  </h6>
                                </div>
                              </div>
                            </Tooltip>
                            <Tooltip title="Coeficiente" placement="right">
                              <div
                                className={`card w-50 p-2 m-1 d-flex justify-content-center text-center ${parseFloat(this.state.Coeficiente).toFixed(2) > 50
                                  ? "bg-success"
                                  : "bg-danger"
                                  }`}
                              >
                                <h3 className="text-white mb-0 ind-txt text-nowrap ind-txt">{`${this.state.Coeficiente}%`}</h3>
                                <h6 className="text-white mb-0 d-flex flex-row justify-content-center ind-txt-sm">
                                  <span>Quorum</span>
                                </h6>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <button
                          disabled={true}
                          type="button"
                          className="btn btn-lg btn-block btn-outline-primary"
                        >
                          Ver asistencia
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* COL ENLANCES */}
                  <div className={clasesCol}>
                    <div className={"card shadow my-2 h-100"}>
                      <div className="card-body d-flex flex-column align-items-stretch px-4">
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="fas fa-link fs-5 text-primary me-2"></span>
                          <h4 className="card-title text-dark m-0">Enlaces</h4>
                        </div>
                        <div className="mt-3 d-grid gap-2 h-100">
                          <button
                            disabled={true}
                            type="button"

                            className="btn btn-lg btn-block btn-r-blue text-wrap"
                          >
                            Preguntas
                          </button>

                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-r-blue text-wrap"
                          >
                            {Global.Asamblea.NamePoder}
                          </button>
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-r-blue text-wrap"
                          >
                            Morosos
                          </button>
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-r-blue text-wrap"
                          >
                            Asistente votaciones
                          </button>
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-r-blue text-wrap"
                          >
                            Historial Asambleas
                          </button>
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-lg btn-block btn-r-blue text-wrap"
                          >
                            Finalizar Asamblea
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      (this.state.data === true && this.state.HAsamblea === true) ||
      this.state.Rol === 2
    ) {
      return (
        <div className="container-fluid bg-light">
          <TablaAsambleaCrear
            Asamblea={this.state.AsambleaId}
            Regresar={this.Regresar}
            NuevaAsamblea={() => {
              this.setState({ data: false, HAsamblea: false, Asamblea: 1 });
              this.CargarDataAsamblea();
            }}
          />
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default HomeAsamblea;
