import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class CardMenuHome extends Component {
    render() {
        const data = this.props
        return (

            <NavLink exact to={data.url} className="col-md-3" key={data.key}>
                <div className="card card-custom menu">
                    <div className="card-body texto-resp">
                        <p className={`mb-1 text-${data.color}`}><i className={data.icono}></i></p>
                        <h5 className="card-title mb-1"><strong>{data.titulo}</strong></h5>
                        <p className="card-menu-text">{data.detalle}</p>
                    </div>
                </div>
            </NavLink>
        )
    }
}

CardMenuHome.defaultProps = {
    titulo: "na",
    detalle: "na",
    icono: "",
    url: "",
    color: "",
    key: 0
};