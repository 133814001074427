import React, { Component } from 'react';
import { Modal, } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { styled } from "@material-ui/core";

import txt from '../../../assets/css/textFloow.module.css'
import swal from "sweetalert";

export default class FormContestar extends Component {

    state = {
        Asunto: '',
        contenido: '',
        data: true,
        archivo: false,
        selectedFile: null,
        dataFile: null,

        Asuntos: [],
    }

    componentWillMount() {
        this.setState({
            data: true,
            Asunto: '',
            contenido: '',
        })
    }

    Guardar = () => {
        if (this.state.contenido === '') {
            swal({
                title: "ERROR!",
                text: "Debe ingresar un mensaje.",
                icon: "error",
                button: "Aceptar"
            })
            return
        }
        this.props.Guardar(1, 1, this.props.idmensaje, this.state.contenido, this.state.selectedFile)
    }

    filterFile = (ccFile) => {
        if (ccFile !== undefined) {
            if (ccFile.type === "application/pdf") {
                if (ccFile.size <= 2000000) {
                    return 0;
                } else {
                    return 1;
                }
            } else {
                return 2;
            }
        } else {
            return 3;
        }
    };

    //caja para arrastrar
    onFileDrop = (event) => {
        event.preventDefault();

        var resp = this.filterFile(event.target.files[0]);

        switch (resp) {
            case 0:
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const base64File = e.target.result;
                        this.setState({ selectedFile: base64File, dataFile: file });
                    };

                    reader.readAsDataURL(file);
                }
                break;
            case 1:
                swal({
                    title: "ATENCIÓN !!",
                    text: "El archivo excede el tamaño permitido de 2MB.",
                    icon: "error",
                }).then((res) => {
                    this.setState({ selectedFile: null });
                });
                break;
            case 2:
                swal({
                    title: "ATENCIÓN !!",
                    text: "El archivo no puede ser subido porque no es un .pdf",
                    icon: "error",
                }).then((res) => {
                    this.setState({ selectedFile: null });
                });
                break;
            default:
                swal({
                    title: "ATENCIÓN !!",
                    text: "Error, No ha seleccionado un archivo.",
                    icon: "error",
                }).then((res) => {
                    this.setState({ selectedFile: null });
                });
                break;
        }
    };

    // seleccionar archivo (popup del sistema)
    onFileChange = (event) => {
        var resp = this.filterFile(event.target.files[0]);

        switch (resp) {
            case 0:
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const base64File = e.target.result;
                        this.setState({ selectedFile: base64File, dataFile: file });
                    };

                    reader.readAsDataURL(file);
                }
                break;
            case 1:
                swal({
                    title: "ATENCIÓN !!",
                    text: "El archivo excede el tamaño permitido de 2MB.",
                    icon: "error",
                }).then((res) => {
                    this.setState({ selectedFile: null });
                });
                break;
            case 2:
                swal({
                    title: "ATENCIÓN !!",
                    text: "El archivo no puede ser subido porque no es un .pdf",
                    icon: "error",
                }).then((res) => {
                    this.setState({ selectedFile: null });
                });
                break;
            default:
                swal({
                    title: "ATENCIÓN !!",
                    text: "Error, No ha seleccionado un archivo.",
                    icon: "error",
                }).then((res) => {
                    this.setState({ selectedFile: null });
                });
                break;
        }
    };

    render() {

        const Input = styled("input")({
            display: "none",
        });

        if (this.state.data) {
            return (
                <div>
                    <Modal.Header >
                        <Modal.Title>Responder Solicitud23</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='container'>
                            <div className="row row-cols-2 mx-n1 px-1 justify-content-between">
                                <div className={txt.cover + " col-5 bg-secondary bg-opacity-75 my-1 p-2 rounded overflow-hidden"}>
                                    <h6 className={`${txt.titulo} text-uppercase text-light m-0`}>{this.props.Nombre}</h6>
                                </div>
                                <div className={txt.cover + " col-6 offset-1 bg-secondary bg-opacity-75 my-1 p-2 rounded overflow-hidden"}>
                                    <h6 className={`${txt.titulo} text-light m-0`}>{this.props.Direccion}</h6>
                                </div>
                                <div className="col-12 bg-secondary bg-opacity-75 my-1 p-2 rounded text-justify ">
                                    <h6 className='text-white text-opacity-75 text-wrap m-0'>{this.props.Asunto}</h6>
                                    <h6 className='text-light text-wrap m-0 text-justify'>{this.props.Mensaje}</h6>
                                </div>
                            </div>
                            <hr className='my-2 text-secondary' />
                            <div className='row'>
                                <div className='col-12'>
                                    <TextField className="my-1" rows={4} multiline fullWidth
                                        label='Cuerpo de la respuesta' helperText={`${this.state.contenido.length} de 1000`}
                                        value={this.state.contenido} onChange={(e) => this.setState({ contenido: e.target.value })}
                                        error={this.state.contenido.length === 1000 ? true : false} inputProps={{ maxLength: 1000 }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                {!this.state.archivo ? (
                                    <div className="col-12 d-grid gap-2">
                                        <button className="btn btn-primary btn-block" onClick={() => { this.setState({ archivo: true }) }}> Adjuntar Archivo</button>
                                    </div>
                                ) : (
                                    <div className="col-12 d-grid gap-2">
                                        <div className="card mx-auto text-center position-relative border-a-1 border-secondary">
                                            <div className="card-body">
                                                <div className="row gy-2 justify-content-center">
                                                    {(this.state.dataFile === null) ? (
                                                        <React.Fragment>
                                                            <div className="col-12 d-none d-lg-block">
                                                                <h6 className="text-center m-0">
                                                                    Arrastre y suelte aquí el archivo que desea subir ...
                                                                </h6>
                                                            </div>
                                                            <div className="col-12 text-center">
                                                                <h6 className="text-center m-0"> o </h6>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="col-12 d-none d-lg-block">
                                                            <h6 className="text-center m-0">
                                                                {this.state.dataFile.name}
                                                            </h6>
                                                        </div>
                                                    )
                                                    }
                                                    <div className="col-auto">
                                                        <input
                                                            id="drop-area"
                                                            accept="application/pdf"
                                                            type="file"
                                                            className="top-50 start-50 translate-middle"
                                                            onChange={this.onFileDrop}
                                                        />
                                                        <label htmlFor="contained-button-file">
                                                            <Input
                                                                id="contained-button-file"
                                                                accept="application/pdf"
                                                                type="file"
                                                                onChange={this.onFileChange}
                                                            />
                                                            <button
                                                                className="btn btn-primary text-nowrap"
                                                                variant="contained"
                                                                component="span"
                                                            >
                                                                Seleccionar archivo...
                                                            </button>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                            <div className="row">
                                <div className="col-12 d-grid gap-2">
                                    <button className="btn btn-primary btn-block" onClick={() => this.props.Guardar(2, 1, this.props.idmensaje, "")}> No Aplica</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className='row row-cols-2 justify-content-around w-75'>
                            <button className='btn btn-danger col-5' onClick={this.props.onClose}>
                                Cancelar
                            </button>
                            <button className='btn btn-success col-5' onClick={this.Guardar}>
                                Enviar
                            </button>
                        </div>
                    </Modal.Footer>
                </div>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}
