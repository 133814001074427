import {
  Checkbox,
  FormControl,
  //createTheme,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  //ThemeProvider,
} from "@mui/material";
import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import Global from "../../../../Global";
import axios from "axios";
import { Buffer } from "buffer";
import swal from "sweetalert";
import HourGlass from "./../../../principal/HourGlass";
import { removeDiacritics } from "../../../Helpers"

export default class ModalMoraLib extends Component {
  state = {
    data: false,
    itemList: [],
    OriginitemList: [],
    Busqueda: "",
    HourGlass: false,

    // selects
    select1: 1,
    morososSel: false,
  };

  componentWillMount() {
    this.Inmueble();
  }

  Inmueble = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios.post(Global.Ruta.Url + "admin/selectinmueble/", body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          data: true,
          itemList: res.data.data,
          OriginitemList: res.data.data,
        });
      }
    }).catch((err) => {
      swal({
        title: "ATENCIÓN!",
        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
        icon: "error",
        button: "Aceptar",
      }).then(() => { });
    });
  };

  CheckID = (inmuebleid) => {
    this.setState({ HourGlass: true });

    var tem = this.state.itemList;
    var index = this.BuscarIndexInmueble(inmuebleid, tem);

    tem[index].mora = !tem[index].mora;

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      mora: Buffer.from("" + tem[index].mora).toString("base64"),
      inmuebleid: Buffer.from("" + inmuebleid).toString("base64"),
    };

    axios.post(Global.Ruta.Url + "admin/updatemoraid/", body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          itemList: tem,
          HourGlass: false,
        });
      }
    }).catch((err) => {
      swal({
        title: "ATENCIÓN!",
        text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          HourGlass: false,
        });
      });
    });
  };

  BuscarIndexInmueble = (PresId, temRows) => {
    const findIndexArray = (element) =>
      parseInt(element.inmuebleid) === parseInt(PresId);

    return temRows.findIndex(findIndexArray);
  };

  CheckAll = (value) => {
    var tem = this.state.itemList;
    for (var i = 0; i < tem.length; i++) {
      tem[i].mora = value === true ? true : false;
    }

    var mensaje =
      value === true
        ? "¿Desea Marcar Todos a Morosos?"
        : "¿Desea Desmarcar Todos a Morosos?";
    var mensaje1 =
      value === true ? "Inmuebles Marcados" : "Inmuebles Desmarcados";

    swal({
      title: "Atención",
      text: `${mensaje}`,
      icon: "warning",
      buttons: true,
    }).then((Update) => {
      if (Update) {
        this.setState({ HourGlass: true });
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          mora: Buffer.from("" + value).toString("base64"),
        };
        axios
          .post(Global.Ruta.Url + "admin/updatemorainmuebles/", body)
          .then((res) => {
            if (res.data.message === "") {
              this.setState({
                itemList: tem,
                HourGlass: false,
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ATENCIÓN!",
              text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({
                HourGlass: false,
              });
            });
          });
        swal(`${mensaje1}`, {
          icon: "success",
        });
      }
    });
  };

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value,
    }));

    if (event.target.value === "") {
      var tem = this.state.OriginitemList;
      this.setState({
        itemList: tem,
      });
    } else {
      this.setState({
        itemList: this.filterItems1(
          event.target.value,
          this.state.OriginitemList
        ),
      });
    }
  };

  filterItems1 = (query, temRows) => {
    return temRows.filter(function (data) {
      return removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1;
    });
  };

  Indeterminate = () => {
    return this.state.itemList.filter(function (data) {
      return data.mora === true;
    }).length;
  };

  Filtrar = (e) => {
    if (parseInt(e) === 2) {
      this.setState({
        itemList: this.filterMorosos(this.state.itemList),
      });
    } else {
      this.setState({
        itemList: this.state.OriginitemList,
      });
    }
  }

  filterMorosos = (temRows) => {
    return temRows.filter(function (data) {
      return data.mora.toString().toLowerCase().indexOf("true") > -1;
    });
  };

  render() {
    const children = (item) => {
      return (
        <Form.Check key={item.inmuebleid} type="checkbox" label={`${item.direccion}`} checked={item.mora}
          inmuebleid={`item-${item.inmuebleid}`} onChange={() => this.CheckID(item.inmuebleid)} />
      );
    };

    if (this.state.data) {
      const Seleccion = ({ titulo, opciones, valor, handler }) => {
        let options = [...opciones];
        return (
          <FormControl fullWidth size="small" className="shadow-sm">
            <InputLabel id={`${titulo}-id-label`}>{titulo}</InputLabel>
            <Select labelId={`${titulo}-id-label`} id={`${titulo}-id`} value={valor} onChange={(e) => handler(e.target.value)} label={titulo}>
              {options.map((item) => (
                <MenuItem key={item} value={item.valor}>
                  {item.opcion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      return (
        <div className="w-100">
          <Modal.Header closeButton>Inmuebles en mora</Modal.Header>
          <HourGlass show={this.state.HourGlass}></HourGlass>
          <Modal.Body>
            <div className="row gy-3">
              <div className="col">
                <TextField fullWidth className="shadow-sm" size="small" disabled={(this.state.select1 === 1) ? (false) : (true)}
                  label={<div className="d-flex align-items-center"> <i className="fa-solid fa-search me-2" />{" "} <h6 className="m-0">Buscar</h6>{" "} </div>}
                  value={this.state.Busqueda} onChange={this.handleChange("Busqueda")} />
              </div>
              <div className="col">
                <Seleccion titulo='Filtro' opciones={[{ valor: 1, opcion: "Todos" }, { valor: 2, opcion: "Morosos" },]}
                  valor={this.state.select1} handler={(v) => { this.setState({ select1: v }); this.Filtrar(v) }} />
              </div>
              <hr />
              <div className="col-12">
                <FormControlLabel label="Todos" control={
                  <Checkbox checked={this.Indeterminate() === this.state.itemList.length ? true : false}
                    indeterminate={this.Indeterminate() > 0 && this.Indeterminate() < this.state.itemList.length}
                    onChange={(e) => this.CheckAll(e.target.checked)} />
                } />
                <div className="row ps-4">
                  {this.state.itemList.map((item) => (
                    <div className="col-12">{children(item)}</div>
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center text-info flex-column align-items-center min-vh-100">
        <div className="spinner-border fs-3 m-3" style={{ width: "5rem", height: "5rem" }} role="status" ></div>
        <p className="fs-3">Cargando...</p>
      </div>
    );
  }
}
