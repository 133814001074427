import React, {Component} from "react";
import {Form, Modal} from "react-bootstrap";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";

import logo from "../../../../assets/images/logo.png";
import alarm from "../../../../assets/sounds/alarm.mp3";
import {Fab} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default class PupTimer extends Component {
  state = {
    intervalid: 0,
    Tiempo: `00:00`,
    TiempoInicial: 0,
    barraProgreso: 1,

    tempo: 0,
    realTempo: 0,
    disabled: false,
    alarma: false,

    min: 0,
    max: 60,
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }

  Iniciar = () => {
    if (parseInt(this.state.tempo) > 0) {
      var idinterval = 0;
      if (this.state.restart) {
        this.setState({
          disabled: true,
        });
        idinterval = setInterval(this.Contador, 1000);
        this.setState({
          intervalid: idinterval,
          restart: false,
        });
      } else {
        this.setState({
          disabled: true,
        });
        // LLAMO A MI PROCEDIMIENTO AUTOMATICO CADA MINUTO
        idinterval = setInterval(this.Contador, 1000);
        var tiempo = parseInt(this.state.tempo) * 60;

        this.setState({
          intervalid: idinterval,
          TiempoInicial: tiempo,
          barraProgreso: tiempo,
          alarma: true,
        });
      }
    }
  };

  Contador = () => {
    var restante = this.state.TiempoInicial - 1;

    var Minutos = Math.floor(this.state.TiempoInicial / 60);
    var Segundo = this.state.TiempoInicial % 60;

    var Min = Minutos < 10 ? "0" + Minutos : Minutos;
    var Seg = Segundo < 10 ? "0" + Segundo : Segundo;

    this.setState({
      TiempoInicial: restante,
      Tiempo: `${Min}:${Seg}`,
      disabled: true,
    });

    if (this.state.TiempoInicial === -1) {
      this.setState({
        disabled: false,
        barraProgreso: 0,
      });
      clearInterval(this.state.intervalid);
    }
  };

  Inicio = (e) => {
    var Tmp = 0;
    if (e.target.value === "") {
      Tmp = 0;
    } else {
      Tmp = parseInt(e.target.value);
    }
    var tiempo = Tmp * 60;
    this.setState({
      tempo: Tmp,
      TiempoInicial: tiempo,
      barraProgreso: tiempo,
    });
  };

  Detener = () => {
    this.setState({
      alarma: false,
      tempo: 0,
      Tiempo: `00:00`,
      TiempoInicial: 0,
      barraProgreso: 1,
      disabled: false,
    });
    clearInterval(this.state.intervalid);
  };

  Pausar = () => {
    this.setState({
      disabled: false,
      restart: true,
    });
    clearInterval(this.state.intervalid);
  };

  Reiniciar = () => {
    clearInterval(this.state.intervalid);

    var tiempo = parseInt(this.state.tempo) * 60;

    var Minutos = Math.floor(tiempo / 60);
    var Segundo = tiempo % 60;

    var Min = Minutos < 10 ? "0" + Minutos : Minutos;
    var Seg = Segundo < 10 ? "0" + Segundo : Segundo;

    this.setState({
      disabled: false,
      TiempoInicial: tiempo,
      Tiempo: `${Min}:${Seg}`,
      barraProgreso: tiempo,
    });
  };

  Alarma = () => {
    let sonido = new Audio();
    sonido.src = alarm;
    sonido.play();
    swal({
      className: "fs-3",
      title: "Gracias. Su tiempo de participación ha terminado.",
      icon: "warning",
      button: "Aceptar",
    }).then(() => {
      sonido.pause();
    });
  };

  render() {
    if (this.state.TiempoInicial === 0 && this.state.alarma === true) {
      this.Alarma();
      this.setState({alarma: false});
    }

    const increment = () => {
      let tmp = this.state.tempo;

      let tiempo = (t) => {
        return t * 60
      }

      if(tmp<60){
        tmp= tmp+1
        this.setState({
          tempo: tmp,
          TiempoInicial: tiempo(tmp),
          barraProgreso: tiempo(tmp),
        });
      }

    };

    const decrement = () => {
      let tmp = this.state.tempo;

      let tiempo = (t) => {
        return t * 60
      }

      if(tmp>0){
        tmp= tmp-1
        this.setState({
          tempo: tmp,
          TiempoInicial: tiempo(tmp),
          barraProgreso: tiempo(tmp),
        });
      }
    };

    const theme = createTheme({
      status: {
        danger: '#e53e3e',
      },
      palette: {
        primary: {
          main: '#0d6efd',
          darker: '#053e85',
          contrastText: '#fff',
        },
        error: {
          main: '#dc3545',
          darker: '#053e85',
          contrastText: '#fff',
          light: '#053e85'
        },
        warning: {
          main: '#ffc107',
          darker: '#053e85',
        },
        success: {
          main: '#198754',
          darker: '#053e85',
        },
      },
    });

    return (
      <div>
        <Modal.Header closeButton>
          <img src={logo} className="img-responsive col-1 m-0 me-3" alt="" />
          <p className="m-0 fw-normal">Temporizador</p>
        </Modal.Header>
        <Modal.Body>
          <div className="container-sm">
            <div className="row py-3 align-items-center justify-content-center border border-2 rounded">
              <div className="col-8">
                <CircularProgressbar
                  value={this.state.TiempoInicial}
                  maxValue={this.state.barraProgreso}
                  text={`${this.state.Tiempo}`}
                />
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <div className={`row py-2 gy-4 align-items-center`}>
                <div className="col-12">
                  <div className="row justify-content-center">
                    <div className="col-auto d-flex align-items-center pe-0">
                      <h6 className="m-0">Minutos</h6>
                    </div>
                    <div className="col-auto d-flex align-items-center pe-3">
                      {/* <InputNumberCstm min={0} max={60} valor={(v)=>this.Inicio(v)}/> */}
                      <Form.Control
                        className="input-number-1"
                        type={"number"}
                        placeholder=" "
                        size="lg"
                        value={this.state.tempo}
                        disabled={this.state.disabled}
                        onChange={(e) => {
                          this.Inicio(e);
                        }}
                      />
                    </div>
                    <div className="col-auto pe-0">
                      <Fab
                        className="btn btn-success d-flex align-items-center justify-content-center p-2 rounded-circle"
                        size="small"
                        color="error"
                        onClick={decrement}
                      >
                        <i className="fas fa-minus fs-4" />
                      </Fab>
                    </div>
                    <div className="col-auto ps-1">
                      <Fab
                        className="btn btn-success d-flex align-items-center justify-content-center p-2 rounded-circle"
                        size="small"
                        color="success"
                        onClick={increment}
                      >
                        <i className="fas fa-plus fs-4" />
                      </Fab>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row justify-content-center gx-3">
                    <div className="col-auto">
                      <Fab
                        className="btn btn-success d-flex align-items-center justify-content-center p-2 rounded-circle"
                        size="small"
                        color='success'
                        disabled={this.state.disabled}
                        onClick={this.Iniciar}
                      >
                        <i className="fas fa-play fs-4" />
                      </Fab>
                    </div>
                    <div className="col-auto">
                      <Fab
                        className="btn btn-primary"
                        size="small"
                        color='primary'
                        disabled={!this.state.disabled}
                        onClick={this.Pausar}
                      >
                        <i className="fas fa-pause fs-4" />
                      </Fab>
                    </div>
                    <div className="col-auto">
                      <Fab
                        className="btn btn-secondary d-flex align-items-center justify-content-center p-2  rounded-circle"
                        size="small"
                        color='warning'
                        onClick={this.Reiniciar}
                      >
                        <i className="fas fa-rotate-left fs-4" />
                      </Fab>
                    </div>
                    <div className="col-auto">
                      <Fab
                        className="btn btn-danger d-flex align-items-center justify-content-center p-2  rounded-circle"
                        size="small"
                        color="error"
                        disabled={this.state.TiempoInicial === 0 ? true : false}
                        onClick={this.Detener}
                      >
                        <i className="fas fa-stop fs-4" />
                      </Fab>
                    </div>
                  </div>
                </div>
              </div>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </div>
    );
  }
}
