import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class CardSolicitudes extends Component {
    render() {
        const data = this.props
        return (
            <NavLink exact to={data.url} className="col-lg-3 col-12 d-none d-md-block" key={data.key}>
                <div className="card card-custom sol bg-c-blue">
                    <div className="card-body">
                        <h5>{data.titulo}</h5>
                        <p className="card-title">{data.texto}</p>
                        <h2>{data.valor}</h2>
                    </div>
                </div>
            </NavLink>
        )
    }
}

CardSolicitudes.defaultProps = {
    titulo: "na",
    valor: 0,
    texto: "na",
    key: 0,
    url: '/'
};