import React, { Component } from "react";
import MaterialTable from "material-table";
import { Modal } from "react-bootstrap";
import Global from "../../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import PpHourglass from "../../../Asamblea/PopUps/PpHourglass";
import { TextField } from "@material-ui/core";
import { removeDiacritics } from "../../../../Helpers"

export default class TableVisitAnunci extends Component {
  state = {
    cols: [
      {
        field: 'fecha_crea',
        title: "fecha_crea",
        hidden: true,
      },
      {
        field: 'fecha_llega',
        title: "fecha_llega",
        hidden: true,
      },
      {
        field: 'nombre_susc',
        title: "nombre_susc",
        hidden: true,
      },
      {
        field: 'id_inmueble',
        title: "id_inmueble",
        hidden: true,
      },
      {
        field: 'id_susc',
        title: "id_susc",
        hidden: true,
      },
      {
        field: 'nombre_visitante',
        title: "Nombre",
      },
      {
        field: 'detalle',
        title: "Detalle",
      },
      {
        field: 'direccion',
        title: "Direccion",
      },
      {
        field: 'id_anuncios',
        title: "Acción",
        align: 'center',
        render: rowData => (
          <button className="btn btn-primary" onClick={() => this.GuardarAnunciante(rowData)}>
            Llegó
          </button>
        )
      },
    ],
    data: this.props.data,
    PpHourglass: false,
    visitantes: true,
    caso: this.props.caso,
    VisitantesAll: [],
    ParaBusqueda: [],
    Busqueda: ""
  };

  componentWillMount() {
    if (this.state.caso !== undefined) {
      this.BuscaVisitantesAll()
    }
  }

  componentDidMount() {
    if (this.state.caso !== undefined) {
      this.timerID = setInterval(() =>
        this.BuscaVisitantesAll(),
        60000
      );
    }
  }

  componentWillUnmount() {
    if (this.state.caso !== undefined) {
      clearInterval(this.timerID);
    }
  }

  GuardarAnunciante = (Value) => {
    swal({
      title: "Atención",
      text: `¿Desea autorizar el ingreso al visitante anunciado: ${Value.nombre_visitante.toUpperCase()}?`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((save) => {
      if (save) {
        this.setState({ PpHourglass: true })
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          id_susc: Buffer.from("" + Value.id_susc).toString("base64"),
          id_inmueble: Buffer.from("" + Value.id_inmueble).toString("base64"),
          id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
          id_anuncios: Buffer.from("" + Value.id_anuncios).toString("base64"),
          nombre: Buffer.from("" + Value.nombre_visitante).toString("base64"),
          detalle: Buffer.from("" + Value.detalle).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}porteria/updateanunciantes`, body).then((res) => {
          if (res.data.message === "") {
            this.setState({ PpHourglass: false, visitantes: false })
            swal({
              title: "Atención",
              text: `Visitante ${Value.nombre_visitante} Ingresado`,
              icon: "success",
              button: false,
              timer: 1000,
            }).then(() => {
              this.ActualizarAnunciados(Value.id_anuncios)
            });
          } else {
            this.setState({ visitantes: false, PpHourglass: false });
          }
        }).catch((err) => {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
            timer: 1000,
          }).then(() => {
            this.setState({
              redirect_home: true,
            });
          });
        });

      } else {
        swal({
          title: `Visitante ${Value.nombre_visitante} No Ingresado`,
          icon: "success",
          timer: 1000,
        });
      }
    });

  }

  GuardaFrecuentes = (Value) => {
    swal({
      title: "Atención",
      text: `¿Desea autorizar el ingreso al visitante frecuente: ${Value.nombre_visitante.toUpperCase()}?`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((save) => {
      if (save) {
        this.setState({ PpHourglass: true })
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          id_susc: Buffer.from("" + Value.id_susc).toString("base64"),
          id_inmueble: Buffer.from("" + Value.id_inmueble).toString("base64"),
          id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
          id_visitante: Buffer.from("" + Value.id_frecuentes).toString("base64"),
          nombre: Buffer.from("" + Value.nombre_visitante).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}porteria/updatefrecuentes`, body).then((res) => {
          if (res.data.message === "") {
            this.setState({ PpHourglass: false })
            swal({
              title: "Atención",
              text: `Fue autorizado el ingreso del visitante: ${Value.nombre_visitante.toUpperCase()} `,
              icon: "success",
              button: false,
              timer: 1000
            }).then(() => {
              this.ActualizarFrecuentes(Value.id_frecuentes)
            });
          } else {
            this.setState({ visitantes: false, PpHourglass: false });
          }
        }).catch((err) => {
          swal({
            title: "ERROR!",
            text: "Por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
            timer: 1000,
          }).then(() => {
            this.setState({
              redirect_home: true,
            });
          });
        });

      } else {
        swal({
          title: `Visitante ${Value.nombre_visitante} No Ingresado`,
          icon: "success",
          timer: 1000,
        });
      }
    });
  }

  handler = (data) => {
    if (parseInt(data.tipo) === 0) {
      // anunciado
      this.GuardarAnunciante(data)
    } else {
      //frecuente
      this.GuardaFrecuentes(data)
    }
  };

  ActualizarAnunciados = (id_anuncios) => {
    var tem = undefined
    var index = undefined
    if (this.state.caso === undefined) {
      tem = this.state.data;
      index = this.IndexCambioAnunciados(id_anuncios, tem);

      tem.splice(index, 1);
      this.setState({
        data: tem,
      });

      setTimeout(() => {
        this.setState({
          visitantes: true,
        });
      }, 150);
    } else {
      tem = this.state.ParaBusqueda;
      index = this.IndexCambioAnunciados(id_anuncios, tem);

      tem.splice(index, 1);
      this.setState({
        VisitantesAll: tem,
      });
    }
  };

  IndexCambioAnunciados = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_anuncios) === parseInt(Id) && parseInt(element.tipo) === 0;

    return temRows.findIndex(findIndexArray);
  };

  ActualizarFrecuentes = (id_frecuentes) => {
    var tem = this.state.ParaBusqueda;
    var index = this.IndexCambioAnunciados(id_frecuentes, tem);

    tem.splice(index, 1);
    this.setState({
      VisitantesAll: tem,
    });
  };

  IndexCambioFrecuentes = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_frecuentes) === parseInt(Id) && parseInt(element.tipo) === 1;

    return temRows.findIndex(findIndexArray);
  };

  BuscaVisitantesAll = () => {

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/visitantesall`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          VisitantesAll: res.data.Visitantes,
          ParaBusqueda: res.data.Visitantes,
        });
      } else {
        this.setState({ visitantes: false });
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          redirect_home: true,
        });
      });
    });
  };

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value
    }));
    this.setState({
      VisitantesAll: this.filterItems(event.target.value, this.state.ParaBusqueda)
    })
  }

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return (
        removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.nombre_visitante).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1);
    })
  }

  render() {
    if (this.state.caso !== undefined) {
      return (
        <React.Fragment>
          <div className="mx-2 row" style={{ height: "60px" }}>
            <div className="col">
              <TextField
                autoFocus
                size="small"
                fullWidth
                margin="normal"
                placeholder="Buscar Visitante"
                variant="outlined"
                defaultValue={this.state.Busqueda}
                value={this.state.Busqueda}
                onChange={this.handleChange("Busqueda")}
              />
            </div>
            <div className="col-auto d-flex align-items-center justify-content-center">
              <button className="btn btn-success rounded-circle btn-sm" onClick={(e) => this.props.agregarVisitante(e)}>
                <i className="fa-solid fa-plus" />
              </button>
            </div>
          </div>
          <div className="w-100 h-100 position-relative align-items-start overflow-x-hidden custom-scroll">
            <div className="border border-light-subtle rounded bg-light mx-2 mb-3 d-flex flex-column position-relative">
              <div className="h-100 position-relative align-items-start" style={{ maxHeight: "89%" }}>
                <div className="h-100" style={{ padding: "0px 0px 0px 5px" }}>
                  {this.state.VisitantesAll.map((data, index) => (
                    <div className="row border  border-secondary rounded bg-white my-2" key={index} style={{ marginInline: "0px", width: "95%", cursor: "pointer" }}
                      onClick={() => this.handler(data)}>
                      <div className="col" style={{ minWidth: "cal(100%-45px)" }}>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>{data.direccion}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{data.nombre_visitante}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{parseInt(data.tipo) === 1 ? 'Frecuente' : 'Anunciado'}</span>
                      </div>
                      <div className=" d-flex align-items-center justify-content-center" style={{ maxWidth: "45px" }}>
                        <button className="btn btn-primary rounded-circle btn-sm" onClick={() => this.handler(data)}>
                          <i className="fa-solid fa-check" />
                        </button>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div>
          <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}>
            <PpHourglass />
          </Modal>
          {this.state.visitantes ? (<MaterialTable
            title={``}
            columns={this.state.cols}
            data={this.state.data}
            options={{
              pageSize:
                this.state.data.length < 20
                  ? this.state.data.length
                  : 20,
              pageSizeOptions: [
                this.state.data.length < 20
                  ? this.state.data.length
                  : 20,
              ],
              actionsColumnIndex: -1,
              padding: "dense",
              rowStyle: (rowData) => {
                if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                  return {
                    backgroundColor: "#eeeeee",
                    fontSize: "0.9rem",
                    textTransform: "uppercase"
                  };
                }
                return {
                  backgroundColor: "#ffffff",
                  fontSize: "0.9rem",
                  textTransform: "uppercase"
                };
              },
            }}
          />) : ("")}
        </div>
      );
    }
  }
}
