import React, { Component } from "react";
import axios from "axios";
import Global from "../../../../Global";
import swal from "sweetalert";
import { CookieSesion } from "../../../Helpers";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import { Fab } from "@material-ui/core";
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";
import { Modal } from 'react-bootstrap';
import moment from "moment";
import Loader from "../../../principal/Loader";
const columns = [
    {
        field: "id",
        title: "Id",
        hidden: true,
    },
    {
        field: "inmuebleid",
        title: "Inmueble Id",
        hidden: true,
    },
    {
        field: "tipo",
        title: "Tipo",
    },
    {
        field: "marca",
        title: "Marca",
    },
    {
        field: "color",
        title: "Color",
    },
    {
        field: "placa",
        title: "Placa",
    },
    {
        field: "direccion",
        title: "Dirección",
    },
];

class DataTable extends Component {

    state = {
        redirect_home: false,
        redirect_login: 0,
        data: false,
        modal: false,
        modal1: false,
        Vehiculos: [],
        Telefonos: [],
        Placa: "",

        Rol: 0,
        PermisoPortero: ''
    }

    componentWillMount() {

        var login = CookieSesion();
        var userid = Global.GlobalUser.userid;
        var userRol = Global.GlobalUser.rol;

        if (parseInt(login) > 0) {
            if (parseInt(userid) > 0) {
                if (parseInt(userRol) === 4 || parseInt(userRol) === 1 || parseInt(userRol) === 2) {
                    var body = {
                        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                    }
                    axios.post(`${Global.Ruta.Url}porteria/listvehiculo/`, body).then((res) => {
                        if (res.data.message === "") {
                            axios.post(Global.Ruta.Url + "porteria/permite/", body).then((res1) => {
                                if (res1.data.message === "") {
                                    this.setState({ data: true, Vehiculos: res.data.vehiculos, Rol: userRol, PermisoPortero: res1.data.PermisoTelefonos });
                                } else {
                                    console.log(res1.data.message)
                                }
                            }).catch(err2 => {
                                console.log(err2.message)
                            })

                        } else {
                            swal({
                                title: "ALERTA!",
                                text: res.data.message,
                                icon: "error",
                                button: "Aceptar"
                            }).then(() => {
                                this.setState({ redirect_home: true })
                            });
                        }
                    }).catch((err) => {
                        swal({
                            title: "ALERTA!",
                            text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                            icon: "error",
                            button: "Aceptar"
                        }).then(e => {
                            this.setState({ redirect_home: true })
                        });
                    });
                } else {
                    this.setState({ redirect_home: true })
                }
            } else {
                this.setState({ redirect_login: 1 })
            }
        } else {
            this.setState({ redirect_login: 1 })
        }
    }

    NumerosDeTelefono = (inmuebleid, placa) => {
        this.setState({
            modal: true
        })
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            inmuebleid: Buffer.from("" + inmuebleid).toString("base64"),
        }
        axios.post(`${Global.Ruta.Url}porteria/listcontacto/`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({
                    modal: false,
                    modal1: true,
                    Telefonos: res.data.Telefonos,
                    Placa: placa
                });
            } else {
                swal({
                    title: "ALERTA!",
                    text: res.data.message,
                    icon: "error",
                    button: "Aceptar"
                })
            }
        }).catch((err) => {
            swal({
                title: "ALERTA!",
                text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar"
            })
        });
    }

    render() {

        if (this.state.redirect_home === true) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/home-admin'} />
            )
        }

        if (this.state.redirect_login === 1) {
            return (
                <Redirect to={Global.Ruta.Raiz + '/'} />
            )
        }

        if (this.state.data === true) {
            return (
                <div className="container-fluid bg-light">
                    <Modal show={this.state.modal} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                        <PpHourglass />
                    </Modal>
                    <Modal show={this.state.modal1} centered onHide={() => this.setState({ modal1: false })}>
                        <Modal.Header closeButton>
                            {`Números de Contacto del Vehículo ${this.state.Placa}`}
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row row-cols-2 gy-3">
                                {this.state.Telefonos.map((telefono, indice) => (
                                    <div key={indice} className="col-12">
                                        <label htmlFor="nombre">
                                            <Fab className="mx-3 bg-primary" aria-label="edit" size="small" disabled>
                                                <i className="fa-solid fa-phone" style={{ color: 'white' }} />

                                            </Fab>
                                            {`  ${telefono.telefono}`}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div className="row py-2">
                        <div className="col-12">
                            <MaterialTable
                                columns={columns}
                                data={this.state.Vehiculos}
                                title="Vehículos"

                                actions={[
                                    {
                                        onClick: (event, rowData) => this.NumerosDeTelefono(rowData.inmuebleid, rowData.placa)
                                    }
                                ]}
                                components={{
                                    Action: props => (
                                        this.state.PermisoPortero === true ? <Fab className="mx-3" onClick={(event) => props.action.onClick(event, props.data)} color="primary"
                                            aria-label="edit" size="small" >
                                            <i className="fas fa-square-phone" />
                                        </Fab> : ''
                                    ),
                                }}
                                options={{
                                    pageSize: (parseInt(this.state.Rol) === 4) ? (this.state.Vehiculos.length < 11 ? this.state.Vehiculos.length : 10) : (this.state.Vehiculos.length < 5 ? this.state.Vehiculos.length : 4),
                                    pageSizeOptions: (parseInt(this.state.Rol) === 4) ? ([this.state.Vehiculos.length < 11 ? this.state.Vehiculos.length : 10]) : ([this.state.Vehiculos.length < 5 ? this.state.Vehiculos.length : 4]),
                                    paging: (parseInt(this.state.Rol) === 4) ? (this.state.Vehiculos.length >= 11 ? true : false) : (this.state.Vehiculos.length >= 5 ? true : false),
                                    actionsColumnIndex: -1,
                                    search: (parseInt(this.state.Rol) === 4) ? (true) : (false),
                                    exportButton: (parseInt(this.state.Rol) === 4) ? (false) : (true),
                                    exportAllData: (parseInt(this.state.Rol) === 4) ? (false) : (true),
                                    exportDelimiter: (parseInt(this.state.Rol) === 4) ? ("") : (";"),
                                    exportFileName: (parseInt(this.state.Rol) === 4) ? ("") : (`Informe de vehículos, ${moment(new Date()).format('LL')}`),
                                    rowStyle: (rowData) => {
                                        if (
                                            parseInt(rowData.tableData.id + 1) % 2 ===
                                            0
                                        ) {
                                            return {
                                                backgroundColor: "#eeeeee",
                                                fontSize: "0.9rem",
                                            };
                                        }
                                        return {
                                            backgroundColor: "#ffffff",
                                            fontSize: "0.9rem",
                                        };
                                    },
                                }}

                                localization={{
                                    header: {
                                        actions: 'Contacto',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos por mostrar.',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'registros',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar',
                                        exportCSVName: "CSV",
                                        exportPDFName: "PDF"
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            // <div className="d-flex justify-content-center text-info donita">
            //     <div className="spinner-border" role="status">
            //         <span className="sr-only">Loading...</span>
            //     </div>
            // </div>
            <Loader princial={true} uno={"Vehículos"} dos={"Datos"} tres={"Inmuebles"} />
        );
    }
}

export default DataTable;