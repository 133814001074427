
import React, { Component } from 'react'
import PpHourglass from "../Asamblea/PopUps/PpHourglass";
import { Modal } from 'react-bootstrap';
import SidebarReportes from './components/SidebarReportes';
import Rplacas from './screens/Rplacas';
import Rronda from './screens/Rronda'
import RAsistenciaReserva from './screens/RAsistenciaReserva'
import Rcartera from './screens/Rcartera'
import HeadTitle from './../../principal/HeadTitle';

export default class ReportesComp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      PpHourglass: false,
      mainWind: 0,
      tabs: 0,
      fechaI: new Date(),
      indexSideBar: [
        {
          id: 0,
          label: 'placas',
          icon: 'fa-solid fa-car-on',
        },
        {
          id: 1,
          label: 'Rondas',
          icon: 'fa-solid fa-map-location-dot',
        },
        {
          id: 2,
          label: 'Cartera',
          icon: 'fa-solid fa-chart-line',
        },
        {
          id: 3,
          label: 'Reserva',
          icon: 'fa-solid fa-calendar-days',
        },
      ]
    }
  }


  IndxButtons = (indx) => {
    this.setState({ mainWind: indx });
  };


  render() {

    const SwitchPanel = (props) => {

      const { option } = props

      switch (option) {
        case 0:
          return (
            <Rplacas />
          )
        case 1:
          return (
            <Rronda />
          )
        case 2:
          return (
            <Rcartera />
          )

        case 3:
          return (
            <RAsistenciaReserva />
          )

        default:
          return (
            <Rplacas />
          )

      }
    }

    const { indexSideBar, mainWind } = this.state

    return (
      <div className='container-fluid mh-100'>
        <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>

        <div className='row h-100'>
          <HeadTitle
            titulo="Reportes"
            subTitulo="Modulo para radicar reportes"
            classNameOwn="col-12 px-5 pt-3 pb-1"
            indicadores={[]}
            actionButton={null}
          />
          {/* side bar */}
          <div className='col-12'>
            <div className='row pt-4 pb-1 bg-light'>
              <SidebarReportes indexBtn={indexSideBar} mainWind={mainWind} setPanel={this.IndxButtons} />
              {/* Main */}
              <div className='col px-4'>
                <SwitchPanel option={mainWind} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )


  }
}

