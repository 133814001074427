import React, { Component } from "react";
import axios from "axios";
import Global from "../../../../Global";

import MaterialTable from "material-table";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Es from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Modal, Navbar } from "react-bootstrap";
import moment from 'moment';

const columns = [
  {
    field: "id_sms",
    title: "Id sms",
    hidden: true,
  },
  {
    field: "destino",
    title: "Destino",
    hidden: true,
  },
  {
    field: "id_porteria",
    title: "Id Porteria",
    hidden: true,
  },
  {
    field: "asunto",
    title: "Asunto",
  },
  {
    field: "mensaje",
    title: "Mensaje",
  },
  {
    field: "fecha_envio",
    title: "Fecha de envio",
  },
];

export default class TablaHistorialPort extends Component {

  state = {
    estado: "autorizado",
    rows: [],
    data: false,

    //DATA
    Rol: 0,
    Historial: false,
    Solicitudes: [],
    Busqueda: "",
    TemParaBusqueda: [],

    fechai: null,
    fechaf: null,
    fechaAct: new Date(),

    Roles: [1, 2],

    // MODAL
    modal: false,
    datosModal: { destinos: [] },
  };

  componentWillMount() {
    this.listHistorialMensaje("0")
  }

  listHistorialMensaje = (caso) => {

    this.setState({ data: false })

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      caso: Buffer.from(caso).toString("base64"),
      fechai: Buffer.from("" + moment(this.state.fechai).format("DD/MM/YYYY")).toString("base64"),
      fechaf: Buffer.from("" + moment(this.state.fechaf).format("DD/MM/YYYY")).toString("base64"),
    }

    axios.post(Global.Ruta.Url + "porteria/historialmsn/", body).then((res) => {
      if (res.data.message === "") {

        var tem = res.data.Historial
        var rows = [];

        for (let i of tem) {
          var temp = i
          temp.fecha_envio = moment(i.fecha_envio).format('LLLL')
          rows.push(temp)
        }

        this.setState({ rows: rows, data: true });
      } else {
        this.props.cerrar();
      }
    }).catch(err => {
      this.props.cerrar();
    })
  }

  filtro = () => {
    var Caso = ""
    if (this.state.fechai === null && this.state.fechaf === null) {
      Caso = "0"
    } else if (this.state.fechai !== null && this.state.fechaf === null) {
      Caso = "2"
    } else if (this.state.fechai === null && this.state.fechaf !== null) {
      Caso = "3"
    } else if (this.state.fechai !== null && this.state.fechaf !== null) {
      Caso = "1"
    }

    this.listHistorialMensaje(Caso)
  }

  dataDetalle = (rowData) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_porteria: Buffer.from("" + rowData.id_porteria).toString("base64"),
      destino: Buffer.from("" + rowData.destino).toString("base64"),
      id_sms: Buffer.from("" + rowData.id_sms).toString("base64"),
    }

    axios.post(Global.Ruta.Url + "porteria/detallemensaje/", body).then((res) => {
      if (res.data.message === "") {

        var datosModal = {
          asunto: rowData.asunto,
          mensaje: rowData.mensaje,
          fecha_envio: rowData.fecha_envio,
          porteria: res.data.Portero,
          destinos: res.data.Enviados
        }

        this.setState({ modal: true.valueOf, datosModal: datosModal });
      } else {
        console.log("Error 37961 - " + res.data.message)
        this.setState({ modal: false });
      }
    }).catch(err => {
      console.log("Error 575654 - " + err.message)
      this.setState({ modal: false });
    })
  }

  render() {
    return (
      <div className="container-fluid">

        <Modal show={this.state.modal} onHide={() => this.setState({ modal: false })} backdrop="static" centered className="shadow-lg" backdropClassName="bg-dark">
          <Modal.Header closeButton>Detalle</Modal.Header>
          <Modal.Body className=''>
            <div className="row px-3 gy-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-auto bg-secondary px-2 py-1 rounded">
                    <h5 className="text-white m-0">Asunto</h5>
                  </div>
                  <div className="col-12 border border-3 p-1 rounded shadow" style={{ borderBlockColor: 'gainsboro' }}>
                    <h6 className="fw-normal">{this.state.datosModal.asunto}</h6>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-auto bg-secondary px-2 py-1 rounded">
                    <h5 className="text-white m-0">Mensaje</h5>
                  </div>
                  <div className="col-12 border border-3 p-1 rounded shadow" style={{ borderBlockColor: 'gainsboro' }}>
                    <h6 className="fw-normal">{this.state.datosModal.mensaje}</h6>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-auto bg-secondary px-2 py-1 rounded">
                    <h5 className="text-white m-0">Fecha de envió</h5>
                  </div>
                  <div className="col-12 border border-3 p-1 rounded shadow" style={{ borderBlockColor: 'gainsboro' }}>
                    <h6 className="fw-normal">{this.state.datosModal.fecha_envio}</h6>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-auto bg-primary px-2 py-1 rounded">
                    <h5 className="text-white m-0">De:{' '}
                      <span>{this.state.datosModal.porteria}</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-auto bg-primary px-2 py-1 rounded">
                    <h5 className="text-white m-0">Para:</h5>
                  </div>
                  <div className="col-12 border border-3 p-1 rounded shadow p-3" style={{ borderBlockColor: 'gainsboro' }}>
                    <div className="row row-cols-1 gy-2">

                      {this.state.datosModal.destinos.map((destino, indice) => (
                        <div className="col bg-primary px-2 py-1 rounded cover-marqss" key={indice}>
                          <h6 className="fw-normal text-white m-0 marqss" >{destino}</h6>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="row justify-content-center mb-3 pt-2 px-3">
          <div className="card bg-light col-12 text-center shadow">
            <div className="card-body">
              <h3>Historial mensajes a portería</h3>
              <p className="m-0 fw-normal" style={{ fontSize: "14px" }}>
                Historial de los mensajes realizadas a portería por la
                Administración
              </p>
            </div>
            <div className="card-footer p-0">
              <Navbar className="navbar bg-light" expand="lg" style={{ backgroundColor: "gainsboro" }}>
                <div className="navbar-brand ms-2">Filtro</div>
                <Navbar.Toggle aria-controls="id_navbar_1" />
                <Navbar.Collapse className="py-3 py-md-0" id="id_navbar_1">
                  <ul className="navbar-nav row justify-content-end align-items-center py-2 w-100 mx-0">
                    <div className="col-auto col-lg-10">
                      <div className="row justify-content-center">
                        <li className="col-auto">
                          <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                            <DatePicker label="Fecha inicio" value={this.state.fechai} maxDate={this.state.fechaAct} onChange={(date) => this.setState({ fechai: date })}
                              renderInput={(params) => (<TextField {...params} />)} />
                          </LocalizationProvider>
                        </li>
                        <li className="col-auto">
                          <LocalizationProvider dateAdapter={AdapterDateFns} locale={Es}>
                            <DatePicker label="Fecha final" value={this.state.fechaf} minDate={this.state.fechai} maxDate={this.state.fechaAct}
                              onChange={(date) => this.setState({ fechaf: date })} renderInput={(params) => (<TextField {...params} />)} />
                          </LocalizationProvider>
                        </li>
                      </div>
                    </div>
                  </ul>
                  <div className="col-auto d-flex justify-content-center px-3">
                    <Button className="mx-1" color="error" variant="contained" onClick={() => { this.setState({ fechai: null, fechaf: null }); this.listHistorialMensaje("0") }}>Quitar Filtro
                    </Button>
                    <Button className="mx-1" color="primary" variant="contained" disabled={(this.state.fechai === null && this.state.fechaf === null) ? (true) : (false)}
                      onClick={() => { this.filtro() }}>Filtrar
                    </Button>
                  </div>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
        {(this.state.data) ? (
          <div className="row">
            <div className="col-12 shadow-lg p-0">
              <MaterialTable
                columns={columns}
                data={this.state.rows}
                title={`Suscriptores Sin App`}
                actions={[
                  {
                    onClick: (event, rowData) => {
                      this.dataDetalle(rowData)
                    },
                  },
                ]}
                components={{
                  Action: (props) => (
                    <button
                      className="btn btn-primary text-nowrap m-2"
                      onClick={(event) => {
                        props.action.onClick(event, props.data);
                      }}>
                      mas detalles
                    </button>
                  ),
                }}
                options={{
                  pageSize: 10,
                  actionsColumnIndex: -1,
                  showTitle: false,
                  rowStyle:{fontSize: "0.9rem"},
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                    nRowsSelected:
                      "{0} " + this.props.tituloT + "(s) Seleccionado(s)",
                  },
                }}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center text-info donita">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
