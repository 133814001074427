import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { Buffer } from "buffer";



export default class LectorQR extends Component {

    state = {
        redirect_login: false,
    }

    handleScan = (data) => {
        if (data) {
            var phcode = this.DescifrarURL(data.split(";")[0])
            var id_usuario = this.DescifrarURL(data.split(";")[1])
            this.props.DataLectorQR(phcode, id_usuario)
            this.props.CloseLector()
        }
    }

    DescifrarURL = (Valor) => {
        var phcode = ""
        if (Valor !== undefined) {
            var SinBuffer = Buffer.from(Valor, "base64").toString("utf8");
            var primero = SinBuffer.substr(7);
            var segundo = primero.substring(0, primero.length - 5);
            var ParteUno = segundo.substring(segundo.length - 5, segundo.length);
            var ParteDos = segundo.substring(0, segundo.length - 5);
            phcode = Buffer.from(ParteUno + "" + ParteDos, "base64").toString("utf8");
            return phcode
        }
    };

    handleError = (err) => {
        //this.setState({ result: err });
    };

    render() {

        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        // const isAndroid = /Android/.test(navigator.userAgent);
        return (
            <div className='flex justify-center items-center h-full'>

                <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: isIOS ? '28em' : '20em', }}
                    facingMode="environment"
                />



            </div>
        )
    }
}
