import React, { Component } from "react";
import { Card, CardHeader, Typography, Tooltip, Divider } from "@mui/material";
import { Redirect } from "react-router-dom";
import HeadTitle from "../../principal/HeadTitle";
import Loader from "../../principal/Loader";
import CrearAgenda from './CrearAgenda'
import Global from "../../../Global";
import swal from 'sweetalert';
import axios from "axios";
import Dropdown from 'react-bootstrap/Dropdown'
import { styled } from '@mui/material/styles';
import PpHourglass from "../Asamblea/PopUps/PpHourglass";
import { Modal } from "react-bootstrap";

class AgendaMain extends Component {


    state = {
        windowWidth: window.innerWidth,
        redirect_home: false,
        Archive: false,
        Grid: parseInt(Global.GlobalUser.rol) !== 4 ? false : true,
        Data: false,
        Modal: false,
        DataModal: {},
        Loader: false,
        DropdownOpen: false,
        showButtons: -1,
        idNotaTemp: undefined,
        idTareaTemp: undefined,
        Notas: [],
        Nota: [],
        ViewButton: parseInt(Global.GlobalUser.rol) !== 4 ? false : true,
        PpHourglass: false,
    }

    componentWillMount() {
        this.NotasAdmin()
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    componentDidUpdate(prevProps) {
        if (this.props.caso !== undefined) {
            if (this.props.CambioNota !== prevProps.CambioNota) {
                this.NotasAdmin()
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    NotasAdmin = () => {
        this.setState({ Data: false })
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString('base64'),
            idusuario: Buffer.from("" + Global.GlobalUser.userid).toString('base64'),
            rol: Buffer.from("" + Global.GlobalUser.rol).toString('base64'),
        };
        axios.post(`${Global.Ruta.Url}tarea/selectnotas`, body).then(res => {
            this.setState(
                { Notas: res.data.Notas },
                () => {
                    this.setState({ Data: true, Modal: false, Archive: false })
                }
            );
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    NotasAdminArchivadas = () => {
        this.setState({ Data: false })
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
            idusuario: Buffer.from(Global.GlobalUser.userid).toString('base64'),
        };
        axios.post(`${Global.Ruta.Url}tarea/selectnotasarchivadas`, body).then(res => {
            if (res.data.Notas.length > 0) {
                this.setState(
                    { Notas: res.data.Notas }, () => { this.setState({ Data: true, Modal: false, Archive: true }) }
                )
            } else {
                swal({
                    title: "",
                    text: "No tienes notas archivadas",
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ Data: true, Archive: false })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    FijarCard = (index) => {
        this.setState({ Loader: true })
        var temp = this.state.Notas
        temp[index].fijar = !temp[index].fijar
        this.setState({ Notas: temp })
        var id_nota = temp[index].id_nota

        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
            idnota: Buffer.from("" + id_nota).toString('base64'),
        };
        axios.post(`${Global.Ruta.Url}tarea/fijarnotas`, body).then(res => {
            this.setState({ Loader: false })
            if (res.data.message !== "") {
                swal({
                    title: "",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    temp[index].fijar = !temp[index].fijar
                    this.setState({ Notas: temp, Data: true })
                })
            }
        }).catch(err => {
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    ActionCard = (index, index1, caso) => {
        this.setState({ PpHourglass: false, })
        var temp = this.state.Notas
        var data = {
            titulo: parseInt(caso) === 1 ? ("") : (`${temp[index].nombre}`),
            index: index,
            index1: index1,
            caso: caso,
        }

        var nota = {
            f_last_edit: parseInt(caso) === 1 ? ("") : (temp[index].f_last_edit),
            fijar: parseInt(caso) === 1 ? ("") : (temp[index].fijar),
            id_nota: parseInt(caso) === 1 ? (undefined) : (temp[index].id_nota),
            nombre: parseInt(caso) === 1 ? ("") : (temp[index].nombre),
            tareas: parseInt(caso) === 1 ? ([]) : (temp[index].tareas)
        }

        this.setState(
            { DataModal: data, Nota: nota },
            () => {
                this.setState({ Modal: true, PpHourglass: false, })
            }
        );


    }

    AñadirAgenda = (caso, data) => {
        this.setState({ Loader: true })
        var temp = this.state.Notas
        var temp1 = this.state.DataModal
        var body = {}
        if (parseInt(caso) === 1) {
            body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
                codigoph: Buffer.from("" + Global.Ph.Codigo).toString('base64'),
                idusuario: Buffer.from("" + Global.GlobalUser.userid).toString('base64'),
                nombre: Buffer.from("" + data.nombre).toString('base64'),
                listaTareas: Buffer.from(JSON.stringify(data.tareas)).toString('base64'),
            };
        } else {
            body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
                codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
                idusuario: Buffer.from(Global.GlobalUser.userid).toString('base64'),
                nombre: Buffer.from("" + data.nombre).toString('base64'),
                listaTareas: Buffer.from(JSON.stringify(data.tareas)).toString('base64'),
            };
        }

        axios.post(`${Global.Ruta.Url}tarea/newnotas`, body).then(res => {
            this.setState({ Loader: false })
            if (res.data.message === "") {
                if (parseInt(caso) === 1) {
                    data.id_nota = res.data.id_nota
                    temp1.index = temp.length
                    temp.push(data)
                    this.setState({ idNotaTemp: res.data.id_nota, Notas: temp, DataModal: temp1 })
                } else {
                    this.NotasAdmin()
                }
            } else {
                swal({
                    title: "",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    if (parseInt(caso) === 1) {
                        this.setState({ Modal: false })
                    } else {
                        this.NotasAdmin()
                    }
                })
            }
        }).catch(err => {
            this.setState({ Loader: false })
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    AñadirTarea = (id_nota, data, index) => {
        this.setState({ Loader: true, data: false })
        var temp = this.state.Notas
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
            idusuario: Buffer.from("" + Global.GlobalUser.userid).toString('base64'),
            nombre: Buffer.from("" + data.nombre).toString('base64'),
            idnota: Buffer.from("" + id_nota).toString('base64'),
        };
        axios.post(`${Global.Ruta.Url}tarea/newtarea`, body).then(res => {
            if (res.data.message === "") {
                data.id_tarea = res.data.id_tarea
                temp[index].f_last_edit = new Date()
                temp[index].tareas.push(data)
                this.setState({ Notas: temp, data: true, Loader: false })
            } else {
                swal({
                    title: "",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {

                    this.setState({ Modal: false, Loader: false })

                })
            }
        }).catch(err => {
            this.setState({ Loader: false })
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    updateNombreNota = (data) => {
        this.setState({ Loader: true })
        var temp = this.state.Notas
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
            nombre: Buffer.from("" + data.nombre).toString('base64'),
            idnota: Buffer.from("" + data.id_nota).toString('base64'),
        };

        temp[data.index].nombre = data.nombre
        temp[data.index].f_last_edit = new Date()
        this.setState({ Notas: temp })

        axios.post(`${Global.Ruta.Url}tarea/updatenombrenotas`, body).then(res => {
            this.setState({ Loader: false })
            if (res.data.message !== "") {
                swal({
                    title: "",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.setState(
                        { Data: false },
                        () => {
                            this.setState({ Modal: false, Loader: false })
                            this.NotasAdmin()
                        }
                    );
                })
            }
        }).catch(err => {
            this.setState({ Loader: false })
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    UpdateNota = (index, estado) => {
        this.setState({ Loader: true })
        var anuncio = ""
        var temp = this.state.Notas
        var id_nota = temp[index].id_nota
        temp[index].f_last_edit = new Date()
        switch (parseInt(estado)) {
            case 0:
                anuncio = "Desarchivar"
                break;
            case 1:
                anuncio = "Archivar"
                break;
            case 2:
                anuncio = "Eliminar"
                break;
            default:
                break;
        }

        swal({
            title: `${temp[index].nombre}`,
            text: `¿Desea ${anuncio} esta nota?`,
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
        }).then((willDelete) => {
            if (willDelete) {
                temp.splice(index, 1);
                this.setState({ Notas: temp, Modal: false })
                var body = {
                    ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
                    codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
                    idnota: Buffer.from("" + id_nota).toString('base64'),
                    estado: Buffer.from("" + estado).toString('base64'),
                };
                axios.post(`${Global.Ruta.Url}tarea/setestadonota`, body).then(res => {
                    this.setState({ Loader: false })
                    if (res.data.message !== "") {
                        swal({
                            title: "",
                            text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                            icon: "warning",
                            button: "Aceptar"
                        }).then(() => {
                            this.setState({ Data: false })
                            this.NotasAdmin()
                        })
                    }
                }).catch(err => {
                    swal({
                        title: "ERROR!",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar"
                    }).then(() => {
                        this.setState({ redirect_home: true })
                    })
                });
            }
        })
    }

    MarcarAllElementos = async (index, caso, update) => {
        var temp = this.state.Notas[index]
        // const temp1 = temp.tareas.filter(tarea => tarea.estado === 0).map(tarea => tarea.id_tarea)
        var temp1 = []
        if (parseInt(caso) === 1) {
            temp1 = temp.tareas.map((tarea, idx) => tarea.estado === 0 ? idx : -1).filter(idx => idx !== -1);
        } else {
            temp1 = temp.tareas.map((tarea, idx) => tarea.estado === 1 ? idx : -1).filter(idx => idx !== -1);
        }
        if (temp1.length > 0) {
            for (var i = 0; i < temp1.length; i++) {
                await this.EstadoTarea(index, temp1[i], update)
            }
        }
    }

    EstadoTarea = (index, index1, caso) => {
        return new Promise((resolve, reject) => {
            this.setState({ Loader: true, Data: false })
            var temp = this.state.Notas
            if (parseInt(caso) === 1) {
                temp[index].tareas[index1].estado = temp[index].tareas[index1].estado === 1 ? 0 : 1
            } else {
                temp[index].tareas[index1].estado = 2
            }
            var id_tarea = temp[index].tareas[index1].id_tarea
            var estado = temp[index].tareas[index1].estado
            temp[index].f_last_edit = new Date()
            this.setState(
                { Notas: temp },
                () => {
                    this.setState({ Data: true })
                }
            );

            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
                codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
                idtarea: Buffer.from("" + id_tarea).toString('base64'),
                estado: Buffer.from("" + estado).toString('base64'),
                idnota: Buffer.from("" + temp[index].id_nota).toString('base64'),
            };
            axios.post(`${Global.Ruta.Url}tarea/archivartarea`, body).then(res => {
                this.setState({ Loader: false })
                if (res.data.message !== "") {
                    swal({
                        title: "",
                        text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                        icon: "warning",
                        button: "Aceptar"
                    }).then(() => {
                        if (parseInt(caso) === 1) {
                            temp[index].tareas[index1].estado = temp[index].tareas[index1].estado === 1 ? 0 : 1
                        } else { temp[index].tareas[index1].estado = 1 }
                        this.setState({ Notas: temp, Data: true })
                    })
                } else { resolve() }
            }).catch(err => {
                swal({
                    title: "ERROR!",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar"
                }).then(() => {
                    this.setState({ redirect_home: true })
                })
            })
                .catch(err => {

                    reject()
                });
        });
    }

    UpdateNombreTarea = (data) => {
        this.setState({ Loader: true })
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
            idtarea: Buffer.from("" + data.idtarea).toString('base64'),
            nombre: Buffer.from("" + data.nombre).toString('base64'),
            idnota: Buffer.from("" + data.id_nota).toString('base64'),
        };

        axios.post(`${Global.Ruta.Url}tarea/updatenombretarea`, body).then(res => {
            this.setState({ Loader: false })
            if (res.data.message !== "") {
                swal({
                    title: "",
                    text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                    icon: "warning",
                    button: "Aceptar"
                }).then(() => {
                    this.setState(
                        { Data: false },
                        () => {
                            this.setState({ Modal: false, Loader: false })
                            this.NotasAdmin()
                        }
                    );
                })
            }
        }).catch(err => {
            this.setState({ Loader: false })
            swal({
                title: "ERROR!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar"
            }).then(() => {
                this.setState({ redirect_home: true })
            })
        });
    }

    CrearCopiaAgenda = (index) => {
        var temp = this.state.Notas
        var data = {
            nombre: temp[index].nombre,
            tareas: temp[index].tareas,
        }
        this.AñadirAgenda(2, data)
    }

    render() {
        if (this.state.redirect_home === true) {
            return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />)
        }

        const CardNotas = (props) => {
            const largo = !this.state.Grid ? (25) : (this.state.ViewButton ? 75 : 50)
            const StyledLabel = styled('label')({
                color: "black",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontSize: '14px',
                overflow: 'hidden',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                maxWidth: '230px', // Puedes ajustar esto según el ancho máximo deseado
            });
            return (
                <Card sx={!this.state.Grid ? ({ maxWidth: 300, minWidth: 300, minHeight: 300 }) : ({ minWidth: 300, minHeight: 300 })} className=" 
                 border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative"
                    onClick={() => !this.state.Archive ? (this.ActionCard(props.index, -1, 2)) : ("")}
                    onMouseEnter={() => this.setState({ showButtons: props.index })}
                    onMouseLeave={() => this.setState({ showButtons: -1 })}
                >
                    <CardHeader
                        title={<StyledLabel>{props.notas.nombre}</StyledLabel>}
                        action={!this.state.Archive && !this.state.ViewButton ? (
                            <Tooltip title={!props.notas.fijar ? ("Fijar") : ("Dejar de fijar")}>
                                <button className="btn btn-light rounded-circle " style={{ display: this.state.showButtons === props.index ? 'block' : 'none' }} onClick={(event) => { event.stopPropagation(); this.FijarCard(props.index) }}>
                                    {props.notas.fijar ? (<i className="fa-solid fa-thumbtack"></i>) : (<i className="fa-solid fa-thumbtack fa-rotate-90" style={{ color: "#abc9e8" }}></i>)}
                                </button>
                            </Tooltip>
                        ) : ("")}
                    />
                    {props.notas.tareas.length > 0 ? (
                        <div>
                            {
                                props.notas.tareas.map((tarea, index1) => (
                                    Global.GlobalUser.rol !== 4 ? (

                                        tarea.estado === 0 && index1 < 7 ? (
                                            <Typography key={index1} sx={{ mb: 0.2 }} color="text.secondary" className="text-start">
                                                <button className="btn btn-light rounded-circle" disabled={this.state.Archive} onClick={(event) => { event.stopPropagation(); this.EstadoTarea(props.index, index1, 1) }}>
                                                    <i className="fa-regular fa-circle" style={{ color: "#608fe2" }}></i>
                                                </button>
                                                <span style={{ fontSize: '12px' }} onClick={!this.state.Archive ? ((event) => { event.stopPropagation(); this.ActionCard(props.index, index1, 2) }) : ("")}>{tarea.nombre.length > largo ? `${tarea.nombre.slice(0, largo)}...` : tarea.nombre}</span>

                                            </Typography>
                                        ) : null
                                    ) : (
                                        tarea.estado === 0 ? (
                                            <Typography key={index1} sx={{ mb: 0.2 }} color="text.secondary" className="text-start">
                                                <button className="btn btn-light rounded-circle" disabled={this.state.Archive} onClick={(event) => { event.stopPropagation(); this.EstadoTarea(props.index, index1, 1) }}>
                                                    <i className="fa-regular fa-circle" style={{ color: "#608fe2" }}></i>
                                                </button>
                                                <span style={{ fontSize: '12px' }} onClick={!this.state.Archive ? ((event) => { event.stopPropagation(); this.ActionCard(props.index, index1, 2) }) : ("")}>{tarea.nombre.length > largo ? `${tarea.nombre.slice(0, largo)}...` : tarea.nombre}</span>

                                            </Typography>
                                        ) : null
                                    )
                                ))
                            }
                            {props.notas.tareas.some(tarea => tarea.estado === 1) && (
                                !props.notas.tareas.slice(0, 7).every(tarea => tarea.estado === 1) && (
                                    <Divider style={{ backgroundColor: 'grey', height: '2px' }} className="my-2" />
                                ))
                            }
                            {

                                props.notas.tareas.map((tarea, index1) => (

                                    Global.GlobalUser.rol !== 4 ? (
                                        tarea.estado === 1 && index1 < 7 ? (
                                            <Typography key={index1} sx={{ mb: 0.2 }} color="text.secondary" className="text-start">
                                                <button className="btn btn-light rounded-circle" disabled={this.state.Archive} onClick={(event) => { event.stopPropagation(); this.EstadoTarea(props.index, index1, 1) }}>
                                                    <i className="fa-regular fa-circle-check fa-shake" style={{ color: "#608fe2" }}></i>
                                                </button>
                                                <span disabled={this.state.Archive} onClick={!this.state.Archive ? ((event) => { event.stopPropagation(); this.ActionCard(props.index, index1, 2) }) : ("")} style={{ textDecoration: "line-through", color: "#ccc", fontSize: '12px' }}>{tarea.nombre.length > largo ? `${tarea.nombre.slice(0, largo)}...` : tarea.nombre}</span>
                                            </Typography>
                                        ) : null
                                    ) : (
                                        tarea.estado === 1 ? (
                                            <Typography key={index1} sx={{ mb: 0.2 }} color="text.secondary" className="text-start">
                                                <button className="btn btn-light rounded-circle" disabled={this.state.Archive} onClick={(event) => { event.stopPropagation(); this.EstadoTarea(props.index, index1, 1) }}>
                                                    <i className="fa-regular fa-circle-check fa-shake" style={{ color: "#608fe2" }}></i>
                                                </button>
                                                <span disabled={this.state.Archive} onClick={!this.state.Archive ? ((event) => { event.stopPropagation(); this.ActionCard(props.index, index1, 2) }) : ("")} style={{ textDecoration: "line-through", color: "#ccc", fontSize: '12px' }}>{tarea.nombre.length > largo ? `${tarea.nombre.slice(0, largo)}...` : tarea.nombre}</span>
                                            </Typography>
                                        ) : null
                                    )



                                ))

                            }
                            {
                                Global.GlobalUser.rol === 1 ? (
                                    props.notas.tareas.length > 5 ? (
                                        <Typography sx={{ mb: 0.5, ml: 5 }} color="text.secondary" className="text-start">
                                            <span style={{ color: "#5d5c5c" }}>...</span>
                                        </Typography>
                                    ) : ("")) : ("")

                            }
                        </div>
                    ) : ("")}

                    {(!this.state.ViewButton) ? (
                        <div className="mt-auto d-flex justify-content-between mb-0">
                            <div className="row">
                                <div className="col  px-0 ps-3">
                                    <Tooltip title="Eliminar">
                                        <button className="btn btn-light rounded-circle " style={{ display: this.state.showButtons === props.index ? 'block' : 'none' }} onClick={(event) => { event.stopPropagation(); this.UpdateNota(props.index, 2) }}><i className="fa-solid fa-trash-can" style={{ color: "#d16f6f" }}></i></button>
                                    </Tooltip>
                                </div>
                                <div className="col px-0">
                                    <Tooltip title={!this.state.Archive ? ("Archivar") : ("Desarchivar")}>
                                        <button className="btn btn-light rounded-circle justify-content-start" style={{ display: this.state.showButtons === props.index ? 'block' : 'none' }} onClick={(event) => { event.stopPropagation(); this.UpdateNota(props.index, this.state.Archive ? 0 : 1) }}> {!this.state.Archive ? (<i className="fa-solid fa-box-archive" style={{ color: "#abc9e8" }}></i>) : (<i className="fa-solid fa-boxes-packing" style={{ color: "#abc9e8" }}></i>)}  </button>
                                    </Tooltip>

                                </div>
                            </div>
                            {!this.state.Archive ? (
                                <div className="d-flex justify-content-center align-items-center pe-1" onClick={(event) => { event.stopPropagation() }}
                                >
                                    <Tooltip title="Más">
                                        <Dropdown style={{ display: this.state.showButtons === props.index ? 'block' : 'none' }} >
                                            <Dropdown.Toggle variant="light" className="rounded-circle custom-dropdown-toggle" >
                                                <i className="fa-solid fa-ellipsis-vertical" style={{ color: "#8dbdee", display: this.state.showButtons === props.index ? 'block' : 'none' }}></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(event) => { event.stopPropagation(); this.setState({ DropdownOpen: false }); this.CrearCopiaAgenda(props.index) }}>Crear una copia</Dropdown.Item>
                                                <Dropdown.Item onClick={(event) => { event.stopPropagation(); this.setState({ DropdownOpen: false }); this.MarcarAllElementos(props.index, 1, 1) }}>Marcar todos los elementos</Dropdown.Item>
                                                <Dropdown.Item onClick={(event) => { event.stopPropagation(); this.setState({ DropdownOpen: false }); this.MarcarAllElementos(props.index, 2, 1) }}>Desmarcar todos los elementos</Dropdown.Item>
                                                <Dropdown.Item onClick={(event) => { event.stopPropagation(); this.setState({ DropdownOpen: false }); this.MarcarAllElementos(props.index, 2, 2) }}>Eliminar elementos marcados</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Tooltip>
                                </div>
                            ) : ("")}


                        </div>
                    ) : ("")
                    }
                </Card >
            )
        }

        if (this.state.Data === true && this.props.caso === undefined) {
            return (

                <div className="container">

                    {this.state.Modal && (
                        <CrearAgenda show={this.state.Modal} CerrarModal={() => this.setState({ Modal: false })} DataModal={this.state.DataModal} Nota={this.state.Nota}
                            AñadirAgenda={(caso, data) => this.AñadirAgenda(caso, data)} idNotaTemp={this.state.idNotaTemp} idTareaTemp={this.state.idTareaTemp}
                            AñadirTarea={(id_nota, data, index) => this.AñadirTarea(id_nota, data, index)}
                            EstadoTarea={(index, index1, caso) => this.EstadoTarea(index, index1, caso)}
                            MarcarAllElementos={(index, caso, update) => this.MarcarAllElementos(index, caso, update)}
                            UpdateNota={(index, estado) => this.UpdateNota(index, estado)}
                            UpdateNombreTarea={(data) => this.UpdateNombreTarea(data)}
                            updatenombrenota={(data) => this.updateNombreNota(data)}
                        />
                    )}

                    <div className="col-12 mt-2 overflow-y-visible">
                        <HeadTitle classNameOwn="row align-items-center " titulo={parseInt(Global.GlobalUser.rol) !== 4 ? "Notas" : "Minutas"} subTitulo="" indicadores={[]}
                            actionButton={
                                <div className="buttons-container d-flex justify-content-end pe-0 overflow-y-hidden">
                                    {(!this.state.ViewButton) ? (
                                        <div className="col " >
                                            {!this.state.Archive ? (
                                                <Tooltip title="Añadir Nota">
                                                    <button className="btn btn-ligh rounded-circle m-1  "
                                                        onClick={() => this.ActionCard(0, -1, 1)}>
                                                        <i className="fa-solid fa-folder-plus" style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                                    </button>
                                                </Tooltip>
                                            ) : ("")}


                                            <Tooltip title={!this.state.Archive ? ("Notas archivadas") : ("Notas")}>
                                                <button className="btn btn-ligh rounded-circle m-1"
                                                    onClick={() => !this.state.Archive ? (this.NotasAdminArchivadas()) : (this.NotasAdmin())}>
                                                    {!this.state.Archive ? (<i className="fa-solid fa-box-archive" style={{ color: "#abc9e8", fontSize: "24px" }}></i>) :
                                                        (<i className="fa-solid fa-lightbulb" style={{ color: "#abc9e8", fontSize: "24px" }}></i>)}
                                                </button>
                                            </Tooltip>
                                        </div>
                                    ) :
                                        ("")
                                    }

                                    <Tooltip title="Actualizar">
                                        <button className="btn btn-ligh rounded-circle"
                                            onClick={() => this.NotasAdmin()} >
                                            <i className="fa-solid fa-arrow-rotate-right" style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                        </button>
                                    </Tooltip>
                                    {this.state.windowWidth >= 800 && !this.state.ViewButton ? (
                                        <div className={`d-flex align-items-center pe-1`}>
                                            <Tooltip title={!this.state.Grid ? ("Ver por listas") : ("Ver por cuadrícula")}>
                                                <button className="btn btn-ligh rounded-circle m-1 me-2"
                                                    onClick={() => this.setState({ Grid: !this.state.Grid })} >
                                                    <i className={`${!this.state.Grid ? ("fa-solid fa-pause fa-rotate-90") : ("fa-solid fa-grip")}`} style={{ color: "#abc9e8", fontSize: "24px" }}></i>
                                                </button>
                                            </Tooltip>
                                        </div>
                                    ) : ("")}
                                    <div className="d-flex align-items-center pe-5 " >
                                        {!this.state.Loader ? (<span><i className="fa-solid fa-cloud" style={{ color: "#abc9e8", fontSize: "24px" }}></i></span>) : (<Loader princial={false} uno={"Nota"} dos={"Notas"} tres={"Imagenes"} />)}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    {
                        !this.state.Grid ? (
                            this.state.Notas.find((notas) => notas.fijar) && (
                                <div className="my-1">
                                    <span className="m-0 fw-light text-muted text-center" style={{ color: "black", fontWeight: "bold", paddingTop: "1000px", fontSize: "14px" }}>FIJADAS</span>
                                </div>
                            )
                        ) : (
                            this.state.Notas.find((notas) => notas.fijar) && (
                                <div className="my-1 text-center">
                                    <span className="m-0 fw-light text-muted" style={{ color: "black", fontWeight: "bold", fontSize: "14px", textAlign: "center" }}>FIJADAS</span>
                                </div>
                            )
                        )
                    }
                    {
                        !this.state.Grid ? (
                            <div className={`row row-cols-1 row-cols-md-4  mb-4 ${this.state.windowWidth >= 800 ? 'justify-content-start' : 'justify-content-center'}`}>
                                {this.state.Notas.map((notas, index) => (
                                    notas.fijar === true && (
                                        <CardNotas key={index} notas={notas} index={index} />
                                    )
                                ))}
                            </div>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                <div className={`${(this.state.ViewButton) ? "col-9" : "col-6"}`}>
                                    {this.state.Notas.map((notas, index) => (
                                        notas.fijar === true && (
                                            <div key={index} className="mb-4">
                                                <CardNotas notas={notas} index={index} />
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        )
                    }

                    {
                        !this.state.Grid ?
                            (this.state.Notas.find((notas) => notas.fijar) && this.state.Notas.find((notas) => !notas.fijar)) && (
                                <div className="my-1 ">
                                    <span className="m-0 fw-light text-muted" style={{ color: "black", fontWeight: "bold", paddingTop: "1000px", fontSize: "14px" }}>OTRAS</span>
                                </div>
                            ) : (
                                this.state.Notas.find((notas) => notas.fijar) && this.state.Notas.find((notas) => !notas.fijar)) && (
                                <div className="my-1 text-center">
                                    <span className="m-0 fw-light text-muted" style={{ color: "black", fontWeight: "bold", paddingTop: "1000px", fontSize: "14px" }}>OTRAS</span>
                                </div>)
                    }

                    {
                        !this.state.Grid ? (
                            <div className={`row row-cols-1 row-cols-md-4  mb-4 ${this.state.windowWidth >= 800 ? 'justify-content-start' : 'justify-content-center'}`}>
                                {this.state.Notas.map((notas, index) => (
                                    notas.fijar === false && (
                                        <CardNotas key={index} notas={notas} index={index} />
                                    )
                                ))}
                            </div>
                        ) : (
                            //porteria
                            <div className="d-flex align-items-center justify-content-center">
                                <div className={`${(this.state.ViewButton) ? "col-9" : "col-6"}`}>
                                    {this.state.Notas.map((notas, index) => (
                                        notas.fijar === false && (
                                            <div key={index} className="mb-4">
                                                <CardNotas notas={notas} index={index} />
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        )
                    }


                    {this.state.Notas.length === 0 ? (
                        <div className="d-flex flex-column align-items-center mt-5">
                            <div className="mb-4">
                                {!this.state.Archive ? (<i className="fa-regular fa-lightbulb fa-9x" style={{ color: "#ccc" }}></i>) : (
                                    <i className="fa-solid fa-boxes-packing fa-9x" style={{ color: "#ccc" }}></i>
                                )}
                            </div>
                            <div>
                                <Typography variant="h5" style={{ fontFamily: 'Comfortaa', fontSize: '1.5rem', color: '#ccc' }}>
                                    {!this.state.Archive ? ("Las notas que añadas aparecerán aquí") : ("Tus notas archivadas se mostrarán aquí")}
                                </Typography>
                            </div>
                        </div>
                    ) : ("")}

                </div>

            )
        }

        if (this.state.Data === true && this.props.caso !== undefined) {
            return (
                <div className="w-100 h-100 position-relative align-items-start overflow-x-hidden custom-scroll">
                    <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}>
                        <PpHourglass />
                    </Modal>
                    {this.state.Notas.map((notas, index) => (
                        notas.fijar === false && (
                            <div key={index} className="mb-4">
                                <CardNotas notas={notas} index={index} />
                            </div>
                        )
                    ))}

                    {this.state.Modal && (
                        <CrearAgenda show={this.state.Modal} CerrarModal={() => this.setState({ Modal: false })} DataModal={this.state.DataModal} Nota={this.state.Nota}
                            AñadirAgenda={(caso, data) => this.AñadirAgenda(caso, data)} idNotaTemp={this.state.idNotaTemp} idTareaTemp={this.state.idTareaTemp}
                            AñadirTarea={(id_nota, data, index) => this.AñadirTarea(id_nota, data, index)}
                            EstadoTarea={(index, index1, caso) => this.EstadoTarea(index, index1, caso)}
                            MarcarAllElementos={(index, caso, update) => this.MarcarAllElementos(index, caso, update)}
                            UpdateNota={(index, estado) => this.UpdateNota(index, estado)}
                            UpdateNombreTarea={(data) => this.UpdateNombreTarea(data)}
                            updatenombrenota={(data) => this.updateNombreNota(data)}
                        />
                    )}
                </div>
            )
        }


        return (
            <div style={{ minHeight: this.props.caso === undefined ? "100vh" : "10%" }}>
                <Loader princial={true} uno={"Notas"} dos={"Tareas"} tres={"Imagenes"} />
            </div>
        );


    }
}

export default AgendaMain;