import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import Global from "../../../Global";
import axios from "axios";
import Button from "../IconHome";
import { NavLink } from "react-router-dom";
import {
  CookieSesion,
  CookieNombrePH,
  CookiePhCodigo,
  CookieNombre,
  CookieTipo,
  CookieUserId,
  CookieRol,
  CookieNitph,
  CookieCiudad,
  CookieRondero,
  CookieSupervisor
} from "../../Helpers";
//--------------------------------------------CSS--------------------------------------------

import "../../../assets/css/admin.css";

//-------------------------------------------Iconos------------------------------------------

// imagenes
import img_1 from '../../../assets/images/images_menu/img_1.png'
import img_2 from '../../../assets/images/images_menu/img_2.png'
import img_3 from '../../../assets/images/images_menu/img_3.png'
import img_4 from '../../../assets/images/images_menu/img_4.png'
import img_5 from '../../../assets/images/images_menu/img_5.png'
import img_6 from '../../../assets/images/images_menu/img_6.png'
import img_7 from '../../../assets/images/images_menu/img_7.png'
import img_8 from '../../../assets/images/images_menu/img_8.png'
import img_9 from '../../../assets/images/images_menu/img_9.png'
import img_10 from '../../../assets/images/images_menu/img_10.png'
import img_11 from '../../../assets/images/images_menu/img_11.png'
import img_12 from '../../../assets/images/images_menu/img_12.png'
import img_header from "../../../assets/images/images_menu/img_header.png";

// componentes
import CardCopyLink from "./compos/CardCopyLink";
import { Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";



const cookies = new Cookie();

class HomeA extends Component {

  state = {
    redirect_login: 0,
    CantInmuebles: 0,
    Reservas_Pend: 0,
    CantPreRegistro: 0,
    solicitudes: 0,
    solicitudesAdmin: 0,
    TareasPendientes: 0,
    Rol: -1,
    Roles: [1, 2],

    nombreEvento: 'Bienvenido a GoPH',
    descrEvento: '',
    slide: false,
    h: 0,
    pantalla: window.screen.width,

    modalQr: false

  };

  componentWillMount() {
    var login = CookieSesion();
    var Rol = CookieRol();
    var userid = CookieUserId();

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        this.setState({
          Rol: parseInt(Rol),
        });
        Global.GlobalUser.rol = parseInt(Rol);
        Global.GlobalUser.userid = CookieUserId();
        Global.GlobalUser.Nombre = CookieNombre();
        Global.GlobalUser.Rondero = CookieRondero();
        Global.GlobalUser.Supervisor = CookieSupervisor();
        Global.Ph.Nombre = CookieNombrePH();
        Global.Ph.Codigo = CookiePhCodigo();
        Global.Ph.Nitph = CookieNitph();
        Global.Ph.Ciudadph = CookieCiudad();
        Global.Ph.Estadoph = CookieTipo();
        axios.get(Global.Ruta.Url + "admin/inithome/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from(Global.Ruta.Ipin).toString("base64")).then((res) => {
          if (res.data.message === "") {
            if (parseInt(Rol) === 4) {
              this.cantidadPeticiones(4);
            }
            if (this.state.Roles.includes(this.state.Rol)) {
              this.cantidadPeticiones(1);

            }
            if (parseInt(Rol) === 3 || parseInt(Rol) === 5) {
              this.setState({
                redirect_login: 1,
              });
            }
            this.setState({
              CantInmuebles: parseInt(res.data.CantInmuebles),
              Reservas_Pend: parseInt(res.data.Reservas_Pend),
              CantPreRegistro: parseInt(res.data.cantPreRegistro),
              TareasPendientes: parseInt(res.data.TareasPendientes)
            });
          }

          var user = parseInt(Rol) + ";" + CookieUserId() + ";" + CookieNombre() + ";" + CookiePhCodigo() + ";" + CookieNombrePH();

          Global.GlobalUser.UserLog = user;

        }).catch((err) => {
          cookies.remove("Sesion", { path: "/" });
          cookies.remove("UserId", { path: "/" });
          cookies.remove("Nombre", { path: "/" });
          this.setState({
            redirect_login: 2,
          });
        });
      } else {
        cookies.remove("Sesion", { path: "/" });
        cookies.remove("UserId", { path: "/" });
        cookies.remove("Nombre", { path: "/" });
        this.setState({
          redirect_login: 2,
        });
      }
    } else {
      this.setState({
        redirect_login: 2,
      });
    }
    if (parseInt(Rol) === 0) {
      cookies.remove("Sesion", { path: "/" });
      cookies.remove("UserId", { path: "/" });
      cookies.remove("Nombre", { path: "/" });
      this.setState({
        redirect_login: 2,
      });
    }
  }

  cantidadPeticiones = (rol) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };
    if (rol === 4) {
      axios.post(`${Global.Ruta.Url}porteria/cantpeticiones`, body).then((res) => {
        if (res.data.message === "") {
          this.setState({
            solicitudes: res.data.cantidad,
            redirect_login: 1,
          });

        }
      }).catch((err) => { });
    }
    if (rol === 1) {
      axios.post(`${Global.Ruta.Url}admin/cantpeticiones`, body).then((res) => {
        if (res.data.message === "") {
          this.setState({
            solicitudesAdmin: res.data.cantidad,
            redirect_login: 1,
          });
        }
      })
        .catch((err) => { });
    }
  };

  render() {

    const admBtn = [
      {
        id: '1',
        titulo: 'Comunidad',
        descr: 'Información y Edición de los residentes, aprobación de Pre-Registro',
        url: '/suscriptores',
        icon: img_1,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ||
          this.state.Rol === 3 ||
          this.state.Rol === 4
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Sección donde se encuentra la información de los perfiles de los
          usuarios Residentes y Propietarios, Login de acceso a la App GoPH,
          Rol, email, teléfonos y demás. <br /> Aquí podemos cambiar el
          estado, restablecer sus contraseñas de acceso, cambiar datos
          básicos, enviar email de bienvenida y mas… <br />
          Contiene un apartado para aprobar el registro de sus datos de acceso
          que realizan desde la App GoPH o desde el link web.
        </p>,
        onClick: null,
        rolAccs: [1, 2, 3, 4]
      },
      {
        id: '2',
        titulo: 'Propietarios',
        descr: 'Los inmuebles y los residentes que lo habitan',
        url: '/libro-propietarios',
        icon: img_2,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ||
          this.state.Rol === 3 ||
          this.state.Rol === 4
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Corresponde al registro de los residentes de cada inmueble y sus
          vehículos registrados. Aquí se configuran los inmuebles que
          registran MORA en el pago de las expensas.
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
      {
        id: '3',
        titulo: 'Usuarios',
        descr: 'Datos de los usuarios de Administración y colaboradores',
        url: '/usuarios',
        icon: img_3,
        color: 'palegreen',
        pin: [1, 2].includes(this.state.Rol) ? "0" : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Los USUARIOS pertenecen al grupo de Administradores, Asistentes o
          Auxiliares y los colaboradores de vigilancia en Portería de la
          Unidad Residencial y son quienes pueden administrar la plataforma
          GoPh, enviar mensajes PUSH o comunicados a los residentes, aprobar
          reservas de zonas sociales o pre registros de nuevos usuarios que
          solicitan los residentes a través de la APP, ver informes de
          Asambleas y mucho más... <br />
          Aquí puedes crear nuevos usuarios, modificarlos, cambiar contraseñas
          de acceso e inactivar usuarios.
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
      {
        id: '4',
        titulo: 'Vehículos',
        descr: 'Los vehículos de los residentes y su parking',
        url: '/vehiculos',
        icon: img_3,
        color: 'palegreen',
        pin: [1, 2, 4].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Modulo para revisar los vehículos.
        </p>,
        onClick: null,
        rolAccs: [4]
      },
      {
        id: '5',
        titulo: 'Registro de Placas',
        descr: 'Registra entrada y salida de vehículos',
        url: '/placas',
        icon: img_3,
        color: 'palegreen',
        pin: [4].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Modulo para registro de placas.
        </p>,
        onClick: null,
        rolAccs: [4]
      },
      {
        id: '6',
        titulo: 'Código QR',
        descr: 'Invitar a un residente a registrarse a través de un formulario web',
        url: '/home-admin',
        icon: img_7,
        color: 'palegreen',
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          En este modulo puedes generar un código QR que permite al que lea este código con su dispositivo, acceder a un formulario de registro a la plataforma web de GoPH.
        </p>,
        onClick: () => this.setState({ modalQr: true }),
        rolAccs: [1, 2, 4]
      },
      {
        id: '7',
        titulo: 'Notas',
        descr: 'Crear y editar notas con tareas a realizar',
        url: '/notas',
        icon: img_11,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) || this.state.Rol === 3 || this.state.Rol === 4 ? "1" : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          En este módulo, podrás crear nuevas notas y agregar tareas a tu lista, las cuales podrás marcar como completadas, archivar o eliminar.
          Adicionalmente, tendrás la opción de fijarlas, lo que garantizará que siempre estén disponibles, facilitando así su acceso y prioridad.
        </p>,
        onClick: null,
        rolAccs: [1, 2, 4]
      },
    ]

    const comBtn = [
      {
        id: '8',
        titulo: 'Peticiones',
        descr: 'Solicitudes a la Administración',
        url: '/peticiones',
        icon: img_4,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ? "1" : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Cuando los residentes desean comunicarse con la administración de la
          propiedad horizontal, desde la App GoPH realizan sus peticiones y la
          administración desde aquí responderá a cada una de ellas.
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
      {
        id: '9',
        titulo: 'Mensajes',
        descr: 'Enviar notificaciones a la comunidad',
        url: '/sistema-msn',
        icon: img_5,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ||
          this.state.Rol === 4
          ? "0"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          El módulo de Mensajería nos permite enviar notificaciones a los
          dispositivos móviles de los propietarios y/o residentes que tienen
          la App GoPH instalada. Es posible enviar mensajes a toda la
          comunidad, solo a propietarios, solo a residentes o grupos
          personalizados creados por el Administrador de la Propiedad
          Horizontal. <br /> Contiene un historial de mensajes enviados y la
          posibilidad de conocer quienes no tienen la App GoPH instalada
          permitiendo enviar un email de invitación a descargarla y usarla.
        </p>,
        onClick: null,
        rolAccs: [1, 2, 4]
      },
      {
        id: '10',
        titulo: 'Documentos',
        descr: 'Enviar comunicados a la comunidad',
        url: '/subir-archivo',
        icon: img_6,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ? "0" : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          La administración requiere enviar un comunicado en formato PDF que
          los residentes puedan leer de manera inmediata. <br /> Es aquí donde
          fácilmente se envían los documentos para toda la comunidad que
          usa la App GoPH.
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
      {
        id: '11',
        titulo: 'Solicitudes Portería',
        descr: 'Paquetería, Domicilio y Visitantes',
        url: '/autorizaciones',
        icon: img_4,
        color: 'palegreen',
        pin: [1, 2, 4].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Solicitudes de paquetería, domicilios y visitantes.
        </p>,
        onClick: null,
        rolAccs: [4]//[1, 2, 4]
      },
      {
        id: '12',
        titulo: 'Historial',
        descr: 'Comunicación a portería',
        url: '/despacho-porteria/paquetes',
        icon: img_3,
        color: 'palegreen',
        pin: [3, 4].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Comunicación enviada desde administración.
        </p>,
        onClick: null,
        rolAccs: [4]
      },
      {
        id: '13',
        titulo: 'Porteria',
        descr: 'Historial de solicitudes a portería',
        url: '/porteria-msn',
        icon: img_12,
        color: 'palegreen',
        pin: [3, 4].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Modulo para enviar mensajes a portería y leer su respectiva respuesta.
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
    ]

    const gestBtn = [
      {
        id: '14',
        titulo: 'Zonas Sociales',
        descr: 'Crear y configurar zonas de la Unidad Residencial',
        url: '/ZonaSocial',
        icon: img_8,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ? "0" : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Aquí se crean y configuran las Zonas de uso y disfrute tales como
          canchas para deportes, piscinas, salones sociales, gimnasios, entre
          otras. Una vez activas éstas zonas los residentes pueden realizar
          reservas para su uso a través de la APP GoPH. <br /> Se pueden
          activar e inactivar, configurar horarios para disponibilidad,
          permitir a moros o no su uso, agotar reservas pendientes para
          realizar otra
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
      {
        id: '15',
        titulo: 'Reservas',
        descr: 'Aprobación e Historial de reservas',
        url: '/ReservaZonas',
        icon: img_9,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) ||
          this.state.Rol === 4
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          En este apartado se encuentran las reservas de zonas sociales que
          realizan los residentes a través de la App GoPH. <br /> Aquí la
          administración o su asistente autorizan o deniegan las reservas que
          requieren una aprobación.
        </p>,
        onClick: null,
        rolAccs: [1, 2, 4, 5]
      },
      {
        id: '16',
        titulo: 'Informes',
        descr: 'Reportes e Informes',
        url: '/reportes',
        icon: img_10,
        color: 'palegreen',
        pin: this.state.Roles.includes(this.state.Rol) || this.state.Rol === 3 ? "0" : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          En este módulo Los Administradores, Asistentes o Auxiliares pueden
          descargar los informes de una Asamblea realizada con GoPH, entre
          ellos informes de registro de placas, rondas y otros más...
        </p>,
        onClick: null,
        rolAccs: [1, 2]
      },
      {
        id: '17',
        titulo: 'Asambleas',
        descr: 'Informes de asambleas virtuales',
        url: '/home-asamblea',
        icon: img_3,
        color: 'palegreen',
        pin: [1, 2, 3].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Información sobre la asamblea realizada.
        </p>,
        onClick: null,
        rolAccs: [1, 2, 3]
      },
      {
        id: '18',
        titulo: 'Ronda',
        descr: 'Añade rondas para porteria',
        url: '/ronda-porteria',
        icon: img_3,
        color: 'palegreen',
        pin: [1, 2].includes(this.state.Rol)
          ? "1"
          : "2",
        masInfo: <p className="m-0 fw-light ind-txt-sm">
          Modulo para crear y editar las rondas.
        </p>,
        onClick: null,
        rolAccs: [1, parseInt(Global.GlobalUser.Supervisor) === 0 ? 2 : ""]
      }
    ]

    const { modalQr } = this.state

    if (this.state.redirect_login === 1) {
      return (
        <div className="container-fluid h-100" style={{ minWidth: 0 }}>
          <div className="row h-100 position-relative">


            <div className="col-12 px-0 position-relative">
              <img src={img_header} alt="" className="w-100" style={{ height: '12rem' }} />
              <div className="position-absolute d-flex align-items-center justify-content-center" style={{ inset: 0 }}>
                <h5 className="bg-secondary text-white p-2 rounded-2 fw-bolder shadow">{Global.Ph.Nombre}</h5>
              </div>
            </div>


            <div className="col-12 pt-1">
              <div className="ps-5 d-flex mb-2 mb-md-0">
                <span className="fw-bold fs-4" style={{ fontSize: '1rem' }}>
                  Bienvenid@
                </span>
              </div>

              <div className="row justify-content-center gap-1 gap-md-5">
                <div className="col-auto  text-center fw-bold">
                  <NavLink key={"0"} to={`/suscriptores`}>
                    {/* <div className="col-auto  text-center fw-bold"> */}
                    <h6 className="fw-bold text-primary">
                      Residentes
                    </h6>
                    <h6 className="" style={{ fontWeight: '900', marginBottom: '0px', color: "black" }}>
                      {this.state.CantInmuebles}
                    </h6>
                    {/* </div> */}
                  </NavLink>
                </div>
                {this.state.Rol !== 4 && (
                  <React.Fragment>
                    <div className="col-auto text-center fw-bold">
                      <NavLink key={"0"} to={`/peticiones`}>
                        <h6 className="fw-bold text-primary">
                          Solicitudes
                        </h6>
                        <h6 className="fw-bolder " style={{ fontWeight: '900', marginBottom: '0px', color: "black" }}>{this.state.solicitudesAdmin}</h6>
                      </NavLink>
                    </div>
                    <div className="col-auto  text-center fw-bold">
                      <NavLink key={"0"} to={`/ReservaZonas`}>
                        <h6 className="fw-bold text-primary">
                          Reservas
                        </h6>
                        <h6 className="fw-bold " style={{ fontWeight: '900', marginBottom: '0px', color: "black" }}>
                          {this.state.Reservas_Pend}
                        </h6>
                      </NavLink>
                    </div>
                    <div className="col-auto  text-center fw-bold">
                      <NavLink key={"0"} to={`/notas`}>
                        <h6 className="fw-bold text-primary">
                          Tareas
                        </h6>
                        <h6 className="fw-bold " style={{ fontWeight: '900', marginBottom: '0px', color: "black" }}>
                          {this.state.TareasPendientes}
                        </h6>
                      </NavLink>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className="col-12 col-lg pt-3 pt-xl-3 pt-xxl-3">
              <div className="row gy-3 h-100 px-5">
                <div className='col-12 pt-4'>
                  <hr className="bg-violetblue opacity-75" style={{ height: '0.15rem' }} />
                  <h5>
                    Administración
                  </h5>
                  <div className="row row-cols-4 column-gap-1 flex-nowrap pb-3 pb-md-0 overflow_card_menu">
                    {admBtn.map((item, index) => {
                      if (item.rolAccs.includes(this.state.Rol)) {
                        return (
                          <div key={index} className={`col-auto `}>
                            <Button
                              id={item.id}
                              titulo={item.titulo}
                              descripcion={item.descr}
                              url={Global.Ruta.Raiz + item.url}
                              colorIcon={item.color}
                              icono={item.icon}
                              masInfo={item.masInfo}
                              onClick={item.onClick}
                            />
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>

                </div>
                <div className="col-12 pt-4">
                  <hr className="bg-violetblue opacity-75" style={{ height: '0.15rem' }} />
                  <h5>
                    Comunicación
                  </h5>
                  <div className="row row-cols-4 column-gap-1 row-gap-4 flex-nowrap pb-3 pb-md-0 overflow_card_menu">
                    {comBtn.map((item, index) => {
                      if (item.rolAccs.includes(this.state.Rol)) {
                        return (
                          <div key={index} className={`col-auto `}>
                            <Button
                              id={item.id}
                              titulo={item.titulo}
                              descripcion={item.descr}
                              url={Global.Ruta.Raiz + item.url}
                              colorIcon={item.color}
                              icono={item.icon}
                              masInfo={item.masInfo}
                              onClick={item.onClick}
                            />
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
                <div className="col-12 pt-4">
                  <hr className="bg-violetblue opacity-75" style={{ height: '0.15rem' }} />
                  <h5>
                    Gestión
                  </h5>
                  <div className="row row-cols-4 column-gap-1 flex-nowrap pb-3 pb-md-0 overflow_card_menu">
                    {gestBtn.map((item, index) => {
                      if (item.rolAccs.includes(this.state.Rol)) {
                        return (
                          <div key={index} className={`col-auto `}>
                            <Button
                              id={item.id}
                              titulo={item.titulo}
                              descripcion={item.descr}
                              url={Global.Ruta.Raiz + item.url}
                              colorIcon={item.color}
                              icono={item.icon}
                              masInfo={item.masInfo}
                              onClick={item.onClick}
                            />
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div >

          <Modal show={modalQr} centered backdrop='static' size="xl" contentClassName="bg-transparent border-0">
            <div className="d-flex align-items-center justify-content-center gap-3">
              <CardCopyLink openOverMod={() => this.setState({ modalQr: true })} closeOverMod={() => this.setState({ modalQr: false })} />
              <Tooltip title='Cerrar' placement="right" className="fs-4">
                <button className="btn btn-danger rounded-circle d-flex justify-content-center align-items-center"
                 onClick={() => this.setState({ modalQr: false })}
                  >
                  <i className="fa-solid fa-times fs-1" />
                </button>
              </Tooltip>
            </div>
          </Modal>
        </div >
      );
    }

    if (this.state.redirect_login === 2) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default HomeA;