import React, { Component } from 'react'
import { Form, Modal } from 'react-bootstrap';
import CampoDeTexto from './compos/CampoDeTexto';
import HorasAtencion from './compos/HorasAtencion';
import swal from "sweetalert";
import Global from "../../../Global";
import SeccionBanka from './compos/SeccionBanka';
import axios from "axios";
import CampoDeTextoPsw from './compos/CampoDeTextoPsw';


export default class MyPhModal extends Component {

    state = {
        // data
        n_ph: '',
        nit: '',
        fijo: '',
        addrss: '',
        city: '',
        email: '',
        passw: '',
        domin: '',
        admin: '',
        tel_admin: '',
        aux: '',
        tel_aux: '',
        horarios: [],
        wtsapp: '',
        cuentas: [],
        val: false,
        Pwd: "",
        permiteporteria: false,
        checked: false,
        look: true

    }

    componentWillMount() {
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}admin/dataph/`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({
                    n_ph: res.data.data.nombre,
                    addrss: res.data.data.direccion,
                    city: res.data.data.ciudad,
                    nit: res.data.data.nit,
                    email: res.data.data.email,
                    domin: res.data.data.datos_adicionales.Host,
                    admin: res.data.data.contacto,
                    tel_admin: res.data.data.movil,
                    fijo: res.data.data.telefono_fijo,
                    aux: res.data.data.datos_adicionales.Asistente.Nombre,
                    tel_aux: res.data.data.datos_adicionales.Asistente.Telefono,
                    horarios: res.data.data.datos_adicionales.Horario,
                    Pwd: res.data.data.datos_adicionales.Pwd,
                    cuentas: res.data.data.datos_adicionales.CuentaBancaria,
                    wtsapp: res.data.data.datos_adicionales.Whatsapp,
                    permiteporteria: res.data.data.datos_adicionales.permiteporteria,
                })
            } else {
                swal({
                    title: "ALERTA!",
                    text: res.data.message,
                    icon: "warning",
                    button: "Aceptar",
                })
            }
        }).catch((err) => {
            console.log("Error al cargar los datos de la ph: " + err.message)
        })
    }

    handleChange = (id, value) => {
        switch (id) {
            case 'cmp1':
                this.setState({ n_ph: value })
                break;
            case 'cmp2':
                this.setState({ addrss: value })
                break;
            case 'cmp3':
                this.setState({ city: value })
                break;
            case 'cmp4':
                let valid = '@'
                if (value.match(valid)) {
                    this.setState({ email: value, val: true })
                } else {
                    this.setState({ email: value, val: false })
                }
                break;
            case 'cmp5':
                this.setState({ admin: value })
                break;
            case 'cmp6':
                this.setState({ tel_admin: value })
                break;
            case 'cmp7':
                this.setState({ aux: value })
                break;
            case 'cmp8':
                this.setState({ tel_aux: value })
                break;
            case 'cmp9':
                this.setState({ wtsapp: value })
                break;
            case 'cmp12':
                this.setState({ nit: value })
                break;
            case 'cmp13':
                this.setState({ fijo: value })
                break;
            case 'cmp14':
                this.setState({ passw: value })
                break;
            case 'cmp15':
                this.setState({ domin: value })
                break;
            default:
                break;
        }
    }

    agregarHorario = (Data) => {
        let temp = this.state.horarios
        temp.push(Data)
        this.setState({
            horarios: temp
        })
    }

    eliminarHorario = (indice) => {
        let temp = this.state.horarios
        temp.splice(indice, 1)
        this.setState({
            horarios: temp
        })
    }

    agregarCuenta = (Data) => {
        let temp = this.state.cuentas
        temp.push(Data)
        this.setState({
            cuentas: temp
        })
    }

    eliminarCuenta = (indice) => {
        let temp = this.state.cuentas
        temp.splice(indice, 1)
        this.setState({
            cuentas: temp
        })
    }

    Guardar = () => {
        if (this.state.n_ph === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar el nombre de la propiedad horizontal.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.addrss === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar una dirección.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.nit === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar el numero NIT.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.fijo === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar un teléfono.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.city === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar una ciudad.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.email === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar un email.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.admin === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar un nombre de administrador (a).",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.tel_admin === '') {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar un teléfono de administración.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.horarios.length === 0) {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar almenos un horario.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        if (this.state.cuentas.length === 0) {
            swal({
                title: "ALERTA!",
                text: "Debe ingresar almenos una cuenta bancaria.",
                icon: "warning",
                button: "Aceptar",
            })
            return
        }
        var caso = 0
        var Pwd = ""
        if (this.state.passw === "") {
            caso = 1
            Pwd = this.state.Pwd
        } else {
            caso = 0
            Pwd = Buffer.from("" + this.state.passw).toString("base64")
        }

        var tmp = {
            Horario: this.state.horarios,
            Asistente: {
                Nombre: this.state.aux,
                Telefono: this.state.tel_aux
            },
            CuentaBancaria: this.state.cuentas,
            Whatsapp: this.state.wtsapp,
            permiteporteria: this.state.permiteporteria,
            Pwd: Pwd,
            Host: this.state.domin
        }

        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            caso: Buffer.from("" + caso).toString("base64"),
            nombre: Buffer.from(this.state.n_ph).toString("base64"),
            direccion: Buffer.from(this.state.addrss).toString("base64"),
            ciudad: Buffer.from(this.state.city).toString("base64"),
            nit: Buffer.from(this.state.nit).toString("base64"),
            email: Buffer.from(this.state.email).toString("base64"),
            contacto: Buffer.from(this.state.admin).toString("base64"),
            telefono: Buffer.from(this.state.tel_admin).toString("base64"),
            fijo: Buffer.from(this.state.fijo).toString("base64"),
            datosadicionales: Buffer.from(JSON.stringify(tmp)).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}admin/updatedataph/`, body).then((res) => {

            if (res.data.message === "OK") {

                swal({
                    title: "ATENCIÓN!",
                    text: "Los datos fueron guardados satisfactoriamente.",
                    icon: "success",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                })

            } else {
                swal({
                    title: "ATENCIÓN",
                    text: res.data.message,
                    icon: "warning",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                })
            }
        }).catch((err) => {
            console.log("Error al actualizar los datos de la ph: " + err.message)
        })
    }

    render() {
        return (
            <div className='g-r-prf1 h-100'>
                <Modal.Header closeButton>
                    <h5 className="m-0">Mi PH</h5>
                </Modal.Header>
                <Modal.Body className='' >
                    <div className="row g-r-prf2">
                        <div className="col text-center pb-2">
                            <h3>Información de la PH.</h3>
                            <p className="text-muted m-0 fw-normal">Información de contacto del area administrativa.</p>
                        </div>
                    </div>
                    <div className="row row-cols-2 w-50 mx-auto gy-3">
                        <div className="col-12">
                            <CampoDeTexto id='cmp1' valor={this.state.n_ph} writhing={this.handleChange} icono='building-user' label='Nombre de la PH' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp2' valor={this.state.addrss} writhing={this.handleChange} icono='house' label='Dirección' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp3' valor={this.state.city} writhing={this.handleChange} icono='city' label='Ciudad' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp12' valor={this.state.nit} writhing={this.handleChange} icono='building-circle-check' label='NIT' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp13' valor={this.state.fijo} writhing={this.handleChange} icono='phone' tipo='number' label='Teléfono Fijo' />
                        </div>
                        <div className="col-12">
                            <CampoDeTexto id='cmp4' valor={this.state.email} writhing={this.handleChange} tipo='email' icono='at' label='E-mail' />
                        </div>
                        <div className="col">
                            <CampoDeTextoPsw id='cmp14' valor={this.state.passw} writhing={this.handleChange} icono='lock' look={this.state.look} handleLook={() => (this.state.look) ? (this.setState({ look: false })) : (this.setState({ look: true }))} label='Contraseña' />
                        </div>
                        <div className="col">
                            <Form.Floating>
                                <Form.Select id='cmp15' value={this.state.domin} onChange={(e) => this.handleChange(e.target.id, e.target.value)}>
                                    <option value={'Hotmail'}>{'Outlook (Hotmail)'}</option>
                                    <option value={'Gmail'}>{'Gmail'}</option>
                                </Form.Select>
                                <label><i className='fa-solid fa-envelopes-bulk' /> Dominio</label>
                            </Form.Floating>
                        </div>

                        <div className="col">
                            <CampoDeTexto id='cmp5' valor={this.state.admin} writhing={this.handleChange} icono='user-shield' label='Administrador' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp6' tipo='number' valor={this.state.tel_admin} writhing={this.handleChange} icono='phone' label='Teléfono Admin' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp7' valor={this.state.aux} writhing={this.handleChange} icono='user-check' label='Aux Administrativo' />
                        </div>
                        <div className="col">
                            <CampoDeTexto id='cmp8' tipo='number' valor={this.state.tel_aux} writhing={this.handleChange} icono='phone' label='Teléfono Aux adm' />
                        </div>

                        <div className="col">
                            <Form className='py-2 mx-3'>
                                <label htmlFor="nombre" className='row'>Permitir a la portería visualizar los números telefónicos?</label>
                                <Form.Check
                                    type="switch"
                                    onChange={(e) => this.setState({ permiteporteria: e.target.checked })}
                                    label={this.state.permiteporteria === false ? ("No") : ("Si")}
                                    checked={this.state.permiteporteria}
                                />
                            </Form>
                        </div>

                        <div className="col-12">
                            <HorasAtencion Indice={0} eliminar={false} accion={this.agregarHorario} desdeDate={null} hastaDate={null} Dias={'Lunes a Viernes'} />
                        </div>
                        {(this.state.horarios.length > 0) ? (this.state.horarios.map((horario, indice) => (
                            <div className="col-12">
                                <HorasAtencion titulo='d-none' eliminar={true} dias={horario.dias} Dias={horario.dias}
                                    desdeDate={horario.desdeDate} hastaDate={horario.hastaDate} accion={this.eliminarHorario} Indice={indice} />
                            </div>
                        ))) : ("")
                        }
                        <div className="col-12">
                            <CampoDeTexto id='cmp9' valor={this.state.wtsapp} writhing={this.handleChange} icono='whatsapp' tipo='number' label='WhatsApp' />
                        </div>
                        <div className="col-12">
                            <SeccionBanka
                                index={0}
                                eliminar={false}
                                bancos={"BANCAMIA S.A."}
                                tipo_c={'Ahorros'}
                                n_cuenta={''}
                                detalle={''}
                                link={''}
                                accion={this.agregarCuenta}
                            />
                        </div>
                        {(this.state.cuentas.length > 0) ? (this.state.cuentas.map((bank, indice) => (
                            <div className="col-12">
                                <SeccionBanka
                                    index={indice}
                                    eliminar={true}
                                    bancos={bank.banco}
                                    tipo_c={bank.tipo}
                                    n_cuenta={bank.numero}
                                    detalle={bank.detalle}
                                    link={bank.link}
                                    accion={this.eliminarCuenta} />
                            </div>
                        ))) : ("")}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <button className="btn btn-primary" onClick={this.Guardar}>Guardar Cambios</button>
                </Modal.Footer>
            </div>
        )
    }
}
