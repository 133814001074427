import { CsvBuilder } from 'filefy';
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import Global from "../../../Global";

import logo from "../../../assets/images/logo.png";

//--------------------------------------
import { ProgressBar } from 'react-bootstrap'
import moment from 'moment';

export const varT1 = {
    hdd: null
}

export const vPDf = {

    dAsistencia: {
        columns: [
            {
                field: 'nombres',
                title: 'Nombres',
            },
            {
                field: 'direccion',
                title: 'Dirección',
            },
            {
                field: 'calasistencia',
                title: 'Calidad Asistencia',
            },
            {
                field: 'coeficiente',
                title: 'Coeficiente',
            },
            {
                field: 'fecha',
                title: 'Fecha',
                hidden: true,
                export: true,
            },
            {
                field: 'origen',
                title: 'Origen',
                hidden: true,
                export: true,
            },
        ],
        data: [],
    },

    dPoderes: {
        columns: [
            {
                field: 'Apoderado',
                title: 'Representante',
            },
            {
                field: 'DireccionApoderado',
                title: 'Dirección',
            },
            {
                field: 'Poderdante',
                title: 'Representado',
            },
            {
                field: 'DireccionPoderdante',
                title: 'Dirección',
            },
        ],
        data: []
    },

    dPreguntas: {
        columns: [

            {
                field: 'Item',
                title: 'Item',
                width: '10px',
                cellStyle: { paddingX: '5px' }
            },
            {
                field: 'preguntaid',
                title: 'PreguntaId',
                hidden: true,
            },
            {
                field: 'contenido',
                title: 'Contenido Pregunta',
            },
            {
                field: 'tipo',
                title: 'Tipo',
            },
            {
                field: 'motivocierre',
                title: 'Motivo Cierre Id',
                hidden: true,
            },
            {
                field: 'votoconsejo',
                title: 'Voto Consejo',
                hidden: true,
            },
            {
                field: 'detallecierre',
                title: 'Motivo Cierre',
            },

        ],
        data: [],
        Nitem: [],
    },

    dRespuestas: [],

    dAusentes: {
        columns: [
            {
                field: 'nombres',
                title: 'Nombres',
            },
            {
                field: 'direccion',
                title: 'Dirección',
            },
            {
                field: 'calasistencia',
                title: 'Calidad Asistencia',
            },
            {
                field: 'coeficiente',
                title: 'Coeficiente',
            },
        ],
        data: []
    },

    dAbstinentes: [
        {
          field: "nombres",
          title: "Nombres",
          minWidth: "15%",
          maxWidth: "20%",
          width: "15%",
        },
        {
          field: "direccion",
          title: (Global.Asamblea.NameInm),
        },
        {
          field: "coeficiente",
          title: "Coeficiente",
          minWidth: "10%",
          maxWidth: "15%",
          width: "10%",
          hidden: (Global.Asamblea.Col_coe_prg)
        },
    ],

}

const FdataPdf = {

    Asistencias: () => {

        let body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            asambleaid: Buffer.from(Global.InfoAsamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/infoasistentes/`, body).then((res) => {
            if (res.data.message === "") {
                let temp = []
                res.data.Asistentes.forEach(Asistente => {

                    let Origen = '';
                    switch (Asistente.origen) {
                        case 'A':
                            Origen = 'Asistente'
                            break
                        case 'W':
                            Origen = 'Web'
                            break
                        case 'M':
                            Origen = 'App'
                            break
                        default:
                            break
                    }

                    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: "America/Bogota" };
                    let fecha = new Date(Asistente.fechahora)

                    let asistente = {
                        nombres: Asistente.nombres.toUpperCase(),
                        direccion: Asistente.direccion,
                        calasistencia: Asistente.expr1,
                        coeficiente: Asistente.coeficiente,
                        fecha: `${fecha.toLocaleDateString("es-ES", options)} ${fecha.toLocaleTimeString("en-US")}`,
                        origen: Origen,
                    }
                    temp.push(asistente)
                });
                return (vPDf.dAsistencia.data = temp, console.log('Los Datos de Asistencia an cargado'))
            } else {
                console.log("Error en el backend 567.2: " + res.data.message)
            }
        }).catch((err) => {
            console.log("Error en la petición 564.2: " + err.message)
        })
    },

    poderes: () => {
        let body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            asambleaid: Buffer.from(Global.InfoAsamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/infopoderes/`, body).then((res) => {
            if (res.data.message === "") {
                return (vPDf.dPoderes.data = res.data.Poderes, console.log('Los Datos de los poderes han cargado'))
            } else {
                console.log("Error en el backend 567.1: " + res.data.message)
            }
        }).catch((err) => {
            console.log("Error en la petición 564.1: " + err.message)
        })

    },

    preguntas: () => {
        let body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            asambleaid: Buffer.from(Global.InfoAsamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/infopreguntas/`, body).then((res) => {
            if (res.data.message === "") {
                vPDf.dPreguntas.data = res.data.Preguntas

                vPDf.dPreguntas.Nitem = res.data.Preguntas.Item

                Preguntas(res.data.Preguntas)

                //return (vPDf.dPreguntas.data = res.data.Preguntas, vPDf.dPreguntas.Nitem = res.data.Preguntas.Item)
                return
            } else {
                console.log("Error en el backend 567: " + res.data.message)
            }
        }).catch((err) => {
            console.log("Error en la petición 564: " + err.message)
        })
    },

    Ausentes: () => {
        let body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            asambleaid: Buffer.from(Global.InfoAsamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/infoausentes/`, body).then((res) => {
            if (res.data.message === "") {

                this.props.TotalAusentes(res.data.TotalAusentes)

                let temp = []

                res.data.Ausentes.forEach(Ausente => {

                    let ausente = {
                        nombres: Ausente.nombres.toUpperCase(),
                        direccion: Ausente.direccion,
                        calasistencia: Ausente.expr1,
                        coeficiente: Ausente.coeficiente,

                    }
                    temp.push(ausente)
                });
                return (vPDf.dAusentes.data = temp)
            } else {
                console.log("Error en el backend 5.0: " + res.data.message)
            }
        }).catch((err) => {
            console.log("Error al enviar la petición")
        })
    },

    ResetVar: () => {
        vPDf.dAsistencia.data = []
        vPDf.dPoderes.data = []
        vPDf.dPreguntas.data = []
        vPDf.dRespuestas = []
        vPDf.dAusentes.data = []

        return
    }

}
//------------------------------------------------------------------------------------

var TotalAsistentes = 0;

const columnsn = [
    {
        title: 'Opción',
        field: 'opcion'
    },
    {
        title: 'Coef. o Indiv.',
        field: 'cOi',
        hidden: Global.Asamblea.Col_coe_prg
    },
    {
        title: 'Votos',
        field: 'votos',
        type: 'numeric',
    },
    {
        title: 'Total',
        field: 'total',
    },
    {
        title: 'Infografia',
        field: 'dato',
        render: rowData => <ProgressBar className="w-75 mr-2" now={rowData.dato} />
    },
]
const columnsC = [
    {
        title: 'Item',
        field: 'item'
    },
    {
        title: 'Opción',
        field: 'opcion'
    },
    {
        title: 'Votos',
        field: 'votos',
        type: 'numeric',
    },
    {
        title: 'Infografia',
        field: 'dato',
        render: rowData => <ProgressBar className="w-75 mr-2" now={rowData.dato} max={TotalAsistentes} />
    },
]

const columns = [
    {
        field: "nombre",
        title: "Nombres",
        minWidth: "15%",
        maxWidth: "20%",
        width: "15%",
    },
    {
        field: "coe",
        title: "Coeficiente",
        minWidth: "10%",
        maxWidth: "15%",
        width: "10%",
        hidden: Global.Asamblea.Col_coe_prg
    },
    {
        field: "direccion",
        title: (Global.Asamblea.NameInm),
    },
    {
        field: "fecha",
        title: "Fecha/Hora",
    },
    {
        field: "opcion",
        title: "Opción",
        hidden: true,
        export: true,
    },
    {
        field: "origen",
        title: "Origen",
        minWidth: "10%",
        maxWidth: "15%",
        width: "10%",
    },
];


const Preguntas = async (Preguntas) => {

    for (let i = 0; i < Preguntas.length; i++) {

        if (parseInt(Preguntas[i]['motivocierre']) === 3) {

            var body = {
                codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
                ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
                preguntaid: Buffer.from("" + Preguntas[i]['preguntaid']).toString('base64'),
                votoconsejo: Buffer.from("" + Preguntas[i]['votoconsejo']).toString('base64'),
            }

            await axios.post(`${Global.Ruta.Url}adminasamblea/resultpregunta`, body)
                .then(res => {

                    if (res.data.message === '') {

                        var Data = []


                        if (res.data.OpcionesVotadas !== undefined) {
                            res.data.OpcionesVotadas.forEach(DataOpc => {
                                var Opcion = {
                                    opcion: DataOpc.Opcion,
                                    cOi: DataOpc.Coeficiente + "%",
                                    votos: DataOpc.Votos,
                                    total: DataOpc.TotalPorcentaje + "%",
                                    dato: DataOpc.TotalPorcentaje,
                                }
                                Data.push(Opcion)
                            });
                        } else {

                            if (res.data.OpcionesRespuestas !== undefined) {
                                res.data.OpcionesRespuestas.forEach(DataOpc => {
                                    var Opcion = {
                                        item: DataOpc.item,
                                        opcion: DataOpc.Opcion,
                                        votos: DataOpc.TotalVotos,
                                        dato: DataOpc.TotalVotos,
                                    }
                                    Data.push(Opcion)
                                });
                            }
                        }

                        ResultPreguntaVotantes(Preguntas[i]['preguntaid'], parseFloat(res.data.QuorumActual).toFixed(2), Preguntas[i]['votoconsejo'],
                            Preguntas[i]['contenido'], Data, res.data.Asistencia)

                    } else {
                        console.log(`Error al traer la info de una pregunta: ${res.data.message}`)
                    }
                }).catch(err => {
                    console.log(`Error en componentWillMount 2.0: ${err.message}`)
                });
        }
    }
}

const ResultPreguntaVotantes = async (preguntaId, Qactual, votoConsejo, contPrgnt, dataGlobal, TotalAsistentes) => {

    var body = {
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        preguntaid: Buffer.from("" + preguntaId).toString("base64"),
        votoconsejo: Buffer.from("" + votoConsejo).toString("base64"),
    };

    await axios.post(`${Global.Ruta.Url}adminasamblea/listavotantes`, body).then((res) => {

        if (res.data.message === "") {
            var Data = [];

            res.data.ListaVotantes.forEach((Votantes) => {
                var Fecha = moment(Votantes.Fecha).format("LLLL");
                var Votante = {
                    nombre: Votantes.Nombres.toUpperCase(),
                    coe: Votantes.Coeficiente + "%",
                    direccion: Votantes.Direccion,
                    fecha: Fecha,
                    opcion: Votantes.Opcion,
                    origen: Votantes.Medio,
                };
                Data.push(Votante);
            });

            var DataResp = {}

            if (parseInt(votoConsejo) === 0) {
                DataResp = {
                    DataPregunta: dataGlobal,
                    Data: Data,
                    Qactual: Qactual,
                    contPrgnt: contPrgnt,
                    votoConsejo: votoConsejo,
                    columnsp: columnsn,
                    columnsv: columns
                }
            } else {
                DataResp = {
                    DataPregunta: dataGlobal,
                    Data: Data,
                    Qactual: Qactual,
                    contPrgnt: contPrgnt,
                    votoConsejo: votoConsejo,
                    columnsp: columnsC,
                    columnsv: columns
                }
            }
            vPDf.dRespuestas.push(DataResp)
            return (TotalAsistentes)
        } else {
            console.log(`Error en el backend 3.0: ${res.data.message}`)
        }
    }).catch((err) => {
        console.log(`Error en componentWillMount 3.0: ${err.message}`)
    });
}

////---------------------------------------------------------------------------------------

export const PdfGlobal = {

    pdf1: (columns = vPDf.dAsistencia.columns, data = vPDf.dAsistencia.data) => {

        const doc = new jsPDF("portrait");
        doc.addImage(logo, "JPEG", 95, 15, 25, 25);
        doc.setFontSize(15);
        doc.text(Global.Ph.Nombre, 105, 48, null, null, "center")
        doc.setTextColor("DodgerBlue")
        doc.text(`del ${Global.InfoAsamblea.Fecha}.`, 105, 60, null, null, "center");
        doc.text(`Asistentes de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}`, 105, 54, null, null, "center");

        const columnTitles = []
        for (var i = 0; i < columns.length; i++) {
            if (i !== 4) {
                columnTitles.push(columns[i].title)
            }
        }

        const pdfData = []
        data.forEach(IndividualSusc => {
            var Data = []
            Data[0] = IndividualSusc.nombres
            Data[1] = IndividualSusc.direccion
            Data[2] = IndividualSusc.calasistencia
            Data[3] = IndividualSusc.coeficiente
            Data[4] = IndividualSusc.origen

            pdfData.push(Data)
        });

        doc.autoTable({
            startY: 68,
            theme: 'grid',
            rowPageBreak: 'avoid',
            styles: { cellWidth: 'auto', halign: 'center', valign: 'middle', },
            columnStyles: { 2: { cellWidth: 50 } },
            bodyStyles: { halign: 'left', cellPadding: 3 },
            head: [columnTitles],
            body: pdfData,
        });

        const cuentaPaginas = doc.internal.getNumberOfPages();
        for (let i = 1; i <= cuentaPaginas; i++) {
            doc.setPage(i);
            doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 26, 'S');
            doc.setFontSize(12)
            doc.setTextColor("DarkGray")
            doc.text(`Pagina ${String(i)} de ${String(cuentaPaginas)}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, "right")
            doc.setFontSize(10)
            doc.text(`PDF hecho desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 10, null, null, "left")
        }

        doc.save(`Asistentes de la asamblea del ${Global.InfoAsamblea.Fecha}.pdf`);

    },
    pdf2: (columns = vPDf.dPoderes.columns, data = vPDf.dPoderes.data) => {
        const doc = new jsPDF("portrait");
        doc.addImage(logo, "JPEG", 95, 15, 25, 25);
        doc.setFontSize(15);
        doc.text(Global.Ph.Nombre, 105, 48, null, null, "center")
        doc.setTextColor("DodgerBlue")
        doc.text(`del ${Global.InfoAsamblea.Fecha}.`, 105, 60, null, null, "center");
        doc.text(`Representaciones de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}`, 105, 54, null, null, "center");


        const columnTitles = []
        for (var i = 0; i < columns.length; i++) {
            columnTitles.push(columns[i].title)
        }

        const pdfData = []
        data.forEach(IndividualSusc => {
            var Data = []
            Data[0] = IndividualSusc.Apoderado
            Data[1] = IndividualSusc.DireccionApoderado
            Data[2] = IndividualSusc.Poderdante
            Data[3] = IndividualSusc.DireccionPoderdante

            pdfData.push(Data)
        });

        doc.autoTable({
            startY: 68,
            theme: 'grid',
            rowPageBreak: 'avoid',
            styles: { cellWidth: 'auto', halign: 'center', valign: 'middle', },
            columnStyles: { 2: { cellWidth: 50 } },
            bodyStyles: { halign: 'left', cellPadding: 3 },
            head: [columnTitles],
            body: pdfData,
        });

        const cuentaPaginas = doc.internal.getNumberOfPages();
        for (let i = 1; i <= cuentaPaginas; i++) {
            doc.setPage(i);
            doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 26, 'S');
            doc.setFontSize(12)
            doc.setTextColor("DarkGray")
            doc.text(`Pagina ${String(i)} de ${String(cuentaPaginas)}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, "right")
            doc.setFontSize(10)
            doc.text(`PDF hecho desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 10, null, null, "left")
        }

        doc.save(`Representaciones de la asamblea del ${Global.InfoAsamblea.Fecha}`);
    },
    pdf3: (columns = vPDf.dPreguntas.columns, data = vPDf.dPreguntas.data) => {
        const doc = new jsPDF("portrait");
        doc.addImage(logo, "JPEG", 95, 15, 25, 25);
        doc.setFontSize(15);
        doc.text(Global.Ph.Nombre, 105, 48, null, null, "center")
        doc.setTextColor("DodgerBlue")
        doc.text(`del ${Global.InfoAsamblea.Fecha}.`, 105, 60, null, null, "center");
        doc.text(`Preguntas de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}`, 105, 54, null, null, "center");

        const columnTitles = []
        for (var i = 0; i < columns.length; i++) {
            if (i !== 1 && i !== 4 && i !== 5) {
                columnTitles.push(columns[i].title)
            }
        }

        const pdfData = []
        data.forEach(IndividualSusc => {
            var Data = []
            Data[0] = IndividualSusc.Item
            Data[1] = IndividualSusc.contenido
            Data[2] = IndividualSusc.tipo
            Data[3] = IndividualSusc.detallecierre

            pdfData.push(Data)
        });

        doc.autoTable({
            startY: 68,
            theme: 'grid',
            rowPageBreak: 'avoid',
            styles: { cellWidth: 'auto', halign: 'center', valign: 'middle', },
            columnStyles: { 2: { cellWidth: 50 } },
            bodyStyles: { halign: 'left', cellPadding: 3 },
            head: [columnTitles],
            body: pdfData,
        });

        const cuentaPaginas = doc.internal.getNumberOfPages();
        for (let i = 1; i <= cuentaPaginas; i++) {
            doc.setPage(i);
            doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 26, 'S');
            doc.setFontSize(12)
            doc.setTextColor("DarkGray")
            doc.text(`Pagina ${String(i)} de ${String(cuentaPaginas)}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, "right")
            doc.setFontSize(10)
            doc.text(`PDF hecho desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 10, null, null, "left")
        }

        doc.save(`Lista de Preguntas de la Asamblea del ${Global.InfoAsamblea.Fecha}.pdf`);
    },
    pdf4: () => {
        for (var k = 0; k < vPDf.dRespuestas.length; k++) {
            const doc = new jsPDF("portrait");
            doc.addImage(logo, "JPEG", 70, 15, 25, 25);
            if (Global.Ph.Logo !== undefined) {
                doc.addImage(Global.Ph.Logo, "JPEG", 110, 15, 25, 25);
            }
            doc.setFontSize(15);
            doc.text(
                Global.Ph.Nombre,
                105,
                48,
                null,
                null,
                "center"
            );
            doc.text(`Asamblea del ${Global.InfoAsamblea.Fecha}.`, 105, 54, null, null, "center");
            doc.setFontSize(13);
            doc.text(
                `Estado de la pregunta: Válida y Votada`,
                105,
                64,
                null,
                null,
                "center"
            );
            doc.text(
                `Coef. o Indiv. Habilitado: ${vPDf.dRespuestas[k].Qactual}%`,
                105,
                70,
                null,
                null,
                "center"
            );
            doc.text(
                `Contenido de la Pregunta: `,
                105,
                75,
                null,
                null,
                "center"
            );
            doc.setDrawColor(0);
            doc.setFillColor(220, 220, 220);
            doc.roundedRect(14, 80, 181, 35, 2, 2, "F");
            let texto = `¿${vPDf.dRespuestas[k].contPrgnt}?`;
            let lines = doc.splitTextToSize(texto, 210 - 16 - 16);
            doc.text(15, 86, lines,);

            const columnTitles = [];
            for (var i = 0; i < vPDf.dRespuestas[k].columnsv.length; i++) {

                columnTitles.push(vPDf.dRespuestas[k].columnsv[i].title);

            }

            let columns2 = vPDf.dRespuestas[k].columnsp;

            const columnTitles2 = [];
            for (let i = 0; i < columns2.length; i++) {
                if (i !== 3) {
                    columnTitles2.push(columns2[i].title);
                }
            }

            const pdfData = [];
            vPDf.dRespuestas[k].Data.forEach((IndividualSusc) => {
                var Data = [];
                Data[0] = IndividualSusc.nombre;
                Data[1] = IndividualSusc.coe;
                Data[2] = IndividualSusc.direccion;
                Data[3] = IndividualSusc.fecha;
                Data[4] = IndividualSusc.opcion;
                Data[5] = IndividualSusc.origen;


                pdfData.push(Data);
            });

            let data2 = vPDf.dRespuestas[k].DataPregunta;

            const pdfDAta2 = [];
            if (parseInt(vPDf.dRespuestas[k].votoConsejo) === 1) {
                data2.forEach((DatosIndv, i) => {
                    let Data = [];
                    Data[0] = i + 1;
                    Data[1] = DatosIndv.opcion;
                    Data[2] = DatosIndv.votos;

                    pdfDAta2.push(Data);
                });
            } else {
                data2.forEach((DatosIndv) => {
                    let Data = [];
                    Data[0] = DatosIndv.opcion;
                    Data[1] = parseFloat(DatosIndv.cOi).toFixed(2);
                    Data[2] = DatosIndv.votos;
                    Data[3] = DatosIndv.total;

                    pdfDAta2.push(Data);
                });
            }

            doc.autoTable({
                startY: 120,
                theme: "grid",
                rowPageBreak: "avoid",
                styles: {
                    cellWidth: "auto",
                    halign: "center",
                    valign: "middle",
                },
                columnStyles: { 2: { cellWidth: 50 } },
                bodyStyles: { halign: "left", cellPadding: 3 },
                head: [columnTitles2],
                body: pdfDAta2,
            });

            doc.addPage();
            doc.setFontSize(17);
            doc.text(
                "Votaciones Individuales",
                105,
                25,
                null,
                null,
                "center"
            );

            doc.autoTable({
                startY: 37,
                theme: "grid",
                rowPageBreak: "avoid",
                styles: {
                    cellWidth: "auto",
                    halign: "center",
                    valign: "middle",
                },
                columnStyles: { 2: { cellWidth: 50 } },
                bodyStyles: { halign: "left", cellPadding: 3 },
                head: [columnTitles],
                body: pdfData,
            });

            const cuentaPaginas = doc.internal.getNumberOfPages();
            for (let i = 1; i <= cuentaPaginas; i++) {
                doc.setPage(i);
                doc.rect(
                    10,
                    10,
                    doc.internal.pageSize.width - 20,
                    doc.internal.pageSize.height - 26,
                    "S"
                );
                doc.setFontSize(12);
                doc.setTextColor("DarkGray");
                doc.text(
                    `Pagina ${String(i)} de ${String(cuentaPaginas)}`,
                    doc.internal.pageSize.width - 20,
                    doc.internal.pageSize.height - 10,
                    null,
                    null,
                    "right"
                );
                doc.setFontSize(10);
                doc.text(
                    `Informe realizado desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`,
                    doc.internal.pageSize.width - 200,
                    doc.internal.pageSize.height - 10,
                    null,
                    null,
                    "left"
                );
            }

            doc.save(
                `Votantes de la Asamblea del ${Global.InfoAsamblea.Fecha}.pdf`
            );
        }
    },
    pdf5: (columns = vPDf.dAusentes.columns, data = vPDf.dAusentes.data) => {
        const doc = new jsPDF("portrait");
        doc.addImage(logo, "JPEG", 95, 15, 25, 25);
        doc.setFontSize(15);
        doc.text(Global.Ph.Nombre, 105, 48, null, null, "center")
        doc.setTextColor("DodgerBlue")
        doc.text(`del ${Global.InfoAsamblea.Fecha}.`, 105, 60, null, null, "center");
        doc.text(`Ausentes de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}`, 105, 54, null, null, "center");

        const columnTitles = []
        for (var i = 0; i < columns.length; i++) {
            columnTitles.push(columns[i].title)
        }

        const pdfData = []
        data.forEach(IndividualSusc => {
            var Data = []
            Data[0] = IndividualSusc.nombres
            Data[1] = IndividualSusc.direccion
            Data[2] = IndividualSusc.calasistencia
            Data[3] = IndividualSusc.coeficiente

            pdfData.push(Data)
        });

        doc.autoTable({
            startY: 68,
            theme: 'grid',
            rowPageBreak: 'avoid',
            styles: { cellWidth: 'auto', halign: 'center', valign: 'middle', },
            columnStyles: { 2: { cellWidth: 50 } },
            bodyStyles: { halign: 'left', cellPadding: 3 },
            head: [columnTitles],
            body: pdfData,
        });

        const cuentaPaginas = doc.internal.getNumberOfPages();
        for (let i = 1; i <= cuentaPaginas; i++) {
            doc.setPage(i);
            doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 26, 'S');
            doc.setFontSize(12)
            doc.setTextColor("DarkGray")
            doc.text(`Pagina ${String(i)} de ${String(cuentaPaginas)}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, "right")
            doc.setFontSize(10)
            doc.text(`PDF hecho desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 10, null, null, "left")
        }

        doc.save(`Ausentes de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`);
    }
}

export const CsvGlobal = {
    csv1: (data = vPDf.dAsistencia.data, columns = vPDf.dAsistencia.columns) => { 
        const columnTitles = columns
        .map(columnDef => columnDef.title);
    
        const csvData = data.map(rowData =>
            columns.map(columnDef => rowData[columnDef.field]),
            );
  
        const builder = new CsvBuilder(`Asistentes de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`)
            .setDelimeter(';')
            .setColumns(columnTitles)
            .addRows(csvData)
            .exportFile();
  
    return builder;
    },
    csv2: (data = vPDf.dPoderes.data, columns = vPDf.dPoderes.columns) => { 
        const columnTitles = columns
        .map(columnDef => columnDef.title);
    
        const csvData = data.map(rowData =>
            columns.map(columnDef => rowData[columnDef.field]),
            );
  
        const builder = new CsvBuilder(`Representaciones de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`)
            .setDelimeter(';')
            .setColumns(columnTitles)
            .addRows(csvData)
            .exportFile();
  
    return builder;
    },
    csv3: (data = vPDf.dPreguntas.data, columns = vPDf.dPreguntas.columns) => { 
        const columnTitles = columns
        .map(columnDef => columnDef.title);
    
        const csvData = data.map(rowData =>
            columns.map(columnDef => rowData[columnDef.field]),
            );
  
        const builder = new CsvBuilder(`Listado de Preguntas de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`)
            .setDelimeter(';')
            .setColumns(columnTitles)
            .addRows(csvData)
            .exportFile();
  
    return builder;
    },
    csv4: (data = vPDf.dAusentes.data, columns = vPDf.dAusentes.columns) => { 
        const columnTitles = columns
        .map(columnDef => columnDef.title);
    
        const csvData = data.map(rowData =>
            columns.map(columnDef => rowData[columnDef.field]),
            );
  
        const builder = new CsvBuilder(`Ausentes de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`)
            .setDelimeter(';')
            .setColumns(columnTitles)
            .addRows(csvData)
            .exportFile();
  
    return builder;
    },
}


export default FdataPdf
