
import MaterialTable from 'material-table';
import moment from 'moment';
import "moment/locale/es";
import React, { Component } from 'react'
import Global from './../../../../Global';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";

export default class Rcartera extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableHeads: [
        {
          field: "direccion",
          title: "Dirección",
        },
        {
          field: "cdpago",
          title: "Tercero",
        },
        {
          field: "valor1",
          title: "Valor 1",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor1),
        },
        {
          field: "valor2",
          title: "Valor 2",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor2),
        },
        {
          field: "valor3",
          title: "Valor 3",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor3),
        },
        {
          field: "valor4",
          title: "Valor 4",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor4),
        },
        {
          field: "valor5",
          title: "Valor 5",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor5),
        },
        {
          field: "valor6",
          title: "Valor 6",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor6),
        },
        {
          field: "valor7",
          title: "Valor 7",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor7),
        },
        {
          field: "valor8",
          title: "Valor 8",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor8),
        },
        {
          field: "valor9",
          title: "Valor 9",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor9),
        },
        {
          field: "valor10",
          title: "Valor 10",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.valor10),
        },
        {
          field: "anticipo",
          title: "Anticipo",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.anticipo),
        },
        {
          field: "cuota_admon",
          title: "Cuota Admin",
          render: (data) =>
            new Intl.NumberFormat("en-US", {}).format(data.cuota_admon),
        },
        {
          field: "fecha",
          title: "Fecha",
          render: (data) => moment(data.fecha).format("DD MMM YYYY"),
        },
      ],
      tableHeads2: [
        {
          field: "fecha",
          title: "Fecha",
          render: (data) => moment(data.fecha).format("DD MMM YYYY HH:MM:ss"),
        },
      ],
      data: [],
      datatable: false,
      FileMora: undefined,
      Registros: undefined,
      Modal1: false
    }
  }

  componentWillMount() {
    this.BuscarCartera()
  }

  BuscarCartera = () => {
    this.setState({
      PpHourglass: true,
    });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };
    axios.post(`${Global.Ruta.Url}cartera/selectcr`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          data: res.data.Data,
          FileMora: res.data.FileMora.file,
          Registros: res.data.Registros,
          PpHourglass: false,
          datatable: true
        });
      } else {
        this.setState({
          PpHourglass: false,
          datatable: true
        });
      }
    })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  downLoad = () => {
    const link = document.createElement('a');
    link.href = this.state.FileMora;
    link.download = `cartera.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const Tabla = (props) => {
      return (
        <MaterialTable
          title='Cartera'
          columns={this.state.tableHeads}
          data={this.state.data}
          options={{
            rowStyle: { fontSize: "0.7rem" },
            pageSize: 10,
            actionsColumnIndex: -1,
            exportButton: false,
            exportAllData: false,
          }}
          localization={{
            header: {
              actions: "Estado",
            },
            body: {
              emptyDataSourceMessage: "No hay datos por mostrar.",
            },
            pagination: {
              labelRowsSelect: "registros",
              labelDisplayedRows: "{from}-{to} de {count}",
              firstTooltip: "Primera página",
              previousTooltip: "Página anterior",
              nextTooltip: "Próxima página",
              lastTooltip: "Última página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
              exportCSVName: "CSV",
              exportPDFName: "PDF"
            },
          }}
        />
      )
    }

    return (
      <div className='row bg-white shadow rounded-3'>
        <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>
        <Modal show={this.state.Modal1} centered onHide={() => this.setState({ Modal1: false })}>
          <Modal.Header closeButton>
            Historial de la Cartera
          </Modal.Header>
          <Modal.Body>
            {this.state.datatable ? (
              <MaterialTable
                title='Historial'
                columns={this.state.tableHeads2}
                data={this.state.Registros}
                options={{
                  rowStyle: { fontSize: "1rem" },
                  pageSize: this.state.Registros.length,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: false,
                }}
                localization={{
                  header: {
                    actions: "Estado",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                    exportCSVName: "CSV",
                    exportPDFName: "PDF"
                  },
                }}
              />) : ("")
            }
          </Modal.Body>
        </Modal>
        {/* menu de opciones, depende del tipo de reporte */}
        <div className='col-12'>
          <div className='row py-3 px-2'>

            <div className="col-auto">
              <button className="btn btn-success" onClick={this.downLoad}>
                Excel
              </button>
            </div>
            <div className="col-auto">
              <button className="btn btn-info" onClick={() => { this.setState({ Modal1: true }) }}>
                Historial Carga
              </button>
            </div>
          </div>
        </div>
        {/* contenido del reporte */}
        <main className="col-12 px-0">
          {this.state.datatable ? (
            <Tabla />) : (
            <div className="d-flex justify-content-center bg-with text-info flex-column align-items-center min-vh-100">
              <div
                className="spinner-border fs-3 m-3"
                style={{ width: "5rem", height: "5rem" }}
                role="status"></div>
              <p className="fs-3">Cargando...</p>
            </div>
          )}
        </main>
      </div>
    )
  }
}
