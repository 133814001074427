import React, { Component } from "react";

import Global from "../../../../Global";

import axios from "axios";
import swal from "sweetalert";

class PpQactual extends Component {

    state = {
        Hora: undefined,
        QActualAsis: 0,
        QActualCoef: 0,
        intervalid: 0,
    }

    componentWillMount() {

        this.QActual()

        var idinterval2 = setInterval(this.intervale, 1000);

        this.setState({
            Hora: new Date().toLocaleTimeString(),
            idinterval2: idinterval2
        })
    }

    intervale = () => {
        this.setState({ Hora: new Date().toLocaleTimeString() })
    }

    componentWillUnmount() {
        clearInterval(this.state.idinterval2)
    }

    QActual = () => {
        axios.get(`${Global.Ruta.Url}adminasamblea/quorumactual/${Buffer.from(Global.Ph.Codigo).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString("base64")}/
        ${Buffer.from("" + Global.Asamblea.AsambleaId).toString("base64")}`)
            .then((res) => {
                if (res.data.message === "") {
                    if (parseInt(this.state.QActualAsis) < parseInt(res.data.Asistentes)) {
                        this.setState({
                            QActualAsis: parseInt(res.data.Asistentes),
                            QActualCoef: parseFloat(res.data.Coeficiente),
                        })
                    }
                } else {
                    swal({
                        title: "ALERTA!",
                        text: res.data.message,
                        icon: "error",
                        button: "Aceptar"
                    }).then(() => {
                        this.props.onClose()
                    });
                }
            })
            .catch((err) => {
                console.log(`Error en QActual: ${err.message}`)
                this.props.onClose()
            });
    }

    render() {

        return (
            <div className="row h-100 justify-content-center align-items-center px-0">
                <div className="col-sm-12 col-lg-4">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <span>Quorum</span><p>{this.state.Hora}</p>
                        </div>
                        <div className="card-body px-4 m-auto">
                            <h5 className="card-title"><i className="col-2 fas fa-users me-4"></i>{this.state.QActualAsis}</h5>
                            <h5 className="card-title"><i className="col-2 fas fa-percent me-4 px-1"></i>{parseFloat(this.state.QActualCoef).toFixed(2)}</h5>
                        </div>
                        <div className="card-footer">
                            <div className="row justify-content-center">
                                <button className="btn btn-primary col-5" onClick={this.props.onClose}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PpQactual