import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';

export default class DetallesSolicitud extends Component {
  render() {

    return (
      <div>
        <Modal.Header closeButton>
          Detalles de la petición
        </Modal.Header>
        <Modal.Body className='bg-secondary bg-opacity-75'>
          <div className="card">
            <div className="card-header bg-info bg-opacity-25">
              <h6 className="fw-normal text-muted">Nombre: <span className="text-dark px-2 fs-5 fw-bold">{this.props.Data.Nombre}</span></h6>
              <h6 className="fw-normal text-muted">Dirección: <span className="text-dark px-2 fs-5 fw-bold">{this.props.Data.Direccion}</span></h6>
              <hr className='my-2'/>
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal text-muted cover-marqss">Fecha de solicitud: <span className="text-dark fw-bold marqss overflow-hidden">{this.props.Data.Fecha_Solicitud}</span></h6>
                <h6 className="fw-normal text-muted cover-marqss">Fecha de respuesta: <span className="text-dark fw-bold marqss">{this.props.Data.Fecha_Respuesta}</span></h6>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal text-muted">Asunto: <span className="text-dark px-2 fs-5 fw-bold">{this.props.Data.Asunto}</span></h6>
                <h6 className="fw-normal text-muted">Radicado: <span className="text-dark px-2 fs-5 fw-bold">{this.props.Data.Radicado}</span></h6>
              </div>
              <h6 className="fw-normal text-muted mb-3">Estado: <span className={`text-success fw-bold px-2 fs-5`}>Contestada</span></h6>
              <div className='d-flex justify-content-start'>
                <div className="card w-75 p-3 my-1 shadow-sm">
                  <h6 className="fw-normal text-muted">Mensaje:</h6>
                  <p className="fw-normal text-dark " style={{fontSize:'16px'}}>{this.props.Data.Mensaje}</p>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <div className="card w-75 p-3 my-1 shadow-sm bg-info bg-opacity-25">
                  <h6 className="fw-normal text-muted">Respuesta:</h6>
                  <p className="fw-normal text-dark" style={{fontSize:'16px'}}>{this.props.Data.Respuesta}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    )
  }
}
