import axios from "axios";
import MaterialTable from "material-table";
import moment from "moment";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Global from "../../../../Global";
import PpHourglass from "../../Asamblea/PopUps/PpHourglass";
import ModalDispatchPD from "./ModalDispatchPD";
import { TextField } from "@material-ui/core";
import { removeDiacritics } from "../../../Helpers"

// TABLAS DE DESPACHO PORTERIA

// component class de tabla domicilios
export class DspachPendDeliv extends Component {
  state = {
    tab: "1",
    data: [],
    ParaBusqueda: [],
    loanding: false,
    PpHourglass: false,
    modal: false,
    modalH: false,
    modData: [],
    Cols: [],
    Busqueda: ""
  };

  componentWillMount() {
    this.Pendientes();
  }

  componentDidMount() {
    this.timerID = setInterval(() =>
      this.Pendientes(),
      60000
    );

    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  Pendientes = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/domiciliospendientes`, body).then((res) => {
      if (res.data.message === "") {
        var cols = [
          { field: "id_domicilio", title: "id_domicilio", hidden: true },
          { field: "id_inmueble", title: "id_inmueble", hidden: true },
          { field: "nombres", title: "Nombre" },
          { field: "direccion", title: "Dirección" },
          { field: "detalle", title: "Descripción Paquete" },
          {
            field: "fecha_crea",
            title: "Fecha de Petición",
            render: (data) =>
              moment(data.fecha_crea).format("DD MMM YYYY HH:mm:ss"),
          },
          {
            field: "despachado",
            title: "Ingreso",
            width: "15%",
            align: "center",
            render: (rowData) => (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    onClick={() => this.handler(rowData)}
                    className="btn btn-primary"
                  >
                    Llegó
                  </button>
                </div>
              </div>
            ),
          },
        ];
        this.setState({
          Cols: cols,
          data: res.data.data,
          ParaBusqueda: res.data.data,
          loanding: true,
        });
      } else {
        //this.setState({data: false});
      }
    })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  handler = (data) => {
    this.setState({ modal: true, modData: data });
  };

  Aprobar = (data) => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_domicilio: Buffer.from("" + data.id_domicilio).toString("base64"),
      id_portero: Buffer.from(Global.GlobalUser.userid).toString("base64"),
      id_inmueble: Buffer.from("" + data.id_inmueble).toString("base64"),
    };
    axios.post(`${Global.Ruta.Url}porteria/entregadomicilio`, body).then((res) => {
      if (res.data.message === "") {
        this.setState({
          PpHourglass: false,
        });
        swal({
          title: "",
          text: "Domicilio Entregado",
          icon: "success",
          button: "Aceptar",
          timer: `800`,
        }).then(() => this.Cerrar(data.id_domicilio));
      } else {
        this.setState({ PpHourglass: false });
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
        timer: `2000`,
      }).then(() => {
        this.setState({
          redirect_home: true,
          PpHourglass: false,
        });
      });
    });
  };

  Cerrar = (id_paquete) => {
    var tem = undefined
    var index = undefined
    if (this.props.caso === undefined) {
      this.setState({ modal: false, loanding: false });
      tem = this.state.data;
      index = this.IndexCambio(id_paquete, tem);

      tem.splice(index, 1);
      this.setState({
        data: tem,
      });

      setTimeout(() => {
        this.setState({
          loanding: true,
        });
      }, 150);
    } else {
      this.setState({ modal: false });
      tem = this.state.ParaBusqueda;
      index = this.IndexCambio(id_paquete, tem);

      tem.splice(index, 1);
      this.setState({
        data: tem,
        Busqueda: ""
      });
    }
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_domicilio) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  capitalFirtsLetter = (texto) => {
    var srt = texto
    return srt.charAt(0).toUpperCase() + srt.slice(1)
  }

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value
    }));
    this.setState({
      data: this.filterItems(event.target.value, this.state.ParaBusqueda)
    })
  }

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return (
        removeDiacritics(data.detalle).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1);
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.modal) {
      // Realiza la acción que desees cuando el usuario presione Enter
      this.Aprobar(this.state.modData)
    }
  }

  render() {
    const ModalD = () => {
      return (
        <Modal
          show={this.state.modal}
          onHide={() => this.setState({ modal: false })}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            {`${this.state.modData.direccion}`}
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <i
                  className="fa-solid fa-triangle-exclamation my-3 text-warning"
                  style={{ fontSize: "5rem" }}
                />
                <h5 className="fw-normal">
                  Esta seguro de aprobar el domicilio:
                  <br></br>
                  {`${this.state.modData.detalle}`}
                </h5>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => this.Aprobar(this.state.modData)}
            >
              Aprobar
            </button>
          </Modal.Footer>
        </Modal>
      );
    };

    if (this.props.caso !== undefined) {
      return (
        <React.Fragment>
          <Modal
            show={this.state.PpHourglass}
            centered
            backdrop="static"
            onHide={() => this.setState({ PpHourglass: false })}
          >
            <PpHourglass />
          </Modal>
          <ModalD />
          <div className="mx-2" style={{ height: "60px" }}>
            <TextField
              autoFocus
              size="small"
              fullWidth
              margin="normal"
              placeholder="Buscar domicilio"
              variant="outlined"
              defaultValue={this.state.Busqueda}
              value={this.state.Busqueda}
              onChange={this.handleChange("Busqueda")}
            />
          </div>
          <div className="w-100 h-100 position-relative align-items-start overflow-x-hidden custom-scroll">
            <div className="border border-light-subtle rounded bg-light mx-2 mb-3 d-flex flex-column position-relative">
              <div className="h-100 position-relative align-items-start" style={{ maxHeight: "89%" }}>
                <div className="h-100" style={{ padding: "0px 0px 0px 5px" }}>
                  {this.state.data.map((data, index) => (
                    <div className="row border  border-secondary rounded bg-white my-2" key={index} style={{ marginInline: "0px", width: "95%", cursor: "pointer" }} onClick={() => this.handler(data)}>
                      <div className="col" style={{ minWidth: "cal(100%-45px)" }}>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>{data.direccion}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{this.capitalFirtsLetter(data.detalle.toLowerCase())}</span>
                      </div>
                      <div className=" d-flex align-items-center justify-content-center" style={{ maxWidth: "45px" }}>
                        <button className="btn btn-primary rounded-circle btn-sm" onClick={() => this.handler(data)}>
                          <i className="fa-solid fa-check" />
                        </button>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
    if (this.props.caso === undefined) {
      return (
        <div className="row h-100 pt-3">
          <Modal
            show={this.state.PpHourglass}
            centered
            backdrop="static"
            onHide={() => this.setState({ PpHourglass: false })}
          >
            <PpHourglass />
          </Modal>
          <ModalD />
          <div className="col-12">
            {this.state.loanding ? (
              <MaterialTable
                title={false}
                columns={this.state.Cols}
                data={this.state.data}
                options={{
                  pageSize:
                    this.state.data.length < 20 ? this.state.data.length : 20,
                  pageSizeOptions: [
                    this.state.data.length < 20 ? this.state.data.length : 20,
                  ],
                  actionsColumnIndex: -1,
                  padding: "dense",
                  rowStyle: (rowData) => {
                    if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                      return {
                        backgroundColor: "#eeeeee",
                        fontSize: "0.9rem",
                        textTransform: "uppercase",
                      };
                    }
                    return {
                      backgroundColor: "#ffffff",
                      fontSize: "0.9rem",
                      textTransform: "uppercase",
                    };
                  },
                }}
              />
            ) : (
              <div className="d-flex justify-content-center bg-light text-info flex-column align-items-center h-100">
                <div
                  className="spinner-border fs-3 m-3"
                  style={{ width: "5rem", height: "5rem" }}
                  role="status"
                ></div>
                <p className="fs-3">Cargando...</p>
              </div>
            )}
          </div>
        </div>
      )
    }
  }
}

// component class de tabla paquetes
export class DspachPendPaqu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      data: [],
      ParaBusqueda: [],
      modal: false,
      modalH: false,
      modData: [],
      loanding: false,
      Cols: [],
      Busqueda: ""
    };
  }

  componentWillMount() {
    this.Pendientes();
  }

  componentDidMount() {
    this.timerID = setInterval(() =>
      this.Pendientes(),
      60000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      // Llama a la función onDataUpdate pasando el nuevo estado "data"
      this.props.onDataUpdate(this.state.data.length);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  Pendientes = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios.post(`${Global.Ruta.Url}porteria/paquetespendientes`, body).then((res) => {
      if (res.data.message === "") {
        var cols = [
          { field: "id_paquete", title: "id_paquete", hidden: true },
          { field: "id_inmueble", title: "id_inmueble", hidden: true },
          { field: "nombre_portero", title: "nombre_portero", hidden: true },
          { field: "nombre", title: "Nombre" },
          { field: "direccion", title: "Dirección" },
          { field: "detalle", title: "Descripción Paquete" },
          {
            field: "fecha_llegada",
            title: "Fecha Llegada",
            render: (data) =>
              moment(data.fecha_llegada).format("DD MMM YYYY HH:mm:ss"),
          },
          {
            field: "despachado",
            title: "Entregar",
            width: "15%",
            align: "center",
            render: (rowData) => (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    onClick={() => this.handler(rowData)}
                    className="btn btn-primary"
                  >
                    Entregar
                  </button>
                </div>
              </div>
            ),
          },
        ];
        this.setState({
          Cols: cols,
          data: res.data.data,
          ParaBusqueda: res.data.data,
          loanding: true,
        });
      } else {
        //this.setState({data: false});
      }
    }).catch((err) => {
      swal({
        title: "ERROR!",
        text: "Por favor vuelva a intentarlo mas tarde.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({
          redirect_home: true,
        });
      });
    });
  };

  handler = (data) => {
    this.setState({ modal: true, modData: data });
  };

  Cerrar = (id_paquete) => {
    this.setState({ modal: false, loanding: false });
    var tem = this.state.data;
    var index = this.IndexCambio(id_paquete, tem);

    tem.splice(index, 1);
    this.setState({
      data: tem,
    });

    setTimeout(() => {
      this.setState({
        loanding: true,
      });
    }, 150);
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_paquete) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  capitalFirtsLetter = (texto) => {
    var srt = texto
    return srt.charAt(0).toUpperCase() + srt.slice(1)
  }

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value
    }));
    this.setState({
      data: this.filterItems(event.target.value, this.state.ParaBusqueda)
    })
  }

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return (data.detalle.toLowerCase().indexOf(query.toLowerCase()) > -1 || data.direccion.toLowerCase().indexOf(query.toLowerCase()) > -1
        || data.nombre.toLowerCase().indexOf(query.toLowerCase()) > -1);
    })
  }

  render() {
    const ModalD = () => {
      return (
        <Modal
          show={this.state.modal}
          centered
          backdrop={"static"}
          onHide={() => this.setState({ modal: false })}
        >
          <ModalDispatchPD
            infoDispatch={this.state.modData}
            cerrar={this.Cerrar}
          />
        </Modal>
      );
    };

    if (this.props.caso !== undefined) {
      return (
        <React.Fragment>
          <Modal
            show={this.state.PpHourglass}
            centered
            backdrop="static"
            onHide={() => this.setState({ PpHourglass: false })}
          >
            <PpHourglass />
          </Modal>
          <ModalD />
          <div className="mx-2 row" style={{ height: "60px" }}>
            <div className="col">
              <TextField
                autoFocus
                size="small"
                fullWidth
                margin="normal"
                placeholder="Buscar Paquete"
                variant="outlined"
                defaultValue={this.state.Busqueda}
                onChange={this.handleChange("Busqueda")}
              />
            </div>
            <div className="col-auto d-flex align-items-center justify-content-center">
              <button className="btn btn-success rounded-circle btn-sm" onClick={(e) => this.props.agregarPaquete(e)}>
                <i className="fa-solid fa-plus" />
              </button>
            </div>
          </div>
          <div className="w-100 h-100 position-relative align-items-start overflow-x-hidden custom-scroll">
            <div className="border border-light-subtle rounded bg-light mx-2 mb-3 d-flex flex-column position-relative">
              <div className="h-100 position-relative align-items-start" style={{ maxHeight: "89%" }}>
                <div className="h-100" style={{ padding: "0px 0px 0px 5px" }}>
                  {this.state.data.map((data, index) => (
                    <div className="row border  border-secondary rounded bg-white my-2" key={index} style={{ marginInline: "0px", width: "95%", cursor: "pointer" }} onClick={() => this.handler(data)}>
                      <div className="col" style={{ minWidth: "cal(100%-45px)" }}>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>{data.nombre}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{data.direccion}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{this.capitalFirtsLetter(data.detalle.toLowerCase())}</span>
                      </div>
                      <div className=" d-flex align-items-center justify-content-center" style={{ maxWidth: "45px" }}>
                        <button className="btn btn-primary rounded-circle btn-sm" onClick={() => this.handler(data)}>
                          <i className="fa-solid fa-check" />
                        </button>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }

    if (this.props.caso === undefined) {
      return (
        <div className="row h-100 pt-3">
          <Modal
            show={this.state.PpHourglass}
            centered
            backdrop="static"
            onHide={() => this.setState({ PpHourglass: false })}
          >
            <PpHourglass />
          </Modal>

          <ModalD />
          <div className="col-12">
            {this.state.loanding ? (
              <MaterialTable
                title={false}
                columns={this.state.Cols}
                data={this.state.data}
                options={{
                  pageSize:
                    this.state.data.length < 20 ? this.state.data.length : 20,
                  pageSizeOptions: [
                    this.state.data.length < 20 ? this.state.data.length : 20,
                  ],
                  actionsColumnIndex: -1,
                  padding: "dense",
                  rowStyle: (rowData) => {
                    if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                      return {
                        backgroundColor: "#eeeeee",
                        fontSize: "0.9rem",
                        textTransform: "uppercase",
                      };
                    }
                    return {
                      backgroundColor: "#ffffff",
                      fontSize: "0.9rem",
                      textTransform: "uppercase",
                    };
                  },
                }}
              />
            ) : (
              <div className="d-flex justify-content-center bg-light text-info flex-column align-items-center h-100">
                <div
                  className="spinner-border fs-3 m-3"
                  style={{ width: "5rem", height: "5rem" }}
                  role="status"
                ></div>
                <p className="fs-3">Cargando...</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

DspachPendPaqu.defaultProps = {
  onDataUpdate: () => {}, // Función vacía por defecto
  // Otros valores por defecto que puedas necesitar
};