import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Autocomplete } from '@mui/material';
import { TextField } from "@material-ui/core";
import HourGlass from "./../../../principal/HourGlass";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from 'simple-react-validator'

export default class CrearMsnpa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      HourGlass: false,
      id_portero: "",

      contenido: "",
    }
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'El campo es requerido.',
        email: 'Correo Invalido',
        alpha_num: 'No puede Contener Caracteres',
        numeric: 'solo puede ingresar numeros enteros',
        integer: 'solo puede ingresar numeros enteros',
        min: 'Debe Ingresar mínimo 5 caracteres',
        max: 'Puede Ingresar Máximo 10 caracteres',
      }
    });
  }

  GuardarMs = () => {
    if (this.validator.allValid()) {
      this.setState({ HourGlass: true })

      var body = {
        ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
        id_usuario: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        mensaje: Buffer.from("" + this.state.contenido).toString("base64"),
        id_portero_send: Buffer.from("" + this.state.id_portero).toString("base64")
      };
      axios.post(`${Global.Ruta.Url}smsp/newmensajesporteria`, body).then(res => {
        this.setState({ HourGlass: false })
        if (res.data.message === "") {
          var mensaje = {
            id_com: res.data.id_com,
            id_portero_send: this.state.id_portero,
            mensaje: this.state.contenido,
          }
          this.props.AñadirMensaje(mensaje)
        } else {
          swal({
            title: "Atención",
            text: `${res.data.message}`,
            icon: "warning",
            button: "Aceptar"
          }).then(() => {
            this.props.CerrarModal()
          })
        }
      }).catch(err => {
        swal({
          title: "ERROR!",
          text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar"
        }).then(() => {
          this.props.CerrarModal()
        })
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    if (!this.state.HourGlass) {
      return (
        <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" onHide={this.props.CerrarModal} backdrop="static" centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Crear Mensaje</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="col ">
              <Autocomplete
                disableClearable
                disablePortal
                noOptionsText="No Encontrado"
                size="small"
                options={this.props.Porteros}
                onChange={(event, newValue) => { this.setState({ id_portero: newValue.id }) }}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Selecciona destinatario" />}
              />
              <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message('Usuario ', this.state.id_portero, 'required', { className: 'text-danger' })}</label>

              <TextField
                value={this.state.contenido}
                multiline
                rows={5}
                fullWidth
                placeholder="Cuerpo del Mensaje"
                helperText={`${this.state.contenido.length} de 250 caracteres`}
                variant="outlined"
                margin="dense"
                onChange={(e) => this.setState({ contenido: e.target.value })}
                inputProps={{ maxLength: 250 }}
              />
              <label className='text-muted  m-0 px-1 txt-helper mx-2' style={{ fontSize: "11px" }}>{this.validator.message('contenido ', this.state.contenido, 'required', { className: 'text-danger' })}</label>
            </div>
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={() => this.GuardarMs()} size="sm"><i className="fas fa-save"></i> Guardar</Button>
          </Modal.Footer>
        </Modal>
      )
    }
    return (
      <HourGlass show={this.state.HourGlass}></HourGlass>
    )
  }
}
