import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import MaterialTable, { MTableToolbar } from "material-table";
import ModalDispatchPD from "./ModalDispatchPD";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import "moment/locale/es";
import ModalDispatchH from "./ModalDispatchH";
import { Typography, Grid, TextField, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Es from "date-fns/locale/es";

export default class DespachoPack extends Component {

  state = {
    tab: "1",
    data: [],
    modal: false,
    modalH: false,
    modData: [],
    loanding: false,
    Cols: [],
    startDate: null,
    endDate: null,
  };

  componentWillMount() {
    this.Historial();
  }


  Pendientes = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}porteria/paquetespendientes`, body)
      .then((res) => {
        if (res.data.message === "") {
          var cols = [
            { field: "id_paquete", title: "id_paquete", hidden: true },
            { field: "id_inmueble", title: "id_inmueble", hidden: true },
            { field: "nombre_portero", title: "nombre_portero", hidden: true },
            { field: "nombre", title: "Nombre" },
            { field: "direccion", title: "Dirección" },
            { field: "detalle", title: "Descripción Paquete" },
            {
              field: "fecha_llegada",
              title: "Fecha Llegada",
              render: (data) =>
                moment(data.fecha_llegada).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "despachado",
              title: "Entregar",
              width: "15%",
              align: "center",
              render: (rowData) => (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button
                      onClick={() => this.handler(rowData)}
                      className="btn btn-primary"
                    >
                      Entregar
                    </button>
                  </div>
                </div>
              ),
            },
          ];
          this.setState({
            Cols: cols,
            data: res.data.data,
            loanding: true,
          });
        } else {
          //this.setState({data: false});
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  Historial = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_portero:Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}porteria/paqueteshistorial`, body)
      .then((res) => {
        if (res.data.message === "") {
          var cols = [
            { field: "id_paquete", title: "id_paquete", hidden: true },
            { field: "estado", title: "estado", hidden: true },
            {
              field: "nombre_portero_recibe",
              title: "nombre_portero_recibe",
              hidden: true,
            },
            { field: "nombre_recibe", title: "nombre_recibe", hidden: true },
            { field: "nombre_paquete", title: "Nombre" },
            { field: "direccion", title: "Dirección" },
            {
              field: "fecha_llegada",
              title: "Fecha Llegada",
              render: (data) =>
                moment(data.fecha_llegada).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "fecha_entrega",
              title: "Fecha Entrega",
              render: (data) =>
                moment(data.fecha_entrega).format("DD MMM YYYY HH:mm:ss"),
            },
            { field: "nombre_portero_entrega", title: "Entrego Portero" },
            {
              field: "detalle",
              title: "Detalle",
              align: "center",
              render: (rowData) => (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handlerH(rowData)}
                    >
                      Ver Mas...
                    </button>
                  </div>
                </div>
              ),
            },
          ];

          this.setState({
            Cols: cols,
            data: res.data.data,
            loanding: true,
          });
        } else {
          this.setState({ data: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  HistorialDate = () => {
    this.setState({ loanding: false });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      fechai:Buffer.from(moment(this.state.startDate).format("DD/MM/YYYY")).toString("base64"),
      fechaf:Buffer.from(moment(this.state.endDate).format("DD/MM/YYYY")).toString("base64"),
      id_portero:Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}porteria/paqueteshistorialdate`, body)
      .then((res) => {
        if (res.data.message === "") {
          var cols = [
            { field: "id_paquete", title: "id_paquete", hidden: true },
            { field: "estado", title: "estado", hidden: true },
            {
              field: "nombre_portero_recibe",
              title: "nombre_portero_recibe",
              hidden: true,
            },
            { field: "nombre_recibe", title: "nombre_recibe", hidden: true },
            { field: "nombre_paquete", title: "Nombre" },
            { field: "direccion", title: "Dirección" },
            {
              field: "fecha_llegada",
              title: "Fecha Llegada",
              render: (data) =>
                moment(data.fecha_llegada).format("DD MMM YYYY HH:mm:ss"),
            },
            {
              field: "fecha_entrega",
              title: "Fecha Entrega",
              render: (data) =>
                moment(data.fecha_entrega).format("DD MMM YYYY HH:mm:ss"),
            },
            { field: "nombre_portero_entrega", title: "Entrego Portero" },
            {
              field: "detalle",
              title: "Detalle",
              align: "center",
              render: (rowData) => (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handlerH(rowData)}
                    >
                      Ver Mas...
                    </button>
                  </div>
                </div>
              ),
            },
          ];

          this.setState({
            Cols: cols,
            data: res.data.data,
            loanding: true,
          });
        } else {
          this.setState({ data: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  handler = (data) => {
    this.setState({ modal: true, modData: data });
  };

  handlerH = (data) => {
    this.setState({ modalH: true, modData: data });
  };

  Cerrar = (id_paquete) => {
    this.setState({ modal: false, loanding: false });
    var tem = this.state.data;
    var index = this.IndexCambio(id_paquete, tem);

    tem.splice(index, 1);
    this.setState({
      data: tem,
    });

    setTimeout(() => {
      this.setState({
        loanding: true,
      });
    }, 150);
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_paquete) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  render() {
    const ModalD = () => {
      return (
        <Modal
          show={this.state.modal}
          centered
          backdrop={"static"}
          onHide={() => this.setState({ modal: false })}
        >
          <ModalDispatchPD
            infoDispatch={this.state.modData}
            cerrar={this.Cerrar}
          />
        </Modal>
      );
    };

    const ModalH = () => {
      return (
        <Modal
          show={this.state.modalH}
          centered
          backdrop={"static"}
          onHide={() => this.setState({ modalH: false })}
        >
          <ModalDispatchH infoDispatch={this.state.modData} dependencia={1} />
        </Modal>
      );
    };

    return (
      <div className="container-fluid h-100">
        <ModalD />
        <ModalH />
        <div className="row h-100 py-3">
          <div className="col-12">
            <div className="card h-100 shadow g-r-prf2">
              <div className="card-body">
                {this.state.loanding ? (
                  <MaterialTable
                    title={``}
                    columns={this.state.Cols}
                    data={this.state.data}
                    options={{
                      pageSize:
                        this.state.data.length < 20
                          ? this.state.data.length
                          : 20,
                      pageSizeOptions: [
                        this.state.data.length < 20
                          ? this.state.data.length
                          : 20,
                      ],
                      search: true,
                      exportButton: true,
                      exportAllData: true,
                      exportDelimiter: ";",
                      exportFileName: (`Informe de Visitas, ${moment(new Date()).format('LL')}`),
                      actionsColumnIndex: -1,
                      padding: "dense",
                      rowStyle: (rowData) => {
                        if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                          return {
                            backgroundColor: "#eeeeee",
                            fontSize: "0.9rem",
                            textTransform: "uppercase",
                          };
                        }
                        return {
                          backgroundColor: "#ffffff",
                          fontSize: "0.9rem",
                          textTransform: "uppercase",
                        };
                      },
                    }}
                    localization={{

                      pagination: {
                        labelRowsSelect: 'registros',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                      },
                      toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "CSV",
                        exportPDFName: "PDF"
                      }
                    }}
                    components={{
                      Toolbar: props => (
                        <div>
                          <MTableToolbar {...props} />
                          <div style={{ padding: '0px 10px' }}>

                          <div className="row pb-3">
                        <div className="col-4">
                          <div className="position-relative s-r-option d-flex align-item-center" style={{ zIndex: 23 }}>
                            <Accordion className="absolute-swt shadow-sm" >
                              <AccordionSummary
                                className=""
                                expandIcon={<i className="fas fa-chevron-down"></i>}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                              >
                                <Typography>Fecha</Typography>
                              </AccordionSummary>
                              <AccordionDetails className="bg-light shadow">
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                      locale={Es}
                                    >
                                      <DatePicker
                                        label="fecha inicio"
                                        value={this.state.startDate}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                          this.setState({ startDate: date });
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            className="barraCalendar"
                                            size="small"
                                            {...params}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                      locale={Es}
                                    >
                                      <DatePicker
                                        label="fecha final"
                                        value={this.state.endDate}
                                        minDate={this.state.startDate}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                          this.setState({ endDate: date });
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            className="barraCalendar"
                                            size="small"
                                            {...params}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                        <div className="col">
                          <button className="btn btn-primary text-nowrap btn-sm mx-3" disabled={this.state.startDate===null || this.state.endDate===null ? (true):(false)} onClick={this.HistorialDate}> Buscar </button>
                          <button className="btn btn-danger text-nowrap btn-sm mx-3" onClick={this.Historial}> Limpiar </button>
                        </div>
                      </div>
                         
                          </div>
                        </div>
                      ),
                    }}
                  />
                ) : (
                  <div className="d-flex justify-content-center bg-light text-info flex-column align-items-center h-100">
                    <div
                      className="spinner-border fs-3 m-3"
                      style={{ width: "5rem", height: "5rem" }}
                      role="status"
                    ></div>
                    <p className="fs-3">Cargando...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
