import React, { Component } from "react";

import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import { CookieSesion } from "../../Helpers";
import { Redirect } from "react-router-dom";
import { Fab, Tooltip, Modal } from "@material-ui/core";
import moment from 'moment';

import MaterialTable from "material-table";
import PpBloquearAsisC from "./PopUps/BloquearAsisCrearAsamblea"

const columns = [
  {
    field: "asambleaid",
    title: "Id asamblea",
    hidden: true,
  },
  {
    field: "estado",
    title: "EstadoId",
    hidden: true,
  },
  {
    field: "tipo",
    title: "Asamblea",
  },
  {
    field: "convocatoria",
    title: "Convocatoria",
  },
  {
    field: "abierta",
    title: "Apertura",
  },
  {
    field: "descripcion",
    title: "Descripción",
  },
  {
    field: "estadodetalle",
    title: "Estado",
    cellStyle: function (rowData) {
      if (rowData === "ABIERTA") {
        return { color: 'Green' }
      } else {
        return { color: 'OrangeRed' }
      }
    }
  },
  {
    field: "limite_poderes",
    title: "Límite Representaciones",
  },
  {
    field: "cerrada",
    title: "Cierre",
  },
  {
    field: "detalle_cierre",
    title: "Detalle Cierre",
  },
];

class TablaAsambleaCrear extends Component {
  state = {
    rows: [],

    redirect_home: false,
    redirect_login: 0,
    redirect_info: false,
    data: false,
    idAsamblea: 0,

    PpCrear: false,
    PpBloquearAsis: false,

    DataNewAsamblea: {},

    Rol: -1,

  };

  componentWillMount() {

    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    this.setState({
      Rol: parseInt(Global.GlobalUser.rol)
    })

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        axios.get(`${Global.Ruta.Url}adminasamblea/listarasamblea/${Buffer.from(Global.Ruta.Ipin).toString("base64")}/
        ${Buffer.from(Global.Ph.Codigo).toString("base64")}`).then((res) => {
          if (res.data.message === "") {
            this.setState({
              rows: res.data.Asambleas,
              data: true
            })
          } else {
            console.log("Error al cargar la asamblea: " + res.data.message)
            swal({
              title: "ALERTA!",
              text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ redirect_home: true });
            });
          }
        }).catch((err) => {
          console.log("Error al crear la asamblea: " + err.message)
          this.setState({ redirect_home: true });
        });
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  render() {

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-asamblea"} />;
    }

    if (this.state.redirect_info === true) {
      return <Redirect to={{ pathname: Global.Ruta.Raiz + "/info-asmablea-asistentes", state: { id: this.state.id } }} />
    }

    if (this.state.data === true) {
      return (
        <div className="row mb-5 bg-light h-100">
          <Modal open={this.state.PpBloquearAsis} aria-labelledby="child-modal-title" aria-describedby="child-modal-description" >
            <div className="container-fluid h-100 overflow-auto">
              <PpBloquearAsisC GuardarAsamblea={this.GuardarAsamblea} />
            </div>
          </Modal>
          <div className="col-12">
            <div className="my-2 col-auto ">
              <MaterialTable
                columns={columns}
                data={this.state.rows}

                style={{
                  paddingX: '20px',
                }}

                options={{
                  selectionProps: {
                    color: "primary",
                  },
                  sorting: false,
                  draggable: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50],
                  actionsColumnIndex: -1,
                  rowStyle: { fontSize: "0.9rem" },
                }}

                actions={[
                  {
                    onClickInfo: (rowData) => {
                      Global.InfoAsamblea.AsambleaId = rowData.asambleaid
                      Global.InfoAsamblea.Descripcion = rowData.descripcion
                      Global.InfoAsamblea.Fecha = moment(rowData.abierta).format('LLLL')
                      Global.InfoAsamblea.Asamblea = rowData.tipo.toLowerCase()
                      Global.InfoAsamblea.Convocatoria = rowData.convocatoria.toLowerCase()
                      this.setState({ redirect_info: true, id: rowData.asambleaid })
                    },
                  },
                ]}

                components={{
                  Action: props => (
                    <Tooltip title="Ver" placement="left-start" >
                      <Fab
                        onClick={() => props.action.onClickInfo(props.data)}
                        aria-label="edit"
                        size="small"
                        disabled={(parseInt(props.data.estado) === 1) ? (true) : (false)}
                        className={`mx-2 text-white ${(parseInt(props.data.estado)) ? ("bg-secondary") : ("bg-success")}`}

                      >
                        <i className="fas fa-search" />
                      </Fab>
                    </Tooltip>
                  )
                }}

                localization={{
                  header: {
                    actions: "Acción",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                    nRowsSelected:
                      "{0} " + this.props.tituloT + "(s) Seleccionado(s)",
                  },
                }}

              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default TablaAsambleaCrear;
