import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap';
import swal from "sweetalert";
import Global from "./../../../../Global";
import axios from "axios";
import SimpleReactValidator from 'simple-react-validator'
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import JSZip from 'jszip';

export default class CrearPDFBD extends Component {

    state = {
        PpHourglass: false,
        Estado: [{ id: 1, tipo: "Virtual / Mixta" }, { id: 2, tipo: "Presencial" }],
        estado: "1",
        codigo: this.props.codigoph,
        nombreph: ""
    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            messages: {
                required: 'El campo es requerido.',
                email: 'Correo Invalido',
                alpha_num: 'No puede Contener Caracteres',
                numeric: 'solo puede ingresar numeros enteros',
                integer: 'solo puede ingresar numeros enteros',
                min: 'solo puede ingresar numeros enteros'
            }
        });
    }

    Verificar = () => {
        if (this.validator.allValid()) {
            // if (parseInt(this.state.codigo) >= 30000) {
            this.setState({ PpHourglass: true });
            var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(this.state.codigo).toString("base64"),
            };
            axios.post(`${Global.Ruta.Url}newbd/validarcodigoph`, body).then((res) => {
                if (res.data.message === "") {
                    this.setState({ PpHourglass: false });
                    swal({
                        title: `${res.data.nombre}`,
                        text: `¿Desea descargar los documentos PDF ${parseInt(this.state.estado) === 1 ? ("Virtuales") : ("Presenciales")} correspondientes a la Propiedad Horizontal?`,
                        icon: "info",
                        buttons: ["Cancelar", "Descargar"],
                    }).then((acepto) => {
                        if (acepto) {
                            this.setState({ PpHourglass: true, nombreph: res.data.nombre }, () => this.GenerarPDF());
                        } else {
                            this.props.onHide()
                            this.validator.hideMessages();
                            this.forceUpdate();
                        }
                    });
                } else {
                    swal({
                        title: "Atención",
                        text: res.data.message,
                        icon: "info",
                        button: "Aceptar",
                    }).then(() => {
                        this.props.onHide()
                    });
                }
            }).catch((err) => {
                swal({
                    title: "ERROR!",
                    text: "Por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                });
            });
            // } else {
            //     swal({
            //         title: "Atención",
            //         text: `Lamentablemente, no es posible generar archivos PDF para esta Propiedad Horizontal`,
            //         icon: "info",
            //         button: "Aceptar",
            //     })
            // }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    GenerarPDF = () => {
        this.setState({ PpHourglass: true });

        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(this.state.codigo).toString("base64"),
            caso: Buffer.from(this.state.estado).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}newbd/crearpdfsusc`, body).then((res) => {
            console.log(res.data)
            if (res.data.message === "") {

                this.downloadPDF(res.data.PDFLogin)
            } else {
                swal({
                    title: "Atención",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.props.onHide()
            });
        });
    }

    downloadPDF = async (Data) => {
        const zip = new JSZip();
        Data.forEach((item, index) => {
            const { nombre, login, base64Pdf } = item;
            const formattedBase64 = base64Pdf.startsWith('data:application/pdf;base64,') ? base64Pdf : `data:application/pdf;base64,${base64Pdf}`;
            zip.file(`${login} ${nombre}.pdf`, formattedBase64.split(';base64,')[1], { base64: true });
        });

        zip.generateAsync({ type: 'blob' })
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `USUARIOS ${this.state.nombreph}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            });
        this.setState({ PpHourglass: false })
        this.props.onHide()
    }

    render() {

        return (
            <div className='g-r-prf1 h-100'>
                <Modal.Header closeButton>
                    <span className="fw-bold m-0"> {this.props.nombre_ph}</span>
                </Modal.Header>
                <Modal.Body>
                    <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}>
                        <PpHourglass />
                    </Modal>
                    <div className='row row-cols-2'>
                        <div className='col-12'>
                            <Form.Group>
                                <label>Tipo de Asamblea</label>
                                <Form.Select value={this.state.estado} onChange={(e) => this.setState({ estado: e.target.value })}>
                                    {this.state.Estado.map((data, indice) => (
                                        <option key={indice} value={data.id}>{data.tipo.toUpperCase()}</option>
                                    ))}
                                </Form.Select>
                                <label className='text-muted text-capitalize m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message('Estado', this.state.estado, 'required', { className: 'text-danger' })}</label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <button className='btn btn-primary' onClick={() => this.Verificar()}> <i className="fa-regular fa-file-pdf me-2"></i> Descargar PDF</button>
                </Modal.Footer>
            </div>
        )
    }
}