import React, {Component} from "react";
import {Form, InputGroup, Modal} from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";

export default class ModalDispatchH extends Component {


  render() {
    let {infoDispatch,dependencia} = this.props;

    return (
      <div>
        <Modal.Header closeButton>Historial</Modal.Header>
        <Modal.Header>
        {parseInt(dependencia)===1 ? (
          <div className="row">
             <div className="col-12">
              <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Despachado">Despachado</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.estado === 2 ? "Si" : "No"}
                  className={`text-light ${
                    infoDispatch.estado === 2 ? "bg-success" : "bg-danger"
                  }`}
                  disabled
                  aria-label="Depsachado"
                  aria-describedby="Depsachado"
                />
              </InputGroup>
            </div>
             <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Dirección</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.direccion.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Nombre del Paquete</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.nombre_paquete.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Recibe</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.nombre_recibe.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Fecha de Llegada</InputGroup.Text>
                <Form.Control
                  value={moment(infoDispatch.fecha_llegada).format('DD MMM YYYY HH:mm:ss')}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Fecha de Entrega</InputGroup.Text>
                <Form.Control
                  value={moment(infoDispatch.fecha_entrega).format('DD MMM YYYY HH:mm:ss')}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Portero Recibe</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.nombre_portero_recibe.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Portero Entrega</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.nombre_portero_entrega.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
              <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Detalle">Detalle</InputGroup.Text>
                <Form.Control
                  disabled
                  as="textarea"
                  value={infoDispatch.detalle}
                  aria-label="Detalle"
                  aria-describedby="Detalle"
                />
              </InputGroup>
            </div>
             </div>
        ):("")}

        {parseInt(dependencia)===2 ? (
          <div className="row">
             <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Dirección</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.direccion.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Nombre del Residente</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.nombres.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Fecha de Petición</InputGroup.Text>
                <Form.Control
                  value={moment(infoDispatch.fecha_crea).format('DD MMM YYYY HH:mm:ss')}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Fecha de Entrega</InputGroup.Text>
                <Form.Control
                  value={moment(infoDispatch.fecha_llegada).format('DD MMM YYYY HH:mm:ss')}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
            <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Depsachado">Portero Entrega</InputGroup.Text>
                <Form.Control
                  value={infoDispatch.nombre_portero.toUpperCase()}
                  className={`text-dark`}
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12">
              <InputGroup className="mb-3">
                <InputGroup.Text className="bg-secondary text-light" id="Detalle">Detalle</InputGroup.Text>
                <Form.Control
                  disabled
                  as="textarea"
                  value={infoDispatch.detalle.toUpperCase()}
                  aria-label="Detalle"
                  aria-describedby="Detalle"
                />
              </InputGroup>
            </div>
          </div>
        ):("")}
        </Modal.Header>
        <Modal.Footer></Modal.Footer>
      </div>
    );
  }
}
