import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import Global from "../../../Global";
import Loader from "../../principal/Loader";
import { CookieSesion, CookieNombrePH, CookiePhCodigo, CookieNombre, CookieTipo, CookieUserId, CookieRol, CookieNitph, CookieCiudad, } from "../../Helpers";
import CardReservasRol6 from "../reservas/CardReservasRol6"
import { Card } from "@mui/material";

const cookies = new Cookie();

class HomeRol6 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect_login: 0,
            reservas: 0
        };

        this.handleDataUpdate = this.handleDataUpdate.bind(this);
    }

    componentWillMount() {

        var login = CookieSesion();
        var Rol = CookieRol();
        var userid = CookieUserId();

        if (parseInt(login) > 0) {
            if (parseInt(userid) > 0) {
                this.setState({
                    Rol: parseInt(Rol),
                });

                Global.GlobalUser.rol = parseInt(Rol);
                Global.GlobalUser.userid = CookieUserId();
                Global.GlobalUser.Nombre = CookieNombre();
                Global.Ph.Nombre = CookieNombrePH();
                Global.Ph.Codigo = CookiePhCodigo();
                Global.Ph.Nitph = CookieNitph();
                Global.Ph.Ciudadph = CookieCiudad();
                Global.Ph.Estadoph = CookieTipo();

                if (parseInt(Rol) === 6) {
                    this.setState({
                        redirect_login: 1,
                    });
                }
            } else {
                cookies.remove("Sesion", { path: "/" });
                cookies.remove("UserId", { path: "/" });
                cookies.remove("Nombre", { path: "/" });
                this.setState({
                    redirect_login: 2,
                });
            }
        } else {
            this.setState({
                redirect_login: 2,
            });
        }
        if (parseInt(Rol) === 0) {
            cookies.remove("Sesion", { path: "/" });
            cookies.remove("UserId", { path: "/" });
            cookies.remove("Nombre", { path: "/" });
            this.setState({
                redirect_login: 2,
            });
        }
    }

    handleDataUpdate(data) {
        if (data !== this.state.reservas) {
            this.setState({
                reservas: data
            });
        }
    }

    render() {
        if (this.state.redirect_login === 1) {
            return (
                <div className="container-fluid" style={{ minWidth: "100vw", maxWidth: "100vw", minHeight: "100vh", maxHeight: "100vh" }}>
                    <div className="h-100 row bg-light"
                        style={{ maxHeight: 'calc(100% - 86px)', marginTop: "74px", marginBottom: "12px", marginLeft: "0px", marginRight: "0px", }}>
                        <div className="w-100 d-flex flex-column position-relative" >
                            <div className="col h-100 w-100 row flex-md-row justify-content-start">
                                <Card sx={{ minWidth: "100%", maxWidth: "100%", minHeight: "90vh", maxHeight: "90vh" }}
                                    className="border border-light-subtle mx-2 mb-3 d-flex flex-column position-relative">
                                    <div className="text-center my-2">
                                        <span style={{ fontWeight: "bold" }}>
                                            {` Reservas ${(this.state.reservas > 0 ? (`(${this.state.reservas})`) : (''))}`}
                                        </span>
                                    </div>
                                    <CardReservasRol6 onDataUpdate={this.handleDataUpdate} />
                                </Card>

                            </div>
                        </div>
                    </div >
                </div >
            )
        }

        if (this.state.redirect_login === 2) {
            return <Redirect to={Global.Ruta.Raiz + "/"} />;
        }

        return (
            <div style={{ minHeight: "calc(100% - 86px)" }}>
                <Loader princial={true} uno={"Inicio"} dos={"Datos"} tres={"Imagenes"} />
            </div>
        );
    }
}

export default HomeRol6;

