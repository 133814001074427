import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Global from "../../../../../Global";
import axios from "axios";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";
import jsPDF from 'jspdf'
import "jspdf-autotable";
import logo from "../../../../../assets/images/logo.png"

const columns = [
    {
        field: 'nombres',
        title: 'Nombres',
    },
    {
        field: 'direccion',
        title: 'Dirección',
    },
    {
        field: 'calasistencia',
        title: 'Calidad Asistencia',
    },
    {
        field: 'coeficiente',
        title: 'Coeficiente',
    },
    {
        field: 'fecha',
        title: 'Fecha',
        export: true,
    },
    {
        field: 'origen',
        title: 'Origen',
        export: true,
    },
]

export default class TablaInfoAsistentes extends Component {
    
    state = {
        rows: [],
        data: false,
        redirect_home: false,
    }

    componentWillMount() {

        let body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            asambleaid: Buffer.from(Global.InfoAsamblea.AsambleaId).toString("base64"),
        }

        axios.post(`${Global.Ruta.Url}adminasamblea/infoasistentes/`, body).then((res) => {
            if (res.data.message === "") {

                this.props.TotalAsistentes(res.data.TotalAsistentes)

                let temp = []

                res.data.Asistentes.forEach(Asistente => {

                    let Origen = '';
                    switch (Asistente.origen) {
                        case 'A':
                            Origen = 'Asistente'
                            break
                        case 'W':
                            Origen = 'Web'
                            break
                        case 'M':
                            Origen = 'App'
                            break
                        default:
                            break
                    }

                    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: "America/Bogota" };
                    let fecha = new Date(Asistente.fechahora)

                    let asistente = {
                        nombres: Asistente.nombres.toUpperCase(),
                        direccion: Asistente.direccion,
                        calasistencia: Asistente.expr1,
                        coeficiente: Asistente.coeficiente,
                        fecha: `${fecha.toLocaleDateString("es-419", options)} ${fecha.toLocaleTimeString("en-US")}`,
                        origen: Origen,
                    }
                    temp.push(asistente)
                });
                this.setState({
                    rows: temp,
                    data: true
                })
            } else {
                swal({
                    title: "ALERTA!",
                    text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                    icon: "error",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ redirect_home: true });
                });
            }
        }).catch((err) => {
            swal({
                title: "ALERTA!",
                text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar"
            }).then(e => {
                this.setState({ redirect_home: true })
            });
        })

    }

    render() {

        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-asamblea"} />;
        }

        if (this.state.data === true) {
            return (
                <div className="container-fluid bg-light h-100 pt-2 pb-2">
                    <MaterialTable
                        columns={columns}
                        data={this.state.rows}
                        title={`Asistentes de la asamblea del ${Global.InfoAsamblea.Fecha}`}

                        options={{
                            pageSize: 15,
                            pageSizeOptions: [15, 20, 30, 50, 100],
                            actionsColumnIndex: -1,
                            rowStyle:{fontSize: "0.9rem"},
                            exportButton: true,
                            exportAllData: true,
                            exportDelimiter: ";",
                            exportFileName: `Asistentes de la asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria} del ${Global.InfoAsamblea.Fecha}`,
                            exportPdf: (columns, data) => {

                                const doc = new jsPDF("portrait");
                                doc.addImage(logo, "JPEG", 95, 15, 25, 25);
                                doc.setFontSize(15);
                                doc.text(Global.Ph.Nombre, 105, 48, null, null, "center")
                                doc.setTextColor("DodgerBlue")
                                doc.text(`del ${Global.InfoAsamblea.Fecha}.`, 105, 60, null, null, "center");
                                doc.text(`Asistentes de la Asamblea ${Global.InfoAsamblea.Asamblea} en ${Global.InfoAsamblea.Convocatoria}`, 105, 54, null, null, "center");

                                const columnTitles = []
                                for (var i = 0; i < columns.length; i++) {
                                    if (i !== 4) {
                                        columnTitles.push(columns[i].title)
                                    }
                                }

                                const pdfData = []
                                data.forEach(IndividualSusc => {
                                    var Data = []
                                    Data[0] = IndividualSusc.nombres
                                    Data[1] = IndividualSusc.direccion
                                    Data[2] = IndividualSusc.calasistencia
                                    Data[3] = IndividualSusc.coeficiente
                                    Data[4] = IndividualSusc.origen

                                    pdfData.push(Data)
                                });

                                doc.autoTable({
                                    startY: 68,
                                    theme: 'grid',
                                    rowPageBreak: 'avoid',
                                    styles: { cellWidth: 'auto', halign: 'center', valign: 'middle', },
                                    columnStyles: { 2: { cellWidth: 50 } },
                                    bodyStyles: { halign: 'left', cellPadding: 3 },
                                    head: [columnTitles],
                                    body: pdfData,
                                });

                                const cuentaPaginas = doc.internal.getNumberOfPages();
                                for (let i = 1; i <= cuentaPaginas; i++) {
                                    doc.setPage(i);
                                    doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 26, 'S');
                                    doc.setFontSize(12)
                                    doc.setTextColor("DarkGray")
                                    doc.text(`Pagina ${String(i)} de ${String(cuentaPaginas)}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, "right")
                                    doc.setFontSize(10)
                                    doc.text(`PDF hecho desde la Plataforma GoPH© \nDesarrollado por Inggo Developments SAS`, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 10, null, null, "left")
                                }

                                doc.save(`Asistentes de la asamblea ${Global.InfoAsamblea.Asamblea}.pdf`);
                            }
                        }}

                        localization={{
                            header: {
                                actions: "Acción",
                            },
                            body: {
                                emptyDataSourceMessage: "No hay datos por mostrar.",
                            },
                            pagination: {
                                labelRowsSelect: "registros",
                                labelDisplayedRows: "{from}-{to} de {count}",
                                firstTooltip: "Primera página",
                                previousTooltip: "Página anterior",
                                nextTooltip: "Próxima página",
                                lastTooltip: "Última página",
                            },
                            toolbar: {
                                searchTooltip: "Buscar",
                                searchPlaceholder: "Buscar",
                                exportCSVName: "CSV",
                                exportPDFName: "PDF"
                            },
                        }}
                    />
                </div>
            )
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}
