import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CopyRight from './CopyRight';
import goph_body from '../../assets/images/oopsss.jpg';
import Global from '../../Global';

class Error extends Component {

    render() {

        return (
            <React.Fragment>
                <div className="container-fluid min-vh-100 d-flex justify-content-center w-75 align-items-center" >
                    <div className="row" style={{"backgroundColor": "white"}}>
                        <div className="col-md-6">
                            <div className="text-center">
                                <img className="img-responsive" src={goph_body} alt="Error" />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="separador"></div>
                            <h2>Upsssssssss !!!</h2>
                            <p>
                                <span>No se ha encontrado la página solicitada.</span>
                                <span>Puedes volver a la página anterior, visitar nuestra página de inicio </span>
                                <span>o ponerte en contacto con nuestro equipo de asistencia técnica.</span>
                            </p>

                            <div className="separador">
                                <div className="d-grid gap-2">
                                    <Link id="btn_err" to={Global.Ruta.Raiz+"/home"} className="btn btn-primary">Visita la página de inicio</Link>
                                    <Link id="btn_err" to={Global.Ruta.Raiz+"/Soporte"} className="btn btn-outline-primary">Contáctanos</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <div className="separador"></div>

                <CopyRight />
            </React.Fragment>
        );
    }
}

export default Error;