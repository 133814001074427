import React, { Component } from 'react';

import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";

import MaterialTable, { MTableToolbar } from "material-table";
import { Fab, Tooltip } from "@material-ui/core";
import ListaTransfer from './ListaTransferMsn';
import { Modal } from 'react-bootstrap';

const columns = [
    {
        field: "id",
        title: "Id",
        hidden: true
    },
    {
        field: "suscriptorid",
        title: "Id Suscriptor",
        hidden: true
    },
    {
        field: "nombre",
        title: "Nombre",
    },
];

class TablaGruposMsn extends Component {

    state = {
        rows: [],
        count: 0,
        data: false,
        tituloTable: "",
        modalShow: false,
        idGrp: [],
        Grupos: [],
        Asignados: [],
        SinAsignar: [],
        grupoSelect: 0,
        NombreSelect: ""
    }

    componentWillMount() {

        axios.get(Global.Ruta.Url + "mensajeria/notificagrupo/" + Buffer.from(Global.Ph.Codigo).toString("base64"))
            .then((res) => {
                if (res.data.message === "") {
                    var tem = [];
                    var RowGrupo = {};
                    for (var i = 0; i < res.data.Grupo.length; i++) {

                        RowGrupo = {
                            id: res.data.Grupo[i].id_grupo,
                            suscriptorid: 0,
                            nombre: res.data.Grupo[i].nombre_grupo,
                        };
                        tem.push(RowGrupo);

                        var Asignado = {};
                        for (var asignado of res.data.Grupo[i].Asignados) {
                            Asignado = {
                                id: 0,
                                suscriptorid: asignado.suscriptorid,
                                nombre: asignado.nombres.toUpperCase() + " - " + asignado.direccion,
                                parentId: res.data.Grupo[i].id_grupo,
                            };
                            tem.push(Asignado);
                        }
                    }
                    this.setState({ rows: tem, data: true, Grupos: res.data.Grupo });
                } else {
                    this.props.cerrar();
                }
            })
            .catch((err) => {
                this.props.cerrar();
            });
    }

    BuscarAsignadosGrupo = (GrupoId, ParamNombre) => {
        this.setState({ grupoSelect: GrupoId, modalShow: true, NombreSelect: ParamNombre })
    }

    guardarCambios = (GrpId, Asignados, Nombre, tipo) => {

        this.setState({
            data: false,
            modalShow: false
        });

        var body = {
            codigoph: Global.Ph.Codigo,
            nombre: Nombre,
            idgrupo: GrpId,
            asignados: Asignados
        }
        if (tipo === 1) {
            axios.post(Global.Ruta.Url + "mensajeria/editargrupo/", body)
                .then((res) => {
                    if (res.data.message === "") {
                        var tem = [];
                        var RowGrupo = {};
                        var TemGrupos = this.state.Grupos;
                        for (var i = 0; i < this.state.Grupos.length; i++) {

                            var Asignado = {};
                            var TemAsignados = [];

                            if (parseInt(this.state.Grupos[i].id_grupo) === parseInt(GrpId)) {
                                RowGrupo = {
                                    id: this.state.Grupos[i].id_grupo,
                                    suscriptorid: 0,
                                    nombre: Nombre,
                                };
                                tem.push(RowGrupo);
                                TemAsignados = Asignados;
                                TemGrupos[i].Asignados = Asignados;
                                TemGrupos[i].nombre_grupo = Nombre;
                            } else {
                                RowGrupo = {
                                    id: this.state.Grupos[i].id_grupo,
                                    suscriptorid: 0,
                                    nombre: this.state.Grupos[i].nombre_grupo,
                                };
                                tem.push(RowGrupo);
                                TemAsignados = this.state.Grupos[i].Asignados
                            }

                            for (var asignado of TemAsignados) {
                                Asignado = {
                                    id: 0,
                                    suscriptorid: asignado.suscriptorid,
                                    nombre: asignado.nombres.toUpperCase() + " - " + asignado.direccion,
                                    parentId: this.state.Grupos[i].id_grupo,
                                };
                                tem.push(Asignado);
                            }
                        }
                        this.setState({
                            rows: tem,
                            data: true,
                            Grupos: TemGrupos
                        });
                    } else if (res.data.message === -1) {
                        swal({
                            title: "ALERTA!",
                            text: "El nombre del grupo es unico por favor colocar otro nombre.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    } else {
                        swal({
                            title: "ALERTA!",
                            text: "Vaya algo a salido mal al guardar el cambio, por favor vuelva a intentarlo.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ALERTA!",
                        text: "Vaya algo a salido mal al guardar el cambio, por favor vuelva a intentarlo.",
                        icon: "error",
                        button: "Aceptar"
                    })
                });
            this.setState({
                data: true,
            });
        }
        if (tipo === 2) {
            axios.post(Global.Ruta.Url + "mensajeria/creargrupo/", body)
                .then((res) => {
                    if (res.data.message === "") {
                        var GrupoId = parseInt(res.data.VarIdGrupo)
                        var tem = this.state.rows;
                        var RowGrupo = {};

                        RowGrupo = {
                            id: GrupoId,
                            suscriptorid: 0,
                            nombre: Nombre,
                        };
                        tem.push(RowGrupo);

                        var Asignado = {}
                        for (var asignado of Asignados) {
                            Asignado = {
                                id: 0,
                                suscriptorid: asignado.suscriptorid,
                                nombre: asignado.nombres.toUpperCase() + " - " + asignado.direccion,
                                parentId: GrupoId,
                            };
                            tem.push(Asignado);
                        }
                        /* AGREGARLO A LOS GRUPOS PARA PROXIMAMENTE ACTUALIZAR */
                        var TemGrupos = this.state.Grupos;
                        var TemGrupo = {
                            id_grupo: GrupoId,
                            nombre_grupo: Nombre,
                            Asignados: Asignados,
                        }
                        TemGrupos.push(TemGrupo)
                        /*----------------------------------------------------- */

                        this.setState({
                            rows: tem,
                            data: true,
                            Grupos: TemGrupos
                        });
                    } else if (res.data.message === -1) {
                        swal({
                            title: "ALERTA!",
                            text: "El nombre del grupo es unico por favor colocar otro nombre.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    } else {
                        swal({
                            title: "ALERTA!",
                            text: "Vaya algo a salido mal al guardar el cambio, por favor vuelva a intentarlo.",
                            icon: "error",
                            button: "Aceptar"
                        })
                    }
                })
                .catch((err) => {
                    swal({
                        title: "ALERTA!",
                        text: "Vaya algo a salido mal al guardar el cambio, por favor vuelva a intentarlo.",
                        icon: "error",
                        button: "Aceptar"
                    })
                });
            this.setState({
                data: true,
            });
        }
    }

    EliminarGrupo = (GrupoId) => {
        this.setState({
            data: false,
            modalShow: false
        });

        axios.get(Global.Ruta.Url + "mensajeria/borrargrupo/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from("" + GrupoId).toString("base64"))
            .then((res) => {
                if (res.data.message === "") {

                    var TemGrupos = this.state.Grupos;

                    var indiceCambio = this.BuscarIndexCambio(GrupoId, TemGrupos)
                    TemGrupos.splice(indiceCambio, 1)

                    var TemRows = this.state.rows;
                    var temp = this.BuscarIndexCambioTable(GrupoId, TemRows)

                    for (let DeleteTable of temp.reverse()) {
                        TemRows.splice(DeleteTable.tableData.id, 1)
                    }

                    this.setState({
                        rows: TemRows,
                        Grupos: TemGrupos
                    })

                } else {
                    swal({
                        title: "ALERTA!",
                        text: "Vaya algo a salido mal al eliminar el grupo, por favor vuelva a intentarlo.",
                        icon: "error",
                        button: "Aceptar"
                    })
                }
            })
        this.setState({
            data: true,
        });
    }
    BuscarIndexCambio = (GrupoId, temRows) => {
        // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON 

        const findIndexArray = (element) => parseInt(element.id_grupo) === parseInt(GrupoId);

        return temRows.findIndex(findIndexArray)
    }

    BuscarIndexCambioTable = (GrupoId, temRows) => {
        // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON 

        const findIndexArray = (element) => (parseInt(element.id) === parseInt(GrupoId) || parseInt(element.parentId) === parseInt(GrupoId));

        return temRows.filter(findIndexArray)
    }

    render() {

        if (this.state.data === true) {
            return (
                <Modal.Body>

                    <Modal show={this.state.modalShow} fullscreen backdrop='static' onHide={() => { this.setState({ modalShow: false }) }}>
                        <Modal.Header closeButton>Edición de Grupo</Modal.Header>
                        <Modal.Body className="bg-white">
                            <ListaTransfer id="parent-modal-title" grupoid={this.state.grupoSelect} guardar={this.guardarCambios} nombre={this.state.NombreSelect} 
                            eliminar={this.EliminarGrupo} />
                        </Modal.Body>
                    </Modal>

                    <div className="row w-100">
                        <div className="tablaContact col-auto  ">
                            <MaterialTable
                                columns={columns}
                                data={this.state.rows}
                                title={this.props.tituloT}

                                actions={[
                                    {
                                        onClick: (event, rowData) => { this.BuscarAsignadosGrupo(rowData.id, rowData.nombre) }
                                    }
                                ]}

                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

                                options={{
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    selectionProps: rowData => ({
                                        disabled: rowData.id === 0
                                    }),
                                    rowStyle:{fontSize: "0.9rem"},
                                }}

                                components={{
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                            <div style={{ padding: '0px 15px', marginBottom: '12px' }}>
                                                <Tooltip title="Crear Nuevo Grupo" onClick={() => { this.setState({ modalShow: true, grupoSelect: -1 }) }} placement="right-start">
                                                    <Fab color="secondary" size="medium" aria-label="edit">
                                                        <i className="fas fa-plus"></i>
                                                    </Fab>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    ),
                                    Action: props => (
                                        <div style={{ padding: '10px 3px' }}>
                                            <Tooltip title="Editar Grupo" placement="left-start" >
                                                <Fab
                                                    onClick={(event) => props.action.onClick(event, props.data)}
                                                    color="primary"
                                                    aria-label="edit"
                                                    size="small"
                                                    disabled={(props.data.id === 0 ? true : false)}
                                                >
                                                    <i className="fas fa-edit" />
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                    ),
                                }}

                                localization={{
                                    header: {
                                        actions: 'Editar Grupo ',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos por mostrar.',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'registros',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

}

export default TablaGruposMsn;