import React, { Component } from "react";
import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import { CookieSesion } from "../../Helpers";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import { Fab, Tooltip } from "@material-ui/core";
// import { DataGrid } from '@mui/x-data-grid';


const columns = [
  {
    field: "usuarioid",
    title: "Id usuario",
    hidden: true,
  },
  {
    field: "nombres",
    title: "Nombres",
    validate: (rowData) =>
      rowData.nombres === undefined || rowData.nombres === ""
        ? { isValid: false, helperText: "Debe colocar un nombre." }
        : true,
    ///validate: rowData => rowData.nombres === '' ? { isValid: false, helperText: 'Name cannot be empty' } : true,
  },
  {
    field: "codigo",
    title: "Código",
    hidden: parseInt(Global.GlobalUser.rol) === 1 ? false : true,
  },
  {
    field: "login",
    title: "Usuario",
    validate: (rowData) =>
      rowData.login === undefined || rowData.login === ""
        ? { isValid: false, helperText: "Debe colocar un Login correcto.." }
        : true,
  },
  {
    field: "rol",
    title: "Rol",
    lookup: {
      1: "ADMINISTRADOR GOPH",
      2: "ADMINISTRADOR",
      3: "CALL CENTER",
      4: "PORTERIA",
    },
  },
  {
    field: "estado",
    title: "Estado",
    lookup: { 0: "INACTIVO", 1: "ACTIVO" },
  },
  {
    field: "rondero",
    title: "rondero",
    hidden: true,
  },
];

class DataTable extends Component {
  state = {
    redirect_home: false,
    redirect_login: 0,
    form_user: 0,
    rows: [],
    nombres: [],
    newData: [],
    count: 0,
    data: false,
    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          axios
            .get(
              Global.Ruta.Url +
              "admin/listuser/" +
              Buffer.from(Global.Ph.Codigo).toString("base64") +
              "/" +
              Buffer.from(Global.Ruta.Ipin).toString("base64")
            )
            .then((res) => {
              if (res.data.message === "") {
                var tem = [];
                if (res.data.message === "") {
                  var user = {};
                  for (var i = 0; i < res.data.Usuarios.length; i++) {
                    user = {
                      usuarioid: res.data.Usuarios[i].usuarioid,
                      nombres: res.data.Usuarios[i].nombres,
                      codigo: res.data.Usuarios[i].codigo,
                      login: res.data.Usuarios[i].login,
                      rol: res.data.Usuarios[i].rol,
                      estado: res.data.Usuarios[i].estado,
                      rondero: res.data.Usuarios[i].rondero,
                    };
                    tem.push(user);
                  }
                }
           
                this.setState({ rows: tem, data: true });
                //setData(tem);
              } else {
                swal({
                  title: "ALERTA!",
                  text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({ redirect_home: true });
                });
              }
            })
            .catch((err) => {
              swal({
                title: "ALERTA!",
                text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
                icon: "error",
                button: "Aceptar",
              }).then((e) => {
                this.setState({ redirect_home: true });
              });
            });
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  formulario = (e) => {
    Global.Form.tipo = e.tipo;
    if (e.tipo === 2) {
      Global.Form.id = e.id;
      Global.Form.nombre = e.nombre;
      Global.Form.login = e.login;
      Global.Form.rol = e.rol;
      Global.Form.estado = e.estado;
      Global.Form.rondero = e.rondero;
    } else {
      Global.Form.id = "";
      Global.Form.nombre = "";
      Global.Form.login = "";
      Global.Form.rol = "";
      Global.Form.estado = "";
      Global.Form.rondero = "";
    }
    this.setState({ form_user: 1, data: false });
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.form_user === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/formusuario"} />;
    }

    if (this.state.data === true) {
      return (
        <div className="container-fluid bg-light">
          <div className="row py-2">
            <div className="col-12">
              <MaterialTable
                columns={columns}
                data={this.state.rows}
                title={
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <h5 className="mb-0 me-3">{`Usuarios`}</h5>
                    </div>
                    <div className="col-auto ps-0">
                      <Tooltip
                        title="Crear Nuevo Usuario"
                        placement="right" arrow
                      >
                        <Fab
                          onClick={() => this.formulario({ tipo: 1 })}
                          className="shadow-none bg-success text-light"
                          size="small"
                          aria-label="edit"
                        >
                          <i className="fas fa-plus"></i>
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                }
                actions={[
                  {
                    tooltip: "Save User",
                    onClick: (event, rowData) =>
                      this.formulario({
                        tipo: 2,
                        id: rowData.usuarioid,
                        nombre: rowData.nombres,
                        login: rowData.login,
                        rol: rowData.rol,
                        estado: rowData.estado,
                        rondero: rowData.rondero,
                      }), //alert("You saved " + rowData.usuarioid)
                  },
                ]}
                components={{
                  Action: (props) => (
                    <div className="px-3">
                      <Tooltip title="Editar Usuario" placement="left-start">
                        <Fab
                          onClick={(event) =>
                            props.action.onClick(event, props.data)
                          }
                          color="primary"
                          aria-label="edit"
                          size="small"
                        >
                          <i className="fas fa-edit" />
                        </Fab>
                      </Tooltip>
                    </div>
                  ),
                }}
                options={{
                  pageSize: 20,
                  pageSizeOptions: [10, 25, 50],
                  actionsColumnIndex: -1,
                  rowStyle: { fontSize: "0.9rem" },
                }}
                localization={{
                  header: {
                    actions: "Editar",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                }}
              />

              {/* <DataGrid
                
              /> */}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default DataTable;
