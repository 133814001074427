import React, { Component } from "react";

class PpBloquearAsisC extends Component {

    render() {

        return (
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-sm-12 col-md-6 col-lg-6 ">
                    <div className="card">
                        <div className="card-header">
                            Bloqueo de Asistencia
                        </div>
                        <div className="card-body px-4 text-center">
                            <h5 className="card-title">¿Desea bloquear la Asistencia para esta asamblea?</h5>
                            
                        </div>
                        <div className="card-footer">
                            <div className="row justify-content-center w-100">
                                <button className="btn btn-success col-4 col-md-4 col-lg-2 mx-1" onClick={() => this.props.GuardarAsamblea(0)}>Si</button>
                                <button className="btn btn-danger col-4 col-md-4 col-lg-2 mx-1" onClick={() => this.props.GuardarAsamblea(1)}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PpBloquearAsisC