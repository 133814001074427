import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TextField } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import Loader from "../../../principal/Loader";
import swal from "sweetalert";
import Global from "./../../../../Global";
import axios from "axios";
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import MaterialTable from 'material-table';
import marcaAguaImage from "./../../../../assets/images/NewLogoPH.png"
import CrearPDFBD from "./CrearPDFBD"
import TablaCorreos from "./TablaCorreos"
import moment from "moment";
import "moment/locale/es";



export default class BaseDatos extends Component {

    state = {
        PpHourglass: false,
        data: false,
        modalpdf: false,
        modaladd: false,
        modalcorreos: false,
        codigoph: "",
        nombre_ph: "",
        addcodigoph: "",
        addcodigopartner: "",
        DataBase: [],
        Row: []
    }

    componentWillMount() {
        this.SDateBase()
    }

    SDateBase = () => {
        this.setState({ data: false, modaladd: false })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigopartner: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}newbd/bdcreadas`, body).then((res) => {
            var row = []
            if (Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid))) {
                row = [
                    {
                        field: "codigo_bd",
                        title: "Código",
                        width: "10%",
                        render: data => <span className='fw-bold' onClick={() => {
                            swal({
                                title: `${data.nombre_ph}`,
                                text: `${data.nombre_bd}`,
                                icon: "success",
                                button: "Aceptar",
                            });
                        }}>{data.codigo_bd}</span>
                    },
                    {
                        field: "nombre_partner",
                        title: "Partner",
                        width: "15%",
                    },
                    {
                        field: "nombre_ph",
                        title: "Propiedad Horizontal",
                        width: "45%",
                    },
                    {
                        field: "fecha",
                        title: "Fecha",
                        render: data => moment(data.fecha).format("DD MMM YYYY HH:mm:ss"),
                        width: "15%",

                    },
                    {
                        field: "codigo_partner",
                        title: "Acciones",
                        cellStyle: { paddingRight: 0, paddingLeft: 0, },
                        align: "center",
                        render: data => <div>
                            <Tooltip title={"Descargar PDF"}>
                                <button className='btn btn-danger btn-sm '
                                    onClick={() => this.setState({ codigoph: data.codigo_bd, nombre_ph: data.nombre_ph }, () => { this.setState({ modalpdf: true }) })}>
                                    <i className="fa-solid fa-file-pdf"></i>
                                </button>
                            </Tooltip>
                            <Tooltip title={"Enviar Correos"}>
                                <button className='btn btn-primary btn-sm ms-2' onClick={() => this.ComprobarCorreos(data)}>
                                    <i className="fa-solid fa-paper-plane"></i>
                                </button>
                            </Tooltip>
                            <Tooltip title={"Descargar Suscriptores"}>
                                <button className='btn btn-success opacity-75 btn-sm ms-2' onClick={() => this.ArchivoSusc(data)}>
                                    <i className="fa-solid fa-file-arrow-down"></i>
                                </button>
                            </Tooltip>
                            <Tooltip title={"Infomar al Partner"}>
                                <button className='btn btn-info opacity-50 btn-sm ms-2' onClick={() => this.InfoPartner(data)}>
                                    <i class="fa-solid fa-comment-dots" style={{ color: "#ffffff", }} ></i>
                                </button>
                            </Tooltip>
                        </div>,
                    },
                ];
            } else {
                row = [
                    {
                        field: "codigo_bd",
                        title: "Código",
                        width: "10%",
                    },
                    {
                        field: "nombre_partner",
                        title: "Partner",
                        width: "15%",
                    },
                    {
                        field: "nombre_ph",
                        title: "Propiedad Horizontal",
                        width: "55%",
                    },
                    {
                        field: "fecha",
                        title: "Fecha",
                        render: data => moment(data.fecha).format("DD MMM YYYY HH:mm:ss"),
                        width: "15%",

                    },
                    {
                        field: "codigo_partner",
                        title: "Acciones",
                        cellStyle: { paddingRight: 0, paddingLeft: 0, },
                        align: "center",
                        render: data => <div>
                            <Tooltip title={"Descargar PDF"}>
                                <button className='btn btn-danger btn-sm '
                                    onClick={() => this.setState({ codigoph: data.codigo_bd, nombre_ph: data.nombre_ph }, () => { this.setState({ modalpdf: true }) })}>
                                    <i className="fa-solid fa-file-pdf"></i>
                                </button>
                            </Tooltip>
                            <Tooltip title={"Enviar Correos"}>
                                <button className='btn btn-primary btn-sm ms-2' onClick={() => this.ComprobarCorreos(data)}>
                                    <i className="fa-solid fa-paper-plane"></i>
                                </button>
                            </Tooltip>
                            <Tooltip title={"Descargar Suscriptores"}>
                                <button className='btn btn-success opacity-75 btn-sm ms-2' onClick={() => this.ArchivoSusc(data)}>
                                    <i className="fa-solid fa-file-arrow-down"></i>
                                </button>
                            </Tooltip>
                        </div>,
                    },
                ];
            }
            if (res.data.message === "") {
                this.setState({ DataBase: res.data.BD, Row: row },
                    () => { this.setState({ data: true, PpHourglass: false }) })
            } else {
                swal({
                    title: "Atención",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.props.onHide()
            });
        });

    }

    ComprobarCorreos = (data) => {
        if (parseInt(data.enviados) >= 2 && !Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid))) {
            swal({
                title: `${data.nombre_ph}`,
                text: `Si necesitas enviar más correos electrónicos de esta Propiedad Horizontal, por favor te invitamos a ponerte en contacto con nuestro amable equipo de soporte GOPH, ya que han alcanzado el límite máximo de envío.`,
                icon: "info",
                button: "Aceptar",
            })
        } else {
            swal({
                title: `${data.nombre_ph}`,
                text: `¿Desea enviar correos a esta propiedad horizontal?`,
                icon: "info",
                buttons: ["Cancelar", "Continuar"],

            }).then((willDelete) => {
                if (willDelete) {
                    this.TipoCorreo(data)
                    // this.setState({ PpHourglass: true })
                    // var body = {
                    //     ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                    //     codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
                    //     codigopartner: Buffer.from("" + data.codigo_bd).toString("base64"),
                    // };

                    // axios.post(`${Global.Ruta.Url}newbd/validarcorreos`, body).then((res) => {
                    //     this.setState({ PpHourglass: false })
                    //     if (res.data.message === "") {
                    //         this.TipoCorreo(data)
                    //     } else {
                    //         swal({
                    //             title: "Atención",
                    //             text: res.data.message,
                    //             icon: "info",
                    //             button: "Aceptar",
                    //         })
                    //     }
                    // }).catch((err) => {
                    //     swal({
                    //         title: "ERROR!",
                    //         text: "Por favor vuelva a intentarlo mas tarde.",
                    //         icon: "error",
                    //         button: "Aceptar",
                    //     }).then(() => {
                    //         this.props.onHide()
                    //     });
                    // });
                }
            });
        }
    }

    TipoCorreo = (data) => {
        swal({
            title: `${data.nombre_ph}`,
            icon: "success",
            text: `Seleccione el tipo de asamblea`,
            buttons: {
                cancel: "Cancelar",
                catch: { text: "Presencial", value: "catch", },
                defeat: "Virtual",
            },
        }).then((value) => {
            switch (value) {

                case "defeat":
                    this.SendCorreos(data, 1)
                    break;
                case "catch":
                    this.SendCorreos(data, 2)
                    break;
                default:
            }
        });
    }

    SendCorreos = (data, caso) => {
        this.setState({ PpHourglass: true })
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigopartner: Buffer.from("" + data.codigo_partner).toString("base64"),
            codigoph: Buffer.from("" + data.codigo_bd).toString("base64"),
            nombreph: Buffer.from("" + data.nombre_ph).toString("base64"),
            caso: Buffer.from("" + caso).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}newbd/enviocorreos`, body).then((res) => {
            this.setState({ PpHourglass: false })
            if (res.data.message === "") {
                swal({
                    title: "Atención",
                    text: "Correos enviados correctamente",
                    icon: "success",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ data: false }, () => {
                        this.SDateBase()
                    })
                })
            } else {
                if (parseInt(res.data.caso) === 1) {
                    swal({
                        title: "Atención",
                        text: `No se enviaron los correos a los siguientes suscriptores
                        
                        ${res.data.NoEnviados}`,
                        icon: "info",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ data: false }, () => {
                            this.SDateBase()
                        })
                    })
                } else {
                    swal({
                        title: "Atención",
                        text: res.data.message,
                        icon: "info",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ data: false }, () => {
                            this.SDateBase()
                        })
                    })
                }
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.props.onHide()
            });
        });
    }

    ArchivoSusc = (data) => {
        swal({
            title: `${data.nombre_ph}`,
            text: `¿Desea descargar el archivio  de esta propiedad horizontal?`,
            icon: "info",
            buttons: ["Cancelar", "Continuar"],
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ PpHourglass: true })
                var body = {
                    ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                    codigoph: Buffer.from("" + data.codigo_bd).toString("base64"),
                };
                axios.post(`${Global.Ruta.Url}newbd/archivosusc`, body).then((res) => {
                    this.setState({ PpHourglass: false })
                    if (res.data.message === "") {
                        swal(`Seleccione el tipo de archivo`, {
                            title: `${data.nombre_ph} `,
                            buttons: {
                                cancel: "Cancelar",
                                catch: {
                                    text: "Excel",
                                    value: "catch",
                                },
                                defeat: "PDF",
                            },
                        }).then((value) => {
                            switch (value) {
                                case "defeat":
                                    this.descargarCSV(res.data.Susc, data.nombre_ph, 1, data.codigo_bd)
                                    break;
                                case "catch":
                                    this.descargarCSV(res.data.Susc, data.nombre_ph, 2, data.codigo_bd)
                                    break;
                                default:
                            }
                        });
                    } else {
                        this.setState({ PpHourglass: false })
                        swal({
                            title: "Atención",
                            text: res.data.message,
                            icon: "info",
                            button: "Aceptar",
                        })
                    }
                }).catch((err) => {
                    swal({
                        title: "ERROR!",
                        text: "Por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.props.onHide()
                    });
                });
            }
        });
    }

    descargarCSV = (data, nombreph, caso, codigo) => {

        if (parseInt(caso) === 1) {
            const columns = [
                { title: 'NOMBRES', dataKey: 'NOMBRES' },
                { title: 'DIRECCIÓN', dataKey: 'DIRECCIÓN' },
                { title: 'LOGIN', dataKey: 'LOGIN' },
                { title: 'CLAVE', dataKey: 'CLAVE' },
            ];
            const rows = data.map(item => ({
                NOMBRES: item.nombres,
                DIRECCIÓN: item.direccion,
                LOGIN: item.login,
                CLAVE: item.clave,
            }));
            const doc = new jsPDF({ orientation: 'landscape' });

            const imgWidth = 20;
            const imgHeight = 20;
            doc.addImage(marcaAguaImage, 'PNG', 10, 0, imgWidth, imgHeight)

            doc.setFont('helvetica', 'bold');
            const textWidth = doc.getStringUnitWidth(codigo + " " + nombreph) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const centeredX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text(codigo + " " + nombreph, centeredX, 13);

            doc.autoTable({
                theme: 'grid',
                headStyles: { fillColor: [2, 117, 216] },
                columns,
                body: rows,
                startY: 20,
                content: 'Text', colSpan: 10, rowSpan: 5, styles: { halign: 'left', }, fontSize: 12,
            });
            doc.save(`${codigo} - ${nombreph}.pdf`);
        } else {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);
            const headers = [['NOMBRES', 'DIRECCIÓN', 'LOGIN', 'CLAVE']];
            XLSX.utils.sheet_add_aoa(ws, headers, { origin: 'A1' });
            const columnWidths = [
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 50 },
                { wpx: 50 },
                { wpx: 200 },
                { wpx: 200 },
            ];
            ws['!cols'] = columnWidths;
            XLSX.utils.book_append_sheet(wb, ws, "Suscriptores");
            XLSX.writeFile(wb, `${codigo} ${nombreph}.xlsx`);
        }
        this.setState({ PpHourglass: false });
    };

    VerificarPartnerBD = () => {

        this.setState({ PpHourglass: true });
        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(this.state.addcodigoph).toString("base64"),
            codigopartner: Buffer.from(this.state.addcodigopartner).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}newbd/validaraddcodigoph`, body).then((res) => {
            this.setState({ PpHourglass: false });
            if (res.data.message === "") {
                swal({
                    title: `${res.data.nombreph}`,
                    text: `¿Desea adicionar la Propiedad Horizontal al partner ${res.data.partner}?`,
                    icon: "info",
                    buttons: ["Cancelar", "Aceptar"],
                }).then((acepto) => {
                    if (acepto) {
                        this.GuardarPartner(this.state.addcodigoph, this.state.addcodigopartner, res.data.nombrebd, res.data.nombreph)
                    } else {
                        this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
                    }
                });
            } else {
                swal({
                    title: "Atención",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
            });
        });

    }

    GuardarPartner = (codigoph, codigopartner, nombrebd, nombreph) => {
        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + codigoph).toString("base64"),
            codigopartner: Buffer.from("" + codigopartner).toString("base64"),
            nombreph: Buffer.from("" + nombreph).toString("base64"),
            nombrebd: Buffer.from("" + nombrebd).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}newbd/anadirlognbd`, body).then((res) => {
            this.setState({ PpHourglass: false });
            if (res.data.message === "") {
                this.setState({ addcodigoph: "", addcodigopartner: "" }, () => {
                    this.SDateBase()
                });
            } else {
                swal({
                    title: "Atención",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
            });
        });
    }

    InfoPartner = (data) => {
        swal({
            title: `${data.nombre_ph}`,
            text: `Desea informar al partner ${data.nombre_partner}`,
            icon: "info",
            buttons: ["Cancelar", "Aceptar"],
        }).then((informar) => {
            if (informar) {
                this.setState({ PpHourglass: true });
                var body = {
                    ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
                    codigoph: Buffer.from("" + data.codigo_bd).toString("base64"),
                    nombreph: Buffer.from("" + data.nombre_ph).toString("base64"),
                };
                axios.post(`${Global.Ruta.Url}newbd/informarpartner`, body).then((res) => {
                    this.setState({ PpHourglass: false });
                    if (res.data.message === "") {
                        swal({
                            title: "Atención",
                            text: `Partner ${data.nombre_partner} informado.`,
                            icon: "info",
                            button: "Aceptar",
                        })
                    } else {
                        swal({
                            title: "Atención",
                            text: res.data.message,
                            icon: "info",
                            button: "Aceptar",
                        }).then(() => {
                            this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
                        });
                    }
                }).catch((err) => {
                    swal({
                        title: "ERROR!",
                        text: "Por favor vuelva a intentarlo mas tarde.",
                        icon: "error",
                        button: "Aceptar",
                    }).then(() => {
                        this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false });
                    });
                });
            }
        });
    }


    render() {

        if (this.state.data) {
            return (
                <div className='g-r-prf1 h-100'>
                    <Modal.Header closeButton>
                        <span className="fw-bold m-0">Bases de Datos</span>
                    </Modal.Header>

                    <Modal show={this.state.modalpdf} onHide={() => this.setState({ codigoph: "", nombre_ph: "", modalpdf: false })} centered backdrop="static" size="md">
                        <CrearPDFBD onHide={() => this.setState({ codigoph: "", nombre_ph: "", modalpdf: false })} codigoph={this.state.codigoph} nombre_ph={this.state.nombre_ph} />
                    </Modal>

                    <Modal show={this.state.modalcorreos} onHide={() => this.setState({ modalcorreos: false })} centered backdrop="static" fullscreen>
                        <TablaCorreos onHide={() => this.setState({ modalcorreos: false })} />
                    </Modal>

                    <Modal show={this.state.modaladd} onHide={() => this.setState({ addcodigoph: "", addcodigopartner: "", modaladd: false })} centered backdrop="static" size="md">
                        <Modal.Header closeButton>
                            <span className="fw-bold m-0"> Añadir Código PH</span>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='col-12'>
                                <TextField fullWidth placeholder="Código PH" variant="outlined" margin="dense"
                                    type="number"
                                    value={this.state.addcodigoph}
                                    onChange={(e) => this.setState({ addcodigoph: e.target.value })}
                                />
                            </div>
                            <div className='col-12 pt-2'>
                                <TextField fullWidth placeholder="Código Partner" variant="outlined" margin="dense"
                                    type="number"
                                    value={this.state.addcodigopartner}
                                    onChange={(e) => this.setState({ addcodigopartner: e.target.value })}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='d-flex justify-content-center'>
                            <button className='btn btn-primary' disabled={this.state.addcodigoph === "" || this.state.addcodigopartner === ""} onClick={() => this.VerificarPartnerBD()}>Adicionar</button>
                        </Modal.Footer>
                    </Modal>


                    <Modal.Body>
                        <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}><PpHourglass /></Modal>

                        <MaterialTable
                            title={
                                Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid)) ? (
                                    <div className='container'>
                                        <Tooltip title={"Añadir Bases de Datos"}>
                                            <button className='btn btn-primary btn-sm me-2' onClick={() => { this.setState({ modaladd: true }) }}>
                                                <i className="fa-solid fa-cloud"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title={"Correos Enviados"}>
                                            <button className='btn btn-success btn-sm' onClick={() => { this.setState({ modalcorreos: true }) }}>
                                                <i className="fa-solid fa-truck-fast"></i>
                                            </button>
                                        </Tooltip>
                                    </div>
                                ) : (false)
                            }
                            columns={this.state.Row}
                            data={this.state.DataBase}
                            options={{
                                pageSize: 5,
                                actionsColumnIndex: -1,
                                exportButton: false,
                                exportAllData: false,
                                rowStyle: (rowData) => {
                                    if (parseInt(rowData.tableData.id + 1) % 2 === 0
                                    ) {
                                        return {
                                            backgroundColor: "#eeeeee",
                                            fontSize: "0.9rem",
                                        };
                                    }

                                    return {
                                        backgroundColor: "#ffffff",
                                        fontSize: "0.9rem",
                                    };

                                },
                                headerStyle: {
                                    fontWeight: 'bold'
                                },
                            }
                            }
                            localization={{
                                header: {
                                    actions: "Estado",
                                },
                                body: {
                                    emptyDataSourceMessage: "No hay datos por mostrar.",
                                },
                                pagination: {
                                    labelRowsSelect: "registros",
                                    labelDisplayedRows: "{from}-{to} de {count}",
                                    firstTooltip: "Primera página",
                                    previousTooltip: "Página anterior",
                                    nextTooltip: "Próxima página",
                                    lastTooltip: "Última página",
                                },
                                toolbar: {
                                    searchTooltip: "Buscar",
                                    searchPlaceholder: "Buscar",
                                    exportCSVName: "CSV",
                                    exportPDFName: "PDF"
                                },
                            }}
                        />
                    </Modal.Body >

                </div >
            )
        }
        return (
            <div style={{ minHeight: "70vh" }}>
                <Loader princial={true} uno={"Data Base"} dos={"Códigos"} tres={"Usuarios"} />
            </div>
        );


    }
}