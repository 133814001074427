import React, { Component } from 'react'
import "./../../assets/css/Loader.css"


export default class Loader extends Component {

    render() {

        if (this.props.princial === true) {
            return (
                <div className="spinnerContainer d-flex align-items-center justify-content-center " style={{minHeight: "90vh"}}>
                    <div className="spinner"></div>
                    <div className="loader">
                        <p>Cargando</p>
                        <div className="words">
                            <span className="word"></span>
                            <span className="word">{this.props.uno}</span>
                            <span className="word">{this.props.dos}</span>
                            <span className="word">{this.props.tres}</span>
                            <span className="word">GoPH</span>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.princial === false) {
            return (
                <div className="dot-spinner">
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                </div>
            )
        }

    }
}