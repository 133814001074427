import React from "react";
import axios from "axios";
import Global from "../../../Global";
import Cookie from "universal-cookie";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CookieSesion, capitalizeFirstLetter } from "../../Helpers";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
//import {withStyles} from "@material-ui/core/styles";
import { blue, grey, common } from "@mui/material/colors";

import { setCookieP, setCookieD } from "../../Helpers";
import {
  OutlinedInput,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  createTheme,
  ThemeProvider,
  MenuItem,
} from "@mui/material";
import { Button } from "react-bootstrap";

const cookies = new Cookie();

//------------------------------------------Opciones del Dropdown(seleccionador)---------------------------
const estado = [
  {
    value: "1",
    label: "Activo",
  },
  {
    value: "0",
    label: "Inactivo",
  },
];

//---------------------------------------------------------------------------------------------------------

//------------------------------------------------COLORES---------------------------------------------------
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: blue[400],
      light: common.white,
    },
    secondary: {
      main: grey[500],
      light: common.white,
    },
    btsp_prim: {
      main: "#0d6efd",
      light: common.white,
      contrastText: common.white,
    },
    background: {
      default: "#ffffff",
      paper: "#fbfafa",
    },
  },
});

//---------------------------------------------------------------------------------------------------------
class FormSusc extends React.Component {
  state = {
    opcion: 0,
    usertipo: 0,

    iduser: 0,
    nombre: "",
    login: "",
    estado: "",
    rol: "",
    rondero: undefined,
    passwordMain: "",
    passwordMensajeria: "",
    titulo: "",

    DataRol: [],
    Rondero: [{
      value: 0,
      label: "No"
    }, {
      value: 1,
      label: "Si"
    }],
    CpasswordMain: "",
    CpasswordMensajeria: "",
    showPassword1: false,
    showPassword2: false,

    //redirects
    redirect_home: false,
    redirect_login: 0,
    redirect_usuarios: false,
    data: false,

    showPassword: false,
    modalPasswrd: false,
    password: "",

    Roles: [1, 2],
  };

  componentWillMount() {
    if (
      Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid))
    ) {
      this.setState({
        DataRol: [
          {
            value: "1",
            label: "Administrador GoPH",
          },
          {
            value: "2",
            label: "Administrador",
          },
          {
            value: "3",
            label: "Call Center",
          },
          {
            value: "4",
            label: "Portero",
          },
        ],
      });
    } else {
      this.setState({
        DataRol: [
          {
            value: "2",
            label: "Administrador",
          },
          {
            value: "4",
            label: "Portero",
          },
        ],
      });
    }

    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(parseInt(Global.GlobalUser.rol))) {
          if (parseInt(Global.Form.tipo) === 2 || parseInt(Global.Form.tipo) === 3) {
            // tipo 1 = CREAR - tipo 2= EDITAR
            this.setState({
              opcion: Global.Form.tipo,
              iduser: parseInt(Global.Form.id),
              nombre: Global.Form.nombre,
              login: Global.Form.login,
              rol: Global.Form.rol,
              estado: Global.Form.estado,
              rondero: Global.Form.rondero,
              titulo: "Modificar usuario",
              data: true,
            });
          } else {
            this.setState({
              opcion: Global.Form.tipo,
              titulo: "Crear usuario",
              data: true,
            });
          }
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  //----------------------------------------------BEGIN FUNCTION--------------------------------------------------
  // ACTUALIZA EL ESTADO CADA VEZ QUE SE OPRIMA UNA TECLA
  handleChange = (prop) => (event) => {
    this.setState((state) => ({
      ...state,
      [prop]: event.target.value,
    }));
  };
  // EL ESTADO PARA VER LAS CLAVES
  EstadoVerClaveMaestra = () => {
    this.setState((state) => ({
      showPassword1: !state.showPassword1,
    }));
  };
  EstadoVerClaveMensajeria = () => {
    this.setState((state) => ({
      showPassword2: !state.showPassword2,
    }));
  };
  //PREVENIR EL EVENTO AL OPRIMIR EN EL OJO DE VER CONTRASEÑA
  OprimirVerContrasena = (event) => {
    event.preventDefault();
  };
  EstadoVerClaveValidacion = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };
  //ACTUALIZAR ESTADO DE LOS COMBOBOX
  Combobox = (prop) => (e) => {
    this.setState({
      [prop]: e.target.value,
    });
  };
  // VALIDACIÓN DE CLAVES IGUALES PARA HABILITAR EL BOTON DE REGISTRAR
  ClavesIguales = () => {
    switch (this.state.opcion) {
      case 1:
        if (
          this.state.passwordMain === this.state.CpasswordMain &&
          this.state.passwordMensajeria === this.state.CpasswordMensajeria &&
          this.state.CpasswordMensajeria !== "" &&
          this.state.CpasswordMain !== ""
        ) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (
          this.state.passwordMain === this.state.CpasswordMain &&
          this.state.passwordMensajeria === this.state.CpasswordMensajeria
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return false;
    }
  };
  // VALIDACIÓN DE CLAVES IGUALES
  validarClaveM = (e) => {
    switch (e) {
      case 1:
        if (
          this.state.passwordMain === this.state.CpasswordMain ||
          this.state.CpasswordMain === ""
        ) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (
          this.state.passwordMain === this.state.CpasswordMain ||
          this.state.CpasswordMain === ""
        ) {
          return "";
        } else {
          return "Las contraseñas no coinciden.";
        }
      default:
        return;
    }
  };
  validarClaveMe = (e) => {
    switch (e) {
      case 1:
        if (
          this.state.passwordMensajeria === this.state.CpasswordMensajeria ||
          this.state.CpasswordMensajeria === ""
        ) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (
          this.state.passwordMensajeria === this.state.CpasswordMensajeria ||
          this.state.CpasswordMensajeria === ""
        ) {
          return "";
        } else {
          return "Las contraseñas no coinciden.";
        }
      default:
        return;
    }
  };
  requerirClaves = () => {
    switch (this.state.opcion) {
      case 1:
        return true;
      case 2:
        return false;
      default:
        return true;
    }
  };
  rolesData = () => {
    switch (parseInt(this.state.rol)) {
      case 1:
        return "Administrador GoPH";

      case 2:
        return "Administrador";
      case 3:
        return "Call Center";
      case 4:
        return "Portero";
      default:
        return "";
    }
  };
  //FUNCIÓN ENCARGADA DE ENVIAR EL FORMULARIO
  Enviarformulario = (e) => {
    e.preventDefault();

    this.setState({ data: false });

    var body = {};
    var user = Global.GlobalUser.UserLog;

    var body1 = {
      password: Buffer.from(this.state.password).toString("base64"),
      userid: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
    };

    if (this.state.opcion === 1) {
      axios
        .post(Global.Ruta.Url + "mensajeria/pwdmensajeria/", body1)
        .then((resp) => {
          if (resp.data.message === "") {
            body = {
              ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
              codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
              usernom: Buffer.from(this.state.nombre.toUpperCase()).toString(
                "base64"
              ),
              userlogin: Buffer.from(
                capitalizeFirstLetter(this.state.login).trim()
              ).toString("base64"),
              primeraclave: Buffer.from(this.state.passwordMain).toString(
                "base64"
              ),
              segundaclave: Buffer.from(this.state.passwordMensajeria).toString(
                "base64"
              ),
              estado: Buffer.from(this.state.estado).toString("base64"),
              rol: Buffer.from(this.state.rol).toString("base64"),
              caso: Buffer.from("2").toString("base64"),
              user: Buffer.from(user).toString("base64"),
              rondero: Buffer.from(`${this.state.rondero}`).toString("base64"),
            };

            if (
              parseInt(this.state.rol) === 1 ||
              parseInt(this.state.rol) === 3
            ) {
              body.caso = Buffer.from("1").toString("base64");
              this.InsertarUsuario(body);
            } else {
              this.InsertarUsuario(body);
            }
          } else {
            swal({
              title: "ALERTA!",
              text: "Contraseña incorrecta.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ data: true, modalPasswrd: false });
            });
          }
        })
        .catch((err) => {
          swal({
            title: "ALERTA!",
            text: "61010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ data: true, modalPasswrd: false });
          });
        });
    } else if (this.state.opcion === 2 || this.state.opcion === 3) {
      var clave1 = this.state.passwordMain;
      var clave2 = this.state.passwordMensajeria;

      if (this.state.passwordMain === "") {
        clave1 = "NA";
      }
      if (this.state.passwordMensajeria === "") {
        clave2 = "NA";
      }

      axios
        .post(Global.Ruta.Url + "mensajeria/pwdmensajeria/", body1)
        .then((resp) => {
          if (resp.data.message === "") {
            body = {
              ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
              codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
              userid: Buffer.from("" + this.state.iduser).toString("base64"),
              usernom: Buffer.from(this.state.nombre.toUpperCase()).toString(
                "base64"
              ),
              userlogin: Buffer.from(
                capitalizeFirstLetter(this.state.login).trim()
              ).toString("base64"),
              primeraclave: Buffer.from(clave1).toString("base64"),
              segundaclave: Buffer.from(clave2).toString("base64"),
              estado: Buffer.from(this.state.estado).toString("base64"),
              rol: Buffer.from("" + this.state.rol).toString("base64"),
              loginold: Buffer.from(Global.Form.login).toString("base64"),
              nombreold: Buffer.from(Global.Form.nombre).toString("base64"),
              user: Buffer.from(user).toString("base64"),
              rondero: Buffer.from(`${this.state.rondero}`).toString("base64"),
            };
            this.EditarUsuario(body);
          } else {
            swal({
              title: "ALERTA!",
              text: "Contraseña incorrecta.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ data: true, modalPasswrd: false });
            });
          }
        })
        .catch((err) => {
          swal({
            title: "ALERTA!",
            text: "61010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ data: true, modalPasswrd: false });
          });
        });
    }
  };
  /*
    CrearUser = () => {
  
      var user = Global.GlobalUser.UserLog;
  
      axios.get(Global.Ruta.Url + "mensajeria/pwdmensajeria/" + Buffer.from(this.state.password).toString("base64") + "/" + Buffer.from("" + Global.GlobalUser.userid).toString("base64")).then(resp => {
        if (resp.data.message === "") {
          var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
            codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
            usernom: Buffer.from(this.state.nombre.toUpperCase()).toString('base64'),
            userlogin: Buffer.from(capitalizeFirstLetter(this.state.login).trim()).toString('base64'),
            primeraclave: Buffer.from(this.state.passwordMain).toString('base64'),
            segundaclave: Buffer.from(this.state.passwordMensajeria).toString('base64'),
            estado: Buffer.from(this.state.estado).toString('base64'),
            rol: Buffer.from(this.state.rol).toString('base64'),
            caso: Buffer.from("2").toString('base64'),
            user: Buffer.from(user).toString('base64')
          };
  
          if (parseInt(this.state.rol) === 1 || parseInt(this.state.rol) === 3) {
            body.caso = Buffer.from("1").toString('base64');
            this.InsertarUsuario(body)
          } else {
            this.InsertarUsuario(body)
          }
        } else {
          swal({
            title: "ALERTA!",
            text: "Contraseña incorrecta.",
            icon: "error",
            button: "Aceptar"
          }).then(() => {
            this.setState({ data: true, modalPasswrd: false })
          });
        }
      }).catch(err => {
        swal({
          title: "ALERTA!",
          text: "61010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar"
        }).then(() => {
          this.setState({ data: true, modalPasswrd: false })
        });
      })
    }*/

  InsertarUsuario = (body) => {
    axios
      .post(Global.Ruta.Url + "admin/insertuser", body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text: "El usuario se ha creado correctamente.",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_usuarios: true });
          });
        } else if (res.data.message === "ERROR0") {
          swal({
            title: "ERROR!",
            text: "El usuario no se ha creado, por favor cambiar el usuario.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ data: true });
          });
        } else if (res.data.message === "ERROR1") {
          swal({
            title: "ERROR!",
            text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_usuarios: true });
          });
        } else {
          swal({
            title: "ERROR!",
            text: res.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_usuarios: true });
        });
      });
  };

  EditarUsuario = (body) => {
    axios
      .post(Global.Ruta.Url + "admin/edituser", body)
      .then((res) => {
        if (res.data.message === "") {
          swal({
            title: "ATENCIÓN!",
            text: "El usuario se ha modificado correctamente.",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            if (this.state.opcion === 3) {
              Global.GlobalUser.Nombre = this.state.nombre.toUpperCase();
              cookies.remove("Nombre", { path: "/" });
              if (Global.Plataforma.Valor === 1) {
                setCookieD("Nombre", this.state.nombre.toUpperCase(), 1);
              } else if (Global.Plataforma.Valor === 2) {
                setCookieP("Nombre", this.state.nombre.toUpperCase(), 1);
              }
              this.setState({ redirect_login: 1 });
            } else {
              this.setState({ redirect_usuarios: true });
            }
          });
        } else if (res.data.message === "ERROR0") {
          swal({
            title: "ERROR!",
            text: "El usuario no se pudo modificar, por favor cambiar el usuario.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ data: true });
          });
        } else if (res.data.message === "ERROR1") {
          swal({
            title: "ERROR!",
            text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_usuarios: true });
          });
        } else {
          swal({
            title: "ERROR!",
            text: res.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({ redirect_usuarios: true });
        });
      });
  };

  EstadoVerClave = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  //----------------------------------------------END FUNCTIONS--------------------------------------------------------

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.redirect_usuarios === true) {
      return <Redirect to={Global.Ruta.Raiz + "/usuarios"} />;
    }

    /*const FormButton = withStyles((theme) => ({
      root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
          backgroundColor: blue[700],
        },
      },
    }))(Button);*/

    if (this.state.data === true) {
      return (
        <div className="container-fluid bg-light">
          <ThemeProvider theme={theme}>
            {/* MODAL DE CONFIRMACIÓN DE ACCIONES */}

            <Modal
              show={this.state.modalPasswrd}
              centered
              backdrop="static"
              onHide={() => this.setState({ modalPasswrd: false })}
            >
              <Modal.Header closeButton>
                {parseInt(Global.GlobalUser.rol) === 1
                  ? `Atención`
                  : `Confirmar Contraseña`}
              </Modal.Header>
              <Modal.Body className="row justify-content-center">
                <div className="col-12">
                  <h6>
                    {parseInt(Global.GlobalUser.rol) === 1
                      ? `Esta seguro que quiere crear un usuario ${this.rolesData()}, por favor digite la clave de lo contrario por favor cierre la ventana y elija el rol adecuado.`
                      : ``}
                  </h6>
                </div>
                <div className="col-6 d-flex justify-content-center">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Contraseña mensajeria</InputLabel>
                    <OutlinedInput
                      id="constraseña"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.EstadoVerClaveValidacion}
                            onMouseDown={this.OprimirVerContrasena}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Contraseña mensajeria"
                      color="primary"
                    />
                  </FormControl>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary text-white text-uppercase fw-bold"
                  onClick={this.Enviarformulario}
                >
                  Guardar Cambios
                </button>
              </Modal.Footer>
            </Modal>

            {/* Formulario */}

            <div className="col-12 h-100">
              <div className="row pt-4 overflow-hidden h-100">
                <div className="col-12 col-md-10 col-lg-11 col-xl-6 mx-auto pb-3">
                  <div className="card p-3 shadow-lg">
                    <div className="row justify-content-center">
                      <div className="col-12 text-center">
                        <div className="titulo">
                          {this.state.titulo}{" "}
                          <small className="text-muted">
                            {this.state.nombre}
                          </small>
                        </div>
                        <div className="texto-descriptivo text-muted">
                          Las contraseñas deben contener mínimo 6 caracteres y
                          repetirla en la casilla{" "}
                          <span className="col p-0 cursiva-negrilla">
                            CONFIRMAR.
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row justify-content-center text-nowrap">
                      <Box noValidate autoComplete="off">
                        <Box
                          className="col-12"
                          component="form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.setState({ modalPasswrd: true });
                          }}
                        >
                          {/* NOMBRES Y LOGIN */}
                          <div className="row pb-3">
                            <div className="col">
                              <TextField
                                className="shadow-sm"
                                size="small"
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Nombres"
                                name="nombres"
                                variant="outlined"
                                defaultValue={this.state.nombre}
                                onChange={this.handleChange("nombre")}
                              />
                            </div>
                            <div className="col">
                              <TextField
                                className="shadow-sm"
                                size="small"
                                required
                                fullWidth
                                id="login"
                                label="Usuario"
                                name="login"
                                variant="outlined"
                                defaultValue={this.state.login}
                                onChange={this.handleChange("login")}
                              />
                            </div>
                          </div>

                          {/* ROL Y ESTADO */}
                          <div className="row pb-3 gy-3">
                            <div className="col">
                              <TextField
                                size="small"
                                disabled={
                                  parseInt(Global.GlobalUser.userid) ===
                                    this.state.iduser
                                    ? true
                                    : false
                                }
                                className="shadow-sm"
                                fullWidth
                                required
                                select
                                label="Seleccione su rol"
                                defaultValue={this.state.rol}
                                value={this.state.rol}
                                onChange={this.Combobox("rol")}
                                variant="outlined"
                              >
                                {this.state.DataRol.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            {parseInt(this.state.rol) === 4 && (
                              <div className="col">
                                <TextField
                                  size="small"
                                  disabled={
                                    parseInt(Global.GlobalUser.userid) ===
                                      this.state.iduser
                                      ? true
                                      : false
                                  }
                                  className="shadow-sm"
                                  fullWidth
                                  required
                                  select
                                  label="Rondero"
                                  defaultValue={this.state.rondero}
                                  value={this.state.rondero}
                                  onChange={this.Combobox("rondero")}
                                  variant="outlined"
                                >
                                  {this.state.Rondero.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            )
                            }
                            <div className="col-12">
                              <TextField
                                id="estado"
                                size="small"
                                disabled={
                                  parseInt(Global.GlobalUser.userid) ===
                                    this.state.iduser
                                    ? true
                                    : false
                                }
                                className="shadow-sm"
                                fullWidth
                                required
                                select
                                label="Seleccione el estado de la cuenta"
                                defaultValue={this.state.estado}
                                value={this.state.estado}
                                onChange={this.Combobox("estado")}
                                variant="outlined"
                              >
                                {estado.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          {/* CONTRASEÑA DE ACCESO Y CONFIRMAR */}
                          <div className="row">
                            <div className="col">
                              <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                              >
                                <InputLabel required={this.requerirClaves()}>
                                  Contraseña de acceso
                                </InputLabel>
                                <OutlinedInput
                                  id="constraseña"
                                  type={
                                    this.state.showPassword1
                                      ? "text"
                                      : "password"
                                  }
                                  className="shadow-sm"
                                  value={this.state.passwordMain}
                                  onChange={this.handleChange("passwordMain")}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.EstadoVerClaveMaestra}
                                        onMouseDown={this.OprimirVerContrasena}
                                        edge="end"
                                      >
                                        {this.state.showPassword1 ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Contraseña de acceso"
                                  variant="filled"
                                  color="primary"
                                />
                              </FormControl>
                            </div>
                            <div className="col">
                              <FormControl
                                error={this.validarClaveM(1)}
                                fullWidth
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "red" }}
                              >
                                <InputLabel required={this.requerirClaves()}>
                                  Confirmar
                                </InputLabel>
                                <OutlinedInput
                                  id="c-contraseña-main"
                                  className="shadow-sm"
                                  type={
                                    this.state.showPassword1
                                      ? "text"
                                      : "password"
                                  }
                                  value={this.state.CpasswordMain}
                                  onChange={this.handleChange("CpasswordMain")}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.EstadoVerClaveMaestra}
                                        onMouseDown={this.OprimirVerContrasena}
                                        edge="end"
                                      >
                                        {this.state.showPassword1 ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Confirmar"
                                  variant="filled"
                                />
                              </FormControl>
                            </div>
                            <div className="col-12 text-center">
                              {this.validarClaveM(1) ? (
                                <p
                                  className="m-0 fw-danger"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  {this.validarClaveM(2)}
                                </p>
                              ) : (
                                <p
                                  className="m-0 fw-normal"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  Contraseña para ingresar a la plataforma GoPH
                                </p>
                              )}
                            </div>
                          </div>

                          <hr
                            className="mt-4 mb-3"
                            style={{ height: "0.1rem" }}
                          />

                          {/* CONTRASEÑA SMS Y CONFIRMAR */}
                          <div className="card bg-secondary bg-opacity-25 shadow p-2">
                            <div className="row py-sm-1 py-2">
                              <div className="col">
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                >
                                  <InputLabel
                                    required={this.requerirClaves()}
                                    className="flex-nowrap"
                                  >
                                    Contraseña enviar mensajes
                                  </InputLabel>
                                  <OutlinedInput
                                    id="contraseña-maestra"
                                    className="bg-light rounded"
                                    type={
                                      this.state.showPassword2
                                        ? "text"
                                        : "password"
                                    }
                                    value={this.state.passwordMensajeria}
                                    onChange={this.handleChange(
                                      "passwordMensajeria"
                                    )}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            this.EstadoVerClaveMensajeria
                                          }
                                          onMouseDown={
                                            this.OprimirVerContrasena
                                          }
                                          edge="end"
                                        >
                                          {this.state.showPassword2 ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Contraseña enviar mensajes"
                                    variant="filled"
                                  />
                                </FormControl>
                              </div>
                              <div className="col">
                                <FormControl
                                  error={this.validarClaveMe(2)}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                >
                                  <InputLabel required={this.requerirClaves()}>
                                    Confirmar
                                  </InputLabel>
                                  <OutlinedInput
                                    id="c-contraseña-maestra"
                                    className="bg-light rounded"
                                    type={
                                      this.state.showPassword2
                                        ? "text"
                                        : "password"
                                    }
                                    value={this.state.CpasswordMensajeria}
                                    onChange={this.handleChange(
                                      "CpasswordMensajeria"
                                    )}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            this.EstadoVerClaveMensajeria
                                          }
                                          onMouseDown={
                                            this.OprimirVerContrasena
                                          }
                                          edge="end"
                                        >
                                          {this.state.showPassword2 ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="contraseña"
                                    variant="filled"
                                  />
                                </FormControl>
                              </div>
                              <div className="col-12 text-center">
                                <div className="col-12 text-center">
                                  {this.validarClaveMe(1) ? (
                                    <p
                                      className="m-0 fw-danger"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {this.validarClaveMe(2)}
                                    </p>
                                  ) : (
                                    <p
                                      className="m-0 fw-normal"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      Contraseña para enviar mensajes y
                                      notificaciones push a los residentes
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 justify-content-center">
                            <div className="col-6 col-md-3 d-grid">
                              <Button
                                className="btn btn-primary text-capitalize"
                                variant="contained"
                                onClick={() => {
                                  this.setState({ redirect_usuarios: true });
                                }}
                              >
                                {" "}
                                <i className="fas fa-chevron-left me-1"></i>{" "}
                                Regresar
                              </Button>
                            </div>
                            <div className="col-6 col-md-3 d-grid">
                              <button
                                className="btn btn-success text-capitalize px-3"
                                disabled={this.ClavesIguales()}
                                type="submit"
                              >
                                {" "}
                                <i className="fas fa-save me-1"></i> Registrar
                                cambios
                              </button>
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default FormSusc;
