import React, {Component} from "react";
import axios from "axios";
import {styled} from "@material-ui/core";
import Global from "../../../Global";
import {CookieSesion} from "../../Helpers";
import swal from "sweetalert";
import {NavLink, Redirect} from "react-router-dom";
import HeadTitle from "./../../principal/HeadTitle";

class ArchivoReceptor extends Component {
  state = {
    //inicialmente, no hay archivo
    selectedFile: null,
    uploading: false,

    //redirect
    redirect_login: 0,
    redirect_revarchivos: false,
    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    if (this.state.Roles.includes(Global.GlobalUser.rol)) {
    } else {
      this.setState({redirect_home: true});
    }

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
      } else {
        this.setState({redirect_login: 1});
      }
    } else {
      this.setState({redirect_login: 1});
    }
  }

  // seleccionar archivo (popup del sistema)
  onFileChange = (event) => {
    var resp = this.filterFile(event.target.files[0]);

    switch (resp) {
      case 0:
        this.setState({selectedFile: event.target.files[0]});
        break;
      case 1:
        swal({
          title: "ATENCIÓN !!",
          text: "El archivo excede el tamaño permitido de 2MB.",
          icon: "error",
        }).then((res) => {
          this.setState({selectedFile: null});
        });
        break;
      case 2:
        swal({
          title: "ATENCIÓN !!",
          text: "El archivo no puede ser subido porque no es un .pdf",
          icon: "error",
        }).then((res) => {
          this.setState({selectedFile: null});
        });
        break;
      default:
        swal({
          title: "ATENCIÓN !!",
          text: "Error, No ha seleccionado un archivo.",
          icon: "error",
        }).then((res) => {
          this.setState({selectedFile: null});
        });
        break;
    }
  };

  //caja para arrastrar
  onFileDrop = (event) => {
    event.preventDefault();

    var resp = this.filterFile(event.target.files[0]);

    switch (resp) {
      case 0:
        this.setState({selectedFile: event.target.files[0]});
        break;
      case 1:
        swal({
          title: "ATENCIÓN !!",
          text: "El archivo excede el tamaño permitido de 2MB.",
          icon: "error",
        }).then((res) => {
          this.setState({selectedFile: null});
        });
        break;
      case 2:
        swal({
          title: "ATENCIÓN !!",
          text: "El archivo no puede ser subido porque no es un .pdf",
          icon: "error",
        }).then((res) => {
          this.setState({selectedFile: null});
        });
        break;
      default:
        swal({
          title: "ATENCIÓN !!",
          text: "Error, No ha seleccionado un archivo.",
          icon: "error",
        }).then((res) => {
          this.setState({selectedFile: null});
        });
        break;
    }
  };

  filterFile = (ccFile) => {
    if (ccFile !== undefined) {
      if (ccFile.type === "application/pdf") {
        if (ccFile.size <= 2000000) {
          return 0;
        } else {
          return 1;
        }
      } else {
        return 2;
      }
    } else {
      return 3;
    }
  };

  //El contenido del archivo se mostrará después
  //se subio el archivo
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div className="contenedorInfoDoc">
          <h2>Detalles del archivo</h2>
          <span>Nombre:</span>
          <p className="tituloDoc fw-normal">{this.state.selectedFile.name}</p>
          <span>Tipo:</span> <p>{this.state.selectedFile.type}</p>
          <span>Fecha de modificación:</span>
          <p>
            {" "}
            {new Date(this.state.selectedFile.lastModified).toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h6 className="m-0 fw-normal">No hay archivo PDF para publicar.</h6>
        </div>
      );
    }
  };

  //boton de subidir archivo
  onFileUpload = () => {
    if (this.state.selectedFile) {
      this.setState({
        uploading: true,
      });
      document.querySelector("#drop-area");

      // crear un objeto FormData
      const formData = new FormData();

      // actualizar el objeto FormData
      formData.append(
        "uploadpdf",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      //consulta hecha para el backend API
      //enviar al objeto formData
      axios
        .post(
          Global.Ruta.Url +
            "admin/subirarchivo/" +
            Buffer.from(Global.Ph.Codigo).toString("base64") +
            "/",
          formData,
          {headers: {"Content-Type": "multipart/form-data"}}
        )
        .then((res) => {
          if (res.data.message === "") {
            swal({
              title: "ATENCIÓN !!",
              text: "El archivo se subió correctamente.",
              icon: "success",
            }).then((res1) => {
              this.setState({
                selectedFile: null,
                uploading: false,
              });
            });
          } else {
            swal({
              title: "ATENCIÓN !!",
              text: "El archivo no pudo ser cargado correctamente, por favor revise el formato del archivo.  Se requiere un archivo tipo PDF.",
              icon: "error",
            }).then((res) => {
              this.setState({selectedFile: null, uploading: false});
            });
          }
        });
    } else {
      swal({
        title: "ATENCIÓN !!",
        text: "Error, No ha seleccionado un archivo.",
        icon: "error",
      }).then((res) => {
        this.setState({selectedFile: null, uploading: false});
      });
    }
  };

  render() {
    const Input = styled("input")({
      display: "none",
    });

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.uploading === false) {
      return (
        <div className="container-fluid h-100 bg-light overflow-auto">
          <div className="row h-100 py-3 gy-3">
            <div className="col-12">
              <HeadTitle
                titulo="Publicar Archivo"
                subTitulo='Enviar documentos y comunicaciones a la comunidad'
                classNameOwn="row px-3"
                indicadores={[]}
                actionButton={
                  <div className="col-auto my-2 d-flex justify-content-end">
                    <div className="row row-cols-2 gx-1">
                      <div className="col-auto">
                        <NavLink
                          className="btn btn-outline-primary"
                          exact
                          to={Global.Ruta.Raiz + "/revisar-archivo"}
                        >
                          Ver Historial
                        </NavLink>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="col-12">
              <div className='row justify-content-center align-items-center'>
                <div className='col-12 col-md-6'>
                  <div className="card p-3 shadow">
                    <div className="card-body">
                      <div className="row row-cols-1 gy-4 justify-content-center">
                        <div className="col">
                          <div className="card mx-auto text-center  position-relative border-a-1 border-secondary">
                            <div className="card-body">
                              <div className="row gy-2 justify-content-center">
                                <div className="col-12 d-none d-lg-block">
                                  <h3 className="text-center m-0">
                                    Arrastre y suelte aquí el archivo que desea subir ...
                                  </h3>
                                </div>
                                <div className="col-12 text-center">
                                  <span className="spanO"> o </span>
                                </div>
                                <div className="col-auto">
                                  <input
                                    id="drop-area"
                                    accept="application/pdf"
                                    type="file"
                                    className="top-50 start-50 translate-middle"
                                    onChange={this.onFileDrop}
                                  />
                                  <label htmlFor="contained-button-file">
                                    <Input
                                      id="contained-button-file"
                                      accept="application/pdf"
                                      type="file"
                                      onChange={this.onFileChange}
                                    />
                                    <button
                                      className="btn btn-primary text-nowrap"
                                      variant="contained"
                                      component="span"
                                    >
                                      Seleccionar archivo...
                                    </button>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="card bg-secondary bg-opacity-50 text-center">
                          <div className="card-body">
                          {this.fileData()}
                          </div>
                          </div>
                        </div>
                        <div className="col-auto d-grid">
                        <button
                          className="btn btn-primary"
                          onClick={this.onFileUpload}
                        >
                          Publicar archivo
                        </button>
                      </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default ArchivoReceptor;
