import React, { Component } from "react";
import axios from "axios";
import { NavLink, Redirect } from "react-router-dom";
import Cookie from "universal-cookie";
import { Modal } from "react-bootstrap";
import { TextField } from "@mui/material";

import swal from "sweetalert";

import { CambiarUserPorteria } from "../../../Helpers";
import MyPhModal from "../MyPhModal";
import CrearBD from "../compos/CrearBD"
import CrearPDFBD from "../compos/CrearPDFBD"
import BaseDatos from "../compos/BaseDatos"
import Global from "../../../../Global";


const cookies = new Cookie();

export default class NavHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            NombrePH: "",
            NombreUser: "",
            CodPH: "",
            redirect_login: 0,
            confi: 0,
            data: false,
            slc_perf: "",

            porteros: [],
            Nombre: "",
            Direccion: "",
            Login: "",

            Ing: [2, 25, 24],
            rol: 2,
            // modal
            modal: false,
            modal1: false,
            modalbd: false,
            modalpdf: false,
            modaldatabase: false
        };
    }

    componentWillMount() {
        this.setState({
            NombrePH: Global.Ph.Nombre,
            NombreUser: Global.GlobalUser.Nombre,
            CodPH: Global.Ph.Codigo,
            NombreUserLimit: Global.GlobalUser.Nombre.substr(0, 18),
            rol: Global.GlobalUser.rol
        });

        var body = {};

        if (parseInt(Global.GlobalUser.rol) === 4) {
            body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                porteroid: Buffer.from(Global.GlobalUser.userid).toString("base64"),
            };

            axios.post(`${Global.Ruta.Url}porteria/listportero`, body).then((res) => {
                if (res.data.message === "") {
                    this.setState({
                        porteros: res.data.Usuarios,
                    });
                }
            }).catch((err) => {

            });
        }
    }

    CambiarUsuario = (id, nombre, login) => {
        swal({
            title: "ATENCIÓN!!",
            text: `QUIERE CAMBIAR ALUSUARIO ${nombre}`,
            content: {
                element: "input",
                attributes: {
                    placeholder: "Ingrese su Contraseña",
                    type: "password",
                },
            },
            icon: "warning",
            buttons: ["CANCELAR", "ACEPTAR"],
        }).then((result) => {
            if (result) {
                this.CerrarSesionTem();
                CambiarUserPorteria(Global.Ph.Codigo, login, result);
                setTimeout(() => {
                    this.setState({ slc_perf: id, redirect_login: 1 });
                }, 600);
            }
        });
    };

    CerrarSesion = (e) => {
        cookies.remove("Sesion", { path: "/" });
        cookies.remove("UserId", { path: "/" });
        cookies.remove("Nombre", { path: "/" });
        cookies.remove("Rol", { path: "/" });
        cookies.remove("PhCodigo", { path: "/" });
        cookies.remove("Conjunto", { path: "/" });
        cookies.remove("Nitph", { path: "/" });
        cookies.remove("Ciudad", { path: "/" });
        cookies.remove("Tipo", { path: "/" });
        cookies.remove("Rondero", { path: "/" });
        cookies.remove("Ciudad", { path: "/" });
        this.setState({ redirect_login: 1 });
        Global.NotifHome.View = false;
        Global.NotifHome.FirtsEntry = false;
        Global.NotifHome.Pendientes = [];
    };

    CerrarSesionTem = (e) => {
        cookies.remove("Sesion", { path: "/" });
        cookies.remove("UserId", { path: "/" });
        cookies.remove("Nombre", { path: "/" });
        cookies.remove("Rol", { path: "/" });
        cookies.remove("PhCodigo", { path: "/" });
        cookies.remove("Conjunto", { path: "/" });
        cookies.remove("Nitph", { path: "/" });
        cookies.remove("Ciudad", { path: "/" });
        cookies.remove("Tipo", { path: "/" });
        cookies.remove("Rondero", { path: "/" });
        cookies.remove("Ciudad", { path: "/" });
        Global.NotifHome.View = false;
        Global.NotifHome.FirtsEntry = false;
        Global.NotifHome.Pendientes = [];
    };

    ConfigurarPerfil = () => {
        axios.get(`${Global.Ruta.Url}admin/datauser/${Buffer.from(Global.Ruta.Ipin).toString("base64")}/${Buffer.from("" + Global.GlobalUser.userid).toString("base64")}`).then((res) => {
            if (res.data.message === "") {
                for (let user of res.data.Usuarios) {
                    Global.Form.tipo = 3;
                    Global.Form.id = Global.GlobalUser.userid;
                    Global.Form.nombre = user.nombres;
                    Global.Form.login = user.login;
                    Global.Form.rol = user.rol;
                    Global.Form.estado = user.estado;
                }
                this.setState({ confi: 1 });
            } else {
                this.setState({ redirect_login: 1 });
            }
        }).catch((err) => {
            this.setState({ redirect_login: 1 });
        });
    };

    //VERIFICA LA CREACION DE LA BD
    verificarBD() {
        swal({
            title: "ATENCIÓN!",
            text: `Espere un momento por favor`,
            icon: "warning",
            timer: 3000,
            button: false,
            closeOnClickOutside: false,
        });
        var body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        };
        axios.post(`${Global.Ruta.Url}adminasamblea/validarbd`, body).then((res) => {
            var ConEspacios = "";
            var Mayusculas = "";
            var ClaveNoCoincide = "";
            var MaloNombre = "";
            if (res.data.message === "") {
                res.data.ConEspacios.forEach((login) => {
                    ConEspacios = ConEspacios + login + " - ";
                });
                res.data.ConMayusculas.forEach((login) => {
                    Mayusculas = Mayusculas + login + " - ";
                });
                res.data.ClaveNoCoincide.forEach((login) => {
                    ClaveNoCoincide = ClaveNoCoincide + login + " - ";
                });
                res.data.MaloNombre.forEach((login) => {
                    MaloNombre = MaloNombre + login + " - ";
                });
                swal({
                    title: "ATENCIÓN!",
                    text: `
                    Total Suscriptores: ${res.data.Suscriptores}\n
                    Total Inmuebles: ${res.data.Inmuebles}\n
                    Total Coeficiente: ${res.data.TotalCoeficiente}\n
                    Usuarios con espacios: ${ConEspacios}\n
                    Usuarios con Mayusculas: ${Mayusculas}\n
                    Mal Nombre: ${MaloNombre}\n
                    Usuarios con Claves Mala: ${ClaveNoCoincide}`,
                    icon: "success",
                    button: "Aceptar",
                });
            } else {
                swal({
                    title: "ERROR!",
                    text: "Por favor vuelva a intentarlo.",
                    icon: "error",
                    button: "Aceptar",
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
                icon: "error",
                button: "Aceptar",
            });
        });
    }

    // ACTUALIZA LOS COEFICIENTES DE INMUEBLE A ASISTENCIA
    ActualizarLosCoe() {
        swal({
            title: "ATENCIÓN!",
            text: `Espere un momento por favor`,
            icon: "warning",
            timer: 3000,
            button: false,
            closeOnClickOutside: false,
        });
        var body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}adminasamblea/organizarcoe`, body).then((res) => {
            if (res.data.message === "") {
                swal({
                    title: "ATENCIÓN!",
                    text: `Coeficiente acualizado.`,
                    icon: "success",
                    button: "Aceptar",
                });
            } else {
                swal({
                    title: "ERROR!",
                    text: "Por favor vuelva a intentarlo.",
                    icon: "error",
                    button: "Aceptar",
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
                icon: "error",
                button: "Aceptar",
            });
        });
    }

    // AGREGAR USUARIO EN ASOSIACION
    handleChange = (paramprop) => (event) => {
        this.setState((state) => ({
            ...state,
            [paramprop]: event.target.value,
        }));
    };
    NewSuscAso = () => {
        var body = {
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            nombre: Buffer.from(this.state.Nombre).toString("base64"),
            direccion: Buffer.from(this.state.Direccion).toString("base64"),
            login: Buffer.from(this.state.Login).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}adminasamblea/newsusc`, body).then((res) => {
            if (res.data.message === "") {
                swal({
                    title: "ATENCIÓN!",
                    text: `El usuario es: ${this.state.Login} \n
                      La clave es: 103020`,
                    icon: "success",
                    button: "Aceptar",
                    closeOnClickOutside: false,
                }).then((res) => {
                    this.setState({ modal: false });
                });
            } else {
                swal({
                    title: "ERROR!",
                    text: res.data.message,
                    icon: "error",
                    button: "Aceptar",
                }).then((res) => {
                    this.setState({ modal: false });
                });
            }
        })
            .catch((err) => {
                swal({
                    title: "ERROR!",
                    text: "Vaya algo a salido mal, por favor inténtelo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                }).then((res) => {
                    this.setState({ modal: false });
                });
            });
    };

    render() {

        if (this.state.redirect_login === 1) {
            return <Redirect to={Global.Ruta.Raiz + "/"} />;
        }

        if (this.state.confi === 1) {
            return <Redirect to={Global.Ruta.Raiz + "/formusuario"} />;
        }

        return (
            <React.Fragment>
                {/* modals */}
                <Modal show={this.state.modal} onHide={() => this.setState({ modal: false })} centered >
                    <Modal.Header closeButton>Agregando Usuario</Modal.Header>
                    <Modal.Body>
                        <div className="w-100">
                            <TextField
                                className="my-2"
                                variant="outlined"
                                label="Nombre"
                                fullWidth
                                onChange={this.handleChange("Nombre")}
                            />
                            <TextField
                                className="my-2"
                                variant="outlined"
                                label="Dirección"
                                fullWidth
                                onChange={this.handleChange("Direccion")}
                            />
                            <TextField
                                className="my-2"
                                variant="outlined"
                                label="Login"
                                fullWidth
                                onChange={this.handleChange("Login")}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center d-flex">
                        <button className="btn btn-primary" onClick={this.NewSuscAso}>
                            Agregar
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modal1} onHide={() => this.setState({ modal1: false })} centered backdrop="static" fullscreen >
                    <MyPhModal onHide={() => this.setState({ modal1: false })} />
                </Modal>

                <Modal show={this.state.modalbd} onHide={() => this.setState({ modalbd: false })} centered backdrop="static" size="lg" >
                    <CrearBD onHide={() => this.setState({ modalbd: false })} />
                </Modal>

                <Modal show={this.state.modalpdf} onHide={() => this.setState({ modalpdf: false })} centered backdrop="static" size="md" >
                    <CrearPDFBD onHide={() => this.setState({ modalpdf: false })} />
                </Modal>

                <Modal show={this.state.modaldatabase} onHide={() => this.setState({ modaldatabase: false })} centered backdrop="static" fullscreen={true} >
                    <BaseDatos onHide={() => this.setState({ modaldatabase: false })} />
                </Modal>

                <nav className="navbar navbar-expand-sm navbar-dark navbar-custom" >
                    <a className="navbar-brand text-white ms-3" href="/">GoPH</a>

                    <button className="navbar-toggler mr-2" type="button" data-toggle="collapse" data-target="#navbarNav2" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="mx-1 text-white">MENU</span><span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav2">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <div className="d-block d-sm-none dropdown-menu m-2">
                                    <button className="dropdown-item" onClick={this.ConfigurarPerfil}>Configuración</button>
                                    <button className="dropdown-item" onClick={() => this.setState({ modal1: true })}>Mi PH</button>
                                    <div className="dropdown-divider text-danger"></div>
                                    {Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid)) ?
                                        (
                                            <React.Fragment>
                                                <button className="dropdown-item" onClick={this.verificarBD}>Valida BD</button>
                                                <button className="dropdown-item" onClick={this.ActualizarLosCoe}>Actualizar Coef Asistencia</button>
                                                {this.state.Ing.includes(parseInt(Global.GlobalUser.userid)) ? (
                                                    <button className="dropdown-item" onClick={() => this.setState({ modal: true })}>Agregar Suscriptor</button>
                                                ) : (null)}
                                                <div className="dropdown-divider text-danger"></div>
                                            </React.Fragment>
                                        ) : null
                                    }

                                    {Global.Plataforma.Partner.includes(parseInt(Global.Ph.Codigo)) ? (
                                        <React.Fragment>
                                            <button className="dropdown-item" onClick={() => this.setState({ modalbd: true })}>Crear Base de Datos</button>
                                            <button className="dropdown-item" onClick={() => this.setState({ modaldatabase: true })}>Mis Bases de Datos</button>
                                            <div className="dropdown-divider text-danger"></div>
                                        </React.Fragment>
                                    ) : (null)}
                                    <button className="dropdown-item ms-0" onClick={this.CerrarSesion}>Cerrar Sesión</button>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="collapse navbar-collapse justify-content-end d-none d-sm-block" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {this.state.NombreUser}
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                    <button className="dropdown-item" onClick={this.ConfigurarPerfil}>Configuración</button>
                                    <button className="dropdown-item" onClick={() => this.setState({ modal1: true })}>Mi PH</button>
                                    <div className="dropdown-divider"></div>
                                    {Global.Plataforma.Usuarios.includes(parseInt(Global.GlobalUser.userid)) ?
                                        (
                                            <React.Fragment>
                                                <button className="dropdown-item" onClick={this.verificarBD}>Valida BD</button>
                                                <button className="dropdown-item" onClick={this.ActualizarLosCoe}>Actualizar Coef Asistencia</button>
                                                {this.state.Ing.includes(parseInt(Global.GlobalUser.userid)) ? (
                                                    <button className="dropdown-item" onClick={() => this.setState({ modal: true })}>Agregar Suscriptor</button>
                                                ) : (null)}
                                                <div className="dropdown-divider"></div>
                                            </React.Fragment>
                                        ) : null
                                    }

                                    {Global.Plataforma.Partner.includes(parseInt(Global.Ph.Codigo)) ? (
                                        <React.Fragment>
                                            <button className="dropdown-item" onClick={() => this.setState({ modalbd: true })}>Crear Base de Datos</button>
                                            <button className="dropdown-item" onClick={() => this.setState({ modaldatabase: true })}>Mis Bases de Datos</button>
                                            <div className="dropdown-divider"></div>
                                        </React.Fragment>
                                    ) : (null)}
                                    <button className="dropdown-item" onClick={this.CerrarSesion}>Cerrar Sesión</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav >
            </React.Fragment>
        )
    }
}