import React, { Component } from 'react'
import { Image, Modal, ProgressBar } from 'react-bootstrap'
import logo from '../../../../assets/images/logo.png'
import Global from '../../../../Global'
import swal from "sweetalert";
import axios from "axios";
import moment from 'moment';

// COMPONENETES PARA MODAL RESULTADO PREGUNTA
import Titulo from "../../../../assets/css/textFloow.module.css"
import TResultPrg from './TablaResultPrg'
import MaterialTable from 'material-table'

export default class ResultPrg extends Component {
  // ESTADOS
  state = {
    TotalAsistentes: 2,

    rows: [],

    /* REDIRECTS */
    data: false,

    /* MODALS */
    PpTresult: false,
    HppTresult: true,
    Fullscreen: false,
  }
  // FUNCION QUE TRAE DE LA DB DATA DE LOS RESULTADOS DE UNA PREGUNTA
  componentWillMount() {

    var body = {
      codigoph: Buffer.from(Global.Ph.Codigo).toString('base64'),
      ipin: Buffer.from(Global.Ruta.Ipin).toString('base64'),
      preguntaid: Buffer.from("" + this.props.pregId).toString('base64'),
      votoconsejo: Buffer.from("" + this.props.votoConsejo).toString('base64'),
    }

    axios.post(`${Global.Ruta.Url}adminasamblea/resultpregunta`, body)
      .then(res => {
        if (res.data.message === '') {

          var FechaCierre = moment(res.data.FechaCierre).format('LLLL')

          var Data = []

          if (this.props.votoConsejo === 0) {
            res.data.OpcionesVotadas.forEach(DataOpc => {
              var Opcion = {
                opcion: DataOpc.Opcion,
                cOi: DataOpc.Coeficiente + "%",
                votos: DataOpc.Votos,
                total: DataOpc.TotalPorcentaje + "%",
                dato: DataOpc.TotalPorcentaje,
              }
              Data.push(Opcion)
            });
          } else {
            this.setState({
              TotalAsistentes: res.data.Asistencia
            })
            res.data.OpcionesRespuestas.forEach(DataOpc => {
              var Opcion = {
                item: DataOpc.item,
                opcion: DataOpc.Opcion,
                votos: DataOpc.TotalVotos,
                dato: DataOpc.TotalVotos,
              }
              Data.push(Opcion)
            });
          }

          this.setState({
            Asistencia: res.data.Asistencia,
            QuorumActual: res.data.QuorumActual,
            FechaCierre: FechaCierre,
            rows: Data,
            data: true
          })

        } else {
          swal({
            title: "ERROR!",
            text: res.data.message,
            icon: "warning",
            button: "Aceptar"
          }).then(() => {
            this.props.onClose()
          });
        }
      }).catch(err => {
        console.log(`Error en componentWillMount: ${err.message}`)
        this.props.onClose()
      });
  }

  render() {

    //console.log(Global.Asamblea.Col_coe_prg);

    const columns = [
      {
        title: 'Opción',
        field: 'opcion'
      },
      {
        title: 'Coef. o Indiv.',
        field: 'cOi',
        hidden: Global.Asamblea.Col_coe_prg
      },
      {
        title: 'Votos',
        field: 'votos',
        type: 'numeric',
      },
      {
        title: 'Total',
        field: 'total',
      },
      {
        title: 'Infografia',
        field: 'dato',
        render: rowData => <ProgressBar className="w-75 mr-2" now={rowData.dato} />
      },
    ]
    const columnsC = [
      {
        title: 'Item',
        field: 'item'
      },
      {
        title: 'Opción',
        field: 'opcion'
      },
      {
        title: 'Votos',
        field: 'votos',
        type: 'numeric',
      },
      {
        title: 'Infografia',
        field: 'dato',
        render: rowData => <ProgressBar className="w-75 mr-2" now={rowData.dato} max={this.state.TotalAsistentes} />
      },
    ]

    if (this.state.data === true) {
      return (
        <div className=''>
          <div className="container-fluid px-0">

            {/* POPUP'S: TABLA DE RESULTADOS INDIVIDUALES DE VOTANTES Y ABSTINENTES  */}

            <Modal show={this.state.PpTresult} backdrop="static" size="xl" centered className="overflow-auto">
            <TResultPrg onClose={() => { this.setState({ PpTresult: false }) }} textPregunta={this.props.pregText} preguntaId={this.props.pregId} hidden={Global.Asamblea.Col_coe_prg} 
            Qactual={parseFloat(this.state.QuorumActual).toFixed(2)} votoConsejo={this.props.votoConsejo} contPrgnt={this.props.pregText} dataGlobal={this.state.rows} 
            columnsGlobal={(this.props.votoConsejo === 0) ? (columns) : (columnsC)}/>
          </Modal>

          {/* MODAL PRINCIPAL */}
            <Modal.Body className="h-auto pt-1">
              <div className='row flex-column flex-lg-row justify-content-center align-items-center mb-2'>
                  <div className=" col-6 col-lg order-1 order-lg-0 d-flex align-items-center justify-content-center">
                    <div className='w-50'>
                      <Image className='img-fluid img-responsive' src={logo} roundedCircle />
                    </div>
                  </div>
                  <div className="col col-lg-8 order-2 order-lg-1 text-center">
                    <Modal.Title className={"text-nowrap " + Titulo.titulo}>{Global.Ph.Nombre}</Modal.Title>
                    {/**
                      ESTE SEGEMENTO QUEDA EN DUDA, DESCRIBE EL TIPO DE PREGUNTA
                      <p className="text-muted">{(parseInt(this.props.votoConsejo) === 1) ? ('RESULTADO PREGUNTA ESPECIAL') : ('Resultados por pregunta')}</p>
                     */}
                  </div>
                  <div className="col order-0 order-lg-2 d-flex align-items-center justify-content-between">
                    <button onClick={() => { this.setState({ PpTresult: true }) }} className="btn btn-success my-1"><i className="fas fa-search"></i></button>
                    <button className="btn rounded-circle ms-4" style={{ backgroundColor: 'GhostWhite' }} onClick={this.props.onClose}>
                      <i className="fa fa-times" />
                    </button>
                  </div>
                  <div className="row order-3 my-2">
                    <div className='col-12'>
                      <div className="row flex-column flex-lg-row bg-secondary bg-opacity-50 rounded py-2 justify-content-center">
                        <div className='col text-center cover-marqss my-2 my-lg-0'>
                          <h6 className="text-nowrap marqss">Fecha cierre: {"  "}<span className="">{this.state.FechaCierre}</span></h6>
                        </div>
                        <div className='col text-center cover-marqss my-2 my-lg-0'>
                          <h6 className="marqss">Estado de la pregunta: <span className="">Válida y Votada</span></h6>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              {/* SEGEMENTO DE PREGUNTA */}
              <div className="card w-100 mb-3 border-0 py-1" style={{ backgroundColor: 'gainsboro' }} >
                <div className="card-body py-1">
                  <div className="text-center">
                    <h5 className="">Texto de la pregunta</h5>
                    <div className="card overflow-auto mb-2 py-2">
                      <h6 className="text-muted fw-normal m-0">{this.props.pregText}</h6>
                    </div>
                  </div>
                  <div className="d-flex text-center flex-column flex-lg-row justify-content-sm-center justify-content-lg-between">
                    <p className="m-0 ind-txt-xs">Coef. o Indiv. Habilitado <span className="text-muted">{`${parseFloat(this.state.QuorumActual).toFixed(2)}%`}</span></p>
                    <p className="m-0 ind-txt-xs">Asistentes <span className="text-muted">{this.state.Asistencia}</span></p>
                  </div>
                </div>
              </div>
              <div className="h-25 m-0" >
              {/** TABLA CON CANTIDAD DE PREGUNATS HECHAS */}
                <MaterialTable
                  title="Basic Sorting Preview"
                  columns={(this.props.votoConsejo === 0) ? (columns) : (columnsC)}
                  data={this.state.rows}
                  style={{
                    marginBottom: 0,
                    backgroundColor: 'Gainsboro',
                    borderRadius: 10,
                    border: 'none'
                  }}
                  options={{
                    rowStyle:{fontSize: "0.9rem"},
                    toolbar: false,
                    pageSizeOptions: [5, 7, 9, 11, 13, 15, 17, 19],
                    pageSize: (this.props.votoConsejo === 0) ? (5) : (9),
                    sorting: true,
                    headerStyle: {
                      backgroundColor: 'Silver'
                    }
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay datos por mostrar.",
                    },
                    pagination: {
                      labelRowsSelect: "registros",
                      labelDisplayedRows: "{from}-{to} de {count}",
                      firstTooltip: "Primera página",
                      previousTooltip: "Página anterior",
                      nextTooltip: "Próxima página",
                      lastTooltip: "Última página",
                    },
                    toolbar: {
                      searchTooltip: "Buscar",
                      searchPlaceholder: "Buscar",
                      nRowsSelected:
                        "{0} " + this.props.tituloT + "(s) Seleccionado(s)",
                    },
                  }}
                />
              </div>
            </Modal.Body>
          </div>
        </div>
      )
    }
    // DONUT SPINNER
    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
