import React, { Component } from 'react';
import HeaderA2 from '../admin/Home/HeaderA2';
// import ListAutrz from './Comunicaciones/listAutrz';
import TablaAutrz from './Comunicaciones/TablaAutrz';
import { Redirect } from "react-router-dom";
import Global from "../../Global";
import { ValidarRolPagina } from "../Helpers";
import DispatchPorteria from './Comunicaciones/DispatchPorteria';

export default class Autorizaciones extends Component {
  state = {
    redirect_home: false
  }
  componentWillMount() {
    this.setState({ redirect_home: ValidarRolPagina('Autorizaciones') })
  }
  render() {
    if (this.state.redirect_home === true) {
      return (<Redirect to={Global.Ruta.Raiz + "/home-admin"} />);
    }
    return (
      <div className="g-r-prf3 bg-light vh-100">
        <HeaderA2 />
        {(Global.GlobalUser.rol === 4) ? (<DispatchPorteria/>) : (<TablaAutrz />)}
         
      </div>
    );
  };
};
