import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import React, { Component } from "react";
import Global from "../../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import PpHourglass from "../../../Asamblea/PopUps/PpHourglass";
import { Modal } from "react-bootstrap";
import TableVisitAnunci from "./TableVisitAnunci";
import SimpleReactValidator from 'simple-react-validator'



export default class SwitchBodyModal extends Component {
  state = {
    dirTemp: "",
    direcciones: [],
    dir2: "",
    tipoDoc: [
      { label: "Cedula de Ciudadania CC", id: "1" },
      { label: "Tarjeta de Identidad TI", id: "2" },
      { label: "Cedula de Extranjeria", id: "3" },
    ],
    frecuentes: [],
    Anunciados: [],
    tDoc: "",
    doc: "",
    tel: "",
    acomp: "",
    nombrevisitante: "",
    suscriptores: [],
    susc2: null,
    id_suscriptor: "",
    modal: true,
    visitantes: false,
    PpHourglass: false,
  };

  componentWillMount() {
    this.BuscaInmueble();
    this.validator = new SimpleReactValidator({
      locale: 'es',
      messages: {
        required: 'Requerido',
      }
    });

  }

  BuscaInmueble = () => {
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      porteroid: Buffer.from(Global.GlobalUser.userid).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}admin/listinm`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            direcciones: res.data.Inmuebles,
            modal: false,
          });
        } else {
          this.setState({ data: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  // PAQUETES
  BuscarSuscriptor = (e) => {
    var Value = e;
    this.setDir(Value);
    this.setState({ modal: true });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      inmuebleid: Buffer.from("" + Value).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}admin/listsuscinm`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            suscriptores: res.data.Suscriptores,
            modal: false,
          });
        } else {
          this.setState({ modal: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  setDir = (data) => {
    this.props.selectDir(data);
  };

  setSuscrip = (data) => {
    this.props.selectSusc(data);
    this.setState({ id_suscriptor: data });
  };

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) => parseInt(element.id) === parseInt(Id);
    return temRows.findIndex(findIndexArray);
  };

  // VISITANTES
  BuscaVisitantes = (e) => {

    this.setState({ dir2: e, visitantes: false, PpHourglass: true })

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      id_inmueble: Buffer.from("" + e).toString("base64"),
    };

    axios
      .post(`${Global.Ruta.Url}porteria/visitantes`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            frecuentes: res.data.Frecuentes,
            Anunciados: res.data.Anunciados,
            visitantes: true,
            suscriptores: [],
            PpHourglass: false,
          });
        } else {
          this.setState({ visitantes: false });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  GuardaFrecuentes = (Value) => {
    swal({
      title: "Atención",
      text: `¿Desea Ingresar al Visitante ${Value.nombre_visitante}?`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    })
      .then((save) => {
        if (save) {


          this.setState({ PpHourglass: true })
          var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            id_susc: Buffer.from("" + Value.id_susc).toString("base64"),
            id_inmueble: Buffer.from("" + Value.id_inmueble).toString("base64"),
            id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            id_visitante: Buffer.from("" + Value.id_frecuentes).toString("base64"),
            nombre: Buffer.from("" + Value.nombre_visitante).toString("base64"),
          };

          axios.post(`${Global.Ruta.Url}porteria/updatefrecuentes`, body).then((res) => {
            if (res.data.message === "") {
              this.setState({ PpHourglass: false })
              swal({
                title: "Atención",
                text: `Visitante ${Value.nombre_visitante} Ingresado`,
                icon: "success",
                button: false,
                timer: 1000
              }).then(() => {
                this.ActualizarFrecuentes(Value.id_frecuentes)
              });
            } else {
              this.setState({ visitantes: false, PpHourglass: false });
            }
          }).catch((err) => {
            swal({
              title: "ERROR!",
              text: "Por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({
                redirect_home: true,
              });
            });
          });

        } else {
          swal({
            title: `Visitante ${Value.nombre_visitante} No Ingresado`,
            icon: "success",
          });
        }
      });
  }

  ActualizarFrecuentes = (id_frecuentes) => {
    var tem = this.state.frecuentes;
    var index = this.IndexCambioFrecuentes(id_frecuentes, tem);

    tem.splice(index, 1);
    this.setState({
      data: tem,
    });

    setTimeout(() => {
      this.setState({
        visitantes: true,
      });
    }, 150);
  };

  IndexCambioFrecuentes = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.id_frecuentes) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  GuardarVisitante = () => {

    if (this.state.tDoc === "") { this.setState({ tDoc: 0 }) }
    if (this.state.doc === "") { this.setState({ doc: 0 }) }
    if (this.state.tel === "") { this.setState({ tel: 0 }) }

    if (this.state.dir2 === "") {
      swal({
        title: "Atención",
        text: `Por favor seleccionar un inmueble`,
        icon: "warning",
        dangerMode: false,
      })
      return
    }

    if (this.validator.allValid()) {
      swal({
        title: "Atención",
        text: `¿Desea autorizar el ingreso del visitante: ${this.state.nombrevisitante}?`,
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((save) => {
        if (save) {
          this.setState({ PpHourglass: true })
          var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            nombre: Buffer.from("" + this.state.nombrevisitante).toString("base64"),
            tipo_documento: Buffer.from("" + this.state.tDoc).toString("base64"),
            documento: Buffer.from("" + this.state.doc).toString("base64"),
            telefono: Buffer.from("" + this.state.tel).toString("base64"),
            id_inmueble: Buffer.from("" + this.state.dir2).toString("base64"),
            id_portero: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
            cantidad: Buffer.from("" + this.state.acomp).toString("base64"),
          };

          axios.post(`${Global.Ruta.Url}porteria/creavisitante`, body).then((res) => {
            if (res.data.message === "") {
              this.setState({ PpHourglass: false, })
              swal({
                title: "Atención",
                text: `Visitante ${this.state.nombrevisitante} autorizado`,
                icon: "success",
                button: false,
                timer: 1000
              }).then(() => this.props.CerrarModal())
            } else {
              this.setState({ visitantes: false, PpHourglass: false });
            }
          }).catch((err) => {
            swal({
              title: "ERROR!",
              text: "Por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
              timer: 1000
            }).then(() => {
              this.setState({
                redirect_home: true,
              });
            });
          });

        } else {
          swal({
            title: `Visitante ${this.state.nombrevisitante} No Ingresado`,
            icon: "success",
          });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    let { id, info, labels, details, otro, textsus = "", textdescr = "", } = this.props;

    let lab = String(labels);


    if (parseInt(id) === 1) {
      return (
        <div
          className="row align-items-center justify-content-center h-100 gy-3"
          id="capture"
        >
          <Modal
            show={this.state.modal}
            centered
            backdrop="static"
            onHide={() => this.setState({ modal: false })}
          >
            <PpHourglass />
          </Modal>
          <div className="col">
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                disablePortal
                id="combo-box-demo"
                options={this.state.direcciones}
                getOptionLabel={(option) => option.direccion}
                onChange={(event, value) => this.BuscarSuscriptor((value.inmuebleid))}
                renderInput={(params) => (
                  <TextField {...params} label="Dirección" />
                )}
              />
            </FormControl>
          </div>
          <div className="col">
            <FormControl
              fullWidth
              disabled={this.state.suscriptores.length > 0 ? false : true}
            >
              <InputLabel id="demo-simple-select-label">Suscriptor</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={info.susc}
                label="Suscriptor"
                onChange={(e) => this.setSuscrip(e.target.value)}
              >
                {this.state.suscriptores.map((items) => (
                  <MenuItem value={items.suscriptorid}>
                    {items.nombres.toUpperCase()}
                  </MenuItem>
                ))}
                <MenuItem key={0} value={0}>
                  Otro...
                </MenuItem>
              </Select>
              {textsus}
            </FormControl>
          </div>
          {this.state.id_suscriptor === 0 ? (
            <div className="col-12">
              <TextField
                disabled={this.state.id_suscriptor === 0 ? false : true}
                id="outlined-multiline-static"
                label={"Nombre"}
                fullWidth
                value={info.otherN}
                onChange={(e) => otro(e.target.value)}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </div>
          ) : (
            ""
          )}

          <div className="col-12">
            <TextField
              id="outlined-multiline-static"
              label={lab[0].toUpperCase() + lab.substring(1)}
              fullWidth
              multiline
              rows={4}
              value={info.descr}
              onChange={(e) => details(e.target.value)}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
            {textdescr}
          </div>
        </div>
      );
    } else if (parseInt(id) === 2) {
      return (
        <div className="g-r-prf4 h-100 gy-3" id="capture" style={{ gridRowGap: '1rem' }}>
          <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}>
            <PpHourglass />
          </Modal>
          <div className="col-12 h-auto" style={{ zIndex: 10 }}>
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={this.state.direcciones}
              getOptionLabel={(option) => option.direccion}
              onChange={(event, value) => this.BuscaVisitantes((value.inmuebleid))}
              renderInput={(params) => (
                <TextField {...params} label="Dirección" />
              )}
            />
          </div>
          {this.state.frecuentes.length > 0 || this.state.Anunciados.length > 0 ? (
            <div className="col-12">
              <div className="row h-100">
                {this.state.frecuentes.length > 0 ? (
                  <div className="col-6">
                    <span><p className="font-weight-bold" style={{ marginBottom: 5 }}>FRECUENTES</p></span>
                    <div className="border border-5  rounded w-100 p-2" style={{ height: '95%' }}>
                      <div className="row row-cols-1 gy-1">
                        {this.state.frecuentes.map((items, item) => (
                          <div className="col d-grid" key={item}>
                            <button className="btn btn-primary d-flex w-100" onClick={() => { this.GuardaFrecuentes(items) }}>
                              <div className="card me-1 text-slide">
                                <span className="text-dark px-3 text-capitalize">
                                  {items.nombre_visitante}
                                </span>
                              </div>
                              <div className="text-slide">
                                <span className="text-uppercase"> {items.profesion}</span>
                              </div>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>) : null}
                {this.state.Anunciados.length > 0 ? (
                  <div className="col-6">
                    <span><p className="font-weight-bold pt-1" style={{ marginBottom: 5 }}>ANUNCIADOS</p></span>
                    <div className="border border-5  rounded w-100">
                      <TableVisitAnunci data={this.state.Anunciados} />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {this.state.visitantes ? (<div className="col-12 h-100">
            <span><p className="font-weight-bold" style={{ marginBottom: 5 }}>NUEVO VISITANTES</p></span>
            <div className="border border-5  rounded w-100 py-3 px-5">
              <div className="row gy-3">
                <div className="col-12">
                  <TextField label="Nombre" value={this.state.nombrevisitante.toUpperCase()} onChange={(e) => this.setState({ nombrevisitante: (e.target.value).toUpperCase() })} fullWidth />
                  <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('Nombre', this.state.nombrevisitante, 'required', { className: 'text-danger' })}</p>
                </div>
                <div className="col-12">
                  <TextField label="Acompañanates" value={this.state.acomp} onChange={(e) => this.setState({ acomp: e.target.value })} onKeyDown={(evt) => ["e", "E", "+", "-", '.', ','].includes(evt.key) && evt.preventDefault()} type={'number'} fullWidth />
                  <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('Acompañantes', this.state.acomp, 'required', { className: 'text-danger' })}</p>
                </div>
                <div className="col">
                  <Autocomplete
                    disablePortal
                    options={this.state.tipoDoc}
                    onChange={(event, value) => this.setState({ tDoc: value.id })}
                    renderInput={(params) => (
                      <TextField {...params} label="Tipo Documento" />
                    )}
                  />
                </div>
                <div className="col">
                  <TextField label="Numero Documento" type={'number'} onChange={(e) => this.setState({ doc: e.target.value })} onKeyDown={(evt) => ["e", "E", "+", "-", '.', ','].includes(evt.key) && evt.preventDefault()} fullWidth />
                </div>
                <div className="col-12">
                  <TextField label="Teléfono" type={'number'} onChange={(e) => this.setState({ tel: e.target.value })} onKeyDown={(evt) => ["e", "E", "-", '.', ','].includes(evt.key) && evt.preventDefault()} fullWidth />
                </div>
                <div className="col-12">
                </div>
              </div>
              <div className="col-12 pt-2">
                <button className="btn btn-primary" onClick={() => this.GuardarVisitante()}> Crear Visitante </button>
              </div>
            </div>
          </div>) : ("")}
        </div>
      );
    } else {
      return (
        <div
          className="row align-items-center justify-content-center h-100 gy-3"
          id="capture"
        >
          <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ PpHourglass: false })}>
            <PpHourglass />
          </Modal>
          <div className="col">
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={this.state.direcciones}
              getOptionLabel={(option) => option.direccion}
              onChange={(event, value) => this.setState({ dir2: value.inmuebleid })}
              renderInput={(params) => (
                <TextField {...params} label="Dirección" />
              )}
            />
          </div>
          <div className="col-12 h-100">
            {/* <span><p className="font-weight-bold" style={{ marginBottom: 5 }}>NUEVO VISITANTES</p></span> */}
            {/* <div className="border border-5  rounded w-100 py-3 px-5"> */}
            <div className="row gy-3">
              <div className="col-12">
                <TextField label="Nombre" value={this.state.nombrevisitante.toUpperCase()} onChange={(e) => this.setState({ nombrevisitante: (e.target.value).toUpperCase() })} fullWidth />
                <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('Nombre', this.state.nombrevisitante, 'required', { className: 'text-danger' })}</p>
              </div>
              <div className="col-12">
                <TextField label="Acompañanates" value={this.state.acomp} onChange={(e) => this.setState({ acomp: e.target.value })} onKeyDown={(evt) => ["e", "E", "+", "-", '.', ','].includes(evt.key) && evt.preventDefault()} type={'number'} fullWidth />
                <p className='fst-italic text-muted text-capitalize m-0 txt-helper' >{this.validator.message('Acompañantes', this.state.acomp, 'required', { className: 'text-danger' })}</p>
              </div>
              <div className="col">
                <Autocomplete
                  disablePortal
                  options={this.state.tipoDoc}
                  onChange={(event, value) => this.setState({ tDoc: value.id })}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipo Documento" />
                  )}
                />
              </div>
              <div className="col">
                <TextField label="Numero Documento" type={'number'} onChange={(e) => this.setState({ doc: e.target.value })} onKeyDown={(evt) => ["e", "E", "+", "-", '.', ','].includes(evt.key) && evt.preventDefault()} fullWidth />
              </div>
              <div className="col-12">
                <TextField label="Teléfono" type={'number'} onChange={(e) => this.setState({ tel: e.target.value })} onKeyDown={(evt) => ["e", "E", "-", '.', ','].includes(evt.key) && evt.preventDefault()} fullWidth />
              </div>
              <div className="col-12">
              </div>
            </div>
            <div className="col-12 pt-2 d-flex justify-content-center">
              <button className="btn btn-primary" onClick={() => this.GuardarVisitante()}> Crear Visitante </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      );
    }
  }
}
