import React, {Component} from "react";
import {Modal, Navbar} from "react-bootstrap";
import {NavLink, Redirect} from "react-router-dom";
import Global from "../../../Global";
import MaterialTable from "material-table";
import DetallesSolicitud from "./DetallesSolicitud";
import {LocalizationProvider, DatePicker} from "@mui/lab";
import Es from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Button, TextField} from "@material-ui/core";
import {CookieSesion} from "../../Helpers";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";
import HeadTitle from "../../principal/HeadTitle";

const columns = [
  {
    field: "suscriptorid",
    title: "Radicado",
  },
  {
    field: "nombre_susc",
    title: "Nombre",
  },
  {
    field: "direccion",
    title: "Dirrecion",
  },
  {
    field: "asunto",
    title: "Asunto",
    cellStyle: {
      maxWidth: "30rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  {
    field: "mensaje",
    title: "Mensaje",
    cellStyle: {
      maxWidth: "30rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    hidden: true,
  },
  {
    field: "fecha",
    title: "Fecha de la solicitud",
  },
  {
    field: "fecha_respuesta",
    title: "Fecha respuesta",
  },
  {
    field: "respuesta",
    title: "Respuesta",
    hidden: true,
  },
];

export default class HistorialSolicitudes extends Component {
  state = {
    //DATAS
    data: true,
    modal1: false,
    rows: [],

    //FECHAS FILTRO
    fechai: null,
    fechaf: null,
    fechaAct: new Date(),

    //REDIRECTS
    redirect_home: false,
    redirect_login: 0,

    Data: {},

    Roles: [1, 2],
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (this.state.Roles.includes(Global.GlobalUser.rol)) {
          this.HistorialSolicitudes();
        } else {
          this.setState({redirect_home: true});
        }
      } else {
        this.setState({redirect_login: 1});
      }
    } else {
      this.setState({redirect_login: 1});
    }
  }

  HistorialSolicitudes = () => {
    this.setState({
      data: false,
    });

    var Caso = "";
    var fechai = null;
    var fechaf = null;

    if (this.state.fechai === null && this.state.fechaf === null) {
      Caso = "0";
    } else if (this.state.fechai !== null && this.state.fechaf === null) {
      Caso = "1";
      fechai = this.state.fechai;
    } else if (this.state.fechai === null && this.state.fechaf !== null) {
      Caso = "2";
      fechaf = this.state.fechaf;
    } else if (this.state.fechai !== null && this.state.fechaf !== null) {
      Caso = "3";
      fechai = this.state.fechai;
      fechaf = this.state.fechaf;
    }

    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      caso: Buffer.from("" + Caso).toString("base64"),
      fechai: Buffer.from("" + moment(fechai).format("DD/MM/YYYY")).toString(
        "base64"
      ),
      fechaf: Buffer.from("" + moment(fechaf).format("DD/MM/YYYY")).toString(
        "base64"
      ),
    };

    axios
      .post(`${Global.Ruta.Url}admin/historialsolicitudadmin`, body)
      .then((res) => {
        if (res.data.message === "") {
          this.setState({
            rows: res.data.Solicitudes,
            data: true,

            //limpiar variables de filtro
            fechai: null,
            fechaf: null,
          });
        }
      })
      .catch((err) => {
        swal({
          title: "ERROR!",
          text: "Por favor vuelva a intentarlo mas tarde.",
          icon: "error",
          button: "Aceptar",
        }).then(() => {
          this.setState({
            redirect_home: true,
          });
        });
      });
  };

  DetallePeticion = (Data) => {
    this.setState({
      Data: {
        Asunto: Data.asunto,
        Mensaje: Data.mensaje,
        Nombre: Data.nombre_susc.toUpperCase(),
        Direccion: Data.direccion,
        Fecha_Solicitud: Data.fecha,
        Fecha_Respuesta: Data.fecha_respuesta,
        Respuesta: Data.respuesta,
        Radicado: Data.suscriptorid,
      },
      modal1: true,
    });
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (this.state.data) {
      return (
        <div className="container-fluid pb-5 mb-3 bg-white">
          {/* modal */}
          <Modal
            show={this.state.modal1}
            size="lg"
            centered
            onHide={() => {
              this.setState({modal1: false});
            }}
          >
            <DetallesSolicitud Data={this.state.Data} />
          </Modal>
          {/* fin del modal */}
          <div className="row justify-content-center gy-3 pt-3 pb-4">
            <div className="col-12">
              <HeadTitle
                titulo="Historial de Solicitudes"
                subTitulo="Ver las peticiones pasadas de los residentes"
                classNameOwn="row px-3"
                indicadores={[]}
                actionButton={
                  <div className="col my-2">
                    <div className="row gx-1 justify-content-center">
                      <div className="col d-grid">
                      <NavLink
                        className="btn btn-outline-primary btn-block"
                        exact
                        to={Global.Ruta.Raiz + "/solicitudescomu"}
                      >
                        Ver Tramites
                      </NavLink>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className='col-12'>
              <div className="card text-center shadow">
                <div className="card-body">
                  <div className='row justify-content-center '>
                    <div className="col-12 px-0">
                      <Navbar
                        className="navbar rounded-bottom bg-light"
                        expand="lg"
                      >
                        <div className="navbar-brand ms-2">Filtro</div>
                        <Navbar.Toggle aria-controls="id_navbar_1" />
                        <Navbar.Collapse className="py-3 py-md-0" id="id_navbar_1">
                          <ul className="navbar-nav row justify-content-end align-items-center w-100 mx-0">
                            <div className="col-auto col-lg-10">
                              <div className="row justify-content-center">
                                <li className="col-auto">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={Es}
                                  >
                                    <DatePicker
                                      label="Fecha inicio"
                                      value={this.state.fechai}
                                      maxDate={this.state.fechaAct}
                                      onChange={(date) =>
                                        this.setState({fechai: date})
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </li>
                                <li className="col-auto">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={Es}
                                  >
                                    <DatePicker
                                      label="Fecha final"
                                      value={this.state.fechaf}
                                      minDate={this.state.fechai}
                                      maxDate={this.state.fechaAct}
                                      onChange={(date) =>
                                        this.setState({fechaf: date})
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </li>
                              </div>
                            </div>
                          </ul>
                          <div className="col-auto d-flex justify-content-center px-3">
                            <Button
                              className="mx-1"
                              color="error"
                              variant="contained"
                              onClick={() => {
                                this.HistorialSolicitudes(0);
                              }}
                            >
                              {" "}
                              Quitar Filtro{" "}
                            </Button>
                            <Button
                              className="mx-1"
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                this.HistorialSolicitudes();
                              }}
                            >
                              {" "}
                              Filtrar{" "}
                            </Button>
                          </div>
                        </Navbar.Collapse>
                      </Navbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <MaterialTable
                title="Autorizaciones"
                columns={columns}
                data={this.state.rows}
                options={{
                  pageSize: 10,
                  actionsColumnIndex: -1,
                  rowStyle: {fontSize: "0.9rem"},
                }}
                actions={[
                  {
                    tooltip: "Detalles",
                    onClick: (Data) => this.DetallePeticion(Data),
                  },
                ]}
                components={{
                  Action: (props) => (
                    <button
                      className="btn btn-primary mx-5 pe-3 text-nowrap"
                      onClick={(event) => props.action.onClick(props.data)}
                    >
                      Detalles
                    </button>
                  ),
                }}
                localization={{
                  header: {
                    actions: "Ver",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos por mostrar.",
                  },
                  pagination: {
                    labelRowsSelect: "registros",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
