import React, { Component } from 'react';
import{Modal} from 'react-bootstrap'
import axios from "axios";
import Global from "../../../Global";
import MaterialTable from "material-table";



const columns = [
    {
        field: "suscriptorid",
        title: "Id Suscriptor",
        hidden: true
    },
    {
        field: "inmuebleid",
        title: "Id inmueble",
        hidden: true
    },
    {
        field: "nombres",
        title: "Nombres",
    },
    {
        field: "direccion",
        title: "Dirección",
    },
];


class TablaContactoMsn extends Component {

    state = {
        redirect_home: false,
        redirect_login: 0,
        form_user: 0,
        rows: [],
        nombres: [],
        newData: [],
        count: 0,
        data: false,
        contactos: [],
    }

    componentWillMount() {
        axios.get(Global.Ruta.Url + "mensajeria/notificasusc/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from("" + this.props.caso).toString("base64"))
            .then((res) => {
                if (res.data.message === "") {
                    var tem = [];
                    var user = {};
                    for (var i = 0; i < res.data.User.length; i++) {
                        user = {
                            suscriptorid: res.data.User[i].suscriptorid, 
                            inmuebleid: res.data.User[i].inmuebleid,
                            nombres: res.data.User[i].nombres.toUpperCase(),
                            direccion: res.data.User[i].direccion,
                        };
                        tem.push(user);
                    }
                    this.setState({ rows: tem, data: true });
                } else {
                    this.props.cerrar();
                }
            }).catch(err => {
                this.props.cerrar();
            })
    }

    render() {

        if (this.state.data === true) {
            return (
                <Modal.Body className=''  style={{}}>
                    <div className="container-fluid">
                        <div className="">
                            <MaterialTable
                                columns={columns}
                                data={this.state.rows}
                                title={this.props.tituloT}

                                options={{
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    selectionProps: ({
                                        color: 'primary'
                                    }),
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    rowStyle:{fontSize: "0.9rem"},
                                }}

                                actions={[
                                    {
                                        tooltip: 'Guardar seleccionados',
                                        icon: 'save',
                                        iconProps: ({
                                            fontSize: 'large'
                                        }),
                                        onClick: (evt, data) => { this.props.guardar(data)},
                                    }
                                ]}


                                localization={{
                                    header: {
                                        actions: 'Editar',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos por mostrar.',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'registros',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar',
                                        nRowsSelected: '{0} ' + this.props.tituloT + '(s) Seleccionado(s)'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            );
        }

        return (
            <div className="d-flex justify-content-center text-info donita">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );

    }

}

export default TablaContactoMsn;