import React, { Component } from "react";
import { CookieSesion, CookieNombrePH, CookiePhCodigo, CookieNombre, CookieTipo, CookieUserId, CookieNitph, CookieCiudad, CookieRol, CookieRondero, CookieSupervisor, CerrarSesion } from "../Helpers";
import HeaderA from "./Home/HeaderA";

import NuevoHome from "./Home/NuevoHome";

import HomeA from "./Home/HomeA";
import HomeRol6 from "./Home/HomeRol6";
import Home from "./Home/HomeNew";
import FooterHome from "../principal/FooterHome";
import Global from "../../Global";
import logo from "../../assets/images/images_menu/goph_logo.png";
import RondaPortero from './Ronda/RondaPortero'
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";

class homeAdm extends Component {

  state = {
    redirect_login: false,
    Data: true,
    Home: false,
    Ronda: false,
  }


  componentWillMount() {
    var login = CookieSesion();
    var userid = CookieUserId();

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        Global.GlobalUser.userid = CookieUserId();
        Global.GlobalUser.Nombre = CookieNombre();
        Global.GlobalUser.Rondero = CookieRondero();
        Global.GlobalUser.Supervisor = CookieSupervisor();
        Global.Ph.Nombre = CookieNombrePH();
        Global.Ph.Codigo = CookiePhCodigo();
        Global.Ph.Nitph = CookieNitph();
        Global.Ph.Ciudadph = CookieCiudad();
        Global.Ph.Estadoph = CookieTipo();
        Global.GlobalUser.rol = parseInt(CookieRol());
      }
    }

    const isWin = /Windows/.test(navigator.userAgent);
    const isMac = /Macintosh/.test(navigator.userAgent);

    if (parseInt(Global.GlobalUser.rol) === 4) {
      if (isWin || isMac) {
        this.setState({ Data: false, Home: true })
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              Global.Geolocalizacion.Latitud = position.coords.latitude
              Global.Geolocalizacion.Longitud = position.coords.longitude
            }, (err) => {
              swal({
                title: "Atención",
                text: `Por favor active la geolocalización para utilizar la función de rondero`,
                icon: "warning",
                button: "Aceptar"
              }).then(() => {
                CerrarSesion()
                this.setState({ redirect_login: true });
              })
            }
          )
        }
      }

    }

  }

  render() {
    if (this.state.redirect_login) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    const SwHome = () => {
      switch (parseInt(Global.GlobalUser.rol)) {
        case 1:
          return (
            <React.Fragment>
              {/* <NuevoHome /> */}
              <HomeA />
              <FooterHome />
            </React.Fragment>
          );
        case 2:
          return (
            <React.Fragment>
              <HomeA />
              <FooterHome />
            </React.Fragment>
          );
        case 3:
          return (
            <React.Fragment>
              <HomeA />
              <FooterHome />
            </React.Fragment>
          );
        case 4:
          return parseInt(Global.GlobalUser.Rondero) === 0 ? (<Home />) : (
            this.state.Data ? (
              <div div className="container d-flex justify-content-center align-items-center vh-100">
                <div className="text-center">
                  <div className="col-auto col-lg-3">
                    <img src={logo} alt="" className="w-50" />
                  </div>
                  <button className="btn btn-primary btn-lg my-2 mx-2" onClick={() => this.setState({ Home: true, Data: false })}> <i className="fa-solid fa-house-user"></i> Home</button>
                  <button className="btn btn-success btn-lg my-2 mx-2" onClick={() => this.setState({ Ronda: true, Data: false })}> <i className="fa-solid fa-map-location-dot"></i> Ronda</button>
                </div >
              </div>

            ) : (
              this.state.Home ? (<Home />) : (this.state.Ronda ? (<RondaPortero />) : (null))
            )
          );
        case 5:
          return (
            <React.Fragment>
              <HomeA />
              <FooterHome />
            </React.Fragment>
          );
        case 6:
          return (
            <HomeRol6 />
          );

        default:
          return (
            <React.Fragment>
              <HomeA />
              <FooterHome />
            </React.Fragment>
          );
      }
    }
    return (
      <div className="g-r-prf1 vh-100">
        <HeaderA />
        <SwHome />
      </div >
    );
  }
}

export default homeAdm;
