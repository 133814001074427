import React, { Component } from "react";
import Global from "../../../../Global";
import logo from "../../../../assets/images/logo.png"
import QrCode from "qrcode.react"
import axios from "axios";

class PpQr extends Component {

    state = {
        CodigoQr: ""
    }

    componentWillMount() {
        axios.get(`${Global.Ruta.Url}adminasamblea/codigoqr/${Buffer.from(Global.Ph.Codigo).toString('base64')}/${Buffer.from(Global.Ruta.Ipin).toString('base64')}/${Buffer.from("" + Global.Asamblea.AsambleaId).toString('base64')}`).then(res => {
            this.setState({ CodigoQr: res.data.message })
        })
    }
    
    render() {
        return (
                <div id="capture" className="row align-items-center justify-content-center my-3">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                Codigo QR
                            </div>
                            <div className="card-body px-4 py-1">
                                <div className="card-title d-flex justify-content-center">
                                    <img className="" style={{ width: "4rem" }} src={logo} aria-label="GoPH" />
                                </div>
                                <h3 className="text-center text-dark">{Global.Ph.Nombre}</h3>
                                <h4 className="card-title text-center text-muted">Registre aquí su asistencia.</h4>
                                <p className="card-subtitle text-center mb-2 text-muted font-weight-normal" style={{fontSize: 15}}>Escanee este código QR desde la App de GoPH, para marcar asistencia en esta asamblea.</p>
                                <div className="row justify-content-center pt-3 pb-2">
                                    <div className="col-6 d-flex justify-content-center">
                                        <QrCode value={this.state.CodigoQr} size="250" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row justify-content-center">
                                    <div className="col-6 d-grid">
                                        <button className="btn btn-primary" onClick={this.props.cerrar}>Aceptar</button>
                                    </div>
                                    <div className="col-6 d-grid">
                                        <button className="btn btn-primary" id="snap" onClick={() => window.print()}>PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default PpQr